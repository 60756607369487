import React, { useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  List,
  Modal,
  Text,
} from '@forward-financing/fast-forward';
import { CustomerSubmission } from './matchedRecords.types';

export interface DeclineInfoModalProps {
  currentSubmission: CustomerSubmission | undefined;
  previousSubmission: CustomerSubmission;
}

export const DeclineInfoModal = ({
  currentSubmission,
  previousSubmission,
}: DeclineInfoModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const DeclineInfo = ({
    currentSubmission: currentSubmissionData,
    previousSubmission: submissionData,
  }: DeclineInfoModalProps): JSX.Element => {
    return (
      <Flex flexDirection="column">
        <Flex gap={1} marginTop={2}>
          <Text bold>Current Submission: </Text>
          <Text>{currentSubmissionData?.submissionName || 'N/A'}</Text>
        </Flex>

        <Flex gap={1} marginTop={2}>
          <Text bold>Previous Submission:</Text>
          <Text>{submissionData.submissionName}</Text>
        </Flex>

        <Flex gap={1} marginTop={2}>
          <Text bold>Last Stage:</Text>
          <Text>
            {submissionData.stage} / {submissionData.subStage}
          </Text>
        </Flex>

        <Flex marginTop={2}>
          <Text bold>Decline Drivers</Text>
        </Flex>
        <List>
          {submissionData.declineDrivers?.split(';').map((driver) => (
            <List.Item key={driver}>{driver}</List.Item>
          ))}
        </List>

        <Flex marginTop={2}>
          <Text bold>Decline Notes</Text>
        </Flex>
        <Text>{submissionData.declineDriverNotes || 'N/A'}</Text>
      </Flex>
    );
  };

  return (
    <Box>
      <Modal
        trigger={
          <Icon name="info-circle" data-testid="decline-info-modal-trigger" />
        }
        isOpen={isModalOpen}
        title="Submission has been declined in the past"
        onOpenChange={handleOpenChange}
        size="auto"
        body={
          <DeclineInfo
            currentSubmission={currentSubmission}
            previousSubmission={previousSubmission}
          />
        }
      />
    </Box>
  );
};
