import React from 'react';
import { Button, Box, Flex, Subheading } from '@forward-financing/fast-forward';
import { useAttachmentManagerContext } from '../AttachmentManagerContext';

export const AccountInformationPanel = (): JSX.Element => {
  const { customerLegalName, customerDba, ownerNames, entityType } =
    useAttachmentManagerContext();

  if (!customerDba && !customerLegalName && !ownerNames && !entityType) {
    return <></>;
  }

  return (
    <Box padding={3} backgroundColor="blue-100">
      <Subheading>Account Information</Subheading>
      <Flex justifyContent="space-between" gap={3}>
        {customerLegalName && (
          <Flex flexDirection="column" gap={1}>
            <Subheading variant="subsection">Legal Name</Subheading>
            <Flex justifyContent="space-between" gap={2} alignItems="center">
              {customerLegalName}
              <Button
                onClick={() => navigator.clipboard.writeText(customerLegalName)}
              >
                Copy
              </Button>
            </Flex>
          </Flex>
        )}
        {customerDba && (
          <Flex flexDirection="column" gap={1}>
            <Subheading variant="subsection">DBA</Subheading>
            <Flex justifyContent="space-between" gap={2} alignItems="center">
              {customerDba}
              <Button
                onClick={() => navigator.clipboard.writeText(customerDba)}
              >
                Copy
              </Button>
            </Flex>
          </Flex>
        )}
        {entityType && (
          <Flex flexDirection="column" gap={1}>
            <Subheading variant="subsection">Entity Type</Subheading>
            <Flex justifyContent="space-between" gap={2} alignItems="center">
              {entityType}
              <Button onClick={() => navigator.clipboard.writeText(entityType)}>
                Copy
              </Button>
            </Flex>
          </Flex>
        )}
        {ownerNames && (
          <Flex flexDirection="column" gap={1}>
            <Subheading variant="subsection">Owner Name(s)</Subheading>
            {ownerNames.map((name) => (
              <Flex
                justifyContent="space-between"
                gap={2}
                alignItems="center"
                key={name}
              >
                {name}
                <Button onClick={() => navigator.clipboard.writeText(name)}>
                  Copy
                </Button>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
