import React from 'react';
import {
  Flex,
  LinkPreview,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { CriminalFilingsLinkPreviewContainer } from './CriminalFilingsLinkPreviewContainer';

export interface CriminalFilingsCountProps {
  submissionUuid: string;
  ownerUuid: string;
  total: string;
  last12Months: string;
  oneToThreeYears: string;
  threeToFiveYears: string;
  fiveToTenYears: string;
  moreThanTenYears: string;
}
export const CriminalFilingsCount = ({
  submissionUuid,
  ownerUuid,
  total,
  last12Months,
  oneToThreeYears,
  threeToFiveYears,
  fiveToTenYears,
  moreThanTenYears,
}: CriminalFilingsCountProps): JSX.Element => {
  return (
    <>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Criminal Filings</Subheading>
        <LinkPreview
          href={
            new URL(
              `/dashboard/submissions/${submissionUuid}/owners/${ownerUuid}/criminal_filings`,
              window.location.origin
            )
          }
          linkname="View Criminal Filings"
          content={
            <CriminalFilingsLinkPreviewContainer
              submissionUuid={submissionUuid}
              ownerUuid={ownerUuid}
            />
          }
          size="large"
          newTab
        />
      </Flex>

      <Table caption="Criminal Filings">
        <Table.Head>
          <Table.ColumnHeader>Criminal Filings</Table.ColumnHeader>
          <Table.ColumnHeader>12 mo.</Table.ColumnHeader>
          <Table.ColumnHeader>1-3 yrs.</Table.ColumnHeader>
          <Table.ColumnHeader>3-5 yrs.</Table.ColumnHeader>
          <Table.ColumnHeader>5-10 yrs.</Table.ColumnHeader>
          <Table.ColumnHeader>10+ yrs.</Table.ColumnHeader>
          <Table.ColumnHeader>Total</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>Count</Table.RowHeader>
            <Table.Cell>{last12Months}</Table.Cell>
            <Table.Cell>{oneToThreeYears}</Table.Cell>
            <Table.Cell>{threeToFiveYears}</Table.Cell>
            <Table.Cell>{fiveToTenYears}</Table.Cell>
            <Table.Cell>{moreThanTenYears}</Table.Cell>
            <Table.Cell>{total}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
