/**
 * What date is initially selected in From datepicker for opportunity search
 *
 * This date was chosen as a result of decline driver logic changing in 2019.
 * Underwriters use a different process to verify declines before and after
 * this date, so this is the date they usually use as a start date when performing
 * a search.
 */
export const INITIAL_START_DATE = new Date('01/01/2019');

/**
 * Absolute minimum date for Submission Search.
 * This is based on when the company started
 */
export const MIN_DATE_ALLOWED = new Date('01/01/2012');
