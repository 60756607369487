import { FUNDING_BASE_URL } from 'constants/globals';
import {
  BulkAdjustmentsCreateAttributes,
  CreateBulkAdjustmentsRequestBody,
} from 'types/api/funding/types';
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';

/**
 * Creates new bulk adjustments in the Funding App
 */
export const createBulkAdjustments = async (
  advanceRecordIds: number[],
  bulkAdjustments: BulkAdjustmentsCreateAttributes[]
): Promise<{ success: boolean }> => {
  const url = new URL(
    '/api/v2/payback/bulk_payback_schedule_adjustment_groups',
    FUNDING_BASE_URL()
  );

  const body: CreateBulkAdjustmentsRequestBody = {
    advance_record_ids: advanceRecordIds,
    bulk_payback_schedule_adjustments_attributes: bulkAdjustments,
  };

  const promiseResponse = await makeInternalAPIRequest<
    void,
    CreateBulkAdjustmentsRequestBody
  >(url, 'POST', body);

  if (!promiseResponse.ok) {
    throw new NetworkError(
      promiseResponse.status,
      'Failed to create bulk adjustment.'
    );
  }

  return { success: true };
};
