import React from 'react';
import { Navbar as FFNavbar } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { NavbarSignInLink } from '../NavbarSignInLink';
import { NavbarAccountDropdown } from '../NavbarAccountDropdown';
import { NavbarOpportunitySearchLink } from '../NavbarOpportunitySearchLink';
import { NavbarLivePipelineLink } from '../NavbarLivePipelineLink';
import { NavbarSignOutLink } from '../NavbarSignOutLink';
import { NavbarBankingLink } from './NavbarBankingLink';
import { useParams } from 'react-router-dom';
import { useSubmission } from './navbarFetchHooks';
import { NavbarFundingLink } from './NavbarFundingLink';
import { NavbarHelpModal } from './NavbarHelpModal/NavbarHelpModal';
import { NavbarSubmissionStageHistoryModal } from './NavbarSubmissionStageHistoryModal';
import { NavbarPaymentHistoryLink } from './NavbarPaymentHistoryLink';
import { NavbarSubmissionOnboardingQueueLink } from '../NavbarSubmissionOnboardingQueueLink';

const logoUrl = new URL('/dashboard/applications', window.location.origin);
type Params = {
  submissionUuid: string;
};
export const SubmissionNavbar = (): JSX.Element => {
  const { id: userId } = useUserContext();
  const { submissionUuid } = useParams<keyof Params>() as Params;
  const { data: submission } = useSubmission(submissionUuid);

  const isUserSignedOut = userId === 0;

  if (isUserSignedOut) {
    return (
      <FFNavbar
        logoUrl={logoUrl}
        mainMenu={[<NavbarSignInLink key="signin" />]}
        mobileMenu={[<NavbarSignInLink key="signin" />]}
      />
    );
  }

  return (
    <>
      <FFNavbar
        logoUrl={logoUrl}
        mainMenu={[
          <NavbarPaymentHistoryLink
            submission={submission}
            key="paymentHistory"
          />,
          <NavbarSubmissionOnboardingQueueLink key="submissionOnboardingQueue" />,
          <NavbarLivePipelineLink key="livePipeline" />,
          <NavbarOpportunitySearchLink key="opportunitySearch" />,
          <NavbarFundingLink submission={submission} key="fundingApp" />,
          <NavbarBankingLink submission={submission} key="bankingApp" />,

          <NavbarHelpModal key="help" />,
          <NavbarSubmissionStageHistoryModal
            key="submissionStageHistory"
            submissionUuid={submissionUuid}
          />,
          <NavbarAccountDropdown key="accountdropdown" />,
        ]}
        mobileMenu={[
          <NavbarPaymentHistoryLink
            submission={submission}
            key="paymentHistory"
          />,
          <NavbarSubmissionOnboardingQueueLink key="submissionOnboardingQueue" />,
          <NavbarLivePipelineLink key="livePipeline" />,
          <NavbarOpportunitySearchLink key="opportunitySearch" />,
          <NavbarFundingLink submission={submission} key="fundingApp" />,
          <NavbarBankingLink submission={submission} key="bankingApp" />,
          <NavbarHelpModal key="help" />,
          <NavbarSubmissionStageHistoryModal
            key="submissionStageHistory"
            submissionUuid={submissionUuid}
          />,
          <NavbarSignOutLink key="signout" />,
        ]}
      />
    </>
  );
};
