import React, { useState } from 'react';

import { Modal, Navbar } from '@forward-financing/fast-forward';
import { HelpInstructions } from './HelpInstructions';

export const NavbarHelpModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Modal
      title="Helpful Tips"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      body={<HelpInstructions handleOpenChange={handleOpenChange} />}
      trigger={
        <Navbar.Button key="help" icon="circle-question">
          Help
        </Navbar.Button>
      }
    />
  );
};
