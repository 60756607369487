import React from 'react';
import { Navbar as FFNavbar } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { NavbarSignInLink } from './NavbarSignInLink';
import { NavbarAccountDropdown } from './NavbarAccountDropdown';
import { NavbarOpportunitySearchLink } from './NavbarOpportunitySearchLink';
import { NavbarLivePipelineLink } from './NavbarLivePipelineLink';
import { NavbarSignOutLink } from './NavbarSignOutLink';
import { NavbarSubmissionOnboardingQueueLink } from './NavbarSubmissionOnboardingQueueLink';

const logoUrl = new URL('/dashboard/applications', window.location.origin);

export const Navbar = (): JSX.Element => {
  const user = useUserContext();

  if (user.id === 0) {
    return (
      <FFNavbar
        logoUrl={logoUrl}
        mainMenu={[<NavbarSignInLink key="signin" />]}
        mobileMenu={[<NavbarSignInLink key="signin" />]}
      />
    );
  }

  return (
    <FFNavbar
      logoUrl={logoUrl}
      mainMenu={[
        <NavbarSubmissionOnboardingQueueLink key="submissionOnboardingQueue" />,
        <NavbarLivePipelineLink key="livePipeline" />,
        <NavbarOpportunitySearchLink key="opportunitySearch" />,
        <NavbarAccountDropdown key="accountdropdown" />,
      ]}
      mobileMenu={[
        <NavbarSubmissionOnboardingQueueLink key="submissionOnboardingQueue" />,
        <NavbarLivePipelineLink key="livePipeline" />,
        <NavbarOpportunitySearchLink key="opportunitySearch" />,
        <NavbarSignOutLink key="signout" />,
      ]}
    />
  );
};
