import React from 'react';
import { Box, Flex, IconButton } from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';
import { RenewalComparison } from './RenewalComparison';

type Params = {
  submissionUuid: string;
};

export const RenewalComparisonCoreContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const handleBackToSubmission = (): void => {
    const submissionUrl = new URL(
      `/submissions/${submissionUuid}`,
      window.location.origin
    );

    window.location.assign(submissionUrl.toString());
  };

  return (
    <Flex p={3} gap={3} flexDirection="column">
      <Box>
        <IconButton
          label="Back to submission"
          onClick={handleBackToSubmission}
          icon={'circle-left'}
        />
      </Box>
      <Box>
        <RenewalComparison submissionUuid={submissionUuid} />
      </Box>
      <Box>
        <IconButton
          label="Back to submission"
          onClick={handleBackToSubmission}
          icon={'circle-left'}
        />
      </Box>
    </Flex>
  );
};
