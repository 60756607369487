import React from 'react';

import { Flex, Icon, Text } from '@forward-financing/fast-forward';

export type ChangeComparisonProps = {
  currentValue: number;
  previousValue: number;
};

export const ChangeComparison = ({
  currentValue,
  previousValue,
}: ChangeComparisonProps): JSX.Element => {
  const diff = currentValue - previousValue;

  if (currentValue > previousValue) {
    return (
      <Flex gap={2} alignItems="center">
        <Text>
          {currentValue} (+{diff})
        </Text>
        <Icon color="primary" name="arrow-trend-up" />
      </Flex>
    );
  }

  if (currentValue < previousValue) {
    return (
      <Flex gap={2} alignItems="center">
        <Text color="danger">
          {currentValue} ({diff})
        </Text>
        <Icon color="danger" name="arrow-trend-down" />
      </Flex>
    );
  }

  // if there's no change we only display the formatted current value
  return <Text>{currentValue}</Text>;
};
