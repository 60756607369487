import React from 'react';
import { Section } from './Section';
import { FormRow } from '../../shared/form/FormRow';
import { Field } from './Field';
import { SubformSection } from '../../shared/SubformSection';
import { OwnerRow } from './OverviewPage';

export interface OwnerSectionProps {
  submissionFields: OwnerRow[];
  isSectionComplete: boolean;
  handleNavigation: () => void;
  handleSendLogs?: (
    field_name: string | undefined,
    objectUuid: string | undefined,
    object_type: string | undefined
  ) => Promise<void>;
}

export const OwnerSection = (props: OwnerSectionProps): JSX.Element => {
  return (
    <Section
      handleNavigation={props.handleNavigation}
      isSectionComplete={props.isSectionComplete}
      sectionTitle="Owner Information"
      sectionTestid="owner-information-section"
    >
      <div>
        {props.submissionFields.map((owner, index) => {
          return (
            <div className="overview-subform" key={owner.objectUuid}>
              <SubformSection
                label={`Owner ${index + 1}`}
                showCloseButton={false}
                onClick={() => {
                  // Intentionally empty
                  // this was already like this when we turned on 'no-empty-function' rule
                  // we've left it alone, but this might be supposed to actually do
                  // something on click
                }}
                needsConfirm={false}
              >
                <div className="overview-form-row">
                  {owner.fields.map((field) => {
                    return (
                      <div key={field.id}>
                        <FormRow
                          left={
                            field.left ? (
                              <Field
                                fieldName={field.left.name}
                                valueName={field.left.value}
                                isValid={field.left.isValid}
                                shouldBeMasked={field.left.shouldBeMasked}
                                handleSendLogs={props.handleSendLogs}
                                objectUuid={owner.objectUuid}
                              />
                            ) : (
                              <></>
                            )
                          }
                          right={
                            <Field
                              fieldName={field.right?.name}
                              valueName={field.right?.value}
                              isValid={!!field.right?.isValid}
                              shouldBeMasked={field.right?.shouldBeMasked}
                              handleSendLogs={props.handleSendLogs}
                              objectUuid={owner.objectUuid}
                            />
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </SubformSection>
            </div>
          );
        })}
      </div>
    </Section>
  );
};
