import { useCallback } from 'react';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  UsePullRelativity6IndustryPredictionArgs,
  useApiPullRelativity6IndustryPrediction,
} from 'apiHooks/3pi/relativity6FetchHooks';
import { Relativity6IndustryPredictionResponseBody } from 'types/api/3pi/types';
import {
  DeclineDriversResponse,
  IndustryNaicsResponse,
} from 'types/api/underwriting/types';
import { useApiIndustryNaicsIndustryId } from 'apiHooks/underwriting/relativity6FetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useApiUpdateApplication } from 'apiHooks/waitingRoom/applicationFetchHooks';
import { ApplicationResponseOnboardingStatus } from 'types/api/waitingRoom/types';
import { useGetApiDeclineDrivers } from 'apiHooks/underwriting/declineDriversFetchHooks';
import { PullRelativity6IndustryPredictionResponse } from './Wizard.types';

export type UsePullRelativity6IndustryPredictionResponse = [
  (args: PullRelativity6IndustryPredictionArgs) => Promise<MutationResponse>,
  {
    data?: PullRelativity6IndustryPredictionResponse;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

type PullRelativity6IndustryPredictionArgs = {
  body: UsePullRelativity6IndustryPredictionArgs;
};

const toRelativity6IndustryPredictionAttributes = (
  prediction: Relativity6IndustryPredictionResponseBody
): PullRelativity6IndustryPredictionResponse => {
  return {
    id: prediction.id,
    naicsVersion: prediction.naics_version,
    industryPredictions: prediction.industry_predictions.map((p) => ({
      industryName: p.industry_name,
      naicsCode: p.naics_code,
      confidenceScore: p.confidence_score,
      rank: p.rank,
    })),
    webPresences: prediction.web_presences,
  };
};

export const usePullRelativity6IndustryPrediction =
  (): UsePullRelativity6IndustryPredictionResponse => {
    const [pullFn, { data, error, responseReady, ...rest }] =
      useApiPullRelativity6IndustryPrediction();

    const pullFunction = useCallback(
      async (
        args: PullRelativity6IndustryPredictionArgs
      ): Promise<MutationResponse> => {
        return pullFn(args.body);
      },
      [pullFn]
    );

    return [
      pullFunction,
      {
        data: data
          ? toRelativity6IndustryPredictionAttributes(data)
          : undefined,
        error,
        responseReady,
        ...rest,
      },
    ];
  };

type IndustryNaicsResponseBody = {
  industryId: string;
};

const toIndustryNaicsIndustryId = (
  data?: IndustryNaicsResponse
): IndustryNaicsResponseBody | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    industryId: data.industry_id,
  };
};

export const useGetIndustryNaicsIndustryId = (
  naicsCode?: string,
  naicsVersion?: '2017' | '2022'
): UseGenericQueryResponse<IndustryNaicsResponseBody> => {
  return useGenericFeatureQuery(
    useApiIndustryNaicsIndustryId,
    toIndustryNaicsIndustryId,
    naicsCode,
    naicsVersion
  );
};

export type UpdateApplicationBody = {
  applicationUuid: string;
  onboardingAnalystUuid?: string;
  onBoardingStatus?: ApplicationResponseOnboardingStatus;
  submissionUuid?: string;
};

type UseUpdateApplicationResult = [
  (input: UpdateApplicationBody) => Promise<MutationResponse>,
  { error?: Error; loading: boolean }
];

export const useUpdateApplication = (): UseUpdateApplicationResult => {
  const [updateApplication, { error, loading }] = useApiUpdateApplication();

  const updateFunction = (
    args: UpdateApplicationBody
  ): Promise<MutationResponse> => {
    return updateApplication({
      applicationUuid: args.applicationUuid,
      updateBody: {
        onboarding_analyst_uuid: args.onboardingAnalystUuid,
        onboarding_status: args.onBoardingStatus,
        uw_application_uuid: args.applicationUuid,
        submission_uuid: args.submissionUuid,
      },
      source: 'underwriting',
    });
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

const toDeclineDriversArray = (
  declineDrivers?: DeclineDriversResponse
): string[] => {
  if (!declineDrivers) {
    return [];
  }

  return declineDrivers?.data.map((declineDriver) => declineDriver.value);
};

export const useDeclineDrivers = (): UseGenericQueryResponse<string[]> =>
  useGenericFeatureQuery(useGetApiDeclineDrivers, toDeclineDriversArray);
