/**
 * As these are used more widely, they should be moved
 * higher in the directory structure. Starting them
 * here because they're only used here at the moment
 * and we have additional cleanup to do
 *
 * @tyrelosaur - Jan 31, 2022
 */
export const PROGRAM_STARTER = 'Starter';
export const PROGRAM_CORE = 'Core';
export const PROGRAM_STANDARD = 'Standard';
export const PROGRAM_LOW_RISK = 'Low Risk';
export const PROGRAM_PREMIUM = 'Premium';

export const PROGRAMS = [
  PROGRAM_STARTER,
  PROGRAM_CORE,
  PROGRAM_STANDARD,
  PROGRAM_LOW_RISK,
  PROGRAM_PREMIUM,
];
