import React from 'react';

import {
  Box,
  Flex,
  Heading,
  Icon,
  PageContainer,
  Tabs,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import {
  LexisNexisPersonalPublicDocument,
  PublicRecordsOverview,
} from '../types';
import { PublicRecords } from './PublicRecords';

export interface PublicRecordsPageProps {
  publicRecord: PublicRecordsOverview | null;
  ownerPublicDocuments?: LexisNexisPersonalPublicDocument[];
}
export const PublicRecordsPage = ({
  publicRecord,
  ownerPublicDocuments,
}: PublicRecordsPageProps): JSX.Element => {
  return (
    <PageContainer>
      <Heading>Public Records</Heading>
      {!ownerPublicDocuments || ownerPublicDocuments?.length === 0 ? (
        <Text>There are no public records</Text>
      ) : (
        <Tabs defaultValue={ownerPublicDocuments[0]?.documentId?.toString()}>
          <Tabs.List>
            {ownerPublicDocuments.map((doc) => (
              <Tabs.Trigger
                key={doc.documentId}
                value={doc.documentId.toString()}
              >
                <Flex gap={2}>
                  {doc.primary && <Icon name="flag" />}
                  {doc.individualBestName
                    ? doc.individualBestName
                    : `No Name available`}{' '}
                  - {formatDateString(doc.createdAt)}
                </Flex>
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {ownerPublicDocuments.map((doc) => (
            <Tabs.Content
              key={doc.documentId}
              value={doc.documentId.toString()}
            >
              <Box>
                <PublicRecords
                  publicRecord={publicRecord}
                  bankruptcies={doc.bankruptcies}
                  liens={doc.lienJudgments}
                />
              </Box>
            </Tabs.Content>
          ))}
        </Tabs>
      )}
    </PageContainer>
  );
};
