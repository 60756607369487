import React from 'react';

import { Box, PageContainer, Text } from '@forward-financing/fast-forward';
import { LexisNexisPersonalPublicDocument } from './types';
import { CriminalFilingsLinkPreviewTable } from './CriminalFilingsLinkPreviewTable';

export type CriminalFilingsLinkPreviewPageProps = {
  ownerPublicDocument?: LexisNexisPersonalPublicDocument;
};

export const CriminalFilingsLinkPreviewPage = ({
  ownerPublicDocument,
}: CriminalFilingsLinkPreviewPageProps): JSX.Element => {
  return (
    <PageContainer>
      {ownerPublicDocument ? (
        <Box backgroundColor="gray-200" padding={1}>
          <CriminalFilingsLinkPreviewTable
            criminalFilings={ownerPublicDocument.criminals}
          />
        </Box>
      ) : (
        <Text>There are no criminal filings</Text>
      )}
    </PageContainer>
  );
};
