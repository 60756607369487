import React from 'react';
import { Table, Tabs, Text } from '@forward-financing/fast-forward';
import { AchReturnCode } from '../achReturns.types';

export interface ReturnCodeTablesProps {
  codes?: AchReturnCode[];
  responseReady: boolean;
}

export const ReturnCodeTables = ({
  codes,
  responseReady,
}: ReturnCodeTablesProps): JSX.Element => {
  if (!responseReady) {
    return <Text>Loading return codes...</Text>;
  }

  return (
    <Tabs defaultValue="external">
      <Tabs.List>
        <Tabs.Trigger value="external">External Return Codes</Tabs.Trigger>
        <Tabs.Trigger value="internal">Internal Return Codes</Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="external">
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Return Code</Table.ColumnHeader>
            <Table.ColumnHeader>Description</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {codes && codes.length > 0 ? (
              codes?.map(
                (code) =>
                  !code.internal && (
                    <Table.Row key={code.code}>
                      <Table.Cell>{code.code}</Table.Cell>
                      <Table.Cell columnWidth="large" overflowStrategy="wrap">
                        {code.description}
                      </Table.Cell>
                    </Table.Row>
                  )
              )
            ) : (
              <Text>No return codes found.</Text>
            )}
          </Table.Body>
        </Table>
      </Tabs.Content>

      <Tabs.Content value="internal">
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Return Code</Table.ColumnHeader>
            <Table.ColumnHeader>Description</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {codes && codes.length > 0 ? (
              codes?.map(
                (code) =>
                  code.internal && (
                    <Table.Row key={code.code}>
                      <Table.Cell>{code.code}</Table.Cell>
                      <Table.Cell>{code.description}</Table.Cell>
                    </Table.Row>
                  )
              )
            ) : (
              <Text>No return codes found.</Text>
            )}
          </Table.Body>
        </Table>
      </Tabs.Content>
    </Tabs>
  );
};
