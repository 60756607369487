import React from 'react';
import { Box, Card } from '@forward-financing/fast-forward';
import { InternalLink } from 'components/shared/InternalLink';

interface LivePipelineQuickViewBoxProps {
  underwriterView: boolean;
  currentUserUnderwriter?: boolean;
}

export const LivePipelineQuickViewBox = ({
  currentUserUnderwriter,
  underwriterView,
}: LivePipelineQuickViewBoxProps): JSX.Element => {
  return (
    <Box margin={2}>
      <Card title="Quick Views">
        {currentUserUnderwriter && (
          <Box>
            <InternalLink
              reloadDocument
              to={`/dashboard/live-${
                underwriterView ? '' : 'underwriting-'
              }pipeline`}
            >
              {underwriterView ? 'Live Pipeline' : 'Live Underwriting Pipeline'}
            </InternalLink>
          </Box>
        )}
        <Box>
          <InternalLink to="/dashboard/applications">
            Submission Search
          </InternalLink>
        </Box>
      </Card>
    </Box>
  );
};
