import React, { useState } from 'react';

import { Account, UsState } from '../../../api/UnderwritingClient/codecs';
import {
  dropDownStates,
  obtainValidZipCodeEvent,
} from '../../../helpers/utils';

import {
  addressSpecialCharValidationMessage,
  validate,
  validateFein,
  validatePhone,
  validateZipCode,
  validationRecord,
  validField,
} from '../../../helpers/validations/FieldValidator';
import {
  isRequired,
  addressHasNoSpecialChar,
} from '../../../helpers/validations/ValidationHelpers';

// Components
import { BorderedContainer } from '../../shared/BorderedContainer';
import { FormRow } from '../../shared/form/FormRow';
import { Input } from '../../shared/form/Input';
import { Button, Loading } from '@forward-financing/fast-forward';
import { Dropdown } from '../../shared/form/Dropdown';
import { HTMLFormChangeOrMouseEvent } from '../../../types/form';
import { WizardSteps } from '../utils/types';
import { FederalTaxIdPopover } from './FederalTaxIdPopover';
import { GoogleZipCodeLookup } from 'components/shared/form/GoogleZipCodeLookup';

// The forPrequal prop is used here to indicate whether the component is being rendered for the Prequal Portal
// or UW Editing. Certain child components will rendered or not based on the context.
export interface AccountInformationFormProps {
  partner_id: number;
  account: Account;
  usStates: UsState[];
  handleAccountChange: (e: HTMLFormChangeOrMouseEvent) => void;
  handleAccountAddressChange: (e: HTMLFormChangeOrMouseEvent) => void;
  onSubmit: () => void;
  forPrequal: boolean;
  setNextStep?: () => void;
  currentStep?: WizardSteps;
}

export const AccountInformationForm = (
  props: AccountInformationFormProps
): JSX.Element => {
  /**
   * This is using a pretty weird pattern to see if we've changed
   * some specific values. We're disabling this ESLint rule here in
   * order to turn the rule on fully, but please please please look
   * for an alternate solution to this. Specifically, the recommended
   * pattern would be to store the response from the network request in
   * the parent component, and then either explicitly track whether an input
   * has been changed (see Deal Scoring for an example of this pattern),
   * or to compare the new and old values directly in the parent, which
   * (in this case) is the component responsible for managing state.
   *
   * @tyrelosaur - Jan 11, 2023
   */
  // eslint-disable-next-line react/hook-use-state
  const [accountData] = useState(props.account);
  // eslint-disable-next-line react/hook-use-state
  const [partnerId] = useState(props.partner_id);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setNextStep = () => {
      // Intentionally empty - default function should have void return type
    },
  } = props;
  const dbaName = props.account.dbaSameAsLegalName
    ? props.account.legal_name
    : props.account.name;

  const validationErrors = validationRecord({
    legal_name: validate([isRequired], props.account.legal_name),
    name: validate([isRequired], dbaName),
    phone: props.account.phoneNotPresent
      ? validField
      : validatePhone(props.account.phone) || '',

    fein: props.account.feinNotPresent
      ? validField
      : validateFein(props.account.fein || ''),
    street1: validate(
      [addressHasNoSpecialChar, isRequired],
      props.account.address.street1,
      `Street Address ${addressSpecialCharValidationMessage}`
    ),
    city: validate(
      [addressHasNoSpecialChar, isRequired],
      props.account.address.city,
      `City ${addressSpecialCharValidationMessage}`
    ),
    state: validate([isRequired], props.account.address.state),
    zip: validateZipCode(props.account.address.zip),
  });

  const isValidAccount = Object.values(validationErrors).every(
    (result) => result?.hasValue && result.isValid
  );

  const handleSubmit = (e: React.MouseEvent): void => {
    e.preventDefault();

    if (isValidAccount) {
      setIsLoading(true);
      hasToSearchRecords ? props.onSubmit() : setNextStep();
    }
  };

  const changeDetected = (): boolean => {
    return (
      Object.entries(accountData).toString() !==
        Object.entries(props.account).toString() ||
      Object.entries(accountData.address).toString() !==
        Object.entries(props.account.address).toString() ||
      partnerId !== props.partner_id
    );
  };

  const hasToSearchRecords =
    changeDetected() || props.currentStep === WizardSteps.AccountInformation;

  const handleDBASameAsLegalName = (e: HTMLFormChangeOrMouseEvent): void => {
    // Setting the value of dbaSameAsLegalName to true or false
    props.handleAccountChange(e);

    const target = (
      e.currentTarget ? e.currentTarget : e.target
    ) as HTMLInputElement;

    let syntheticEvent = {
      persist: () => {
        // Intentionally empty
      },
      target: {
        name: 'name',
        value: '',
      },
    };

    // Setting the DBA to the same value as the legal name if the checkbox is checked
    if (target.checked) {
      syntheticEvent = {
        persist: () => {
          // Intentionally empty
        },
        target: {
          name: 'name',
          value: props.account.legal_name || '',
        },
      };
    }

    props.handleAccountChange(syntheticEvent as HTMLFormChangeOrMouseEvent);
  };

  const handleLegalNameChange = (e: HTMLFormChangeOrMouseEvent): void => {
    props.handleAccountChange(e);

    const target = (
      e.currentTarget ? e.currentTarget : e.target
    ) as HTMLInputElement;

    // Setting the DBA to the same value as the legal name if the checkbox is checked
    // and legal has changed
    if (props.account.dbaSameAsLegalName) {
      const syntheticEvent = {
        persist: () => {
          // Intentionally empty
        },
        target: {
          name: 'name',
          value: target.value,
        },
      };

      props.handleAccountChange(syntheticEvent as HTMLFormChangeOrMouseEvent);
    }
  };

  const handleNotPresentFieldsChange = (
    e: HTMLFormChangeOrMouseEvent
  ): void => {
    props.handleAccountChange(e);

    const target = (
      e.currentTarget ? e.currentTarget : e.target
    ) as HTMLInputElement;
    const fieldToClear = target.name === 'phoneNotPresent' ? 'phone' : 'fein';

    if (target.checked) {
      const syntheticEvent = {
        persist: () => {
          // Intentionally empty
        },
        target: {
          name: fieldToClear,
          value: '',
        },
      };

      props.handleAccountChange(syntheticEvent as HTMLFormChangeOrMouseEvent);
    }
  };

  const handleFEINvalue = (): string => {
    return props.account.feinNotPresent ? '' : props.account.fein || '';
  };

  const handlePhonevalue = (): string => {
    return props.account.phoneNotPresent ? '' : props.account.phone || '';
  };

  const autofillZipFields = (
    fields: {
      city?: string;
      state?: string;
      street1?: string;
      zip?: string;
    } = {}
  ): void => {
    Object.entries(fields)
      .filter(([name]) => ['city', 'state', 'zip'].includes(name))
      .forEach(([name, value]) => {
        const syntheticEvent = {
          persist: () => {
            // Intentionally empty
          },
          target: { name, value },
        };

        props.handleAccountAddressChange(
          syntheticEvent as HTMLFormChangeOrMouseEvent
        );
      });
  };

  const handleZipCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const event = obtainValidZipCodeEvent(e);
    props.handleAccountAddressChange(event);
  };

  const getButtonText = (): string => {
    if (hasToSearchRecords) {
      return 'Search Existing Customers';
    }

    return `Back${
      props.currentStep ? ` to ${WizardSteps[props.currentStep]}` : ''
    }`;
  };

  return (
    <div className="account-information-form">
      <BorderedContainer label="Account Information">
        <FormRow
          left={
            <Input
              label="Legal Name"
              name="legal_name"
              onChange={handleLegalNameChange}
              validationResult={validationErrors.legal_name}
              value={props.account.legal_name || ''}
            />
          }
          right={
            <Input
              label="Street Address"
              name="street1"
              onChange={props.handleAccountAddressChange}
              validationResult={validationErrors.street1}
              value={props.account.address.street1}
            />
          }
        />
        <FormRow
          left={
            <Input
              label="DBA"
              name="name"
              onChange={props.handleAccountChange}
              required={true}
              checkBox={{
                label: '"DBA" same as Legal Name',
                name: 'dbaSameAsLegalName',
                value: props.account.dbaSameAsLegalName || false,
                checkboxLabelSize: 'is-half',
                onClick: handleDBASameAsLegalName,
              }}
              validationResult={validationErrors.name}
              value={dbaName || ''}
              isDisabled={props.account.dbaSameAsLegalName === true}
            />
          }
          right={
            <Input
              label="City"
              name="city"
              onChange={props.handleAccountAddressChange}
              validationResult={validationErrors.city}
              value={props.account.address.city}
            />
          }
        />
        <FormRow
          left={
            <Input
              label="Phone"
              name="phone"
              leadingIcon="fas fa-phone"
              inputMask="(999)999-9999"
              onChange={props.handleAccountChange}
              checkBox={
                props.forPrequal || !accountData.phone
                  ? {
                      label: 'Phone not on application',
                      name: 'phoneNotPresent',
                      value: !!props.account.phoneNotPresent,
                      checkboxLabelSize: 'is-half',
                      onClick: handleNotPresentFieldsChange,
                    }
                  : undefined
              }
              placeholder="(999)999-9999"
              validationResult={validationErrors.phone}
              value={handlePhonevalue()}
              isDisabled={props.account.phoneNotPresent}
            />
          }
          right={
            <Dropdown
              label="State"
              name="state"
              options={dropDownStates(props.usStates)}
              onChange={props.handleAccountAddressChange}
              validationResult={validationErrors.state}
              value={props.account.address.state}
            />
          }
        />
        <FormRow
          left={
            <Input
              label="Federal Tax ID"
              name="fein"
              inputMask="99-9999999"
              onChange={props.handleAccountChange}
              placeholder="99-9999999"
              moreInfoPopover={<FederalTaxIdPopover />}
              checkBox={{
                label: '"FEIN" not on application',
                name: 'feinNotPresent',
                value: !!props.account.feinNotPresent,
                checkboxLabelSize: 'is-half',
                onClick: handleNotPresentFieldsChange,
              }}
              value={handleFEINvalue()}
              validationResult={validationErrors.fein}
              isDisabled={props.account.feinNotPresent}
            />
          }
          right={
            <GoogleZipCodeLookup
              handleAutofill={autofillZipFields}
              label="Zip Code"
              onChange={(newValue: string) => {
                handleZipCodeChange({
                  persist: () => {
                    // intentionally empty
                    // just trying to provide enough interface that we don't crash the page
                  },
                  target: {
                    name: 'zip',
                    value: newValue,
                  },
                } as React.ChangeEvent<HTMLInputElement>);
              }}
              placeholder="5 or 9-digit Zip Code"
              usStates={props.usStates}
              value={props.account.address?.zip || ''}
            />
          }
        />
      </BorderedContainer>
      <div className="account-navigation-actions columns">
        <div className="column has-text-right">
          {isLoading ? (
            <Loading size="small" />
          ) : (
            <Button
              startIcon={hasToSearchRecords ? 'search' : undefined}
              disabled={!isValidAccount}
              onClick={handleSubmit}
            >
              {getButtonText()}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
