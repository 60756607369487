import React from 'react';
import {
  Combobox,
  TextInput,
  Subheading,
  Grid,
  Select,
  DatePicker,
  PlainDate,
} from '@forward-financing/fast-forward';
import { AttachmentSource } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';

export interface SingleAttachmentFormState {
  name: string;
  tags: string[];
  internalComments: string;
  location: AttachmentSource | undefined;
  expiresOn?: PlainDate;
}

export interface EditAttachmentFormProps {
  fileName: string;
  formState: SingleAttachmentFormState;
  onChange: (newState: SingleAttachmentFormState) => void;
}

export const EditAttachmentForm = (
  props: EditAttachmentFormProps
): JSX.Element => {
  const { documentTags } = useAttachmentManagerContext();

  return (
    <>
      <Subheading variant="section">{props.fileName}</Subheading>
      <Grid gutter>
        <Grid.Item m={8}>
          <TextInput
            label="Document Name"
            value={props.formState.name}
            onValueChange={(newVal) => {
              props.onChange({
                ...props.formState,
                name: newVal,
              });
            }}
          />
        </Grid.Item>
        <Grid.Item m={4}>
          <Select
            label="Location"
            value={props.formState.location ?? ''}
            onValueChange={(newVal) => {
              props.onChange({
                ...props.formState,
                location:
                  newVal === '' ? undefined : (newVal as AttachmentSource),
              });
            }}
            options={[
              {
                value: 'original_submission',
                text: 'Original Submission',
              },
              {
                value: 'closing_documents',
                text: 'Closing Documents',
              },
              {
                value: 'other',
                text: 'Other Documents',
              },
            ]}
          />
        </Grid.Item>
        <Grid.Item m={8}>
          <Combobox
            closeMenuOnSelect={false}
            isMulti
            label="Document Tags"
            placeholder="Document Tags"
            values={props.formState.tags.map((tag) => ({
              value: tag,
              text: documentTags[tag],
            }))}
            onValueChange={(newVals) => {
              props.onChange({
                ...props.formState,
                tags: newVals.map((val) => val.value),
              });
            }}
            options={Object.entries(documentTags).map(([key, pretty]) => ({
              value: key,
              text: pretty,
            }))}
          />
        </Grid.Item>
        <Grid.Item m={4}>
          <TextInput
            label="Internal Comments"
            value={props.formState.internalComments}
            onValueChange={(newVal) => {
              props.onChange({
                ...props.formState,
                internalComments: newVal,
              });
            }}
            errorMessage={
              props.formState.tags.includes('other') &&
              props.formState.internalComments === ''
                ? 'Comments are required if attachment has "Other" tag'
                : undefined
            }
          />
        </Grid.Item>
        {props.formState.tags.includes('drivers_license') && (
          <Grid.Item m={4}>
            <DatePicker
              canSelectTime={false}
              label="Drivers License Expiration Date"
              selected={props.formState.expiresOn}
              onChange={(newVal) => {
                props.onChange({
                  ...props.formState,
                  expiresOn: newVal,
                });
              }}
              errorMessage={
                props.formState.expiresOn
                  ? undefined
                  : 'Expiry date is required if attachment has "Drivers License" tag'
              }
              showDropdownCalendarPicker
            />
          </Grid.Item>
        )}
      </Grid>
    </>
  );
};
