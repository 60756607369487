import React from 'react';
import { StickyContainer } from '@forward-financing/fast-forward';
import { useCustomer, useOwners } from './applicationSummaryFetchHooks';
import { ApplicationSummary } from './ApplicationSummary';
import { ApplicationSummarySubmission } from './applicationSummary.types';

export interface ApplicationSummaryContainerProps {
  submission: ApplicationSummarySubmission | undefined;
}

export const ApplicationSummaryContainer = (
  props: ApplicationSummaryContainerProps
): JSX.Element => {
  const { customerUuid, ownerUuids } = props.submission || {};

  const {
    customer,
    error: errorCustomer,
    loading: loadingCustomer,
  } = useCustomer(customerUuid);

  const {
    owners,
    error: errorOwners,
    loading: loadingOwners,
  } = useOwners(ownerUuids || []);

  const isNonEmptyMessage = (message?: string): message is string =>
    typeof message === 'string' && message.length > 0;

  const errors = [errorCustomer?.message, errorOwners?.message].filter(
    isNonEmptyMessage
  );
  const loading = loadingCustomer || loadingOwners;

  return (
    <StickyContainer stickTo="top">
      <ApplicationSummary
        customer={customer}
        owners={owners}
        submission={props.submission}
        errorMessages={errors}
        loading={loading}
      />
    </StickyContainer>
  );
};
