import { useCallback } from 'react';
import {
  assignNextApplication,
  fetchSubmissionOnboardingQueueApplication,
  fetchSubmissionOnboardingQueueApplications,
  patchApplication,
} from 'api/waitingRoom/applicationFetchUtils';
import {
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
  useGenericMutation,
  useGenericQuery,
  useLazyGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  ApplicationResponse,
  ApplicationQueueItemResponse,
  SubmissionOnboardingQueueResponse,
  UpdateApplicationRequestBody,
} from 'types/api/waitingRoom/types';

type UseLazyGetApiApplicationQueueArgs = {
  applicationStatus: string;
  page: number;
  search?: string;
};

export type UseLazyGetApiApplicationQueueRefresher =
  UseLazyGenericQueryFetcher<UseLazyGetApiApplicationQueueArgs>;

export type UseLazyGetApiApplicationQueueResponse =
  UseLazyGenericQueryResult<SubmissionOnboardingQueueResponse>;

const fetchApplicationQueueWithArgs = (
  args: UseLazyGetApiApplicationQueueArgs
): Promise<SubmissionOnboardingQueueResponse> =>
  fetchSubmissionOnboardingQueueApplications(
    args.applicationStatus,
    args.page,
    args.search
  );

export type UseApiApplicationResponse =
  UseGenericQueryResponse<ApplicationQueueItemResponse>;

export const useApiGetApplication = (
  applicationUuid?: string
): UseApiApplicationResponse => {
  const fetchData = useCallback(() => {
    if (!applicationUuid) {
      return undefined;
    }

    return fetchSubmissionOnboardingQueueApplication(applicationUuid);
  }, [applicationUuid]);

  return useGenericQuery(fetchData);
};

export const useLazyGetApiApplicationQueue = (): [
  UseLazyGetApiApplicationQueueRefresher,
  UseLazyGetApiApplicationQueueResponse
] => {
  return useLazyGenericQuery(fetchApplicationQueueWithArgs);
};

export type UseAssignNextApplicationArgs = {
  onboardingAnalystUuid: string | null;
  applicationUuid?: string;
};

export type UseAssignNextApplicationResponse = UseGenericMutationResponse<
  ApplicationQueueItemResponse,
  UseAssignNextApplicationArgs
>;

const assignNextApplicationForUser = async (
  body: UseAssignNextApplicationArgs
): Promise<ApplicationQueueItemResponse> => {
  return assignNextApplication({
    onboarding_analyst_uuid: body.onboardingAnalystUuid,
    application_uuid: body.applicationUuid,
  });
};

export const useApiAssignNextApplication =
  (): UseAssignNextApplicationResponse => {
    return useGenericMutation(assignNextApplicationForUser);
  };

export interface UseUpdateApplicationArgs {
  applicationUuid: string;
  updateBody: UpdateApplicationRequestBody;
  source?: 'underwriting';
}

export const updateApplication = (
  input: UseUpdateApplicationArgs
): Promise<ApplicationResponse> => {
  return patchApplication(
    input.applicationUuid,
    input.updateBody,
    input.source
  );
};

export type UseUpdateApplicationResponse = UseGenericMutationResponse<
  ApplicationResponse,
  UseUpdateApplicationArgs
>;

export const useApiUpdateApplication = (): UseUpdateApplicationResponse => {
  return useGenericMutation(updateApplication);
};
