import { LedgerBuyRates, BuyRates, MonthRate } from './buyRates.types';

/**
 * Checks if the provided key is a valid key of the LedgerBuyRates type.
 *
 * @param key - The key to check.
 * @returns A boolean indicating whether the key is a valid key of LedgerBuyRates.
 */

export const isLedgerBuyRatesKey = (key: string): key is keyof LedgerBuyRates =>
  key in
  {
    buyRate2Months: '',
    buyRate3Months: '',
    buyRate4Months: '',
    buyRate5Months: '',
    buyRate6Months: '',
    buyRate7Months: '',
    buyRate8Months: '',
    buyRate9Months: '',
    buyRate10Months: '',
    buyRate11Months: '',
    buyRate12Months: '',
  };

/**
 * Type guard function to check if a value is of type `BuyRates`.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a `BuyRates` object, otherwise `false`.
 */
export const isBuyRates = (value: unknown): value is BuyRates => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const buyRates = value as BuyRates;

  if (
    !Array.isArray(buyRates.buyRateTable) ||
    !Array.isArray(buyRates.totalFactorRateTable)
  ) {
    return false;
  }

  if (typeof buyRates.maxFactorRate !== 'number') {
    return false;
  }

  return true;
};

/**
 * Retrieves a specific buy rate from an array of buy rates based on the number of months.
 *
 * @param {MonthRate[]} buyRates - An array of buy rates.
 * @param {number} months - The number of months to match against the buy rates.
 * @returns {MonthRate | undefined} - The buy rate that matches the specified number of months, or undefined if no match is found.
 */
export const getBuyRateByMonths = (
  buyRates: MonthRate[],
  months: number
): MonthRate | undefined => {
  return buyRates.find((rate) => rate.months === months.toString());
};

/**
 * Converts the given buy rates to ledger buy rates.
 *
 * @param {BuyRates} buyRates - The buy rates to convert.
 * @returns {LedgerBuyRates} The converted ledger buy rates.
 */
export const toLedgerBuyRates = (buyRates: BuyRates): LedgerBuyRates => {
  return {
    buyRate2Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      2
    )?.rate.toString(),
    buyRate3Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      3
    )?.rate.toString(),
    buyRate4Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      4
    )?.rate.toString(),
    buyRate5Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      5
    )?.rate.toString(),
    buyRate6Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      6
    )?.rate.toString(),
    buyRate7Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      7
    )?.rate.toString(),
    buyRate8Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      8
    )?.rate.toString(),
    buyRate9Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      9
    )?.rate.toString(),
    buyRate10Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      10
    )?.rate.toString(),
    buyRate11Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      11
    )?.rate.toString(),
    buyRate12Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      12
    )?.rate.toString(),
    buyRate13Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      13
    )?.rate.toString(),
    buyRate14Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      14
    )?.rate.toString(),
    buyRate15Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      15
    )?.rate.toString(),
    buyRate16Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      16
    )?.rate.toString(),
    buyRate17Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      17
    )?.rate.toString(),
    buyRate18Months: getBuyRateByMonths(
      buyRates.buyRateTable,
      18
    )?.rate.toString(),
  };
};

export const formatRate = (rate?: number): string => {
  if (!rate) {
    return '-';
  }

  return rate.toFixed(3);
};
