import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { DeclineReasonsResponse } from 'types/api/banking/types';

export const fetchExceptionRequestDeclineReasons =
  async (): Promise<DeclineReasonsResponse> => {
    const url = new URL('/api/v2/exception_decline_reasons', BANK_BASE_URL());

    const response = await makeInternalAPIRequest<DeclineReasonsResponse>(
      url,
      'GET'
    );

    if (!response.ok) {
      throw new NetworkError(
        response.status,
        'Failed to fetch exception request decline reasons'
      );
    }

    return response.json();
  };
