import React from 'react';
import { Box, Grid } from '@forward-financing/fast-forward';
import { LienJudgmentRecord } from 'components/LiensTable/types';
import { categorizeLiensJudgments } from 'components/LiensTable/liensTableUtils';
import { LiensTable } from 'components/LiensTable/LiensTable';
import { BankruptcyRecord, PublicRecordsOverview } from '../types';
import { PublicRecordsSummary } from '../PublicRecordsSummary';
import { Bankruptcies } from './Bankruptcies';

export interface PublicRecordsProps {
  publicRecord: PublicRecordsOverview | null;
  bankruptcies: BankruptcyRecord[];
  liens: LienJudgmentRecord[];
}

export const PublicRecords = (props: PublicRecordsProps): JSX.Element => {
  const { publicRecord } = props;

  const {
    liensOpen,
    liensReleased,
    liensStateOpen,
    liensStateReleased,
    liensFederalOpen,
    liensFederalReleased,
    judgmentsOpen,
    judgmentsSettled,
  } = categorizeLiensJudgments(props.liens);

  return (
    <Box margin={4} paddingBottom={4}>
      <Grid gutter>
        <Grid.Item xs={6}>
          <Bankruptcies bankruptcies={props.bankruptcies} />
        </Grid.Item>

        <Grid.Item xs={4}>
          <PublicRecordsSummary publicRecord={publicRecord} />
        </Grid.Item>

        <Grid.Item xs={2}> </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensOpen} title="Total Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensReleased} title="Total Liens - Released" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensStateOpen} title="State Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable
            data={liensStateReleased}
            title="State Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensFederalOpen} title="Federal Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable
            data={liensFederalReleased}
            title="Federal Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={judgmentsOpen} title="Judgments - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={judgmentsSettled} title="Judgments - Settled" />
        </Grid.Item>
      </Grid>
    </Box>
  );
};
