import React from 'react';
import { Section } from './Section';
import { FormRow } from '../../shared/form/FormRow';
import { Field } from './Field';
import { Row } from './OverviewPage';

export interface AdditionalInformationSectionProps {
  submissionFields: Row[];
  isSectionComplete: boolean;
  handleNavigation: () => void;
}

export const AdditionalInformationSection = (
  props: AdditionalInformationSectionProps
): JSX.Element => {
  return (
    <Section
      handleNavigation={props.handleNavigation}
      isSectionComplete={props.isSectionComplete}
      sectionTitle="Additional Account Information"
      sectionTestid="additional-information-section"
    >
      <div className="overview-form-row">
        {props.submissionFields.map((field) => {
          return (
            <div key={field.id}>
              <FormRow
                left={
                  field.left ? (
                    <Field
                      fieldName={field.left.name}
                      valueName={field.left.value}
                      isValid={field.left.isValid}
                      ariaLabel={field.left.ariaLabel}
                    />
                  ) : (
                    <></>
                  )
                }
                right={
                  field.right ? (
                    <Field
                      fieldName={field.right.name ? field.right.name : ''}
                      leadingIcon={field.right.leadingIcon}
                      valueName={field.right.value}
                      isValid={field.right.isValid}
                      ariaLabel={field.right.ariaLabel}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
          );
        })}
      </div>
    </Section>
  );
};
