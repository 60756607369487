import { useCallback } from 'react';
import { useDeepCompareMemoize } from './useDeepCompareMemoize';

/**
 * Hook similar to `useCallback` but with deep comparison on the
 * dependencies array. Useful when dependencies have nested fields.
 *
 * This hook checks for deep equality of dependencies. The callback
 * is not re-created if the actual values of dependencies haven't changed,
 * even if their references have.
 *
 * This is useful for optimizing the dependencies of React hooks to avoid
 * unintended renders caused by a new but equal value (e.g. object or array).
 *
 * DO NOT use this hook for simple types like number or string. Use the
 * standard `useCallback` hook instead.
 *
 * Note that for large objects or arrays, this hook can be expensive so use
 * it only after testing performance to understand if the benefits outweigh the
 * cost.
 *
 * @template T Type of the callback function. It extends a function that
 * takes any number of arguments and can return any value.
 * @param {T} callback - The callback function to be memoized.
 * @param {React.DependencyList} dependencies - A list of dependencies of
 * the callback that would trigger its re-creation if they change.
 * @returns {T} A memoized version of the callback that only changes if the
 * dependencies have changed based on a deep comparison.
 * @example
 * const memoizedCallback = useDeepCompareCallback(() => {
 *   // Your callback logic here
 * }, [obj, arr]); // `obj` and `arr` are dependencies this callback relies on.
 * @see the tests for more examples. Also see the tests for
 * `useDeepCompareMemoize` since this hook is trivially implemented and relies
 * almost entirely on the behavior of that hook.
 */
export function useDeepCompareCallback<
  T extends (...args: unknown[]) => unknown
>(callback: T, dependencies: React.DependencyList): T {
  const deps = useDeepCompareMemoize(dependencies);

  // Use the standard useCallback hook now that we have deeply compared
  // dependencies.
  return useCallback(callback, deps);
}
