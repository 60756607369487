import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import {
  THIRD_PARTY_INTEGRATIONS_BASE_URL,
  UNDERWRITING_BASE_URL,
} from 'constants/globals';
import { ExperianMetaDataResponse } from 'types/api/3pi/types';
import {
  CustomerResponse,
  LexisNexisCustomerExecutive,
  LexisNexisCustomerUcc,
  SOSFieldsResponse,
  UccBusinessReportResponse,
} from 'types/api/underwriting/types';
import {
  fetchLexisNexisCustomerReports,
  fetchLexisNexisCustomerOverviews,
} from 'api/underwriting/lexisNexisCustomerFetchUtils';
import {
  CustomerExecutive,
  CustomerBusinessSummary,
  ExperianMetaData,
  BusinessCustomerLexisNexisReport,
  CustomerUcc,
  BusinessCustomerLexisNexisOverview,
} from './businessOverview.types';

// Uncomment this line for mock data.
// import { mockLexisNexisCustomerOverview } from '../../../mocks/underwriting/generators/ApiV2SubmissionsUuidLexisNexisCustomerOverviews';

const toBusinessSummary = (bs: CustomerResponse): CustomerBusinessSummary => {
  return {
    uuid: bs.uuid,
    legalName: bs.legal_name,
    dba: bs.name,
    fein: bs.fein,
    fullAddress: bs.address,
    businessPhone: bs.phone,
    industry: bs.industry_name,
    industryId: bs.industry_id,
    businessStartDate: bs.started_on,
    restricted: bs.risk_profile === 'high_risk',
  };
};

// this returns data for the business summary table
export const fetchCustomer = async (
  customerUuid: string
): Promise<CustomerBusinessSummary> => {
  const url = new URL(
    `/api/v2/customers/${customerUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<CustomerResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Customer');
  }

  const data = await response.json();

  return toBusinessSummary(data);
};

export const fetchExperianMetaData = async (
  uuid: string,
  uuidType: string
): Promise<ExperianMetaData> => {
  const url = new URL(
    `/api/v1/experian/commercial/${uuid}?uuidType=${uuidType}&entities=meta`,
    THIRD_PARTY_INTEGRATIONS_BASE_URL()
  );

  const response = await makeInternalAPIRequest<ExperianMetaDataResponse>(
    url,
    'GET'
  );

  if (response.status === 404) {
    throw new NetworkError(response.status, 'Experian not pulled');
  }

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch experian meta data'
    );
  }

  const data = await response.json();
  return {
    fetchedAt: data.meta?.fetched_at,
  };
};

const lexisNexisRelatedExecToCustomerExecutive = (
  exec: LexisNexisCustomerExecutive
): CustomerExecutive => ({
  id: exec.unique_id,
  name: exec.name ? `${exec.name.first} ${exec.name.last}` : '',
  titles: Array.isArray(exec.company_titles.title)
    ? exec.company_titles.title
    : [exec.company_titles.title],
});

const lexisNexisCustomerUccToCustomerUcc = (
  ucc: LexisNexisCustomerUcc
): CustomerUcc => {
  let dateFiled = undefined;
  if (ucc.date_filed) {
    if (typeof ucc.date_filed === 'string') {
      const [month, day, year] = ucc.date_filed.split('/');

      dateFiled = `${year}-${month}-${day}`;
    } else if (
      ucc.date_filed.day &&
      ucc.date_filed.month &&
      ucc.date_filed.year
    ) {
      const year = ucc.date_filed.year;
      const month = ucc.date_filed.month.padStart(2, '0');
      const day = ucc.date_filed.day.padStart(2, '0');

      dateFiled = `${year}-${month}-${day}`;
    } else if (ucc.date_filed.year) {
      dateFiled = ucc.date_filed.year;
    }
  }

  return {
    securedParty: ucc.secured_party,
    dateFiled: dateFiled,
    collateralDescription: ucc.collateral_description ?? undefined,
    isExpired: ucc.is_expired,
    isReleased: ucc.is_released,
  };
};

export const fetchBusinessLexisNexisCustomerReports = async (
  submissionUuid: string
): Promise<BusinessCustomerLexisNexisReport[]> => {
  const data = await fetchLexisNexisCustomerReports(submissionUuid);

  return data.documents.map((doc) => ({
    submissionUuid: doc.submission_uuid,
    documentId: doc.document_id,
    createdAt: doc.created_at,
    executives: doc.executives.map(lexisNexisRelatedExecToCustomerExecutive),
    uccs: doc.uccs.map(lexisNexisCustomerUccToCustomerUcc),
    companyName: doc.company_name ?? undefined,
    address: doc.business_address
      ? {
          street1: `${doc.business_address.street_number} ${doc.business_address.street_name} ${doc.business_address.street_suffix}`,
          city: doc.business_address.city,
          state: doc.business_address.state,
          zip: doc.business_address.zip5,
        }
      : undefined,
    companyPhoneNumber: doc.company_phone ?? undefined,
    established: doc.established
      ? `${doc.established.year}-${doc.established.month.padStart(
          2,
          '0'
        )}-${doc.established.day.padStart(2, '0')}`
      : undefined,
    primary: doc.primary,
    reportIdentifier: doc.report_identifier ?? undefined,
  }));
};

export const fetchBusinessLexisNexisCustomerOverviews = async (
  submissionUuid: string
): Promise<BusinessCustomerLexisNexisOverview[]> => {
  // Uncomment this line and comment out the next line to use mock data.
  // const data = [mockLexisNexisCustomerOverview()];
  const data = await fetchLexisNexisCustomerOverviews(submissionUuid);

  return data.map(
    (doc): BusinessCustomerLexisNexisOverview => ({
      id: doc.id,
      documentId: doc.document_id,
      openBankruptcyCount: doc.open_bankruptcy_count,
      closedBankruptcyCount: doc.closed_bankruptcy_count,
      totalBankruptcyCount: doc.total_bankruptcy_count,
      openLienCount: doc.open_lien_count,
      closedLienCount: doc.closed_lien_count,
      totalLienCount: doc.total_lien_count,
      openJudgmentCount: doc.open_judgment_count,
      closedJudgmentCount: doc.closed_judgment_count,
      totalJudgmentCount: doc.total_judgment_count,
      openLienAmount: doc.open_lien_amount_cents / 100,
      closedLienAmount: doc.closed_lien_amount_cents / 100,
      totalLienAmount: doc.total_lien_amount_cents / 100,
      openJudgmentAmount: doc.open_judgment_amount_cents / 100,
      closedJudgmentAmount: doc.closed_judgment_amount_cents / 100,
      totalJudgmentAmount: doc.total_judgment_amount_cents / 100,
      mismatch: doc.mismatch
        ? doc.mismatch.map((value) => ({
            field: value.field,
            oldValue: value.old_value,
            newValue: value.new_value,
          }))
        : undefined,
    })
  );
};

export const fetchBusinessUccReport = async (
  submissionUuid: string,
  reportId: string
): Promise<UccBusinessReportResponse> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/lexis_nexis_customer_ucc_documents/${reportId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<UccBusinessReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Customer UCC Report'
    );
  }

  return response.json();
};

export const fetchSOSFields = async (
  submissionUuid: string
): Promise<SOSFieldsResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/sos_report`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SOSFieldsResponse>(url, 'GET');

  // The BE will be returning a 422 error when the submission is located in Delaware and we want to
  // tell users that SOS fields are not searched for those submission. Therefore, we will accept and
  // allow these responses.
  if (!response.ok && response.status !== 422) {
    throw new NetworkError(response.status, 'Failed to fetch SOS Fields');
  }

  return response.json();
};
