//TODO DELETE THIS FILE
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CreditDataResponse,
  LexisNexisOwnerSearchResultsResponse,
} from 'types/api/underwriting/types';
import { CreditReport, LexisNexisSearchResult } from './types';

// CAUTION - LOCAL DEV ENV ONLY: Uncomment these lines as needed for mock data.
// import { mockLexisNexisOwnerReportDocument } from 'mocks/underwriting/generators/ApiV2SubmissionsUuidDocumentsLexisNexisOwnerReports';
// import { mockLexisNexisResponse } from 'mocks/underwriting/generators/ApiV2SubmissionsUuidLexisNexisOwners';
// import { mockCreditDataResponse } from 'mocks/underwriting/generators/ApiV2SubmissionUuidCreditOverviews';

/**
 * This function is...bad. Very bad. I don't like doing it.
 *
 * But it's also pretty short lived, in theory.
 *
 * We're making multiple network requests here to mimic what the graphql
 * schema is doing, which is passing work phone down to the owner, despite
 * the fact that it lives on the Customer model.
 *
 * This way, we can blindly pass the result of this into the component that
 * will soon be using GraphQL, and can feature flag between them without risk.
 *
 * @param customerUuid
 * @returns
 */

export const toCreditReport = (response: CreditDataResponse): CreditReport => {
  return {
    id: response.id,
    ownerUuid: response.owner_uuid,
    fico: response.fico_score ?? undefined,
    bkPlus: response.bankruptcy_score,
    vantageScore: response.vantage_score,
    totalUnsecuredBalance: response.total_unsecured_balance,
    totalDebtToHighCredit: response.revolving_utilized_percent,
    totalTradeLines: response.trade_lines_count,
    totalSatisfactoryTradeLines: response.satisfactory_accounts_count,
    totalDelinquentTradeLines: response.now_delinquent_derog_count,
    newDelinquentTradeLines: response.derog_count,
    numberAccountsCurrentlyPayingOnTime: response.active_paid_accounts_count,
    publicRecordsCount: response.public_records_count,
    oldestTradeLine: response.oldest_open_trade_line,
    createdAt: response.created_at,
    creditReportExperianPath: response.credit_report_experian_path,
    experianArchivePath: response.experian_archive_path,
    tradeLinesPath: response.trade_lines_path,
    experianReportDate: response.experian_report_date,
    mismatch: response.mismatch
      ? response.mismatch.map((mismatch) => {
          return {
            field: mismatch.field,
            oldValue: mismatch.old_value,
            newValue: mismatch.new_value,
          };
        })
      : [],
    note: response.note ?? undefined,
  };
};

const toLexisNexisSearchResults = (
  results: LexisNexisOwnerSearchResultsResponse
): LexisNexisSearchResult[] => {
  if (!results.documents) {
    return [];
  }
  return results.documents
    .filter((document) => document.results)
    .map((document) => {
      return {
        ownerUuid: document.owner_uuid,
        results: document.results.map((result) => {
          return {
            fullName: result.full_name,
            ssn: result.ssn,
            dateOfBirth: result.date_of_birth?.map((dob) => {
              return {
                month: dob.month,
                year: dob.year,
              };
            }),
            addresses: result.addresses.map((address) => {
              return {
                city: address.city,
                zip: address.zip5,
                state: address.state,
                streetAddress: `${address.street_number || ''} ${
                  address.street_pre_direction || ''
                } ${address.street_name || ''} ${address.street_suffix || ''}`,
                unitNumber: address.unit_number,
                unitDesignation: address.unit_designation,
              };
            }),
            reportIdentifier: result.report_identifier,
          };
        }),
        mismatch: document.mismatch
          ? document.mismatch.map((mismatch) => {
              return {
                field: mismatch.field,
                oldValue: mismatch.old_value,
                newValue: mismatch.new_value,
              };
            })
          : [],
      };
    });
};

export const updateDocument = async (
  documentId: number,
  primary: boolean
): Promise<boolean> => {
  const url = new URL(
    `/api/v2/documents/${documentId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'PATCH',
    {
      primary: primary,
    }
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update document');
  }

  return true;
};

export const deleteDocument = async (documentId: number): Promise<boolean> => {
  const url = new URL(
    `/api/v2/documents/${documentId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'DELETE'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to delete document');
  }

  return true;
};

export const pullLexisNexis = async (
  submissionUuid: string,
  ownerUuid: string,
  force: boolean,
  reportId?: string
): Promise<boolean> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/lexis_nexis_owner_reports`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'POST',
    {
      force: force,
      report_id: reportId,
    }
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull Lexis Nexis');
  }

  return true;
};

export const fetchLexisNexisSearchResults = async (
  submissionUuid: string
): Promise<LexisNexisSearchResult[]> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/documents/lexis_nexis_owner_search_results`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<LexisNexisOwnerSearchResultsResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to pull Lexis Nexis search results'
    );
  }

  const result: LexisNexisOwnerSearchResultsResponse = await response.json();
  return toLexisNexisSearchResults(result);
};
