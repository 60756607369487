import React from 'react';
import { Flex } from '@forward-financing/fast-forward';
import { DropdownOption } from '../../../types/form/';
import { ValidationResult } from '../../../helpers/validations/codecs';

export interface DropdownProps {
  label?: string;
  options: DropdownOption[];
  name: string;
  textSize?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: number | string;
  validationResult?: ValidationResult;
  isDisabled?: boolean;
  moreInfoPopover?: React.ReactNode;
}

export const Dropdown = (props: DropdownProps): JSX.Element => {
  const textSize = props.textSize ? props.textSize : '';
  const isEmpty = props.validationResult && !props.validationResult.hasValue;
  const className = isEmpty ? 'is-danger' : '';
  const isDisabled = props.isDisabled || false;
  const renderValidationIcon = () => {
    return isEmpty ? (
      <span className="validation-icon">
        <i className="fas fa-exclamation-circle " />
      </span>
    ) : null;
  };

  return (
    <div className="field">
      {props.label ? (
        <div className="columns">
          <div className="column">
            <label className={`label ${textSize} has-text-weight-bold`}>
              <Flex>
                {renderValidationIcon()}
                {props.label}
                {props.moreInfoPopover}
              </Flex>
            </label>
          </div>
        </div>
      ) : null}

      <div className="control ">
        <div className={`select is-fullwidth ${className}`}>
          <select
            aria-label={props.label}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            disabled={isDisabled}
            data-testid={`select-${props.name}`}
          >
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
