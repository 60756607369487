import { useCallback } from 'react';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { RenewalComparisonsResponse } from 'types/api/funding/types';
import { fetchRenewalComparisons } from 'api/funding/renewalComparisonsFetchUtils';

export const useApiRenewalComparisons = (
  submissionUuid?: string
): UseGenericQueryResponse<RenewalComparisonsResponse> => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchRenewalComparisons(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export const useApiMultipleRenewalComparisons = (
  submissionUuids?: string[]
): UseGenericQueryResponse<RenewalComparisonsResponse[]> => {
  const loadData = useCallback(() => {
    if (!submissionUuids) {
      return undefined;
    }

    return Promise.all(
      submissionUuids.map((uuid) => fetchRenewalComparisons(uuid))
    );
  }, [submissionUuids]);

  return useGenericQuery(loadData);
};
