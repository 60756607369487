import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CreateFraudProtectionDecisionBody,
  CreateFraudProtectionDecisionResponse,
  FraudMatchResponse,
  FraudProtectionMatchesResponse,
} from 'types/api/underwriting/types';

// CAUTION: LOCAL TESTING ONLY. UNCOMMENT THESE LINES AS NEEDED FOR MOCK DATA.
// import { mockFraudProtectionMatches } from '../../mocks/underwriting/generators/ApiV2FraudProtectionMatches';

export const fetchSubmissionFraudProtectionMatches = async (
  submissionUuid: string
): Promise<FraudMatchResponse[]> => {
  // CAUTION: LOCAL TESTING ONLY. UNCOMMENT THESE LINES AS NEEDED FOR MOCK DATA.
  // return Promise.resolve([mockFraudProtectionMatches()]);

  const url = new URL(
    `/api/v2/fraud_protection/matches?submission_uuid=${submissionUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<FraudProtectionMatchesResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch fraud matches for Submission'
    );
  }

  return (await response.json()).data;
};

export const createFraudProtectionDecision = async (
  body: CreateFraudProtectionDecisionBody
): Promise<CreateFraudProtectionDecisionResponse> => {
  const url = new URL(
    `/api/v2/fraud_protection/decisions`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    CreateFraudProtectionDecisionResponse,
    CreateFraudProtectionDecisionBody
  >(url, 'POST', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to create fraud protection decision for Submission'
    );
  }

  return response.json();
};
