/**
 * @param name The name or legal name of the business
 * @param city The city the business is in
 * @param state The state the business is in
 * @returns URL - a fully qualified URL which directs to a search for the business name, city, and state
 */
export const buildGoogleSearchUrl = (
  name: string,
  city?: string,
  state?: string
): URL => {
  const search = city && state ? `${name} ${city} ${state}` : name;

  const url = new URL('/search', 'https://www.google.com');
  url.searchParams.append('q', search);

  return url;
};

/**
 * @param date a date string in YYYY-MM-DD format
 * @param now a date object representing the current date. Only included for dependency injection in tests
 * @returns How long ago the date is. (Ex: "4.3 years" or "3.5 months")
 */
export const howLongAgo = (date: string, now: Date = new Date()): string => {
  const [year, month, day] = date
    .split('-')
    .map((numString) => parseInt(numString, 10));

  // minus 1 from month because JavaScript months are 0 indexed
  const then = new Date(year, month - 1, day);

  const diffMilliseconds = now.valueOf() - then.valueOf();

  const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);

  if (diffDays < 30) {
    return `${diffDays.toFixed(1)} days`;
  }

  const diffMonths = diffDays / 30;
  if (diffMonths < 12) {
    return `${diffMonths.toFixed(1)} months`;
  }

  const diffYears = diffDays / 365;
  return `${diffYears.toFixed(1)} years`;
};

/**
 * @param value a string which may be a valid http url
 * @returns boolean
 */
export const isValidHttpUrl = (value: string): boolean => {
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};
