import React from 'react';
import {
  Banner,
  Box,
  Flex,
  IconButton,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { useDeleteBankingStipulation } from '../BankingStipulationManager/BankingStipulationHooks';
import { BankingStipulation } from '../BankingStipulationManager/BankingStipulation.types';
import { stipulationDisplayName } from './stipulationHelpers';

export type StipulationManagerTableProps = {
  submissionUuid?: string;
  stipulations: BankingStipulation[];
  refetchStipulations: () => void;
};

const isStipTypeOther = (stipulationToCheck: BankingStipulation): boolean =>
  stipulationToCheck.name.slice(0, 5) === 'Other';

export const StipulationManagerTable = ({
  submissionUuid,
  stipulations,
  refetchStipulations,
}: StipulationManagerTableProps): JSX.Element => {
  const [deleteStip, { error: errorDeleteStip }] =
    useDeleteBankingStipulation();

  const handleDeleteStip = async (stipUuid: string): Promise<void> => {
    if (!submissionUuid) {
      return;
    }
    const { success } = await deleteStip({
      stipulationUuid: stipUuid,
      submissionUuid,
    });

    success && refetchStipulations();
  };

  const sortedStipulations = [...stipulations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (!isStipTypeOther(a) && isStipTypeOther(b) ? -1 : 1));

  return (
    <>
      {errorDeleteStip && (
        <Banner variant="error" dismissable={false}>
          {errorDeleteStip.message}
        </Banner>
      )}
      <Table caption="Stipulations">
        <Table.Head>
          <Table.ColumnHeader>Stipulation Name</Table.ColumnHeader>
          {!featureFlags.separate_additional_info_and_external_notes && (
            <Table.ColumnHeader>Additional Info</Table.ColumnHeader>
          )}
          <Table.ColumnHeader>Reason</Table.ColumnHeader>
          <Table.ColumnHeader>Actions</Table.ColumnHeader>
        </Table.Head>

        <Table.Body>
          {sortedStipulations.length < 1 && (
            <Box paddingLeft={3}>
              <Subheading variant="subsection">
                No Stipulations to show...
              </Subheading>
            </Box>
          )}

          {sortedStipulations?.map((stip) => (
            <Table.Row key={stip.uuid}>
              <Table.Cell overflowStrategy="wrap">
                {stipulationDisplayName(stip.name, stip.additionalInfo)}
              </Table.Cell>
              {!featureFlags.separate_additional_info_and_external_notes && (
                <Table.Cell overflowStrategy="wrap">{stip.notes}</Table.Cell>
              )}
              <Table.Cell overflowStrategy="wrap">
                {stip.reasons?.join(', ')}
              </Table.Cell>
              <Table.Cell columnWidth="small">
                <Flex justifyContent="center">
                  <IconButton
                    icon="trash"
                    label={`Delete ${stip.name}`}
                    hiddenLabel
                    onClick={() => handleDeleteStip(stip.uuid)}
                  />
                </Flex>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
