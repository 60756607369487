import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Box,
  Flex,
  Banner,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { toError } from 'helpers/errorUtils';
import { useUserContext } from 'contexts/UserContext';
import { iso8601DateTimeStringToPlainDateTime } from 'helpers/iso8601DateTimeStringToPlainDateTime';
import { User } from 'api/AuthClient/codecs';
import { isUnderwriter, isProcessing } from 'helpers/utils';
import {
  shouldAdditionalInfoBeRequired,
  shouldReasonBeRequired,
} from 'components/BankingStipulationManager/BankingStipulationHelpers';
import { featureFlags } from 'helpers/featureFlags';
import { AccountInformationPanel } from '../AttachmentManager/UnderwritingAttachmentManager/AccountInformationPanel';
import { useAttachmentManagerContext } from '../AttachmentManager/AttachmentManagerContext';
import { useUpdateStipulationBatch } from './stipulationManagerHooks';
import {
  EditStipulationForm,
  SingleStipulationFormState,
} from './EditStipulationForm';
import { Stipulation, StipulationStatus } from './stipulationManager.types';
import { isReasonArrayEmpty } from './stipulationHelpers';

export interface EditStipulationModalProps {
  selectedStipulations: Stipulation[];
  onSaveComplete?: () => void;
  trigger: React.ReactNode;
}

export interface FormState {
  [uuid: string]: SingleStipulationFormState;
}

const stipulationListToFormState = (
  stipulations: Stipulation[],
  currentUser: User
): FormState => {
  return stipulations.reduce((acc, stip) => {
    return {
      ...acc,
      [stip.uuid]: {
        status: stip.status,
        bankVerificationType: stip.bankVerificationType,
        externalNotes: defaultTo(stip.notes, ''),
        reasons: stip.reasons,
        internalComments: defaultTo(stip.internalComments, ''),
        // default closing analyst if current user is processing
        // and there is no closing analyst set. Otherwise, use whatever
        // is on the Stipulation
        closingAnalystId:
          isProcessing(currentUser) && !stip.closingAnalyst?.id
            ? currentUser.id
            : stip.closingAnalyst?.id,
        // default underwriter if current user is an underwriter
        // and there is no underwriter set. Otherwise, use whatever
        // is on the Stipulation
        underwriterId:
          isUnderwriter(currentUser) && !stip.underwriter?.id
            ? currentUser.id
            : stip.underwriter?.id,
        receivedAt: stip.receivedAt
          ? iso8601DateTimeStringToPlainDateTime(stip.receivedAt)
          : undefined,
      },
    } as FormState;
  }, {} as FormState);
};

export const isStatusWaivedOrRejected = (status: StipulationStatus): boolean =>
  status === 'rejected' || status === 'waived';

export const validateFormState = (
  formState: FormState,
  allStipulations: Stipulation[],
  userRole: string
): void => {
  const singleFormStates = Object.entries(formState);

  for (const [uuid, singleFormState] of singleFormStates) {
    const unmodifiedStipulation = allStipulations.find(
      (stip) => stip.uuid === uuid
    );

    if (!unmodifiedStipulation) {
      throw new Error(
        'Attempting to modify a stipulation that does not exist. Please create an AST.'
      );
    }

    if (!singleFormState.status) {
      throw new Error(
        `${unmodifiedStipulation.name}: You must select a status`
      );
    }

    if (
      singleFormState.status === 'uw_sign_off' &&
      unmodifiedStipulation.name === 'Bank Verification' &&
      !singleFormState.bankVerificationType
    ) {
      throw new Error(
        `${unmodifiedStipulation.name}: You must select a bank verification type if status is UW Sign Off`
      );
    }

    if (
      !singleFormState.bankVerificationType &&
      unmodifiedStipulation.bankVerificationType
    ) {
      throw new Error(
        `${unmodifiedStipulation.name}: You cannot remove the bank verification type after it has been set.`
      );
    }

    if (userRole !== 'admin' && singleFormState.status === 'waived') {
      throw new Error(
        `${unmodifiedStipulation.name}: You are not authorized to waive stipulations.`
      );
    }

    if (
      isStatusWaivedOrRejected(singleFormState.status) &&
      !singleFormState.externalNotes
    ) {
      throw new Error(
        `${unmodifiedStipulation.name}: External Notes are required if status is waived or rejected.`
      );
    }
  }
};

export const EditStipulationModal = ({
  selectedStipulations,
  onSaveComplete,
  trigger,
}: EditStipulationModalProps): JSX.Element => {
  const currentUser = useUserContext();
  const { primaryId, reloadAttachmentManager, validReasons } =
    useAttachmentManagerContext();
  const [isOpen, setIsOpen] = useState(false);

  const [formState, setFormState] = useState<FormState>(
    stipulationListToFormState(selectedStipulations, currentUser)
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [updateStipulationBatch, { error: errorUpdateStipulation }] =
    useUpdateStipulationBatch();

  const handleOpenChange = (): void => {
    setIsOpen((p) => !p);
    setFormState(stipulationListToFormState(selectedStipulations, currentUser));
    setErrorMessage(undefined);
  };

  const onSubmit = async (): Promise<void> => {
    try {
      // this function throws an error if formState is invalid
      validateFormState(formState, selectedStipulations, currentUser.role);

      const updateStips = Object.entries(formState).map(
        ([stipulationUuid, stipulationChanges]) => {
          return {
            submissionUuid: primaryId,
            stipulationUuid,
            input: {
              status: stipulationChanges.status,
              bankVerificationType: stipulationChanges.bankVerificationType,
              notes: stipulationChanges.externalNotes,
              internalComments: stipulationChanges.internalComments,
              closingAnalystId: stipulationChanges.closingAnalystId,
              underwriterId: stipulationChanges.underwriterId,
              receivedAt: stipulationChanges.receivedAt?.toString(),
              reasons: stipulationChanges.reasons,
            },
          };
        }
      );

      const { success } = await updateStipulationBatch(updateStips);

      if (success) {
        handleOpenChange();
        onSaveComplete?.();
        reloadAttachmentManager();
      }
    } catch (e: unknown) {
      const error = toError(e);

      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (errorUpdateStipulation) {
      setErrorMessage(errorUpdateStipulation.message);
    } else {
      setErrorMessage(undefined);
    }
  }, [errorUpdateStipulation]);

  const isStipTypeOther = (stipulationToCheck: Stipulation): boolean =>
    stipulationToCheck.name.slice(0, 5) === 'Other';

  // See StipulationTable.tsx for explanation of this two-part sort.
  // The goal here is for the sorting logic in this modal to match.
  const sortStipulations = (stipulations: Stipulation[]): Stipulation[] => {
    return [...stipulations]
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => (!isStipTypeOther(a) && isStipTypeOther(b) ? -1 : 1));
  };

  const reasonValidation = (
    stipName: string,
    stipReasons?: string[]
  ): boolean => {
    return shouldReasonBeRequired(stipName) && isReasonArrayEmpty(stipReasons);
  };

  const shouldDisableSaveButton = (): boolean => {
    return selectedStipulations.some((stip) => {
      if (featureFlags.separate_additional_info_and_external_notes) {
        const status = formState[stip.uuid]?.status;

        const externalNotesValidation = status
          ? isStatusWaivedOrRejected(status) &&
            !formState[stip.uuid]?.externalNotes.trim()
          : false;

        return (
          externalNotesValidation ||
          reasonValidation(stip.name, formState[stip.uuid]?.reasons)
        );
      } else {
        return (
          (shouldAdditionalInfoBeRequired(stip.name) &&
            !formState[stip.uuid]?.externalNotes.trim()) ||
          reasonValidation(stip.name, formState[stip.uuid]?.reasons)
        );
      }
    });
  };

  return (
    <Modal
      title={`Edit Stipulation${selectedStipulations.length > 1 ? 's' : ''}`}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      trigger={trigger}
      body={
        <Flex flexDirection="column" gap={3}>
          {errorMessage && (
            <Banner dismissable={false} variant="error">
              {errorMessage}
            </Banner>
          )}
          <AccountInformationPanel />
          {sortStipulations(selectedStipulations).map((att, index) => (
            <Box
              key={att.uuid}
              backgroundColor={index % 2 === 0 ? 'gray-200' : 'white'}
              padding={3}
            >
              <EditStipulationForm
                name={att.name}
                additionalInfo={att.additionalInfo}
                onChange={(newSingleFormValue) => {
                  setErrorMessage(undefined);
                  setFormState((prev) => ({
                    ...prev,
                    [att.uuid]: newSingleFormValue,
                  }));
                }}
                formState={formState[att.uuid]}
                eligibleForClosingAssignment={att.eligibleForClosingAssignment}
                validReasons={validReasons}
              />
            </Box>
          ))}
          <Flex justifyContent="center" gap={2}>
            <Button onClick={onSubmit} disabled={shouldDisableSaveButton()}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleOpenChange}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};
