import React from 'react';
import {
  Box,
  Flex,
  Text,
  Address,
  Icon,
  useScreenSize,
  formatPhoneNumber,
  Grid,
  formatDateString,
  Subheading,
  Link,
} from '@forward-financing/fast-forward';
import { Partner } from 'api/FundingClient/codecs';
import { featureFlags } from 'helpers/featureFlags';
import {
  ActiveSubmission,
  Customer,
} from '../../../api/UnderwritingClient/codecs';

interface CustomerCardProps {
  customer: Customer;
  isRecommended?: boolean;
  activeSubmissions?: ActiveSubmission[];
  partners?: Partner[];
}

export function CustomerCard(props: CustomerCardProps): JSX.Element {
  const { customer } = props;
  const { isSOrSmaller } = useScreenSize();

  const partnerName = (partnerUuid: string): string | undefined =>
    props.partners?.find((p) => p.uuid === partnerUuid)?.label;

  return (
    <Flex gap={4} justifyContent={'space-between'}>
      <Box padding={3}>
        <Box marginBottom={3}>
          <Text bold>
            {customer.legal_name} (d.b.a. {customer.name})
          </Text>
        </Box>
        <Flex
          flexDirection={isSOrSmaller ? 'column' : 'row'}
          gap={isSOrSmaller ? 1 : 4}
        >
          <Text>
            <Text bold>Address: </Text>
            <Address
              city={customer.address.city}
              state={customer.address.state}
              streetAddress={`${customer.address.street1}${
                customer.address.street2 ? `, ${customer.address.street2}` : ''
              }`}
              zipCode={customer.address.zip}
              isSingleLine
            />
          </Text>
          {customer.phone && (
            <Text>
              <Text bold>Phone:</Text> {formatPhoneNumber(customer.phone)}
            </Text>
          )}
          {customer.fein && (
            <Text>
              <Text bold>FEIN:</Text> {customer.fein}
            </Text>
          )}
        </Flex>
        {!featureFlags.show_customer_submissions &&
          props.activeSubmissions &&
          props.activeSubmissions.length !== 0 && (
            <Box>
              <Subheading variant="subsection">Active Submission(s)</Subheading>
              <Grid>
                {props.activeSubmissions.map((sub) => (
                  <Grid.Item key={sub.url} xs={12} s={6} m={3}>
                    <Box marginRight={2}>
                      <Text>
                        <Link
                          newTab
                          href={
                            new URL(
                              `/submissions/${sub.uuid}`,
                              window.location.origin
                            )
                          }
                        >
                          {formatDateString(sub.date)}
                        </Link>{' '}
                        - {partnerName(sub.iso_uuid)}
                      </Text>
                    </Box>
                  </Grid.Item>
                ))}
              </Grid>
            </Box>
          )}
      </Box>
      {props.isRecommended && (
        <Flex
          flexDirection="column"
          gap={1}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Icon color="secondary" name="check-circle" prefix="far" size="3x" />
          <Text bold>Recommended Match</Text>
        </Flex>
      )}
      {customer.has_open_renewal && (
        <Flex
          flexDirection="column"
          gap={1}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Icon color="secondary" name="retweet" prefix="fas" size="3x" />
          <Text bold>Open Renewal</Text>
        </Flex>
      )}
    </Flex>
  );
}
