import React, { useState } from 'react';
import { Banner, Box, Button, Flex } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { ExceptionRequest, Offer } from '../exceptionsRequest.types';
import { useUpdateExceptionRequest } from '../exceptionsRequestHooks';
import { RequestDetails } from './RequestDetails';
import { ExceptionsRequestForm } from './ExceptionsRequestForm';
import { ExceptionRequestFormShape } from './ExceptionsRequestContainer';
export interface ExceptionsRequestRowProps {
  exceptionRequest: ExceptionRequest;
  currentOffer?: Offer;
  reloadExceptionRequestsManager: () => void;
}

export const ExceptionsRequestRow = (
  props: ExceptionsRequestRowProps
): JSX.Element => {
  const { exceptionRequest, currentOffer } = props;

  const currentUser = useUserContext();
  const [isEditing, setIsEditing] = useState(false);

  const startEdit = (): void => {
    setIsEditing(true);
  };

  const cancelEdit = (): void => {
    setIsEditing(false);
  };

  const [updateRequest, { error: updateError, loading: updateLoading }] =
    useUpdateExceptionRequest();

  const handleUpdateExistingRequest = async (
    formData: ExceptionRequestFormShape
  ): Promise<void> => {
    await updateRequest({
      exceptionRequestId: props.exceptionRequest.id,
      body: {
        requestingUser: `${currentUser?.first_name} ${currentUser?.last_name}`,
        fundingAmount: formData.fundingAmount,
        buyRate: formData.buyRate,
        program: formData.program,
        months: formData.termLength,
        stipExceptionNotes: formData.stipExceptionNotes,
        offerExceptionNotes: formData.offerExceptionNotes,
        offerId: props.currentOffer?.id,
      },
    });

    props.reloadExceptionRequestsManager();
  };

  const showEditButton = exceptionRequest.status === 'pending_review';

  return (
    <Box>
      {updateError && (
        <Banner dismissable={false}>{updateError.message}</Banner>
      )}
      {isEditing ? (
        <ExceptionsRequestForm
          currentOffer={currentOffer}
          onSubmit={handleUpdateExistingRequest}
          exceptionRequest={exceptionRequest}
          onCancel={cancelEdit}
          isSubmitDisabled={updateLoading}
        />
      ) : (
        <>
          {showEditButton && (
            <Flex py={3} gap={2} justifyContent="flex-end">
              <Button startIcon="pencil-alt" onClick={startEdit}>
                Edit
              </Button>
            </Flex>
          )}
          <RequestDetails exceptionRequest={exceptionRequest} />
        </>
      )}
    </Box>
  );
};
