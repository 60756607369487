import React from 'react';

interface FormRowProps {
  left?: JSX.Element;
  right?: JSX.Element;
  bottom?: JSX.Element;
  displayStyle?: DisplayStyle;
}
export enum DisplayStyle {
  Inline = 'inline',
  SingleLeft = 'singleLeft',
  SingleRight = 'singleRight',
  TwoAndOne = 'twoAndOne',
}

export const FormRow = (props: FormRowProps): JSX.Element => {
  return (
    <div className="form-row ml-1">
      {props.displayStyle === DisplayStyle.SingleLeft && (
        <div className="columns single-left">
          <div className="column is-half">{props.left}</div>
        </div>
      )}
      {props.displayStyle === DisplayStyle.SingleRight && (
        <div className="columns single-right">
          <div className="column is-half" />
          <div className="column is-half">{props.right}</div>
        </div>
      )}
      {props.displayStyle === DisplayStyle.TwoAndOne && (
        <div className="columns two-one">
          <div className="column is-multiline is-half">
            {props.left}
            {props.bottom}
          </div>
          <div className="column is-half">{props.right}</div>
        </div>
      )}
      {(props.displayStyle === undefined ||
        props.displayStyle === DisplayStyle.Inline) && (
        <div className="columns inline">
          <div className="column is-half">{props.left}</div>
          <div className="column is-half">{props.right}</div>
        </div>
      )}
    </div>
  );
};
