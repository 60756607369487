import React, { useState } from 'react';
import {
  Banner,
  Button,
  CurrencyInput,
  Flex,
  Form,
  MaskedTextInput,
} from '@forward-financing/fast-forward';
import { CreditDecision } from '../types';
import { NumberFormatValues } from 'react-number-format';
import { useUpdateLedger } from '../exceptionsRequestHooks';
import { defaultTo, toNumber } from 'lodash';
import { ProcessingFeeAttributeFormErrors } from '../exceptionsRequest.types';

export type ProcessingFeeAttributeFormProps = {
  creditDecision: CreditDecision;
  submissionUuid: string;
  onExitForm: () => void;
  reloadExceptionsRequestManager: () => void;
};

export const ProcessingFeeAttributeForm = ({
  creditDecision,
  submissionUuid,
  onExitForm,
  reloadExceptionsRequestManager,
}: ProcessingFeeAttributeFormProps): JSX.Element => {
  const [formState, setFormState] = useState({
    percentageWaived: creditDecision.processingFeeWaivedPercentage || undefined,
    amountWaived: creditDecision.processingFeeWaivedCents / 100 || undefined,
    maxUpsell: creditDecision.maxUpsell || undefined,
  });

  const [formErrors, setFormErrors] =
    useState<ProcessingFeeAttributeFormErrors>({
      percentageWaivedError: '',
      amountWaivedError: '',
      maxUpsellError: '',
    });

  const [
    updateLedger,
    { loading: updateLedgerLoading, error: updateLedgerError },
  ] = useUpdateLedger();

  const handleInputChange = (
    valueObject: NumberFormatValues,
    targetName: string
  ): void => {
    setFormState({
      ...formState,
      [targetName]: valueObject.value ?? '',
    });
  };

  const handleSubmit = async (): Promise<void> => {
    const isFormValid = validateForm();

    if (isFormValid) {
      const { success } = await updateLedger({
        submissionUuid: submissionUuid,
        body: {
          processing_fee_waived_percentage: defaultTo(
            toNumber(formState.percentageWaived),
            0
          ),
          processing_fee_waived_cents: defaultTo(
            toNumber(formState.amountWaived) * 100,
            0
          ),
          max_upsell: defaultTo(toNumber(formState.maxUpsell), 0.0),
        },
      });

      if (success) {
        reloadExceptionsRequestManager();
      }
    }
  };

  const validateForm = (): boolean => {
    const errors: ProcessingFeeAttributeFormErrors = {
      percentageWaivedError: '',
      amountWaivedError: '',
      maxUpsellError: '',
    };

    setFormErrors(errors);

    if (
      formState.amountWaived === undefined &&
      formState.percentageWaived === undefined
    ) {
      errors.amountWaivedError =
        'Must set an amount waived (either percentage or dollar amount)';
      errors.percentageWaivedError =
        'Must set an amount waived (either percentage or dollar amount)';
    }

    if (formState.amountWaived && formState.amountWaived < 0) {
      errors.amountWaivedError = 'Amount waived must be 0 or greater';
    }
    if (
      formState.percentageWaived &&
      (formState.percentageWaived < 0 || formState.percentageWaived > 100)
    ) {
      errors.percentageWaivedError =
        'Percentage waived must be between 0 and 100';
    }
    if (
      formState.maxUpsell &&
      (formState.maxUpsell < 0 || formState.maxUpsell > 15)
    ) {
      errors.maxUpsellError = 'Max upsell must be between 0 and 15';
    }

    setFormErrors(errors);

    return Object.values(errors).every((err) => !err);
  };

  return (
    <>
      {updateLedgerError && <Banner>{updateLedgerError.message}</Banner>}
      <Form
        accessibleName={'Processing Fee Attribute Form'}
        onSubmit={handleSubmit}
        allowImplicitSubmission={true}
      >
        {({ fireSubmit }) => (
          <>
            <Flex flexDirection="column" gap={3}>
              <MaskedTextInput
                required={!formState.amountWaived}
                format="###%"
                label="Amount Waived (Percentage)"
                type="number"
                name="percentageWaived"
                placeholder="###%"
                value={formState.percentageWaived}
                onValueChange={(e) => handleInputChange(e, 'percentageWaived')}
                disabled={
                  !formState.percentageWaived && !!formState.amountWaived
                }
                errorMessage={formErrors.percentageWaivedError}
              />

              <CurrencyInput
                required={!formState.percentageWaived}
                label="Amount Waived (Dollar Amount)"
                name="amountWaived"
                prefix="$"
                decimalScale={2}
                placeholder="#####.##"
                value={formState.amountWaived}
                onValueChange={(e) => handleInputChange(e, 'amountWaived')}
                disabled={
                  !formState.amountWaived && !!formState.percentageWaived
                }
                errorMessage={formErrors.amountWaivedError}
              />

              <CurrencyInput
                label="Max Upsell"
                name="maxUpsell"
                placeholder="#.##"
                value={formState.maxUpsell}
                onValueChange={(e) => handleInputChange(e, 'maxUpsell')}
                errorMessage={formErrors.maxUpsellError}
              />

              <Flex gap={3} justifyContent="flex-end">
                <Button variant="secondary" onClick={onExitForm}>
                  Cancel
                </Button>
                <Button disabled={updateLedgerLoading} onClick={fireSubmit}>
                  Submit
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Form>
    </>
  );
};
