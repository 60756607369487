import React, { useState } from 'react';
import {
  Box,
  Table,
  Subheading,
  IconButton,
  Flex,
  Banner,
  TableEditableSelectCellProps,
} from '@forward-financing/fast-forward';
import { formatBoolean } from 'helpers/stringUtils';
import { toError } from 'helpers/errorUtils';
import { usePaymentScheduleContext } from './PaymentScheduleContext';
import { toPascalCase } from './paymentScheduleUtils';
import { updateBankInfo } from './paymentScheduleFetchUtils';

interface AccountNumberDisplayProps {
  accountNumber: string;
}

const AccountNumberDisplay = (
  props: AccountNumberDisplayProps
): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const onClick = (): void => {
    setIsVisible((p) => !p);
  };

  return (
    <Flex gap={2} justifyContent="space-between" alignItems="center">
      {isVisible ? (
        <>
          {props.accountNumber}
          <IconButton
            label="Hide Account Number"
            icon="eye-slash"
            hiddenLabel
            onClick={onClick}
          />
        </>
      ) : (
        <>
          ...{props.accountNumber.slice(-4)}
          <IconButton
            label="Show Account Number"
            icon="eye"
            hiddenLabel
            onClick={onClick}
          />
        </>
      )}
    </Flex>
  );
};

export const BankInfoTable = (): JSX.Element => {
  const {
    advanceRecordId,
    bankInfo,
    advanceProfile,
    achProcessors,
    refetchBankInfo,
    refetchPaymentSchedule,
  } = usePaymentScheduleContext();

  const [achUpdateError, setAchUpdateError] = useState<string | undefined>();

  const onAchProcessorSave: TableEditableSelectCellProps['onSave'] = async (
    id,
    name,
    newValue
  ) => {
    try {
      if (newValue === undefined || newValue === '') {
        setAchUpdateError('Clearing ACH Processor is not allowed');
      } else {
        const response = await updateBankInfo(advanceRecordId, {
          achProvider: newValue,
        });

        if (response.success) {
          void refetchPaymentSchedule();
        } else {
          setAchUpdateError('Updating ACH Processor failed. Please try again.');
        }

        // all error handling is done by this function
        void refetchBankInfo?.();
      }
    } catch (e: unknown) {
      const error = toError(e);
      setAchUpdateError(`Failed to update ACH Processor: ${error.message}`);
    }
  };

  return (
    <Box>
      <Subheading>Bank Information</Subheading>
      {achUpdateError && (
        <Box mb={2}>
          <Banner dismissable={false}>{achUpdateError}</Banner>
        </Box>
      )}
      <Table caption="Bank Information">
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>Active</Table.RowHeader>
            <Table.Cell>
              {formatBoolean(advanceProfile?.manualActive)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Routing Number</Table.RowHeader>
            <Table.Cell>{bankInfo?.achRoutingNumber ?? 'N/A'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Account Number</Table.RowHeader>
            <Table.Cell>
              {bankInfo?.accountNumber ? (
                <AccountNumberDisplay accountNumber={bankInfo.accountNumber} />
              ) : (
                'N/A'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Payment Processor (Advance Level)</Table.RowHeader>
            <Table.EditableSelectCell
              id="achProvider"
              value={bankInfo?.achProvider ?? ''}
              name="achProvider"
              label="Payment Processor (Advance Level)"
              options={
                achProcessors?.map((processor) => ({
                  text: toPascalCase(processor),
                  value: processor,
                })) ?? []
              }
              onSave={onAchProcessorSave}
            />
          </Table.Row>
        </Table.Body>
      </Table>
    </Box>
  );
};
