import { useCallback } from 'react';
import {
  fetchBusinessLexisNexisReportById,
  fetchFullBusinessLexisNexisReport,
  fetchFullOwnerLexisNexisReport,
  fetchFullOwnerLexisNexisReportByReportId,
  updateDocument,
} from 'api/underwriting/documentsFetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  BusinessLexisNexisPayload,
  BusinessLexisNexisReportResponse,
  OwnerLexisNexisPayload,
  OwnerLexisNexisReportResponse,
} from 'types/api/underwriting/types';

export type UseGetApiOwnerLexisNexisReportResponse =
  UseGenericQueryResponse<OwnerLexisNexisReportResponse>;

type UseGetApiOwnerLexisNexisReportByIdResponse =
  UseGenericQueryResponse<OwnerLexisNexisPayload>;

export const useGetApiOwnerLexisNexisReport = (
  submissionUuid?: string
): UseGetApiOwnerLexisNexisReportResponse => {
  const getOwnerLexisNexisReport = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchFullOwnerLexisNexisReport(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getOwnerLexisNexisReport);
};

export const useGetApiOwnerLexisNexisReportById = (
  reportId?: string
): UseGetApiOwnerLexisNexisReportByIdResponse => {
  const getOwnerLexisNexisReportById = useCallback(() => {
    if (!reportId) {
      return undefined;
    }

    return fetchFullOwnerLexisNexisReportByReportId(reportId);
  }, [reportId]);

  return useGenericQuery(getOwnerLexisNexisReportById);
};

export const useGetApiBusinessLexisNexisReport = (
  submissionUuid?: string
): UseGenericQueryResponse<BusinessLexisNexisReportResponse> => {
  const getBusinessLexisNexisReport = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchFullBusinessLexisNexisReport(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getBusinessLexisNexisReport);
};

export const useGetApiBusinessLexisNexisReportById = (
  submissionUuid?: string,
  reportId?: string
): UseGenericQueryResponse<BusinessLexisNexisPayload> => {
  const getBusinessLexisNexisReportById = useCallback(() => {
    if (!submissionUuid || !reportId) {
      return undefined;
    }

    return fetchBusinessLexisNexisReportById(submissionUuid, reportId);
  }, [submissionUuid, reportId]);

  return useGenericQuery(getBusinessLexisNexisReportById);
};

export type UseApiUpdateDocumentArgs = {
  documentId?: number;
  primary?: boolean;
};

export type UseApiUpdateDocumentResponse = UseGenericMutationResponse<
  MutationResponse | undefined,
  UseApiUpdateDocumentArgs
>;

const updateReport = async (
  args: UseApiUpdateDocumentArgs
): Promise<MutationResponse | undefined> => {
  if (!args.documentId || !args.primary) {
    return undefined;
  }
  return updateDocument(args.documentId, args.primary);
};

export const useApiUpdateDocument = (): UseApiUpdateDocumentResponse => {
  return useGenericMutation(updateReport);
};
