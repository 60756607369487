import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Currency,
  CurrencyInput,
  DatePicker,
  Flex,
  formatDateString,
  Grid,
  CurrencyInputProps,
  PlainDate,
  Subheading,
  Table,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';

import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { createAdjustment } from '../paymentScheduleFetchUtils';

export interface SameDayACHFormProps {
  afterTwoPM: boolean;
}

export const SameDayAchForm = ({
  afterTwoPM,
}: SameDayACHFormProps): JSX.Element => {
  const {
    sameDayAchFormState,
    updateSameDayAchFormState,
    resetSameDayAchForm,
    advanceRecordId,
    refetchPaymentSchedule,
  } = usePaymentScheduleContext();

  const [confirmationView, setConfirmationView] = useState<boolean>(false);
  const [sameDayAchError, setSameDayAchError] = useState<string | undefined>(
    undefined
  );
  const [sameDayAchSuccess, setSameDayAchSuccess] = useState<
    string | undefined
  >(undefined);

  const handleCustomAmountChange: CurrencyInputProps['onValueChange'] = (
    values
  ) => {
    setSameDayAchError(undefined);
    updateSameDayAchFormState({
      customAmount: values.floatValue,
    });
  };

  const handleNoteChange: TextAreaProps['onChange'] = (e) => {
    setSameDayAchError(undefined);
    updateSameDayAchFormState({
      adjustmentNote: e.target.value,
    });
  };

  const onSubmitClick = async (): Promise<void> => {
    if (
      sameDayAchFormState.paymentDate &&
      sameDayAchFormState.customAmount !== undefined &&
      sameDayAchFormState.adjustmentNote
    ) {
      try {
        await createAdjustment(advanceRecordId, {
          amount: sameDayAchFormState.customAmount,
          startDate: sameDayAchFormState.paymentDate.toString(),
          endDate: sameDayAchFormState.paymentDate?.toString(),
          frequency: 'daily',
          recurrency: 1,
          notes: sameDayAchFormState.adjustmentNote ?? '',
          settlement: false,
          adjustmentReason: 'courtesy',
          sameDayAch: true,
          achProvider: 'ach_works',
        });

        void refetchPaymentSchedule();
        setSameDayAchSuccess('Same day ACH payment successfully requested');
        resetSameDayAchForm();
        setConfirmationView(false);
      } catch (e: unknown) {
        const error = toError(e);
        setSameDayAchError(error.message);
      }
    }
  };

  const onCancelClick = (): void => {
    setConfirmationView(false);
    setSameDayAchError(undefined);
    resetSameDayAchForm();
  };

  const invalidForm =
    !sameDayAchFormState.adjustmentNote ||
    sameDayAchFormState.customAmount === undefined ||
    sameDayAchFormState.customAmount < 0;

  return (
    <Box>
      {sameDayAchError && (
        <Box mb={2}>
          <Banner dismissable={false}>{sameDayAchError}</Banner>
        </Box>
      )}
      {sameDayAchSuccess && (
        <Box mb={2}>
          <Banner dismissable variant="success">
            {sameDayAchSuccess}
          </Banner>
        </Box>
      )}
      {afterTwoPM && (
        <Banner dismissable={false} variant="error">
          Cannot set up Same Day ACH Payment after 2pm.
        </Banner>
      )}
      {confirmationView && (
        <Box>
          <Flex gap={2}>
            <Subheading>Confirm Same Day ACH Payment</Subheading>
          </Flex>
          <Table>
            <Table.Head>
              <Table.ColumnHeader>Adjustment</Table.ColumnHeader>
              <Table.ColumnHeader>Adjusted Payment</Table.ColumnHeader>
              <Table.ColumnHeader>Frequency</Table.ColumnHeader>
              <Table.ColumnHeader>Adjustment Period</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>CA</Table.Cell>
                <Table.Cell>
                  <Currency amount={sameDayAchFormState.customAmount || 0} />
                </Table.Cell>
                <Table.Cell>Same Day ACH</Table.Cell>
                <Table.Cell>
                  {formatDateString(
                    sameDayAchFormState?.paymentDate?.toString() || ''
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      )}
      {!confirmationView && (
        <Flex flexDirection="column" gap={2}>
          <Subheading variant="subsection">Same Day ACH Payment</Subheading>
          <Grid gutter>
            <Grid.Item xs={12} m={4}>
              <DatePicker
                label="Payment Date"
                canSelectTime={false}
                disabled
                selected={
                  new PlainDate(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    new Date().getDate()
                  )
                }
                onChange={() => undefined}
              />
            </Grid.Item>
            <Grid.Item xs={12} m={4}>
              <CurrencyInput
                label="Custom Amount"
                value={sameDayAchFormState.customAmount?.toString() ?? ''}
                onValueChange={handleCustomAmountChange}
                prefix="$"
                thousandSeparator=","
                decimalScale={2}
                required
                disabled={afterTwoPM}
              />
            </Grid.Item>
          </Grid>
        </Flex>
      )}
      <Grid gutter>
        <Grid.Item xs={12}>
          <TextArea
            label="Notes"
            required
            onChange={handleNoteChange}
            value={sameDayAchFormState.adjustmentNote?.toString() ?? ''}
            disabled={confirmationView || afterTwoPM}
          />
        </Grid.Item>
      </Grid>
      <Flex mt={3} justifyContent="center" gap={2}>
        <Flex gap={3}>
          <Button
            startIcon="check"
            onClick={
              confirmationView ? onSubmitClick : () => setConfirmationView(true)
            }
            disabled={afterTwoPM || invalidForm}
          >
            Submit
          </Button>
          <Button
            startIcon="times"
            variant="secondary"
            onClick={onCancelClick}
            disabled={afterTwoPM}
          >
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
