import { useCallback } from 'react';
import {
  MutationResponse,
  UseGenericMutationResult,
} from 'apiHooks/genericFetchHooks';
import {
  useApiLazyGetFraudProtectionMatches,
  useCreateApiFraudProtectionDecision,
} from 'apiHooks/underwriting/fraudProtectionFetchHooks';
import {
  FraudMatchResponse,
  FraudProtectionAlertResponse,
  FraudStatus,
} from 'types/api/underwriting/types';
import {
  PotentialFraudAlert,
  PotentialFraudMatch,
} from './PotentialFraud.types';

const toPotentialFraudAlert = (
  alert: FraudProtectionAlertResponse
): PotentialFraudAlert => {
  return {
    id: alert.id,
    type: alert.type,
    status: alert.status,
    source: alert.source,
  };
};

const toPotentialFraudMatch = (
  match: FraudMatchResponse
): PotentialFraudMatch => {
  return {
    submission: {
      uuid: match.submission.uuid,
      accountUuid: match.submission.account_uuid ?? undefined,
      receivedAt: match.submission.received_at ?? undefined,
      dba: match.submission.dba ?? undefined,
      legalName: match.submission.legal_name ?? undefined,
    },
    alerts: match.alerts.map(toPotentialFraudAlert),
    criteria: {
      dba: match.criteria.dba,
      legalName: match.criteria.legal_name,
      fein: match.criteria.fein,
      businessAddress: match.criteria.business_address,
      businessPhone: match.criteria.business_phone,
      ownerName: match.criteria.owner_name,
      ownerSsn: match.criteria.owner_ssn,
      ownerEmail: match.criteria.owner_email,
      ownerAddress: match.criteria.owner_address,
      ownerPhone: match.criteria.owner_phone,
    },
  };
};

type UseLazyPotentialFraudMatchesResponse = [
  (submissionUuid: string) => Promise<void>,
  {
    potentialFraudMatches: PotentialFraudMatch[];
    loading: boolean;
    error?: Error;
  }
];

export const useLazyPotentialFraudMatches =
  (): UseLazyPotentialFraudMatchesResponse => {
    const [fetcher, { data, loading, error }] =
      useApiLazyGetFraudProtectionMatches();

    const wrappedFetcher = useCallback(
      (submissionUuid: string): Promise<void> => fetcher({ submissionUuid }),
      [fetcher]
    );

    return [
      wrappedFetcher,
      {
        potentialFraudMatches: data?.map(toPotentialFraudMatch) ?? [],
        loading,
        error,
      },
    ];
  };

// Create Decision
type UseCreatePotentialFraudDecisionResponse = [
  (args: CreatePotentialFraudDecisionArgs) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse>
];

export interface CreatePotentialFraudDecisionArgs {
  alertId: number;
  userId: number;
  status: FraudStatus;
}

export const useCreatePotentialFraudDecision =
  (): UseCreatePotentialFraudDecisionResponse => {
    const [createFn, { error, ...rest }] =
      useCreateApiFraudProtectionDecision();

    const createFunction = async (
      args: CreatePotentialFraudDecisionArgs
    ): Promise<MutationResponse> => {
      return createFn({
        createBody: {
          alert_id: args.alertId,
          user_id: args.userId,
          status: args.status,
        },
      });
    };

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };
