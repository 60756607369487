import { User } from 'api/AuthClient/codecs';
import React, { createContext, useContext } from 'react';

export interface UnderwritersProviderContextData {
  underwriters: User[];
  processors: User[];
}

const UnderwritingAttachmentManagerContext =
  createContext<UnderwritersProviderContextData>({
    underwriters: [],
    processors: [],
  });

export const useUnderwritersProvider = (): UnderwritersProviderContextData =>
  useContext(UnderwritingAttachmentManagerContext);

interface AttachmentManagerProviderProps {
  value: UnderwritersProviderContextData;
  children: React.ReactNode;
}

export const UnderwritersProvider = ({
  value,
  children,
}: AttachmentManagerProviderProps): JSX.Element => {
  return (
    <UnderwritingAttachmentManagerContext.Provider value={value}>
      {children}
    </UnderwritingAttachmentManagerContext.Provider>
  );
};
