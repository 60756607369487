import React, { useState } from 'react';
import { Banner, Box, Flex, Subheading } from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { StipulationRecommendationsContainer } from 'components/StipulationRecommendations/StipulationRecommendationsContainer';
import { CreateBankingStipulationModal } from '../BankingStipulationManager/CreateBankingStipulationModal';
import { useStipulations, useValidReasons } from './stipulationManagerHooks';
import { StipulationManagerTable } from './StipulationManagerTable';

export type StipulationManagerProps = {
  submissionUuid?: string;
};

export const StipulationManager = ({
  submissionUuid,
}: StipulationManagerProps): JSX.Element => {
  const [errorBanner, setErrorBanner] = useState<string>();

  const {
    data: stipulations,
    error: stipulationsError,
    refetch: refetchStipulations,
  } = useStipulations(submissionUuid);

  const { data: validReasons, error: validReasonsError } = useValidReasons();

  const onCancelWithError = (error?: string): void => {
    refetchStipulations();
    setErrorBanner(error);
  };

  const additionalStipulations =
    stipulations?.filter((stip) => stip.isAdditional) || [];

  return (
    <Box>
      <Flex flexDirection="column" gap={2}>
        <Subheading>Stipulation Manager</Subheading>

        <Flex
          justifyContent="flex-end"
          alignItems="flex-start"
          alignContent="flex-start"
          gap={2}
        >
          {featureFlags.show_stipulation_recommendation_text &&
            submissionUuid && (
              <StipulationRecommendationsContainer
                submissionUuid={submissionUuid}
              />
            )}

          {submissionUuid && (
            <CreateBankingStipulationModal
              submissionUuid={submissionUuid}
              refetchStipulations={refetchStipulations}
              onCancelWithError={onCancelWithError}
              validReasons={validReasons}
            />
          )}
        </Flex>

        {stipulationsError && (
          <Banner variant="error" dismissable={false}>
            {stipulationsError.message}
          </Banner>
        )}
        {validReasonsError && (
          <Banner variant="error" dismissable={false}>
            {validReasonsError.message}
          </Banner>
        )}
        {errorBanner && (
          <Banner variant="error" dismissable={false}>
            {errorBanner}
          </Banner>
        )}

        {additionalStipulations.length > 0 && (
          <StipulationManagerTable
            submissionUuid={submissionUuid}
            stipulations={additionalStipulations}
            refetchStipulations={refetchStipulations}
          />
        )}
      </Flex>
    </Box>
  );
};
