import { AuthClient } from '../AuthClient';
import { CRM_GATEWAY_BASE_URL } from '../../constants/globals';
import { CoreSalesforceMap, CoreSalesforceMapType } from './codecs';
import { FetchResponse } from 'api/codecs';

export class CrmGatewayClient extends AuthClient {
  private static BASE_URL = CRM_GATEWAY_BASE_URL();

  static fetchMapping = (
    objectId: string,
    objectType: CoreSalesforceMapType,
    newSalesforce: boolean
  ): Promise<CoreSalesforceMap> => {
    const [apiVersion, mappingApiController] = newSalesforce
      ? ['v2', 'core_salesforce_maps']
      : ['v1', 'salesforce_object_mappings'];
    return CrmGatewayClient.get<CoreSalesforceMap>(
      `${CrmGatewayClient.BASE_URL}/api/${apiVersion}/${mappingApiController}/${objectId}?object_type=${objectType}`
    ).then(CrmGatewayClient.handleResponse);
  };

  private static handleResponse = (
    response: FetchResponse<CoreSalesforceMap>
  ) => {
    if (!response.ok) {
      throw new Error(
        `Non 2XX response from crm gateway: ${response.status} ${response.statusText}`
      );
    }
    return response.json();
  };
}
