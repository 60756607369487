import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  LedgerUnderwritingPricingSuggestionsGetParams,
  LedgerUnderwritingPricingSuggestionsRaw,
} from 'types/api/underwriting/types';

export const fetchLedgerUnderwritingSuggestedPricing = async (
  submissionUuid: string,
  params: LedgerUnderwritingPricingSuggestionsGetParams
): Promise<LedgerUnderwritingPricingSuggestionsRaw> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/pricing_suggestions`,
    UNDERWRITING_BASE_URL()
  );

  url.search = new URLSearchParams(params).toString();

  const response =
    await makeInternalAPIRequest<LedgerUnderwritingPricingSuggestionsRaw>(
      url,
      'GET'
    );

  if (!response.ok) {
    try {
      const parsedResponse = await response.json();

      throw new NetworkError(
        response.status,
        parsedResponse?.error ??
          'Failed to fetch Underwriting Suggested Pricing'
      );
    } catch (_e) {
      throw new NetworkError(
        response.status,
        'Failed to fetch Underwriting Suggested Pricing'
      );
    }
  }

  return response.json();
};
