import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { CriminalRecord } from './types';

export interface CriminalFilingsLinkPreviewTableProps {
  criminalFilings: CriminalRecord[];
}

export const CriminalFilingsLinkPreviewTable = (
  props: CriminalFilingsLinkPreviewTableProps
): JSX.Element => {
  return (
    <>
      {props.criminalFilings.length === 0 ? (
        <Box mb={4}>
          <Text>There are no criminal filings</Text>
        </Box>
      ) : (
        <Table caption="Criminal Records">
          <Table.Head>
            <Table.ColumnHeader>Name</Table.ColumnHeader>
            <Table.ColumnHeader>Offense</Table.ColumnHeader>
            <Table.ColumnHeader>Offense Date</Table.ColumnHeader>
            <Table.ColumnHeader>Detail</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {props.criminalFilings.map((filing) => (
              <Table.Row key={filing.offenseDate}>
                <Table.Cell>{filing.fullName}</Table.Cell>
                <Table.Cell
                  columnWidth="small"
                  rowHeight="small"
                  overflowStrategy="ellipsis"
                >
                  {filing.offense}
                </Table.Cell>
                <Table.Cell>{filing.offenseDate ?? 'N/A'}</Table.Cell>
                <Table.Cell
                  columnWidth="small"
                  rowHeight="small"
                  overflowStrategy="ellipsis"
                >
                  {filing.caseTypeDescription}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
