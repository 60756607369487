import React from 'react';
import { Subheading, Table } from '@forward-financing/fast-forward';

import { ExperianConsumerDetailInfo } from '../types';

type ExperianConsumerDetailMessagesProps = {
  messages: ExperianConsumerDetailInfo['informationalMessages'];
};

export const ExperianConsumerDetailMessages = ({
  messages,
}: ExperianConsumerDetailMessagesProps): JSX.Element | null => {
  if (
    !messages.length ||
    !messages.some(
      // We need to show only messages related to SSN Mismatch
      // 0085 SSN DOES NOT MATCH
      // 0086 SIMILAR SSN ON FILE
      (message) =>
        message.messageText.includes('0085') ||
        message.messageText.includes('0086')
    )
  )
    return null;

  return (
    <>
      <Subheading>Messages</Subheading>
      <Table>
        <Table.Body>
          {messages
            .filter(
              (message) =>
                message.messageText.includes('0085') ||
                message.messageText.includes('0086')
            )
            .map((message) => (
              <Table.Row key={message.messageText}>
                <Table.Cell>{message.messageText}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
};
