import { Button, Modal } from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { IndustryRiskTable } from './IndustryRiskTable';

export const IndustryRiskModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal
      trigger={<Button>Industry Risk</Button>}
      isOpen={isOpen}
      onOpenChange={() => setIsOpen((p) => !p)}
      title="Industry Risk"
      body={<IndustryRiskTable />}
    />
  );
};
