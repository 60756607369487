import React from 'react';
import { useParams } from 'react-router-dom';
import { PrequalAttachmentManagerContainer } from './PrequalAttachmentManagerContainer';

type Params = {
  applicationUuid: string;
};

export const PrequalAttachmentManagerRouteContainer = (): JSX.Element => {
  const { applicationUuid } = useParams<Params>();

  // istanbul ignore next
  if (!applicationUuid) {
    throw new Error('Application UUID Param not set');
  }

  return (
    <PrequalAttachmentManagerContainer applicationUuid={applicationUuid} />
  );
};
