import React, { useCallback, useEffect, useState } from 'react';
import {
  Banner,
  Flex,
  Heading,
  Loading,
  PageContainer,
  Subheading,
} from '@forward-financing/fast-forward';
import { useAssignUnderwriterNextDeal } from './UnderwriterQueueHooks';
import { useUserContext } from 'contexts/UserContext';
import { isUnderwriter } from 'helpers/utils';
import { useTimeout } from 'ahooks';

export const UnderwriterQueueLoading = (): JSX.Element => {
  const [dealFound, setDealFound] = useState(false);
  const [queueError, setQueueError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [retryDelay, setRetryDelay] = useState<number | undefined>();
  const user = useUserContext();

  const [fetchNextDeal, { data: nextDealData, error: nextDealError }] =
    useAssignUnderwriterNextDeal();

  const fetchDeal = useCallback(async (): Promise<void> => {
    const { success } = await fetchNextDeal();

    if (success) {
      setRetryCount((count) => count + 1);
    } else {
      setQueueError(true);
    }
  }, [fetchNextDeal]);

  const notifyUser = async (message: string): Promise<void> => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        // eslint-disable-next-line no-new
        new Notification(message);
      } else if (Notification.permission !== 'denied') {
        // We need to ask the user for permission
        const permission = await Notification.requestPermission();
        // If the user accepts, create a notification
        if (permission === 'granted') {
          // eslint-disable-next-line no-new
          new Notification(message);
        }
      }
    }
  };

  // Initial fetch on page load for UW users to run one time before retry loop begins
  useEffect(() => {
    if (isUnderwriter(user)) {
      void fetchDeal();
    }
    /*
     * Disabling exhaustive deps here because we explicitly want this effect to be run only once
     * on initial render. Each fetchDeal call will trigger a different effect to call itself on a
     * 10 second delay, and including the dependency in this useEffect creates an endless loop.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useTimeout(() => {
    if (!dealFound) {
      void fetchDeal();
    }
    setRetryDelay(undefined);
  }, retryDelay);

  // Triggered after each successful fetch
  useEffect(() => {
    // Do nothing if we haven't completed first fetch, we have a deal, or we have an error
    if (queueError || dealFound || retryCount < 1) {
      return undefined;
    }

    if (nextDealData && nextDealData.dataUrls.length > 0) {
      // If data is present with urls, open them in new tabs
      void notifyUser('We found you a submission!');
      setDealFound(true);
      nextDealData.dataUrls.forEach((url) => {
        window.open(url, '_blank');
      });
      return undefined;
    }

    // If no deals, wait 10s then refetch
    return setRetryDelay(10000);
  }, [retryCount, nextDealData, queueError, dealFound, setRetryDelay]);

  if (!isUnderwriter(user)) {
    return <></>;
  }

  return (
    <PageContainer>
      {dealFound ? (
        <Flex margin={4} gap={2} alignItems={'center'} flexDirection={'column'}>
          <Heading>Deal found!</Heading>
          <Subheading>You can close this page.</Subheading>
        </Flex>
      ) : (
        <Flex margin={4} gap={2} alignItems={'center'} flexDirection={'column'}>
          {queueError && (
            <>
              {nextDealError && (
                <Banner dismissable={false}>{nextDealError.message}</Banner>
              )}
              <Heading>Something went wrong.</Heading>
              <Subheading>
                The system encountered an error while trying to assign a deal.
                Please try again and notify tech support if the problem
                persists.
              </Subheading>
            </>
          )}
          {!queueError && (
            <>
              <Loading size="large" />
              <Heading>Sit tight.</Heading>
              <Subheading>
                We&apos;re locating a submission in your industry group. If one
                isn&apos;t found in 5 minutes, we&apos;ll assign a submission in
                your revenue tier.
              </Subheading>
            </>
          )}
        </Flex>
      )}
    </PageContainer>
  );
};
