import { formatDateString, Subheading } from '@forward-financing/fast-forward';
import React from 'react';
import { PaymentDetailsTable } from './PaymentDetailsTable';
import { usePaymentScheduleContext } from './PaymentScheduleContext';

export const PaymentDetails = (): JSX.Element => {
  const { formState } = usePaymentScheduleContext();
  return (
    <>
      {formState.startDate ? (
        <>
          <Subheading>
            Payment Details for{' '}
            {formatDateString(formState.startDate.toString())}
          </Subheading>
          <PaymentDetailsTable />
        </>
      ) : (
        <Subheading>Select a Date to Access Payment Details</Subheading>
      )}
    </>
  );
};
