import React, { useState } from 'react';
import {
  Box,
  Flex,
  Divider,
  Dropdown,
  Banner,
  Heading,
  Subheading,
  Button,
  IconButton,
} from '@forward-financing/fast-forward';
import { UnderwritingClient } from '../../../api/UnderwritingClient';
import { featureFlags } from '../../../helpers/featureFlags';
import { Customer, Owner } from '../../../api/UnderwritingClient/codecs';
import { generateEmptyCustomer } from '../../../api/UnderwritingClient/utils/index';
import { CustomerCard } from './CustomerCard';
import { CustomerSearchResults } from './CustomerSearchResults';
import { CustomerConfirmationModal } from './CustomerConfirmationModal';
import { Partner } from 'api/FundingClient/codecs';

export interface CustomerSearchProps {
  handleCustomerSearchResultsReviewed: (
    customerUuid: string,
    customerOwners: Owner[],
    industry_id: number
  ) => void;
  partners: Partner[];
  handleEditAccountInformation: () => void;
  handleCustomerChange: (customer: Customer) => void;
  customer: Customer;
}

export function CustomerSearch(props: CustomerSearchProps): JSX.Element {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>(
    generateEmptyCustomer()
  );
  const [errorMessage, setErrorMessage] = useState('');

  const handleOnChange = (customer: Customer): void => {
    setSelectedCustomer(customer);
  };

  const handleConfirmSelection = async (): Promise<void> => {
    const customerOwners: Owner[] = [];

    if (featureFlags.autofill_pq_form_owners_on_customer_match) {
      try {
        const owners = await UnderwritingClient.fetchCustomerOwners(
          selectedCustomer.uuid
        );
        // Only add unique owners.
        owners.forEach((owner) => {
          const existingOwner = customerOwners.find(
            (customerOwner) =>
              customerOwner.first_name === owner.first_name &&
              customerOwner.last_name === owner.last_name &&
              customerOwner.ssn === owner.ssn &&
              customerOwner.born_on === owner.born_on
          );
          if (!existingOwner) {
            customerOwners.push(owner);
          }
        });
      } catch (error) {
        let message =
          'Unknown error fetching for Customer Owners in Customer Search.';

        if (error instanceof Error) {
          message = error.message;
        }

        setErrorMessage(message);
      }
    }

    props.handleCustomerSearchResultsReviewed(
      selectedCustomer.uuid,
      customerOwners,
      selectedCustomer.industry_id || 0
    );
  };

  const handleCreateNewCustomer = (): void => {
    props.handleCustomerChange(props.customer);
    props.handleCustomerSearchResultsReviewed('', [], 0);
  };

  return (
    <Box padding={4}>
      <Box padding={4} bg="gray-100">
        {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
        <Heading>Customer Search</Heading>
        <Divider />
        <Box marginTop={4} bg="white" rounded>
          <Flex justifyContent="space-between" paddingX={3}>
            <Subheading>Search criteria</Subheading>
            <IconButton
              icon="pencil"
              label="Edit search criteria"
              hiddenLabel
              onClick={props.handleEditAccountInformation}
            />
          </Flex>
          <Divider margin={0} />
          <CustomerCard customer={props.customer} />
        </Box>
        <Box marginTop={3} bg="white" rounded>
          <Flex paddingX={3} alignItems="center" justifyContent="space-between">
            <Subheading>Search results</Subheading>
            <Box>
              <Dropdown disabled label="Sort by: Relevance">
                <Dropdown.RadioItem id="1" value="Relevance" />
              </Dropdown>
            </Box>
          </Flex>
          <Divider margin={0} />
          <CustomerSearchResults
            handleOnChange={handleOnChange}
            selectedCustomerUuid={selectedCustomer.uuid}
            customer={props.customer}
          />
        </Box>
      </Box>
      <Flex gap={4} justifyContent="center" marginTop={4}>
        <Button
          startIcon={['fas', 'user-plus']}
          onClick={handleCreateNewCustomer}
        >
          Create New Customer
        </Button>
        <CustomerConfirmationModal
          customer={selectedCustomer}
          handleContinue={handleConfirmSelection}
          partners={props.partners}
        />
      </Flex>
    </Box>
  );
}
