import React from 'react';
import { Table, Text } from '@forward-financing/fast-forward';
import { PersonalInformation } from './ownerLexisNexisPage.types';

export type PersonalInformationTableProps = {
  personalInformationData: PersonalInformation;
};

export const PersonalInformationTable = ({
  personalInformationData: { name, address, ssn, dob, phoneNumbers },
}: PersonalInformationTableProps): JSX.Element => {
  return (
    <Table caption="Personal Information">
      <Table.Head>
        <Table.ColumnHeader>Name</Table.ColumnHeader>
        <Table.ColumnHeader>Address</Table.ColumnHeader>
        <Table.ColumnHeader>SSN</Table.ColumnHeader>
        <Table.ColumnHeader>DOB</Table.ColumnHeader>
        <Table.ColumnHeader>Phone Numbers</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.Cell>{name}</Table.Cell>
          <Table.Cell>{address}</Table.Cell>
          <Table.Cell>{ssn}</Table.Cell>
          <Table.Cell>{dob}</Table.Cell>
          <Table.Cell>
            {phoneNumbers.map((phone) => (
              <Text key={phone}>{phone}</Text>
            ))}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
