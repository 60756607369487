import { featureFlags } from 'helpers/featureFlags';

export const isReasonArrayEmpty = (reasons?: string[]): boolean =>
  !reasons || reasons?.length === 0;

const isStipulationOtherOrHasAdditionalInfo = (name: string): boolean => {
  return name.startsWith('Other') || name.includes('-');
};

export const stipulationDisplayName = (
  name: string,
  addtionalInfo?: string
): string => {
  if (
    featureFlags.separate_additional_info_and_external_notes &&
    !isStipulationOtherOrHasAdditionalInfo(name) &&
    addtionalInfo
  ) {
    return `${name} - ${addtionalInfo}`;
  }

  return name;
};
