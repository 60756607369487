import React from 'react';

export interface ToastProps {
  mainMessage: string;
  leadingIcon?: string;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Toast = (props: ToastProps): JSX.Element => {
  return (
    <div className="notification toast is-danger">
      {props.leadingIcon && (
        <span className="icon">
          <i className={props.leadingIcon} role="img" />
        </span>
      )}
      <span className="toast-message">{props.mainMessage}</span>
      <button
        onClick={props.onClose}
        aria-label="Delete Toast"
        className="delete"
      />
    </div>
  );
};
