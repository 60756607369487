import React from 'react';
import { Flex, Text, formatDate } from '@forward-financing/fast-forward';

export interface LastUpdateMessageProps {
  time: string;
  name: string;
}

export const LastUpdatedMessage = ({
  time,
  name,
}: LastUpdateMessageProps): JSX.Element => (
  <Flex pt={2} alignItems="center" justifyContent="center">
    <Text>
      Last updated on{' '}
      {formatDate(time, {
        excludeTime: false,
      })}{' '}
      by {name}
    </Text>
  </Flex>
);
