import React from 'react';
import { Box, List } from '@forward-financing/fast-forward';

export interface ChecklistPopoverContentProps {
  descriptors: string[];
}

export const ChecklistPopoverContent = ({
  descriptors,
}: ChecklistPopoverContentProps): JSX.Element => (
  <Box>
    <List>
      {descriptors.map((descriptor) => (
        <List.Item key={descriptor}>{descriptor}</List.Item>
      ))}
    </List>
  </Box>
);
