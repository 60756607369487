import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { Event } from './BusinessLexisNexisPage.types';

type EventsTableProps = {
  events?: Event[];
};

export const EventsTable = ({ events }: EventsTableProps): JSX.Element => {
  const hasEvents = events && events.length > 0;
  return hasEvents ? (
    <Table caption="Events">
      <Table.Head>
        <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
        <Table.ColumnHeader>Filing Type</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {events?.map((event) => (
          <Table.Row key={event.filingDate}>
            <Table.Cell>{event.filingDate}</Table.Cell>
            <Table.Cell>{event.filingType}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Events')}</Text>
    </Box>
  );
};
