import React from 'react';
import { User } from '../../../api/AuthClient/codecs';
import { userFullName } from '../../../helpers/utils';
import { Flex, Text, Box, formatDate } from '@forward-financing/fast-forward';

export interface AnalystHeaderProps {
  loggedInUser: User;
  startTime: Date | null;
  currentDate?: Date;
}

export const AnalystHeader = ({
  currentDate = new Date(),
  loggedInUser,
  startTime,
}: AnalystHeaderProps): JSX.Element => {
  const renderAnalystName = () => {
    return userFullName(loggedInUser.first_name, loggedInUser.last_name);
  };

  return (
    <Flex justifyContent="space-between" paddingX={4}>
      <Box padding={3}>
        <Text>Hola, {renderAnalystName()}</Text>
      </Box>
      <Box padding={3}>
        <Text>
          Prequal Start Date/Time:{' '}
          {startTime && formatDate(startTime.getTime(), { includeTime: true })}
        </Text>
      </Box>
      <Box padding={3}>
        <Text>{formatDate(currentDate.getTime())}</Text>
      </Box>
    </Flex>
  );
};
