import React from 'react';
import {
  Banner,
  Box,
  Divider,
  Flex,
  Grid,
} from '@forward-financing/fast-forward';
import { BankInfoTable } from './BankInfoTable';
import { AdvanceProfileTable } from './AdvanceProfileTable';
import { Calculator } from './Calculator/Calculator';
import { PaymentHistoryPdfDownloadLink } from './PaymentHistoryPdfDownloadLink';
import { ResumePayments } from './ResumePayments';
import { PaymentCalendar } from './Calendar/PaymentCalendar';
import { usePaymentScheduleContext } from './PaymentScheduleContext';
import { PaymentDetails } from './PaymentDetails';
import { BorrowingBaseBanner } from './BorrowingBaseBanner';
import { SixtyDayCovenantBanner } from './SixtyDayCovenantBanner';

export const PaymentSchedule = (): JSX.Element => {
  const { advanceProfile, createAdjustmentError } = usePaymentScheduleContext();
  return (
    <Box>
      <Flex gap={2} flexDirection="column">
        <BorrowingBaseBanner />
        <SixtyDayCovenantBanner />
      </Flex>
      <Flex
        flexDirection="row-reverse"
        alignItems="center"
        gap={3}
        justifyContent="space-between"
        mb={3}
        flexWrap="wrap-reverse"
      >
        <PaymentHistoryPdfDownloadLink />
        <ResumePayments />
      </Flex>
      <Box>
        <PaymentCalendar />
      </Box>
      <Divider />
      <Box>
        <Grid gutter>
          <Grid.Item s={12} m={12} l={4}>
            <AdvanceProfileTable />
          </Grid.Item>
          <Grid.Item s={12} m={6} l={4}>
            <BankInfoTable />
          </Grid.Item>
          <Grid.Item s={12} m={6} l={4}>
            <PaymentDetails />
          </Grid.Item>
        </Grid>
      </Box>
      {!advanceProfile?.writeOff && (
        <>
          <Divider />
          <Box>
            {createAdjustmentError && (
              <Banner dismissable={false}>{createAdjustmentError}</Banner>
            )}
            <Calculator />
          </Box>
        </>
      )}
    </Box>
  );
};
