import { Flex, IconButton, Text } from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { Owner } from '__generated__/graphql';

export interface SocialSecurityNumberDisplayProps {
  ssn: Owner['ssn'];
  ssnLastFour: Owner['ssnLastFour'];
  canSeePartialSsn: boolean;
}

export const SocialSecurityNumberDisplay = ({
  ssn,
  ssnLastFour,
  canSeePartialSsn,
}: SocialSecurityNumberDisplayProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  if (!canSeePartialSsn) {
    return <Text>You are not authorized to see SSN</Text>;
  }

  if (!ssnLastFour) {
    // we don't have any content
    return <Text>No Data</Text>;
  }

  if (!ssn) {
    // we have the last four, but not the full value
    // implicitly, we know that they must not have permission
    // to see the full value
    return <Text>XXX-XX-{ssnLastFour}</Text>;
  }

  return isVisible ? (
    <>
      <Flex justifyContent="space-between">
        <>
          {ssn.slice(0, 3)}-{ssn.slice(3, 5)}-{ssn.slice(5)}
        </>
        <IconButton
          label="Hide SSN"
          icon="eye-slash"
          hiddenLabel
          onClick={() => setIsVisible((p) => !p)}
        />
      </Flex>
    </>
  ) : (
    <>
      <Flex justifyContent="space-between">
        <>XXX-XX-{ssnLastFour}</>
        <IconButton
          label="Show SSN"
          icon="eye"
          hiddenLabel
          onClick={() => setIsVisible((p) => !p)}
        />
      </Flex>
    </>
  );
};
