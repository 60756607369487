import React from 'react';

import { Grid, Text } from '@forward-financing/fast-forward';

export interface DataLineProps {
  mainText: string | React.ReactNode;
  text: string | number | React.ReactNode;
}

export const DataLine = ({ mainText, text }: DataLineProps): JSX.Element => {
  return (
    <Grid>
      <Grid.Item xs={2}>
        <Text bold>{mainText}</Text>
      </Grid.Item>
      <Grid.Item xs={10}>
        <Text>{text}</Text>
      </Grid.Item>
    </Grid>
  );
};
