export const convertFileToBase64String = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = () => {
      // Get the base64-encoded component which is guaranteed to not include ','
      return resolve(reader.result?.toString().split(',')[1] || '');
    };

    reader.readAsDataURL(file);
  });
};
