import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  CurrencyInput,
  DatePicker,
  DatePickerWithoutTimeProps,
  Flex,
  Grid,
  CurrencyInputProps,
  Radio,
  RadioProps,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
} from '@forward-financing/fast-forward';
import {
  AdjustmentReason,
  paymentFrequencies,
  PaymentFrequency,
} from '../paymentSchedule.types';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { capitalize } from 'lodash';

const isValidPaymentFrequency = (value: string): value is PaymentFrequency =>
  paymentFrequencies.includes(value as PaymentFrequency);

export const CalculatorForm = (): JSX.Element => {
  const {
    formState,
    calculateError,
    updateFormState,
    getAdjustmentProjection,
    resetForm,
  } = usePaymentScheduleContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleStartDateChange: DatePickerWithoutTimeProps['onChange'] = (
    newValue
  ) => {
    setErrorMessage(null);
    updateFormState({
      startDate: newValue,
    });
  };

  const handleEndDateChange: DatePickerWithoutTimeProps['onChange'] = (
    newValue
  ) => {
    setErrorMessage(null);
    updateFormState({
      endDate: newValue,
    });
  };

  const handleFrequencyChange: SelectProps['onValueChange'] = (newValue) => {
    setErrorMessage(null);
    updateFormState({
      frequency: isValidPaymentFrequency(newValue) ? newValue : undefined,
    });
  };

  const handleLastMonthRevenueChange: CurrencyInputProps['onValueChange'] = (
    values
  ) => {
    setErrorMessage(null);
    updateFormState({
      lastMonthRevenue: values.floatValue,
    });
  };

  const handleCustomAmountChange: CurrencyInputProps['onValueChange'] = (
    values
  ) => {
    setErrorMessage(null);
    updateFormState({
      customAmount: values.floatValue,
    });
  };

  const handleOpenEndedSwitchChange: SwitchProps['onCheckedChange'] = (
    checked
  ) => {
    setErrorMessage(null);
    updateFormState({
      endDate: undefined,
      isOpenEnded: checked,
    });
  };

  const handleProcessorChange: SelectProps['onValueChange'] = (newValue) => {
    setErrorMessage(null);
    updateFormState({
      paymentProcessor: newValue === '' ? undefined : newValue,
    });
  };

  const handleAdjustmentTypeChange: RadioProps['onChange'] = (newValue) => {
    setErrorMessage(null);
    updateFormState({
      adjustmentReason: newValue as AdjustmentReason,
    });
  };

  const onCalculateClick = (): void => {
    if (
      formState.startDate &&
      formState.frequency &&
      formState.adjustmentReason
    ) {
      void getAdjustmentProjection();
    } else {
      setErrorMessage(
        'Start Date, Frequency, and Adjustment Type are required to generate adjustment options'
      );
    }
  };

  const onCancelClick = (): void => {
    setErrorMessage(null);
    resetForm();
  };

  const onStopPaymentsClick = (): void => {
    if (formState.startDate && formState.adjustmentReason) {
      setErrorMessage(null);
      updateFormState({
        frequency: 'daily',
        selectedAdjustment: {
          amount: 0,
          estimatedPayoffDate: null,
          label: 'STOP',
          errors: null,
        },
        adjustmentReason: formState.adjustmentReason,
      });
    } else {
      setErrorMessage(
        'Please select a First Payment Date and Adjustment Type to stop payments'
      );
    }
  };

  return (
    <Box>
      {calculateError && (
        <Box mb={2}>
          <Banner dismissable={false}>{calculateError}</Banner>
        </Box>
      )}
      {errorMessage && (
        <Box mb={2}>
          <Banner dismissable={false}>{errorMessage}</Banner>
        </Box>
      )}
      <Grid gutter>
        <Grid.Item xs={12} m={6}>
          <DatePicker
            label="First Payment Date"
            canSelectTime={false}
            selected={formState.startDate}
            onChange={handleStartDateChange}
          />
        </Grid.Item>
        <Grid.Item xs={12} m={6}>
          <Flex flexDirection="column" gap={2}>
            <DatePicker
              label="Last Payment Date"
              canSelectTime={false}
              selected={formState.endDate}
              onChange={handleEndDateChange}
              disabled={formState.isOpenEnded}
            />
            <Switch
              id="open-ended-control"
              checked={!!formState.isOpenEnded}
              onCheckedChange={handleOpenEndedSwitchChange}
              label="Open Ended"
              inInputGroup
            />
          </Flex>
        </Grid.Item>
        <Grid.Item xs={12} m={6}>
          <Select
            label="Frequency"
            value={formState.frequency ?? ''}
            onValueChange={handleFrequencyChange}
            options={paymentFrequencies.map((value) => ({
              value,
              text: capitalize(value),
            }))}
          />
        </Grid.Item>
        <Grid.Item xs={12} m={6}>
          <CurrencyInput
            label="Last Month's Revenue"
            value={formState.lastMonthRevenue?.toString() ?? ''}
            onValueChange={handleLastMonthRevenueChange}
            prefix="$"
            thousandSeparator=","
            decimalScale={2}
          />
        </Grid.Item>
        <Grid.Item xs={12} m={6}>
          <CurrencyInput
            label="Custom Amount"
            value={formState.customAmount?.toString() ?? ''}
            onValueChange={handleCustomAmountChange}
            prefix="$"
            thousandSeparator=","
            decimalScale={2}
          />
        </Grid.Item>
        <Grid.Item xs={12} m={6}>
          <Select
            label="Payment Processor (Adjustment Level)"
            value={formState.paymentProcessor ?? ''}
            onValueChange={handleProcessorChange}
            options={[
              {
                text: 'Auto ACH',
                value: 'auto_ach',
              },
              {
                text: 'Manual',
                value: 'manual_other',
              },
            ]}
          />
        </Grid.Item>
        <Grid.Item xs={12} m={6}>
          <Radio
            value={formState.adjustmentReason ?? ''}
            onChange={handleAdjustmentTypeChange}
            name={'Adjustment Type'}
          >
            <Radio.Item id="contractual" value="contractual">
              Contractual
            </Radio.Item>
            <Radio.Item id="courtesy" value="courtesy">
              Courtesy
            </Radio.Item>
            <Radio.Item id="no_engagement" value="no_engagement">
              No Engagement
            </Radio.Item>
          </Radio>
        </Grid.Item>
      </Grid>
      <Flex mt={3} justifyContent="space-between" gap={2}>
        <Button
          startIcon="stop-circle"
          variant="danger"
          onClick={onStopPaymentsClick}
        >
          Stop Payments
        </Button>
        <Flex gap={3}>
          <Button startIcon="calculator" onClick={onCalculateClick}>
            Calculate
          </Button>
          <Button startIcon="times" variant="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
