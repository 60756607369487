import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  TextInput,
  Select,
  Grid,
  IconButton,
  Divider,
  Banner,
  SelectableOption,
  Combobox,
} from '@forward-financing/fast-forward';
import { v4 as uuidv4 } from 'uuid';
import {
  shouldAdditionalInfoBeRequired,
  shouldReasonBeRequired,
} from 'components/BankingStipulationManager/BankingStipulationHelpers';
import { featureFlags } from 'helpers/featureFlags';
import { HashMap } from 'api/codecs';
import { StipulationRecommendationsContainer } from 'components/StipulationRecommendations/StipulationRecommendationsContainer';
import { useAttachmentManagerContext } from '../AttachmentManager/AttachmentManagerContext';
import {
  useHandleCreateStipulations,
  useSelectedStipulationReasons,
} from './stipulationManagerHooks';
import { CreateStipulationInput } from './stipulationManager.types';
import { isReasonArrayEmpty } from './stipulationHelpers';

export interface CreateStipulationFormProps {
  onSubmit: () => void;
  onCancel: () => void;
  validReasons?: HashMap<string[]>;
  submissionUuid?: string;
}

const stipTypes = (): string[] => [
  '12 Month Bank Verification',
  'Accounts Must Be Positive Before Funding',
  'Accounts Receivable Report/Invoices',
  'Bank Verification for Additional Account',
  'Confirm Business Type/Online Presence',
  'Confirm Current or Past Funding',
  'Information on Additional Owner',
  'Payback Months',
  'Proof of Ownership',
  'Tax Return',
  'UCC Information',
  'Other',
];

export const CreateStipulationForm = (
  props: CreateStipulationFormProps
): JSX.Element => {
  const [stips, setStips] = useState<CreateStipulationInput[]>([
    { temporaryUuid: uuidv4(), name: '', notes: '', additionalInfo: '' },
  ]);

  const { primaryId } = useAttachmentManagerContext();
  const { error, setError, handleSubmit } = useHandleCreateStipulations({
    stips,
    setStips,
    submissionUuid: primaryId,
    onSubmitCallback: props.onSubmit,
  });

  const handleCancel = (): void => {
    setStips([
      {
        temporaryUuid: uuidv4(),
        name: '',
        notes: '',
        additionalInfo: '',
      },
    ]);
    setError('');
    props.onCancel();
  };

  const handleAddStip = (): void => {
    setStips((prev) => [
      ...prev,
      { temporaryUuid: uuidv4(), name: '', notes: '', additionalInfo: '' },
    ]);
  };

  const handleDeleteStip = (uuidToDelete: string): void => {
    setStips((prev) =>
      prev.filter((stip) => {
        return stip.temporaryUuid !== uuidToDelete;
      })
    );
  };

  const handleUpdateStip = (
    newStip: Partial<CreateStipulationInput>,
    uuidToUpdate: string
  ): void => {
    setStips((prev) =>
      prev.map((stip) => {
        return uuidToUpdate === stip.temporaryUuid
          ? { ...stip, ...newStip }
          : stip;
      })
    );
  };

  const { handleUpdateName, handleUpdateReasons } =
    useSelectedStipulationReasons({ handleUpdateStip });

  const additionalInfoValidation = (stip: CreateStipulationInput): boolean => {
    return (
      shouldAdditionalInfoBeRequired(stip.name) &&
      (featureFlags.separate_additional_info_and_external_notes
        ? !stip.additionalInfo.trim()
        : !stip.notes.trim())
    );
  };

  const reasonValidation = (stip: CreateStipulationInput): boolean => {
    return (
      shouldReasonBeRequired(stip.name) && isReasonArrayEmpty(stip.reasons)
    );
  };

  const shouldDisableSaveButton = (): boolean => {
    let result;
    featureFlags.remove_describe_stipulation_type
      ? (result = stips.some(
          (stip) =>
            stip.name === '' ||
            additionalInfoValidation(stip) ||
            reasonValidation(stip)
        ))
      : (result = stips.some(
          (stip) =>
            stip.name === '' ||
            (stip.name === 'Other' && !stip.otherDescription) ||
            additionalInfoValidation(stip) ||
            reasonValidation(stip)
        ));

    return result;
  };

  const validReasonsOptions = (stipName: string): SelectableOption[] => {
    if (!stipName || !props.validReasons || !props.validReasons[stipName]) {
      return [];
    }

    return props.validReasons[stipName].map((reason) => ({
      value: reason,
      text: reason,
    }));
  };

  const shouldShowAdditionalInfo = (name: string): boolean => {
    return featureFlags.separate_additional_info_and_external_notes
      ? shouldAdditionalInfoBeRequired(name)
      : true;
  };

  return (
    <Flex flexDirection="column">
      {featureFlags.show_stipulation_recommendation_text &&
        props.submissionUuid && (
          <StipulationRecommendationsContainer
            submissionUuid={props.submissionUuid}
          />
        )}
      {error && <Banner>{error}</Banner>}
      {stips.map((stip, index) => (
        <Box p={2} key={stip.temporaryUuid}>
          <Grid gutter>
            <Grid.Item m={5}>
              <Select
                label={`Stipulation ${index + 1} Type`}
                value={stip.name}
                options={stipTypes().map((type) => {
                  return { value: type, text: type };
                })}
                required={true}
                onValueChange={handleUpdateName(stip)}
              />
            </Grid.Item>

            {shouldShowAdditionalInfo(stip.name) && (
              <Grid.Item m={6}>
                <TextInput
                  label={`Stipulation ${index + 1} Additional Info`}
                  value={
                    featureFlags.separate_additional_info_and_external_notes
                      ? stip.additionalInfo
                      : stip.notes
                  }
                  required={additionalInfoValidation(stip)}
                  onValueChange={(newValue) =>
                    handleUpdateStip(
                      featureFlags.separate_additional_info_and_external_notes
                        ? { additionalInfo: newValue }
                        : {
                            notes: newValue,
                          },
                      stip.temporaryUuid
                    )
                  }
                />
              </Grid.Item>
            )}

            {shouldReasonBeRequired(stip.name) && (
              <Grid.Item m={4}>
                <Combobox
                  closeMenuOnSelect={false}
                  isMulti
                  label={`Stipulation ${index + 1} Reason`}
                  values={
                    stip.reasons
                      ? stip.reasons?.map((reason) => ({
                          value: reason,
                          text: reason,
                        }))
                      : []
                  }
                  required={reasonValidation(stip)}
                  onValueChange={handleUpdateReasons(stip)}
                  options={validReasonsOptions(stip.name)}
                />
              </Grid.Item>
            )}

            {stips.length > 1 && (
              <Flex alignItems={'end'}>
                <Grid.Item m={1}>
                  <IconButton
                    icon="trash"
                    label="Delete Stipulation"
                    hiddenLabel
                    onClick={() => handleDeleteStip(stip.temporaryUuid)}
                  />
                </Grid.Item>
              </Flex>
            )}

            {!featureFlags.remove_describe_stipulation_type &&
              stip.name === 'Other' && (
                <Grid.Item m={5}>
                  <TextInput
                    label="Describe Stipulation Type"
                    onValueChange={(newValue) =>
                      handleUpdateStip(
                        {
                          otherDescription: newValue,
                        },
                        stip.temporaryUuid
                      )
                    }
                    required
                  />
                </Grid.Item>
              )}
          </Grid>
        </Box>
      ))}
      <Box mt={2}>
        <Button
          onClick={handleAddStip}
          startIcon="plus"
          disabled={stips.some((stip) => stip.name === '')}
        >
          Add Another Stipulation
        </Button>
      </Box>
      <Divider />
      <Flex justifyContent="center" gap={2}>
        <Button
          onClick={handleSubmit}
          startIcon="check"
          disabled={shouldDisableSaveButton()}
        >
          Save
        </Button>
        <Button onClick={handleCancel} startIcon="times" variant="secondary">
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};
