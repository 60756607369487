import React from 'react';
import {
  Heading,
  Box,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';

import { AcceptedOffer, Prepayment } from '../../api/BankingClient/codecs';
import { Advance } from '../../api/FundingClient/codecs';
import { useHttpQuery } from '../../hooks/useHttpQuery.hook';
import { AdvancePrepaymentSchedule } from './AdvancePrepaymentSchedule';
import { AdvancePrepaymentForm } from './AdvancePrepaymentForm';
import { Toast } from '../shared/Toast';
import { BankingClient } from '../../api/BankingClient';
import { FundingClient } from '../../api/FundingClient';

function getPrepaymentData(data?: AcceptedOffer): Prepayment[] | undefined {
  return data?.included
    .filter((included_object) => included_object.type === 'prepayments')
    .map((prepayment) => prepayment.attributes)
    .sort((left, right) => left.savings - right.savings);
}

export interface AdvancePrepaymentProps {
  opportunityUuid: string;
}

export const AdvancePrepayment = ({
  opportunityUuid,
}: AdvancePrepaymentProps): JSX.Element => {
  const {
    called: prepaymentRequestCalled,
    query: prepaymentRequestQuery,
    error: prepaymentRequestError,
    data: prepaymentRequestData,
  } = useHttpQuery<string, AcceptedOffer>({
    queryFn: BankingClient.fetchAcceptedOffer,
  });
  React.useEffect(() => {
    if (!prepaymentRequestCalled) {
      prepaymentRequestQuery(opportunityUuid);
    }
  }, [opportunityUuid, prepaymentRequestCalled, prepaymentRequestQuery]);

  const {
    called: advanceInfoRequestCalled,
    query: advanceInfoRequestQuery,
    data: advanceInfoRequestData,
    error: advanceInfoRequestError,
  } = useHttpQuery<string, Advance>({
    queryFn: FundingClient.fetchAdvance,
  });
  React.useEffect(() => {
    if (!advanceInfoRequestCalled) {
      advanceInfoRequestQuery(opportunityUuid);
    }
  }, [opportunityUuid, advanceInfoRequestCalled, advanceInfoRequestQuery]);

  const advanceInfo = advanceInfoRequestData;

  const prepaymentData = getPrepaymentData(prepaymentRequestData);
  if (prepaymentRequestError || advanceInfoRequestError) {
    return (
      <Toast
        mainMessage={prepaymentRequestError || advanceInfoRequestError}
        onClose={() => {
          // Intentionally empty
          // this was already like this when we turned on 'no-empty-function' rule
          // we've left it alone, but this might be supposed to actually do
          // something on click
        }}
        leadingIcon="fas fa-exclamation-triangle fa-2x"
      />
    );
  }
  if (prepaymentData && advanceInfo) {
    return (
      <>
        <Box>
          <Heading>Advance Prepayment Received</Heading>
          <AdvancePrepaymentSchedule
            opportunityUuid={opportunityUuid}
            prepaymentSchedule={prepaymentData}
          />
          <Box>
            <Subheading>Original Advance Info</Subheading>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.RowHeader>Advance Date</Table.RowHeader>
                  <Table.Cell>{advanceInfo.date_of_advance}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.RowHeader>Current Buy Rate</Table.RowHeader>
                  <Table.Cell>{advanceInfo.buy_rate}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.RowHeader>Commission</Table.RowHeader>
                  <Table.Cell>{advanceInfo.commission}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.RowHeader>Current Factor Rate</Table.RowHeader>
                  <Table.Cell>{advanceInfo.factor_rate}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Box>
          <AdvancePrepaymentForm
            opportunityUuid={opportunityUuid}
            prepaymentSchedule={prepaymentData}
            advanceDate={advanceInfo.date_of_advance}
            commission={advanceInfo.commission}
            advanceRecordId={advanceInfo.record_id}
          />
        </Box>
      </>
    );
  }
  return <></>;
};
