import {
  Box,
  Currency,
  Flex,
  Link,
  Subheading,
  Table,
  useScreenSize,
} from '@forward-financing/fast-forward';
import React from 'react';
import { PaymentHistoryAdvance } from './PaymentHistory.types';
import { CollectionNotes } from './CollectionNotes';

export interface PaymentHistoryAdvanceDetailsProps {
  advance: PaymentHistoryAdvance;
}

export const PaymentHistoryAdvanceDetails = ({
  advance,
}: PaymentHistoryAdvanceDetailsProps): JSX.Element => {
  const { isSOrSmaller } = useScreenSize();

  const data = [
    { label: 'Expected Payments', value: advance.expectedPaymentCount },
    { label: 'Full Payments', value: advance.fullPaymentCount },
    { label: 'Reduced Payments', value: advance.reducedPaymentCount },
    { label: 'Missed Payments', value: advance.missedPaymentCount },
    { label: 'Rejections', value: advance.rejectionsCount },
    {
      label: 'Advance Amount',
      value: <Currency amount={Number(advance.amount)} />,
    },
  ];

  return (
    <Box p={1}>
      <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center">
        <Subheading>Advance Snapshot</Subheading>
        <Link newTab href={new URL(advance.paymentScheduleUrl)}>
          View Schedule
        </Link>
      </Flex>
      <Table
        caption={`Advance Snapshot for Advance ${advance.advanceNumber} issued ${advance.dateOfAdvance}`}
      >
        {isSOrSmaller ? (
          <Table.Body>
            {data.map(({ label, value }) => (
              <Table.Row key={label}>
                <Table.RowHeader>{label}</Table.RowHeader>
                <Table.Cell>{value}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        ) : (
          <>
            <Table.Head>
              {data.map(({ label }) => (
                <Table.ColumnHeader key={label}>{label}</Table.ColumnHeader>
              ))}
            </Table.Head>
            <Table.Body>
              <Table.Row>
                {data.map(({ label, value }) => (
                  <Table.Cell key={label}>{value}</Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </>
        )}
      </Table>
      <CollectionNotes collectionNotes={advance.collectionNotes} />
    </Box>
  );
};
