import React from 'react';
import { TradeLinesData, TradeLine, Payment } from './codecs/TradeLines.codec';
import {
  Box,
  Currency,
  Grid,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { toDate } from '../../helpers/string/dateUtils';

export interface TradeLinesProps {
  data: TradeLinesData;
}

interface TradeLineContentProps {
  rowData: TradeLine;
}

interface PaymentHistoryGridProps {
  paymentHistory: string;
}

interface PaymentHistoryItemProps {
  code: string;
}

const UNKNOWN_CODE = 'UNK';
const TOTAL_CATEGORY = 'Financial Line Totals';

// Take payment data formatted as an array of objects containing
// a single character code and return a string containing all of
// the code characters in sequence.
const flattenPaymentHistoryData = (paymentData: Payment[]) => {
  let str = '';

  paymentData.forEach((payment) => {
    str += payment.code;
  });

  return str;
};

// Return the correct color variant based on payment status code
const getPaymentColor = (code: string) => {
  switch (code) {
    case 'C':
    case '0':
      return 'green-300';
    case '1':
      return 'yellow-400';
    case '2':
      return 'orange-300';
    case '3':
      return 'orange-600';
    case '4':
      return 'red-200';
    case '5':
    case 'B':
    case 'G':
    case 'H':
    case 'J':
    case 'K':
    case 'L':
      return 'red-500';
    case '-':
    default:
      return 'gray-300';
  }
};

// Reduce functions to handle adding columns for the financial summary totals row
const reducedNumberOfTradeLines = (data: TradeLinesData) => {
  return (
    data.financial_summary?.reduce(
      (acc, elem) =>
        acc +
        (elem.category === TOTAL_CATEGORY ? 0 : elem.number_of_tradelines),
      0
    ) || 0
  );
};

const reducedBalance = (data: TradeLinesData) => {
  return (
    data.financial_summary?.reduce(
      (acc, elem) =>
        acc +
        (!elem.balance.modifier && elem.category !== TOTAL_CATEGORY
          ? elem.balance.amount
          : 0),
      0
    ) || 0
  );
};

const reducedHighCreditAmount = (data: TradeLinesData) => {
  return (
    data.financial_summary?.reduce(
      (acc, elem) =>
        acc +
        (!elem.high_credit_amount.modifier && elem.category !== TOTAL_CATEGORY
          ? elem.high_credit_amount.amount
          : 0),
      0
    ) || 0
  );
};

const reducedMonthlyPayment = (data: TradeLinesData) => {
  return (
    data.financial_summary?.reduce(
      (acc, elem) =>
        acc +
        (!elem.monthly_payment_amount.modifier &&
        elem.category !== TOTAL_CATEGORY
          ? elem.monthly_payment_amount.amount
          : 0),
      0
    ) || 0
  );
};

const reducedPastDue = (data: TradeLinesData) => {
  return (
    data.financial_summary?.reduce(
      (acc, elem) =>
        acc + (elem.category === TOTAL_CATEGORY ? 0 : elem.past_due),
      0
    ) || 0
  );
};

const PaymentHistoryGrid = ({ paymentHistory }: PaymentHistoryGridProps) => {
  const arr = [...Array(36).keys()];

  return (
    <Grid gutter>
      {arr.map((num) => {
        return (
          <Grid.Item xs={1} key={num}>
            <PaymentHistoryItem code={paymentHistory[num] || '\u2001'} />
          </Grid.Item>
        );
      })}
    </Grid>
  );
};

const PaymentHistoryItem = ({ code }: PaymentHistoryItemProps) => {
  return (
    <Box textAlign={'center'} bg={getPaymentColor(code)}>
      <Text bold>{code}</Text>
    </Box>
  );
};

const TradeLineContent = ({ rowData }: TradeLineContentProps) => {
  return (
    <Box paddingBottom={3} paddingRight={3}>
      <Grid gutter>
        <Grid.Item xs={6}>
          <Box marginBottom={3} marginTop={3}>
            <Grid>
              <Grid.Item xs={3}>
                <Text bold>Comments:</Text>
              </Grid.Item>
              <Grid.Item xs={9}>
                <Text>{rowData.comments}</Text>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Text bold>Account Number:</Text>
              </Grid.Item>
              <Grid.Item xs={9}>
                <Text>{rowData.account_number}</Text>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Text bold>Account Type:</Text>
              </Grid.Item>
              <Grid.Item xs={9}>
                <Text>{rowData.account_type}</Text>
              </Grid.Item>
            </Grid>
          </Box>
          <Text bold>
            Present Balance: <Currency amount={rowData.present_balance} />
          </Text>
          <Box paddingTop={3}>
            <Table>
              <Table.Head>
                <Table.ColumnHeader>Current</Table.ColumnHeader>
                <Table.ColumnHeader>1 - 30 days</Table.ColumnHeader>
                <Table.ColumnHeader>31 - 60 days</Table.ColumnHeader>
                <Table.ColumnHeader>61 - 90 days</Table.ColumnHeader>
                <Table.ColumnHeader>91 - 120 days</Table.ColumnHeader>
                <Table.ColumnHeader>121+ days</Table.ColumnHeader>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Currency amount={rowData.current_balance} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={rowData.dbt_30_balance} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={rowData.dbt_60_balance} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={rowData.dbt_90_balance} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={rowData.dbt_120_balance} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency
                      amount={
                        rowData.dbt_150_balance +
                        rowData.dbt_180_balance +
                        rowData.dbt_181_plus_balance
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Box>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Box marginBottom={3} marginTop={3}>
            <Grid>
              <Grid.Item xs={3}>
                <Text bold>Account Status:</Text>
              </Grid.Item>
              <Grid.Item xs={9}>
                <Text>{rowData.account_status}</Text>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Text bold>Date Closed:</Text>
              </Grid.Item>
              <Grid.Item xs={9}>
                <Text>{rowData.date_closed}</Text>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Text bold>Reason Closed:</Text>
              </Grid.Item>
              <Grid.Item xs={9}>
                <Text>{rowData.reason_closed}</Text>
              </Grid.Item>
            </Grid>
          </Box>
          <Text bold>
            36 Month Payment History (
            {toDate(rowData.date_reported).getMonth() + 1}/
            {toDate(rowData.date_reported).getFullYear()} -{' '}
            {toDate(rowData.date_reported).getMonth() + 1}/
            {toDate(rowData.date_reported).getFullYear() - 3})
          </Text>
          <Box paddingTop={3}>
            <PaymentHistoryGrid
              paymentHistory={rowData.payment_history || ''}
            />
            <Text>* See legend below</Text>
          </Box>
        </Grid.Item>
      </Grid>
    </Box>
  );
};

export const TradeLines = ({ data }: TradeLinesProps): JSX.Element => {
  return (
    <Grid gutter>
      <Grid.Item xs={6}>
        <Box marginBottom={5}>
          <Subheading>SBCS Financial Account Summary</Subheading>
          {data.financial_summary && data.financial_summary.length > 0 ? (
            <Table>
              <Table.Head>
                <Table.ColumnHeader>Type</Table.ColumnHeader>
                <Table.ColumnHeader>#</Table.ColumnHeader>
                <Table.ColumnHeader>Balance</Table.ColumnHeader>
                <Table.ColumnHeader>
                  Credit Limit/Original Loan Amt
                </Table.ColumnHeader>
                <Table.ColumnHeader>Monthly Payment</Table.ColumnHeader>
                <Table.ColumnHeader>Past Due</Table.ColumnHeader>
              </Table.Head>
              <Table.Body>
                {data.financial_summary.map((row) => {
                  return row.category === TOTAL_CATEGORY ? null : (
                    <Table.Row key={row.category}>
                      <Table.Cell>{row.category}</Table.Cell>
                      <Table.Cell columnWidth="small">
                        {row.number_of_tradelines}
                      </Table.Cell>
                      <Table.Cell columnWidth="small">
                        {row.balance.modifier ? (
                          UNKNOWN_CODE
                        ) : (
                          <Currency amount={row.balance.amount} />
                        )}
                      </Table.Cell>
                      <Table.Cell columnWidth="large">
                        {row.high_credit_amount.modifier ? (
                          UNKNOWN_CODE
                        ) : (
                          <Currency amount={row.high_credit_amount.amount} />
                        )}
                      </Table.Cell>
                      <Table.Cell columnWidth="small">
                        {row.monthly_payment_amount.modifier ? (
                          UNKNOWN_CODE
                        ) : (
                          <Currency
                            amount={row.monthly_payment_amount.amount}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell columnWidth="small">
                        <Currency amount={row.past_due} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.SummaryRow>
                  <Table.Cell>Totals</Table.Cell>
                  <Table.Cell>{reducedNumberOfTradeLines(data)}</Table.Cell>
                  <Table.Cell>
                    <Currency amount={reducedBalance(data)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={reducedHighCreditAmount(data)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={reducedMonthlyPayment(data)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={reducedPastDue(data)} />
                  </Table.Cell>
                </Table.SummaryRow>
              </Table.Body>
            </Table>
          ) : (
            'Financial account summary is not available.'
          )}
        </Box>
      </Grid.Item>
      <Grid.Item xs={6}>
        <Box marginBottom={3}>
          <Subheading>Payment Performance - Previous 36 Months</Subheading>
          {data.payment_history_data?.payment_history &&
          data.payment_history_data?.payment_history.length > 0 ? (
            <Box>
              <Text>
                The results are listed in descending chronological order
                starting with{' '}
                <Text bold>
                  {toDate(data.payment_history_data.current_date).getMonth() +
                    1}
                  /
                  {toDate(data.payment_history_data.current_date).getFullYear()}
                </Text>{' '}
                and ending with{' '}
                <Text bold>
                  {toDate(data.payment_history_data.current_date).getMonth() +
                    1}
                  /
                  {toDate(
                    data.payment_history_data.current_date
                  ).getFullYear() - 3}
                </Text>
              </Text>
              <Box padding={3}>
                <PaymentHistoryGrid
                  paymentHistory={flattenPaymentHistoryData(
                    data.payment_history_data.payment_history
                  )}
                />
                <Text>* See legend below</Text>
              </Box>
            </Box>
          ) : (
            'Payment performance is not available.'
          )}
        </Box>
      </Grid.Item>
      <Grid.Item>
        <Subheading>Trade Lines</Subheading>
        <Table>
          <Table.Head>
            <Table.ColumnHeader />
            <Table.ColumnHeader>Name</Table.ColumnHeader>
            <Table.ColumnHeader>Business Category</Table.ColumnHeader>
            <Table.ColumnHeader>Date Reported</Table.ColumnHeader>
            <Table.ColumnHeader>Date Opened</Table.ColumnHeader>
            <Table.ColumnHeader>Last Activity</Table.ColumnHeader>
            <Table.ColumnHeader>Payment Terms</Table.ColumnHeader>
            <Table.ColumnHeader>Maturity Date</Table.ColumnHeader>
            <Table.ColumnHeader>Present Balance</Table.ColumnHeader>
            <Table.ColumnHeader>Recent High Credit</Table.ColumnHeader>
            <Table.ColumnHeader>Credit Limit</Table.ColumnHeader>
            <Table.ColumnHeader># of delinq. in last 36 mo</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {data.trade_line_data.length > 0 ? (
              data.trade_line_data.map((row) => (
                <Table.ExpandableRow
                  key={`${row.name}${row.account_number}${row.date_opened}`}
                  content={<TradeLineContent rowData={row} />}
                >
                  <Table.Cell>
                    {row.account_type} #{row.sequence_number}
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    {row.business_category}
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    {row.date_reported}
                  </Table.Cell>
                  <Table.Cell columnWidth="small">{row.date_opened}</Table.Cell>
                  <Table.Cell columnWidth="small">
                    {row.date_last_activity}
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    {row.payment_terms}
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    {row.maturity_date}
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    <Currency amount={row.present_balance} />
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    <Currency amount={row.recent_high_credit} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={row.credit_limit_or_loan_amount} />
                  </Table.Cell>
                  <Table.Cell columnWidth="small">
                    {(row.payment_history?.match(/1|2|3|4|5/g) || []).length}
                  </Table.Cell>
                </Table.ExpandableRow>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={12}>No Trade Line Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Grid.Item>
      <Grid.Item>
        <Box marginTop={3}>
          <Subheading>*Payment Performance / Payment History Legend</Subheading>
          <Grid>
            <Grid.Item xs={2}>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="C" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Current</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="0" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Current, zero balance</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="1" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>1-30 days late</Text>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item xs={2}>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="2" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>31-60 days late</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="3" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>61-90 days late</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="4" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>91-120 days late</Text>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item xs={2}>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="5" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>121+ days late</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="B" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Bankruptcy</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="G" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Collection</Text>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item xs={2}>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="H" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Foreclosure</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="J" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Voluntary Surrender</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={3}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="K" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Repossession</Text>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item xs={3}>
              <Grid gutter>
                <Grid.Item xs={2}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="L" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>Uncollected/Charge-off</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={2}>
                  <Box margin={1}>
                    <PaymentHistoryItem code="-" />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>No data reported</Text>
                  </Box>
                </Grid.Item>
              </Grid>
              <Grid gutter>
                <Grid.Item xs={2}>
                  <Box margin={1}>
                    <PaymentHistoryItem code={'\u2001'} />
                  </Box>
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Box marginTop={2}>
                    <Text>No prior payment history</Text>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </Box>
      </Grid.Item>
    </Grid>
  );
};
