import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Form,
  FormProps,
  IconButton,
  Modal,
  OptionShape,
  Select,
} from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { UpdateCustomerBody } from './businessOverview.types';

export type EditIndustryModalProps = {
  industry: string;
  options: OptionShape[];
  setIndustry: (industry: string) => void;
  updateIndustry: (industry: UpdateCustomerBody) => Promise<MutationResponse>;
  updatedIndustryError?: Error;
  updatedIndustryLoading?: boolean;
};

export const IndustryEditModal = ({
  industry,
  options,
  setIndustry,
  updateIndustry,
  updatedIndustryError,
  updatedIndustryLoading,
}: EditIndustryModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newIndustry, setNewIndustry] = useState<string>(industry);

  const [industryEditing, setIndustryEditing] = useState(false);
  const [industryError, setIndustryError] = useState('');
  const [industryCanceled, setIndustryCanceled] = useState(false);

  const [updatingIndustry, setUpdatingIndustry] = useState(false);

  const hasUpdatedIndustry = industry !== newIndustry;

  // Show errors.
  useEffect(() => {
    // 1. Don't show the error if we aren't editing (modal isn't open).
    // 2. Don't show the error if we previously canceled the changes. This one
    // is an edge case where we submit and get an error, and then cancel the
    // changes. Canceling closes the modal, and we don't want the same error to
    // appear again after re-opening.
    if (industryEditing && updatedIndustryError && !industryCanceled) {
      setIndustryError(updatedIndustryError.message);
    }
  }, [industryEditing, updatedIndustryError, industryCanceled]);

  const handleOpenChange = (): void => {
    // Reset any errors when opening the modal.
    if (!isModalOpen) {
      setIndustryError('');
      setIndustryEditing(true);
    }

    // Changes canceled.
    if (isModalOpen) {
      setNewIndustry(industry);
      setIndustryError('');
      setIndustryEditing(false);
      setIndustryCanceled(true);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit: FormProps['onSubmit'] = async (_sourceInfo) => {
    if (!newIndustry) {
      setIndustryError('Please select an industry.');
      return;
    }

    // No need to fire request if the industry hasn't changed.
    if (!hasUpdatedIndustry) {
      setIsModalOpen(false);
      return;
    }

    setUpdatingIndustry(true);
    setIndustryCanceled(false);

    const { success } = await updateIndustry({
      industryId: Number(newIndustry),
    });

    setUpdatingIndustry(false);

    if (success) {
      // This updates the value in the parent component.
      setIndustry(newIndustry);

      setIsModalOpen(false);
      setIndustryEditing(false);
    }
  };

  return (
    <Modal
      trigger={
        <IconButton icon="pencil-alt" hiddenLabel label="Edit Industry" />
      }
      isOpen={isModalOpen}
      title="Edit Industry"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {industryError && (
            <Banner dismissable={false}>{industryError}</Banner>
          )}

          <Form
            accessibleName="Edit Industry"
            onSubmit={handleSubmit}
            allowImplicitSubmission={true}
          >
            {({ fireSubmit }) => (
              <Flex flexDirection="column" gap={3}>
                <Select
                  label="Industry"
                  options={options}
                  value={newIndustry}
                  onValueChange={(value) => {
                    setNewIndustry(value);
                  }}
                />

                <Box marginY={2}>
                  <Button
                    onClick={fireSubmit}
                    disabled={updatingIndustry || updatedIndustryLoading}
                  >
                    Submit
                  </Button>
                </Box>
              </Flex>
            )}
          </Form>
        </Box>
      }
    />
  );
};
