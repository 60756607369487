import {
  UseFetchReturnCodesResponse,
  useFetchReturnCodes,
} from 'apiHooks/funding/returnCodesFetchHooks';
import { AchReturnCodes } from './achReturns.types';
import { AchReturnCodesResponse } from 'types/api/funding/types';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';

interface UseReturnCodesResponse
  extends Omit<UseFetchReturnCodesResponse, 'data'> {
  data?: AchReturnCodes;
}

const toReturnCodes = (
  data: AchReturnCodesResponse | undefined
): AchReturnCodes | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    codes: data.codes.map((returnCode) => ({
      code: returnCode.code,
      description: returnCode.description,
      reason: returnCode.reason,
      riskAndEnforcementCategory: returnCode.risk_and_enforcement_category,
      internal: returnCode.internal,
    })),
    returnsByType: Object.keys(data.returns_by_type).reduce(
      (acc, date) => ({
        ...acc,
        [date]: {
          returnedCount: data.returns_by_type[date].returned_count,
          unauthorizedReturnsCount:
            data.returns_by_type[date].unauthorized_returns_count,
          administrativeReturnsCount:
            data.returns_by_type[date].administrative_returns_count,
          totalPaybacksCount: data.returns_by_type[date].total_paybacks_count,
        },
      }),
      {}
    ),
    returnsByCode: Object.keys(data.returns_by_code).reduce((acc, date) => {
      const { total_paybacks_count, ...rest } = data.returns_by_code[date];
      return {
        ...acc,
        [date]: {
          ...rest,
          totalPaybacksCount: total_paybacks_count,
        },
      };
    }, {}),
  };
};

export const useReturnCodes = (
  provider: 'ach_works' | 'i_check_gateway'
): UseReturnCodesResponse =>
  useGenericFeatureQuery(useFetchReturnCodes, toReturnCodes, provider);
