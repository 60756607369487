import React from 'react';
import {
  Banner,
  Box,
  Card,
  Grid,
  Heading,
  IconButton,
  PageContainer,
} from '@forward-financing/fast-forward';
import { Params, useParams } from 'react-router-dom';
import { BusinessRegistrationsTable } from './BusinessRegistrationsTable';
import { useBusinessLexisNexisReport } from './businessLexisNexisPageHooks';
import { NameVariationsTable } from './NameVariationsTable';
import { BankruptciesTable } from './BankruptciesTable';
import { BusinessInformationTable } from './BusinessInformationTable';
import { EventsTable } from './EventsTable';
import { Profile } from './BusinessLexisNexisPage.types';
import { ExecutivesTable } from './ExecutivesTable';
import { LiensJudgmentsTable } from './LiensJudgmentsTable';
import { UccFilingsTable } from './UccFilingsTable';
import { BusinessContactTables } from './BusinessContactTables';
import { CorporateHistoriesTable } from './CorporateHistoriesTable';

export const BusinessLexisNexisPageContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const handleBackToSubmission = (): void => {
    const submissionUrl = new URL(
      `/submissions/${submissionUuid}`,
      window.location.origin
    );

    window.location.replace(submissionUrl.toString());
  };

  const { data: businessLexisNexisData, error: businessLexisNexisError } =
    useBusinessLexisNexisReport(submissionUuid);

  return (
    <PageContainer>
      <Box mt={2}>
        <IconButton
          label="Back to submission"
          onClick={handleBackToSubmission}
          icon={'circle-left'}
        />
      </Box>

      <Heading>LexisNexis Enhanced Business Report</Heading>

      {businessLexisNexisError && (
        <Banner variant="danger" dismissable={false}>
          {businessLexisNexisError.message}
        </Banner>
      )}

      <Grid gutter>
        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Business Information">
            <BusinessInformationTable
              businessInformation={businessLexisNexisData?.businessInformation}
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Business Name Variations">
            <NameVariationsTable
              names={businessLexisNexisData?.nameVariations}
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Business Contacts">
            <BusinessContactTables
              businessContacts={businessLexisNexisData?.profiles
                .map((profile) => profile.businessContacts)
                .flat()}
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Business Registrations">
            <BusinessRegistrationsTable
              businessRegistrations={
                businessLexisNexisData?.businessRegistrations
              }
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Corporate History">
            <CorporateHistoriesTable
              corporateHistories={businessLexisNexisData?.profiles
                .map((profile) => profile.corporateHistories)
                .flat()}
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Events">
            <EventsTable
              events={businessLexisNexisData?.profiles
                .map((profile: Profile) => profile.events)
                .flat()}
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Executives">
            <ExecutivesTable executives={businessLexisNexisData?.executives} />
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Liens/Judgments">
            <LiensJudgmentsTable
              lienJudgmentData={businessLexisNexisData?.liensJudgments}
            />
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="UCC Filings">
            <UccFilingsTable uccs={businessLexisNexisData?.uccFilings} />
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Bankruptcies">
            <BankruptciesTable
              bankruptcies={businessLexisNexisData?.bankruptcies}
            />
          </Card>
        </Grid.Item>
      </Grid>
    </PageContainer>
  );
};
