import {
  Banner,
  Button,
  Flex,
  Grid,
  Loading,
  Pagination,
  Table,
  Text,
  TextInput,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import React, { useCallback, useEffect, useState } from 'react';
import { useFetchQueuedApplications } from './SubmissionOnboardingQueueHooks';
import { timeBetweenDates } from 'helpers/string/dateUtils';
import { User } from './SubmissionOnboardingQueue.types';
import { InternalLink } from 'components/shared/InternalLink';
import { displayUserName } from './submissionOnboardingHelpers';
import { useUserContext } from 'contexts/UserContext';
import { featureFlags } from 'helpers/featureFlags';
import { useNavigate } from 'react-router';

export type SubmissionOnboardingQueueTableProps = {
  applicationStatus: string;
  users: User[];
};

export const SubmissionOnboardingQueueTable = ({
  applicationStatus,
  users,
}: SubmissionOnboardingQueueTableProps): JSX.Element => {
  const urlSearch = new URLSearchParams(window.location.search);

  const [page, setPage] = useState(Number(urlSearch.get('page')) || 1);
  const [searchInput, setSearchInput] = useState(urlSearch.get('search') || '');
  const [search, setSearch] = useState<string | undefined>(
    urlSearch.get('search') || undefined
  );

  const user = useUserContext();
  const navigate = useNavigate();

  const [
    fetchQueueData,
    { data: queueData, loading: queueDataLoading, error: queueDataError },
  ] = useFetchQueuedApplications();

  const changedQueueTab =
    urlSearch.get('status') && urlSearch.get('status') !== applicationStatus;

  useEffect(() => {
    if (changedQueueTab) {
      setPage(1);
      setSearch(undefined);
      setSearchInput('');
    }
  }, [changedQueueTab]);

  useEffect(() => {
    const updateUrlParams = (): void => {
      const searchParams = new URLSearchParams({
        status: applicationStatus,
        page: `${page}`,
        ...(search && { search: search }),
      });

      navigate(`?${searchParams}`);
    };

    updateUrlParams();
    if (!changedQueueTab) {
      void fetchQueueData(applicationStatus, page, search);
    }
  }, [
    applicationStatus,
    fetchQueueData,
    navigate,
    changedQueueTab,
    page,
    search,
  ]);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleSearch = (): void => {
    setPage(1);
    setSearch(searchInput);
  };

  const handleSearchInputChange = (newValue: string): void => {
    setSearchInput(newValue);
  };

  const handleClearSearch = (): void => {
    setSearchInput('');
    setSearch(undefined);
  };

  const noSubmissionsReturned =
    queueData?.queuedApplications && queueData.queuedApplications.length === 0;
  const hasSubmissions =
    queueData?.queuedApplications && queueData.queuedApplications.length > 0;

  // Only certain users can access queue items directly via links in the UI.
  // Other users must navigate to queue items only via the Go To Next button.
  const canReviewIndividualApplications =
    user.role === 'admin' || user.sub_role === 'team_lead';

  return (
    <>
      {queueDataError && (
        <Banner variant="error" dismissable={false}>
          {queueDataError.message}
        </Banner>
      )}

      {featureFlags.submission_onboarding_queue_search && (
        <Grid>
          <Grid.Item l={12} xl={6}>
            <Flex alignItems="flex-end" gap={2} marginBottom={3}>
              <TextInput
                label="Queue Search"
                onValueChange={handleSearchInputChange}
                value={searchInput}
                afterInputContent={
                  <>
                    {searchInput && (
                      <TextInput.IconButton
                        onClick={handleClearSearch}
                        description="clear"
                        icon={'times'}
                      />
                    )}
                  </>
                }
              />
              <Button startIcon="search" onClick={handleSearch}>
                Search
              </Button>
            </Flex>
          </Grid.Item>
        </Grid>
      )}

      {queueDataLoading && !queueData && <Loading />}

      {noSubmissionsReturned && <Text>No submissions in this queue</Text>}

      {hasSubmissions && (
        <>
          <Table caption={'Queue table'}>
            <Table.Head>
              <Table.ColumnHeader>Submission</Table.ColumnHeader>
              {applicationStatus !== 'available' && (
                <Table.ColumnHeader>Assignee</Table.ColumnHeader>
              )}
              <Table.ColumnHeader>Time in Queue</Table.ColumnHeader>
              <Table.ColumnHeader>Time Received</Table.ColumnHeader>
            </Table.Head>

            <Table.Body>
              {queueData &&
                queueData.queuedApplications.map(
                  ({
                    uuid,
                    emailSubject,
                    onboardingAnalystUuid,
                    createdAt,
                  }) => (
                    <Table.Row key={uuid}>
                      <Table.Cell>
                        {canReviewIndividualApplications ? (
                          <InternalLink
                            target="_blank"
                            to={`/dashboard/submission-onboarding/applications/${uuid}`}
                          >
                            {emailSubject}
                          </InternalLink>
                        ) : (
                          emailSubject
                        )}
                      </Table.Cell>

                      {applicationStatus !== 'available' && (
                        <Table.Cell>
                          {displayUserName(users, onboardingAnalystUuid)}
                        </Table.Cell>
                      )}

                      <Table.Cell>
                        {timeBetweenDates(new Date(), new Date(createdAt))}
                      </Table.Cell>

                      <Table.Cell>{formatDateTimeString(createdAt)}</Table.Cell>
                    </Table.Row>
                  )
                )}
            </Table.Body>
          </Table>

          {queueData && (
            <>
              <Flex flexDirection="row-reverse" padding={1}>
                <Text>{`Showing ${queueData.queuedApplications.length} of ${queueData.metadata.pagination.totalItems} total results`}</Text>
              </Flex>

              <Flex flexDirection="row-reverse">
                <Pagination
                  numPages={queueData.metadata.pagination.totalPages}
                  currentPage={queueData.metadata.pagination.page}
                  onPageClick={handlePageChange}
                />
                {queueDataLoading && <Loading size="small" />}
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};
