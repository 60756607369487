import React, { useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Flex,
  Banner,
  Subheading,
  Icon,
} from '@forward-financing/fast-forward';

import { useUpdateSubmission } from 'components/Navbar/SubmissionNavbar/navbarFetchHooks';
import { useUserContext } from 'contexts/UserContext';

export interface ToolboxPrequalAnalystModalProps {
  submissionUuid: string;
  refetchSubmission: () => void;
}

export const ToolboxPrequalAnalystModal = ({
  submissionUuid,
  refetchSubmission,
}: ToolboxPrequalAnalystModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { id: userId } = useUserContext();

  const [
    updateSubmission,
    { error: errorUpdateSubmission, loading: loadingUpdateSubmission },
  ] = useUpdateSubmission(submissionUuid);

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePrequalAnalystChange = async (): Promise<void> => {
    const { success } = await updateSubmission({ decisionAnalystId: userId });

    if (success) {
      setIsModalOpen(false);
      refetchSubmission();
    }
  };

  return (
    <>
      <Modal
        trigger={<Button variant={'cta'}>Assign Me as Prequal Analyst</Button>}
        isOpen={isModalOpen}
        title="Assign Me as Prequal Analyst Confirmation"
        onOpenChange={handleOpenChange}
        body={
          <Box>
            {errorUpdateSubmission && (
              <Banner dismissable={false}>
                {errorUpdateSubmission.message}
              </Banner>
            )}

            <Subheading variant="page">
              <Icon name="circle-exclamation" /> You are about to assign
              yourself as Prequal Analyst. Please confirm.
            </Subheading>

            <Flex
              justifyContent={'center'}
              flexDirection={'row'}
              gap={4}
              mt={5}
            >
              <Button
                onClick={handlePrequalAnalystChange}
                disabled={loadingUpdateSubmission}
              >
                Assign Me as Prequal Analyst
              </Button>
              <Button
                startIcon="times"
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
              >
                Cancel
              </Button>
            </Flex>
          </Box>
        }
      />
    </>
  );
};
