import { Subheading, Table, Text } from '@forward-financing/fast-forward';
import React from 'react';
import { CustomerExecutive } from './businessOverview.types';

export interface RelatedExecutivesTableProps {
  executives: CustomerExecutive[];
}

export const RelatedExecutivesTable = ({
  executives,
}: RelatedExecutivesTableProps): JSX.Element => {
  return (
    <>
      <Subheading variant="subsection">Related Executives</Subheading>
      {executives.length === 0 ? (
        <Text>No Related Executives</Text>
      ) : (
        <Table caption="Related Executives">
          <Table.Head>
            <Table.ColumnHeader>Name</Table.ColumnHeader>
            <Table.ColumnHeader>Titles</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {executives.map((exec) => (
              <Table.Row key={exec.id}>
                <Table.Cell>{exec.name}</Table.Cell>
                <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                  {exec.titles.join(', ')}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
