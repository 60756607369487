import React from 'react';
import {
  Address,
  Banner,
  Box,
  Flex,
  Icon,
  IconButton,
  Loading,
  Table,
  Tabs,
  Text,
  formatDateString,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import {
  BusinessCustomerLexisNexisReport,
  LexisNexisCustomerSearchResultDocument,
} from './businessOverview.types';
import { usePullCustomerLexisNexisReport } from './businessOverviewHooks';

export interface LexisNexisSearchResultsTableProps {
  searchResults: LexisNexisCustomerSearchResultDocument[];
  submissionUuid: string;
  customerLexisNexisReports?: BusinessCustomerLexisNexisReport[];
}

export const LexisNexisSearchResultsTable = (
  props: LexisNexisSearchResultsTableProps
): JSX.Element => {
  const [addReportToApplication, { data, loading, error }] =
    usePullCustomerLexisNexisReport(props.submissionUuid);

  const getPulledReportCreateDate = (
    identifier: string
  ): string | undefined => {
    if (!props.customerLexisNexisReports) {
      return undefined;
    }
    const pulledReports = props.customerLexisNexisReports.filter(
      (report) => report.reportIdentifier === identifier
    );
    return pulledReports.length > 0
      ? pulledReports.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0]
          .createdAt
      : undefined;
  };

  const showReportDate = (identifier: string): string | null => {
    const reportDate = getPulledReportCreateDate(identifier);
    if (reportDate) {
      return `Last pulled on ${formatDateTimeString(reportDate)}`;
    }
    return null;
  };
  return (
    <Box>
      {loading && <Loading size="small" text="Attempting to queue job" />}
      {data?.success && (
        <Banner dismissable={false} variant="success">
          <Flex gap={2}>
            <Icon name="circle-check" size="2x" />
            <Text>
              LexisNexis was pulled successfully! It takes anywhere from 5
              seconds to 4 minutes to process the data, so please allow a little
              bit of time before refreshing the page. If you are still not able
              to view the report after 4 minutes, please contact the tech
              support.
            </Text>
          </Flex>
        </Banner>
      )}
      {error && (
        <Banner dismissable={false} variant="error">
          {error.message}
        </Banner>
      )}
      {/**
       * This is a larger ternary than we typically like to see, but it is
       * very short lived only to support the feature flag. Once we remove
       * this flag, the entire ternary will be removed with only the if case
       * remaining.
       *
       * We considered a refactor for readability, but doing so would only
       * complicate the feature flag removal in the near future.
       *
       * - @bradleyden
       * Nov 29, 2023
       */}
      {featureFlags.send_multiple_ln_search_results ? (
        <Tabs defaultValue="tab0">
          <Tabs.List>
            {props.searchResults.map((document, index) => (
              <Tabs.Trigger key={document.createdAt} value={`tab${index}`}>
                {`${document.query?.companyName ?? 'N/A'} - ${formatDateString(
                  document.createdAt
                )} (${document.results.length})`}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {props.searchResults.map((document, index) => (
            <Tabs.Content key={document.createdAt} value={`tab${index}`}>
              {document.results.length === 0 ? (
                <Text>No search results</Text>
              ) : (
                <Table caption="Lexis Nexis Customer Search Results">
                  <Table.Head>
                    <Table.ColumnHeader>Name</Table.ColumnHeader>
                    <Table.ColumnHeader>Address</Table.ColumnHeader>
                    <Table.ColumnHeader>Has UCCs</Table.ColumnHeader>
                    <Table.ColumnHeader>Report</Table.ColumnHeader>
                  </Table.Head>
                  <Table.Body>
                    {document.results.map((result) => (
                      <Table.Row key={result.reportId}>
                        <Table.Cell>{result.name}</Table.Cell>
                        <Table.Cell>
                          <Address
                            streetAddress={result.address.streetAddress ?? ''}
                            city={result.address.city ?? ''}
                            state={result.address.state ?? ''}
                            zipCode={result.address.zip ?? ''}
                          />
                        </Table.Cell>
                        <Table.Cell>{result.hasUcc ? 'Yes' : 'No'}</Table.Cell>
                        <Table.Cell>
                          <IconButton
                            label="Add to Customer"
                            icon="plus-circle"
                            disabled={loading}
                            onClick={() =>
                              addReportToApplication({
                                reportId: result.reportId,
                              })
                            }
                          />
                          <Text italic>{showReportDate(result.reportId)}</Text>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </Tabs.Content>
          ))}
        </Tabs>
      ) : (
        props.searchResults[0] &&
        props.searchResults[0].results.length > 0 && (
          <Table caption="Lexis Nexis Customer Search Results">
            <Table.Head>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Address</Table.ColumnHeader>
              <Table.ColumnHeader>Has UCCs</Table.ColumnHeader>
              <Table.ColumnHeader>Report</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              {props.searchResults[0].results.map((result) => (
                <Table.Row key={result.reportId}>
                  <Table.Cell>{result.name}</Table.Cell>
                  <Table.Cell>
                    <Address
                      streetAddress={result.address.streetAddress ?? ''}
                      city={result.address.city ?? ''}
                      state={result.address.state ?? ''}
                      zipCode={result.address.zip ?? ''}
                    />
                  </Table.Cell>
                  <Table.Cell>{result.hasUcc ? 'Yes' : 'No'}</Table.Cell>
                  <Table.Cell>
                    <IconButton
                      label="Add to Customer"
                      icon="plus-circle"
                      disabled={loading}
                      onClick={() =>
                        addReportToApplication({
                          reportId: result.reportId,
                        })
                      }
                    />
                    <Text italic>{showReportDate(result.reportId)}</Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )
      )}
    </Box>
  );
};
