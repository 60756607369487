import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Banner,
  Heading,
  Loading,
  PageContainer,
  Text,
} from '@forward-financing/fast-forward';
import { useGetExperianConsumerArchive } from '../ownerOverviewHooks';
import { ExperianConsumerArchiveTable } from './ExperianConsumerArchiveTable';

type Params = {
  ownerUuid: string;
};

export const ExperianConsumerArchiveContainer = (): JSX.Element => {
  const { ownerUuid } = useParams<Params>();

  if (!ownerUuid) {
    throw new Error('Owner UUID param not set');
  }

  const {
    data: reports,
    loading,
    error,
  } = useGetExperianConsumerArchive(ownerUuid);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <Heading>Experian Consumer Report Archives</Heading>

      {error && <Banner dismissable={false}>{error.message}</Banner>}

      {reports && reports.length > 0 ? (
        <ExperianConsumerArchiveTable reports={reports} />
      ) : (
        // We shouldn't ever see this because the "Archive" link is hidden
        // when there are no reports.
        <Text>This owner has no Experian Consumer Report Archives</Text>
      )}
    </PageContainer>
  );
};
