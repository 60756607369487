import React from 'react';
import { Box, Accordion, Text } from '@forward-financing/fast-forward';
import { SummaryRow } from '../SummaryRow';
import { ExceptionRequest } from '../exceptionsRequest.types';
import { ExceptionsRequestRow } from './ExceptionsRequestRow';
import { UNDERWRITERS_KEY } from '../exceptionRequestConstants';

export interface ExceptionsRequestProps {
  exceptionsRequestList: ExceptionRequest[];
  loadList: () => void;
}

export const ExceptionsRequest = (
  props: ExceptionsRequestProps
): JSX.Element => {
  return (
    <Box>
      {props.exceptionsRequestList.length === 0 ? (
        <Text>There are currently no exception requests for this ledger.</Text>
      ) : (
        <Box>
          <Accordion
            type="multiple"
            defaultValue={[props.exceptionsRequestList[0].id.toString()]}
          >
            {props.exceptionsRequestList.map((exceptionRequest) => {
              return (
                <Accordion.Item
                  key={exceptionRequest.id}
                  value={exceptionRequest.id.toString()}
                >
                  <Accordion.Trigger>
                    <SummaryRow
                      displayStatus={exceptionRequest.displayStatus}
                      submittedAt={exceptionRequest.submittedAt}
                      viewer={UNDERWRITERS_KEY}
                      collaboratorName={exceptionRequest.requestingUser}
                      reviewCompletionTime={exceptionRequest.reviewCompletedAt}
                      decisionType={exceptionRequest.decisionType}
                    />
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <ExceptionsRequestRow
                      exceptionRequest={exceptionRequest}
                      loadList={props.loadList}
                    />
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Box>
      )}
    </Box>
  );
};
