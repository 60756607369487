import { formatCurrency } from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { z } from 'zod';

import { toDate } from 'helpers/string/dateUtils';
import { displayPercentage } from 'helpers/utils';
import {
  LEDGER_TABLES_IDX_MONTH_NAME_MAP,
  LEDGER_TABLES_MONTHS,
} from '../constants';
import {
  DOLLAR_FIELDS,
  OFFERS_TABLE_FIELD_NAME_LABEL_MAP,
  PERCENT_FIELDS,
} from './offers.constants';
import { LedgerOffer } from './offers.types';

/**
 * Generates an array of strings in the format $monthNumber - $monthName.
 * The month number is calculated from the creation date, counting n months
 * from that date for each item in the OFFERS_TABLE_MONTHS array.
 */
export const generateOffersTableColumnHeaders = (
  creationDateString: string,
  includeYear = false
): string[] => {
  const creationDate = toDate(
    creationDateString.replace('Z', ''),
    "yyyy-MM-dd'T'HH:mm:ss.SSS"
  );

  return LEDGER_TABLES_MONTHS.map((monthOffset) => {
    const date = new Date(creationDate);
    date.setMonth(date.getMonth() + monthOffset);

    const monthNumber = date.getMonth();
    const year = date.getFullYear();
    const monthName = LEDGER_TABLES_IDX_MONTH_NAME_MAP[monthNumber.toString()];

    return `${monthOffset} - ${monthName}${includeYear ? ` ${year}` : ''}`;
  });
};

export const isLedgerOfferKey = (key: string): key is keyof LedgerOffer =>
  Object.keys(OFFERS_TABLE_FIELD_NAME_LABEL_MAP()).includes(key);

export const isLedgerOffer = (
  offer: LedgerOffer | unknown
): offer is LedgerOffer => {
  return (
    typeof offer === 'object' &&
    offer !== null &&
    (Object.keys(offer).includes('gross') ||
      Object.keys(offer).includes('dollarOverride'))
  );
};

/**
 * Formats a field value from a ledger offer based on the field type.
 *
 * @param offer - A partial ledger offer object which may contain the field to be formatted.
 * @param fieldName - The name of the field to be formatted.
 * @returns The formatted field value as a string. Returns an empty string if the field is not valid or the offer is undefined.
 *
 * The function performs the following formatting based on the field type:
 * - If the field is a dollar field, it formats the value as currency.
 * - If the field is a percent field, it formats the value as a percentage.
 * - Otherwise, it converts the field value to a string.
 */
export const formatLedgerOfferField = (
  offer: Partial<LedgerOffer> | undefined,
  fieldName: string
): string => {
  if (!isLedgerOfferKey(fieldName) || !offer || !offer[fieldName]) return '';

  if (DOLLAR_FIELDS.includes(fieldName) && !isNaN(Number(offer[fieldName]))) {
    return formatCurrency(Number(offer[fieldName]));
  }

  if (PERCENT_FIELDS.includes(fieldName) && !isNaN(Number(offer[fieldName]))) {
    return displayPercentage(Number(offer[fieldName]));
  }

  return defaultTo(String(offer?.[fieldName]), '');
};

/**
 * Updates a given offer object with a new value for a specified field.
 * If the field being updated is 'gross', it will unset the 'dollarOverride' field.
 * If the field being updated is 'dollarOverride', it will unset the 'gross' field.
 */
export const getUpdatedOffer = (
  offer: Partial<LedgerOffer>,
  field: keyof LedgerOffer,
  value: string | number
): Partial<LedgerOffer> => ({
  ...offer,
  [field]: value,
  ...(field === 'gross' && { dollarOverride: undefined }),
  ...(field === 'dollarOverride' && { gross: undefined }),
});

/**
 * Schema for validating dollar override values.
 * The dollar override represents amount of USD and its
 * value can either be an empty string or a number between 5000 and 500000.
 * This validation is required to make sure that we're
 * sending correct values to BE when calculating offer.
 */
const dollarOverrideSchema = z
  .string()
  .length(0)
  .or(z.coerce.number().min(5000).max(500000));

/**
 * Schema for validating gross values.
 * The gross represents amount of percents and its
 * value can either be an empty string or a number between 1 and 200.
 * This validation is required to make sure that we're
 * sending correct values to BE when calculating offer.
 */
const grossSchema = z.string().length(0).or(z.coerce.number().min(1).max(200));

export const isValidOfferCellValue = (
  fieldName: string,
  value: string
): boolean => {
  if (fieldName === 'dollarOverride') {
    return dollarOverrideSchema.safeParse(value).success;
  }

  if (fieldName === 'gross') {
    return grossSchema.safeParse(value).success;
  }

  /**
   * We're not validating other fields as they're not editable.
   */
  return true;
};
