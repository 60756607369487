import React from 'react';
import { List, Text } from '@forward-financing/fast-forward';
import { SubmissionMatchCriteria } from './HighRiskReview.types';

export interface MatchCriteriaListProps {
  criteria: SubmissionMatchCriteria;
}

export const MatchCriteriaList = ({
  criteria,
}: MatchCriteriaListProps): JSX.Element => {
  return (
    <>
      <Text bold>Matching Criteria</Text>
      <List>
        {criteria.dba && <List.Item>DBA Name</List.Item>}
        {criteria.legalName && <List.Item>Legal Name</List.Item>}
        {criteria.fein && <List.Item>FEIN</List.Item>}
        {criteria.ownerName && <List.Item>Owner Name</List.Item>}
        {criteria.ownerSsn && <List.Item>Owner SSN</List.Item>}
        {criteria.ownerEmail && <List.Item>Owner Email</List.Item>}
        {criteria.ownerAddress && <List.Item>Owner Address</List.Item>}
        {criteria.ownerPhone && <List.Item>Owner Phone</List.Item>}
        {criteria.businessAddress && <List.Item>Business Address</List.Item>}
        {criteria.businessPhone && <List.Item>Business Phone</List.Item>}
      </List>
    </>
  );
};
