import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { UccFiling } from './BusinessLexisNexisPage.types';

type UccFilingsTableProps = {
  uccs?: UccFiling[];
};

export const UccFilingsTable = ({
  uccs,
}: UccFilingsTableProps): JSX.Element => {
  const hasUccs = uccs && uccs.length > 0;

  return hasUccs ? (
    <Table caption="UCC Filings">
      <Table.Head>
        <Table.ColumnHeader>Debtor</Table.ColumnHeader>
        <Table.ColumnHeader>Secured</Table.ColumnHeader>
        <Table.ColumnHeader>Origin Filing Date</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
        <Table.ColumnHeader>Collateral</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {uccs?.map((row) => (
          <Table.Row
            key={`${row.status} ${row.collateral} ${row.originFilingDate}`}
          >
            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.debtor.length > 0 &&
                row.debtor.map((deb) => (
                  <Box key={deb.originName} mb={4}>
                    <Text>{deb.originName}</Text>
                    {deb.addresses?.map((address) => (
                      <Text size="s" italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.secured.length > 0 &&
                row.secured.map((sec) => (
                  <Box key={sec.originName} mb={4}>
                    <Text>{sec.originName}</Text>
                    {sec.addresses?.map((address) => (
                      <Text size="s" italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell columnWidth="small">{row.originFilingDate}</Table.Cell>

            <Table.Cell columnWidth="small">{row.status}</Table.Cell>

            <Table.Cell overflowStrategy="wrap" columnWidth="large">
              {row.collateral.length > 0 && row.collateral.map((col) => col)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('UCC filings')}</Text>
    </Box>
  );
};
