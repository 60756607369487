import React from 'react';
import { Table, Text } from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { displayPercentage } from 'helpers/utils';
import { OwnerRiskData } from '../renewalComparison.types';
import { ChangeComparison } from './ChangeComparison';

const UNABLE_TO_FETCH = <Text italic>Unable to fetch</Text>;

const renderPercentage = (potentialNumber?: number): string | JSX.Element => {
  if (typeof potentialNumber === 'number') {
    return displayPercentage(potentialNumber);
  }

  return defaultTo(potentialNumber, UNABLE_TO_FETCH);
};

const ScoreComparison = ({
  currentValue,
  previousValue,
}: {
  currentValue?: number;
  previousValue?: number;
}): JSX.Element => {
  if (typeof currentValue !== 'number') {
    return UNABLE_TO_FETCH;
  }

  if (typeof previousValue === 'number') {
    return (
      <ChangeComparison
        currentValue={currentValue}
        previousValue={previousValue}
      />
    );
  }
  return <Text>{currentValue}</Text>;
};

export type OwnerRiskSectionProps = {
  current?: OwnerRiskData;
  prior?: OwnerRiskData;
};

export const OwnerRiskTable = ({
  current,
  prior,
}: OwnerRiskSectionProps): JSX.Element => {
  return (
    <>
      {current && (
        <Table caption="Owner Risk Table">
          <Table.Head>
            <Table.ColumnHeader>Owner Risk</Table.ColumnHeader>
            <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
            <Table.ColumnHeader>Prior Advance</Table.ColumnHeader>
          </Table.Head>

          <Table.Body>
            <Table.Row>
              <Table.RowHeader>FICO</Table.RowHeader>

              <Table.Cell>
                <ScoreComparison
                  currentValue={current.ficoScore}
                  previousValue={prior?.ficoScore}
                />
              </Table.Cell>

              <Table.Cell>
                {defaultTo(prior?.ficoScore, UNABLE_TO_FETCH)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>BK Plus</Table.RowHeader>

              <Table.Cell>
                <ScoreComparison
                  currentValue={current.bankruptcyPlus}
                  previousValue={prior?.bankruptcyPlus}
                />
              </Table.Cell>

              <Table.Cell>
                {defaultTo(prior?.bankruptcyPlus, UNABLE_TO_FETCH)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Vantage</Table.RowHeader>

              <Table.Cell>
                <ScoreComparison
                  currentValue={current.vantageScore}
                  previousValue={prior?.vantageScore}
                />
              </Table.Cell>

              <Table.Cell>
                {defaultTo(prior?.vantageScore, UNABLE_TO_FETCH)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Total Delinquent Trade Lines</Table.RowHeader>

              <Table.Cell>
                {defaultTo(current.totalDelinquentTradeLines, UNABLE_TO_FETCH)}
              </Table.Cell>

              <Table.Cell>
                {defaultTo(prior?.totalDelinquentTradeLines, UNABLE_TO_FETCH)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Debt to High Credit</Table.RowHeader>

              <Table.Cell>
                {renderPercentage(current.debtToHighCredit)}
              </Table.Cell>

              <Table.Cell>
                {renderPercentage(prior?.debtToHighCredit)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
