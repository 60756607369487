import React from 'react';
import {
  IconButton,
  Text,
  Card,
  Box,
  Link,
} from '@forward-financing/fast-forward';
import { useViewHistoryContext } from '../../contexts/ViewHistoryProvider';
import { ViewHistoryEntry } from '../../contexts/stores/ViewHistoryStore';

const LivePipelineRecentlyViewedItemLink = ({
  entry,
}: {
  entry: ViewHistoryEntry;
}): JSX.Element => {
  return (
    <div>
      <Link
        href={
          new URL(`/submissions/${entry.objectUUID}`, window.location.origin)
        }
        newTab
      >
        {entry.title}
      </Link>
    </div>
  );
};

export function LivePipelineRecentDealsBox(): JSX.Element {
  const {
    historyData: { submissions },
    refreshModel,
  } = useViewHistoryContext();

  return (
    <Box margin={2}>
      <Card
        title="Recently Viewed"
        actions={
          <IconButton
            onClick={() => refreshModel('submissions')}
            icon="arrow-rotate-right"
            label="Refresh"
          />
        }
      >
        <Box>
          {submissions.length > 0 ? (
            submissions.map((s) => (
              <LivePipelineRecentlyViewedItemLink
                key={s.objectUUID}
                entry={s}
              />
            ))
          ) : (
            <Text>No recent items</Text>
          )}
        </Box>
      </Card>
    </Box>
  );
}
