import React from 'react';

import { Navbar } from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';

export interface ToolboxEditingSubmissionLinkProps {
  applicationUuid: string | undefined;
  submissionUuid: string;
}
export const ToolboxEditingSubmissionLink = ({
  applicationUuid,
  submissionUuid,
}: ToolboxEditingSubmissionLinkProps): JSX.Element => {
  if (!applicationUuid) {
    return <></>;
  }

  const newSubmissionEditingUrl = new URL(
    `/submissions/${submissionUuid}/editing`,
    window.location.origin
  );
  const oldSubmissionEditingUrl = new URL(
    `/submissions/${applicationUuid}/edit`,
    window.location.origin
  );
  return (
    <Navbar.Link
      icon="pencil"
      href={
        featureFlags.use_new_submission_editing_form
          ? newSubmissionEditingUrl
          : oldSubmissionEditingUrl
      }
    >
      Edit Submission
    </Navbar.Link>
  );
};
