import React from 'react';
import { useParams } from 'react-router-dom';
import { AdvancePrepayment } from './AdvancePrepayment';

type Params = {
  opportunityUuid: string;
};

export const AdvancePrepaymentContainer = (): JSX.Element => {
  const { opportunityUuid } = useParams<Params>();

  if (!opportunityUuid) {
    throw new Error('No opportunityUuid param set in URL');
  }

  return <AdvancePrepayment opportunityUuid={opportunityUuid} />;
};
