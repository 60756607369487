import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { BankNameResponse } from 'types/api/underwriting/types';

export const fetchBanks = async (): Promise<BankNameResponse[]> => {
  const url = new URL('api/v2/banks', UNDERWRITING_BASE_URL());

  const response = await makeInternalAPIRequest<BankNameResponse[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong fetching Bank Names'
    );
  }

  return response.json();
};

type BankBody = {
  name: string;
};

export const createBank = async (
  bankName: string
): Promise<BankNameResponse> => {
  const url = new URL('api/v2/banks', UNDERWRITING_BASE_URL());

  const body = { name: bankName };
  const response = await makeInternalAPIRequest<BankNameResponse, BankBody>(
    url,
    'POST',
    body
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong creating Bank Name'
    );
  }

  return response.json();
};
