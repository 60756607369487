import React, { useState, useEffect } from 'react';
import { Box, Loading, Banner, Text } from '@forward-financing/fast-forward';
import {
  Customer,
  CustomerSearchParams,
} from '../../../api/UnderwritingClient/codecs';
import { CustomerOption } from './CustomerSearch.types';
import orderBy from 'lodash/orderBy';
import { UnderwritingClient } from '../../../api/UnderwritingClient';
import { CustomerList } from './CustomerList';
import { toError } from 'helpers/errorUtils';

export interface CustomerSearchResultsProps {
  handleOnChange: (customer: Customer) => void;
  customer: Customer;
  selectedCustomerUuid: string;
}

// We sanitize values that could be saved in our database with different formats,
// like the FEIN or Phone, in order to do a exact match
const sanitizeValue = (value: string): string => {
  // Replace all special characters (like comma and points) and white spaces if the value is not null
  return value ? value.replace(/[^a-zA-Z0-9]/g, '') : '';
};

const isRecommended = (
  params: CustomerSearchParams,
  customer: Customer
): boolean =>
  (params.name === customer.name &&
    sanitizeValue(params.legal_name) === sanitizeValue(customer.legal_name) &&
    sanitizeValue(params.phone) === sanitizeValue(customer.phone || '') &&
    params.city === customer.address.city &&
    params.street1 === customer.address.street1) ||
  sanitizeValue(params.fein || '') === sanitizeValue(customer.fein || '');

export const CustomerSearchResults = (
  props: CustomerSearchResultsProps
): JSX.Element => {
  const { customer } = props;

  const [customers, setCustomers] = useState<CustomerOption[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params: CustomerSearchParams = {
      name: customer.name,
      legal_name: customer.legal_name,
      phone: customer.phone || '',
      city: customer.address.city,
      street1: customer.address.street1,
      fein: customer.fein || undefined,
    };

    UnderwritingClient.searchCustomers(params)
      .then((customers) => {
        let found = false;
        const options: CustomerOption[] = customers.reduce(
          (customers: Customer[], customer: Customer) => {
            if (!found && isRecommended(params, customer)) {
              found = true;
              return [...customers, { ...customer, recommended: true }];
            } else {
              return [...customers, { ...customer, recommended: false }];
            }
          },
          []
        );

        setCustomers(
          orderBy(options, ['recommended', 'created_at'], ['desc', 'desc'])
        );
      })
      .catch((e: unknown) => {
        const error = toError(e);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);

  return (
    <Box padding={2}>
      {isLoading ? (
        <Loading size="medium" />
      ) : (
        <Box padding={3}>
          {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
          {customers.length > 0 ? (
            <Box>
              <Box marginBottom={3}>
                <Text>Number of matching customers: {customers.length}</Text>
              </Box>
              <CustomerList
                customers={customers}
                selectedCustomerUuid={props.selectedCustomerUuid}
                handleOnChange={(uuid) => {
                  const customerOption = customers.find((customer) => {
                    return customer.uuid === uuid;
                  });
                  customerOption && props.handleOnChange(customerOption);
                }}
              />
            </Box>
          ) : (
            !errorMessage && <Text>There are no matching customers.</Text>
          )}
        </Box>
      )}
    </Box>
  );
};
