import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { Bankruptcy } from './BusinessLexisNexisPage.types';

type BankruptciesTableProps = {
  bankruptcies?: Bankruptcy[];
};

export const BankruptciesTable = ({
  bankruptcies,
}: BankruptciesTableProps): JSX.Element => {
  const hasBankruptcies = bankruptcies && bankruptcies.length > 0;

  return hasBankruptcies ? (
    <Table caption="Bankruptcies">
      <Table.Head>
        <Table.ColumnHeader>Debtor</Table.ColumnHeader>
        <Table.ColumnHeader>Type</Table.ColumnHeader>
        <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
        <Table.ColumnHeader>Status History</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {bankruptcies?.map((bankruptcy) => (
          <Table.Row key={bankruptcy.filingDate}>
            <Table.Cell>
              {bankruptcy.debtorNames
                .map((item) => item.originalName)
                .join('\n')}
            </Table.Cell>
            <Table.Cell>{bankruptcy.chapter}</Table.Cell>
            <Table.Cell>{bankruptcy.filingDate}</Table.Cell>
            <Table.Cell>
              {bankruptcy.statusHistory &&
              bankruptcy.statusHistory.length > 0 ? (
                <Table>
                  <Table.Body>
                    {bankruptcy.statusHistory.map((status) => (
                      <Table.Row key={status.type}>
                        <Table.Cell>{status.type}</Table.Cell>
                        <Table.Cell>{status.date}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : (
                <Text>No Status History</Text>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Bankruptcies')}</Text>
    </Box>
  );
};
