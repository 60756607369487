import React, { useState } from 'react';
import { Banner, Box, Button, Flex } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { DownloadAllAttachmentsButton } from 'components/AttachmentManager/DownloadAllAttachmentsButton';
import { useAttachmentManagerContext } from 'components/AttachmentManager/AttachmentManagerContext';
import { featureFlags } from 'helpers/featureFlags';
import { StipulationRecommendationsContainer } from 'components/StipulationRecommendations/StipulationRecommendationsContainer';
import { Attachment } from '../AttachmentManager/attachmentManager.types';
import { UnderwritingSignOffModal } from '../AttachmentManager/UnderwritingAttachmentManager/UnderwritingSignOffModal';
import { Stipulation } from './stipulationManager.types';
import { StipulationTable } from './StipulationTable';
import { CreateStipulationModal } from './CreateStipulationModal';
import { EditStipulationModal } from './EditStipulationModal';

export interface NewStipulationManagerProps {
  submissionUuid: string;
  stipulations: Stipulation[];
  submissionSubStage: string | undefined;
  attachments: Attachment[];
  setUnderwriterSignOff: (underwriterSignOffId: number) => void;
}

export const NewStipulationManager = ({
  submissionUuid,
  stipulations,
  submissionSubStage,
  attachments,
  setUnderwriterSignOff,
}: NewStipulationManagerProps): JSX.Element => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { sub_role, id: currentUserId } = useUserContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { isSalesforce } = useAttachmentManagerContext();

  const onStipRowChecked = (stipulationtId: string, checked: boolean): void => {
    if (checked) {
      setSelectedIds([...selectedIds, stipulationtId]);
    } else {
      setSelectedIds(selectedIds.filter((a) => a !== stipulationtId));
    }
  };

  const toggleAllChecked = (allChecked: boolean): void => {
    if (allChecked) {
      setSelectedIds(stipulations.map((s) => s.uuid));
    } else {
      setSelectedIds([]);
    }
  };

  const showUwSignOffButton = (): boolean => {
    if (featureFlags.move_assignment_out_of_live_pipeline) {
      return false;
    }

    if (sub_role?.includes('vca') && submissionSubStage === 'In Verification') {
      return true;
    }

    if (
      sub_role?.includes('underwriter') &&
      submissionSubStage === 'Final UW Signoff'
    ) {
      return true;
    }

    return false;
  };

  return (
    <Box>
      {errorMessage && (
        <Banner variant="error" dismissable={false}>
          {errorMessage}
        </Banner>
      )}
      <Flex justifyContent="flex-end" gap={2}>
        {featureFlags.show_stipulation_recommendation_text && (
          <StipulationRecommendationsContainer
            submissionUuid={submissionUuid}
          />
        )}

        {!isSalesforce && <CreateStipulationModal />}

        {!isSalesforce && (
          <EditStipulationModal
            selectedStipulations={stipulations.filter((stip) =>
              selectedIds.includes(stip.uuid)
            )}
            onSaveComplete={() => setSelectedIds([])}
            trigger={
              <Button
                startIcon="pencil-alt"
                disabled={selectedIds.length === 0}
              >
                Update Stipulation(s)
              </Button>
            }
          />
        )}
        {showUwSignOffButton() && (
          <>
            <Button
              startIcon={'user-check'}
              onClick={() => setUnderwriterSignOff(currentUserId)}
            >
              Assign Me as UW Sign Off
            </Button>
            <UnderwritingSignOffModal
              setUnderwriterSignOff={setUnderwriterSignOff}
            />
          </>
        )}
        <DownloadAllAttachmentsButton
          source="closing_documents"
          setErrorMessage={setErrorMessage}
          disabledForDocumentRetention={attachments.every(
            (att) => !!att.fileDeletedAt
          )}
        />
      </Flex>
      <StipulationTable
        attachments={attachments}
        stipulations={stipulations}
        selectedIds={selectedIds}
        onRowSelect={onStipRowChecked}
        toggleAllChecked={toggleAllChecked}
      />
    </Box>
  );
};
