export enum StepStatus {
  Inactive,
  Active,
  Completed,
  Incomplete,
}

export enum WizardSteps {
  AccountInformation,
  OwnerInformation,
  AdditionalInformation,
  CustomerSearchResults,
  Overview,
  PrequalComplete,
}

export interface StepProgress {
  step: WizardSteps;
  status: StepStatus;
}

export enum WizardProgressIconSize {
  Small = '54px',
  Large = '70px',
}

export interface IconSize {
  width: string;
  height: string;
}

export const SmallIcon: IconSize = {
  width: WizardProgressIconSize.Small,
  height: WizardProgressIconSize.Small,
};

export const LargeIcon: IconSize = {
  width: WizardProgressIconSize.Large,
  height: WizardProgressIconSize.Large,
};

export enum WizardProgressIconColor {
  Green = '#007853',
  Gray = '#8596B3',
  Red = '#E4002B',
}

export interface IconColor {
  stroke: string;
  fill: string;
}

export const GreenIcon: IconColor = {
  stroke: WizardProgressIconColor.Green,
  fill: WizardProgressIconColor.Green,
};

export const EvergreenIcon: IconColor = {
  stroke: '#113841',
  fill: '#113841',
};

export const GrayIcon: IconColor = {
  stroke: WizardProgressIconColor.Gray,
  fill: WizardProgressIconColor.Gray,
};

export const RedIcon: IconColor = {
  stroke: WizardProgressIconColor.Red,
  fill: WizardProgressIconColor.Red,
};
