import {
  Banner,
  Button,
  Flex,
  Icon,
  Modal,
  Select,
  Subheading,
  TextArea,
} from '@forward-financing/fast-forward';
import React, { useEffect, useState } from 'react';
import {
  useGetStageTransitions,
  useUpdateSubmission,
} from '../../Navbar/SubmissionNavbar/navbarFetchHooks';
import { SendDealForm } from '../../Navbar/SubmissionNavbar/navbar.types';
import { useLazyGetSubmissionEligibility } from '../ApplicationSnapshot/applicationSnapshotFetchHooks';
import { featureFlags } from 'helpers/featureFlags';
import { ToolboxSubmission } from './Toolbox.types';

export interface ToolboxSendDealToModalProps {
  submission: ToolboxSubmission;
  refetchSubmission: () => void;
}

export const ToolboxSendDealToModal = ({
  submission,
  refetchSubmission,
}: ToolboxSendDealToModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [sendDealForm, setSendDealForm] = useState<SendDealForm>({
    transition: '',
    stageChangeNotes: '',
  });
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const [fetchEligibility, { data: internalRenewalEligibility }] =
    useLazyGetSubmissionEligibility();

  const [updateSubmission, { error: errorUpdateSubmission }] =
    useUpdateSubmission(submission.uuid);

  const { data: stageTransitions, error: stageTransitionError } =
    useGetStageTransitions(submission?.uuid);

  useEffect(() => {
    if (submission.previousFundedSubmissionUuid) {
      void fetchEligibility(submission.previousFundedSubmissionUuid);
    }
  }, [fetchEligibility, submission.previousFundedSubmissionUuid]);

  const handleOpenChange = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  const handleStageChange = async (): Promise<void> => {
    const transitionObject = stageTransitions?.find(
      (transition) =>
        `${transition.stage}${
          transition.subStage ? `/${transition.subStage}` : ''
        }` === sendDealForm.transition
    );

    const { success } = await updateSubmission({
      stage: transitionObject?.stage,
      subStage: transitionObject?.subStage || null,
      stageChangeNotes: sendDealForm.stageChangeNotes,
      changedByMoveDealModal: true,
    });

    if (success) {
      refetchSubmission();
      setIsOpen(false);
    }
  };

  const shouldBeDisabled =
    !!stageTransitionError || stageTransitions?.length === 0;

  if (!stageTransitions) {
    return <></>;
  }

  const aboutToSkipInternalRenewal =
    submission.type === 'Renewal' &&
    sendDealForm.transition === 'Processing/Pre-qualification' &&
    internalRenewalEligibility?.eligible;

  const ConfirmationMessage = (): JSX.Element => {
    return (
      <Flex flexDirection={'column'} gap={2}>
        <Subheading variant="page">
          <Icon name="circle-exclamation" /> This Submission is eligible for an
          internal renewal. Please confirm that you wish to proceed with this
          stage change instead.
        </Subheading>
        <Flex justifyContent="center" gap={2}>
          <Button
            startIcon="x"
            variant="secondary"
            onClick={() => setShowConfirmationMessage(false)}
          >
            Cancel
          </Button>
          <Button startIcon={'timeline'} onClick={handleStageChange}>
            Confirm Stage Change
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Modal
        title="Send Deal To..."
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        body={
          <>
            {errorUpdateSubmission && (
              <Banner dismissable={false} variant="error">
                <Flex gap={3}>
                  <Icon size="lg" name="triangle-exclamation" />
                  {errorUpdateSubmission.message}
                </Flex>
              </Banner>
            )}
            {showConfirmationMessage &&
            featureFlags.external_renewal_confirmation_flow ? (
              <ConfirmationMessage />
            ) : (
              <Flex flexDirection={'column'} gap={2}>
                <Subheading variant="section">
                  Please provide details.
                </Subheading>
                <Select
                  label="Select Department"
                  required
                  value={sendDealForm.transition}
                  onValueChange={(newValue) => {
                    setSendDealForm({
                      ...sendDealForm,
                      transition: newValue,
                    });
                  }}
                  options={stageTransitions?.map((transition) => {
                    return {
                      value: `${transition.stage}${
                        transition.subStage ? `/${transition.subStage}` : ''
                      }`,
                      text: `${transition.stage}${
                        transition.subStage ? `/${transition.subStage}` : ''
                      }`,
                    };
                  })}
                />
                <TextArea
                  rows={5}
                  label="Reason for change"
                  value={sendDealForm.stageChangeNotes}
                  onChange={(e) =>
                    setSendDealForm({
                      ...sendDealForm,
                      stageChangeNotes: e.target.value,
                    })
                  }
                />

                <Flex justifyContent="center" gap={2}>
                  <Button
                    startIcon="x"
                    variant="secondary"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={sendDealForm.transition === ''}
                    startIcon={'timeline'}
                    onClick={
                      aboutToSkipInternalRenewal &&
                      featureFlags.external_renewal_confirmation_flow
                        ? () => setShowConfirmationMessage(true)
                        : handleStageChange
                    }
                  >
                    Proceed With Stage Change
                  </Button>
                </Flex>
              </Flex>
            )}
          </>
        }
        trigger={
          <Button
            disabled={shouldBeDisabled}
            key="sendDealTo"
            startIcon="timeline"
          >
            Send Deal To...
          </Button>
        }
      />
    </>
  );
};
