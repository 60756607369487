/* istanbul ignore file */
/**
 * This component is almost entirely React Router
 * functionality, with a tiny ternary, and setting up tests
 * for it would be mostly a check that React Router works.
 * Anyone using this component in their feature will know
 * pretty heckin quick if something is wrong with it.
 *
 * @tyrelosaur - Oct 6, 2022
 */
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { NotAuthorized } from './NotAuthorized';

export interface AuthorizedRouteProps extends RouteProps {
  isAuthorized: boolean;
  children: JSX.Element;
}

export const AuthorizedRoute = (props: AuthorizedRouteProps): JSX.Element => {
  return props.isAuthorized ? props.children : <NotAuthorized />;
};
