import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  DatePicker,
  Divider,
  Flex,
  Heading,
  PlainDate,
  Subheading,
  Switch,
  Table,
  TextArea,
  TextInput,
  formatDateString,
  PageContainer,
  Text,
  Grid,
  CurrencyInput,
} from '@forward-financing/fast-forward';
import { v4 as uuidv4 } from 'uuid';
import { useCreateBulkAdjustment } from './bulkAdjustmentsHooks';

export type NewBulkAdjustmentsForm = {
  temporaryUuid: string;
  firstPaymentDate: PlainDate | undefined;
  lastPaymentDate: PlainDate | undefined;
  adjustment: number | undefined;
  frequency: string;
  processor: string;
  percentageSwitch: boolean;
  oneTimePaymentSwitch: boolean;
  openEndedSwitch: boolean;
  notes: string;
};

export type NewBulkAdjustmentsFormErrors = {
  firstPaymentDate: string;
  lastPaymentDate: string;
  adjustment: string;
  notes: string;
};

const defaultPaymentPlanErrors: NewBulkAdjustmentsFormErrors = {
  firstPaymentDate: '',
  lastPaymentDate: '',
  adjustment: '',
  notes: '',
};

const defaultPaymentPlan: NewBulkAdjustmentsForm = {
  temporaryUuid: uuidv4(),
  firstPaymentDate: undefined,
  lastPaymentDate: undefined,
  adjustment: undefined,
  frequency: 'daily',
  processor: 'ach_works',
  percentageSwitch: false,
  oneTimePaymentSwitch: false,
  openEndedSwitch: false,
  notes: '',
};

interface ConfirmPaymentPlansTableProps {
  paymentPlans: NewBulkAdjustmentsForm[];
  saving: boolean;
  setShowConfirmation: (val: boolean) => void;
  handleSubmit: () => void;
}

const ConfirmPaymentPlansTable = ({
  paymentPlans,
  saving,
  setShowConfirmation,
  handleSubmit,
}: ConfirmPaymentPlansTableProps): JSX.Element => {
  const displayDates = (
    first: PlainDate | undefined,
    last: PlainDate | undefined
  ): string => {
    // first should never be undefined realistically
    // istanbul ignore else
    if (first) {
      let output = formatDateString(first.toString() || '');

      if (last) {
        output = output.concat(
          ' ',
          // The String.fromCodePoint(8211) is to add
          // the `en-dash` within the concat method
          String.fromCodePoint(8211),
          ` ${formatDateString(last.toString())}`
        );
      } else {
        output = output.concat(
          ' ',
          String.fromCodePoint(8211),
          ` Until Paid in Full`
        );
      }

      return output;
    } else {
      return '';
    }
  };

  return (
    <>
      <Subheading>Confirm Payment Plan</Subheading>

      <Table caption="Confirm Payment Plan Table">
        <Table.Head>
          <Table.ColumnHeader>Date</Table.ColumnHeader>
          <Table.ColumnHeader>Adjustment</Table.ColumnHeader>
          <Table.ColumnHeader>Processor</Table.ColumnHeader>
          <Table.ColumnHeader>Notes</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {paymentPlans.map((plan) => (
            <Table.Row key={plan.temporaryUuid}>
              <Table.Cell>
                {displayDates(plan.firstPaymentDate, plan.lastPaymentDate)}
              </Table.Cell>
              <Table.Cell>
                {plan.percentageSwitch ? '' : '$'}
                {plan.adjustment}
                {plan.percentageSwitch ? '%' : ''}
              </Table.Cell>
              <Table.Cell>{plan.processor}</Table.Cell>
              <Table.Cell>{plan.notes}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Flex mt={4} gap={4} justifyContent="center">
        <Button
          startIcon="x"
          variant="secondary"
          onClick={() => {
            setShowConfirmation(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={saving}
          startIcon="check"
          onClick={() => handleSubmit()}
        >
          {saving ? 'Saving' : 'Save'}
        </Button>
      </Flex>
    </>
  );
};

interface CreatePaymentPlansProps {
  advanceRecordIdsError: string;
  advanceRecordIds: number[];
  rawAdvanceRecordIds: string;
  paymentPlans: NewBulkAdjustmentsForm[];
  paymentPlanErrors: NewBulkAdjustmentsFormErrors[];
  setAdvanceRecordIdsError: (newVal: string) => void;
  setShowSuccessBanner: (val: boolean) => void;
  setShowConfirmation: (val: boolean) => void;
  setPaymentPlanErrors: (newVal: NewBulkAdjustmentsFormErrors[]) => void;
  handleAdvanceRecordIds: (newVal: string) => void;
  handleCreateNewPaymentPlan: () => void;
  handleUpdatePaymentPlan: (
    paymentPlan: NewBulkAdjustmentsForm,
    index: string
  ) => void;
  handleDeletePaymentPlan: (uuid: string, index: number) => void;
}

const CreatePaymentPlans = ({
  advanceRecordIds,
  advanceRecordIdsError,
  rawAdvanceRecordIds,
  paymentPlanErrors,
  paymentPlans,
  setAdvanceRecordIdsError,
  setShowSuccessBanner,
  setShowConfirmation,
  setPaymentPlanErrors,
  handleAdvanceRecordIds,
  handleCreateNewPaymentPlan,
  handleDeletePaymentPlan,
  handleUpdatePaymentPlan,
}: CreatePaymentPlansProps): JSX.Element => {
  const isValidFormData = (): boolean => {
    setAdvanceRecordIdsError(
      advanceRecordIds.length ? '' : 'Missing Advance Record IDs'
    );

    setPaymentPlanErrors([]);

    const errors: NewBulkAdjustmentsFormErrors[] = paymentPlans.map((plan) => {
      const lastPaymentDateError = (): string => {
        if (
          plan.firstPaymentDate &&
          plan.lastPaymentDate &&
          PlainDate.compare(plan.firstPaymentDate, plan.lastPaymentDate) === 1
        ) {
          return 'Last Payment Date must be after First Payment Date';
        }

        if (
          plan.openEndedSwitch ||
          plan.oneTimePaymentSwitch ||
          plan.lastPaymentDate
        ) {
          return '';
        }

        return 'Missing Last Payment Date';
      };
      return {
        firstPaymentDate: plan.firstPaymentDate
          ? ''
          : 'Missing First Payment Date',
        lastPaymentDate: lastPaymentDateError(),
        adjustment: plan.adjustment ? '' : 'Missing Adjustment',
        notes: plan.notes ? '' : 'Missing Notes',
      };
    });

    setPaymentPlanErrors(errors);

    return (
      advanceRecordIds.length !== 0 &&
      errors.every((err) => Object.values(err).every((e) => !e))
    );
  };
  return (
    <>
      <Subheading>Enter a list of Advance IDs</Subheading>
      <TextArea
        label="Advance IDs"
        placeholder="Enter or manually paste Advance IDs in this area. Each ID should be placed on its own line."
        onChange={(e) => handleAdvanceRecordIds(e.target.value)}
        rows={8}
        errorMessage={advanceRecordIdsError}
        value={rawAdvanceRecordIds}
      />
      <Divider />
      {paymentPlans.map((plan, index) => (
        <Box key={plan.temporaryUuid}>
          <Box mb={4}>
            <Flex gap={4} alignItems="center">
              <Subheading>Select Adjustment Parameters</Subheading>
              <Switch
                id="one-time-payment-switch"
                checked={plan.oneTimePaymentSwitch}
                onCheckedChange={(checked) =>
                  handleUpdatePaymentPlan(
                    {
                      ...plan,
                      oneTimePaymentSwitch: checked,
                      lastPaymentDate: checked
                        ? plan.firstPaymentDate
                        : plan.lastPaymentDate,
                      openEndedSwitch: false,
                    },
                    plan.temporaryUuid
                  )
                }
                label="One-Time Payment"
              />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading>Payment Plan {index + 1}</Heading>
              {index !== 0 && (
                <Button
                  startIcon="x"
                  variant="danger"
                  onClick={() =>
                    handleDeletePaymentPlan(plan.temporaryUuid, index)
                  }
                >
                  Remove
                </Button>
              )}
            </Flex>

            <Grid gutter>
              <Grid.Item xs={12} s={12} m={6} l={6} xl={6}>
                <Box>
                  <DatePicker
                    selected={plan.firstPaymentDate}
                    onChange={(date: PlainDate | undefined) =>
                      handleUpdatePaymentPlan(
                        {
                          ...plan,
                          firstPaymentDate: date,
                          lastPaymentDate: plan.oneTimePaymentSwitch
                            ? date
                            : plan.lastPaymentDate,
                        },
                        plan.temporaryUuid
                      )
                    }
                    label="First Payment Date"
                    placeholder="Choose a Date"
                    showDropdownCalendarPicker
                    errorMessage={
                      paymentPlanErrors[index]?.firstPaymentDate ?? ''
                    }
                  />
                </Box>
              </Grid.Item>
              <Grid.Item xs={12} s={12} m={6} l={6} xl={6}>
                <>
                  <DatePicker
                    disabled={plan.oneTimePaymentSwitch || plan.openEndedSwitch}
                    selected={plan.lastPaymentDate}
                    onChange={(date: PlainDate | undefined) =>
                      handleUpdatePaymentPlan(
                        {
                          ...plan,
                          lastPaymentDate: date,
                        },
                        plan.temporaryUuid
                      )
                    }
                    label="Last Payment Date"
                    placeholder="Choose a Date"
                    showDropdownCalendarPicker
                    errorMessage={
                      paymentPlanErrors[index]?.lastPaymentDate ?? ''
                    }
                  />
                  <Switch
                    id="open-ended-plan-switch"
                    disabled={plan.oneTimePaymentSwitch}
                    checked={plan.openEndedSwitch}
                    onCheckedChange={(checked) =>
                      handleUpdatePaymentPlan(
                        {
                          ...plan,
                          openEndedSwitch: checked,
                          lastPaymentDate: checked
                            ? undefined
                            : plan.lastPaymentDate,
                        },
                        plan.temporaryUuid
                      )
                    }
                    label="Open Ended Plan"
                  />
                </>
              </Grid.Item>
              <Grid.Item xs={12} s={12} m={6} l={6} xl={6}>
                <Flex flexDirection="column" gap={3} mb={2}>
                  <CurrencyInput
                    label="Adjustment"
                    beforeInputContent={
                      <TextInput.DecorativeIcon
                        icon={
                          plan.percentageSwitch ? 'percentage' : 'dollar-sign'
                        }
                      />
                    }
                    value={plan.adjustment}
                    onValueChange={(newValue) =>
                      handleUpdatePaymentPlan(
                        {
                          ...plan,
                          adjustment: parseFloat(newValue.formattedValue),
                        },
                        plan.temporaryUuid
                      )
                    }
                    errorMessage={paymentPlanErrors[index]?.adjustment ?? ''}
                  />
                  <Switch
                    id="percentage-switch"
                    checked={plan.percentageSwitch}
                    onCheckedChange={(checked) =>
                      handleUpdatePaymentPlan(
                        {
                          ...plan,
                          percentageSwitch: checked,
                        },
                        plan.temporaryUuid
                      )
                    }
                    label="Switch to Percentage"
                  />
                  <Flex flexDirection="column">
                    <Subheading variant="subsection">Processor</Subheading>
                    <Text>ACH Works</Text>
                  </Flex>
                </Flex>
              </Grid.Item>
            </Grid>

            {index + 1 === paymentPlans.length && (
              <Button
                startIcon="plus"
                variant="text"
                onClick={handleCreateNewPaymentPlan}
              >
                Add Another Payment Plan
              </Button>
            )}
          </Box>

          <Subheading>Provide notes</Subheading>
          <TextArea
            label="Notes"
            required
            value={plan.notes}
            onChange={(e) =>
              handleUpdatePaymentPlan(
                {
                  ...plan,
                  notes: e.target.value,
                },
                plan.temporaryUuid
              )
            }
            errorMessage={paymentPlanErrors[index]?.notes ?? ''}
          />
          <Divider />
        </Box>
      ))}

      <Flex mt={4} gap={4} justifyContent="center">
        <Button
          startIcon="check"
          onClick={() => {
            setShowSuccessBanner(false);
            if (isValidFormData()) {
              setShowConfirmation(true);
            }
          }}
        >
          Save
        </Button>
      </Flex>
    </>
  );
};

export const NewBulkAdjustments = (): JSX.Element => {
  const [advanceRecordIds, setAdvanceRecordIds] = useState<number[]>([]);
  const [rawAdvanceRecordIds, setRawAdvanceRecordIds] = useState<string>('');
  const [paymentPlans, setPaymentPlans] = useState<NewBulkAdjustmentsForm[]>([
    defaultPaymentPlan,
  ]);
  const [paymentPlanErrors, setPaymentPlanErrors] = useState<
    NewBulkAdjustmentsFormErrors[]
  >([defaultPaymentPlanErrors]);
  const [advanceRecordIdsError, setAdvanceRecordIdsError] =
    useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false);
  const [createBulkAdjustments, { error: createError }] =
    useCreateBulkAdjustment();
  const [saving, setSaving] = useState<boolean>(false);

  const handleAdvanceRecordIds = (newValue: string): void => {
    const newRecordIds = newValue
      .split('\n')
      // Removes last line (which is set to '' and converted to 0)
      // in case user hits enter after last id entry
      .filter((line) => line.trim() !== '')
      .map(Number);

    setRawAdvanceRecordIds(newValue);
    setAdvanceRecordIds(newRecordIds);
  };

  const handleCreateNewPaymentPlan = (): void => {
    setPaymentPlans((prev) => [
      ...prev,
      { ...defaultPaymentPlan, temporaryUuid: uuidv4() },
    ]);
    setPaymentPlanErrors((prev) => [...prev, defaultPaymentPlanErrors]);
  };

  const handleDeletePaymentPlan = (
    uuidToDelete: string,
    index: number
  ): void => {
    setPaymentPlans((prev) =>
      prev.filter((plan) => {
        return plan.temporaryUuid !== uuidToDelete;
      })
    );
    setPaymentPlanErrors((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1, prev.length),
    ]);
  };

  const handleUpdatePaymentPlan = (
    newPlan: NewBulkAdjustmentsForm,
    uuidToUpdate: string
  ): void => {
    setPaymentPlans((prev) =>
      prev.map((plan) => {
        return uuidToUpdate === plan.temporaryUuid ? newPlan : plan;
      })
    );
  };

  const handleSubmit = async (): Promise<void> => {
    setSaving(true);

    const response = await createBulkAdjustments({
      advanceRecordIds,
      createBody: paymentPlans,
    });

    if (response.success) {
      setRawAdvanceRecordIds('');
      setAdvanceRecordIds([]);
      setPaymentPlans([defaultPaymentPlan]);
      setShowConfirmation(false);
      setShowSuccessBanner(true);
    }

    setSaving(false);
  };

  return (
    <PageContainer>
      <Box p={4}>
        {showSuccessBanner && (
          <Banner variant="success">
            New Bulk Adjustment has been successfully created.
          </Banner>
        )}
        {createError && (
          <Banner dismissable={false}>{createError.message}</Banner>
        )}
        <Heading>Create New Bulk Adjustment</Heading>

        {showConfirmation ? (
          <ConfirmPaymentPlansTable
            paymentPlans={paymentPlans}
            saving={saving}
            setShowConfirmation={setShowConfirmation}
            handleSubmit={handleSubmit}
          />
        ) : (
          <CreatePaymentPlans
            advanceRecordIds={advanceRecordIds}
            advanceRecordIdsError={advanceRecordIdsError}
            paymentPlans={paymentPlans}
            paymentPlanErrors={paymentPlanErrors}
            rawAdvanceRecordIds={rawAdvanceRecordIds}
            setAdvanceRecordIdsError={setAdvanceRecordIdsError}
            setPaymentPlanErrors={setPaymentPlanErrors}
            setShowConfirmation={setShowConfirmation}
            setShowSuccessBanner={setShowSuccessBanner}
            handleAdvanceRecordIds={handleAdvanceRecordIds}
            handleCreateNewPaymentPlan={handleCreateNewPaymentPlan}
            handleDeletePaymentPlan={handleDeletePaymentPlan}
            handleUpdatePaymentPlan={handleUpdatePaymentPlan}
          />
        )}
      </Box>
    </PageContainer>
  );
};
