import React, { useContext, createContext } from 'react';
import { PaymentScheduleContextData } from './paymentSchedule.types';

const PaymentScheduleContext = createContext<PaymentScheduleContextData>({
  advanceRecordId: 0,
  paymentSchedule: undefined,
  advanceProfile: undefined,
  bankInfo: undefined,
  formState: {
    startDate: undefined,
    endDate: undefined,
    isOpenEnded: false,
    frequency: undefined,
    lastMonthRevenue: undefined,
    customAmount: undefined,
    paymentProcessor: undefined,
    adjustmentReason: undefined,
  },
  sameDayAchFormState: {
    paymentDate: undefined,
    customAmount: undefined,
    adjustmentNote: undefined,
  },
  calculateError: undefined,
  updateFormState: () => {
    // intentionally empty
  },
  updateSameDayAchFormState: () => {
    // intentionally empty
  },
  getAdjustmentProjection:
    /* istanbul ignore next: this will never get run */
    () => Promise.resolve(),
  adjustmentProjections: undefined,
  resetForm: () => {
    // intentionally empty
  },
  resetSameDayAchForm: () => {
    // intentionally empty
  },
  createPaymentScheduleAdjustment:
    /* istanbul ignore next: this will never get run */
    () => Promise.resolve(),
  resumePaymentsOn:
    /* istanbul ignore next: this will never get run */
    () => Promise.resolve(),
  refetchPaymentSchedule:
    /* istanbul ignore next: this will never get run */
    () => Promise.resolve(),
});

export const usePaymentScheduleContext = (): PaymentScheduleContextData =>
  useContext(PaymentScheduleContext);

interface PaymentScheduleProviderProps {
  value: PaymentScheduleContextData;
  children: React.ReactNode;
}

export const PaymentScheduleProvider = ({
  value,
  children,
}: PaymentScheduleProviderProps): JSX.Element => (
  <PaymentScheduleContext.Provider value={value}>
    {children}
  </PaymentScheduleContext.Provider>
);
