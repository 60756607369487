import React, { useState } from 'react';
import { Divider, Flex, IconButton } from '@forward-financing/fast-forward';

export interface SubformSectionProps {
  label: string;
  showCloseButton: boolean;
  children: JSX.Element;
  needsConfirm: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export const SubformSection = (props: SubformSectionProps): JSX.Element => {
  const [displayConfirm, setDisplayConfirm] = useState(false);

  const { showCloseButton, onClick, label, needsConfirm } = props;

  // arg: e: React.MouseEvent<HTMLButtonElement>
  const onCloseClick = () => (): void => {
    setDisplayConfirm(true);
  };

  const onConfirm =
    () =>
    (e: React.MouseEvent): void => {
      setDisplayConfirm(false);
      onClick(e);
    };

  // arg: e: React.MouseEvent<HTMLButtonElement>
  const onCancel = () => (): void => {
    setDisplayConfirm(false);
  };

  const renderButtons = (): JSX.Element => {
    if (showCloseButton && !displayConfirm) {
      return (
        <IconButton
          label="Close"
          hiddenLabel
          onClick={onCloseClick()}
          icon="times"
        />
      );
    } else if (displayConfirm && needsConfirm) {
      return (
        <Flex flexDirection="row">
          <IconButton label="Cancel" icon="times-circle" onClick={onCancel()} />
          <Divider orientation="vertical" />
          <IconButton label="Remove" icon="trash" onClick={onConfirm()} />
        </Flex>
      );
    }
    return <></>;
  };

  return (
    <div data-testid="subformSection-container" className="sub-form">
      <div className="banner">
        <div className="columns">
          <div className="column">
            <span className="banner-title has-text-weight-bold">{label}</span>
          </div>
          <div className="column banner-action-buttons has-text-right">
            {renderButtons()}
          </div>
        </div>
      </div>

      <div className="content">{props.children}</div>
    </div>
  );
};
