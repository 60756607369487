/**
 * This file was largely copy-pasted from the business-timer version 0.1.0 library.
 * A bug was found inside of the _toTimestamp function that made the library unusable
 * without modification, hence the need for this class. The types used were also not
 * being exported, which made it difficult to use this class without needing to
 * copy-paste the type definitions to make Typescript happy.
 *
 * Dexter Richards - March 9, 2023
 */

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

/** hh:mm:ss */
type ISOTime = string;

export type DailyHours = null | [ISOTime, ISOTime];

export type DateLike = Date | string | number;

/** A seven-item array describing operating hours for each day of the week
 *
 * Order follows JS' `getDay()`: [Sun, Mon, Tue, ...]
 * */
export type WeeklyHours = readonly [
  DailyHours,
  DailyHours,
  DailyHours,
  DailyHours,
  DailyHours,
  DailyHours,
  DailyHours
];

export const DEFAULT_HOURS: WeeklyHours = [
  null,
  ['09:00', '18:00'],
  ['09:00', '18:00'],
  ['09:00', '18:00'],
  ['09:00', '18:00'],
  ['09:00', '18:00'],
  null,
];

/** Options for a new `BusinessTimer` */
export type BusinessTimerOpts = {
  /** The business hours for each day of the week*/
  hours?: WeeklyHours;

  /** The timezone for `hours`
   *
   *  To avoid bringing the full IANA database along for the ride, the timezone
   *  provided here must be understood by the `Intl.DateTimeFormat`
   *  implementation in the runtime environment.
   *
   *  This option may be safely omitted if all dates/times used with
   *  `BusinessTimer` will already be represented in an offset-less timezone
   *  (read: UTC).
   */
  timeZone?: string;
};

function isoTimeToDuration(isoTime: ISOTime): number {
  if (isoTime.length === 0) {
    return 0;
  }

  const hours = isoTime.substring(0, 2);
  let duration = parseInt(hours, 10) * HOUR;

  const minutes = isoTime.substring(3, 6);
  if (minutes) {
    duration += parseInt(minutes, 10) * MINUTE;
  }

  if (isoTime.length >= 6) {
    const seconds = isoTime.substring(7);
    if (seconds) {
      duration += parseInt(seconds, 10) * SECOND;
    }
  }

  return duration;
}

function startOfDay(ts: number): number {
  return Math.floor(ts / DAY) * DAY;
}

function secondsSinceMidnight(ts: number): number {
  return ts % DAY;
}

type ParsedHours = { start: number; end: number; duration: number };

function dailyHoursToDuration(dailyHours: DailyHours): ParsedHours {
  const parsed = { start: -1, end: -1, duration: -1 };
  if (dailyHours) {
    parsed.start = isoTimeToDuration(dailyHours[0]);
    parsed.end = isoTimeToDuration(dailyHours[1]);
    parsed.duration = parsed.end - parsed.start;
  }

  return parsed;
}

type DaysSinceEpoch = number;

/** Default options */
export const DEFAULTS: BusinessTimerOpts = {
  hours: DEFAULT_HOURS,
  timeZone: 'UTC',
};

/** BusinessTimer only counts time during operating hours */
export class BusinessTimer {
  private readonly _dateFormat: Intl.DateTimeFormat;
  private readonly _timeFormat: Intl.DateTimeFormat;
  private readonly _hours: ParsedHours[];
  private _knownWorkingDays: Set<DaysSinceEpoch> = new Set();

  constructor({
    hours = DEFAULT_HOURS,
    timeZone = 'UTC',
  }: BusinessTimerOpts = DEFAULTS) {
    // TODO: validate options

    // YYYY-MM-DD
    this._dateFormat = new Intl.DateTimeFormat('fr-ca', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    this._timeFormat = new Intl.DateTimeFormat('en-US', {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });

    this._hours = hours.map(dailyHoursToDuration);
  }

  /** Compute elapsed business time (in milliseconds) between two dates */
  public diff(start: DateLike, end: DateLike): number {
    let t1 = this._toTimestamp(new Date(start));

    // If the starting time falls outside of business hours, fast-forward to the
    // start of business on the next working day.
    if (!this._isOpen(t1) || !this._isWorkday(t1)) {
      t1 = this._nextOpen(t1);
    }

    // If the ending time falls outside of business hours, rewind to close of
    // business on the most recent working day.
    let t2 = this._toTimestamp(new Date(end));
    if (!this._isOpen(t2) || !this._isWorkday(t2)) {
      t2 = this._previousClose(t2);
    }

    // Short-circuit cases where the adjusted time range is negative or fall
    // within the same business day.
    const totalDiff = t2 - t1;
    if (totalDiff <= 0) {
      return 0;
    } else if (this._open(t1) === this._open(t2)) {
      return totalDiff;
    }

    // Business hours on the first day
    let diff = this._close(t1) - t1;

    // Plus business hours on the last day, if the ending time is not closing (whole days are counted in the following loop)
    if (t2 !== this._close(t2)) {
      diff += t2 - this._open(t2);
    }

    // Plus business hours for all the days in between
    //
    // TODO: we could ~O(1) this when diff > 7d by computing the number of
    // days, minus weekend days. Iteration's fine
    // for now.
    for (let t = t1 + DAY; t < t2; t += DAY) {
      if (this._isWorkday(t)) {
        // If this is the last day and it is not a whole working day, skip; it was already added
        if (t2 - t >= this._lookupHours(t).duration) {
          diff += this._lookupHours(t).duration;
        }
      }
    }

    return diff;
  }

  /** Turn a date-like object into an offset-less timestamp
   *
   *  Discarding offset details allows downstream calculations to avoid the
   *  vagaries of local time (see also: daylight savings).
   *
   *  It's all just math from here.
   **/
  private _toTimestamp(...dl: ConstructorParameters<DateConstructor>): number {
    const d = new Date(...dl);
    const formattedDate = this._dateFormat.format(d);
    const formattedTime = this._timeFormat.format(d);
    const hackyISODate = `${formattedDate}T${formattedTime}Z`;
    return new Date(hackyISODate).getTime();
  }

  private _isWorkday(ts: number): boolean {
    const daysSinceEpoch = Math.floor(ts / DAY);
    if (this._knownWorkingDays.has(daysSinceEpoch)) {
      return true;
    }

    const { start } = this._lookupHours(ts);
    if (start === -1) {
      return false;
    }

    this._knownWorkingDays.add(daysSinceEpoch);
    return true;
  }

  private _open(ts: number): number {
    const { start } = this._lookupHours(ts);
    return startOfDay(ts) + start;
  }

  private _close(ts: number): number {
    const { end } = this._lookupHours(ts);
    return startOfDay(ts) + end;
  }

  private _nextOpen(ts: number): number {
    let newTs = ts;
    const { start } = this._lookupHours(newTs);
    if (start === -1 || secondsSinceMidnight(newTs) >= start) {
      // Advance to the next day if this isn't a work day (or we're already past
      // opening time).
      newTs = startOfDay(newTs + DAY);
    }

    while (!this._isWorkday(newTs)) {
      newTs += DAY;
    }

    return this._open(newTs);
  }

  private _previousClose(ts: number): number {
    let newTs = ts;
    const { end } = this._lookupHours(newTs);
    if (end === -1 || secondsSinceMidnight(newTs) <= end) {
      // Rewind to the previous day if this isn't a work day or we're still
      // before closing time.
      newTs = startOfDay(newTs - DAY);
    }

    while (!this._isWorkday(newTs)) {
      newTs -= DAY;
    }

    return this._close(newTs);
  }

  private _lookupHours(ts: number): ParsedHours {
    const day = (Math.floor(ts / DAY) + 4) % 7; // ~ Date.getUTCDay
    return this._hours[day] || { start: -1, end: -1, duration: -1 };
  }

  private _isOpen(ts: number): boolean {
    const { end, start } = this._lookupHours(ts);
    if (start === -1) {
      return true;
    }

    const secs = secondsSinceMidnight(ts);
    return secs >= start && secs <= end;
  }
}
