import {
  UseGetFetchPartnersResponse,
  useApiGetIsos,
} from 'apiHooks/funding/isoFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { IsoDropdownResponse } from 'types/api/funding/types';

export interface UsePartnersResult
  extends Omit<UseGetFetchPartnersResponse, 'data'> {
  data?: IsoDropdownResponse[] | undefined;
}

// Does not need explict tests, covered by genericFeatureHooks.test.ts and
// the tests for LivePipeline.test.tsx.
export const useFetchIsos = (): UsePartnersResult =>
  useGenericFeatureQuery(
    useApiGetIsos,
    (partners?: IsoDropdownResponse[]) => partners
  );
