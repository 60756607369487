import React from 'react';
import { useParams } from 'react-router-dom';
import { ExceptionsRequest } from './ExceptionsRequest';
import { Banner, Box, Heading, Loading } from '@forward-financing/fast-forward';
import { useExceptionRequests } from '../exceptionsRequestHooks';

type Params = { uuid?: string };

export const ExceptionsRequestContainer = (): JSX.Element => {
  const { uuid } = useParams<Params>();

  // istanbul ignore next - this condition is not really worth testing
  if (!uuid) {
    throw new Error('No Submission UUID Param set in URL');
  }

  const { requests, loading, error, refetch } = useExceptionRequests(uuid);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box p={3}>
      <Heading>Exceptions Request(s)</Heading>
      {error && <Banner dismissable={false}>{error.message}</Banner>}
      <ExceptionsRequest
        exceptionsRequestList={requests ?? []}
        loadList={refetch}
      />
    </Box>
  );
};
