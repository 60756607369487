import {
  Accordion,
  Banner,
  Flex,
  Loading,
} from '@forward-financing/fast-forward';
import React from 'react';
import { AttachmentGroup } from '../AttachmentGroup';
import { AttachmentManagerProvider } from '../AttachmentManagerContext';
import {
  useAttachments,
  useDocumentTags,
  useSupportedFileExtensions,
} from '../attachmentManagerHooks';
import { AttachmentManagerUploader } from '../AttachmentManagerUploader';

export interface PrequalAttachmentManagerProps {
  applicationUuid: string;
}

export const PrequalAttachmentManager = ({
  applicationUuid,
}: PrequalAttachmentManagerProps): JSX.Element => {
  const {
    attachments,
    loading,
    error,
    refetch: refetchAttachments,
  } = useAttachments(applicationUuid);

  const {
    documentTags,
    loading: tagsLoading,
    error: tagsError,
  } = useDocumentTags();
  const {
    supportedFileExtensions,
    loading: extensionsLoading,
    error: extensionsError,
  } = useSupportedFileExtensions();

  const closingDocuments =
    attachments?.filter((att) => att.source === 'closing_documents') ?? [];
  const originalDocuments =
    attachments?.filter((att) => att.source === 'original_submission') ?? [];
  const otherDocuments =
    attachments?.filter((att) => att.source === 'other') ?? [];

  const isAnythingLoading = loading || tagsLoading || extensionsLoading;

  const reloadAttachmentManager = (): void => {
    void refetchAttachments();
  };

  if (isAnythingLoading) {
    return <Loading text="Loading" />;
  }

  if (error || tagsError || extensionsError) {
    return (
      <Flex flexDirection="column" gap={2}>
        {error && <Banner dismissable={false}>{error}</Banner>}
        {tagsError && <Banner dismissable={false}>{tagsError}</Banner>}
        {extensionsError && (
          <Banner dismissable={false}>{extensionsError}</Banner>
        )}
      </Flex>
    );
  }

  if (documentTags && supportedFileExtensions) {
    return (
      <AttachmentManagerProvider
        value={{
          documentTags,
          primaryId: applicationUuid,
          reloadAttachmentManager,
          supportedFileExtensions,
        }}
      >
        <AttachmentManagerUploader />
        <Accordion type={'multiple'} defaultValue={[]}>
          <Accordion.Item
            value="closingDocuments"
            disabled={closingDocuments.length === 0}
          >
            <Accordion.Trigger>
              Closing Documents ({closingDocuments?.length})
            </Accordion.Trigger>
            <Accordion.Content>
              <AttachmentGroup
                source="closing_documents"
                attachments={closingDocuments}
                shouldShowDownload
              />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item
            value="originalSubmission"
            disabled={originalDocuments.length === 0}
          >
            <Accordion.Trigger>
              Original Submission ({originalDocuments?.length})
            </Accordion.Trigger>
            <Accordion.Content>
              <AttachmentGroup
                source="original_submission"
                attachments={originalDocuments}
                shouldShowDownload
              />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item
            value="otherDocuments"
            disabled={otherDocuments.length === 0}
          >
            <Accordion.Trigger>
              Other Documents ({otherDocuments?.length})
            </Accordion.Trigger>
            <Accordion.Content>
              <AttachmentGroup
                source="other"
                attachments={otherDocuments}
                shouldShowDownload
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      </AttachmentManagerProvider>
    );
  }

  // istanbul ignore next: we should never hit this case.
  return (
    <Banner dismissable={false}>
      Something has gone very wrong, and we are in a state that should be
      impossible. Please create an AST.
    </Banner>
  );
};
