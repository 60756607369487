import React from 'react';
import { Icon, Table, Tooltip } from '@forward-financing/fast-forward';

import { TradelineDetails } from '../types';

export const TradelineCurrentStatusCell = ({
  currentStatus,
  ratingType,
}: TradelineDetails): JSX.Element => {
  if (currentStatus) {
    return (
      <Table.Cell columnWidth="medium">
        <span>{currentStatus}</span>{' '}
        <Tooltip
          content={ratingType}
          trigger={
            <span data-testid="tradelines-table-current-status-tooltip">
              <Icon name="info-circle" />
            </span>
          }
        />
      </Table.Cell>
    );
  }

  return <Table.Cell columnWidth="medium">-</Table.Cell>;
};
