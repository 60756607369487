import React from 'react';
import {
  Card,
  Table,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import { BankruptcyRecord, BankruptcyStatus } from '../types';

export interface BankruptciesProps {
  bankruptcies: BankruptcyRecord[];
}

export const getBankruptcyStatus = (
  statuses: BankruptcyStatus[] | null
): string => {
  if (statuses?.some((status) => status.type === 'CLOSED')) {
    return 'CLOSED';
  }
  return statuses?.at(-1)?.type ?? 'N/A';
};

export const Bankruptcies = ({
  bankruptcies,
}: BankruptciesProps): JSX.Element => {
  return (
    <Card title="Bankruptcies">
      <Table caption="Bankruptcies">
        <Table.Head>
          <Table.ColumnHeader>Type</Table.ColumnHeader>
          <Table.ColumnHeader>Chapter</Table.ColumnHeader>
          <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
          <Table.ColumnHeader>Exit Date</Table.ColumnHeader>
          <Table.ColumnHeader>Filed As</Table.ColumnHeader>
          <Table.ColumnHeader>Status</Table.ColumnHeader>
        </Table.Head>

        <Table.Body>
          {bankruptcies.map(
            ({ filingDate, chapter, exitDate, filedAs, statuses }) => (
              <Table.Row key={filingDate}>
                <Table.Cell>BANKRUPTCY</Table.Cell>
                <Table.Cell>{chapter}</Table.Cell>
                <Table.Cell>
                  {filingDate ? formatDateString(filingDate) : 'N/A'}
                </Table.Cell>
                <Table.Cell>
                  {exitDate ? formatDateString(exitDate) : 'N/A'}
                </Table.Cell>
                <Table.Cell>
                  {filedAs.map((name) => (
                    <Text key={name}>{name}</Text>
                  ))}
                </Table.Cell>
                <Table.Cell>{getBankruptcyStatus(statuses)}</Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </Card>
  );
};
