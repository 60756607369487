import React from 'react';
import { Flex, Grid } from '@forward-financing/fast-forward';

import { TradelineDetails } from '../types';
import { TradelineOverviewTable } from './TradelineOverviewTable';
import { DelinquencyCounterTable } from './DelinquencyCounterTable';
import { CalendarTable } from './CalendarTable';

type TradelineOverviewProps = {
  tradeline: TradelineDetails;
};

export const TradelineOverview = ({
  tradeline,
}: TradelineOverviewProps): JSX.Element => {
  return (
    <Grid gutter>
      <Grid.Item xs={12} l={6}>
        <TradelineOverviewTable tradeline={tradeline} />
      </Grid.Item>

      <Grid.Item xs={12} l={6}>
        <Flex gap={3} flexDirection={'column'}>
          <CalendarTable tradeline={tradeline} />

          <DelinquencyCounterTable tradeline={tradeline} />
        </Flex>
      </Grid.Item>
    </Grid>
  );
};
