import { useCallback } from 'react';
import {
  createInternalRenewal,
  declinePatchSubmission,
  fetchFraudScores,
  fetchLexisNexisCustomerSearchResults,
  fetchOwnerCreditOverview,
  fetchPaynetSearchResults,
  fetchSubmission,
  fetchSubmissionStageHistory,
  fetchSubmissionStageTransitions,
  fetchSubmissions,
  getSubmissionEligibilityForRenewal,
  goToNextDeal,
  patchSubmission,
  pullLexisNexisCustomerReport,
  pullPaynetReport,
  refreshCompositeScore,
  refreshSimilarOwners,
  fetchSubmissionNotes,
  patchSubmissionNote,
  fetchRenewalScore,
  patchRenewalScore,
  createRenewalScore,
  fetchSubmissionStipulations,
  createStipulation,
  updateStipulation,
  fetchSubmissionCompositeScore,
  fetchLexisNexisCustomerManualSearch,
  assignEnqueuedProcessingDeal,
  deleteStipulation,
  fetchPaynetManualSearch,
  fetchStipulationValidReasons,
  fetchSubmissionLogs,
  fetchStipulationRecommendations,
  fetchDataMerch,
  fetchRenewalComparison,
  patchWebPresence,
  patchWebPresenceBulk,
  fetchWebPresences,
  createSubmissionLog,
  fetchPreviousFundedSubmissions,
} from 'api/underwriting/submissionFetchUtils';
import {
  AlgoliaSearchParams,
  CreditDataResponse,
  DeclineSubmissionRequestBody,
  EnqueuedDealResponse,
  FraudScoringDocumentResponse,
  InternalRenewalCreationResponse,
  LexisNexisCustomerSearchResultsResponse,
  PaynetResultsResponse,
  PaynetSearchResultsResponseDocument,
  RenewalEligibleResponse,
  SubmissionResponse,
  SubmissionSearchResultResponse,
  SubmissionStageHistoryResponse,
  SubmissionStageTransitionsResponse,
  UpdateSubmissionRequestBody,
  SubmissionNoteType,
  SubmissionNotesResponse,
  RenewalScoreResponse,
  RenewalScoresFetchResponse,
  UpdateRenewalScoreBody,
  CreateRenewalScoreBody,
  StipulationResponse,
  CreateStipulationRequestBody,
  UpdateStipulationRequestBody,
  SubmissionCompositeScoreResponse,
  LexisNexisCustomerSearchResponse,
  ProcessingQueueResponse,
  SubmissionLogsResponse,
  StipulationRecommendationsResponse,
  DataMerchReportResponse,
  RenewalComparisonResponse,
  SubmissionWebPresenceResponse,
  BulkUpdateApiWebPresenceBody,
  BulkUpdateWebPresenceResponse,
  UpdateApiWebPresenceBody,
  WebPresencePatchResponse,
  WebPresenceType,
  CreateApiSubmissionLogBody,
  CreateSubmissionLogResponse,
} from 'types/api/underwriting/types';
import {
  MutationResponse,
  useGenericMutation,
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
  useLazyGenericQuery,
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
} from 'apiHooks/genericFetchHooks';
import { HashMap } from 'api/codecs';

export type UseGetApiSubmissionResponse =
  UseGenericQueryResponse<SubmissionResponse>;

export const useGetApiSubmission = (
  submissionUuid?: string
): UseGetApiSubmissionResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmission(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SubmissionResponse>(fetchFunction);
};

export type UseLazyGetApiSubmissionsRefresher =
  UseLazyGenericQueryFetcher<AlgoliaSearchParams>;

export type UseLazyGetApiSubmissionsResponse =
  UseLazyGenericQueryResult<SubmissionSearchResultResponse>;

const fetchSubmissionsWithArgs = (
  args: AlgoliaSearchParams
): Promise<SubmissionSearchResultResponse> => fetchSubmissions(args);

export const useApiLazyGetSubmissions = (): [
  UseLazyGetApiSubmissionsRefresher,
  UseLazyGetApiSubmissionsResponse
] => {
  return useLazyGenericQuery(fetchSubmissionsWithArgs);
};

interface UseLazyGetApiSubmissionArgs {
  submissionUuid: string;
}

export type UseLazyGetApiSubmissionRefresher =
  UseLazyGenericQueryFetcher<UseLazyGetApiSubmissionArgs>;

export type UseLazyGetApiSubmissionResponse =
  UseLazyGenericQueryResult<SubmissionResponse>;

const fetchSubmissionWithArgs = (
  args: UseLazyGetApiSubmissionArgs
): Promise<SubmissionResponse> => fetchSubmission(args.submissionUuid);

export const useLazyGetApiSubmission = (): [
  UseLazyGetApiSubmissionRefresher,
  UseLazyGetApiSubmissionResponse
] => {
  return useLazyGenericQuery(fetchSubmissionWithArgs);
};

export interface UseUpdateSubmissionArgs {
  submissionUuid: string;
  updateBody: UpdateSubmissionRequestBody;
}

export type UseUpdateSubmissionResponse = UseGenericMutationResponse<
  SubmissionResponse,
  UseUpdateSubmissionArgs
>;

const updateSubmission = (
  input: UseUpdateSubmissionArgs
): Promise<SubmissionResponse> =>
  patchSubmission(input.submissionUuid, input.updateBody);

export const useUpdateApiSubmission = (): UseUpdateSubmissionResponse => {
  return useGenericMutation(updateSubmission);
};

export interface UseDeclineSubmissionArgs {
  submissionUuid: string;
  declineBody: DeclineSubmissionRequestBody;
}

export type UseDeclineSubmissionResponse = UseGenericMutationResponse<
  SubmissionResponse,
  UseDeclineSubmissionArgs
>;

const declineSubmission = (
  input: UseDeclineSubmissionArgs
): Promise<SubmissionResponse> =>
  declinePatchSubmission(input.submissionUuid, input.declineBody);

export const useDeclineApiSubmission = (): UseDeclineSubmissionResponse => {
  return useGenericMutation(declineSubmission);
};

export type UseLexisNexisCustomerSearchResultsResponse =
  UseGenericQueryResponse<LexisNexisCustomerSearchResultsResponse>;

export const useGetApiLexisNexisCustomerSearchResults = (
  submissionUuid?: string
): UseLexisNexisCustomerSearchResultsResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchLexisNexisCustomerSearchResults(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};

export type UsePullCustomerLexisNexisArgs = {
  submissionUuid: string;
  force?: boolean;
  reportId?: string;
};

export type UsePullCustomerLexisNexisResponse = UseGenericMutationResponse<
  { success: true },
  UsePullCustomerLexisNexisArgs
>;

const pullCreditWithArgs = (
  input: UsePullCustomerLexisNexisArgs
): Promise<{ success: true }> => {
  return pullLexisNexisCustomerReport(
    input.submissionUuid,
    input.force,
    input.reportId
  );
};

export const usePullCustomerLexisNexis =
  (): UsePullCustomerLexisNexisResponse => {
    return useGenericMutation(pullCreditWithArgs);
  };

export type UsePullPaynetArgs = {
  submissionUuid: string;
  reportId?: string;
};

export type UsePullPaynetResponse = UseGenericMutationResponse<
  MutationResponse,
  UsePullPaynetArgs
>;

const paynetFetchFunction = (
  input: UsePullPaynetArgs
): Promise<MutationResponse> => {
  return pullPaynetReport(input.submissionUuid, input?.reportId);
};

export const usePullPaynet = (): UsePullPaynetResponse => {
  return useGenericMutation(paynetFetchFunction);
};

export type UsePaynetSearchResultsResponse =
  UseGenericQueryResponse<PaynetResultsResponse>;

export const useGetApiPaynetSearchResults = (
  submissionUuid?: string
): UsePaynetSearchResultsResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchPaynetSearchResults(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<PaynetResultsResponse>(fetchFunction);
};

export interface UseRefreshOwnersArgs {
  ownerUuid?: string;
  submissionUuid?: string;
}

export type UseRefreshSimilarOwnersResponse = UseGenericMutationResponse<
  MutationResponse,
  UseRefreshOwnersArgs
>;

const refreshOwnersFunction = ({
  ownerUuid,
  submissionUuid,
}: UseRefreshOwnersArgs): Promise<MutationResponse> | undefined => {
  if (!ownerUuid || !submissionUuid) {
    return undefined;
  }
  return refreshSimilarOwners(ownerUuid, submissionUuid);
};

export const useApiRefreshOwners = (): UseRefreshSimilarOwnersResponse => {
  return useGenericMutation(refreshOwnersFunction);
};

export const useGetOwnerFicoScoresBatch = (
  submissionUuids?: string[]
): UseGenericQueryResponse<CreditDataResponse[][]> => {
  const fetchCreditData = useCallback(() => {
    if (!submissionUuids) {
      return undefined;
    }

    return Promise.all(
      submissionUuids.map((id) => fetchOwnerCreditOverview(id))
    );
  }, [submissionUuids]);

  return useGenericQuery(fetchCreditData);
};

export const useGetOwnerCreditOverviews = (
  submissionUuid?: string
): UseGenericQueryResponse<CreditDataResponse[]> => {
  const fetchCreditData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchOwnerCreditOverview(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchCreditData);
};

export type UseGotToNextButtonResponse = UseGenericMutationResponse<
  EnqueuedDealResponse,
  void
>;

const goToNextEnqueuedDeal = (): Promise<EnqueuedDealResponse> => {
  return goToNextDeal();
};

export const useApiGoToNextDeal = (): UseGotToNextButtonResponse => {
  return useGenericMutation(goToNextEnqueuedDeal);
};

export type UseGetSubmissionStageHistory = UseGenericQueryResponse<
  SubmissionStageHistoryResponse[]
>;

export const useApiGetSubmissionStageHistory = (
  submissionUuid?: string
): UseGetSubmissionStageHistory => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionStageHistory(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SubmissionStageHistoryResponse[]>(fetchFunction);
};

export type UseCreateInternalRenewalResponse = UseGenericMutationResponse<
  InternalRenewalCreationResponse,
  string
>;

const createInternalRenewalFunction = (
  submissionuuid?: string
): Promise<InternalRenewalCreationResponse> | undefined => {
  if (!submissionuuid) {
    return undefined;
  }
  return createInternalRenewal(submissionuuid);
};

export const useApiCreateInternalRenewal =
  (): UseCreateInternalRenewalResponse => {
    return useGenericMutation(createInternalRenewalFunction);
  };

export type UseLazyGetApiSubmissionEligibilityRefresher =
  UseLazyGenericQueryFetcher<string>;

export type UseLazySubmissionEligibilityResponse =
  UseLazyGenericQueryResult<RenewalEligibleResponse>;

const fetchSubmissionEligibility = (
  submissionUuid: string
): Promise<RenewalEligibleResponse> =>
  getSubmissionEligibilityForRenewal(submissionUuid);

export const useApiLazyGetSubmissionEligibility = (): [
  UseLazyGetApiSubmissionEligibilityRefresher,
  UseLazySubmissionEligibilityResponse
] => {
  return useLazyGenericQuery(fetchSubmissionEligibility);
};

export type UseGetSubmissionStageTransitions = UseGenericQueryResponse<
  SubmissionStageTransitionsResponse[]
>;

export const useApiGetSubmissionStageTransitions = (
  submissionUuid?: string
): UseGetSubmissionStageTransitions => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionStageTransitions(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SubmissionStageTransitionsResponse[]>(fetchFunction);
};

export type UseGetSubmissionCompositeScoreResponse =
  UseGenericQueryResponse<SubmissionCompositeScoreResponse>;

export const useGetSubmissionCompositeScore = (
  submissionUuid?: string
): UseGetSubmissionCompositeScoreResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionCompositeScore(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SubmissionCompositeScoreResponse>(fetchFunction);
};

export type UseRefreshCompositeScoreResponse = UseGenericMutationResponse<
  SubmissionCompositeScoreResponse,
  string
>;

const refreshCompositeScoreFunction = (
  submissionUuid?: string
): Promise<SubmissionCompositeScoreResponse> | undefined => {
  if (!submissionUuid) {
    return undefined;
  }
  return refreshCompositeScore(submissionUuid);
};

export const useApiRefreshCompositeScore =
  (): UseRefreshCompositeScoreResponse => {
    return useGenericMutation(refreshCompositeScoreFunction);
  };

export type UseGetApiFraudScoringResponse =
  UseGenericQueryResponse<FraudScoringDocumentResponse>;

export const useGetApiFraudScoring = (
  submissionUuid?: string
): UseGetApiFraudScoringResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchFraudScores(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<FraudScoringDocumentResponse>(fetchFunction);
};

export type UseGetApiSubmissionNotesResponse = UseGenericQueryResponse<
  SubmissionNotesResponse[]
>;

export const useGetSubmissionNotes = (
  submissionUuid?: string
): UseGetApiSubmissionNotesResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionNotes(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SubmissionNotesResponse[]>(fetchFunction);
};
export interface UpdateSubmissionNoteRequestBody {
  content: string;
}
export interface UseUpdateSubmissionNoteArgs {
  submissionUuid: string;
  noteType: SubmissionNoteType;
  updateBody: UpdateSubmissionNoteRequestBody;
}

export type UseUpdateSubmissionNoteResponse = UseGenericMutationResponse<
  SubmissionNotesResponse,
  UseUpdateSubmissionNoteArgs
>;

const updateSubmissionNote = ({
  submissionUuid,
  noteType,
  updateBody,
}: UseUpdateSubmissionNoteArgs): Promise<SubmissionNotesResponse> =>
  patchSubmissionNote(submissionUuid, noteType, updateBody);

export const useUpdateApiSubmissionNote =
  (): UseUpdateSubmissionNoteResponse => {
    return useGenericMutation(updateSubmissionNote);
  };

export type UseGetApiRenewalScoreResponse =
  UseGenericQueryResponse<RenewalScoresFetchResponse>;

export const useGetRenewalScore = (
  submissionUuid?: string
): UseGetApiRenewalScoreResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchRenewalScore(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<RenewalScoresFetchResponse>(fetchFunction);
};

export interface UseUpdateRenewalScoreArgs {
  submissionUuid: string;
  updateBody: UpdateRenewalScoreBody;
}

export type UseUpdateRenewalScoreResponse = UseGenericMutationResponse<
  RenewalScoreResponse,
  UseUpdateRenewalScoreArgs
>;

const updateRenewalScore = ({
  submissionUuid,
  updateBody,
}: UseUpdateRenewalScoreArgs): Promise<RenewalScoreResponse> =>
  patchRenewalScore(submissionUuid, updateBody);

export const useUpdateApiRenewalScore = (): UseUpdateRenewalScoreResponse => {
  return useGenericMutation(updateRenewalScore);
};

export interface UseCreateRenewalScoreArgs {
  submissionUuid: string;
  createBody?: CreateRenewalScoreBody;
}

export type UseCreateRenewalScoreResponse = UseGenericMutationResponse<
  RenewalScoreResponse,
  UseCreateRenewalScoreArgs
>;

const createRenewalScoreFunction = ({
  submissionUuid,
  createBody,
}: UseCreateRenewalScoreArgs): Promise<RenewalScoreResponse> | undefined => {
  return createRenewalScore(submissionUuid, createBody);
};

export const useCreateApiRenewalScore = (): UseCreateRenewalScoreResponse => {
  return useGenericMutation(createRenewalScoreFunction);
};

export type UseGetApiStipulationResponse = UseGenericQueryResponse<
  StipulationResponse[]
>;

// Stipulations
export const useGetApiStipulations = (
  submissionUuid?: string
): UseGetApiStipulationResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionStipulations(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<StipulationResponse[]>(fetchFunction);
};

// Create Stipulation
export interface UseCreateStipulationArgs {
  submissionUuid: string;
  createBody: CreateStipulationRequestBody;
}

const createStipulationFunction = ({
  submissionUuid,
  createBody,
}: UseCreateStipulationArgs): Promise<StipulationResponse> => {
  return createStipulation(submissionUuid, createBody);
};

export const useCreateApiStipulation = (): UseGenericMutationResponse<
  StipulationResponse,
  UseCreateStipulationArgs
> => {
  return useGenericMutation(createStipulationFunction);
};

// Create Stipulation Batch
export const useCreateApiStipulationBatch = (): UseGenericMutationResponse<
  PromiseSettledResult<StipulationResponse>[],
  UseCreateStipulationArgs[]
> => {
  const createStipulationBatchFunction = (
    args: UseCreateStipulationArgs[]
  ): Promise<PromiseSettledResult<StipulationResponse>[]> => {
    return Promise.allSettled(
      args.map(({ submissionUuid, createBody }) =>
        createStipulation(submissionUuid, createBody)
      )
    );
  };

  return useGenericMutation(createStipulationBatchFunction);
};

// Update Stipulation
interface UseUpdateStipulationArgs {
  submissionUuid: string;
  stipulationUuid: string;
  body: UpdateStipulationRequestBody;
}

const updateStipulationFunction = ({
  submissionUuid,
  stipulationUuid,
  body,
}: UseUpdateStipulationArgs): Promise<StipulationResponse> => {
  return updateStipulation(submissionUuid, stipulationUuid, body);
};

export const useUpdateApiStipulation = (): UseGenericMutationResponse<
  StipulationResponse,
  UseUpdateStipulationArgs
> => {
  return useGenericMutation(updateStipulationFunction);
};

// Update Stipulation Batch
export const useUpdateApiStipulationBatch = (): UseGenericMutationResponse<
  StipulationResponse[],
  UseUpdateStipulationArgs[]
> => {
  const updateStipulationBatchFunction = (
    args: UseUpdateStipulationArgs[]
  ): Promise<StipulationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, stipulationUuid, body }) =>
        updateStipulation(submissionUuid, stipulationUuid, body)
      )
    );
  };

  return useGenericMutation(updateStipulationBatchFunction);
};

export interface UseDeleteStipulationArgs {
  submissionUuid: string;
  stipulationUuid: string;
}

const deleteStipulationFunction = ({
  submissionUuid,
  stipulationUuid,
}: UseDeleteStipulationArgs): Promise<MutationResponse> => {
  return deleteStipulation(submissionUuid, stipulationUuid);
};

export const useDeleteApiStipulation = (): UseGenericMutationResponse<
  MutationResponse,
  UseDeleteStipulationArgs
> => {
  return useGenericMutation(deleteStipulationFunction);
};

export type UseGetApiLexisNexisCustomerSearchResponse =
  UseGenericQueryResponse<LexisNexisCustomerSearchResponse>;

export const useGetApiLexisNexisCustomerManualSearch = (
  submissionUuid?: string,
  queryParams?: string
): UseGetApiLexisNexisCustomerSearchResponse => {
  const getLexisNexisCustomerSearch = useCallback(() => {
    if (!submissionUuid || !queryParams) {
      return undefined;
    }

    return fetchLexisNexisCustomerManualSearch(submissionUuid, queryParams);
  }, [submissionUuid, queryParams]);

  return useGenericQuery(getLexisNexisCustomerSearch);
};

export type UseLazyGetLexisNexisCustomerSearchArgs = {
  submissionUuid: string;
  queryParams: string;
};

export type UseLazyGetApiLexisNexisCustomerSearchRefresher =
  UseLazyGenericQueryFetcher<UseLazyGetLexisNexisCustomerSearchArgs>;

export type UseLazyGetApiLexisNexisCustomerSearchResponse =
  UseLazyGenericQueryResult<LexisNexisCustomerSearchResponse>;

const fetchLexisNexisCustomerManualSearchWithArgs = ({
  submissionUuid,
  queryParams,
}: UseLazyGetLexisNexisCustomerSearchArgs): Promise<LexisNexisCustomerSearchResponse> => {
  return fetchLexisNexisCustomerManualSearch(submissionUuid, queryParams);
};

export const useLazyGetApiLexisNexisCustomerManualSearch = (): [
  UseLazyGetApiLexisNexisCustomerSearchRefresher,
  UseLazyGetApiLexisNexisCustomerSearchResponse
] => {
  return useLazyGenericQuery(fetchLexisNexisCustomerManualSearchWithArgs);
};

export type UseLazyGetPaynetSearchArgs = {
  submissionUuid: string;
  queryParams: string;
};

export type UseLazyGetApiPaynetSearchRefresher =
  UseLazyGenericQueryFetcher<UseLazyGetPaynetSearchArgs>;

export type UseLazyGetApiPaynetSearchResponse =
  UseLazyGenericQueryResult<PaynetSearchResultsResponseDocument>;

const fetchPaynetManualSearchWithArgs = ({
  submissionUuid,
  queryParams,
}: UseLazyGetPaynetSearchArgs): Promise<PaynetSearchResultsResponseDocument> => {
  return fetchPaynetManualSearch(submissionUuid, queryParams);
};

export const useLazyGetApiPaynetManualSearch = (): [
  UseLazyGetApiPaynetSearchRefresher,
  UseLazyGetApiPaynetSearchResponse
] => {
  return useLazyGenericQuery(fetchPaynetManualSearchWithArgs);
};

export type UseNextProcessingDealResponse = UseGenericMutationResponse<
  ProcessingQueueResponse,
  void
>;

const goToNextProcessingDeal = (): Promise<ProcessingQueueResponse> => {
  return assignEnqueuedProcessingDeal();
};

export const useApiAssignNextProcessingDeal =
  (): UseNextProcessingDealResponse => {
    return useGenericMutation(goToNextProcessingDeal);
  };

export const useGetApiValidReasons = (): UseGenericQueryResponse<
  HashMap<string[]>
> => {
  const fetcher = useCallback(() => {
    return fetchStipulationValidReasons();
  }, []);

  return useGenericQuery(fetcher);
};
export type UseGetApiSubmissionLogsResponse =
  UseGenericQueryResponse<SubmissionLogsResponse>;

export const useGetApiSubmissionLogs = (
  submissionUuid?: string
): UseGetApiSubmissionLogsResponse => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionLogs(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SubmissionLogsResponse>(fetchFunction);
};

export const useGetApiStipulationRecommendations = (
  submissionUuid?: string
): UseGenericQueryResponse<StipulationRecommendationsResponse[]> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchStipulationRecommendations(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<StipulationRecommendationsResponse[]>(fetchFunction);
};

export const useGetApiDataMerch = (
  submissionUuid?: string
): UseGenericQueryResponse<DataMerchReportResponse> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchDataMerch(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<DataMerchReportResponse>(fetchFunction);
};

export const useGetApiRenewalComparison = (
  submissionUuid?: string,
  sections?: string[]
): UseGenericQueryResponse<RenewalComparisonResponse> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid || !sections) {
      return undefined;
    }

    return fetchRenewalComparison(submissionUuid, sections);
  }, [submissionUuid, sections]);

  return useGenericQuery<RenewalComparisonResponse>(fetchFunction);
};

export const useGetApiWebPresences = (
  submissionUuid?: string
): UseGenericQueryResponse<SubmissionWebPresenceResponse> => {
  const getWebPresences = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }
    return fetchWebPresences(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getWebPresences);
};

export type UseUpdateWebPresenceArgs = {
  submissionUuid: string;
  webPresenceType: WebPresenceType;
  body: UpdateApiWebPresenceBody;
};

export type UseUpdateWebPresenceResponse = UseGenericMutationResponse<
  WebPresencePatchResponse,
  UseUpdateWebPresenceArgs
>;

const updateWebPresence = (
  input: UseUpdateWebPresenceArgs
): Promise<WebPresencePatchResponse> =>
  patchWebPresence(input.submissionUuid, input.webPresenceType, input.body);

export const useUpdateApiWebPresence = (): UseUpdateWebPresenceResponse => {
  return useGenericMutation(updateWebPresence);
};

export type UseBulkUpdateWebPresenceArgs = {
  submissionUuid: string;
  body: BulkUpdateApiWebPresenceBody;
};

export type UseBulkUpdateWebPresenceResponse = UseGenericMutationResponse<
  BulkUpdateWebPresenceResponse,
  UseBulkUpdateWebPresenceArgs
>;

const bulkUpdateWebPresence = (
  input: UseBulkUpdateWebPresenceArgs
): Promise<BulkUpdateWebPresenceResponse> =>
  patchWebPresenceBulk(input.submissionUuid, input.body);

export const useBulkUpdateApiWebPresence =
  (): UseBulkUpdateWebPresenceResponse => {
    return useGenericMutation(bulkUpdateWebPresence);
  };

export type UseCreateSubmissionLogArgs = {
  submissionUuid: string;
  createBody: CreateApiSubmissionLogBody;
};

export type UseCreateSubmissionLogResponse = UseGenericMutationResponse<
  CreateSubmissionLogResponse,
  UseCreateSubmissionLogArgs
>;

const createSubmissionLogFunction = ({
  submissionUuid,
  createBody,
}: UseCreateSubmissionLogArgs):
  | Promise<CreateSubmissionLogResponse>
  | undefined => {
  return createSubmissionLog(submissionUuid, createBody);
};

export const useCreateApiSubmissionLog = (): UseCreateSubmissionLogResponse => {
  return useGenericMutation(createSubmissionLogFunction);
};

export const useGetApiPreviousFundedSubmissions = (
  submissionUuid?: string
): UseGenericQueryResponse<SubmissionResponse[]> => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchPreviousFundedSubmissions(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};
