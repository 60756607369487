import React, { useState } from 'react';
import { EditAttachmentsModal } from './EditAttachmentsModal';
import { Flex, Banner } from '@forward-financing/fast-forward';
import { Attachment, AttachmentSource } from './attachmentManager.types';
import { AttachmentsTable } from './AttachmentsTable';
import { DeleteAttachmentModal } from './DeleteAttachmentModal';
import { ReadOnlyAttachmentsTable } from './ReadOnlyAttachmentsTable';
import { DownloadAllAttachmentsButton } from './DownloadAllAttachmentsButton';
import { BulkEditAttachmentModal } from './BulkEditAttachmentModal';
import { useAttachmentManagerContext } from './AttachmentManagerContext';

export interface AttachmentGroupProps {
  attachments: Attachment[];
  source: AttachmentSource;
  shouldShowDownload: boolean;
}

export const AttachmentGroup = ({
  attachments,
  source,
  shouldShowDownload,
}: AttachmentGroupProps): JSX.Element => {
  const [selectedAttachmentIds, setSelectedAttachmentIds] = useState<string[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { isSalesforce } = useAttachmentManagerContext();

  const deletedAttachments = attachments.filter((att) => att.fileDeletedAt);
  const currentAttachments = attachments.filter((att) => !att.fileDeletedAt);

  const onAttachmentRowChecked = (
    attachmentId: string,
    checked: boolean
  ): void => {
    if (checked) {
      setSelectedAttachmentIds((prev) => [...prev, attachmentId]);
    } else {
      setSelectedAttachmentIds((prev) =>
        prev.filter((a) => a !== attachmentId)
      );
    }
  };

  const toggleAllChecked = (allChecked: boolean): void => {
    if (allChecked) {
      setSelectedAttachmentIds(currentAttachments.map((att) => att.uuid));
    } else {
      setSelectedAttachmentIds([]);
    }
  };

  const selectedAttachments = currentAttachments.filter((att) =>
    selectedAttachmentIds.includes(att.uuid)
  );

  return (
    <>
      {errorMessage && (
        <Banner dismissable={false} variant="error">
          {errorMessage}
        </Banner>
      )}
      <Flex
        justifyContent={'flex-end'}
        marginBottom={3}
        gap={2}
        flexWrap="wrap"
      >
        <DeleteAttachmentModal selectedAttachments={selectedAttachments} />

        {!isSalesforce && (
          <EditAttachmentsModal
            selectedAttachments={selectedAttachments}
            onSaveComplete={() => setSelectedAttachmentIds([])}
          />
        )}

        {!isSalesforce && (
          <BulkEditAttachmentModal
            selectedAttachments={selectedAttachments}
            onSaveComplete={() => setSelectedAttachmentIds([])}
          />
        )}

        {shouldShowDownload && (
          <DownloadAllAttachmentsButton
            source={source}
            setErrorMessage={setErrorMessage}
            disabledForDocumentRetention={attachments.every(
              (att) => !!att.fileDeletedAt
            )}
          />
        )}
      </Flex>
      <Flex flexDirection="column" gap={3}>
        {currentAttachments.length > 0 && (
          <AttachmentsTable
            attachments={currentAttachments}
            selectedAttachmentIds={selectedAttachmentIds}
            onRowSelect={onAttachmentRowChecked}
            toggleAllChecked={toggleAllChecked}
          />
        )}
        {deletedAttachments.length > 0 && (
          <ReadOnlyAttachmentsTable attachments={deletedAttachments} />
        )}
      </Flex>
    </>
  );
};
