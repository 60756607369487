import React from 'react';
import { useParams } from 'react-router-dom';
import { Banner, Box, Flex, Loading } from '@forward-financing/fast-forward';
import { ExceptionsRequest } from './ExceptionsRequest';
import { useCoreSalesforceMap } from 'apiHooks/crmGateway/coreSalesforceMapsFetchHooks';
import {
  useCurrentOffer,
  useExceptionRequests,
} from '../exceptionsRequestHooks';
import { ExceptionRequestProgram } from '../exceptionsRequest.types';

type Params = {
  sfid?: string;
};

export interface ExceptionRequestFormShape {
  id?: number | null;
  fundingAmount: number;
  termLength: number;
  buyRate: number;
  program: ExceptionRequestProgram;
  stipExceptionNotes: string;
  offerExceptionNotes: string;
  underwritingLedgerId?: number | null;
}

export const ExceptionsRequestContainer = (): JSX.Element => {
  const { sfid: opportunitySfid }: Params = useParams();

  if (!opportunitySfid) {
    throw new Error('No Submission SFID set in URL');
  }

  const {
    map: coreSalesforceMap,
    loading: coreIdLoading,
    error: coreIdError,
    responseReady: coreIdResponseReady,
  } = useCoreSalesforceMap(opportunitySfid);
  const {
    requests,
    loading: requestsLoading,
    error: requestsError,
    refetch: reloadExceptionRequests,
  } = useExceptionRequests(coreSalesforceMap?.coreId);
  const {
    offer,
    loading: offerLoading,
    error: offerError,
  } = useCurrentOffer(coreSalesforceMap?.coreId);

  if (coreIdLoading || requestsLoading || offerLoading) {
    return (
      <Box p={3}>
        <Loading size="large" />
      </Box>
    );
  }

  if (!coreIdResponseReady) {
    return <></>;
  }

  // istanbul ignore next - this is impossible to hit
  if (!coreSalesforceMap?.coreId) {
    return (
      <Box p={3}>
        <Banner dismissable={false}>
          Something has gone terribly wrong, and we are in a state that should
          be impossible. Please create an AST.
        </Banner>
      </Box>
    );
  }

  if (coreIdError || requestsError || offerError) {
    return (
      <Box p={3}>
        <Flex flexDirection="column" gap={2}>
          {coreIdError && (
            <Banner dismissable={false}>{coreIdError.message}</Banner>
          )}
          {requestsError && (
            <Banner dismissable={false}>{requestsError.message}</Banner>
          )}
          {offerError && (
            <Banner dismissable={false}>{offerError.message}</Banner>
          )}
        </Flex>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <ExceptionsRequest
        submissionUuid={coreSalesforceMap?.coreId}
        currentOffer={offer}
        exceptionsRequestList={requests || []}
        reloadExceptionRequestsManager={reloadExceptionRequests}
      />
    </Box>
  );
};
