import React, { useEffect } from 'react';
import {
  Banner,
  Card,
  Divider,
  Flex,
  Icon,
  Loading,
  Tabs,
} from '@forward-financing/fast-forward';
import { toNumber } from 'lodash';
import { PotentialFraudContent } from './PotentialFraud/PotentialFraudContent';
import {
  useIneligibilities,
  useLazyPotentialFraudMatches,
} from './HighRiskReviewHooks';
import { IneligibilitiesContent } from './Ineligibilities/IneligibilitiesContent';

export interface HighRiskReviewContainerProps {
  submissionUuid: string;
  customerUuid: string;
}

export const HighRiskReviewContainer = ({
  submissionUuid,
  customerUuid,
}: HighRiskReviewContainerProps): JSX.Element => {
  const [
    fetchPotentialFraudMatches,
    {
      data: potentialFraudMatches,
      error: potentialFraudError,
      responseReady: potentialFraudMatchesResponseReady,
    },
  ] = useLazyPotentialFraudMatches();

  const {
    data: ineligibilities,
    error: ineligibilitiesError,
    responseReady: ineligibleResponseReady,
  } = useIneligibilities(customerUuid);

  useEffect(() => {
    void fetchPotentialFraudMatches(submissionUuid);
  }, [fetchPotentialFraudMatches, submissionUuid]);

  // Sum of all matched customers and the current customer, if it has any ineligibilities
  // Regardless of how many ineligibilities the current customer has, we only count it
  // as one customer.
  const totalIneligibleCustomers =
    toNumber(ineligibilities?.matchedCustomers?.length || 0) +
    (ineligibilities &&
    ineligibilities.currentCustomerIneligibilities.length >= 1
      ? 1
      : 0);

  const shouldHideContainer =
    potentialFraudMatchesResponseReady &&
    ineligibleResponseReady &&
    !potentialFraudError &&
    !ineligibilitiesError &&
    potentialFraudMatches.length === 0 &&
    totalIneligibleCustomers === 0;

  if (shouldHideContainer) {
    return <></>;
  }

  return (
    <Card title="High Risk Review" collapsible>
      {(!potentialFraudMatchesResponseReady || !ineligibleResponseReady) && (
        <Loading />
      )}

      {potentialFraudMatchesResponseReady && ineligibleResponseReady && (
        <Tabs
          defaultValue={
            potentialFraudMatches.length === 0
              ? 'ineligible'
              : 'potential-fraud'
          }
        >
          <Tabs.List>
            <Tabs.Trigger value="potential-fraud">
              <Flex gap={2} alignItems="center">
                {potentialFraudMatches.length > 0 && (
                  <Icon name="circle-exclamation" color="error" />
                )}
                Potential Fraud ({potentialFraudMatches.length})
              </Flex>
            </Tabs.Trigger>
            <Tabs.Trigger value="ineligible">
              <Flex gap={2} alignItems="center">
                {totalIneligibleCustomers > 0 && (
                  <Icon name="circle-exclamation" color="error" />
                )}
                Ineligible ({totalIneligibleCustomers})
              </Flex>
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="potential-fraud">
            <Divider />

            {potentialFraudError && (
              <Banner>{potentialFraudError.message}</Banner>
            )}

            <PotentialFraudContent
              matches={potentialFraudMatches}
              submissionUuid={submissionUuid}
              refetchMatches={fetchPotentialFraudMatches}
            />
          </Tabs.Content>
          <Tabs.Content value="ineligible">
            <Divider />

            {ineligibilitiesError && (
              <Banner>{ineligibilitiesError.message}</Banner>
            )}

            {ineligibilities && (
              <IneligibilitiesContent ineligibilities={ineligibilities} />
            )}
          </Tabs.Content>
        </Tabs>
      )}
    </Card>
  );
};
