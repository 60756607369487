import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { getYear } from '../../helpers/string/dateUtils';
import {
  UCCInterface,
  DateWithTotalInterface,
} from './codecs/UniformCommercialCode.codec';
import {
  Accordion,
  Box,
  Text,
  Card,
  Flex,
  Table,
} from '@forward-financing/fast-forward';

export const countUCCRecords = (
  data: UniformCommercialCodeProps['data']
): number => {
  return (data.totals_by_status && data.totals_by_status.total_filed) || 0;
};

const UCCData = (
  data: UniformCommercialCodeProps['data']
): DateWithTotalInterface[] => {
  const uccHash = (data || {}).totals_by_date_range_and_status || {};
  const uccArray: DateWithTotalInterface[] = Object.entries(uccHash).map(
    ([key, ucc]) => {
      // each key is a date
      const year = getYear(key);
      return { date_range: key, year, ...ucc } as DateWithTotalInterface;
    }
  );
  return uccArray;
};
export interface UniformCommercialCodeProps {
  data: UCCInterface;
}

export const UniformCommercialCode = ({
  data,
}: UniformCommercialCodeProps): JSX.Element => {
  if (!data || isEmpty(data)) {
    return <></>;
  }

  const uccData = UCCData(data);
  const uccSummary = data.totals_by_status;

  return (
    <Box>
      <Accordion type="multiple" defaultValue={['ucc', 'details']}>
        <Accordion.Item value={'ucc'}>
          <Accordion.Trigger>
            Uniform Commercial Code (UCC) Filings
          </Accordion.Trigger>
          <Accordion.Content>
            <Table caption={'UCC'}>
              <Table.Head>
                <Table.ColumnHeader>Date Range</Table.ColumnHeader>
                <Table.ColumnHeader>Year</Table.ColumnHeader>
                <Table.ColumnHeader>Total Filed</Table.ColumnHeader>
                <Table.ColumnHeader>Cautionary</Table.ColumnHeader>
                <Table.ColumnHeader>Release Or Terminated</Table.ColumnHeader>
                <Table.ColumnHeader>Continuous</Table.ColumnHeader>
                <Table.ColumnHeader>Amended Or Assigned</Table.ColumnHeader>
              </Table.Head>
              {uccData.map((data) => (
                <Table.Row key={data.date_range}>
                  <Table.Cell>{data.date_range}</Table.Cell>
                  <Table.Cell>{data.year}</Table.Cell>
                  <Table.Cell>{data.total_filed}</Table.Cell>
                  <Table.Cell>{data.cautionary}</Table.Cell>
                  <Table.Cell>{data.released_or_terminated}</Table.Cell>
                  <Table.Cell>{data.continuous}</Table.Cell>
                  <Table.Cell>{data.amended_or_assigned}</Table.Cell>
                </Table.Row>
              ))}
              <Table.SummaryRow>
                <Table.Cell>Totals</Table.Cell>
                <Table.Cell />
                <Table.Cell>{uccSummary?.total_filed}</Table.Cell>
                <Table.Cell>{uccSummary?.cautionary}</Table.Cell>
                <Table.Cell>{uccSummary?.released_or_terminated}</Table.Cell>
                <Table.Cell>{uccSummary?.continuous}</Table.Cell>
                <Table.Cell>{uccSummary?.amended_or_assigned}</Table.Cell>
              </Table.SummaryRow>
            </Table>
            <Text>
              ** Cautionary UCC Filings include one or more of the following
              collateral: Accounts, Accounts Receivables, Contract Rights,
              Hereafter Acquired Property, Inventory, Leases, Notes Receivable
              or Proceeds.
            </Text>
          </Accordion.Content>
        </Accordion.Item>

        <Accordion.Item value={'details'}>
          <Accordion.Trigger>UCC Details</Accordion.Trigger>
          <Accordion.Content>
            <Flex flexDirection={'column'} gap={2}>
              <Card collapsible title="Filed">
                <Table caption={'filed'}>
                  <Table.Head>
                    <Table.ColumnHeader>UCC Filed Date</Table.ColumnHeader>
                    <Table.ColumnHeader>Filing Number</Table.ColumnHeader>
                    <Table.ColumnHeader>
                      Original Filing Date
                    </Table.ColumnHeader>
                    <Table.ColumnHeader>Original Filing #</Table.ColumnHeader>
                    <Table.ColumnHeader>Jurisdiction</Table.ColumnHeader>
                    <Table.ColumnHeader>Secured Party</Table.ColumnHeader>
                    <Table.ColumnHeader>Collateral</Table.ColumnHeader>
                  </Table.Head>
                  {data.filed.map((data) => (
                    <Table.Row key={data.filing_number}>
                      <Table.Cell>{data.filed_date}</Table.Cell>
                      <Table.Cell>{data.filing_number}</Table.Cell>
                      <Table.Cell>{data.original_filing_date}</Table.Cell>
                      <Table.Cell>{data.original_filing_number}</Table.Cell>
                      <Table.Cell>{data.jurisdiction}</Table.Cell>
                      <Table.Cell>{data.secured_party}</Table.Cell>
                      <Table.Cell>{data.collateral}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table>
              </Card>
              <Card collapsible title="Terminated">
                <Table caption={'terminated'}>
                  <Table.Head>
                    <Table.ColumnHeader>UCC Terminated Date</Table.ColumnHeader>
                    <Table.ColumnHeader>Filing Number</Table.ColumnHeader>
                    <Table.ColumnHeader>
                      Original Filing Date
                    </Table.ColumnHeader>
                    <Table.ColumnHeader>Original Filing #</Table.ColumnHeader>
                    <Table.ColumnHeader>
                      Original Filing State
                    </Table.ColumnHeader>
                    <Table.ColumnHeader>Jurisdiction</Table.ColumnHeader>
                    <Table.ColumnHeader>Secured Party</Table.ColumnHeader>
                  </Table.Head>
                  {data.terminated.map((data) => (
                    <Table.Row key={data.filing_number}>
                      <Table.Cell>{data.terminated_date}</Table.Cell>
                      <Table.Cell>{data.filing_number}</Table.Cell>
                      <Table.Cell>{data.original_filing_date}</Table.Cell>
                      <Table.Cell>{data.original_filing_number}</Table.Cell>
                      <Table.Cell>{data.original_filing_state}</Table.Cell>
                      <Table.Cell>{data.jurisdiction}</Table.Cell>
                      <Table.Cell>{data.secured_party}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table>
              </Card>
            </Flex>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};
