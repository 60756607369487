import { defaultTo, isArray } from 'lodash';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiDataMerch } from 'apiHooks/underwriting/submissionFetchHooks';
import {
  DataMerchNotesResponse,
  DataMerchReportResponse,
} from 'types/api/underwriting/types';
import { DataMerch, DataMerchError, Note } from './dataMerchTypes.types';

const toNotes = (note: DataMerchNotesResponse): Note => {
  return {
    note: defaultTo(note.note, undefined),
    category: defaultTo(note.category, undefined),
    createdAt: defaultTo(note.created_at, undefined),
    addedBy: defaultTo(note.added_by, undefined),
  };
};

const toDataMerch = (
  response: DataMerchReportResponse
): DataMerch[] | DataMerchError => {
  if (!response.documents) {
    return [];
  }

  const merchants = response.documents.full_payload.body;

  if ('merchant' in merchants || isArray(merchants)) {
    return [merchants].flat().map((merch) => ({
      legalName: merch.merchant.legal_name,
      fein: merch.merchant.fein,
      dba: defaultTo(merch.merchant.dba, undefined),
      notes: merch.merchant.notes.map((n) => toNotes(n.note)),
    }));
  } else if ('response' in merchants) {
    return { error: merchants.response.error };
  }

  return [];
};

export const useDataMerchReport = (
  submissionUuid?: string
): UseGenericQueryResponse<DataMerch[] | DataMerchError> => {
  return useGenericFeatureQuery(
    useGetApiDataMerch,
    (data) => data && toDataMerch(data),
    submissionUuid
  );
};
