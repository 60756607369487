import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiStipulationRecommendations } from 'apiHooks/underwriting/submissionFetchHooks';
import { StipulationRecommendationsResponse } from 'types/api/underwriting/types';
import { StipulationRecommendation } from './stipulationRecommendations.types';

const toStipulationRecommendation = (
  response?: StipulationRecommendationsResponse[]
): StipulationRecommendation[] | undefined => {
  if (!response) {
    return undefined;
  }

  return response.map((item) => {
    return {
      submissionUuid: item.submission_uuid,
      stipulationType: item.stipulation_type,
      bucket: item.bucket,
      overallScore: item.overall_score,
      ...(item?.results && {
        results: {
          experianCommercial: {
            score: item?.results?.experian_commercial.score,
            reasons: item?.results?.experian_commercial.reasons,
          },
          experianConsumer: {
            score: item.results?.experian_consumer.score,
            reasons: item.results?.experian_consumer.reasons,
          },
          lexisNexisBusiness: {
            score: item.results?.lexis_nexis_business.score,
            reasons: item.results?.lexis_nexis_business.reasons,
          },
          lexisNexisPersonal: {
            score: item.results?.lexis_nexis_personal.score,
            reasons: item.results?.lexis_nexis_personal.reasons,
          },
        },
      }),
    };
  });
};

export const useStipulationRecommendations = (
  submissionUuid?: string
): UseGenericQueryResponse<StipulationRecommendation[]> =>
  useGenericFeatureQuery(
    useGetApiStipulationRecommendations,
    toStipulationRecommendation,
    submissionUuid
  );
