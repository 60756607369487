import {
  Box,
  Divider,
  Flex,
  Grid,
  Subheading,
  TextInput,
} from '@forward-financing/fast-forward';
import React from 'react';
import { WebPresence } from './SubmissionEditing.types';
import { WebPresenceFormSchemaError } from './SubmissionSectionForm';

type WebPresenceFormProps = {
  webPresenceForm: WebPresence;
  webPresenceErrors?: WebPresenceFormSchemaError;
  handleWebPresenceFormOnchange: (
    newVal: string,
    field: keyof WebPresence
  ) => void;
};

export const WebPresenceForm = ({
  webPresenceForm,
  webPresenceErrors,
  handleWebPresenceFormOnchange,
}: WebPresenceFormProps): JSX.Element => {
  return (
    <Box mt={3} pr={3} pl={3} pb={4} borderColor="blue-900" rounded>
      <Flex mt={2} textAlign={'left'} flexDirection={'column'}>
        <Subheading variant="section">Social Presence</Subheading>
        <Box mb={3}>
          <Divider />
        </Box>
      </Flex>

      <Grid gutter>
        <Grid.Item s={12} m={6} l={6}>
          <Flex flexDirection={'column'} gap={2}>
            <TextInput
              label="Website"
              beforeInputContent={<TextInput.DecorativeIcon icon="globe" />}
              value={webPresenceForm.businessWebsite}
              onValueChange={(newVal) =>
                handleWebPresenceFormOnchange(newVal, 'businessWebsite')
              }
              errorMessage={webPresenceErrors?.businessWebsite?._errors.join(
                ' '
              )}
            />
            <TextInput
              label="Instagram"
              beforeInputContent={
                <TextInput.DecorativeIcon icon={['fab', 'instagram']} />
              }
              value={webPresenceForm.instagram}
              onValueChange={(newVal) =>
                handleWebPresenceFormOnchange(newVal, 'instagram')
              }
              errorMessage={webPresenceErrors?.instagram?._errors.join(' ')}
            />
            <TextInput
              label="Other"
              value={webPresenceForm.other}
              onValueChange={(newVal) =>
                handleWebPresenceFormOnchange(newVal, 'other')
              }
              errorMessage={webPresenceErrors?.other?._errors.join(' ')}
            />
          </Flex>
        </Grid.Item>

        <Grid.Item s={12} m={6} l={6}>
          <Flex flexDirection={'column'} gap={2}>
            <TextInput
              label="Facebook"
              beforeInputContent={
                <TextInput.DecorativeIcon icon={['fab', 'facebook']} />
              }
              value={webPresenceForm.facebook}
              onValueChange={(newVal) =>
                handleWebPresenceFormOnchange(newVal, 'facebook')
              }
              errorMessage={webPresenceErrors?.facebook?._errors.join(' ')}
            />

            <TextInput
              label="Yelp"
              beforeInputContent={
                <TextInput.DecorativeIcon icon={['fab', 'yelp']} />
              }
              value={webPresenceForm.yelp}
              onValueChange={(newVal) =>
                handleWebPresenceFormOnchange(newVal, 'yelp')
              }
              errorMessage={webPresenceErrors?.yelp?._errors.join(' ')}
            />
          </Flex>
        </Grid.Item>
      </Grid>
    </Box>
  );
};
