import React from 'react';
import { Box, Tabs } from '@forward-financing/fast-forward';
import { BusinessSummary } from './BusinessSummary';
import { TradeLines } from './TradeLines';
import { InquiriesInterface } from './codecs/Inquiries.codec';
import { PublicRecordsInterface } from './codecs/PublicRecords.codec';
import { UCCInterface } from './codecs/UniformCommercialCode.codec';
import { Inquiries, countInquiries } from './Inquiries';
import { PublicRecords, countPublicRecords } from './PublicRecords';
import {
  UniformCommercialCode,
  countUCCRecords,
} from './UniformCommercialCode';
import { BusinessSummaryInterface } from './codecs/BusinessSummary.codec';
import { TradeLinesData } from './codecs/TradeLines.codec';
import { ErrorBoundary } from 'components/shared/generic/ErrorBoundary';

export interface BusinessCreditTabsProps {
  public_records: PublicRecordsInterface;
  ucc: UCCInterface;
  inquiries: InquiriesInterface;
  business_summary: BusinessSummaryInterface;
  trade_lines: TradeLinesData;
}

export const BusinessCreditTabs = (
  props: BusinessCreditTabsProps
): JSX.Element => {
  const publicRecordsCount = countPublicRecords(props.public_records);
  const uccCount = countUCCRecords(props.ucc);
  const inquiriesCount = countInquiries(props.inquiries);

  return (
    <Box>
      <Tabs defaultValue="businessSummary">
        <Tabs.List>
          <Tabs.Trigger value="businessSummary">Business Summary</Tabs.Trigger>
          <Tabs.Trigger value="publicRecords">
            Public Records ({publicRecordsCount})
          </Tabs.Trigger>
          <Tabs.Trigger value="uccs">UCCs ({uccCount})</Tabs.Trigger>
          <Tabs.Trigger value="inquiries">
            Inquiries ({inquiriesCount})
          </Tabs.Trigger>
          <Tabs.Trigger value="tradeLines">Trade Lines</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="businessSummary">
          <ErrorBoundary>
            <BusinessSummary data={props.business_summary} />
          </ErrorBoundary>
        </Tabs.Content>
        <Tabs.Content value="publicRecords">
          <ErrorBoundary>
            <PublicRecords data={props.public_records} />
          </ErrorBoundary>
        </Tabs.Content>
        <Tabs.Content value="uccs">
          <ErrorBoundary>
            <UniformCommercialCode data={props.ucc} />
          </ErrorBoundary>
        </Tabs.Content>
        <Tabs.Content value="inquiries">
          <ErrorBoundary>
            <Inquiries data={props.inquiries} />
          </ErrorBoundary>
        </Tabs.Content>
        <Tabs.Content value="tradeLines">
          <ErrorBoundary>
            <TradeLines data={props.trade_lines} />
          </ErrorBoundary>
        </Tabs.Content>
      </Tabs>
    </Box>
  );
};
