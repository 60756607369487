import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { UpdateApplicationRequestBody } from 'types/api/underwriting/types';

const underwriting = (path: string): URL =>
  new URL(path, UNDERWRITING_BASE_URL());

export const patchApplication = async (
  applicationUuid: string,
  body: UpdateApplicationRequestBody
): Promise<boolean> => {
  const url = underwriting(`/api/v2/applications/${applicationUuid}`);

  const response = await makeInternalAPIRequest<
    boolean,
    UpdateApplicationRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to update Application in Underwriting'
    );
  }

  return true;
};
