import React, { useEffect, useState } from 'react';
import { BorderedContainer } from '../../shared/BorderedContainer';
import {
  Opportunity,
  Submission,
} from '../../../api/UnderwritingClient/codecs';
import { DropdownOption, HTMLFormChangeEvent } from '../../../types/form';
import { validate } from '../../../helpers/validations/FieldValidator';
import { isRequired } from '../../../helpers/validations/ValidationHelpers';
import { userFullName } from '../../../helpers/utils';

import { Dropdown } from '../../shared/form/Dropdown';
import { Banner, Box, Grid, Text } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import {
  useGetUsersByRole,
  useUpdateSubmission,
} from 'components/Submissions/SubmissionEditingHooks';
import { User } from 'components/Submissions/SubmissionEditing.types';

export interface AssignmentInformationFormProps {
  opportunity: Opportunity;
  submission: Submission;

  handleApplicationChange: (e: HTMLFormChangeEvent) => void;
  isSubmitting?: boolean;
}

const userOptions = (
  users: User[] | undefined,
  label: string,
  showLabel: boolean
): DropdownOption[] => {
  if (!users) {
    return [];
  }
  const userDropdownOptions = users
    .map((user) => {
      const fullName = userFullName(user.firstName, user.lastName);
      return { value: fullName, label: fullName };
    })
    .sort((userA, userB) => userA.label.localeCompare(userB.label));
  return showLabel
    ? [{ value: '', label }, ...userDropdownOptions]
    : userDropdownOptions;
};

const analystOptions = (
  users: User[] | undefined,
  label: string,
  showLabel: boolean
): DropdownOption[] => {
  if (!users) {
    return [];
  }
  const options = users
    .map((user) => {
      return {
        value: user.id,
        label: userFullName(user.firstName, user.lastName),
      };
    })
    .sort((userA, userB) => userA.label.localeCompare(userB.label));
  return showLabel ? [{ value: '', label }, ...options] : options;
};

export const AssignmentInformationForm = (
  props: AssignmentInformationFormProps
): JSX.Element => {
  const { role, sub_role: subRole } = useUserContext();
  const { data: prequalUsers, error: prequalError } =
    useGetUsersByRole('prequal');

  const { data: processingUsers, error: processingError } =
    useGetUsersByRole('processor');

  const { data: vcaUsers, error: vcaError } = useGetUsersByRole('vca');

  const { data: teamLeadsUsers, error: teamLeadError } = useGetUsersByRole(
    'processing_team_lead'
  );

  const { data: underwritingUsers, error: underwritingError } =
    useGetUsersByRole('underwriter');

  const [underwriter, setUnderwriter] = useState(
    props.opportunity.x2dc_underwriter__c
  );
  const [decisionAnalyst, setDecisionAnalyst] = useState(
    props.opportunity.decision_analyst_id
  );
  const [processingAnalyst, setProcessingAnalyst] = useState(
    props.opportunity.processing_analyst_id
  );

  const [updateSubmission, { error: updateSubmissionError }] =
    useUpdateSubmission(props.opportunity.uuid);

  // This is temporary fix. We have one submit button in the parent component and
  // we are still using the big state from that class component, so until we refactor each
  // section and get rid of that state, we need a workaround to submit everything at the same time.
  useEffect(() => {
    const onSubmit = async (): Promise<void> => {
      await updateSubmission({
        decisionAnalystId: decisionAnalyst || undefined,
        processingAnalystId: processingAnalyst || undefined,
        underwriter: underwriter,
        allowDecisionAnalystOverwrite: true,
      });
    };
    if (props.isSubmitting) {
      void onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSubmitting]);

  const canUpdateAnalysts = (): boolean => {
    const isAdminOrSuperAdmin = ['admin', 'super_admin'].includes(role || '');

    const isPrequalTeamLead = role === 'processing' && subRole === 'team_lead';

    return isAdminOrSuperAdmin || isPrequalTeamLead;
  };
  const prequalDropdownOptions = prequalUsers?.concat(teamLeadsUsers || []);
  const processingDropdownOptions = processingUsers?.concat(
    vcaUsers || [],
    teamLeadsUsers || []
  );

  return (
    <BorderedContainer label="Assignment Information">
      <Box pb={3}>
        {updateSubmissionError && (
          <Banner dismissable={false}>
            <Text>{updateSubmissionError.message}</Text>
          </Banner>
        )}
        {prequalError && (
          <Banner dismissable={false}>
            <Text>{prequalError.message}</Text>
          </Banner>
        )}
        {underwritingError && (
          <Banner dismissable={false}>
            <Text>{underwritingError.message}</Text>
          </Banner>
        )}
        {teamLeadError && (
          <Banner dismissable={false}>
            <Text>{teamLeadError.message}</Text>
          </Banner>
        )}
        {vcaError && (
          <Banner dismissable={false}>
            <Text>{vcaError.message}</Text>
          </Banner>
        )}
        {processingError && (
          <Banner dismissable={false}>
            <Text>{processingError.message}</Text>
          </Banner>
        )}
      </Box>

      <Grid gutter>
        {canUpdateAnalysts() && (
          <Grid.Item m={12} l={6}>
            <Dropdown
              label="Sub_On Analyst Name"
              name="prequal_analyst_name"
              options={userOptions(
                prequalDropdownOptions,
                'Select Analyst',
                !props.submission.prequal_analyst_name
              )}
              onChange={props.handleApplicationChange}
              validationResult={validate(
                [isRequired],
                props.submission.prequal_analyst_name
              )}
              value={props.submission.prequal_analyst_name}
            />
          </Grid.Item>
        )}

        <Grid.Item m={12} l={6}>
          <Dropdown
            label="Underwriter Name"
            name="x2dc_underwriter__c"
            options={userOptions(
              underwritingUsers,
              'Select Underwriter',
              !underwriter
            )}
            onChange={(e) => setUnderwriter(e.target.value)}
            value={underwriter}
          />
        </Grid.Item>

        {canUpdateAnalysts() && (
          <Grid.Item m={12} l={6}>
            <Dropdown
              label="Prequal Analyst Name"
              name="decision_analyst_id"
              options={analystOptions(
                prequalDropdownOptions,
                'Select Analyst',
                !decisionAnalyst
              )}
              onChange={(e) => setDecisionAnalyst(+e.target.value)}
              value={decisionAnalyst ?? ''}
            />
          </Grid.Item>
        )}

        {canUpdateAnalysts() && (
          <Grid.Item m={12} l={6}>
            <Dropdown
              label="Processor Name"
              name="processing_analyst_id"
              options={analystOptions(
                processingDropdownOptions,
                'Select Processor',
                !processingAnalyst
              )}
              onChange={(e) => setProcessingAnalyst(+e.target.value)}
              value={processingAnalyst ?? ''}
            />
          </Grid.Item>
        )}
      </Grid>
    </BorderedContainer>
  );
};
