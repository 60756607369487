import React from 'react';
import { FUNDING_URL } from 'constants/globals';
import {
  Table,
  Text,
  Currency,
  formatDateTimeString,
  Flex,
  Link,
} from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from './PaymentScheduleContext';
import { capitalize } from 'lodash';
import { toPascalCase } from './paymentScheduleUtils';

interface PaymentDetailsNoteProps {
  // user's name
  createdBy?: string;
  // a date time string
  createdOn?: string;
}

const PaymentDetailsNote = (props: PaymentDetailsNoteProps): JSX.Element => {
  if (!props.createdBy || !props.createdOn) {
    return <></>;
  }

  return (
    <Text italic color="status">
      Created by {props.createdBy} on {formatDateTimeString(props.createdOn)}
    </Text>
  );
};

export const PaymentDetailsTable = (): JSX.Element => {
  const { formState, paymentSchedule } = usePaymentScheduleContext();

  const selectedDateData = paymentSchedule?.find(
    (o) => formState.startDate && o.date.equals(formState.startDate)
  );

  const sameDayAchPayments =
    selectedDateData && selectedDateData.kind === 'ScheduleDay'
      ? selectedDateData.payments.filter((payment) => {
          return payment.sameDayAch;
        })
      : [];

  if (
    selectedDateData === undefined ||
    selectedDateData.kind === 'HolidayDay' ||
    selectedDateData.kind === 'EmptyDay'
  ) {
    return <></>;
  }

  if (
    selectedDateData?.kind === 'IndefinitePauseDay' ||
    selectedDateData?.status === 'skipped'
  ) {
    return (
      <>
        <Text>No payments required for this day.</Text>
        {selectedDateData.notes && (
          <Text>
            <Text bold>Notes:</Text> {selectedDateData.notes}
          </Text>
        )}
        <PaymentDetailsNote {...selectedDateData} />
      </>
    );
  }

  if (
    selectedDateData.payments.length === 0 &&
    selectedDateData.scheduledSameDayAch !== undefined
  ) {
    return (
      <>
        <>
          <Table caption="Expected Same Day ACH Payment Details">
            <Table.Body>
              <Table.Row>
                <Table.RowHeader>Expected Payment Amount</Table.RowHeader>
                <Table.Cell>
                  <Currency
                    amount={selectedDateData.scheduledSameDayAch.amount}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>Payment Status</Table.RowHeader>
                <Table.Cell>Scheduled</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>Repeats</Table.RowHeader>
                <Table.Cell>Same Day ACH</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
        {selectedDateData.notes && (
          <Text>
            <Text bold>Notes:</Text> {selectedDateData.notes}
          </Text>
        )}
        <PaymentDetailsNote {...selectedDateData} />
      </>
    );
  }

  if (selectedDateData.payments.length === 0) {
    return (
      <>
        <Table caption="Payment Details">
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Expected Payment Amount</Table.RowHeader>
              <Table.Cell>
                <Currency amount={selectedDateData.amount} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Payment Status</Table.RowHeader>
              <Table.Cell>{capitalize(selectedDateData.status)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Percentage Paid</Table.RowHeader>
              <Table.Cell>
                {typeof selectedDateData.percentageRepaid === 'number'
                  ? `${selectedDateData.percentageRepaid.toFixed(2)}%`
                  : 'N/A'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Payment Provider</Table.RowHeader>
              <Table.Cell>{toPascalCase(selectedDateData.provider)}</Table.Cell>
            </Table.Row>
            {selectedDateData.frequency && (
              <Table.Row>
                <Table.RowHeader>Repeats</Table.RowHeader>
                <Table.Cell>
                  {selectedDateData.frequency === 'weekly' &&
                  selectedDateData.recurrency === 2
                    ? 'Biweekly'
                    : capitalize(selectedDateData.frequency)}
                </Table.Cell>
              </Table.Row>
            )}
            {selectedDateData.adjustmentReason && (
              <Table.Row>
                <Table.RowHeader>Adjustment Type</Table.RowHeader>
                <Table.Cell>{selectedDateData.adjustmentReason}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {selectedDateData.notes && (
          <Text>
            <Text bold>Notes:</Text> {selectedDateData.notes}
          </Text>
        )}
        <PaymentDetailsNote {...selectedDateData} />
      </>
    );
  }

  return (
    <Flex flexDirection="column" gap={2}>
      {selectedDateData.scheduledSameDayAch !== undefined &&
        sameDayAchPayments.length === 0 && (
          <>
            <Table caption="Expected Same Day ACH Payment Details">
              <Table.Body>
                <Table.Row>
                  <Table.RowHeader>Payment Amount</Table.RowHeader>
                  <Table.Cell>
                    <Currency
                      amount={selectedDateData.scheduledSameDayAch.amount}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.RowHeader>Payment Status</Table.RowHeader>
                  <Table.Cell>Scheduled</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.RowHeader>Repeats</Table.RowHeader>
                  <Table.Cell>Same Day ACH</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
      {selectedDateData.payments.map((payment) => (
        <Table caption="Payment Details" key={payment.toString()}>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Payment Amount</Table.RowHeader>
              <Table.Cell>
                <Currency amount={payment.amount} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Payment Method</Table.RowHeader>
              <Table.Cell>
                {selectedDateData.manual ? 'Manual' : 'ACH'}
              </Table.Cell>
            </Table.Row>
            {selectedDateData.frequency && (
              <Table.Row>
                <Table.RowHeader>Repeats</Table.RowHeader>
                <Table.Cell>
                  {payment.sameDayAch
                    ? 'Same Day ACH'
                    : capitalize(selectedDateData.frequency)}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.RowHeader>Payment Provider</Table.RowHeader>
              <Table.Cell>{toPascalCase(payment.provider)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Percentage Paid</Table.RowHeader>
              <Table.Cell>
                {typeof selectedDateData.percentageRepaid === 'number'
                  ? `${selectedDateData.percentageRepaid.toFixed(2)}%`
                  : 'N/A'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Payment Status</Table.RowHeader>
              <Table.Cell>{capitalize(payment.status)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              {payment.status === 'rejected' ? (
                <>
                  <Table.RowHeader>Status Description</Table.RowHeader>
                  <Table.Cell columnWidth="large" overflowStrategy="wrap">
                    {payment.code}: {payment.reason}. {payment.description}
                  </Table.Cell>
                </>
              ) : (
                <>
                  <Table.RowHeader>Description</Table.RowHeader>
                  <Table.Cell>{payment.description}</Table.Cell>
                </>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Notes</Table.RowHeader>
              <Table.Cell>{selectedDateData.notes}</Table.Cell>
            </Table.Row>
            {payment.batchPath && (
              <Table.Row>
                <Table.RowHeader>Payment Batch</Table.RowHeader>
                <Table.Cell>
                  <Link
                    newTab
                    href={new URL(`${payment.batchPath}`, FUNDING_URL())}
                  >
                    {payment.batchPath.split('/').pop()}
                  </Link>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      ))}
      <PaymentDetailsNote {...selectedDateData} />
    </Flex>
  );
};
