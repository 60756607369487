import React from 'react';
import { CorporateAffiliation } from './ownerLexisNexisPage.types';

import { Box, Table } from '@forward-financing/fast-forward';
import { LexisNexisManualSearchTable } from './LexisNexisManualSearchTable';

type CorporateAffiliationsTableProps = {
  submissionUuid: string;
  corporateAffiliations: CorporateAffiliation[];
};

export const CorporateAffiliationsTable = ({
  submissionUuid,
  corporateAffiliations,
}: CorporateAffiliationsTableProps): JSX.Element => {
  return (
    <Box>
      <Table caption={'Corporate Affiliations'}>
        <Table.Head>
          <Table.ColumnHeader />
          <Table.ColumnHeader>Company Name</Table.ColumnHeader>
          <Table.ColumnHeader>Address</Table.ColumnHeader>
          <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
          <Table.ColumnHeader>State</Table.ColumnHeader>
          <Table.ColumnHeader>Status</Table.ColumnHeader>
          <Table.ColumnHeader>Record Type</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {corporateAffiliations.map((data) => (
            <Table.ExpandableRow
              key={`${data.companyName}${data.filingDate}`}
              content={
                <Box mr={6}>
                  <LexisNexisManualSearchTable
                    submissionUuid={submissionUuid}
                    companyName={data.companyName}
                  />
                </Box>
              }
            >
              <Table.Cell>{data.companyName}</Table.Cell>
              <Table.Cell>{data.address}</Table.Cell>
              <Table.Cell>{data.filingDate}</Table.Cell>
              <Table.Cell>{data.state}</Table.Cell>
              <Table.Cell>{data.status}</Table.Cell>
              <Table.Cell>{data.recordType}</Table.Cell>
            </Table.ExpandableRow>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
