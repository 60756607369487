import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { StateListResponse } from 'types/api/underwriting/types';

export const fetchStatesList = async (): Promise<StateListResponse[]> => {
  const url = new URL(`/api/v1/states`, UNDERWRITING_BASE_URL());

  const response = await makeInternalAPIRequest<StateListResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Error fetching states list');
  }

  return response.json();
};
