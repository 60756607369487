import React from 'react';
import { withLinkStyles } from '@forward-financing/fast-forward';
import { Link, LinkProps } from 'react-router-dom';

interface LinkWrapperProps extends Omit<LinkProps, 'to'> {
  to: string;
}

const LinkWrapper = (props: LinkWrapperProps): JSX.Element => {
  return <Link {...props} />;
};
export const InternalLink = withLinkStyles(LinkWrapper);
