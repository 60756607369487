import React from 'react';
import { Partner } from '../api/FundingClient/codecs';
import { UsState } from '../api/UnderwritingClient/codecs';
import { DropdownOption } from '../../src/types/form';
import { User } from '../api/AuthClient/codecs';
import { notAvailableMsg, unableToFetchMsg } from '../constants/globals';

export function isNullOrUndefined<T>(obj: T | undefined | null): obj is T {
  return typeof obj === 'undefined' || obj === null;
}

export const findPartner = (
  partners: Partner[],
  partner_id: number
): Partner | undefined =>
  partners.find((partner) => partner.value === partner_id);

export const findIsoByUuid = (
  isos: Partner[],
  isoUuid: string
): Partner | undefined => isos.find((iso) => iso.uuid === isoUuid);

export const dropDownStates = (usStates: UsState[]): DropdownOption[] => {
  return [
    {
      value: '',
      label: 'Select a State',
    },
    ...usStates.map((usState) => ({
      value: usState.abbreviation,
      label: usState.name,
    })),
  ];
};

export const totalOwnership = (
  ownersToCheck: { ownership_percentage?: number | string }[]
): number =>
  ownersToCheck.reduce((total, owner) => {
    let ownershipPercentage = owner.ownership_percentage || 0;

    if (typeof ownershipPercentage === 'string') {
      ownershipPercentage = Number.parseFloat(ownershipPercentage);
    }

    return ownershipPercentage + total;
  }, 0);

export const userFullName = (first_name: string, last_name: string): string => {
  return `${first_name} ${last_name}`;
};

export const zipCodeMask = (input: string | null | undefined): string => {
  const numbers = input?.match(/\d/g);
  const allDigitsOnInput = numbers ? numbers.length : 0;

  if (allDigitsOnInput > 5 || allDigitsOnInput === 0) {
    return '99999-9999';
  } else if (allDigitsOnInput < 5) {
    return '99999';
  } else {
    return '';
  }
};

export const obtainValidZipCodeEvent = (
  e: React.ChangeEvent<HTMLInputElement>
): React.ChangeEvent<HTMLInputElement> => {
  const zipValue = e.target.value;

  // Since onChange happens before setting the mask,
  // this verifies contents after the dash ("-") from the zip have no digits.
  if (/^\d{5}\D*$/.test(zipValue)) {
    const syntheticEvent = {
      persist: () => {
        // Intentionally empty
      },
      target: {
        name: e.target.name,
        value: zipValue.slice(0, 5),
      },
    };

    return syntheticEvent as React.ChangeEvent<HTMLInputElement>;
  } else {
    return e;
  }
};

export const declineDriversOptions = (
  declineDrivers: string[]
): DropdownOption[] =>
  declineDrivers.map((driver) => ({ value: driver, label: driver }));

export const sanitizeTargetValue = (target: HTMLInputElement): string => {
  switch (target.name) {
    case 'capital_needed':
      //this regrex replaces anything that is not a digit, with a empty space, after that it formats the number to follow currency structure
      return currencyFormat(target.value.replace(/[^0-9.]/g, ''));
    case 'account_number':
      return target.value.replace(/[^0-9]/g, '');
    default:
      return target.value;
  }
};
//These are required style props for transition component
export const transitionStyles = {
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const defaultStyle = {
  transition: `transform 400ms, opacity 400ms ease`,
  opacity: 1,
};

export const currencyFormat = (
  input?: string | number | null,
  addSign = false
): string => {
  // we don't want empty dollar sign
  if (input === null || input === undefined) {
    return '';
  }
  // if is one of the controlled error messages we can assume it will be readable for the user
  if (input === unableToFetchMsg || input === notAvailableMsg) {
    return input;
  }
  if (typeof input === 'number') {
    // eslint-disable-next-line no-param-reassign
    input = input.toString();
  }
  let formated = (input || '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  if (addSign) {
    formated = `$${formated}`;
  }
  return formated;
};

export const isAdmin = (user: User): boolean => user.role === 'admin';
export const isProcessing = (user: User): boolean => user.role === 'processing';
export const isUnderwriter = (user: User): boolean => {
  return (
    isAdmin(user) &&
    [
      'senior_underwriter',
      'mid_underwriter',
      'junior_underwriter',
      'onboarding_underwriter',
    ].includes(user.sub_role || '')
  );
};

// ((New value-old value)/old value)*100
export function percentageDifference(
  newValue: number,
  oldValue: number
): string {
  return (100 * Math.abs((newValue - oldValue) / oldValue)).toFixed(2);
}

export function displayPercentage(input: number): string {
  return `${input.toFixed(2)}%`;
}

export function displayMonths(input: number): string {
  return `${input} months`;
}

export function isPriorToApproval(stageName: string): boolean {
  return ['Processing', 'Underwriting', 'Declined'].includes(stageName);
}

export const isAfterUnderwriting = (stage: string): boolean => {
  return [
    'Approved',
    'Contracting',
    'Closing',
    'Declined',
    'Funded',
    'Non-Responsive',
    'Offer Not Accepted',
    'Returned Funds',
  ].includes(stage);
};
