import React, { useCallback } from 'react';
import {
  Flex,
  Subheading,
  Loading,
  Banner,
} from '@forward-financing/fast-forward';

import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { SuggestedPricingContainer } from '../SuggestedPricingContainer';
import { useCreateCalculatedOffer, useLedgerOffers } from '../ledgerHooks';
import { Ledger } from '../ledger.types';
import { OffersTable } from './OffersTable';
import { LedgerOffer } from './offers.types';

interface OffersContainerProps {
  submissionUuid: string;
  ledger: Ledger;
  customerUuid?: string;
  readOnly?: boolean;
  handleChangeLedger: (updates: Partial<Ledger>) => void;
  eligibleForWeekly: boolean;
  isRenewal?: boolean;
}

export const OffersContainer = (props: OffersContainerProps): JSX.Element => {
  const { data, error, loading } = useLedgerOffers({
    submissionUuid: props.submissionUuid,
    ledgerId: props.ledger.id,
  });

  const [calculateOffer, { error: calculateOfferError }] =
    useCreateCalculatedOffer();

  const handleCalculateOffer = useCallback(
    async (
      offer: Partial<LedgerOffer> | undefined,
      months: string
    ): Promise<MutationResponse> => {
      if (!offer) return { success: false, response: null };

      return calculateOffer({
        submissionUuid: props.submissionUuid,
        ledgerId: props.ledger.id,
        ledger: props.ledger,
        offer,
        months,
      });
    },
    [calculateOffer, props.ledger, props.submissionUuid]
  );

  return (
    <Flex flexDirection="column">
      <Subheading>Offer</Subheading>

      {loading && <Loading />}

      {!loading && error && <Banner>{error.message}</Banner>}
      {calculateOfferError && <Banner>{calculateOfferError.message}</Banner>}

      {!props.isRenewal && (
        <SuggestedPricingContainer
          ledger={props.ledger}
          submissionUuid={props.submissionUuid}
          customerUuid={props.customerUuid}
        />
      )}

      {!loading && data && (
        <OffersTable
          ledgerCreationDate={props.ledger.createdAt}
          fetchedOffers={data}
          readOnly={props.readOnly}
          handleChangeLedger={props.handleChangeLedger}
          handleOfferCalculation={handleCalculateOffer}
          eligibleForWeekly={props.eligibleForWeekly}
        />
      )}
    </Flex>
  );
};
