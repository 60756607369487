import React from 'react';
import {
  Flex,
  Grid,
  Icon,
  IconButton,
  Popover,
  RadioGrid,
  RadioGridProps,
  Subheading,
  Table,
  Text,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { UnderwriterScoreForm } from '../NewDealScoring.types';
import { useNewDealScoringContext } from '../NewDealScoringContext';
import { OwnerRiskPopoverContent } from './OwnerRiskPopoverContent';
import { BusinessRiskPopoverContent } from './BusinessRiskPopoverContent';

export const INITIAL_NOTES_TEXT = `Industry: 
Owner Risk: 
Business Risk: 
Cash Flow: 
Pricing Strategy Deviation Reason: `;

export const getAverageScore = (score: UnderwriterScoreForm): number => {
  const attributes = [
    score.businessRisk,
    score.ownerRisk,
    score.qualityOfCashFlows,
    score.dealSpecificFactors,
    score.onlinePresence,
  ];

  const scoredAttributes = attributes.filter(
    (n): n is number => n !== null && n !== 0
  );

  const sum = scoredAttributes.reduce((acc, num) => acc + num, 0);

  return scoredAttributes.length === 0 ? 0 : sum / scoredAttributes.length;
};

export const UnderwriterScoring = (): JSX.Element => {
  const {
    hasUnsavedUnderwriterChanges,
    isPartialSave,
    score,
    systemScore,
    handleScoreUpdate,
    handleUnderwriterTextAreaChange,
    onSubmit,
  } = useNewDealScoringContext();

  const questions: RadioGridProps['questions'] = [
    {
      name: 'ownerRisk',
      value: score.ownerRisk.toString(),
      label: 'Owner Risk',
    },
    {
      name: 'businessRisk',
      value: score.businessRisk.toString(),
      label: 'Business Risk',
    },
    {
      name: 'qualityOfCashFlows',
      value: score.qualityOfCashFlows.toString(),
      label: 'Quality of Cash Flows',
    },
  ];

  const handleRadioGridChange: RadioGridProps['onValueChange'] = (
    name,
    newValue
  ) => {
    handleScoreUpdate({
      ...score,
      [name]: Number(newValue),
    });
  };

  const handleTextAreaChange: TextAreaProps['onChange'] = (e) => {
    handleUnderwriterTextAreaChange({
      ...score,
      underwriterNote: e.target.value,
    });
  };

  const isLegacyScore = !!(score.dealSpecificFactors || score.onlinePresence);

  return (
    <Grid gutter>
      <Grid.Item xs={12} s={5} m={7}>
        <Flex gap={2} alignItems="center">
          {isPartialSave && (
            <Icon
              data-testid="underwriter-score-partial-save-icon"
              color="warning"
              name="exclamation-triangle"
            />
          )}
          {hasUnsavedUnderwriterChanges && (
            <Icon
              data-testid="underwriter-score-unsaved-changes-icon"
              color="danger"
              name="exclamation-circle"
            />
          )}
          <Subheading>
            New Deal Score: {getAverageScore(score).toFixed(1)} / 5.0
          </Subheading>
        </Flex>
        <Grid gutter>
          <Grid.Item xl={7}>
            <Subheading variant="subsection">Select Scores</Subheading>
            <RadioGrid
              options={[
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '5' },
              ]}
              questions={questions}
              onValueChange={handleRadioGridChange}
            />
            {isLegacyScore && (
              <Text>
                * This deal was scored based on a previous, now deprecated
                version of the guidelines.
              </Text>
            )}
          </Grid.Item>
          <Grid.Item xl={5}>
            <Subheading variant="subsection">
              System Suggested Score Breakdown
            </Subheading>
            {systemScore && featureFlags.system_score_in_uw_scoring ? (
              <Table caption={'System Suggested Score Breakdown'}>
                <Table.Head>
                  <Table.ColumnHeader />
                  <Table.ColumnHeader>Score</Table.ColumnHeader>
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    <Table.RowHeader>
                      <Flex alignItems="center" gap={1}>
                        Owner Risk
                        <Popover
                          position="right"
                          trigger={
                            <IconButton
                              icon="question-circle"
                              label="More info"
                              hiddenLabel
                            />
                          }
                          content={<OwnerRiskPopoverContent />}
                          header="Owner Risk"
                        />
                      </Flex>
                    </Table.RowHeader>
                    <Table.Cell>{systemScore.ownerRisk}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>
                      <Flex alignItems="center" gap={1}>
                        Business Risk
                        <Popover
                          position="right"
                          trigger={
                            <IconButton
                              icon="question-circle"
                              label="More info"
                              hiddenLabel
                            />
                          }
                          content={<BusinessRiskPopoverContent />}
                          header="Business Risk"
                        />
                      </Flex>
                    </Table.RowHeader>
                    <Table.Cell>{systemScore.businessRisk}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Quality of Cash Flows</Table.RowHeader>
                    <Table.Cell />
                  </Table.Row>
                </Table.Body>
              </Table>
            ) : (
              <Text>System suggested score is not available.</Text>
            )}
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item xs={12} s={7} m={5}>
        <Subheading>Notes</Subheading>
        <TextArea
          label="Record Notes Below"
          rows={7}
          value={score.underwriterNote}
          onChange={handleTextAreaChange}
          onBlur={onSubmit}
        />
      </Grid.Item>
    </Grid>
  );
};
