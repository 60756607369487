import { useGetApiBusinessUccReport } from 'apiHooks/underwriting/customerFetchHooks';
import { UseGenericQueryResponse } from '../../../../apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from '../../../featureHooks/genericFeatureHooks';
import { UccBusinessFetchResponse } from '../../../../types/api/underwriting/types';
import { UccBusinessReport } from './businessUccReport.types';

const toUccReport = (
  uccResponse: UccBusinessFetchResponse
): UccBusinessReport => {
  return {
    filingDate: uccResponse.filing_date ?? undefined,
    securedParties: uccResponse.secured_parties ?? [],
    debtors: uccResponse.debtors ?? [],
    status: uccResponse.status ?? undefined,
    fileType: uccResponse.file_type ?? undefined,
    fileNumber: uccResponse.file_number,
    state: uccResponse.state,
    collateralDescription: uccResponse.collateral_description ?? undefined,
    closed: uccResponse.closed,
  };
};

export const useUccBusinessReport = (
  submissionUuid?: string,
  reportId?: string
): UseGenericQueryResponse<UccBusinessReport[]> => {
  return useGenericFeatureQuery(
    useGetApiBusinessUccReport,
    (data) => data?.uccs.map(toUccReport),
    { submissionUuid: submissionUuid, reportId }
  );
};
