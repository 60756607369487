import React, { useMemo } from 'react';
import {
  TextInput,
  Button,
  Select,
  DatePicker,
  Divider,
  Flex,
  Grid,
  PlainDate,
  SelectProps,
  TextInputProps,
  DatePickerWithoutTimeProps,
} from '@forward-financing/fast-forward';
import { Partner } from 'api/FundingClient/codecs';
import {
  ActiveSearch,
  HandleSearchSubmitOptions,
} from './submissionSearch.types';
import { INITIAL_START_DATE, MIN_DATE_ALLOWED } from './constants';

export interface SearchFormProps {
  activeSearch: ActiveSearch;
  setActiveSearch: (newSearch: ActiveSearch) => void;
  partners: Partner[];
  handleSubmit: (
    activeSearch: ActiveSearch,
    options: HandleSearchSubmitOptions
  ) => void;
  maxDateAllowed?: Date;
}

const getPlainDate = (incomingDate: Date): PlainDate => {
  return new PlainDate(
    incomingDate.getFullYear(),
    incomingDate.getMonth() + 1,
    incomingDate.getDate()
  );
};

const getDate = (incomingPlainDate: PlainDate): Date => {
  return new Date(
    incomingPlainDate.year,
    incomingPlainDate.month - 1,
    incomingPlainDate.day
  );
};

export const SearchForm = (props: SearchFormProps): JSX.Element => {
  const maxDateAllowed = props.maxDateAllowed || new Date();

  const partnersOptions: SelectProps['options'] = useMemo(
    () =>
      props.partners.map((p) => {
        return { value: p.value.toString(), text: p.label };
      }),
    [props.partners]
  );

  const onSearchChange: TextInputProps['onChange'] = (e) => {
    props.setActiveSearch({
      ...props.activeSearch,
      query: e.target.value,
    });
  };

  const handleSelectPartner: SelectProps['onValueChange'] = (
    selectedOption
  ) => {
    props.setActiveSearch({
      ...props.activeSearch,
      partnerId: parseInt(selectedOption, 10),
    });
  };

  const resetSearchBox = (): void => {
    props.setActiveSearch({
      ...props.activeSearch,
      query: '',
    });
  };

  const resetForm = (): void => {
    props.setActiveSearch({
      ...props.activeSearch,
      startDate: INITIAL_START_DATE,
      endDate: new Date(),
      partnerId: undefined,
      query: '',
    });
  };

  const handleStartDateChange: DatePickerWithoutTimeProps['onChange'] = (
    date
  ) => {
    if (date) {
      props.setActiveSearch({
        ...props.activeSearch,
        startDate: getDate(date),
      });
    }
  };

  const handleEndDateChange: DatePickerWithoutTimeProps['onChange'] = (
    date
  ) => {
    if (date) {
      props.setActiveSearch({
        ...props.activeSearch,
        endDate: getDate(date),
      });
    }
  };

  return (
    <>
      <Divider orientation="horizontal" />
      <Grid gutter>
        <Grid.Item xs={12} m={8}>
          <Grid gutter>
            <Grid.Item xs={12}>
              <TextInput
                value={props.activeSearch.query}
                name="query"
                onChange={onSearchChange}
                label="Legal Name or DBA"
                afterInputContent={
                  <>
                    {props.activeSearch.query && (
                      <TextInput.IconButton
                        onClick={resetSearchBox}
                        description="clear"
                        icon={'times'}
                      />
                    )}
                  </>
                }
              />
            </Grid.Item>

            <Grid.Item xs={12} m={6}>
              <Select
                label="ISO"
                placeholder="Select ISO"
                onValueChange={handleSelectPartner}
                options={partnersOptions}
                value={
                  props.activeSearch.partnerId
                    ? props.activeSearch.partnerId.toString()
                    : ''
                }
              />
            </Grid.Item>
            <Grid.Item xs={12} m={3}>
              <DatePicker
                selected={getPlainDate(props.activeSearch.startDate)}
                onChange={handleStartDateChange}
                label="From"
                minDate={getPlainDate(MIN_DATE_ALLOWED)}
                maxDate={getPlainDate(props.activeSearch.endDate)}
                showDropdownCalendarPicker
              />
            </Grid.Item>
            <Grid.Item xs={12} m={3}>
              <DatePicker
                selected={getPlainDate(props.activeSearch.endDate)}
                onChange={handleEndDateChange}
                label="To"
                minDate={getPlainDate(props.activeSearch.startDate)}
                maxDate={getPlainDate(maxDateAllowed)}
                showDropdownCalendarPicker
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>

        <Grid.Item xs={12} m={4}>
          <Flex alignItems="center" gap={2} mt={3} pt={1}>
            <Button
              onClick={() =>
                props.handleSubmit(props.activeSearch, {
                  resetPagination: true,
                })
              }
              startIcon={'search'}
            >
              Search
            </Button>
            <Button onClick={resetForm} startIcon={'times'} variant="secondary">
              Clear Filters
            </Button>
          </Flex>
        </Grid.Item>
      </Grid>
      <Divider orientation="horizontal" />
    </>
  );
};
