import React from 'react';
import { Table, Text } from '@forward-financing/fast-forward';

export interface PricingStrategyProgramTermTableProps {
  title: string;
  pricingStrategyProfiles: {
    id: string;
    score: string;
    term: string;
    program: string;
  }[];
}

export const PricingStrategyProgramTermTable = (
  props: PricingStrategyProgramTermTableProps
): JSX.Element => {
  return (
    <>
      <Text bold={true}>{props.title}</Text>
      <Table caption={props.title}>
        <Table.Head>
          <Table.ColumnHeader>Score</Table.ColumnHeader>
          <Table.ColumnHeader>Term (Months)</Table.ColumnHeader>
          <Table.ColumnHeader>Program</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {props.pricingStrategyProfiles.map((r) => (
            <Table.Row key={r.id}>
              <Table.Cell>{r.score}</Table.Cell>
              <Table.Cell>{r.term}</Table.Cell>
              <Table.Cell>{r.program}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
