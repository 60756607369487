import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { FUNDING_URL } from 'constants/globals';
import { RenewalComparisonsResponse } from 'types/api/funding/types';

export const fetchRenewalComparisons = async (
  submissionUuid: string
): Promise<RenewalComparisonsResponse> => {
  const url = new URL(
    `api/v2/renewal_comparisons/${submissionUuid}`,
    FUNDING_URL()
  );

  const response = await makeInternalAPIRequest<RenewalComparisonsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch renewal comparisons'
    );
  }

  return response.json();
};
