import React from 'react';
import { RenewalScore } from '../RenewalScoring.types';
import { ScoreTable } from './ScoreTable';
import { formatDateString } from '@forward-financing/fast-forward';

export interface RenewalScoreTableProps {
  score: RenewalScore;
}

export const RenewalScoreTable = ({
  score,
}: RenewalScoreTableProps): JSX.Element => {
  const data = [
    {
      id: 1,
      label: 'Payment History',
      value: score.paymentHistory,
    },
    {
      id: 2,
      label: 'Stacking Considerations',
      value: score.stackingConsiderations,
    },
    {
      id: 3,
      label: 'Revenue Trends',
      value: score.revenueTrends,
    },
    {
      id: 4,
      label: 'Credit Trends',
      value: score.creditTrends,
    },
  ];

  // filter out the nulls. This effectively causes a null to count as a 0
  const numericScores = data
    .map((row) => row.value)
    .filter((scoreValue): scoreValue is number => Number.isInteger(scoreValue));

  const averageScore =
    numericScores.length > 0
      ? numericScores.reduce((acc, scoreValue) => acc + scoreValue, 0) /
        numericScores.length
      : null;

  return (
    <ScoreTable
      title={`Most Recent Renewal Advance: ${formatDateString(
        score.dealFundedOn
      )}`}
      data={data}
      maxScore={3}
      averageScore={averageScore}
      showTrendIcons
    />
  );
};
