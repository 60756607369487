import {
  Table,
  formatDateTimeString,
  formatDateString,
  Box,
  Subheading,
} from '@forward-financing/fast-forward';
import React from 'react';
import { AttachmentDownloadLink } from './AttachmentDownloadLink';
import { Attachment } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';

export interface ReadOnlyAttachmentsTableProps {
  attachments: Attachment[];
}

export const ReadOnlyAttachmentsTable = ({
  attachments,
}: ReadOnlyAttachmentsTableProps): JSX.Element => {
  const { documentTags } = useAttachmentManagerContext();

  return (
    <Box>
      <Subheading variant="section">System Deleted Attachments</Subheading>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Document Name</Table.ColumnHeader>
          <Table.ColumnHeader>Document Tags</Table.ColumnHeader>
          <Table.ColumnHeader>Internal Notes</Table.ColumnHeader>
          <Table.ColumnHeader>Date Created</Table.ColumnHeader>
          <Table.ColumnHeader>Date Modified</Table.ColumnHeader>
          <Table.ColumnHeader>Expiry Date</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {attachments?.map((att) => (
            <Table.Row key={att.uuid}>
              <Table.Cell columnWidth="large">
                <AttachmentDownloadLink attachment={att} />
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {att.documentTags
                  .map((tag) => documentTags[tag])
                  .sort()
                  .join(', ')}
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {att.description}
              </Table.Cell>
              <Table.Cell>
                {att.createdAt && formatDateTimeString(att.createdAt)}
              </Table.Cell>
              <Table.Cell>
                {att.updatedAt && formatDateTimeString(att.updatedAt)}
              </Table.Cell>
              <Table.Cell>
                {att.documentTags.includes('drivers_license') ? (
                  <>
                    {att.expiresOn
                      ? formatDateString(att.expiresOn)
                      : 'Not set'}
                  </>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
