import React from 'react';
import { InternalNavbarLink } from './InternalNavbarLink';
import { featureFlags } from 'helpers/featureFlags';

export const NavbarSubmissionOnboardingQueueLink = (): JSX.Element =>
  featureFlags.submission_onboarding_queue_navigation ? (
    <InternalNavbarLink
      icon="rectangle-list"
      to="/dashboard/submission-onboarding/queue"
    >
      Submission Onboarding Queue
    </InternalNavbarLink>
  ) : (
    <></>
  );
