import React from 'react';
import { formatDateTimeString, List } from '@forward-financing/fast-forward';
import { ExceptionRequest } from '../exceptionsRequest.types';

export interface NoteListProps {
  exceptionRequest: ExceptionRequest;
}

export const NoteList = (props: NoteListProps): JSX.Element => {
  const { exceptionRequest } = props;

  return (
    <List>
      {exceptionRequest.offerExceptionNotes && (
        <List.Item>
          Request Notes: {exceptionRequest.offerExceptionNotes}
        </List.Item>
      )}
      {exceptionRequest.reviewCompletedAt && (
        <List.Item>
          Completed at:{' '}
          {formatDateTimeString(exceptionRequest.reviewCompletedAt)}
        </List.Item>
      )}
      {exceptionRequest.declineReason && (
        <List.Item>Decline Reason: {exceptionRequest.declineReason}</List.Item>
      )}
      {exceptionRequest.reviewerUser && (
        <List.Item>Reviewer: {exceptionRequest.reviewerUser}</List.Item>
      )}
      {exceptionRequest.declineNotes && (
        <List.Item>Decline Notes: {exceptionRequest.declineNotes}</List.Item>
      )}
    </List>
  );
};
