import { HashMap } from 'api/codecs';

export const paynetCollateralDescription: HashMap<string> = {
  AGRI: 'Agricultural',
  AIR: 'Aircraft',
  AUTO: 'Cars',
  BOAT: 'Marine Craft',
  BUS: 'Buses & Motor Coaches',
  CNST: 'Construction & Mining',
  COMP: 'Computer',
  COPY: 'Copier & Fax',
  ENGY: 'Energy',
  FORK: 'Forklift',
  LOG: 'Logging & Forestry',
  MDTR: 'Medium/Light Duty Trucks',
  MEDC: 'Medical',
  MFG: 'Manufacturing',
  OFFC: 'Office Equipment',
  PRNT: 'Printing & Photographic',
  RAIL: 'Railroad',
  REAL: 'Real Estate',
  RETL: 'Retail',
  TELE: 'Telecommunications',
  TRCK: 'Truck',
  UNKN: 'Unknown',
  VEND: 'Vending & Restaurant',
  WAST: 'Waste & Refuse Handling',
  CCRC: 'Credit Card Receipts',
  RECV: 'Receivables',
  FINL: 'Financial Assets',
  'FF&E': 'Furniture, Fixtures & Equipment',
  'F&F': 'Furniture & Fixtures',
  INVT: 'Inventory',
  BLNK: 'Blanket Lien All Assets',
  CREO: 'Commercial/Industrial Real Estate - Owner Occupied',
  CREI: 'Commercial/Industrial Real Estate - Investor Owned',
  HOME: 'Residential Real Estate (O-O or Investor)',
  LAND: 'Land, Without a Structure',
  MATL: 'Materials Handling & Racking',
  OTHR: 'Collateral not Elsewhere Classified',
};

export const paynetContracts: HashMap<string> = {
  CondSale: 'Conditional Sale',
  LeasePur: 'Lease Purchase',
  LevrLeas: 'Leveraged Lease',
  Loan: 'Loan',
  Rentalse: 'Rental Lease',
  Revolver: 'Revolver',
  TruLease: 'True Lease',
  MCA: 'Merchant Cash Advance',
  CredCard: 'Credit Card',
  Trade: 'Trade Credit',
  RevAcct: 'Revolving Account',
};
