import React from 'react';
import { Table, formatDateString } from '@forward-financing/fast-forward';
import { Ineligibility } from '../HighRiskReview.types';

export type IneligibilitiesTableProps = {
  ineligibilities: Ineligibility[];
};

export const IneligibilitiesTable = ({
  ineligibilities,
}: IneligibilitiesTableProps): JSX.Element => {
  return (
    <Table caption="ineligibile details">
      <Table.Head>
        <Table.ColumnHeader>Type</Table.ColumnHeader>
        <Table.ColumnHeader>Reason</Table.ColumnHeader>
        <Table.ColumnHeader>Created At</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {ineligibilities.map((ineligibility) => (
          <Table.Row key={ineligibility.createdAt}>
            <Table.Cell>{ineligibility.ineligibilityType}</Table.Cell>
            <Table.Cell>{ineligibility.reason}</Table.Cell>
            <Table.Cell>{formatDateString(ineligibility.createdAt)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
