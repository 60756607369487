import {
  SubmissionResponse,
  SubmissionStageHistoryResponse,
  SubmissionStageTransitionsResponse,
  DeclineDriversResponse,
  SubmissionLogsResponse,
} from 'types/api/underwriting/types';
import {
  UseGetApiSubmissionResponse,
  useApiGetSubmissionStageHistory,
  useApiGetSubmissionStageTransitions,
  useDeclineApiSubmission,
  useGetApiSubmission,
  useGetApiSubmissionLogs,
  useUpdateApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { useGetApiDeclineDrivers as useGetApiDeclineDriversFromUnderwriting } from 'apiHooks/underwriting/declineDriversFetchHooks';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { AuthenticatedUserResponse } from 'types/api/auth/types';
import { useGetApiUser } from 'apiHooks/auth/userFetchHooks';
import {
  DeclineDataSavedLog,
  Submission,
  SubmissionStageHistory,
  SubmissionStageTransition,
  User,
} from './navbar.types';

const toSubmission = (
  submission?: SubmissionResponse
): Submission | undefined => {
  if (!submission) {
    return undefined;
  }

  return {
    uuid: submission.uuid,
    customerUuid: submission.customer_uuid,
    stage: submission.stage_name,
    subStage: submission.sub_stage ?? undefined,
    attachmentsReadyForProcessing: submission.attachments_ready_for_processing,
    isoCompetingSubMessage: submission.iso_competing_sub_message || undefined,
    type: submission.type,
    fundedSubmissionUuid:
      submission.previous_funded_opportunity_uuid || undefined,
    internalRenewal: submission.internal_renewal,
    decisionAnalystId: submission.decision_analyst_id || undefined,
    applicationUuid: submission.application_uuid,
    accountUuid: submission.account_uuid,
  };
};

const toDeclineDriversArray = (
  declineDrivers?: DeclineDriversResponse
): string[] => {
  if (!declineDrivers) {
    return [];
  }

  return declineDrivers?.data.map((declineDriver) => declineDriver.value);
};

interface UseSubmissionResult
  extends Omit<UseGetApiSubmissionResponse, 'data'> {
  data?: Submission;
}

export const useSubmission = (submissionUuid: string): UseSubmissionResult =>
  useGenericFeatureQuery(useGetApiSubmission, toSubmission, submissionUuid);

export const useUnderwritingDeclineDrivers = (
  submissionUuid?: string
): UseGenericQueryResponse<string[]> =>
  useGenericFeatureQuery(
    useGetApiDeclineDriversFromUnderwriting,
    toDeclineDriversArray,
    submissionUuid
  );

type DeclineBody = {
  isPreviousDecline: boolean;
  declineDrivers: string;
  declineDriverNotes: string;
  declinedSource: string;
  decisionAnalystId: number;
};

type UseDeclineSubmissionResult = [
  (input: DeclineBody) => Promise<MutationResponse>,
  { submission?: Submission; loading: boolean; error?: Error }
];

export const useDeclineSubmission = (
  submissionUuid: string
): UseDeclineSubmissionResult => {
  const [declineSubmission, { data, loading, error }] =
    useDeclineApiSubmission();

  const updateFunction = async (
    args: DeclineBody
  ): Promise<MutationResponse> => {
    return await declineSubmission({
      submissionUuid,
      declineBody: {
        is_previous_decline: args.isPreviousDecline,
        decline_drivers: args.declineDrivers,
        decline_driver_notes: args.declineDriverNotes,
        declined_source: args.declinedSource,
        decision_analyst_id: args.decisionAnalystId,
      },
    });
  };

  return [
    updateFunction,
    {
      submission: data ? toSubmission(data) : undefined,
      error,
      loading,
    },
  ];
};

export type UpdateSubmissionBody = {
  decisionAnalystId?: number;
  stage?: string;
  isoCompetingSubMessage?: string;
  subStage?: string | null;
  waitingOnIsoNote?: string;
  stageChangeNotes?: string;
  changedByMoveDealModal?: boolean;
  declineDrivers?: string;
  declineDriverNotes?: string;
};

type UseUpdateSubmissionStageResult = [
  (input: UpdateSubmissionBody) => Promise<MutationResponse>,
  { data?: Submission; error?: Error; loading: boolean }
];

export const useUpdateSubmission = (
  submissionUuid: string
): UseUpdateSubmissionStageResult => {
  const [updateSubmission, { data, error, loading }] = useUpdateApiSubmission();

  const updateFunction = (
    args: UpdateSubmissionBody
  ): Promise<MutationResponse> => {
    return updateSubmission({
      submissionUuid,
      updateBody: {
        decision_analyst_id: args.decisionAnalystId,
        stage_name: args.stage,
        sub_stage: args.subStage,
        iso_competing_sub_message: args.isoCompetingSubMessage,
        waiting_on_iso_note__c: args.waitingOnIsoNote,
        stage_change_notes: args.stageChangeNotes,
        changed_by_move_deal_modal: args.changedByMoveDealModal,
        decline_drivers__c: args.declineDrivers,
        decline_driver_notes__c: args.declineDriverNotes,
      },
    });
  };

  return [
    updateFunction,
    {
      data: toSubmission(data),
      error,
      loading,
    },
  ];
};

const toSubmissionStageHistory = (
  data: SubmissionStageHistoryResponse
): SubmissionStageHistory => {
  return {
    date: data.date,
    updatedBy: data.updated_by,
    stage: data.stage,
    subStage: data.sub_stage,
    assignedUnderwriter: data.assigned_underwriter,
    notes: data.notes,
  };
};

export const useGetSubmissionStageHistory = (
  submissionUuid: string
): UseGenericQueryResponse<SubmissionStageHistory[]> =>
  useGenericFeatureQuery(
    useApiGetSubmissionStageHistory,
    (data) => data?.map(toSubmissionStageHistory),
    submissionUuid
  );

const toSubmissionStageTransition = (
  data: SubmissionStageTransitionsResponse
): SubmissionStageTransition => {
  return {
    stage: data.stage,
    subStage: data.sub_stage || undefined,
  };
};

const toSubmissionStageTransitions = (
  data?: SubmissionStageTransitionsResponse[]
): SubmissionStageTransition[] => data?.map(toSubmissionStageTransition) || [];

export const useGetStageTransitions = (
  submissionUuid?: string
): UseGenericQueryResponse<SubmissionStageTransition[]> =>
  useGenericFeatureQuery(
    useApiGetSubmissionStageTransitions,
    toSubmissionStageTransitions,
    submissionUuid
  );

const toDeclineDataSavedSubmissionLogs = (
  response?: SubmissionLogsResponse
): DeclineDataSavedLog[] | undefined => {
  if (!response) {
    return undefined;
  }

  const data = response.data;

  return data
    .filter((log) => log.event === 'underwriting_decline_driver_saved')
    .map((log) => ({
      savedBy: log.user_id,
      createdAt: log.created_at,
      updatedAt: log.updated_at,
    }));
};

export const useDeclineDataSavedSubmissionLogs = (
  submissionUuid?: string
): UseGenericQueryResponse<DeclineDataSavedLog[]> =>
  useGenericFeatureQuery(
    useGetApiSubmissionLogs,
    toDeclineDataSavedSubmissionLogs,
    submissionUuid
  );

const toUser = (
  userResponse: AuthenticatedUserResponse | undefined
): User | undefined => {
  if (!userResponse) {
    return undefined;
  }

  const user = userResponse.user;

  return {
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
  };
};

export const useGetUser = (
  id?: string | number
): UseGenericQueryResponse<User> =>
  useGenericFeatureQuery(useGetApiUser, toUser, id);
