import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  Transition,
  TransitionGroup,
  TransitionStatus,
} from 'react-transition-group';

import {
  Address,
  Contact,
  UsState,
} from '../../../api/UnderwritingClient/codecs';
import {
  generateEmptyAddress,
  generateEmptyContact,
  SubmissionObject,
} from '../../../api/UnderwritingClient/utils/index';
import {
  DYNAMIC_FORM_ITEM_EXIT_TIMEOUT,
  MAX_OWNERS,
} from '../../../constants/globals';
import { HTMLFormChangeOrMouseEvent } from '../../../types/form';
import { HashMap } from '../../../api/codecs';
import { ValidationResult } from '../../../helpers/validations/codecs';

// validation utils
import {
  validate,
  validatePhone,
  validateZipCode,
  validateSsn,
  validateOwnership,
  validField,
  validateEmail,
  validateDate,
  specialCharValidationMessage,
  addressSpecialCharValidationMessage,
} from '../../../helpers/validations/FieldValidator';
import {
  isRequired,
  textHasNoSpecialChar,
  addressHasNoSpecialChar,
} from '../../../helpers/validations/ValidationHelpers';

// Components
import { BorderedContainer } from '../../shared/BorderedContainer';
import { OwnerInformation } from './OwnerInformation';
import { Button, IconButton, Loading } from '@forward-financing/fast-forward';
import {
  defaultStyle,
  totalOwnership,
  transitionStyles,
} from '../../../helpers/utils';
import { LogData } from '../../../api/AnalyticsGatewayClient/codecs';

// The forPrequal prop is used here to indicate whether the component is being rendered for the Prequal Portal
// or UW Editing. Certain child components will only be rendered for a specific context.
export interface OwnerInformationFormProps {
  owners: Contact[];
  usStates: UsState[];
  accountAddress: Address;
  storeOwners: (owners: Contact[]) => void;
  onBack: () => void;
  onSubmit: (owners: Contact[]) => void;
  submitActionLabel: string;
  declinedIcon: () => JSX.Element;
  forPrequal: boolean;
  sendUnmaskedFieldLogs?: (data: LogData) => Promise<void>;
}

export const OwnerInformationForm = (
  props: OwnerInformationFormProps
): JSX.Element => {
  const { storeOwners } = props;

  const newOwnerReference = useRef<HTMLDivElement>(null);

  const [owners, setOwners] = useState(props.owners);
  const [isLoading, setIsLoading] = useState(false);
  const [ownerUpdated, setOwnerUpdated] = useState(false);
  const [ownersUsingFirstOwnerAddress, setOwnersUsingFirstOwnerAddress] =
    useState<Contact[]>([]);

  useEffect(() => {
    if (!!newOwnerReference?.current && ownerUpdated) {
      newOwnerReference.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
      setOwnerUpdated(false);
    }
  }, [ownerUpdated]);

  useEffect(() => {
    storeOwners(owners);
  }, [owners, storeOwners]);

  const activeOwners = owners.filter((element) => {
    return props.forPrequal ? !element._destroy : !element.deleted_at;
  });

  const firstOwner = (): Contact => {
    return owners[0];
  };

  // Interactions
  // args: e: React.MouseEvent<HTMLButtonElement>
  const handleNextAction = (): void => {
    if (areOwnersValid() && totalOwnership(activeOwners) <= 100) {
      setIsLoading(true);
      props.onSubmit(owners);
    }
  };

  // args: e: React.MouseEvent<HTMLButtonElement>
  const handleBackAction = (): void => {
    props.storeOwners(owners);
    props.onBack();
  };

  const handleOwnersChange = (
    object: SubmissionObject,
    ownerToChange: Contact
  ): ((e: HTMLFormChangeOrMouseEvent) => void) => {
    return (e) => {
      e.persist();

      const target = (
        e.currentTarget ? e.currentTarget : e.target
      ) as HTMLInputElement;
      const value =
        target.type && target.type === 'checkbox'
          ? target.checked
          : target.value;

      switch (object) {
        case SubmissionObject.Contacts: {
          const updatedOwners = owners.map((owner) => {
            if (owner.key === ownerToChange.key) {
              if (target.name === 'ssnNotPresent' && value) {
                owner.ssn = null;
              } else if (target.name === 'bornOnNotPresent' && value) {
                owner.born_on = null;
              }
              return {
                ...owner,
                [target.name]: value,
              };
            }
            return owner;
          });
          setOwners(updatedOwners);
          break;
        }
        case SubmissionObject.ContactAddress: {
          const updatedAddressOwners = owners.map((owner) => {
            if (shouldUpdateOwnerAddress(ownerToChange, owner)) {
              owner.address = {
                ...ownerToChange.address,
                [target.name]: value,
              } as Address;
            }
            return owner;
          });
          setOwners(updatedAddressOwners);
          break;
        }
      }
    };
  };

  const shouldUpdateOwnerAddress = (
    ownerToChange: Contact,
    owner: Contact
  ): boolean => {
    const isSameOwner = ownerToChange.key === owner.key;
    const linkedToFirstOwner =
      ownerToChange.key === firstOwner().key && isUsingFirstOwnerAddress(owner);
    return isSameOwner || linkedToFirstOwner;
  };

  // args: e: React.MouseEvent<HTMLButtonElement>
  const addOwner = (): void => {
    if (activeOwners.length < MAX_OWNERS) {
      setOwners([...owners, generateEmptyContact()]);
      setOwnerUpdated(true);
    }
  };

  // args: e: React.MouseEvent<HTMLButtonElement>
  const removeOwner = (ownerToRemove: Contact) => (): void => {
    const newOwners = owners.map((owner) => {
      return owner.key === ownerToRemove.key
        ? { ...owner, _destroy: true, deleted_at: new Date() }
        : owner;
    });
    setOwners(newOwners);
  };

  const setOwnerAddress = (ownerToChange: Contact, address: Address): void => {
    setOwners(
      owners.map((owner) => {
        if (shouldUpdateOwnerAddress(ownerToChange, owner)) {
          return {
            ...owner,
            address,
          };
        }

        return owner;
      })
    );
  };

  const handleAssignAccountAddressToContact =
    (contact: Contact): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
    (e) => {
      if (e.target.checked && isUsingFirstOwnerAddress(contact)) {
        removeFromUsingFirstOwnerAddress(contact);
      }
      const address = e.target.checked
        ? props.accountAddress
        : generateEmptyAddress();
      setOwnerAddress(contact, address);
    };

  const handleAssignFirstOwnerAddress =
    (contact: Contact): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
    (e) => {
      if (e.target.checked) {
        addToUsingFirstOwnerAddress(contact);
        setOwnerAddress(contact, firstOwner().address);
      } else {
        removeFromUsingFirstOwnerAddress(contact);
        setOwnerAddress(contact, generateEmptyAddress());
      }
    };

  const addToUsingFirstOwnerAddress = (contact: Contact): void => {
    if (!isUsingFirstOwnerAddress(contact)) {
      setOwnersUsingFirstOwnerAddress([
        ...ownersUsingFirstOwnerAddress,
        contact,
      ]);
    }
  };

  const removeFromUsingFirstOwnerAddress = (contact: Contact): void => {
    const filteredOwners = ownersUsingFirstOwnerAddress.filter(
      (owner) => owner.key !== contact.key
    );
    setOwnersUsingFirstOwnerAddress(filteredOwners);
  };

  const isUsingFirstOwnerAddress = (contact: Contact): boolean => {
    return !!ownersUsingFirstOwnerAddress.find(
      (owner) => owner.key === contact.key
    );
  };

  // Validations
  const areOwnersValid = (): boolean =>
    activeOwners
      .map((owner) =>
        Object.values(validationErrors(owner)).every(
          (result) => result.hasValue && result.isValid
        )
      )
      .every(Boolean);

  const validationErrors = (owner: Contact): HashMap<ValidationResult> => ({
    first_name: validate(
      [textHasNoSpecialChar, isRequired],
      owner.first_name,
      `First Name ${specialCharValidationMessage}`
    ),
    last_name: validate(
      [textHasNoSpecialChar, isRequired],
      owner.last_name,
      `Last Name ${specialCharValidationMessage}`
    ),
    born_on: owner.bornOnNotPresent
      ? validField
      : validateDate(owner.born_on || ''),
    home_phone: owner.home_phone
      ? validatePhone(owner.home_phone, false)
      : validField,
    cell_phone: owner.cell_phone
      ? validatePhone(owner.cell_phone, false)
      : validField,
    ssn: owner.ssnNotPresent ? validField : validateSsn(owner.ssn),
    street1: validate(
      [addressHasNoSpecialChar, isRequired],
      owner.address?.street1,
      `Street Address ${addressSpecialCharValidationMessage}`
    ),
    city: validate(
      [addressHasNoSpecialChar, isRequired],
      owner.address?.city,
      `City ${addressSpecialCharValidationMessage}`
    ),
    state: validate([isRequired], owner.address?.state),
    zip: validateZipCode(owner.address?.zip),
    ownership: validateOwnership(
      (owner.ownership_percentage || 0).toString(),
      totalOwnership(activeOwners)
    ),
    email: owner.email ? validateEmail(owner.email) : validField,
    title: owner.title
      ? validate(
          [textHasNoSpecialChar],
          owner.title,
          `Title ${specialCharValidationMessage}`
        )
      : validField,
  });

  return (
    <div className="owner-information-form">
      <BorderedContainer
        label="Owner Information"
        labelIcon={props.declinedIcon()}
      >
        <TransitionGroup appear component={null}>
          {activeOwners.map((owner, index) => {
            const isFirstOwner = index === 0;
            return (
              <Transition
                key={owner.key}
                timeout={{ exit: DYNAMIC_FORM_ITEM_EXIT_TIMEOUT }}
                unmountOnExit
              >
                {(state) => (
                  <div
                    style={{
                      ...defaultStyle,
                      ...(
                        transitionStyles as Record<
                          TransitionStatus,
                          CSSProperties
                        >
                      )[state],
                    }}
                  >
                    <OwnerInformation
                      validationErrors={validationErrors(owner)}
                      focusReference={
                        index === activeOwners.length - 1
                          ? newOwnerReference
                          : undefined
                      }
                      title={`Owner ${index + 1}`}
                      showCloseButton={!isFirstOwner}
                      needsConfirm={!isFirstOwner}
                      onCloseAction={removeOwner(owner)}
                      usStates={props.usStates}
                      owner={owner}
                      handleOwnerChange={handleOwnersChange(
                        SubmissionObject.Contacts,
                        owner
                      )}
                      handleOwnerAddressChange={handleOwnersChange(
                        SubmissionObject.ContactAddress,
                        owner
                      )}
                      handleUseAccountAddress={handleAssignAccountAddressToContact(
                        owner
                      )}
                      showUseFirstOwnerAddressCheckbox={!isFirstOwner}
                      handleUseFirstOwnerAddress={handleAssignFirstOwnerAddress(
                        owner
                      )}
                      sendUnmaskedFieldLogs={props.sendUnmaskedFieldLogs}
                    />
                  </div>
                )}
              </Transition>
            );
          })}
        </TransitionGroup>

        {activeOwners.length < MAX_OWNERS ? (
          <div className="add-owner">
            <hr />
            <IconButton
              icon="plus"
              label="Add another owner"
              onClick={addOwner}
            />
          </div>
        ) : (
          <></>
        )}
      </BorderedContainer>
      <div className="owner-navigation-actions columns">
        <div className="column">
          {props.submitActionLabel === 'Next' && (
            <Button onClick={handleBackAction} startIcon="arrow-left">
              Back
            </Button>
          )}
        </div>
        <div className="column has-text-right">
          {isLoading ? (
            <Loading size="small" />
          ) : (
            <Button
              onClick={handleNextAction}
              disabled={!areOwnersValid() || totalOwnership(activeOwners) > 100}
              endIcon="arrow-right"
            >
              {props.submitActionLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
