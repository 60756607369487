import React from 'react';
import startCase from 'lodash/startCase';
import defaultTo from 'lodash/defaultTo';
import { Currency, Flex, Table } from '@forward-financing/fast-forward';
import { NewDealScoreComposite } from '../DealScoring.types';

export type NewDealCompositeScoreTableProps = {
  scoreData: NewDealScoreComposite;
};

export const NewDealCompositeScoreTable = ({
  scoreData,
}: NewDealCompositeScoreTableProps): JSX.Element => {
  const {
    industryRisk,
    position,
    fico,
    timeInBusiness,
    riskDecile,
    riskScore,
    trueRevenue,
  } = scoreData;

  return (
    <Table caption="New Deal Composite Score">
      <Table.Head>
        <Table.ColumnHeader> </Table.ColumnHeader>
        <Table.ColumnHeader>Score</Table.ColumnHeader>
        <Table.ColumnHeader>Data Point</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Positions
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {defaultTo(position?.score?.toFixed(1), 'N/A')}
          </Table.Cell>
          <Table.Cell>{defaultTo(position?.value, 'N/A')}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Industry Risk
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {defaultTo(industryRisk?.score?.toFixed(1), 'N/A')}
          </Table.Cell>
          <Table.Cell>
            {/* startCase: low_risk becomes Low Risk */}
            {defaultTo(
              industryRisk?.value && startCase(industryRisk.value),
              'N/A'
            )}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              FICO Score
            </Flex>
          </Table.RowHeader>
          <Table.Cell>{defaultTo(fico?.score?.toFixed(1), 'N/A')}</Table.Cell>
          <Table.Cell>{defaultTo(fico?.value, 'N/A')}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Time in Business
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {defaultTo(timeInBusiness?.score?.toFixed(1), 'N/A')}
          </Table.Cell>
          <Table.Cell>{defaultTo(timeInBusiness?.value, 'N/A')}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Revenue Tier
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {defaultTo(trueRevenue?.score?.toFixed(1), 'N/A')}
          </Table.Cell>
          <Table.Cell>
            {typeof trueRevenue?.value === 'number' ? (
              <Currency amount={trueRevenue?.value} />
            ) : (
              'N/A'
            )}
          </Table.Cell>
        </Table.Row>

        {/* 
            Risk Decile will become deprecated as we move away from v5 scoring.
            It is being replaced with Risk Score which is included in v6/v7.
            However, we still want to display Risk Decile for older deals.
            We should only ever have either one of these values per given deal.
            @mlukanus - Oct 17, 2024
        */}
        {riskDecile && (
          <Table.Row>
            <Table.RowHeader>
              <Flex alignItems="center" gap={1}>
                Risk Decile
              </Flex>
            </Table.RowHeader>
            <Table.Cell>
              {defaultTo(riskDecile?.score?.toFixed(1), 'N/A')}
            </Table.Cell>
            <Table.Cell>{defaultTo(riskDecile?.value, 'N/A')}</Table.Cell>
          </Table.Row>
        )}

        {riskScore && (
          <Table.Row>
            <Table.RowHeader>
              <Flex alignItems="center" gap={1}>
                Risk Score
              </Flex>
            </Table.RowHeader>
            <Table.Cell>
              {defaultTo(riskScore?.score?.toFixed(1), 'N/A')}
            </Table.Cell>
            <Table.Cell>{defaultTo(riskScore?.value, 'N/A')}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
