import { PlainDateTime } from '@forward-financing/fast-forward';

/**
 * This function takes in a DateTime string in ISO 8601 format
 * and returns a PlainDateTime object - which is our custom implementation
 * of the Temporal proposal. It is a stopgap solution that will
 * eventually be able to be replaced with a call to Temporal.PlainDateTime.from(dateTimeString)
 *
 * @param dateString a dateTime string in ISO 8601 format: "2021-12-15T10:29:08.922-05:00"
 * @returns
 */
export const iso8601DateTimeStringToPlainDateTime = (
  dateString: string
): PlainDateTime => {
  // format: "2021-12-15T10:29:08.922-05:00"
  const [dateStringPart, timeString] = dateString.split('T');

  const [year, month, day] = dateStringPart
    .split('-')
    .map((numStr) => parseInt(numStr, 10));
  // using parseInt on the second will end up doing `parseInt('08.922-05:00', 10)`,
  // which will just extract the 8
  const [hour, minute, second] = timeString
    .split(':')
    .map((numStr) => parseInt(numStr, 10));

  return new PlainDateTime(year, month, day, hour, minute, second);
};
