import { AuthClient } from './AuthClient';
import { AuthenticationHeaders } from './AuthClient/codecs';
import { FetchResponse } from './codecs';
import { HTTPClient } from './HTTPClient';

export function makeInternalAPIRequest<TResponseBody>(
  url: URL,
  method: 'GET' | 'DELETE',
  customHeaders?: Record<string, string>
): Promise<FetchResponse<TResponseBody>>;

export function makeInternalAPIRequest<
  TResponseBody,
  TRequestBody extends Record<string, unknown> = Record<string, unknown>
>(
  url: URL,
  method: 'POST' | 'PATCH' | 'PUT',
  body?: TRequestBody,
  customHeaders?: Record<string, string>
): Promise<FetchResponse<TResponseBody>>;

// customHeaders param was added to allow us to send specific headers
// to unique requests that might not use the defaults authenticatedHeaders.
// if your custom headers include defaults headers plus another header,
// you should be in charge of sending both headers in this param.
export function makeInternalAPIRequest<
  TResponseBody,
  TRequestBody extends Record<string, unknown>
>(
  url: URL,
  method: 'GET' | 'DELETE' | 'POST' | 'PATCH' | 'PUT',
  body?: TRequestBody,
  customHeaders?: Record<string, string>
): Promise<FetchResponse<TResponseBody>> {
  const urlString = url.toString();

  const callback = async <TResponse>(
    authenticatedHeaders: AuthenticationHeaders
  ): Promise<FetchResponse<TResponse>> => {
    const headers = {
      headers: customHeaders || authenticatedHeaders,
    };

    if (method === 'DELETE') {
      return HTTPClient.delete<TResponse>(urlString, headers);
    }

    if (method === 'POST') {
      return HTTPClient.post<typeof body, TResponse>(urlString, body, headers);
    }

    if (method === 'PATCH') {
      return HTTPClient.patch<typeof body, TResponse>(urlString, body, headers);
    }

    if (method === 'PUT') {
      return HTTPClient.put<typeof body, TResponse>(urlString, body, headers);
    }

    // method === 'GET'
    return HTTPClient.get<TResponse>(urlString, headers);
  };

  return AuthClient.authenticateApiRequest<TResponseBody>(callback);
}
