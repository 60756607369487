import React from 'react';
import { Box, Calendar, Icon, Text } from '@forward-financing/fast-forward';
import { IndefinitePauseDay } from '../paymentSchedule.types';
export interface IndefinitePauseCalendarDayProps {
  event: IndefinitePauseDay;
}

export const IndefinitePauseCalendarDay = ({
  event,
}: IndefinitePauseCalendarDayProps): JSX.Element => {
  return (
    <Calendar.Day>
      <Box textAlign="center" p={1}>
        <Box>{event.notes && <Icon size="lg" name="comment" />}</Box>
        <Text color="danger" bold>
          STOP
        </Text>
      </Box>
    </Calendar.Day>
  );
};
