export const isoCompetingSubMessageList = [
  'Dupe Decline - Forward Score',
  'Dupe Decline - Contracts In',
  'In Competition Email Sent',
  'Competing Sub - Approval Sent',
  'Competing Sub - Sent to UW',
  'Competing Sub - Received',
  'Competing Sub - Pending',
  'Competing Sub - Complete',
];
