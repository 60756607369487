import {
  Banner,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  PageContainer,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePreventNavigation } from 'hooks/usePreventNavigation.hook';
import { useUserContext } from 'contexts/UserContext';
import {
  useGetCustomer,
  useGetOwners,
  useGetSubmission,
  useGetWebPresences,
} from './SubmissionEditingHooks';
import { CustomerSectionForm } from './CustomerSectionForm';
import { AssignmentSectionForm } from './AssignmentSectionForm';
import { BankAccountSectionForm } from './BankAccountSectionForm';
import { SubmissionSectionForm } from './SubmissionSectionForm';
import { OwnerSectionForm } from './OwnerSectionForm';
import { FormUpdateState } from './SubmissionEditing.types';

type Params = {
  submissionUuid: string;
};

export const SubmissionEditingContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;
  const { role, sub_role: subRole } = useUserContext();

  const [customerUpdateStatus, setCustomerUpdateStatus] =
    useState<FormUpdateState>();
  const [ownerUpdateStatus, setOwnerUpdateStatus] = useState<FormUpdateState>();
  const [bankUpdateStatus, setBankUpdateStatus] = useState<FormUpdateState>();
  const [submissionUpdateStatus, setSubmissionUpdateStatus] =
    useState<FormUpdateState>();
  const [assignmentUpdateStatus, setAssignmentUpdateStatus] =
    useState<FormUpdateState>();

  const { data: submission, error: submissionError } =
    useGetSubmission(submissionUuid);
  const { data: customer, error: customerError } = useGetCustomer(
    submission?.customerUuid
  );

  const {
    data: owners,
    error: ownersError,
    refetch: refetchOwners,
  } = useGetOwners(submissionUuid);

  const { data: webPresence, error: webPresenceError } =
    useGetWebPresences(submissionUuid);

  const showUnsavedChangesBanner = [
    customerUpdateStatus,
    ownerUpdateStatus,
    bankUpdateStatus,
    submissionUpdateStatus,
    assignmentUpdateStatus,
  ].some((status) => status === 'unsaved');

  usePreventNavigation(showUnsavedChangesBanner);

  const canCurrentUserAccessAssigmentForm = (): boolean => {
    // all users with processing role should see this section, except vca analyst. So that includes prequal, processors, team_leads
    // admins and super_admins can see this section.
    if (role === 'processing' && subRole && subRole !== 'vca') {
      return true;
    } else if (['admin', 'super_admin'].includes(role)) {
      return true;
    }
    return false;
  };

  return (
    <PageContainer>
      {showUnsavedChangesBanner && (
        <Banner dismissable={false} variant="warning">
          There are unsaved changes in one of the form sections, don&apos;t
          forget to click save before leaving the form
        </Banner>
      )}
      <Flex gap={2} mt={4}>
        <Icon name="arrow-alt-circle-left" />
        <Link
          href={
            new URL(`/submissions/${submissionUuid}`, window.location.origin)
          }
        >
          Back to Submission
        </Link>
      </Flex>

      <Flex
        borderColor="blue-900"
        rounded
        flexDirection={'column'}
        textAlign={'center'}
        p={10}
      >
        <Heading>Update Submission & Customer Information</Heading>
        <Grid gutter>
          <Grid.Item>
            {customerError && (
              <Banner dismissable>{customerError?.message}</Banner>
            )}
            <Box borderColor="blue-900" rounded p={11} ml={2} mr={2}>
              {customer && webPresence && submission && (
                <CustomerSectionForm
                  customer={customer}
                  submissionUuid={submissionUuid}
                  formStatus={customerUpdateStatus}
                  setFormStatus={setCustomerUpdateStatus}
                />
              )}
            </Box>
          </Grid.Item>

          <Grid.Item>
            <Box borderColor="blue-900" rounded p={11} ml={2} mr={2}>
              {ownersError && (
                <Banner dismissable>{ownersError?.message}</Banner>
              )}
              {owners && submission && customer?.customerAddress && (
                <OwnerSectionForm
                  owners={owners}
                  refetchOwners={refetchOwners}
                  customerAddress={customer.customerAddress}
                  submissionUuid={submission?.uuid}
                  formStatus={ownerUpdateStatus}
                  setFormStatus={setOwnerUpdateStatus}
                />
              )}
            </Box>
          </Grid.Item>

          <Grid.Item>
            <Box borderColor="blue-900" rounded p={11} ml={2} mr={2}>
              {customer && (
                <BankAccountSectionForm
                  submissionStage={submission?.stageName}
                  customerUuid={customer.uuid}
                  bankAccount={customer.bankAccount}
                  formStatus={bankUpdateStatus}
                  setFormStatus={setBankUpdateStatus}
                />
              )}
            </Box>
          </Grid.Item>

          <Grid.Item>
            <Box borderColor="blue-900" rounded p={11} ml={2} mr={2}>
              {submissionError && (
                <Banner dismissable>{submissionError?.message}</Banner>
              )}
              {webPresenceError && (
                <Banner dismissable>{webPresenceError?.message}</Banner>
              )}
              {submission && webPresence && (
                <SubmissionSectionForm
                  submission={submission}
                  webPresence={webPresence}
                  formStatus={submissionUpdateStatus}
                  setFormStatus={setSubmissionUpdateStatus}
                />
              )}
            </Box>
          </Grid.Item>

          <Grid.Item>
            {canCurrentUserAccessAssigmentForm() && submission && (
              <Box borderColor="blue-900" rounded p={11} ml={2} mr={2}>
                <AssignmentSectionForm
                  submissionAnalysts={submission}
                  submissionUuid={submissionUuid}
                  formStatus={assignmentUpdateStatus}
                  setFormStatus={setAssignmentUpdateStatus}
                />
              </Box>
            )}
          </Grid.Item>
        </Grid>
        <Flex justifyContent={'center'} mt={4}>
          <Button
            onClick={() => {
              window.location.assign(
                `${window.location.origin}/submissions/${submissionUuid}`
              );
            }}
          >
            Close
          </Button>
        </Flex>
      </Flex>
    </PageContainer>
  );
};
