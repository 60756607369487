import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { NameVariation } from './BusinessLexisNexisPage.types';

interface NameVariationsTableProps {
  names?: NameVariation[];
}

export const NameVariationsTable = ({
  names,
}: NameVariationsTableProps): JSX.Element => {
  const hasNames = names && names?.length > 0;
  return hasNames ? (
    <Table caption="Name Variation">
      <Table.Head>
        <Table.ColumnHeader>Name</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {names?.map((result) => (
          <Table.Row key={result.name}>
            <Table.Cell>{result.name}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Name Variations')}</Text>
    </Box>
  );
};
