import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  IndustryRisk,
  IndustryRiskAuditEntry,
  IndustryRiskCreateInput,
  IndustryRiskUpdateInput,
} from './IndustryRisk.types';

export const fetchAllIndustryRisks = (): Promise<IndustryRisk[]> => {
  const url = new URL(
    `/api/v2/industries?with_archived=1`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<IndustryRisk[]>(url, 'GET').then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 403) {
      throw new Error('You are not authorized');
    }

    throw new Error(
      `${response.status} Error fetching industries data. Please reload the page and try again.`
    );
  });
};

export const updateIndustryRisk = (
  industryRisk: IndustryRiskUpdateInput
): Promise<IndustryRisk> => {
  const url = new URL(
    `/api/v2/industries/${industryRisk.uuid}`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<IndustryRisk>(url, 'PATCH', {
    ...industryRisk,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 404) {
      throw new Error('IndustryRisk not found');
    } else if (response.status === 403) {
      throw new Error('You are not authorized');
    } else if (response.status === 422) {
      throw new Error('Unable to process request.');
    }

    throw new Error(
      `${response.status} Error updating industry data. Please reload the page and try again.`
    );
  });
};

export const createIndustryRisk = (
  industryRisk: IndustryRiskCreateInput
): Promise<IndustryRisk> => {
  const url = new URL(`/api/v2/industries`, UNDERWRITING_BASE_URL());

  return makeInternalAPIRequest<IndustryRisk>(url, 'POST', {
    ...industryRisk,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 403) {
      throw new Error('You are not authorized');
    } else if (response.status === 422) {
      throw new Error('Unable to process request.');
    }

    throw new NetworkError(
      response.status,
      'Error creating industry risk. Please reload the page and try again.'
    );
  });
};

export const getAuditLog = (
  industryRisk: IndustryRisk
): Promise<IndustryRiskAuditEntry[]> => {
  const url = new URL(
    `/api/v2/industries/${industryRisk.uuid}/versions?include=whodunnit_user_name,object_changes&order_created_desc=1`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<IndustryRiskAuditEntry[]>(url, 'GET').then(
    (response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 403) {
        throw new Error('You are not authorized');
      }

      throw new NetworkError(
        response.status,
        `Error fetching industries data. Please reload the page and try again.`
      );
    }
  );
};
