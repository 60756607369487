import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Hook that performs a deep comparison between the current value and the
 * previous value. If the values are the same it will return the previous
 * reference. If the values are different, it will return a reference to the
 * new value.
 *
 * This is useful for optimizing the dependencies of React hooks
 * (like useEffect) that will benefit from rely on deep value comparisons
 * rather than reference equality. This can prevent an unintended render caused
 * by a new but equal value (e.g. object or array) being passed as a dependency.
 *
 * Note that for large objects or arrays, this hook can be expensive so use
 * it only after testing performance to understand if the benefits outweigh the
 * cost.
 *
 * @template T Type of the input value.
 * @param {T} value Current value that needs to be memoized based on deep
 * comparison.
 * @returns {T} The memoized value that only changes when a deep comparison
 * detects a difference.
 * @example
 * const obj = { a: 1 };
 * const memoizedObj = useDeepCompareMemoize(obj);
 *
 * useEffect(() => {
 *   // This callback only triggers if `obj` has a value changed deeply inside.
 * }, [memoizedObj]);
 * @see the tests for more examples.
 */
export function useDeepCompareMemoize<T>(value: T): T {
  const ref = useRef<T>();

  // if `value` has changed deeply, then update the ref.
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  // Type guard to ensure we are not returning undefined
  if (ref.current === undefined) {
    return value;
  }

  return ref.current;
}
