import React from 'react';
import {
  Box,
  Flex,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { CriminalRecord } from './types';

export interface CriminalFilingsTableProps {
  criminalFilings: CriminalRecord[];
}

export const CriminalFilingsTable = (
  props: CriminalFilingsTableProps
): JSX.Element => {
  return (
    <>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Criminal Filings</Subheading>
      </Flex>
      {props.criminalFilings.length === 0 ? (
        <Box mb={4}>
          <Text>There are no criminal filings</Text>
        </Box>
      ) : (
        <Table caption="Criminal Records">
          <Table.Head>
            <Table.ColumnHeader>Name</Table.ColumnHeader>
            <Table.ColumnHeader>Offense</Table.ColumnHeader>
            <Table.ColumnHeader>Offense Date</Table.ColumnHeader>
            <Table.ColumnHeader>Arrest Date</Table.ColumnHeader>
            <Table.ColumnHeader>Case Filing Date</Table.ColumnHeader>
            <Table.ColumnHeader>State</Table.ColumnHeader>
            <Table.ColumnHeader>Time Served</Table.ColumnHeader>
            <Table.ColumnHeader>Status</Table.ColumnHeader>
            <Table.ColumnHeader>Detail</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {props.criminalFilings.map((filing) => (
              <Table.Row key={filing.offenseDate}>
                <Table.Cell>{filing.fullName}</Table.Cell>
                <Table.Cell>{filing.offense}</Table.Cell>
                <Table.Cell>{filing.offenseDate ?? 'N/A'}</Table.Cell>
                <Table.Cell>{filing.arrestDate ?? 'N/A'}</Table.Cell>
                <Table.Cell>{filing.caseFilingDate ?? 'N/A'}</Table.Cell>
                <Table.Cell>{filing.state}</Table.Cell>
                <Table.Cell>
                  {filing.scheduledReleaseDates?.map((date) => (
                    <Text key={date}>{date}</Text>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {filing.statuses?.map((status) => (
                    <Text key={status}>{status}</Text>
                  ))}
                </Table.Cell>
                <Table.Cell>{filing.caseTypeDescription}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
