import React from 'react';
import {
  Box,
  Grid,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { useNewDealScoringContext } from './NewDealScoringContext';

export const NewDealScoringReadOnly = (): JSX.Element => {
  const { response } = useNewDealScoringContext();

  return (
    <Box mb={4}>
      <Grid gutter>
        <Grid.Item xs={12} s={6} l={5}>
          <Subheading>
            Deal Score: {response?.averageScore?.toFixed(1) ?? 'N/A'} / 5.0
          </Subheading>
          <Table caption="Deal Score">
            <Table.Head>
              <Table.ColumnHeader>Risk Category</Table.ColumnHeader>
              <Table.ColumnHeader>Score</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.RowHeader>Owner Risk</Table.RowHeader>
                <Table.Cell>{response?.ownerRisk}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>Business Risk</Table.RowHeader>
                <Table.Cell>{response?.businessRisk}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>Quality of Cash Flows</Table.RowHeader>
                <Table.Cell>{response?.qualityOfCashFlows}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Item>
        <Grid.Item xs={12} s={6} l={7}>
          <>
            <Subheading>Notes</Subheading>
            {response?.underwriterNote?.split('\n').map((line) => (
              <Text key={line}>{line}</Text>
            ))}
          </>
        </Grid.Item>
      </Grid>
    </Box>
  );
};
