import {
  Box,
  Breadcrumbs,
  Flex,
  Heading,
  PageContainer,
} from '@forward-financing/fast-forward';
import React from 'react';
import { IndustryRiskTable } from './IndustryRisk/IndustryRiskTable';
import { PricingStrategyGross } from './PricingStrategyGross/PricingStrategyGross';
import { PricingStrategyProgramTerm } from './PricingStrategyProgramTerm/PricingStrategyProgramTerm';

export const AdminPanelContainer = (): JSX.Element => {
  return (
    <PageContainer data-testid="uw-container">
      <Box pt={4}>
        <Breadcrumbs>
          <Breadcrumbs.Crumb href={new URL('/', window.location.origin)}>
            Dashboard
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            href={new URL('/dashboard/underwriting', window.location.origin)}
          >
            Underwriting Admin Panel
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
      </Box>

      <Heading>Underwriting Admin Panel</Heading>

      <Flex flexDirection="column" gap={2}>
        <PricingStrategyGross />
        <PricingStrategyProgramTerm />
        <IndustryRiskTable />
      </Flex>
    </PageContainer>
  );
};
