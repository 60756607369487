import React from 'react';
import {
  Banner,
  Box,
  Flex,
  Icon,
  IconButton,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { LexisNexisCustomerManualSearchData } from './businessManualSearch.types';
import { usePullCustomerLexisNexisReport } from './businessManualSearchHooks';

type LexisNexisManualSearchTableProps = {
  searchResults: LexisNexisCustomerManualSearchData[];
  submissionUuid: string;
};

export const LexisNexisManualSearchTable = ({
  submissionUuid,
  searchResults,
}: LexisNexisManualSearchTableProps): JSX.Element => {
  const [
    pullLexisNexis,
    {
      data: pullLexisNexisSuccess,
      loading: pullLexisNexisLoading,
      error: pullLexisNexisError,
    },
  ] = usePullCustomerLexisNexisReport(submissionUuid);

  const hasResults = searchResults.length > 0;

  return (
    <>
      {pullLexisNexisSuccess && (
        <Banner dismissable={false} variant="success">
          <Flex flexDirection={'row'} gap={2} alignItems={'center'}>
            <Icon name="circle-check" size="2x" />
            <Flex flexDirection={'column'} gap={1}>
              <Text>
                LexisNexis was pulled successfully! It takes anywhere from 5
                seconds to 4 minutes to process the data, so please allow a
                little bit of time before refreshing the page.
              </Text>
              <Text>
                If you are still not able to view the report after 4 minutes,
                please contact tech support.
              </Text>
            </Flex>
          </Flex>
        </Banner>
      )}

      {pullLexisNexisError && (
        <Banner dismissable={false} variant="error">
          {pullLexisNexisError.message}
        </Banner>
      )}

      <Table>
        <Table.Head>
          <Table.ColumnHeader>Name</Table.ColumnHeader>
          <Table.ColumnHeader>Address</Table.ColumnHeader>
          <Table.ColumnHeader>UCC</Table.ColumnHeader>
          <Table.ColumnHeader />
        </Table.Head>

        <Table.Body>
          {!hasResults && (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Text>No results found</Text>
              </Table.Cell>
            </Table.Row>
          )}

          {hasResults &&
            searchResults?.map(({ name, address, hasUcc, reportId }) => (
              <Table.Row key={`${name}${address}`}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{address}</Table.Cell>
                <Table.Cell>{hasUcc ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell columnWidth="medium">
                  <Box mx={2}>
                    <IconButton
                      label="Add to Submission"
                      icon="plus-circle"
                      disabled={pullLexisNexisLoading}
                      onClick={() => pullLexisNexis({ reportId })}
                    />
                  </Box>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
};
