import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  TextArea,
  Select,
  Banner,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { useDeclineExceptionRequest } from '../exceptionsRequestHooks';

export interface ExceptionsRequestDeclineFormProps {
  onExit: () => void;
  onSave: () => void;
  declineReasons: string[];
  requestId: number;
}

export const ExceptionsRequestDeclineForm = (
  props: ExceptionsRequestDeclineFormProps
): JSX.Element => {
  const currentUser = useUserContext();

  const [notesError, setNotesError] = useState<string | undefined>();
  const [reasonError, setReasonError] = useState<string | undefined>();
  const [declineNotes, setDeclineNotes] = useState('');
  const [declineReason, setDeclineReason] = useState('');

  const [declineRequest, { loading, error: declineError }] =
    useDeclineExceptionRequest(props.requestId);

  const submitAction = async (): Promise<void> => {
    if (declineReason === '' || declineNotes === '') {
      if (declineReason === '') {
        setReasonError('Decline reason is required');
      }
      if (declineNotes === '') {
        setNotesError('Decline notes are required');
      }
      return;
    }

    const { success } = await declineRequest({
      declineNotes,
      reviewerId: currentUser.id,
      declineReason,
    });

    if (success) {
      props.onSave();
      props.onExit();
    }
  };

  return (
    <Box>
      {declineError && (
        <Banner dismissable={false}>
          <Text>{declineError.message}</Text>
        </Banner>
      )}
      <Box py={3}>
        <Select
          label="Decline reason"
          value={declineReason}
          options={
            props.declineReasons?.map((reason) => ({ value: reason })) ?? []
          }
          onValueChange={(val) => {
            setDeclineReason(val);
            setReasonError(undefined);
          }}
          errorMessage={reasonError}
        />
        <TextArea
          name="decline-notes-field"
          label="Please explain your decision:"
          errorMessage={notesError}
          onChange={(event) => {
            setDeclineNotes(event.target.value);
            setNotesError(undefined);
          }}
          value={declineNotes}
          rows={4}
        />
      </Box>
      <Flex gap={2}>
        <Button variant="secondary" onClick={props.onExit}>
          Cancel
        </Button>
        <Button variant="danger" disabled={loading} onClick={submitAction}>
          Decline Request
        </Button>
      </Flex>
    </Box>
  );
};
