import React from 'react';
import { useApplicationSnapshot } from './applicationSnapshotFetchHooks';
import { ApplicationSnapshot } from './ApplicationSnapshot';
import { ApplicationSnapshotSubmission } from './applicationSnapshot.types';

export interface ApplicationSnapshotContainerProps {
  submission: ApplicationSnapshotSubmission;
}

export const ApplicationSnapshotContainer = ({
  submission,
}: ApplicationSnapshotContainerProps): JSX.Element => {
  const applicationSnapshot = useApplicationSnapshot(submission);

  return <ApplicationSnapshot {...applicationSnapshot} />;
};
