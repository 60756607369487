import {
  ShortTokenCache,
  AuthenticationResponse,
  ShortToken,
  AuthenticationHeaders,
  UserResponse,
  User,
} from './codecs';
import { FetchResponse, HashMap } from '../codecs';
import { AUTH_BASE_URL } from '../../constants/globals';

import { HTTPClient } from '../HTTPClient/';
import add from 'date-fns/add';
import getUnixTime from 'date-fns/getUnixTime';
import isAfter from 'date-fns/isAfter';
import fromUnixTime from 'date-fns/fromUnixTime';
import isEqual from 'date-fns/isEqual';

export class AuthClient extends HTTPClient {
  private static BROWSER_STORAGE_KEY = 'user_short_token';

  private static BASE_AUTH_URL: string = AUTH_BASE_URL() || '';
  private static TIME_TO_LIVE = 30;

  static authenticateApiRequest<A>(
    callback: (
      authenticatedHeaders: AuthenticationHeaders
    ) => Promise<FetchResponse<A>>
  ): Promise<FetchResponse<A>> {
    return AuthClient.fetchShortToken()
      .then((shortTokenCache) =>
        callback(
          AuthClient.makeAuthenticationHeaders(shortTokenCache.shortToken)
        )
      )
      .then((response) => {
        if (response.status === 401) {
          // User is not authenticated, try again
          AuthClient.clearStorage();

          return AuthClient.fetchShortToken().then((shortTokenCache) =>
            callback(
              AuthClient.makeAuthenticationHeaders(shortTokenCache.shortToken)
            )
          );
        }

        return Promise.resolve(response);
      });
  }

  static fetchAuthenticatedUser = (): Promise<User> => {
    const postUserData = (authenticatedHeaders: AuthenticationHeaders) =>
      AuthClient.post<HashMap<void>, UserResponse>(
        `${AuthClient.BASE_AUTH_URL}api/v1/authentication`,
        {},
        {
          headers: authenticatedHeaders,
        }
      );

    return AuthClient.authenticateApiRequest<UserResponse>(postUserData)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Something went wrong fetching current user');
        }
        return response.json();
      })
      .then((userResponse) => userResponse.user);
  };

  static fetchUsersByRole = (role: string): Promise<User[]> => {
    const getUsers = (authenticatedHeaders: AuthenticationHeaders) =>
      AuthClient.get<User[]>(
        `${AuthClient.BASE_AUTH_URL}api/v1/roles/${role}/users`,
        {
          headers: authenticatedHeaders,
        }
      );

    return AuthClient.authenticateApiRequest<User[]>(getUsers).then(
      (response) => {
        if (response.status !== 200) {
          throw new Error('Something went wrong fetching users by role');
        }
        return response.json();
      }
    );
  };

  private static fetchShortToken(): Promise<ShortTokenCache> {
    const cache = AuthClient.fetchFromStorage();

    if (!cache || AuthClient.isExpired(cache.ttl)) {
      AuthClient.clearStorage();

      return AuthClient.authenticateUser().then((shortToken) => {
        const shortTokenCache: ShortTokenCache = {
          shortToken: shortToken,
          ttl: AuthClient.createTTL(),
        };

        AuthClient.persistToStorage(shortTokenCache);

        return shortTokenCache;
      });
    }

    return Promise.resolve(cache);
  }

  private static authenticateUser = (): Promise<ShortToken> => {
    return AuthClient.post<HashMap<void>, AuthenticationResponse>(
      `${AuthClient.BASE_AUTH_URL}api/v1/token`,
      {}
    )
      .then((response: FetchResponse<AuthenticationResponse>) => {
        if (response.status === 201) {
          return response.json();
        }

        throw new Error('Non 201 response fails....');
      })
      .then((authResponse) => authResponse.token)
      .catch(() => {
        AuthClient.redirectUserToLoginPage();
        throw new Error('Unable to fetch short token');
      });
  };

  private static redirectUserToLoginPage = (): void => {
    window.location.assign(
      `${AuthClient.BASE_AUTH_URL}?referer=${window.location.href}`
    );
  };

  private static makeAuthenticationHeaders = (
    shortToken: ShortToken
  ): AuthenticationHeaders => ({
    Authorization: `Token ${shortToken}`,
  });

  private static fetchFromStorage = (): ShortTokenCache | null => {
    const shortToken = localStorage.getItem(AuthClient.BROWSER_STORAGE_KEY);

    return shortToken ? (JSON.parse(shortToken) as ShortTokenCache) : null;
  };

  private static persistToStorage = (shortTokenCache: ShortTokenCache): void =>
    localStorage.setItem(
      AuthClient.BROWSER_STORAGE_KEY,
      JSON.stringify(shortTokenCache)
    );

  private static clearStorage = (): void =>
    localStorage.removeItem(AuthClient.BROWSER_STORAGE_KEY);

  private static createTTL = (): number => {
    const interval = AuthClient.TIME_TO_LIVE;

    return getUnixTime(add(new Date(), { minutes: interval }));
  };

  private static isExpired = (timestamp: number): boolean =>
    isAfter(new Date(), fromUnixTime(timestamp)) ||
    isEqual(new Date(), fromUnixTime(timestamp));
}
