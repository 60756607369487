import { useGetCustomerSubmissions } from 'apiHooks/underwriting/customerFetchHooks';
import { SubmissionResponse } from 'types/api/underwriting/types';
import { CustomerSubmission } from './CustomerSearch.types';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';

const toCustomerSubmissions = (
  customerSubmission: SubmissionResponse
): CustomerSubmission => {
  return {
    dateAppReceived: customerSubmission.date_app_received__c ?? undefined,
    name: customerSubmission.name,
    stage: customerSubmission.stage_name,
    subStage: customerSubmission.sub_stage ?? undefined,
    uuid: customerSubmission.uuid,
    owner1FullName: customerSubmission.owner_1_full_name ?? undefined,
    owner2FullName: customerSubmission.owner_2_full_name ?? undefined,
    isoUuid: customerSubmission.partner_uuid ?? undefined,
  };
};

export const useCustomerSubmissions = (
  customerUuid?: string
): UseGenericQueryResponse<CustomerSubmission[]> => {
  return useGenericFeatureQuery(
    useGetCustomerSubmissions,
    (data) => (data ? data?.map(toCustomerSubmissions) : undefined),
    customerUuid
  );
};
