import React from 'react';
import { formatBoolean } from '../../../helpers/stringUtils';
import { Currency, Table } from '@forward-financing/fast-forward';
import { ExceptionRequest } from '../exceptionsRequest.types';

export interface RequestDetailsProps {
  exceptionRequest: ExceptionRequest;
}

export const RequestDetails = (props: RequestDetailsProps): JSX.Element => {
  const rowData = [
    {
      label: 'Funding Amount',
      offerValue: props.exceptionRequest.offer?.fundingAmount && (
        <Currency amount={props.exceptionRequest.offer?.fundingAmount} />
      ),
      value: <Currency amount={props.exceptionRequest.fundingAmount} />,
      decision: props.exceptionRequest.underwriterDecision?.fundingAmount && (
        <Currency
          amount={props.exceptionRequest.underwriterDecision?.fundingAmount}
        />
      ),
      note: props.exceptionRequest.declineNotes,
    },
    {
      label: 'Term Length',
      offerValue: props.exceptionRequest.offer?.termLength,
      value: props.exceptionRequest.termLength,
      decision: props.exceptionRequest.underwriterDecision?.termLength,
    },
    {
      label: 'Buy Rate',
      offerValue: props.exceptionRequest.offer?.buyRate,
      value: props.exceptionRequest.buyRate,
      decision: props.exceptionRequest.underwriterDecision?.buyRate,
    },
    {
      label: 'Program',
      offerValue: props.exceptionRequest.offer?.program,
      value: props.exceptionRequest.program,
      decision: props.exceptionRequest.underwriterDecision?.program,
    },
    {
      label: 'Decline Decision Reversal',
      offerValue: null,
      value: formatBoolean(props.exceptionRequest.decisionReversal),
      decision: null,
    },
    {
      label: 'Stipulations / Additional Documents',
      offerValue: null,
      value: props.exceptionRequest.stipExceptionNotes,
      decision: null,
    },
    {
      label: 'Notes',
      offerValue: null,
      value: props.exceptionRequest.offerExceptionNotes,
      decision: null,
    },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader>Offer</Table.ColumnHeader>
        <Table.ColumnHeader>FF Offer</Table.ColumnHeader>
        <Table.ColumnHeader>Exception Request</Table.ColumnHeader>
        <Table.ColumnHeader>UW Decision</Table.ColumnHeader>
        <Table.ColumnHeader>UW notes</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {rowData.map((row, index) => (
          <Table.Row key={row.label}>
            <Table.Cell>{row.label}</Table.Cell>
            <Table.Cell>{row.offerValue}</Table.Cell>
            <Table.Cell columnWidth="medium" overflowStrategy="wrap">
              {row.value}
            </Table.Cell>
            <Table.Cell>{row.decision}</Table.Cell>
            {index === 0 && (
              <Table.Cell
                columnWidth="medium"
                overflowStrategy="wrap"
                rowSpan={rowData.length}
              >
                {row.note}
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
