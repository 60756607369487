import { OptionShape } from '@forward-financing/fast-forward';
import { User } from './SubmissionOnboardingQueue.types';

export const displayUserName = (users: User[], userUuid?: string): string => {
  // GoLang doesn't have a global null type, so empty UUID fields will
  // come in as this null UUID value.
  if (userUuid === '00000000-0000-0000-0000-000000000000') {
    return 'Unassigned';
  }

  const matchedUser = users.find((user) => user.uuid && user.uuid === userUuid);

  if (matchedUser?.firstName && !matchedUser?.lastName) {
    return matchedUser.firstName;
  } else if (!matchedUser?.firstName && matchedUser?.lastName) {
    return matchedUser.lastName;
  } else if (matchedUser?.firstName && matchedUser?.lastName) {
    return `${matchedUser.firstName} ${matchedUser.lastName}`;
  } else {
    return 'Unknown';
  }
};

export const userToOptionShape = (users?: User[]): OptionShape[] => {
  if (!users) {
    return [];
  }

  return users?.map((u) => {
    const fullName = `${u.firstName} ${u.lastName}`;
    return { value: u.uuid, text: fullName };
  });
};
