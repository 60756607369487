import React from 'react';
import {
  Box,
  Divider,
  formatCurrency,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { LienJudgment } from './BusinessLexisNexisPage.types';

type LiensJudgmentsTableProps = {
  lienJudgmentData?: LienJudgment[];
};

export const LiensJudgmentsTable = ({
  lienJudgmentData,
}: LiensJudgmentsTableProps): JSX.Element => {
  const hasLiens = lienJudgmentData && lienJudgmentData.length > 0;

  return hasLiens ? (
    <Table caption="Liens Judgments">
      <Table.Head>
        <Table.ColumnHeader>Debtor</Table.ColumnHeader>
        <Table.ColumnHeader>Creditor</Table.ColumnHeader>
        <Table.ColumnHeader>Filings</Table.ColumnHeader>
        <Table.ColumnHeader>Amount</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {lienJudgmentData?.map((row) => (
          <Table.Row key={`${row.creditor[0].name} ${row.amount}`}>
            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.debtor.length > 0 &&
                row.debtor.map((debtor) => (
                  <Box key={defaultTo(debtor.name, debtor.companyName)} mb={4}>
                    <Text>{defaultTo(debtor.name, debtor.companyName)}</Text>
                    {debtor.address?.map((address) => (
                      <Text size="s" italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell>
              {row.creditor.length > 0 &&
                row.creditor.map((creditor) => (
                  <Box key={creditor.name} mb={4}>
                    <Text>{creditor.name}</Text>
                    {creditor.address?.map((address) => (
                      <Text italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell columnWidth="large">
              {row.filings.length > 0 &&
                row.filings.map((filing, index) => (
                  <Box key={filing.filingNumber}>
                    <Text>
                      <Text bold>Type</Text>: {filing.type}
                    </Text>
                    <Text>
                      <Text bold>Filing Date</Text>: {filing.originFilingDate}
                    </Text>
                    <Text>
                      <Text bold>Agency</Text>: {filing.agency}
                    </Text>
                    <Text>
                      <Text bold>Agency State</Text>: {filing.agencyState}
                    </Text>
                    <Text>
                      <Text bold>Agency County</Text>: {filing.agencyCounty}
                    </Text>
                    <Text>
                      <Text bold>Number</Text>: {filing.filingNumber}
                    </Text>

                    {/* Add a visual separator if there are multiple filings */}
                    {index !== row.filings.length - 1 && (
                      <Divider orientation="horizontal" />
                    )}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell>
              {row.amount ? formatCurrency(Number(row.amount)) : 'None'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Lien judgments')}</Text>
    </Box>
  );
};
