import { Button, Loading } from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useUserContext } from 'contexts/UserContext';
import { featureFlags } from 'helpers/featureFlags';
import React, { useEffect } from 'react';

export interface GotToNextDealButtonProps {
  updateFunction: () => Promise<MutationResponse>;
  nextDealUrl: string | undefined;
  nextDealLoading: boolean;
}
export const GoToNextDealButton = ({
  updateFunction,
  nextDealUrl,
  nextDealLoading,
}: GotToNextDealButtonProps): JSX.Element => {
  const { sub_role: userSubRole } = useUserContext();

  const goToNextSubmission = async (): Promise<void> => {
    await updateFunction();
  };

  useEffect(() => {
    if (nextDealUrl && !nextDealLoading) {
      window.open(nextDealUrl, '_blank');
    }
  }, [nextDealUrl, nextDealLoading]);

  return (
    <>
      {userSubRole === 'prequal' &&
        featureFlags.show_prequal_go_to_next_button &&
        (nextDealLoading ? (
          <Loading size="small" />
        ) : (
          <Button
            startIcon={'arrow-right'}
            variant={'cta'}
            onClick={() => goToNextSubmission()}
          >
            Go to Next Submission
          </Button>
        ))}
    </>
  );
};
