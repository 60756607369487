import React, { useState } from 'react';
import {
  Banner,
  Text,
  Box,
  Button,
  Flex,
  Modal,
} from '@forward-financing/fast-forward';
import { useNavigate } from 'react-router';
import { useDeletePaynetReport } from './businessPaynetHooks';

export type BusinessPaynetDeleteReportModalProps = {
  documentId: string;
  submissionUuid: string;
};

export const BusinessPaynetDeleteReportModal = ({
  submissionUuid,
  documentId,
}: BusinessPaynetDeleteReportModalProps): JSX.Element => {
  const navigate = useNavigate();
  const [deleteReport, { error: errorDeleteReport }] = useDeletePaynetReport();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenChange = (): void => setIsModalOpen(!isModalOpen);
  const handleDeleteDocument = async (): Promise<void> => {
    const { success } = await deleteReport(documentId);
    if (success) {
      navigate(`/submissions/${submissionUuid}`);
    }
  };
  return (
    <Modal
      trigger={
        <Button variant="danger" startIcon={'trash'}>
          Delete
        </Button>
      }
      isOpen={isModalOpen}
      title="Deletion Confirmation"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {errorDeleteReport && (
            <Banner dismissable={false} variant="error">
              {errorDeleteReport.message}
            </Banner>
          )}
          <Text>
            You are about to delete this Paynet report. Please confirm.
          </Text>
          <Flex justifyContent={'center'} flexDirection={'row'} gap={4} mt={5}>
            <Button
              startIcon="trash"
              variant="danger"
              onClick={() => handleDeleteDocument()}
            >
              Delete Report
            </Button>
            <Button
              startIcon="times"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancel
            </Button>
          </Flex>
        </Box>
      }
    />
  );
};
