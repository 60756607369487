import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { FUNDING_BASE_URL } from 'constants/globals';
import { PaybackBatchRecordResponse } from 'types/api/funding/types';

export const fetchPayments = async (
  batchType: 'same_day' | 'batch' | 'one_time',
  page = 0
): Promise<PaybackBatchRecordResponse[]> => {
  const url = new URL(`/api/v2/payback/batches`, FUNDING_BASE_URL());
  url.searchParams.append('page', page.toString());
  url.searchParams.append('batch_type', batchType);

  const response = await makeInternalAPIRequest<PaybackBatchRecordResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Failed to fetch payments for batch type ${batchType}, page ${page}`
    );
  }

  return response.json();
};
