import React, { useState } from 'react';

import { Contact, UsState } from '../../../api/UnderwritingClient/codecs';
import { HashMap } from '../../../api/codecs';
import { ValidationResult } from '../../../helpers/validations/codecs';
import {
  dropDownStates,
  obtainValidZipCodeEvent,
} from '../../../helpers/utils';

// Components
import { FormRow, DisplayStyle } from '../../shared/form/FormRow';
import { Input } from '../../shared/form/Input';
import { Dropdown } from '../../shared/form/Dropdown';
import { SubformSection } from '../../shared/SubformSection';
import { HTMLFormChangeOrMouseEvent } from '../../../types/form';
import { DATE_FORMAT } from '../../../constants/globals';
import { Checkbox } from '../../shared/form/Checkbox';
import { LogData } from '../../../api/AnalyticsGatewayClient/codecs';
import { GoogleZipCodeLookup } from 'components/shared/form/GoogleZipCodeLookup';

export interface OwnerInformationProps {
  focusReference?: React.MutableRefObject<HTMLDivElement | null>;
  owner: Contact;
  title: string;
  usStates: UsState[];
  showCloseButton: boolean;
  needsConfirm: boolean;
  validationErrors: HashMap<ValidationResult>;
  handleOwnerChange: (e: HTMLFormChangeOrMouseEvent) => void;
  handleOwnerAddressChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  handleUseAccountAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseAction: (e: React.MouseEvent) => void;
  showUseFirstOwnerAddressCheckbox: boolean;
  handleUseFirstOwnerAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendUnmaskedFieldLogs?: (data: LogData) => Promise<void>;
}

export const OwnerInformation = (props: OwnerInformationProps): JSX.Element => {
  const [useAccountAddress, setUseAccountAddress] = useState(false);
  const [useFirstOwnerAddress, setUseFirstOwnerAddress] = useState(false);

  const handleZipCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const event = obtainValidZipCodeEvent(e);
    props.handleOwnerAddressChange(event);
  };

  const autofillZipFields = (
    fields: {
      city?: string;
      state?: string;
      street1?: string;
      zip?: string;
    } = {}
  ): void => {
    Object.entries(fields)
      .filter(([name]) => ['city', 'state', 'zip'].includes(name))
      .forEach(([name, value]) => {
        const syntheticEvent = {
          persist: () => {
            // Intentionally empty
          },
          target: { name, value },
        };

        props.handleOwnerAddressChange(
          syntheticEvent as React.ChangeEvent<HTMLInputElement>
        );
      });
  };

  const handleSSNvalue = (): string => {
    return props.owner.ssnNotPresent ? '' : props.owner.ssn || '';
  };

  const handleBornOnvalue = (): string => {
    return props.owner.bornOnNotPresent ? '' : props.owner.born_on || '';
  };

  const handleUseAccountAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (useFirstOwnerAddress) {
      setUseFirstOwnerAddress(false);
    }
    setUseAccountAddress(e.target.checked);
    props.handleUseAccountAddress(e);
  };

  const handleUseFirstOwnerAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (useAccountAddress) {
      setUseAccountAddress(false);
    }
    setUseFirstOwnerAddress(e.target.checked);
    props.handleUseFirstOwnerAddress(e);
  };

  const handleSendLogs = async (field_name: string): Promise<void> => {
    await props.sendUnmaskedFieldLogs?.({
      field_name: field_name,
      object_uuid: props.owner.uuid || '',
      name: `${props.owner.first_name} ${props.owner.last_name}`,
      action: 'show',
      user_uuid: '',
      user_email: '',
      source: '',
      timestamp: '',
      object_type: 'Owner',
    });
  };

  const isFieldSet = (field: string | undefined): boolean => {
    return !!(typeof field != 'undefined' && field);
  };

  return (
    <div
      className={props.focusReference ? 'current-reference' : undefined}
      ref={props.focusReference}
    >
      <SubformSection
        label={props.title}
        showCloseButton={props.showCloseButton}
        onClick={props.onCloseAction}
        needsConfirm={props.needsConfirm}
      >
        <div>
          <div className="columns" style={{ marginBottom: '-0.5rem' }}>
            <div className="column">
              <div
                className={
                  'is-pulled-right owner-information-form-checkbox-container'
                }
              >
                <Checkbox
                  checked={useAccountAddress}
                  className="has-text-right"
                  name="useAccountAddress"
                  label="Home address same as business address"
                  onChange={handleUseAccountAddress}
                />
                {props.showUseFirstOwnerAddressCheckbox && (
                  <Checkbox
                    checked={useFirstOwnerAddress}
                    className="has-text-right"
                    name="useFirstOwnerAddress"
                    label="Home address same as Owner 1"
                    onChange={handleUseFirstOwnerAddress}
                  />
                )}
              </div>
            </div>
          </div>

          <div>
            <FormRow
              left={
                <Input
                  label="First Name"
                  name="first_name"
                  onChange={props.handleOwnerChange}
                  validationResult={props.validationErrors.first_name}
                  value={props.owner.first_name}
                />
              }
              right={
                <Input
                  label="Street Address"
                  name="street1"
                  onChange={props.handleOwnerAddressChange}
                  validationResult={props.validationErrors.street1}
                  value={props.owner.address?.street1}
                />
              }
            />
            <FormRow
              left={
                <Input
                  label="Last Name"
                  name="last_name"
                  onChange={props.handleOwnerChange}
                  validationResult={props.validationErrors.last_name}
                  value={props.owner.last_name}
                />
              }
              right={
                <Input
                  label="City"
                  name="city"
                  onChange={props.handleOwnerAddressChange}
                  validationResult={props.validationErrors.city}
                  value={props.owner.address?.city}
                />
              }
            />
            <FormRow
              left={
                <Input
                  label="SSN"
                  name="ssn"
                  checkBox={{
                    label: '"SSN" not on application',
                    name: 'ssnNotPresent',
                    value: !!props.owner.ssnNotPresent,
                    checkboxLabelSize: 'is-half',
                    onClick: props.handleOwnerChange,
                  }}
                  inputMask="999-99-9999"
                  onChange={props.handleOwnerChange}
                  placeholder="999-99-9999"
                  validationResult={props.validationErrors.ssn}
                  value={handleSSNvalue()}
                  isDisabled={props.owner.ssnNotPresent}
                  shouldBeMasked={isFieldSet(props.owner.uuid)}
                  handleSendLogs={handleSendLogs}
                />
              }
              right={
                <Dropdown
                  label="State"
                  name="state"
                  options={dropDownStates(props.usStates)}
                  onChange={props.handleOwnerAddressChange}
                  validationResult={props.validationErrors.state}
                  value={props.owner.address?.state}
                />
              }
            />
            <FormRow
              left={
                <Input
                  label="Date of Birth"
                  name="born_on"
                  checkBox={{
                    label: 'Date of Birth not on application',
                    name: 'bornOnNotPresent',
                    value: !!props.owner.bornOnNotPresent,
                    checkboxLabelSize: 'is-half',
                    onClick: props.handleOwnerChange,
                  }}
                  inputMask="99/99/9999"
                  placeholder={DATE_FORMAT}
                  onChange={props.handleOwnerChange}
                  value={handleBornOnvalue()}
                  isDisabled={props.owner.bornOnNotPresent}
                  validationResult={props.validationErrors.born_on}
                  shouldBeMasked={isFieldSet(props.owner.uuid)}
                  handleSendLogs={handleSendLogs}
                />
              }
              right={
                <GoogleZipCodeLookup
                  handleAutofill={autofillZipFields}
                  label="Zip Code"
                  onChange={(newValue: string) => {
                    handleZipCodeChange({
                      persist: () => {
                        // intentionally empty
                        // just trying to provide enough interface that we don't crash the page
                      },
                      target: {
                        name: 'zip',
                        value: newValue,
                      },
                    } as React.ChangeEvent<HTMLInputElement>);
                  }}
                  placeholder="5 or 9-digit Zip Code"
                  usStates={props.usStates}
                  value={props.owner.address?.zip || ''}
                />
              }
            />

            <FormRow
              left={
                <Input
                  label="Title"
                  name="title"
                  onChange={props.handleOwnerChange}
                  validationResult={props.validationErrors.title}
                  value={props.owner.title || ''}
                />
              }
              right={
                <Input
                  label="Home Phone"
                  name="home_phone"
                  leadingIcon="fas fa-phone"
                  inputMask="(999)999-9999"
                  onChange={props.handleOwnerChange}
                  placeholder="(999)999-9999"
                  validationResult={props.validationErrors.home_phone}
                  value={props.owner.home_phone || ''}
                />
              }
            />

            <FormRow
              left={
                <Input
                  label="Ownership %"
                  name="ownership_percentage"
                  onChange={props.handleOwnerChange}
                  validationResult={props.validationErrors.ownership}
                  placeholder="0"
                  value={
                    props.owner.ownership_percentage?.toString() === '0'
                      ? ''
                      : props.owner.ownership_percentage?.toString()
                  }
                />
              }
              right={
                <Input
                  label="Mobile Phone"
                  name="cell_phone"
                  leadingIcon="fas fa-mobile-alt"
                  inputMask="(999)999-9999"
                  onChange={props.handleOwnerChange}
                  placeholder="(999)999-9999"
                  validationResult={props.validationErrors.cell_phone}
                  value={props.owner.cell_phone || ''}
                />
              }
            />
            <FormRow
              displayStyle={DisplayStyle.SingleRight}
              right={
                <Input
                  label="Email Address"
                  name="email"
                  leadingIcon="fas fa-envelope"
                  onChange={props.handleOwnerChange}
                  validationResult={props.validationErrors.email}
                  value={props.owner.email || ''}
                />
              }
            />
          </div>
        </div>
      </SubformSection>
    </div>
  );
};
