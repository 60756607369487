import React, { useEffect, useState } from 'react';
import {
  Banner,
  Button,
  Flex,
  Modal,
  RadioGrid,
  Text,
} from '@forward-financing/fast-forward';
import { IndustryRisk } from './IndustryRisk.types';
import {
  IndustryRiskColorInterface,
  industryRiskColors,
} from './IndustryRiskTable';
import { updateIndustryRisk } from './IndustryRiskFetchUtils';
import { toError } from 'helpers/errorUtils';

export interface IndustryRiskModalProps {
  industries: IndustryRisk[];
  isOpen: boolean;
  onOpenChange: () => void;
  afterSave: () => void;
}

export const IndustryRiskEditModal = (
  props: IndustryRiskModalProps
): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [modalIndustries, setModalIndustries] = useState<IndustryRisk[]>([]);
  const [deltaIndustries, setDeltaIndustries] = useState<IndustryRisk[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();

  useEffect(() => setModalIndustries(props.industries), [props.industries]);

  const handleError = (e: unknown) => {
    const err = toError(e);
    setError(err.toString());
  };

  const handleChangeSelected = (name: string, newValue: string) => {
    const updatedIndustry = modalIndustries.find((i) => i.name === name);

    if (updatedIndustry) {
      // if archived then we will set the flag to archived, we will not update the pricing_risk_profile
      if (newValue === 'archived') {
        updatedIndustry.archived = true;
      } else {
        updatedIndustry.pricing_risk_profile = newValue;
        updatedIndustry.archived = false;
      }

      // update the delta list, remove any entries that are the new changed value so that we do not send more than one request for each industry risk
      setDeltaIndustries((filteredIndustries) => [
        ...filteredIndustries.filter((i) => i.name !== updatedIndustry.name),
        updatedIndustry,
      ]);

      // now upate the modal so that it reflects the correct selection
      setModalIndustries((prevModalIndustries) =>
        prevModalIndustries.map((i: IndustryRisk) => {
          return i.name === name
            ? {
                ...i,
                pricing_risk_profile: newValue,
              }
            : i;
        })
      );
    }
  };

  const handleOpenChange = (): void => {
    if (props.isOpen) {
      // clear any success or error messages we may have set
      setSuccess('');
      setError('');
    }
    props.onOpenChange();
  };

  const saveIndustryRisks = async () => {
    try {
      setIsProcessing(true);
      setSuccess('');
      await Promise.all(deltaIndustries.map(updateIndustryRisk));
      setDeltaIndustries([]);
      setSuccess('Industry Risk profiles updated!');
      props.afterSave();
    } catch (err) {
      handleError(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      title="Industry Risk"
      isOpen={props.isOpen}
      onOpenChange={handleOpenChange}
      trigger={<></>}
      body={
        <>
          <RadioGrid
            questions={modalIndustries.map((industry) => ({
              name: industry.name,
              label: industry.name,
              value: industry.archived
                ? 'archived'
                : industry.pricing_risk_profile,
            }))}
            onValueChange={handleChangeSelected}
            options={industryRiskColors.map(
              (r: IndustryRiskColorInterface) => ({
                value: r.name,
                text: r.displayName,
                selectedColor: r.color,
              })
            )}
          />

          {error && (
            <Flex justifyContent="center">
              <Banner>{error}</Banner>
            </Flex>
          )}
          {success && (
            <Flex justifyContent="center">
              <Text color="status">{success}</Text>
            </Flex>
          )}

          <Flex gap={4} paddingTop={12} justifyContent="center">
            <Button
              startIcon="check"
              onClick={() => {
                saveIndustryRisks().catch((err) => handleError(err));
              }}
              disabled={isProcessing}
            >
              Save
            </Button>
            <Button
              startIcon="x"
              variant="secondary"
              onClick={handleOpenChange}
            >
              Cancel
            </Button>
          </Flex>
        </>
      }
    />
  );
};
