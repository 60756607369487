import React from 'react';
import { Box, Text } from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { StipulationStatus as StipulationStatusType } from './stipulationManager.types';

export interface StipulationStatusProps {
  stipulationStatus: StipulationStatusType;
}

export const getStatusColor = (
  stipulationStatus: StipulationStatusType
): ColorVariant => {
  switch (stipulationStatus) {
    case 'not_received':
      return 'gray-800';
    case 'received':
      return 'gray-600';
    case 'pending':
      return 'gray-400';
    case 'accepted':
      return 'green-300';
    case 'rejected':
      return 'red-300';
    case 'uw_sign_off':
      return 'purple-100';
    case 'waived':
      return 'orange-200';
    case 'rejected_and_reprice':
      return 'blue-100';
    case 'manual_review':
      return 'yellow-300';
    // istanbul ignore next: technically unreachable
    default:
      return 'gray-400';
  }
};

export const sanitizeStipulationStatus = (status: string): string => {
  return status.replace(/_/g, ' ').toUpperCase();
};

export const StipulationStatus = ({
  stipulationStatus,
}: StipulationStatusProps): JSX.Element => {
  return (
    <Box padding={1} backgroundColor={getStatusColor(stipulationStatus)}>
      <Text size="s">{sanitizeStipulationStatus(stipulationStatus)}</Text>
    </Box>
  );
};
