import React from 'react';
import { Box, Divider, Flex, Text } from '@forward-financing/fast-forward';
import { UnderwriterScoring } from './UnderwriterScoring/UnderwriterScoring';
import { ManagerScoring } from './ManagerScoring/ManagerScoring';
import { useNewDealScoringContext } from './NewDealScoringContext';

export const NewDealScoringEditor = (): JSX.Element => {
  const {
    isUnscored,
    hasUnsavedManagerChanges,
    hasUnsavedUnderwriterChanges,
    isPartialSave,
    response,
    isAutosaving,
  } = useNewDealScoringContext();

  const hasUnsavedChanges =
    hasUnsavedManagerChanges || hasUnsavedUnderwriterChanges;

  if (!response) {
    return <></>;
  }

  return (
    <>
      <Box>
        <UnderwriterScoring />
        {!isUnscored && (
          <>
            <Divider />
            <ManagerScoring />
          </>
        )}
      </Box>
      {isAutosaving && (
        <Flex mt={3} alignItems="center" justifyContent="center">
          <Text color="default">Autosaving...</Text>
        </Flex>
      )}
      {hasUnsavedChanges && !isAutosaving && (
        <Flex mt={3} alignItems="center" justifyContent="center">
          <Text color="danger">You have unsaved changes.</Text>
        </Flex>
      )}
      {isPartialSave && !isAutosaving && (
        <Flex mt={3} alignItems="center" justifyContent="center">
          <Text color="danger">The score is incomplete.</Text>
        </Flex>
      )}
    </>
  );
};
