import {
  createExceptionRequest,
  declineExceptionRequest,
  fetchSubmissionExceptionRequests,
  updateExceptionRequest,
} from 'api/banking/exceptionRequestFetchUtils';
import {
  useGenericMutation,
  useGenericQuery,
  UseGenericQueryResponse,
  UseGenericMutationResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import {
  CreateUnderwritingExceptionRequestInput,
  DeclineExceptionRequestRequestBody,
  UnderwritingExceptionRequestsResponse,
  UpdateUnderwritingExceptionRequestInput,
} from 'types/api/banking/types';

export type UseApiExceptionRequestsResponse =
  UseGenericQueryResponse<UnderwritingExceptionRequestsResponse>;

export const useApiExceptionRequests = (
  submissionUuid?: string
): UseApiExceptionRequestsResponse => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionExceptionRequests(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export interface UseCreateExceptionRequestArgs {
  submissionUuid: string;
  createBody: CreateUnderwritingExceptionRequestInput;
}

export type UseCreateExceptionRequestResponse = UseGenericMutationResponse<
  { success: boolean },
  UseCreateExceptionRequestArgs
>;

const createExceptionRequestWithArgs = async (
  args: UseCreateExceptionRequestArgs
): Promise<{ success: boolean }> =>
  createExceptionRequest(args.submissionUuid, args.createBody);

export const useApiCreateExceptionRequests =
  (): UseCreateExceptionRequestResponse => {
    return useGenericMutation(createExceptionRequestWithArgs);
  };

export interface UseUpdateExceptionRequestArgs {
  exceptionRequestId: number;
  updateBody: UpdateUnderwritingExceptionRequestInput;
}

export type UseUpdateExceptionRequestResponse = UseGenericMutationResponse<
  { success: boolean },
  UseUpdateExceptionRequestArgs
>;

const updateExceptionRequestWithArgs = async (
  args: UseUpdateExceptionRequestArgs
): Promise<{ success: boolean }> =>
  updateExceptionRequest(args.exceptionRequestId, args.updateBody);

export const useApiUpdateExceptionRequests =
  (): UseUpdateExceptionRequestResponse => {
    return useGenericMutation(updateExceptionRequestWithArgs);
  };

export interface UseDeclineExceptionRequestArgs {
  exceptionRequestId: number;
  requestBody: DeclineExceptionRequestRequestBody;
}

export type UseDeclineExceptionRequestResponse = UseGenericMutationResponse<
  { success: boolean },
  UseDeclineExceptionRequestArgs
>;

const declineExceptionRequestWithArgs = async (
  args: UseDeclineExceptionRequestArgs
): Promise<{ success: boolean }> =>
  declineExceptionRequest(args.exceptionRequestId, args.requestBody);

export const useApiDeclineExceptionRequest =
  (): UseDeclineExceptionRequestResponse => {
    return useGenericMutation(declineExceptionRequestWithArgs);
  };
