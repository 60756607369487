import React from 'react';

export interface WebPresenceBorderedContainerProps {
  children: React.ReactNode | React.ReactNode[];
  label: string;
}

export const WebPresenceBorderedContainer = (
  props: WebPresenceBorderedContainerProps
): JSX.Element => {
  return (
    <div className="web-presence-container-bordered columns">
      <div className="web-presence-content column">
        <div className="control has-text-centered">
          <label className="label border-text">{props.label}</label>
        </div>
        <div className="columns">
          <div className="column">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
