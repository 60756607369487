import { defaultTo } from 'lodash';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import {
  useGetApiLexisNexisCustomerOverviews,
  useGetApiLexisNexisCustomerReports,
} from 'apiHooks/underwriting/lexisNexisCustomerFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { toLienJudgment } from 'components/LiensTable/liensTableUtils';
import {
  BankruptcyRecordResponse,
  LexisNexisCustomerOverview,
  LexisNexisCustomerReport,
} from 'types/api/underwriting/types';
import { BankruptcyRecord } from 'components/SubmissionUnderwriting/OwnerOverview/types';
import { LexisNexisCustomerPublicDocument } from './types';

export const toBankruptcies = (
  bankruptcy: BankruptcyRecordResponse
): BankruptcyRecord => ({
  chapter: bankruptcy.chapter,
  filingDate: bankruptcy.filing_date,
  exitDate: bankruptcy.exit_date,
  filedAs: bankruptcy.filed_as,
  statuses: bankruptcy.statuses,
});

const toBusinessCustomerPublicDocument = (
  doc: LexisNexisCustomerReport
): LexisNexisCustomerPublicDocument => {
  return {
    documentId: doc.document_id,
    createdAt: doc.created_at,
    primary: doc.primary,
    companyName: defaultTo(doc.company_name, undefined),
    lienJudgments: doc.lien_judgments.map(toLienJudgment),
    reportIdentifier: defaultTo(doc.report_identifier, undefined),
    bankruptcies: defaultTo(doc.bankruptcies?.map(toBankruptcies), []),
  };
};

export const useBusinessCustomerReports = (
  submissionUuid: string
): UseGenericQueryResponse<LexisNexisCustomerPublicDocument[]> => {
  return useGenericFeatureQuery(
    useGetApiLexisNexisCustomerReports,
    (data) => data?.documents.map(toBusinessCustomerPublicDocument),
    submissionUuid
  );
};

export type BusinessCustomerOverviews = LexisNexisCustomerOverview[];

export const useBusinessCustomerOverviews = (
  submissionUuid: string
): UseGenericQueryResponse<BusinessCustomerOverviews> => {
  return useGenericFeatureQuery(
    useGetApiLexisNexisCustomerOverviews,
    (data) => data,
    submissionUuid
  );
};
