import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { BankingAdditionalDataResponse } from 'types/api/banking/types';

export const fetchSubmission = async (
  submissionUuids: string[]
): Promise<BankingAdditionalDataResponse> => {
  const url = new URL(`/api/v2/submissions`, BANK_BASE_URL());
  submissionUuids.forEach((id) => {
    url.searchParams.append('opportunity_uuids[]', id);
  });
  url.search = decodeURIComponent(url.search);

  const response = await makeInternalAPIRequest<BankingAdditionalDataResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Failed to fetch Additional Banking Data`
    );
  }

  return response.json();
};
