import React, { useEffect } from 'react';
import { LivePipeline } from './LivePipeline';
import { useNavigate } from 'react-router-dom';
import { isUnderwriter } from 'helpers/utils';
import { useUserContext } from 'contexts/UserContext';

export const LiveUnderwritingPipelineContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const user = useUserContext();
  const currentUserUnderwriter = isUnderwriter(user);

  useEffect(() => {
    if (!currentUserUnderwriter) {
      navigate('../live-pipeline', { replace: true });
    }
  }, [currentUserUnderwriter, navigate]);

  return <LivePipeline />;
};
