import React from 'react';
import { Banner, Flex, PageContainer } from '@forward-financing/fast-forward';
import { OwnerManualSearchForm } from './OwnerManualSearchForm';
import { OwnerManualSearchTable } from './OwnerManualSearchTable';
import { useGetLexisNexisManualSearchResult } from './ownerManualSearchHooks';
import { useParams } from 'react-router';

type Params = {
  submissionUuid: string;
  ownerUuid: string;
};

export const OwnerManualSearchPage = (): JSX.Element => {
  const { ownerUuid, submissionUuid } = useParams<Params>();

  const [fetcher, { data, loading, error, responseReady }] =
    useGetLexisNexisManualSearchResult();

  return (
    <PageContainer>
      <Flex mt={4} rounded flexDirection={'column'}>
        {error?.message && <Banner>{error.message}</Banner>}
        <OwnerManualSearchForm
          fetcher={fetcher}
          ownerUuid={ownerUuid}
          submissionUuid={submissionUuid}
        />
        {/* render table only if search request has been sent */}
        {(responseReady || loading) && (
          <OwnerManualSearchTable
            loading={loading}
            results={data}
            ownerUuid={ownerUuid}
            submissionUuid={submissionUuid}
          />
        )}
      </Flex>
    </PageContainer>
  );
};
