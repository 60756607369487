import * as React from 'react';

import { StepStatus, WizardSteps, StepProgress } from '../utils/types';
import { Flex, Icon, IconProps, Text } from '@forward-financing/fast-forward';

interface StepButtonProps {
  stepProgress: StepProgress;
  selectStep: (step: WizardSteps) => void;
}

const getIconName = (step: WizardSteps): IconProps['name'] => {
  switch (step) {
    case WizardSteps.AccountInformation:
      return 'store';
    case WizardSteps.OwnerInformation:
      return 'id-card';
    default:
      return 'layer-group';
  }
};

const getIconLabel = (step: WizardSteps): string => {
  switch (step) {
    case WizardSteps.AccountInformation:
      return 'Account';
    case WizardSteps.OwnerInformation:
      return 'Owner';
    default:
      return 'Additional Info';
  }
};

export const StepButton = ({
  stepProgress: { step, status },
  selectStep,
}: StepButtonProps): JSX.Element => {
  const handleClick = () => {
    if (status === StepStatus.Completed) {
      selectStep(step);
    }
  };

  return (
    <div className="level-item has-text-centered">
      <Flex flexDirection="column">
        <div
          className={`button transparent-button-white title ${
            status === StepStatus.Inactive ? 'disabled' : ''
          }`}
          onClick={handleClick}
          data-testid="button-clickable-container"
        >
          <Icon
            name={getIconName(step)}
            color="primary"
            size={status === StepStatus.Active ? '2x' : undefined}
          />
        </div>
        <Text bold>{getIconLabel(step)}</Text>
      </Flex>
    </div>
  );
};
