import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { Contact } from 'api/UnderwritingClient/codecs';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { ContactInput, ContactResponse } from './OwnerInformation.types';

const contactToInput = (contact: Contact): ContactInput => ({
  first_name: contact.first_name,
  last_name: contact.last_name,
  ssn: contact.ssn,
  email: contact.email,
  home_phone: contact.home_phone,
  cell_phone: contact.cell_phone,
  born_on: contact.born_on,
  title: contact.title,
  ownership_percentage: contact.ownership_percentage || null,
  address: contact.address,
});

const responseToContact = (response: ContactResponse): Contact => ({
  id: response.id,
  uuid: response.uuid || undefined,
  owner_id: response.owner_id || undefined,
  first_name: response.first_name,
  last_name: response.last_name,
  ssn: response.ssn,
  ssnNotPresent: !response.ssn,
  email: response.email || '',
  home_phone: response.home_phone,
  cell_phone: response.cell_phone,
  born_on: response.born_on,
  bornOnNotPresent: !response.born_on,
  title: response.title || '',
  ownership_percentage: Number.parseInt(response.ownership_percentage || '0'),
  address: response.address,
});

export const createContact = (
  contact: Contact,
  applicationUuid: string
): Promise<Contact> => {
  const url = new URL(
    `/api/v2/applications/${applicationUuid}/contacts`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<ContactResponse, ContactInput>(
    url,
    'POST',
    contactToInput(contact)
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 403) {
        throw new NetworkError(
          response.status,
          'You are not authorized to create contacts.'
        );
      } else if (response.status === 500) {
        throw new NetworkError(
          response.status,
          'Something went wrong. Please wait a moment and try again. If the issue persists, please contact the engineering team.'
        );
      }

      throw new NetworkError(
        response.status,
        'Error creating the Contact. Please reload the page and try again.'
      );
    })
    .then(responseToContact);
};

export const updateContact = (contact: Contact): Promise<Contact> => {
  const url = new URL(
    `/api/v2/contacts/${contact.uuid}`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<ContactResponse, ContactInput>(
    url,
    'PATCH',
    contactToInput(contact)
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 403) {
        throw new NetworkError(
          response.status,
          'You are not authorized to update contacts.'
        );
      } else if (response.status === 404) {
        throw new NetworkError(response.status, 'Contact not found.');
      } else if (response.status === 500) {
        throw new NetworkError(
          response.status,
          'Something went wrong. Please wait a moment and try again. If the issue persists, please contact the engineering team.'
        );
      }

      throw new NetworkError(
        response.status,
        'Error updating the Contact. Please reload the page and try again.'
      );
    })
    .then(responseToContact);
};

export const deleteContact = (contact: Contact): Promise<void> => {
  const url = new URL(
    `/api/v2/contacts/${contact.uuid}`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<ContactResponse>(url, 'DELETE').then(
    (response) => {
      if (!response.ok) {
        throw new NetworkError(
          response.status,
          'Something went wrong removing the Contact. Please try again.'
        );
      }
    }
  );
};
