import React from 'react';

import { SectionHeader } from '../RenewalComparison.types';
import { ChangeComparison } from '../ChangeComparison';
import { displayPercentage } from '../../../helpers/utils';
import { Currency, Table } from '@forward-financing/fast-forward';
import { TableCellWithDefault } from './TableCellWithDefault';
import { RenewalComparisonData } from 'types/api/underwriting/types';

interface RevenueOverrideProps {
  currentValue: number;
  previousValue: number | undefined | null;
}

const RevenueOverride = ({
  currentValue,
  previousValue,
}: RevenueOverrideProps): JSX.Element => {
  if (typeof previousValue === 'number') {
    return (
      <ChangeComparison
        currentValue={currentValue}
        previousValue={previousValue}
      />
    );
  }
  return <Currency amount={currentValue} />;
};

export interface CashFlowSectionProps {
  data: RenewalComparisonData<'cash_flow_data' | 'summary_data'>;
  headers: SectionHeader;
}

export const CashFlowSection = ({
  data,
  headers,
}: CashFlowSectionProps): JSX.Element => {
  const {
    current: oppCurrent,
    previous_1: oppPrev1,
    previous_2: oppPrev2,
  } = data;

  const current = oppCurrent.cash_flow_data;
  const previous_1 = oppPrev1?.cash_flow_data;
  const previous_2 = oppPrev2?.cash_flow_data;

  return (
    <>
      {current && (
        <Table caption="Cash Flow">
          <Table.Head>
            <Table.ColumnHeader>Cash Flow</Table.ColumnHeader>
            <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
            <Table.ColumnHeader>{headers.previous_1}</Table.ColumnHeader>
            {headers.previous_2 && (
              <Table.ColumnHeader>{headers.previous_2}</Table.ColumnHeader>
            )}
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Revenue Override</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.revenue_override === 'number' ? (
                  <RevenueOverride
                    currentValue={current.revenue_override}
                    previousValue={previous_1?.revenue_override}
                  />
                ) : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.revenue_override === 'number' ? (
                  <RevenueOverride
                    currentValue={previous_1.revenue_override}
                    previousValue={previous_2?.revenue_override}
                  />
                ) : undefined}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2?.revenue_override === 'number' ? (
                    <Currency amount={previous_2.revenue_override} />
                  ) : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Average Negative Days</Table.RowHeader>
              <TableCellWithDefault>
                {current.average_negative_days}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.average_negative_days}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {previous_2?.average_negative_days}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>ADB</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.average_daily_balance === 'number' ? (
                  <Currency amount={current.average_daily_balance} />
                ) : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.average_daily_balance === 'number' ? (
                  <Currency amount={previous_1.average_daily_balance} />
                ) : undefined}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2?.average_daily_balance === 'number' ? (
                    <Currency amount={previous_2.average_daily_balance} />
                  ) : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader># Current Positions</Table.RowHeader>
              <TableCellWithDefault>
                {current.number_of_positions}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.number_of_positions}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {previous_2?.number_of_positions}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Gross % of Current Positions</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.gross_of_positions === 'number'
                  ? displayPercentage(current.gross_of_positions)
                  : current.gross_of_positions}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.gross_of_positions === 'number'
                  ? displayPercentage(previous_1.gross_of_positions)
                  : previous_1?.gross_of_positions}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2?.gross_of_positions === 'number'
                    ? displayPercentage(previous_2.gross_of_positions)
                    : previous_2?.gross_of_positions}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>FF Gross %</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.ff_percent_of_gross === 'number'
                  ? displayPercentage(current.ff_percent_of_gross)
                  : current.ff_percent_of_gross}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.ff_percent_of_gross === 'number'
                  ? displayPercentage(previous_1?.ff_percent_of_gross)
                  : previous_1?.ff_percent_of_gross}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2?.ff_percent_of_gross === 'number'
                    ? displayPercentage(previous_2?.ff_percent_of_gross)
                    : previous_2?.ff_percent_of_gross}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Total Gross %</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.total_gross_percent === 'number'
                  ? displayPercentage(current.total_gross_percent)
                  : current.total_gross_percent}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.total_gross_percent === 'number'
                  ? displayPercentage(previous_1?.total_gross_percent)
                  : previous_1?.total_gross_percent}
              </TableCellWithDefault>
              {headers.previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2?.total_gross_percent === 'number'
                    ? displayPercentage(previous_2?.total_gross_percent)
                    : previous_2?.total_gross_percent}
                </TableCellWithDefault>
              )}
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
