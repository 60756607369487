import * as React from 'react';
import { StepButton } from './StepButton';
import { WizardSteps, StepProgress, StepStatus } from '../utils/types';
import { FaqModal } from '../FaqModal';
import { Box } from '@forward-financing/fast-forward';

interface Props {
  stepProgresses: StepProgress[];
  selectStep: (step: WizardSteps) => void;
}

export const WizardProgress = (props: Props): JSX.Element => {
  const showFaqModal =
    props.stepProgresses[0].step === WizardSteps.AccountInformation &&
    props.stepProgresses[0].status === StepStatus.Active;

  return (
    <Box>
      {showFaqModal && <FaqModal />}
      <div className="columns is-centered wizard-progress">
        <nav className="level">
          {props.stepProgresses.map((stepProgress) => (
            <StepButton
              key={stepProgress.step}
              stepProgress={stepProgress}
              selectStep={props.selectStep}
            />
          ))}
        </nav>
      </div>
    </Box>
  );
};
