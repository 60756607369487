import { Banner, Flex, Grid } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationSnapshotContainer } from './ApplicationSnapshot/ApplicationSnapshotContainer';
import { FraudScoringContainer } from './FraudScoring/FraudScoringContainer';
import { RiskFactorsContainer } from './RiskFactors/RiskFactorsContainer';
import { SubmissionEmailContentContainer } from './SubmissionEmailContent/SubmissionEmailContentContainer';
import { useGetSubmission } from './SubmissionUnderwritingContainerHooks';

type Params = {
  submissionUuid: string;
};

export const ApplicationSnapshotRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const { data: submission, error: errorSubmission } =
    useGetSubmission(submissionUuid);

  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false} variant="error">
          {errorSubmission.message}
        </Banner>
      )}
      <Grid gutter>
        <Grid.Item m={6}>
          {submission && (
            <ApplicationSnapshotContainer submission={submission} />
          )}
        </Grid.Item>
        <Grid.Item m={6}>
          <Flex gap={3} flexDirection="column">
            {submission && (
              <SubmissionEmailContentContainer submission={submission} />
            )}
            <FraudScoringContainer submission={submission} />
            <RiskFactorsContainer submissionUuid={submissionUuid} />
          </Flex>
        </Grid.Item>
      </Grid>
    </>
  );
};
