import {
  Autocomplete,
  Banner,
  Box,
  Button,
  Divider,
  Flex,
  Form,
  Grid,
  Icon,
  Loading,
  MaskedTextInput,
  Select,
  Subheading,
  Text,
  TextInput,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { z } from 'zod';
import { isEqual } from 'lodash';
import { isPriorToApproval } from 'helpers/utils';
import {
  addressHasNoSpecialChar,
  isNumericString,
  textHasNoSpecialChar,
} from 'helpers/validations/ValidationHelpers';
import { validateZipCode } from 'helpers/validations/FieldValidator';
import { useUserContext } from 'contexts/UserContext';
import { BankAccount, FormUpdateState } from './SubmissionEditing.types';
import {
  useBankNames,
  useCreateBankName,
  useGetStates,
  useSendLogs,
  useUpdateCustomer,
} from './SubmissionEditingHooks';

type BankAccountSectionFormProps = {
  customerUuid: string;
  bankAccount?: BankAccount;
  submissionStage?: string;
  setFormStatus: (value: FormUpdateState) => void;
  formStatus: FormUpdateState;
};

const initializedBankAccount = (): BankAccount => {
  return {
    id: undefined,
    accountHolderFullName: '',
    accountNumber: '',
    routingNumber: '',
    bankId: '',
    bankName: '',
    city: '',
    zip: '',
    state: '',
  };
};

const sanitizeAccountNumber = (accountNumber: string): string => {
  return accountNumber.replace(/[^0-9]/g, '');
};

export const BankAccountSectionForm = ({
  bankAccount,
  customerUuid,
  submissionStage,
  setFormStatus,
  formStatus,
}: BankAccountSectionFormProps): JSX.Element => {
  const isBankAccountPersisted = bankAccount?.id !== undefined;

  const [bankAccountForm, setBankAccountForm] = useState<BankAccount>(
    bankAccount || initializedBankAccount()
  );

  const [revealAccountNumber, setRevealAccountNumber] = useState(
    bankAccount?.id === undefined || !bankAccount?.accountNumber
  );

  const {
    first_name: firstName,
    last_name: lastName,
    uuid: userUuid,
    email: userEmail,
  } = useUserContext();

  const [successfulBankNameCreation, setSuccessfulBankNameCreation] =
    useState<FormUpdateState>();

  const { data: bankNames, error: bankNamesError } = useBankNames();

  const { data: states, error: statesError } = useGetStates();
  const [createBankName, { error: createBankNameError }] = useCreateBankName();

  const [sendLogs, { error: errorLogs }] = useSendLogs();

  const [
    updateBankAccount,
    { error: updateCustomerError, loading: updateCustomerLoading },
  ] = useUpdateCustomer(customerUuid);

  const bankAccountFormSchema = z.object({
    accountHolderFullName: z.string().optional(),
    accountNumber: z
      .string()
      .optional()
      .refine(
        (accountNumber) =>
          accountNumber ? isNumericString(accountNumber) : true, // Account Number is optional;
        {
          message: 'Account Number must be a number.',
        }
      ),
    routingNumber: z
      .string()
      .optional()
      .refine(
        (routingNumber) => {
          if (bankAccount?.routingNumber || routingNumber) {
            return routingNumber ? isNumericString(routingNumber) : false;
          } else {
            return true; // Routing Number is optional if bankAccount.routingNumber is not present
          }
        },
        {
          message: 'Routing Number must be a number.',
        }
      )
      .refine(
        (routingNumber) => {
          if (bankAccount?.routingNumber || routingNumber) {
            return routingNumber ? routingNumber.length === 9 : false;
          } else {
            return true; // Routing Number is optional if bankAccount.routingNumber is not present
          }
        },
        { message: 'Routing Number must be 9 digits.' }
      ),
    bankName: z
      .string()
      .optional()
      .refine((name) => textHasNoSpecialChar(name), {
        message:
          "Bank Name can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), and spaces.",
      }),
    city: z
      .string()
      .optional()
      .refine((city) => addressHasNoSpecialChar(city), {
        message:
          "City can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), number signs (#), and spaces.",
      }),
    state: z.string().optional(),
    zip: z
      .string()
      .optional()
      .refine((zip) => (zip ? validateZipCode(zip).isValid : true), {
        message: 'Invalid Zip Code.',
      }),
  });

  type BankAccountSchemaError = Partial<
    z.inferFormattedError<typeof bankAccountFormSchema>
  >;

  const [bankAccountErrors, setBankAccountErrors] =
    useState<BankAccountSchemaError>({});

  const isValidBankAccount = (): boolean => {
    const validationResult = bankAccountFormSchema.safeParse(bankAccountForm);

    if (validationResult.success) {
      setBankAccountErrors({});
    } else {
      setBankAccountErrors(validationResult.error.format());
    }

    return validationResult.success;
  };

  const handleUpdateBankAccount = async (): Promise<void> => {
    if (!isValidBankAccount()) {
      return;
    }

    setFormStatus(undefined);
    const { success } = await updateBankAccount({
      bankAccount: bankAccountForm,
    });
    success && setFormStatus('updated');
  };

  const handleCreateBankName = async (): Promise<void> => {
    if (bankAccountForm?.bankName) {
      const { success } = await createBankName(bankAccountForm.bankName);
      success && setSuccessfulBankNameCreation('updated');
    }
  };

  const handleBankAccountFormOnchange = (
    newVal: string | number,
    field: keyof BankAccount
  ): void => {
    formStatus !== 'unsaved' && setFormStatus('unsaved');
    const updatedForm = { ...bankAccountForm, [field]: newVal };
    if (isEqual(updatedForm, bankAccount)) {
      setFormStatus(undefined);
    }
    setBankAccountForm(updatedForm);
  };

  const renderVisibilityButton = (
    field: string,
    revealButtonState: boolean,
    setStateFunction: React.Dispatch<React.SetStateAction<boolean>>
  ): JSX.Element => {
    if (!isBankAccountPersisted) {
      return <></>;
    }
    return revealButtonState ? (
      <TextInput.IconButton
        description={`Hide ${field}`}
        onClick={() => setStateFunction(false)}
        icon={'eye-slash'}
      />
    ) : (
      <TextInput.IconButton
        description={`Show ${field}`}
        onClick={() => revealSensitiveValue(field, setStateFunction)}
        icon={'eye'}
      />
    );
  };

  const revealSensitiveValue = async (
    field: string,
    setStateFuction: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> => {
    const { success } = await sendLogs({
      fieldName: field,
      name: `${firstName} ${lastName}`,
      action: 'show',
      userUuid: userUuid,
      userEmail: userEmail,
      source: 'SubmissionEditing',
      timestamp: formatDateTimeString(new Date().toDateString()),
      objectType: 'Customer Bank Account',
      objectUuid: customerUuid,
    });

    success && setStateFuction(true);
  };

  const bankNamesAsSelectableOption = bankNames?.map((val) => ({
    value: val.name,
  }));

  const submissionIsPriorToApproval =
    submissionStage && isPriorToApproval(submissionStage);

  const shouldShowAddButton =
    !bankNames?.some((bank) => bank.name === bankAccountForm?.bankName) &&
    bankAccountForm?.bankName;

  const submissionHasBeenFunded = submissionStage === 'Funded';

  const isAccountNumberFieldDisabled =
    !revealAccountNumber &&
    (bankAccountForm?.accountNumber !== '' ||
      bankAccountForm.accountNumber !== undefined);

  return (
    <Box>
      <Subheading>Bank Account Information</Subheading>

      <Divider margin={4} />
      {updateCustomerError && (
        <Banner dismissable>{updateCustomerError.message}</Banner>
      )}

      {errorLogs && <Banner dismissable>{errorLogs.message}</Banner>}

      {createBankNameError && (
        <Banner dismissable>{createBankNameError.message}</Banner>
      )}

      {statesError && <Banner dismissable>{statesError?.message}</Banner>}

      {bankNamesError && <Banner dismissable>{bankNamesError?.message}</Banner>}
      {formStatus === 'unsaved' && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      <Form
        accessibleName="Edit Bank Account Information"
        onSubmit={handleUpdateBankAccount}
        allowImplicitSubmission={false}
      >
        {({ fireSubmit }) => (
          <Grid gutter>
            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                {submissionIsPriorToApproval && (
                  <TextInput
                    label="Full Name on Account"
                    value={bankAccountForm?.accountHolderFullName}
                    onValueChange={(newVal) =>
                      handleBankAccountFormOnchange(
                        newVal,
                        'accountHolderFullName'
                      )
                    }
                    errorMessage={bankAccountErrors?.accountHolderFullName?._errors.join(
                      ' '
                    )}
                  />
                )}

                <TextInput
                  label="Account Number"
                  value={
                    revealAccountNumber ? bankAccountForm?.accountNumber : ''
                  }
                  onValueChange={(newVal) =>
                    !isAccountNumberFieldDisabled &&
                    handleBankAccountFormOnchange(
                      sanitizeAccountNumber(newVal),
                      'accountNumber'
                    )
                  }
                  type="number"
                  disabled={
                    submissionHasBeenFunded || isAccountNumberFieldDisabled
                  }
                  placeholder={revealAccountNumber ? '' : 'XXXXXXXXX'}
                  afterInputContent={
                    bankAccountForm.accountNumber !== '' &&
                    renderVisibilityButton(
                      'Account Number',
                      revealAccountNumber,
                      setRevealAccountNumber
                    )
                  }
                  errorMessage={bankAccountErrors?.accountNumber?._errors.join(
                    ' '
                  )}
                />
              </Flex>
            </Grid.Item>

            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                {submissionIsPriorToApproval && (
                  <Flex flexDirection={'column'}>
                    <Autocomplete
                      label="Bank Name"
                      name="bank_name"
                      items={bankNamesAsSelectableOption || []}
                      onValueChange={(newVal) =>
                        handleBankAccountFormOnchange(newVal, 'bankName')
                      }
                      value={bankAccountForm?.bankName || ''}
                      errorMessage={bankAccountErrors?.bankName?._errors.join(
                        ' '
                      )}
                    />

                    {shouldShowAddButton && !successfulBankNameCreation && (
                      <Button startIcon={'plus'} onClick={handleCreateBankName}>
                        Add Bank
                      </Button>
                    )}
                  </Flex>
                )}
                {!submissionIsPriorToApproval && (
                  <TextInput
                    label="Routing Number"
                    value={bankAccountForm?.routingNumber}
                    onValueChange={(newVal) =>
                      isNumericString(newVal) &&
                      handleBankAccountFormOnchange(newVal, 'routingNumber')
                    }
                    disabled={submissionHasBeenFunded}
                    errorMessage={bankAccountErrors?.routingNumber?._errors.join(
                      ' '
                    )}
                  />
                )}

                {submissionIsPriorToApproval && (
                  <>
                    <TextInput
                      label="City"
                      value={bankAccountForm?.city}
                      onValueChange={(newVal) =>
                        handleBankAccountFormOnchange(newVal, 'city')
                      }
                      errorMessage={bankAccountErrors?.city?._errors.join(' ')}
                    />

                    <Select
                      label="State"
                      options={states || []}
                      value={bankAccountForm?.state || ''}
                      onValueChange={(newVal) =>
                        handleBankAccountFormOnchange(newVal, 'state')
                      }
                      errorMessage={bankAccountErrors?.state?._errors.join(' ')}
                    />

                    <MaskedTextInput
                      label="Zip Code"
                      value={bankAccountForm.zip}
                      onValueChange={(newVal) =>
                        handleBankAccountFormOnchange(newVal.value, 'zip')
                      }
                      format={
                        bankAccountForm.zip && bankAccountForm.zip.length > 5
                          ? '#####-####'
                          : '#########'
                      }
                      type="number"
                      mask={''}
                      errorMessage={bankAccountErrors?.zip?._errors.join(' ')}
                    />
                  </>
                )}
              </Flex>
            </Grid.Item>

            <Grid.Item>
              <Flex gap={3} justifyContent={'right'}>
                {formStatus === 'updated' && (
                  <Icon size="2x" name="check-circle" />
                )}
                {updateCustomerLoading && <Loading />}

                <Button onClick={fireSubmit} disabled={updateCustomerLoading}>
                  Save Bank Account
                </Button>
              </Flex>
            </Grid.Item>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
