import React, { useState, useEffect } from 'react';

import {
  BankInfo,
  Bank,
  UsState,
} from '../../../api/UnderwritingClient/codecs';
import { HTMLFormChangeOrMouseEvent } from '../../../types/form';

// Components
import { BorderedContainer } from '../../shared/BorderedContainer';
import { FormRow } from '../../shared/form/FormRow';
import { Input } from '../../shared/form/Input';
import { BankInformation } from './BankInformation';
import { sanitizeTargetValue } from '../../../helpers/utils';
import { ValidationResult } from '../../../helpers/validations/codecs';
import { HashMap } from '../../../api/codecs';
import {
  addressSpecialCharValidationMessage,
  specialCharValidationMessage,
  validate,
  validateZipCode,
  validField,
} from '../../../helpers/validations/FieldValidator';
import {
  addressHasNoSpecialChar,
  textHasNoSpecialChar,
  isRoutingNumber,
} from '../../../helpers/validations/ValidationHelpers';
import { LogData } from '../../../api/AnalyticsGatewayClient/codecs';

export interface BankInformationFormProps {
  bankAccount: BankInfo;
  bankError: boolean;
  setBankError: (value: boolean) => void;
  usStates: UsState[];
  storeBankAccount: (bankAccount: BankInfo) => void;
  fullBankInfoIsVisible: boolean;
  banks: Bank[];
  createBank: (bankName: string) => void;
  stageName: string;
  sendUnmaskedFieldLogs?: (data: LogData) => Promise<void>;
  accountUuid?: string;
}

export const BankInformationForm = (
  props: BankInformationFormProps
): JSX.Element => {
  const { storeBankAccount } = props;
  const [bankAccount, setBankAccount] = useState(props.bankAccount);
  const bankInfoIsEditable = props.stageName !== 'Funded';

  useEffect(() => {
    storeBankAccount(bankAccount);
  }, [bankAccount, storeBankAccount]);

  const handleBankInfoChange = (
    bankToChange: BankInfo
  ): ((e: HTMLFormChangeOrMouseEvent) => void) => {
    return (e) => {
      e.persist();
      const target = (
        e.currentTarget ? e.currentTarget : e.target
      ) as HTMLInputElement;
      const value = sanitizeTargetValue(target);

      const bank = {
        ...bankToChange,
        [target.name]: value,
      };

      setBankAccount(bank);
    };
  };

  const handleBankNameChange = (value: string) => {
    props.setBankError(false);
    const bankAccount = {
      ...props.bankAccount,
      bank_name: value,
    };
    setBankAccount(bankAccount);
  };

  const bankInfoValidationErrors = (): ValidationResult => {
    // Routing number is only optional when the account on the server does
    // not have a saved routing number. Once a routing number is saved, the
    // field is required.
    return bankAccount.routing_number
      ? {
          isValid: isRoutingNumber(bankAccount.routing_number || ''),
          hasValue: !!bankAccount.routing_number,
          validationMessage: 'Routing number must be a 9-digit number',
        }
      : validField;
  };

  const validationErrors = (
    bankAccount: BankInfo
  ): HashMap<ValidationResult> => ({
    account_holder: bankAccount.account_holder_full_name
      ? validate(
          [textHasNoSpecialChar],
          bankAccount.account_holder_full_name,
          `Full Name on Account ${specialCharValidationMessage}`
        )
      : validField,
    bank_name: bankAccount.bank_name
      ? validate(
          [textHasNoSpecialChar],
          bankAccount.bank_name,
          `Bank Name ${specialCharValidationMessage}`
        )
      : validField,
    city: bankAccount.city
      ? validate(
          [addressHasNoSpecialChar],
          bankAccount.city,
          `City ${addressSpecialCharValidationMessage}`
        )
      : validField,
    zip: bankAccount.zip ? validateZipCode(bankAccount.zip, false) : validField,
  });

  const handleSendLogs = async (field_name: string) => {
    await props.sendUnmaskedFieldLogs?.({
      field_name: field_name,
      object_uuid: props.accountUuid || '',
      name: props.bankAccount.account_holder_full_name || '',
      action: 'show',
      user_uuid: '',
      user_email: '',
      source: '',
      timestamp: '',
      object_type: 'Customer',
    });
  };

  return (
    <div className="bank-information-form">
      <BorderedContainer label="Bank Information">
        {props.fullBankInfoIsVisible ? (
          <BankInformation
            usStates={props.usStates}
            bankError={props.bankError}
            bank={props.bankAccount}
            banks={props.banks}
            createBank={props.createBank}
            handleBankNameChange={handleBankNameChange}
            handleBankInfoChange={handleBankInfoChange(props.bankAccount)}
            validationErrors={validationErrors(props.bankAccount)}
            handleSendLogs={handleSendLogs}
          />
        ) : (
          <div>
            <FormRow
              left={
                <Input
                  label="Account Number"
                  name="account_number"
                  onChange={handleBankInfoChange(props.bankAccount)}
                  value={props.bankAccount.account_number || ''}
                  isDisabled={!bankInfoIsEditable}
                  shouldBeMasked={true}
                  handleSendLogs={handleSendLogs}
                />
              }
              right={
                <Input
                  label="Routing Number"
                  name="routing_number"
                  onChange={handleBankInfoChange(props.bankAccount)}
                  value={props.bankAccount.routing_number || ''}
                  validationResult={bankInfoValidationErrors()}
                  isDisabled={!bankInfoIsEditable}
                />
              }
            />
          </div>
        )}
      </BorderedContainer>
    </div>
  );
};
