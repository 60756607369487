import React from 'react';
import { Banner } from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';

import {
  usePublicDocuments,
  useLexisNexisForOwners,
} from '../ownerOverviewHooks';
import { PublicRecordsPage } from './PublicRecordsPage';

type Params = {
  submissionUuid: string;
  ownerUuid: string;
};

export const PublicRecordsPageContainer = (): JSX.Element => {
  const { submissionUuid, ownerUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }

  if (!ownerUuid) {
    throw new Error('Owner UUID Param not set');
  }
  const { data: publicDocuments, error: publicDocumentsError } =
    usePublicDocuments(submissionUuid);

  const { data: publicRecordsOverviews, error: lexisNexisError } =
    useLexisNexisForOwners(submissionUuid);

  const ownerPublicDocuments =
    publicDocuments &&
    publicDocuments.filter((doc) => doc.ownerUuid === ownerUuid);

  const { publicRecords } = publicRecordsOverviews || {};

  const publicRecord =
    (publicRecords &&
      publicRecords?.find((pr) => pr.ownerUuid === ownerUuid)) ||
    null;

  return (
    <>
      {publicDocumentsError && (
        <Banner dismissable={false} variant="error">
          {publicDocumentsError.message}
        </Banner>
      )}
      {lexisNexisError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisError.message}
        </Banner>
      )}
      <PublicRecordsPage
        publicRecord={publicRecord}
        ownerPublicDocuments={ownerPublicDocuments}
      />
    </>
  );
};
