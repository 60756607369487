import { GIT_CODE_VERSION } from 'constants/globals';
import Rollbar from 'rollbar';
import { ROLLBAR_ACCESS_TOKEN, ROLLBAR_ENV } from '../constants';
import { Ilogger } from './types';
import { getEnvironment } from './loggerUtils';

/* istanbul ignore next */
const devLogger = (): Ilogger => ({
  info: (message: string | Error, data?: unknown) => {
    console.log(message, data); // eslint-disable-line no-console
  },
  error: (message: Error, data?: unknown) => {
    console.error(message, data); // eslint-disable-line no-console
  },
  warn: (message: string | Error, data?: unknown) => {
    console.warn(message, data); // eslint-disable-line no-console
  },
});

const mockLogger = (): Ilogger => ({
  info: () => {
    // Intentionally empty - this mockLogger is only used in unit tests.
  },
  error: () => {
    // Intentionally empty - this mockLogger is only used in unit tests.
  },
  warn: () => {
    // Intentionally empty - this mockLogger is only used in unit tests.
  },
});

const getLogger = (): Ilogger => {
  /* istanbul ignore next */
  if (getEnvironment() === 'development') {
    return devLogger();
  }

  /* istanbul ignore next */
  if (getEnvironment() === 'test') {
    return mockLogger();
  }

  return new Rollbar({
    accessToken: ROLLBAR_ACCESS_TOKEN(),
    captureUncaught: true, // report all uncaught exceptions to Rollbar by default
    captureUnhandledRejections: true,
    environment: ROLLBAR_ENV(),
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: GIT_CODE_VERSION(),
          guess_uncaught_frames: true,
        },
      },
    },
    // ignoreDuplicateErrors is true by default and avoids sending the same error from the same source many times in a row
    scrubFields: [
      'ssn',
      'social_security_number',
      'socialSecurityNumber',
      'born_on',
      'date_of_birth',
      'birth_date',
      'first_name',
      'firstName',
      'last_name',
      'lastName',
      'full_name',
      'fullName',
      'home_phone',
      'homePhone',
      'cell_phone',
      'cellPhone',
      'phone_number',
      'phoneNumber',
      'email',
      'email_address',
      'emailAddress',
    ],
  });
};

export const Logger = getLogger();
