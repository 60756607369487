import {
  UseDeleteStipulationArgs,
  useDeleteApiStipulation,
} from 'apiHooks/underwriting/submissionFetchHooks';
import {
  UseGenericMutationResult,
  MutationResponse,
} from 'apiHooks/genericFetchHooks';
import { BankingStipulation } from './BankingStipulation.types';

type UseDeleteBankingStipulationResult = [
  (input: UseDeleteStipulationArgs) => Promise<MutationResponse>,
  Omit<UseGenericMutationResult<BankingStipulation[]>, 'responseReady'>
];

export const useDeleteBankingStipulation =
  (): UseDeleteBankingStipulationResult => {
    const [deleteFn, { error, loading }] = useDeleteApiStipulation();

    return [
      deleteFn,
      {
        error,
        loading,
      },
    ];
  };
