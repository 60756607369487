import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { RiskFactors } from './riskFactorsContainer.types';
import { RiskFactorsResponse } from 'types/api/underwriting/types';

export const fetchRiskFactors = (uuid: string): Promise<RiskFactors> => {
  const url = new URL(
    `/api/v2/submissions/${uuid}/risk_factors`,
    UNDERWRITING_BASE_URL()
  );

  return makeInternalAPIRequest<RiskFactorsResponse>(url, 'GET')
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new NetworkError(response.status, `Failed to fetch risk factors`);
    })
    .then((response) => {
      return {
        highRiskQualities: {
          highBankruptcyScore:
            response.risk_factors.high_risk_qualities.high_bankruptcy_score ??
            undefined,
          lowVantageScore:
            response.risk_factors.high_risk_qualities.low_vantage_score ??
            undefined,
          homeBased:
            response.risk_factors.high_risk_qualities.home_based ?? undefined,
        },
        previousAutoDeclines: {
          previouslyProhibitedBusiness:
            response.risk_factors.previous_auto_declines
              .previously_prohibited_business ?? undefined,
          noActiveTradelines:
            response.risk_factors.previous_auto_declines.no_active_tradelines ??
            undefined,
          recentDismissedBankruptcy:
            response.risk_factors.previous_auto_declines
              .recent_dismissed_bankruptcy ?? undefined,
          averageNegativeDays:
            response.risk_factors.previous_auto_declines
              .average_negative_days ?? undefined,
          percentGross:
            response.risk_factors.previous_auto_declines.percent_gross ??
            undefined,
          averageDepositOverride:
            response.risk_factors.previous_auto_declines
              .average_deposit_override ?? undefined,
        },
        previousAdvanceCollectionStatus: {
          collectionStatus:
            response.risk_factors.previous_advance_collection_status
              .collection_status ?? undefined,
          defaultHistory:
            response.risk_factors.previous_advance_collection_status
              .default_history ?? undefined,
        },
        potentialFraud: response.risk_factors.potential_fraud ?? undefined,
      };
    });
};
