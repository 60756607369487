import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@forward-financing/fast-forward';
import {
  ActiveSubmission,
  Customer,
} from '../../../api/UnderwritingClient/codecs';
import { CustomerConfirmation } from './CustomerConfirmation';
import { UnderwritingClient } from 'api/UnderwritingClient';
import { FFLogger } from 'api/LogClient';
import { toError } from 'helpers/errorUtils';
import { Partner } from 'api/FundingClient/codecs';
export interface CustomerConfirmationModalProps {
  handleContinue: () => void;
  customer: Customer;
  partners: Partner[];
}

export function CustomerConfirmationModal(
  props: CustomerConfirmationModalProps
): JSX.Element {
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const [activeSubmissions, setActiveSubmissions] = useState<
    ActiveSubmission[]
  >([]);

  const handleDisplayConfirmationModalChange = (): void => {
    setDisplayConfirmationModal((prevState) => !prevState);
  };

  const onContinue = (): void => {
    handleDisplayConfirmationModalChange();
    props.handleContinue();
  };

  useEffect(() => {
    props.customer.uuid &&
      UnderwritingClient.fetchCustomer(props.customer.uuid)
        .then((account) => {
          account.active_submissions &&
            setActiveSubmissions(account.active_submissions);
        })
        .catch((e: unknown) => {
          const error = toError(e);
          FFLogger.error(error, { customerUuid: props.customer.uuid });
        });
  }, [props.customer.uuid]);

  return (
    <Modal
      title="Confirm Your Selection"
      isOpen={displayConfirmationModal}
      onOpenChange={handleDisplayConfirmationModalChange}
      trigger={
        <Button
          startIcon={['fas', 'user-check']}
          disabled={props.customer.uuid === ''}
        >
          Continue with Selected Customer
        </Button>
      }
      body={
        <CustomerConfirmation
          activeSubmissions={activeSubmissions}
          handleContinue={onContinue}
          handleBack={handleDisplayConfirmationModalChange}
          customer={props.customer}
          partners={props.partners}
        />
      }
    />
  );
}
