import { useCallback } from 'react';
import {
  ConsumerCreditEntities,
  ExperianConsumerArchivesResponse,
  ExperianConsumerCreditReportResponse,
  ExperianConsumerResponseV2,
} from 'types/api/3pi/types';
import {
  fetchExperianConsumer,
  fetchExperianConsumerArchives,
  fetchExperianConsumerCreditReport,
} from 'api/3pi/experianConsumerFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';

export const useFetchExperianConsumer = (
  submissionUuid?: string,
  ownerUuid?: string,
  entities?: ConsumerCreditEntities[]
): UseGenericQueryResponse<ExperianConsumerResponseV2> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid || !ownerUuid) {
      return undefined;
    }

    return fetchExperianConsumer(submissionUuid, ownerUuid, entities);
  }, [submissionUuid, ownerUuid, entities]);

  return useGenericQuery(fetchFunction);
};

export const useFetchExperianConsumerArchives = (
  ownerUuid?: string
): UseGenericQueryResponse<ExperianConsumerArchivesResponse> => {
  const fetchFunction = useCallback(() => {
    if (!ownerUuid) {
      return undefined;
    }

    return fetchExperianConsumerArchives(ownerUuid);
  }, [ownerUuid]);

  return useGenericQuery(fetchFunction);
};

export const useFetchExperianConsumerCreditReport = (
  referenceUuid?: string
): UseGenericQueryResponse<ExperianConsumerCreditReportResponse> => {
  const fetchFunction = useCallback(() => {
    if (!referenceUuid) {
      return undefined;
    }

    return fetchExperianConsumerCreditReport(referenceUuid);
  }, [referenceUuid]);

  return useGenericQuery(fetchFunction);
};
