import { Params, useParams } from 'react-router-dom';
import React from 'react';
import { SubmissionOnboardingApplicationDetail } from './SubmissionOnboardingApplicationDetail';

export const SubmissionOnboardingApplicationDetailContainer =
  (): JSX.Element => {
    const { applicationUuid } = useParams<Params>();

    // This should be impossible to reach
    // istanbul ignore next
    if (!applicationUuid) {
      throw new Error('No valid Application UUID provided in route.');
    }

    return (
      <SubmissionOnboardingApplicationDetail
        applicationUuid={applicationUuid}
      />
    );
  };
