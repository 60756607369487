import React from 'react';
import { useParams } from 'react-router-dom';
import { Banner, PageContainer } from '@forward-financing/fast-forward';
import { useOwnerLexisNexisReportById } from '../OwnerLexisNexisPage/ownerLexisNexisPageHooks';
import { OwnerLexisNexisPage } from '../OwnerLexisNexisPage/OwnerLexisNexisPage';

export const OwnerLNReportPageContainer = (): JSX.Element => {
  const { reportId, submissionUuid } = useParams();
  const { data: ownerLexisNexisReport, error: errorOwnerLexisNexisReports } =
    useOwnerLexisNexisReportById(reportId);

  const heading = 'Lexis Nexis Report';

  return (
    <PageContainer>
      {errorOwnerLexisNexisReports && (
        <Banner dismissable={false}>
          {errorOwnerLexisNexisReports.message}
        </Banner>
      )}
      {ownerLexisNexisReport && (
        <OwnerLexisNexisPage
          submissionUuid={submissionUuid}
          report={ownerLexisNexisReport}
          heading={heading}
        />
      )}
    </PageContainer>
  );
};
