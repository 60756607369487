import React, { useEffect, useState } from 'react';
import {
  Banner,
  Card,
  Divider,
  formatDateString,
  Icon,
  Loading,
  PageContainer,
  StickyContainer,
  Tabs,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import {
  useCustomerSubmissions,
  useScoringComposite,
} from './DealScoringFetchHooks';
import { DealScoringContainer } from './DealScoringContainer';

export interface DealScoringCardContainerProps {
  submissionUuid: string;
  customerUuid?: string;
}

export const DealScoringCardContainer = ({
  submissionUuid,
  customerUuid,
}: DealScoringCardContainerProps): JSX.Element => {
  const cardTitleText = featureFlags.show_new_ledgers_in_UA
    ? 'UW Decision'
    : 'UW Deal Score';
  const [cardTitle, setCardTitle] = useState(cardTitleText);

  const [currentTabValue, setCurrentTabValue] = useState<string | undefined>();
  const [defaultTabValue, setDefaultTabValue] = useState<string | undefined>();

  const [activeTabSubmission, setActiveTabSubmission] =
    useState<string>(submissionUuid);

  const {
    data: customerSubmissions,
    error: customerSubmissionsError,
    loading: customerSubmissionLoading,
  } = useCustomerSubmissions(customerUuid);

  const { data: compositeScore } = useScoringComposite(submissionUuid);

  const compositeScoreText =
    compositeScore &&
    compositeScore.compositeScore?.score &&
    compositeScore.compositeScore.score;
  const compositeScoreTierText =
    compositeScore &&
    compositeScore.compositeScore?.tier &&
    compositeScore.compositeScore.tier;

  // Setting card title in this level because UW want to see the score before having to open the card
  useEffect(() => {
    if (
      compositeScore?.type === 'Renewal' &&
      compositeScoreText &&
      compositeScoreTierText
    ) {
      setCardTitle(
        `${cardTitleText} ${compositeScoreText.toFixed(
          1
        )} / Tier ${compositeScoreTierText}`
      );
    }
  }, [
    compositeScore,
    compositeScoreText,
    compositeScoreTierText,
    cardTitleText,
  ]);

  const isHalfScreen = currentTabValue
    ? currentTabValue.includes('ledger')
    : defaultTabValue?.includes('ledger');

  if (!featureFlags.show_new_ledgers_in_UA) {
    return (
      <StickyContainer stickTo="bottom">
        <PageContainer>
          <Card
            collapsible
            title={cardTitle}
            initialOpenState={false}
            showFullScreenButton={isHalfScreen}
            showHalfScreen={isHalfScreen}
          >
            <DealScoringContainer
              // currentSubmissionUuid and submissionUuid are the same because
              // in this version we don't show all the customer submissions tabs.
              // Let's keep this until we remove the FT.
              submissionUuid={submissionUuid}
              currentSubmissionUuid={submissionUuid}
              setCardTitle={setCardTitle}
              currentTabValue={currentTabValue}
              setCurrentTabValue={setCurrentTabValue}
              defaultTabValue={defaultTabValue}
              setDefaultTabValue={setDefaultTabValue}
            />
          </Card>
        </PageContainer>
      </StickyContainer>
    );
  }

  return (
    <StickyContainer stickTo="bottom">
      <PageContainer>
        <Card
          collapsible
          title={cardTitle}
          initialOpenState={false}
          showFullScreenButton={isHalfScreen}
          showHalfScreen={isHalfScreen}
        >
          <>
            {customerSubmissionsError && (
              <Banner dismissable={false}>
                {customerSubmissionsError.message}
              </Banner>
            )}

            {customerSubmissions?.length === 0 && (
              <Banner dismissable={false}>
                There are no submissions tied to this customer.
              </Banner>
            )}

            {customerSubmissionLoading && <Loading />}
            {customerSubmissions && (
              <Tabs
                defaultValue={submissionUuid}
                value={activeTabSubmission}
                onValueChange={(value) => setActiveTabSubmission(value)}
              >
                <Tabs.List maximumDisplayTabs={5} modalTitle="Submissions">
                  {customerSubmissions?.map((sub, index) => (
                    <Tabs.Trigger value={sub.uuid} key={sub.uuid}>
                      Submission {index + 1} -{' '}
                      {formatDateString(sub.dateSubReceived)}{' '}
                      {sub.type === 'Renewal' && (
                        <>
                          <Divider orientation="vertical" margin={2} />{' '}
                          <Icon name="repeat" />
                        </>
                      )}
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>

                {customerSubmissions?.map((sub) => {
                  return (
                    <Tabs.Content value={sub.uuid} key={sub.uuid}>
                      <DealScoringContainer
                        submissionUuid={sub.uuid}
                        currentSubmissionUuid={submissionUuid}
                        setCardTitle={setCardTitle}
                        currentTabValue={currentTabValue}
                        setCurrentTabValue={setCurrentTabValue}
                        defaultTabValue={defaultTabValue}
                        setDefaultTabValue={setDefaultTabValue}
                      />
                    </Tabs.Content>
                  );
                })}
              </Tabs>
            )}
          </>
        </Card>
      </PageContainer>
    </StickyContainer>
  );
};
