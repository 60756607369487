import React, { useState } from 'react';
import {
  Box,
  Modal,
  Divider,
  Subheading,
  Button,
} from '@forward-financing/fast-forward';
import { HashMap } from 'api/codecs';
import { CreateBankingStipulationForm } from './CreateBankingStipulationForm';

type CreateBankingStipulationModalProps = {
  submissionUuid: string;
  refetchStipulations: () => void;
  onCancelWithError: (error?: string) => void;
  validReasons?: HashMap<string[]>;
};
export const CreateBankingStipulationModal = ({
  submissionUuid,
  refetchStipulations,
  onCancelWithError,
  validReasons,
}: CreateBankingStipulationModalProps): JSX.Element => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const onSubmit = (): void => {
    onCancelWithError(undefined);
    refetchStipulations();
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const onCancelAction = (): void => {
    refetchStipulations();
    setIsCreateModalOpen(false);
  };

  return (
    <Modal
      title="Add New Stipulation(s)"
      isOpen={isCreateModalOpen}
      onOpenChange={() => setIsCreateModalOpen(!isCreateModalOpen)}
      trigger={<Button startIcon="plus">Add New Stipulation(s)</Button>}
      body={
        <Box>
          <Subheading>Provide stipulation information</Subheading>
          <Divider />
          <CreateBankingStipulationForm
            onSubmit={onSubmit}
            onCancel={onCancelAction}
            submissionUuid={submissionUuid}
            validReasons={validReasons}
          />
        </Box>
      }
    />
  );
};
