import { Button } from '../../../components/shared/form/Button';
import React, { useEffect, useState } from 'react';
import { FFLogger } from '../../../api/LogClient';
import { toError } from 'helpers/errorUtils';

export interface FieldProps {
  fieldName?: string;
  valueName?: string | number;
  leadingIcon?: string;
  isValid: boolean;
  shouldBeMasked?: boolean;
  handleSendLogs?: (
    field_name: string | undefined,
    objectUuid: string | undefined,
    object_type: string | undefined
  ) => Promise<void>;
  objectUuid?: string;
  ariaLabel?: string;
}
type SensitiveValueState = 'hidden' | 'fetching' | 'revealed';

export const Field = (props: FieldProps): JSX.Element => {
  const { handleSendLogs, fieldName, objectUuid } = props;
  const [valueState, setValueState] = useState<SensitiveValueState>(
    props.valueName === '' ? 'revealed' : 'hidden'
  );
  const shouldBeMasked = valueState !== 'revealed' && props.shouldBeMasked;
  const renderIcon = (icon: string): JSX.Element =>
    icon.toLowerCase() === 'other' ? (
      <span data-testid="icon-container" className="has-text-weight-bold">
        Other
      </span>
    ) : (
      <span data-testid="icon-container" className="icon">
        <i data-testid="icon" className={`${icon} leading-icon`} />
      </span>
    );

  const fontColor = !props.isValid && 'has-text-danger';

  useEffect(() => {
    let mounted = true;

    if (valueState === 'fetching') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handleSendLogs?.(fieldName, objectUuid, 'Owner')
        .then(() => {
          if (mounted) {
            setValueState('revealed');
          }
        })
        .catch((e: unknown) => {
          const error = toError(e);
          // show error
          FFLogger.error(error);
        });
    }

    const cleanup = (): void => {
      mounted = false;
    };

    return cleanup;
  }, [valueState, handleSendLogs, fieldName, objectUuid]);

  const revealValue = (): void => {
    setValueState('fetching');
  };

  const shouldRenderShowButton = props.valueName !== '' && shouldBeMasked;

  const maskValue =
    props.shouldBeMasked && props.valueName
      ? props.valueName.toString().replace(/[a-z0-9]/gi, 'X')
      : '';

  return (
    <div data-testid="field-container" className="columns">
      {props.fieldName && (
        <div className="column custom-padding-columns is-one-quater">
          <div className="field-label has-text-left">
            <label data-testid="label" className={`label ${fontColor}`}>
              {props.fieldName}
            </label>
          </div>
        </div>
      )}
      <div className="column custom-padding-columns is-one-quater">
        <div className="overview-field">
          {props.leadingIcon ? renderIcon(props.leadingIcon) : null}
          <input
            aria-label={props.fieldName || props.ariaLabel}
            className="input is-static"
            value={shouldBeMasked ? maskValue : props.valueName}
            readOnly
          />
          {shouldRenderShowButton && (
            <Button
              buttonColor={'transparent-button-black'}
              text={'Show'}
              onClick={revealValue}
              buttonClass={`show-masked-value-button`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
