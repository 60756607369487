import { formatDateString, Icon } from '@forward-financing/fast-forward';
import React from 'react';

export interface UnderwritingSignOffBannerProps {
  underwriterName: string | null | undefined;
  signOffDate: string | null | undefined;
}

export const UnderwritingSignOffBanner = ({
  underwriterName,
  signOffDate,
}: UnderwritingSignOffBannerProps): JSX.Element => {
  return (
    <>
      {underwriterName && signOffDate && (
        <>
          {' '}
          <Icon name="circle-check" prefix="fas" /> Final UW Sign-Off by{' '}
          {underwriterName} on {formatDateString(signOffDate)}
        </>
      )}
    </>
  );
};
