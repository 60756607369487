import React from 'react';
import { DealScoringNoteType } from './DealScoring.types';

import {
  useDealScoringNotes,
  useUpdateSubmissionNote,
} from './DealScoringFetchHooks';
import { DealScoringNotes } from './DealScoringNotes';

export interface DealScoringNotesContainerProps {
  submissionUuid: string;
  noteType: DealScoringNoteType;
}

export const DealScoringNotesContainer = ({
  submissionUuid,
  noteType,
}: DealScoringNotesContainerProps): JSX.Element => {
  const {
    data,
    loading: initialLoading,
    error: fetchError,
  } = useDealScoringNotes(submissionUuid);

  const [
    updateSubmissionNote,
    { data: updatedNote, loading: updating, error: updateError },
  ] = useUpdateSubmissionNote(submissionUuid, noteType);

  const loading = initialLoading || updating;

  const submissionNote = data?.find(
    (noteElem) => noteElem.noteType === noteType
  );

  const note = updatedNote || submissionNote;

  const isNonEmptyError = (error?: Error | undefined): boolean =>
    !!error && typeof error?.message === 'string' && error?.message.length > 0;

  const errors = [updateError, fetchError].filter(isNonEmptyError) as Error[];

  return (
    <DealScoringNotes
      note={note}
      emptyNoteType={noteType}
      loading={loading}
      updateNote={updateSubmissionNote}
      errors={errors}
    />
  );
};
