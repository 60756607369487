import { RenewalScoresData, RenewalScore } from './RenewalScoring.types';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { AuthenticationHeaders } from 'api/AuthClient/codecs';
import { FetchResponse } from 'api/codecs';
import { HTTPClient } from 'api/HTTPClient';
import { AuthClient } from 'api/AuthClient';
import { NetworkError } from 'api/networkError';

/**
 * The types in this file are ONLY used in this utils file (and
 * tests for this file). They represent the API shape, but the
 * fetch functions convert the data to the shared types before
 * returning. This way the data is normalized before being used
 * elsewhere.
 */

/**
 * NewDealScore is the API shape of the score
 * for a New Deal (non-renewal)
 */
export interface NewDealScoreResponse {
  online_presence: number | null;
  owner_risk: number | null;
  business_risk: number | null;
  quality_of_cash_flows: number | null;
  deal_specific_factors: number | null;
  average_score: number | null;
  deal_funded_on: string;
}

/**
 * RenewalScore is the API shape of the score
 * for a Renewal Deal.
 */
export interface RenewalScoreResponse {
  payment_history: number | null;
  stacking_considerations: number | null;
  revenue_trends: number | null;
  credit_trends: number | null;
  note: string | null;
  manager_feedback_score: boolean | null;
  manager_feedback_note: string | null;
  deal_funded_on: string;
  last_updated_at: string | null;
  last_updated_by: string | null;
}

/**
 * RenewalScoresData is the shape of the API
 * response from the renewal_scores endpoint
 */
export interface RenewalScoresFetchResponse {
  renewal_score: RenewalScoreResponse;
  previous_renewal_scores: RenewalScoreResponse[];
  original_deal_score: NewDealScoreResponse;
}

const renewalScoreUrl = (submissionUuid: string): string => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/renewal_scores`,
    UNDERWRITING_BASE_URL()
  );
  return `${url}`;
};

const toRenewalScore = (renewalScore: RenewalScoreResponse): RenewalScore => ({
  paymentHistory: renewalScore.payment_history,
  stackingConsiderations: renewalScore.stacking_considerations,
  revenueTrends: renewalScore.revenue_trends,
  creditTrends: renewalScore.credit_trends,
  notes: renewalScore.note,
  managerFeedbackScore: renewalScore.manager_feedback_score,
  managerFeedbackNote: renewalScore.manager_feedback_note,
  dealFundedOn: renewalScore.deal_funded_on,
  lastUpdatedAt: renewalScore.last_updated_at,
  lastUpdatedBy: renewalScore.last_updated_by,
});

export const fetchRenewalScores = (
  opportunityUuid: string
): Promise<RenewalScoresData> => {
  const fetchScores = (
    authenticatedHeaders: AuthenticationHeaders
  ): Promise<FetchResponse<RenewalScoresFetchResponse>> => {
    const url = renewalScoreUrl(opportunityUuid);

    return HTTPClient.get<RenewalScoresFetchResponse>(url, {
      headers: authenticatedHeaders,
    });
  };

  return AuthClient.authenticateApiRequest(fetchScores)
    .then((response) => {
      if (response.status === 404) {
        throw new NetworkError(
          response.status,
          'Renewal opportunity not found'
        );
      } else if (response.status === 403) {
        throw new NetworkError(response.status, 'You are not authorized');
      } else if (response.status === 422) {
        throw new NetworkError(response.status, 'Opportunity is not a renewal');
      } else if (response.status !== 200) {
        throw new NetworkError(
          response.status,
          `Unknown error with status code ${response.status}`
        );
      }
      return response.json();
    })
    .then((data) => {
      return {
        renewalScore: toRenewalScore(data.renewal_score),
        previousRenewalScores: data.previous_renewal_scores.map(toRenewalScore),
        originalDealScore: {
          onlinePresence: data.original_deal_score.online_presence,
          ownerRisk: data.original_deal_score.owner_risk,
          businessRisk: data.original_deal_score.business_risk,
          qualityOfCashFlows: data.original_deal_score.quality_of_cash_flows,
          dealSpecificFactors: data.original_deal_score.deal_specific_factors,
          averageScore: data.original_deal_score.average_score,
          dealFundedOn: data.original_deal_score.deal_funded_on,
        },
      };
    });
};
