import { SubmissionResponse } from 'types/api/underwriting/types';
import { useGetApiSubmission } from 'apiHooks/underwriting/submissionFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { Submission } from './SubmissionUnderwritingContainer.types';

const toSubmission = (
  submission?: SubmissionResponse
): Submission | undefined => {
  if (!submission) {
    return undefined;
  }

  return {
    amountRequested: submission.capital_needed,
    applicationCreatedDate: submission.date_app_received__c ?? undefined,
    applicationUuid: submission.application_uuid,
    attachmentsReadyForProcessing: submission.attachments_ready_for_processing,
    category: submission.category ?? undefined,
    completedAutomationSteps:
      submission.completed_automation_steps ?? undefined,
    creditCommittee: submission.credit_committee__c ?? undefined,
    customerUuid: submission.customer_uuid,
    decisionAnalystId: submission.decision_analyst_id ?? undefined,
    decisionAnalystName: submission.decision_analyst_name ?? undefined,
    emailContent: submission.email_content ?? undefined,
    hasPreviouslyFundedCustomer: submission.has_previously_funded_customer,
    internalRenewal: submission.internal_renewal,
    isoCompetingSubMessage: submission.iso_competing_sub_message ?? undefined,
    isoUuid: submission.partner_uuid ?? undefined,
    ownerUuids: submission.owner_uuids,
    prequalAnalystName: submission.prequal_analyst_name ?? undefined,
    previousFundedSubmissionUuid:
      submission.previous_funded_opportunity_uuid ?? undefined,
    primeDeal: submission.prime_deal ?? undefined,
    processor: submission.processor ?? undefined,
    salesOwnerId: submission.sales_owner_id ?? undefined,
    salesOwnerName: submission.sales_owner_name ?? undefined,
    stageName: submission.stage_name,
    submissionName: submission.name,
    submissionSource: submission.submission_source,
    subStage: submission.sub_stage ?? undefined,
    type: submission.type,
    underwriter: submission.x2dc_underwriter__c ?? undefined,
    underwriterId: submission.underwriter_id ?? undefined,
    underwriterExceptionRequestId:
      submission.exception_request_underwriter_user_id ?? undefined,
    underwriterSignOffId: submission.underwriter_sign_off_id ?? undefined,
    underwriterSignOffName: submission.underwriter_sign_off_name ?? undefined,
    underwriterSignOffTimestamp:
      submission.underwriter_sign_off_timestamp ?? undefined,
    useOfFunds: submission.use_of_funds ?? undefined,
    uuid: submission.uuid,
    declineDriverNotes: submission.decline_driver_notes ?? undefined,
    declineDrivers: submission.decline_drivers ?? undefined,
  };
};

export const useGetSubmission = (
  submissionUuid?: string
): UseGenericQueryResponse<Submission> =>
  useGenericFeatureQuery(useGetApiSubmission, toSubmission, submissionUuid);
