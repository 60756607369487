import { fetchSubmission } from 'api/banking/submissionFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { BankingAdditionalDataResponse } from 'types/api/banking/types';

export const useGetBankingSubmissionsData = (
  submissionUuids?: string[]
): UseGenericQueryResponse<BankingAdditionalDataResponse> => {
  const getBankingSubmissionsData = useCallback(() => {
    if (!submissionUuids) {
      return undefined;
    }
    const uniqueSubmissionUuids = [...new Set(submissionUuids)];

    return fetchSubmission(uniqueSubmissionUuids);
  }, [submissionUuids]);

  return useGenericQuery(getBankingSubmissionsData);
};
