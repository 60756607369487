import React, { useEffect, useState } from 'react';
import { Banner, formatDateString } from '@forward-financing/fast-forward';
import { useLazyQuery } from '@apollo/client';
import { toError } from 'helpers/errorUtils';
import { gql } from '__generated__/gql';
import { BasicOwnerInfoTableFragmentFragment } from '__generated__/graphql';
import { featureFlags } from 'helpers/featureFlags';
import { client } from 'contexts/ApolloContext';
import {
  fetchCreditDataForOwners,
  fetchSubmissionOwnersV2 as fetchSubmissionOwners,
} from 'api/underwriting/ownerFetchUtils';
import {
  CreditReport,
  OwnerOverviewSubmission,
  TradelinesOverview,
} from './types';
import {
  useLexisNexisForOwners,
  useLexisNexisSearchResults,
  usePublicDocuments,
} from './ownerOverviewHooks';
import { OwnerOverview } from './OwnerOverview';

export interface OwnerOverviewProps {
  submissionUuid: string;
  submission?: OwnerOverviewSubmission;
}

const OwnerBasicInfo = gql(`
  query getOwnerOverviewSubmission($id: String!) {
    me {
      canSeeBirthdate: isAllowed(action: "READ", resource: "Submission.Customer.Owner.birthdate")
      canSeeFullSsn: isAllowed(action: "READ", resource: "Submission.Customer.Owner.ssn")
      canSeePartialSsn: isAllowed(action: "READ", resource: "Submission.Customer.Owner.ssnLastFour")
    }
    submission(id: $id) {
      id
      customer {
        id
        owners {
          ...BasicOwnerInfoTableFragment
        }
      }
    }
  }
`);

export const OwnerOverviewSection = ({
  submissionUuid,
  submission,
}: OwnerOverviewProps): JSX.Element => {
  const [submissionOwners, setSubmissionOwners] = useState<
    BasicOwnerInfoTableFragmentFragment[]
  >([]);

  const [submissionType, setSubmissionType] = useState<string>();
  const [submissionOwnerUuids, setSubmissionOwnerUuids] = useState<string[]>(
    []
  );
  const [creditReports, setCreditReports] = useState<CreditReport[]>();
  const [ownerError, setOwnerError] = useState<string>();
  const [pullCreditError, setPullCreditError] = useState<string | undefined>();
  const [fetchOwnerBasicInfoError, setFetchOwnerBasicInfoError] = useState<
    string | undefined
  >();
  const [tradelines, setTradelines] = useState<TradelinesOverview[]>([]);
  const [lexisNexisPullError, setLexisNexisPullError] = useState<string>();

  const {
    data: publicDocuments,
    error: publicDocumentsError,
    refetch: refetchPublicDocuments,
    loading: publicDocumentsLoading,
  } = usePublicDocuments(submissionUuid);

  const { searchResults, error: searchResultError } =
    useLexisNexisSearchResults(submissionUuid);

  const [loadOwnerBasicInfoViaGraphql, { data, error: graphqlError }] =
    useLazyQuery(OwnerBasicInfo, {
      variables: {
        id: submissionUuid,
      },
      client,
    });

  const {
    data: publicRecordsOverviews,
    error: lexisNexisError,
    loading: lexisNexisForOwnersLoading,
  } = useLexisNexisForOwners(submissionUuid);

  const { publicRecords, criminalFilings } = publicRecordsOverviews || {};

  useEffect(() => {
    if (submission) {
      setSubmissionType(submission.type);
      setSubmissionOwnerUuids(submission.ownerUuids);
    } else {
      setSubmissionType(undefined);
      setSubmissionOwnerUuids([]);
    }
  }, [submission]);

  useEffect(() => {
    const getCreditData = async (): Promise<void> => {
      try {
        const creditData = await fetchCreditDataForOwners(submissionUuid);
        setCreditReports(creditData.creditReports);
        setTradelines(creditData.tradelinesOverviews);
      } catch (e: unknown) {
        const error = toError(e);
        setOwnerError(`Credit Report: ${error.message}`);
      }
    };

    void getCreditData();
  }, [submissionUuid]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (!featureFlags.graphql_enabled) {
          const submissionOwnersResponse = await fetchSubmissionOwners(
            submissionUuid
          );
          setSubmissionOwners(submissionOwnersResponse);
        }
      } catch (e: unknown) {
        const error = toError(e);
        setFetchOwnerBasicInfoError(error.message);
      }
    };

    void fetchData();
  }, [submissionUuid]);

  useEffect(() => {
    // this use effect conditionally loads owner basic info from graphql
    // once we no longer need the feature flag, we should flip the current
    // useLazyQuery usage to a simple useQuery
    const getOwnerBasicInfo = (): void => {
      try {
        if (featureFlags.graphql_enabled) {
          void loadOwnerBasicInfoViaGraphql();
        }
      } catch (e: unknown) {
        const error = toError(e);
        setFetchOwnerBasicInfoError(error.message);
      }
    };

    void getOwnerBasicInfo();
  }, [loadOwnerBasicInfoViaGraphql]);

  const owners = featureFlags.graphql_enabled
    ? data?.submission?.customer?.owners
    : submissionOwners;

  const graphqlOwnerUuids =
    data?.submission?.customer?.owners?.map(
      (owner) => (owner as BasicOwnerInfoTableFragmentFragment)?.id
    ) ?? [];

  const restOwnerUuids = submissionOwners
    .filter((owner) => submissionOwnerUuids.includes(owner.id))
    .map((o) => o.id);

  const ownersUuids = featureFlags.graphql_enabled
    ? graphqlOwnerUuids
    : restOwnerUuids;

  const lexisNexisPullDate =
    publicDocuments &&
    publicDocuments[0] &&
    publicDocuments[0].createdAt &&
    formatDateString(publicDocuments[0].createdAt);

  return (
    <>
      {graphqlError && (
        <Banner dismissable={false} variant="error">
          {graphqlError.message}
        </Banner>
      )}
      {ownerError && (
        <Banner dismissable={false} variant="error">
          {ownerError}
        </Banner>
      )}
      {lexisNexisError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisError.message}
        </Banner>
      )}
      {lexisNexisPullError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisPullError}
        </Banner>
      )}
      {publicDocumentsError && (
        <Banner dismissable={false} variant="error">
          {publicDocumentsError.message}
        </Banner>
      )}
      {pullCreditError && (
        <Banner dismissable={false} variant="error">
          {pullCreditError}
        </Banner>
      )}
      {searchResultError && (
        <Banner dismissable={false} variant="error">
          {searchResultError}
        </Banner>
      )}
      {fetchOwnerBasicInfoError && (
        <Banner dismissable={false} variant="error">
          {fetchOwnerBasicInfoError}
        </Banner>
      )}

      {ownersUuids.length === 0 && (
        <Banner dismissable={false} variant="error">
          There are no Owners for this Submission.
        </Banner>
      )}

      {ownersUuids.map((uuid, index) => {
        const publicRecordCount =
          publicRecords?.find((pr) => pr.ownerUuid === uuid) || null;
        const criminalFilingCount =
          criminalFilings?.find((pr) => pr.ownerUuid === uuid) || null;
        const tradelinesCount =
          tradelines.find((t) => t.ownerUuid === uuid) || null;

        const ownerPublicDocuments = publicDocuments?.filter(
          (doc) => doc.ownerUuid === uuid
        );

        const owner = (owners as BasicOwnerInfoTableFragmentFragment[])?.find(
          (o) => o.id === uuid
        );

        const creditReport = creditReports?.find((cr) => cr.ownerUuid === uuid);

        const ownerLexisNexisSearchResult = searchResults.find(
          (results) => results.ownerUuid === uuid
        );

        return (
          <OwnerOverview
            key={uuid}
            uuid={uuid}
            ownerNumber={index + 1}
            submissionUuid={submissionUuid}
            submissionType={submissionType}
            customerUuid={submission?.customerUuid}
            publicRecord={publicRecordCount}
            criminalFiling={criminalFilingCount}
            tradelines={tradelinesCount}
            ownerPublicDocuments={ownerPublicDocuments}
            owner={owner}
            creditReport={creditReport}
            ownerLexisNexisSearchResult={ownerLexisNexisSearchResult}
            currentUserCanSeePartialSsn={!!data?.me?.canSeePartialSsn}
            currentUserCanSeeDateOfBirth={!!data?.me?.canSeeBirthdate}
            setLexisNexisPullError={setLexisNexisPullError}
            setPullCreditError={setPullCreditError}
            publicDocumentsLoading={publicDocumentsLoading}
            lexisNexisPullDate={lexisNexisPullDate}
            lexisNexisForOwnersLoading={lexisNexisForOwnersLoading}
            canArchiveCreditReports={
              (creditReports && creditReports.length > 1) || false
            }
            refetchPublicDocuments={refetchPublicDocuments}
          />
        );
      })}
    </>
  );
};
