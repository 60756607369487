import { ValidationResult } from './codecs';
import {
  isNumber,
  hasExactLength,
  isRequired,
  isInBetween,
  isBiggerThan,
  isValidEmail,
  emailHasValidChars,
  isDateInThePast,
  isTooYoung,
  isTooOld,
  hasMaxLength,
  URLhasInvalidChars,
  isDateAfter1800,
} from './ValidationHelpers';
import { mdyFormat } from '../../helpers/string/dateUtils';

type Validator = (data: string) => boolean;

//Represents a complete valid state of a field
export const validField: ValidationResult = { isValid: true, hasValue: true };
export const specialCharValidationMessage =
  "can only include the following characters: A-z, 0-9, ., &, -, '";
export const addressSpecialCharValidationMessage = `${specialCharValidationMessage}, #, commas`;
// custom rules for Forms
export const validatePhone = (
  input?: string | null,
  requiredField = true
): ValidationResult => {
  // replaces anything that is not a digit with empty string
  // non required fields come as null from backend
  const sanitized = input ? input.replace(/[^\d]+/g, '') : '';
  const validations = isNumber(sanitized) && hasExactLength(10)(sanitized);

  return {
    hasValue: requiredField ? isRequired(sanitized) : true,
    isValid: validations,
  };
};

export const validationRecord = <T extends Record<string, ValidationResult>>(
  fieldRecord: T
): T => {
  return fieldRecord;
};

export const validateZipCode = (
  input?: string,
  requiredField = true
): ValidationResult => {
  // replaces anything that is not a digit with empty string
  const sanitized = input ? input.replace(/[^\d]+/g, '') : '';
  const validations =
    isNumber(sanitized) &&
    (hasExactLength(5)(sanitized) || hasExactLength(9)(sanitized));

  return {
    hasValue: requiredField ? isRequired(sanitized) : true,
    isValid: validations,
  };
};

export const validateOwnership = (
  input: string,
  total: number,
  msg = 'Valid Ownership is required'
): ValidationResult => {
  const ownershipValidation =
    isBiggerThan(0)(input) && isInBetween(1, 100)(total.toString());
  if (!isNumber(input)) {
    msg = 'Ownership can only contain numbers.';
  } else if (!ownershipValidation) {
    msg = 'The Ownership % cannot exceed 100%';
  }

  return {
    hasValue: isRequired(input),
    isValid: isNumber(input) && ownershipValidation,
    validationMessage: msg,
  };
};

export const validateSsn = (input: string | null): ValidationResult => {
  // replaces anything that is not a digit with empty string
  // non required fields come as null from backend
  // ssn can be null in some cases
  const sanitized = input === null ? '' : input.replace(/[^\d]+/g, '');

  return {
    hasValue: isRequired(sanitized),
    isValid: isNumber(sanitized) && hasExactLength(9)(sanitized),
  };
};

export const validateFein = (input: string): ValidationResult => {
  // replaces anything that is not a digit with empty string
  const sanitized = input.replace(/[^\d]+/g, '');

  return {
    hasValue: isRequired(sanitized),
    isValid: isNumber(sanitized) && hasExactLength(9)(sanitized),
  };
};

export const validateEmail = (
  input?: string | null,
  msg = 'Valid Email is Required'
): ValidationResult => {
  if (!input) {
    return {
      hasValue: isRequired(input),
      isValid: false,
      validationMessage: msg,
    };
  } else if (!isValidEmail(input)) {
    msg = 'Email format is not correct.';
  } else if (!emailHasValidChars(input)) {
    msg = 'Email cannot contain any special symbols.';
  }
  return {
    hasValue: isRequired(input),
    isValid: isValidEmail(input) && emailHasValidChars(input),
    validationMessage: msg,
  };
};

export const validateCapitalNeeded = (input: string): ValidationResult => {
  const sanitized = input === null ? '' : input.replace(/[^\d.]+/g, '');
  const validations = isBiggerThan(0)(sanitized) && isNumber(sanitized);
  return {
    hasValue: isRequired(sanitized),
    isValid: validations,
    validationMessage: 'Valid Amount Needed is Required',
  };
};

export const validateDate = (
  input: string,
  type = 'owner',
  msg = 'Valid Date of Birth is Required'
): ValidationResult => {
  if (!isTooYoung(mdyFormat)(input) && type === 'owner') {
    msg = 'The owner has to be at least 18 years old.';
  } else if (!isTooOld(mdyFormat)(input) && type === 'owner') {
    msg = 'The age of the owner cannot exceed 100 years.';
  }
  const dobValidation =
    isTooOld(mdyFormat)(input) && isTooYoung(mdyFormat)(input);
  const startedOnValidation =
    isDateInThePast(mdyFormat)(input) && isDateAfter1800(mdyFormat)(input);

  return {
    hasValue: isRequired(input),
    isValid: type === 'business' ? startedOnValidation : dobValidation,
    validationMessage: msg,
  };
};

export const validateURL = (input?: string | null): ValidationResult => {
  if (input) {
    const validationMessage = (): string | undefined => {
      if (!hasMaxLength(255)(input)) {
        return 'URL exceeds character limit';
      } else if (URLhasInvalidChars(input)) {
        return 'URL cannot contain any special symbols.';
      }
      return undefined;
    };
    return {
      hasValue: true,
      isValid: hasMaxLength(255)(input) && !URLhasInvalidChars(input),
      validationMessage: validationMessage(),
    };
  }

  return validField;
};

/**
 * @param validations an array of functions. See ValidationHelpers.
 * @param input the input to validate
 * @param msg the message to return if the validations failed
 *
 * usage validate([isBiggerThan(1000), isInBetween(1, 21000), isNumber], yourField.value, "any custom message")
 */
export function validate(
  validators: Validator[],
  input?: string | null,
  msg = 'Valid value is required'
): ValidationResult {
  const requiredField = validators.includes(isRequired);
  const validatorsResult = input
    ? validators.map((validator) => validator(input))
    : [];
  return {
    hasValue: requiredField ? isRequired(input) : true,
    isValid: validatorsResult.every(Boolean),
    validationMessage: msg,
  };
}
