import React from 'react';
import { Flex, Subheading, Text } from '@forward-financing/fast-forward';
import { capitalize } from 'lodash';
import { Results } from './stipulationRecommendations.types';

const NO_REASONS_TEXT = 'No reasons given';

const displayReasons = (reasons: string[] | undefined): string => {
  return reasons?.join(', ') || NO_REASONS_TEXT;
};

export type OwnershipConfidenceScoreTextProps = {
  bucket: string;
  score: number;
  results: Results;
};

export const OwnershipConfidenceScoreText = ({
  bucket,
  score,
  results,
}: OwnershipConfidenceScoreTextProps): JSX.Element => {
  return (
    <Flex flexDirection={'column'} gap={2}>
      <Subheading variant="section">
        Ownership Confidence Score: {score} - {capitalize(bucket)}
      </Subheading>
      <Text>
        <Text bold>
          Experian Commercial ({results.experianCommercial.score}):{' '}
        </Text>
        {displayReasons(results.experianCommercial.reasons)}
      </Text>

      <Text>
        <Text bold>Experian Consumer ({results.experianConsumer.score}): </Text>
        {displayReasons(results.experianConsumer.reasons)}
      </Text>

      <Text>
        <Text bold>
          LexisNexis Business ({results.lexisNexisBusiness.score}):{' '}
        </Text>
        {displayReasons(results.lexisNexisBusiness.reasons)}
      </Text>

      <Text>
        <Text bold>
          LexisNexis Personal ({results.lexisNexisPersonal.score}):{' '}
        </Text>
        {displayReasons(results.lexisNexisPersonal.reasons)}
      </Text>
    </Flex>
  );
};
