import {
  useFetchPayments,
  UseFetchPaymentsResponse,
} from 'apiHooks/funding/paymentFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';

export const useGetOneTimePayments = (page: number): UseFetchPaymentsResponse =>
  useGenericFeatureQuery(useFetchPayments, (data) => data, 'one_time', page);

export const useGetBatchPayments = (page: number): UseFetchPaymentsResponse =>
  useGenericFeatureQuery(useFetchPayments, (data) => data, 'batch', page);
