import { AuthClient } from '../AuthClient';
import { FUNDING_BASE_URL } from '../../constants/globals';
import { AuthenticationHeaders } from '../AuthClient/codecs';
import { Partner, Advance, AdvanceResponse, AdvanceAttributes } from './codecs';
import { FetchResponse, HashMap } from '../codecs';

export class FundingClient extends AuthClient {
  private static BASE_URL = FUNDING_BASE_URL();

  static fetchPartners = (): Promise<Partner[]> => {
    const getPartners = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Partner[]>> =>
      FundingClient.get<Partner[]>(
        `${FundingClient.BASE_URL}/v2/dropdown_partners`,
        {
          headers: authenticationHeaders,
        }
      );

    return FundingClient.authenticateApiRequest<Partner[]>(getPartners).then(
      (response) => {
        if (response.status !== 200) {
          throw new Error('Something went wrong fetching Partners');
        }
        return response.json();
      }
    );
  };

  static fetchAdvance = (opportunityUuid: string): Promise<Advance> => {
    const getAdvance = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<AdvanceResponse>> =>
      FundingClient.get<AdvanceResponse>(
        `${FundingClient.BASE_URL}/v2/advances/${opportunityUuid}`,
        { headers: authenticationHeaders }
      );

    return FundingClient.authenticateApiRequest<AdvanceResponse>(getAdvance)
      .then((response) => response.json())
      .then((data) => data.advance);
  };

  static updateAdvance = (
    opportunityUuid: string,
    attributes: AdvanceAttributes
  ): Promise<{ success: boolean }> => {
    const patchAdvance = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Advance>> => {
      const url = `${FundingClient.BASE_URL}/v2/advances/${opportunityUuid}`;
      return FundingClient.patch<HashMap<AdvanceAttributes>, Advance>(
        url,
        { advance: attributes },
        {
          headers: authenticationHeaders,
        }
      );
    };

    return FundingClient.authenticateApiRequest(patchAdvance).then(
      (response) => {
        if (!response.ok) {
          throw new Error('Something went wrong');
        }

        return { success: true };
      }
    );
  };
}
