import React from 'react';
import { Currency, Grid, Table, Text } from '@forward-financing/fast-forward';
import { CreditDecision } from '../types';

export type ProcessingFeeAttributeTableProps = {
  creditDecision: CreditDecision;
};

export const ProcessingFeeAttributeTable = ({
  creditDecision,
}: ProcessingFeeAttributeTableProps): JSX.Element => {
  const amountWaivedDisplayValue = (
    percentage: number,
    cents: number
  ): JSX.Element => {
    if (percentage > 0) {
      return <Text>{percentage}%</Text>;
    } else if (cents > 0) {
      return <Currency amount={cents / 100} />;
    } else {
      return <Text>--</Text>;
    }
  };

  const noProcessingFeeAttributes =
    creditDecision?.processingFeeWaivedCents === 0 &&
    creditDecision?.processingFeeWaivedPercentage === 0 &&
    creditDecision?.maxUpsell === 0;

  return (
    <>
      {noProcessingFeeAttributes ? (
        <Text>
          There are currently no processing fee attributes for this submission.
        </Text>
      ) : (
        <Grid>
          <Grid.Item xl={6} m={12}>
            <Table>
              <Table.Head>
                <Table.ColumnHeader>Amount Waived</Table.ColumnHeader>
                <Table.ColumnHeader>Max Upsell</Table.ColumnHeader>
              </Table.Head>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {amountWaivedDisplayValue(
                      creditDecision.processingFeeWaivedPercentage,
                      creditDecision.processingFeeWaivedCents
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {creditDecision.maxUpsell
                      ? creditDecision.maxUpsell.toFixed(2)
                      : '--'}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Item>
        </Grid>
      )}
    </>
  );
};
