import React from 'react';
import { EmptyCalendarDay } from './EmptyCalendarDay';
import { HolidayCalendarDay } from './HolidayCalendarDay';
import { IndefinitePauseCalendarDay } from './IndefinitePauseCalendarDay';
import { ScheduleCalendarDay } from './ScheduleCalendarDay';
import { PaymentDay } from '../paymentSchedule.types';
import { CalendarRenderPropData } from '@forward-financing/fast-forward';

export const CalendarDay = ({
  date,
  calendarEvents,
}: CalendarRenderPropData<PaymentDay>): JSX.Element => {
  if (calendarEvents.length < 1 || calendarEvents[0].kind === 'EmptyDay') {
    return <EmptyCalendarDay />;
  }

  // we know that we'll only ever have one event per day
  const calendarEvent = calendarEvents[0];

  if (calendarEvent.kind === 'HolidayDay') {
    return <HolidayCalendarDay event={calendarEvent} />;
  }

  if (calendarEvent.kind === 'IndefinitePauseDay') {
    return <IndefinitePauseCalendarDay event={calendarEvent} />;
  }

  return <ScheduleCalendarDay date={date} event={calendarEvent} />;
};
