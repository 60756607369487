import { Box, Table, Text } from '@forward-financing/fast-forward';
import React from 'react';
import { NoticeOfDefault } from './ownerLexisNexisPage.types';

export interface NoticeOfDefaultsTableProps {
  noticeOfDefaults: NoticeOfDefault[];
}

export const NoticeOfDefaultsTable = ({
  noticeOfDefaults,
}: NoticeOfDefaultsTableProps): JSX.Element => {
  return (
    <Box>
      <Table caption={'Notices of Defaults'}>
        <Table.Head>
          <Table.ColumnHeader>Defendant&apos;s Name</Table.ColumnHeader>
          <Table.ColumnHeader>Site Address</Table.ColumnHeader>
          <Table.ColumnHeader>Site Address 2</Table.ColumnHeader>
          <Table.ColumnHeader>Recording Date</Table.ColumnHeader>
          <Table.ColumnHeader>Deed Type</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {noticeOfDefaults.map((data) => (
            <Table.Row key={`${data.defendantNames[0]} ${data.recordingDate}`}>
              <Table.Cell>
                {data.defendantNames.map((name) => (
                  <Text key={name}>{name}</Text>
                ))}
              </Table.Cell>
              <Table.Cell>{data.siteAddress}</Table.Cell>
              <Table.Cell>{data.siteAddress2}</Table.Cell>
              <Table.Cell>{data.recordingDate}</Table.Cell>
              <Table.Cell>{data.deedType}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
