import React, { useState } from 'react';
import {
  Accordion,
  Box,
  Text,
  Modal,
  IconButton,
  Flex,
} from '@forward-financing/fast-forward';
import { FAQ_QUESTIONS } from './faq_questions';

export const FaqModal = (): JSX.Element => {
  const [displayFaqModal, setDisplayFaqModal] = useState(false);

  const handleDisplayFaqModalChange = () => {
    setDisplayFaqModal(!displayFaqModal);
  };

  return (
    <Flex justifyContent="flex-end" paddingRight={4}>
      <Modal
        isOpen={displayFaqModal}
        onOpenChange={handleDisplayFaqModalChange}
        body={
          <Box>
            <Accordion type="multiple" defaultValue={['0']}>
              {FAQ_QUESTIONS.map(({ question, answer }, i) => (
                <Accordion.Item value={i.toString()} key={question}>
                  <Accordion.Trigger>{question}</Accordion.Trigger>
                  <Accordion.Content>
                    <Text>{answer}</Text>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion>
          </Box>
        }
        title="FAQ"
        trigger={<IconButton icon="question" label="FAQ" />}
      />
    </Flex>
  );
};
