import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.scss';
import { datadogLogs } from '@datadog/browser-logs';
import { Application } from './Application';
import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  REACT_APP_ENV,
} from './constants/globals';

// Uncomment the lines below to start the mock service worker locally.
// Note: Also see src/mocks/underwriting/generators/ApiV1ApplicationsUuidDownloadAttachmentsSourceKey.ts
// for additional instructions.
// Note: Use `npx msw init public/` to generate the mockServiceWorker.js file.
// import { worker } from 'mocks/browser';
// if (process.env.NODE_ENV === 'development') {
//   void worker.start({
//     onUnhandledRequest: 'bypass',
//   });
// }
datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN(),
  site: DATADOG_SITE(),
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'internal_frontend',
  env: REACT_APP_ENV(),
});

const container = document.getElementById('root');

// we're disabling no non null assertion here because that is what
// is recommended by the React team
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<Application />);
