import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { REACT_APP_WAITING_ROOM_BASE_URL } from 'constants/globals';
// CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO PRODUCTION.
// Uncomment these lines for mock data, and comment out the code below it.
// import { mockApiV1ApplicationsUuidAttachments } from 'mocks/waitingRoom/generators/ApiV1ApplicationsUuidAttachments';
import { AttachmentsResponse } from 'types/api/waitingRoom/types';

const waitingRoom = (path: string): URL =>
  new URL(path, REACT_APP_WAITING_ROOM_BASE_URL());

export const fetchAttachmentsForApplication = async (
  applicationUuid: string
): Promise<AttachmentsResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(
  //       mockApiV1ApplicationsUuidAttachments(),
  //     );
  //   }, 5000);
  // });

  const url = waitingRoom(
    `/api/v1/applications/${applicationUuid}/attachments`
  );

  const response = await makeInternalAPIRequest<AttachmentsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Submission Onboarding Attachments'
    );
  }

  return response.json();
};
