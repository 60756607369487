import React from 'react';
import {
  Box,
  Subheading,
  List,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const EmailContentPopover = (): JSX.Element => {
  return (
    <Popover
      header="Email Content"
      content={
        <Box>
          <Subheading variant="subsection">
            Information to enter in the Email Content field
          </Subheading>
          <List>
            <List.Item>
              Notes on details of the company sent by the Customer.
            </List.Item>
            <List.Item>Requested exceptions.</List.Item>
            <List.Item>
              Notifications about the receipt of additional information.
            </List.Item>
            <List.Item>Complementary information on renewals.</List.Item>
            <List.Item>
              Notes on any situation that warrants confirmation by UW.
            </List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="Email Content more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
