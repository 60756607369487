import React from 'react';
import { Banner } from '@forward-financing/fast-forward';

interface Props {
  children: JSX.Element | JSX.Element[];
  label: string;
  labelIcon?: JSX.Element;
  alertMessage?: string;
}

export const BorderedContainer = (props: Props): JSX.Element => {
  return (
    <div className="container-bordered">
      {props.alertMessage && <Banner dismissable>{props.alertMessage}</Banner>}
      <div className="content">
        <div className="columns">
          <div className="column column-label has-text-centered">
            <h3>
              {props.label} {props.labelIcon}
            </h3>
          </div>
        </div>
        <div className="columns">
          <div className="column">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
