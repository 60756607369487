import React from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Subheading,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { AdjustmentDetailsTable } from './AdjustmentDetailsTable';

export const ConfirmAdjustment = (): JSX.Element => {
  const {
    formState,
    updateFormState,
    resetForm,
    createPaymentScheduleAdjustment,
  } = usePaymentScheduleContext();

  const handleNotesChange: TextAreaProps['onChange'] = (event) => {
    updateFormState({
      adjustmentNote: event.target.value,
    });
  };

  return (
    <Flex flexDirection="column" gap={3}>
      <Box>
        <Subheading>Confirm New Payment Adjustment</Subheading>
        <Grid>
          <Grid.Item xs={12} s={12} l={8}>
            <AdjustmentDetailsTable />
          </Grid.Item>
        </Grid>
      </Box>
      <Box>
        <TextArea
          label="Notes"
          value={formState.adjustmentNote}
          onChange={handleNotesChange}
          rows={5}
        />
      </Box>
      <Box>
        <Flex gap={3} justifyContent="center">
          <Button startIcon="check" onClick={createPaymentScheduleAdjustment}>
            Submit
          </Button>
          <Button startIcon="times" variant="secondary" onClick={resetForm}>
            Cancel
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
