import React from 'react';
import { Banner, Table } from '@forward-financing/fast-forward';
import { useExperianConsumer } from './applicationSnapshotFetchHooks';

export type ApplicationSnapshotOwnerProps = {
  submissionUuid: string;
  ownerUuid: string;
  ownerFullName: string;
};

export const ApplicationSnapshotOwner = ({
  submissionUuid,
  ownerUuid,
  ownerFullName,
}: ApplicationSnapshotOwnerProps): JSX.Element => {
  const { data: experianConsumer, error: experianConsumerError } =
    useExperianConsumer(submissionUuid, ownerUuid);

  const ficoScore =
    experianConsumer !== undefined &&
    experianConsumer.ficoScore !== undefined &&
    // Lowest FICO score is ~300 so we don't have to worry about falsy values
    // for zero.
    experianConsumer.ficoScore;
  const isLowFico = ficoScore !== false && ficoScore < 500;
  const isExperianConsumerNotFound = experianConsumerError?.statusCode === 404;
  const hasExperianConsumerError =
    experianConsumerError && !isExperianConsumerNotFound;

  if (experianConsumerError && hasExperianConsumerError) {
    return (
      <Table.Row key={ownerUuid}>
        <Table.RowHeader>{ownerFullName} FICO</Table.RowHeader>
        <Table.Cell backgroundColor="red-100" aria-label="FICO Score Error">
          <Banner key={experianConsumerError.message} dismissable={false}>
            {experianConsumerError.message}
          </Banner>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table.Row key={ownerUuid}>
      <Table.RowHeader>{ownerFullName} FICO</Table.RowHeader>

      {ficoScore === undefined ? (
        <Table.Cell backgroundColor="red-100">Experian Not Pulled</Table.Cell>
      ) : (
        <Table.Cell
          // Red background is not accessible nor testable, so add aria-label.
          backgroundColor={isLowFico ? 'red-100' : undefined}
          aria-label={isLowFico ? 'Low FICO Score Warning' : undefined}
        >
          {ficoScore || ''}
        </Table.Cell>
      )}
    </Table.Row>
  );
};
