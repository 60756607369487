import React from 'react';
import { IconButton } from '@forward-financing/fast-forward';

export interface SectionProps {
  children?: React.ReactNode;
  sectionTitle: string;
  message?: string;
  handleNavigation: ((e: React.MouseEvent) => void) | null;
  hasBackgroundColor?: boolean;
  isSectionComplete?: boolean;
  sectionTestid?: string;
}

export const Section = (props: SectionProps): JSX.Element => {
  const hasBackgroundColor = () =>
    props.hasBackgroundColor ? 'colored-section' : '';

  const fontColor = !props.isSectionComplete && 'has-text-danger';

  return (
    <div data-testid={props.sectionTestid} className="overview-section">
      <div className="container">
        <div className="title-border section-header columns">
          <div className="column is-two-fifths title-positioning no-bottom-padding ">
            {!props.isSectionComplete && (
              <span className="has-text-danger">
                <i
                  data-testid="section-incomplete-alert-icon"
                  className="alert-icon fas fa-exclamation-triangle fa-2x"
                />
              </span>
            )}
            <h6 className={`title has-text-weight-bold  ${fontColor}`}>
              {props.sectionTitle}
            </h6>
          </div>
          <div className="column edit-option no-bottom-padding ">
            {props.message ? (
              <span className="has-text-danger message">{props.message}</span>
            ) : null}
            {props.handleNavigation && (
              <IconButton
                icon="pencil-alt"
                onClick={props.handleNavigation}
                label="Edit"
                hiddenLabel
              />
            )}
          </div>
        </div>
        <div className="section-content">
          <div
            data-testid="children-container"
            className={hasBackgroundColor()}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
