import React from 'react';
import { ValidationResult } from '../../../helpers/validations/codecs';

export interface ValidationMessageProps {
  validationResult?: ValidationResult;
}

export const ValidationMessage = (
  props: ValidationMessageProps
): JSX.Element => {
  const hasValidationMessage =
    props.validationResult &&
    !props.validationResult.isValid &&
    props.validationResult.hasValue &&
    props.validationResult.validationMessage &&
    props.validationResult.validationMessage.length > 0;

  return hasValidationMessage ? (
    <span className="help is-danger" role="alert">
      {props.validationResult?.validationMessage}
    </span>
  ) : (
    <></>
  );
};
