import {
  Banner,
  Box,
  Button,
  Divider,
  Flex,
  Form,
  Icon,
  IconButton,
  Loading,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { isEqual } from 'lodash';
import {
  addressHasNoSpecialChar,
  textHasNoSpecialChar,
} from 'helpers/validations/ValidationHelpers';
import { validateZipCode } from 'helpers/validations/FieldValidator';
import { isAtLeast18YearsOld } from 'helpers/date/dateUtils';
import { useDeleteOwner } from 'components/DealScoring/Ledger/ledgerHooks';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { useBulkUpdateOwners, useCreateOwners } from './SubmissionEditingHooks';
import {
  CustomerAddress,
  FormUpdateState,
  Owner,
} from './SubmissionEditing.types';
import { OwnerForm } from './OwnerForm';

type OwnerSectionFormProps = {
  owners: Owner[];
  refetchOwners: () => void;
  customerAddress: CustomerAddress;
  submissionUuid: string;
  setFormStatus: (value: FormUpdateState) => void;
  formStatus: FormUpdateState;
};

const emptyOwner: Owner = {
  firstName: '',
  lastName: '',
  ssn: undefined,
  bornOn: undefined,
  email: '',
  address: {
    street1: '',
    street2: null,
    city: '',
    state: '',
    zip: '',
  },

  uuid: '',
  ssnNotPresent: false,
  dobNotPresent: false,

  cellPhone: undefined,
  homePhone: undefined,
  title: '',
  ownershipPercentage: '',
};

// Individual Owner Form Schema
const ownerFormSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: 'First Name is required.' })
      .refine((name) => textHasNoSpecialChar(name), {
        message:
          "First Name can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), and spaces.",
      }),
    lastName: z
      .string()
      .min(1, { message: 'Last Name is required.' })
      .refine((name) => textHasNoSpecialChar(name), {
        message:
          "Last Name can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), and spaces.",
      }),
    // SSN is required if ssnNotPresent is false
    ssn: z.string().optional(),
    // bornOn is required if dobNotPresent is false
    bornOn: z.string().optional(),
    email: z
      .string()
      .optional()
      .nullish()
      .refine(
        (email) => {
          return email ? z.string().email().safeParse(email).success : true;
        },
        {
          message: 'Invalid email.',
        }
      ),
    address: z.object({
      street1: z
        .string()
        .min(1, { message: 'Street Address is required.' })
        .refine((street) => addressHasNoSpecialChar(street), {
          message:
            "Street Address can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), number signs (#), and spaces.",
        }),
      street2: z.string().nullable(),
      city: z
        .string()
        .min(1, { message: 'City is required.' })
        .refine((city) => addressHasNoSpecialChar(city), {
          message:
            "City can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), number signs (#), and spaces.",
        }),
      state: z.string().min(1, { message: 'State is required.' }),
      zip: z
        .string()
        .min(1, { message: 'Zip Code is required.' })
        .refine((zip) => validateZipCode(zip).isValid, {
          message: 'Invalid Zip Code.',
        }),
    }),
    uuid: z.string(),
    ssnNotPresent: z.boolean(),
    dobNotPresent: z.boolean(),
    cellPhone: z.string().nullish(),
    homePhone: z.string().nullish(),
    title: z.string().nullish(),
    ownershipPercentage: z
      .union([z.string(), z.number()])
      .refine(
        (ownership) => Number(ownership) >= 1 && Number(ownership) <= 100,
        {
          message: 'Ownership must be between 1 and 100.',
        }
      ),
  })
  // SSN Refinements
  .refine((owner) => owner.ssnNotPresent || owner.ssn?.length === 9, {
    path: ['ssn'],
    message: 'SSN must be 9 digits.',
  })
  // DOB Refinements
  .refine(
    (owner) => {
      return (
        owner.dobNotPresent ||
        (owner.bornOn && isAtLeast18YearsOld(new Date(owner.bornOn)))
      );
    },
    {
      path: ['bornOn'],
      message: 'Owner must be at least 18 years old.',
    }
  );

// This is the schema used for validation of the entire form
// It allows us to validate all owners at once,
// along with the total ownership percentage.
const ownersFormSchema = z
  .array(ownerFormSchema)
  // Ownership Percentage Refinement across all Owners
  .refine(
    (allOwners) => {
      const ownershipSum = allOwners.reduce(
        (acc, owner) => acc + Number(owner.ownershipPercentage),
        0
      );

      return ownershipSum >= 1 && ownershipSum <= 100;
    },
    { message: 'Total ownership percentage must be between 1 and 100.' }
  );

// Type used in OwnerForm component for ownerErrors prop
export type OwnerSchemaError = Partial<
  z.inferFormattedError<typeof ownerFormSchema>
>;

type OwnersSchemaError = Partial<
  z.inferFormattedError<typeof ownersFormSchema>
>;

export const OwnerSectionForm = ({
  owners,
  submissionUuid,
  customerAddress,
  setFormStatus,
  formStatus,
  refetchOwners,
}: OwnerSectionFormProps): JSX.Element => {
  const [ownersForm, setOwnersForm] = useState<Owner[]>(owners);
  const [ownersMaxLimit, setOwnersMaxLimit] = useState<boolean>(false);
  const [ownersMinLimit, setOwnersMinLimit] = useState<boolean>(false);

  const [ownersFormErrors, setOwnersFormErrors] = useState<OwnersSchemaError>(
    {}
  );

  /**
   * Memoizing owners to avoid unnecessary re-renders.
   */
  const memoizedOwners = useDeepCompareMemoize(owners);

  useEffect(() => {
    if (memoizedOwners && !isEqual(memoizedOwners, ownersForm)) {
      setOwnersForm(memoizedOwners);
    }
    // Have to disable eslint since we want to react only if owners changed,
    // it happens when we call refetch.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedOwners]);

  const isValidOwnersForm = (): boolean => {
    const validationResult = ownersFormSchema.safeParse(ownersForm);

    if (validationResult.success) {
      setOwnersFormErrors({});
    } else {
      setOwnersFormErrors(validationResult.error.format());
    }

    return validationResult.success;
  };

  const [
    updateOwners,
    { error: updateOwnersError, loading: updateOwnersLoading },
  ] = useBulkUpdateOwners();

  const [
    createOwners,
    { error: createOwnersError, loading: createOwnersLoading },
  ] = useCreateOwners();

  const [
    deleteOwnerReq,
    { error: deleteOwnerError, loading: deleteOwnerLoading },
  ] = useDeleteOwner();

  /**
   * The function performs the following steps:
   * 1. Validates the owners form using `isValidOwnersForm()`. If the form is invalid, it returns early.
   * 2. Filters the `ownersForm` to separate new owners (those with an empty `uuid`) and changed owners (those with a non-empty `uuid`).
   * 3. Resets the form status to `undefined`.
   * 4. If there are new owners, it calls `createOwners` API with the new owners and stores the success status.
   * 5. If there are changed owners, it calls `updateOwners` API with the changed owners and stores the success status.
   * 6. If both creation and update operations are successful, it sets the form status to 'updated'.
   */
  const handleUpdateOwners = async (): Promise<void> => {
    if (!isValidOwnersForm()) {
      return;
    }

    const changedOwners = ownersForm.filter((owner) => owner.uuid !== '');
    const newOwners = ownersForm.filter((owner) => owner.uuid === '');

    setFormStatus(undefined);

    let creationSuccess,
      updateSuccess = undefined;

    if (newOwners.length) {
      creationSuccess = (
        await createOwners({ submissionUuid, owners: newOwners })
      ).success;
    }

    if (changedOwners.length) {
      updateSuccess = (await updateOwners(changedOwners)).success;
    }

    if (creationSuccess || updateSuccess) {
      refetchOwners();
      setFormStatus('updated');
    }
  };

  const handleOwnersFormOnchange = (
    ownerToUpdateIndex: number,
    updatedOwner: Partial<Owner>
  ): void => {
    formStatus !== 'unsaved' && setFormStatus('unsaved');

    const updatedForm = ownersForm.map((owner, index) =>
      index === ownerToUpdateIndex ? { ...owner, ...updatedOwner } : owner
    );

    if (isEqual(updatedForm, owners)) {
      setFormStatus(undefined);
    }

    setOwnersForm(updatedForm);
  };

  const addOwnerForm = (): void => {
    if (ownersForm.length < 4) {
      setOwnersForm([...ownersForm, emptyOwner]);
    } else {
      setOwnersMaxLimit(true);
    }

    // Resetting limit banners
    ownersMinLimit && setOwnersMinLimit(false);
  };

  const deleteOwner = async (ownerToRemoveIndex: number): Promise<void> => {
    const ownerToRemove = ownersForm[ownerToRemoveIndex];

    // This is preventing user from delete owner if there is only one persisted in the DB. For example:
    // You have only one owner in the DB, then you add a second one, but without clicking save you first try
    // to delete the original first owner.
    if (ownersForm.length === 1 && owners.length === 1) {
      setOwnersMinLimit(true);
    } else if (ownerToRemove.uuid === '') {
      setOwnersForm(
        ownersForm.filter((_, index) => index !== ownerToRemoveIndex)
      );
    } else {
      const { success } = await deleteOwnerReq({
        submissionUuid,
        ownerUuid: ownerToRemove.uuid,
      });

      success && refetchOwners();
    }
    // Resetting limit banners
    ownersMaxLimit && setOwnersMaxLimit(false);
  };

  const isLoading =
    updateOwnersLoading || createOwnersLoading || deleteOwnerLoading;

  return (
    <Box>
      <Subheading>Owner Information</Subheading>

      <Divider margin={4} />

      {updateOwnersError && (
        <Banner dismissable>{updateOwnersError?.message}</Banner>
      )}

      {createOwnersError && (
        <Banner dismissable>{createOwnersError?.message}</Banner>
      )}

      {deleteOwnerError && (
        <Banner dismissable>{deleteOwnerError?.message}</Banner>
      )}

      {ownersMaxLimit && (
        <Banner dismissable>Cannot add more than 4 owners</Banner>
      )}

      {ownersMinLimit && (
        <Banner dismissable>
          Cannot remove all owners. Please create a new owner before removing
          this one.
        </Banner>
      )}

      {formStatus === 'unsaved' && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      <Form
        accessibleName="Edit Submission Information"
        onSubmit={handleUpdateOwners}
        allowImplicitSubmission={false}
      >
        {({ fireSubmit }) => (
          <>
            {ownersForm.map((ownerForm, index) => (
              <OwnerForm
                key={ownerForm.uuid}
                handleOwnersFormOnchange={handleOwnersFormOnchange}
                index={index}
                ownerForm={ownerForm}
                deleteOwner={deleteOwner}
                customerAddress={customerAddress}
                owner1Address={ownersForm[0].address}
                ownerErrors={ownersFormErrors[index]}
                persistedOwner={owners[index]}
              />
            ))}

            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <IconButton
                icon={'plus'}
                onClick={() => addOwnerForm()}
                label="Add Owner"
                disabled={ownersMaxLimit}
              />
              <Flex gap={3} justifyContent={'right'} alignItems={'center'}>
                {ownersFormErrors._errors && (
                  <Text color="danger">
                    {ownersFormErrors._errors.join(' ')}
                  </Text>
                )}

                {formStatus === 'updated' && (
                  <Icon size="2x" name="check-circle" />
                )}

                {isLoading && <Loading />}

                <Button onClick={fireSubmit} disabled={isLoading}>
                  Save Owners
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Form>
    </Box>
  );
};
