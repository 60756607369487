import React from 'react';
import {
  Box,
  Subheading,
  Table,
  Text,
  Link,
  Flex,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { InternalLink } from 'components/shared/InternalLink';
import { TradelinesOverview } from './types';

export interface TradelinesContainerProps {
  tradelines?: TradelinesOverview | null;
  tradelinesUrl?: string;
  ownerUuid: string;
  submissionUuid: string;
}

const ReportLink = ({
  tradelines,
  tradelinesUrl,
  ownerUuid,
  submissionUuid,
}: TradelinesContainerProps): JSX.Element | null => {
  if (featureFlags.experian_consumer_3pi_tradelines_detail) {
    return (
      <InternalLink
        target="_blank"
        to={`/dashboard/submissions/${submissionUuid}/owners/${ownerUuid}/tradelines`}
      >
        View Report
      </InternalLink>
    );
  }

  return tradelines && tradelinesUrl ? (
    <Link newTab href={new URL(tradelinesUrl, UNDERWRITING_BASE_URL())}>
      View Report
    </Link>
  ) : null;
};

export const TradelinesContainer = ({
  tradelines,
  tradelinesUrl,
  ownerUuid,
  submissionUuid,
}: TradelinesContainerProps): JSX.Element => {
  return (
    <Box>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Tradelines</Subheading>
        <ReportLink
          tradelines={tradelines}
          tradelinesUrl={tradelinesUrl}
          ownerUuid={ownerUuid}
          submissionUuid={submissionUuid}
        />
      </Flex>
      {tradelines ? (
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Tradelines</Table.ColumnHeader>
            <Table.ColumnHeader>30</Table.ColumnHeader>
            <Table.ColumnHeader>60</Table.ColumnHeader>
            <Table.ColumnHeader>90+</Table.ColumnHeader>
            <Table.ColumnHeader>Derog</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Current Delinquincies</Table.RowHeader>
              <Table.Cell>{tradelines.current30Days}</Table.Cell>
              <Table.Cell>{tradelines.current60Days}</Table.Cell>
              <Table.Cell>{tradelines.current90Days}</Table.Cell>
              <Table.Cell>{tradelines.currentDerog}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>All-Time Delinquincies</Table.RowHeader>
              <Table.Cell>{tradelines.allTime30Days}</Table.Cell>
              <Table.Cell>{tradelines.allTime60Days}</Table.Cell>
              <Table.Cell>{tradelines.allTime90Days}</Table.Cell>
              <Table.Cell>{tradelines.allTimeDerog}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <Text>No Tradelines available.</Text>
      )}
    </Box>
  );
};
