import React from 'react';
import {
  Box,
  Subheading,
  List,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const EntityTypePopover = (): JSX.Element => {
  return (
    <Popover
      header="Entity Type"
      content={
        <Box>
          <Subheading variant="subsection">Additional Entity Types</Subheading>
          <List>
            <List.Item>P.A. = Corporation</List.Item>
            <List.Item>LTD = Corporation or LLC</List.Item>
            <List.Item>PSC/PC = Corporation</List.Item>
            <List.Item>PLLC = LLC</List.Item>
            <List.Item>LC = LLC</List.Item>
            <List.Item>ABN = Sole Proprietor</List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="Entity Type more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
