import { Banner, PageContainer } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { isArray } from 'lodash';
import { DataMerchTable } from './DataMerchTable';
import { useDataMerchReport } from './dataMerchHooks';

type Params = { submissionUuid: string };

export const DataMerchReportPageContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  const { data: dataMerchReport, error: dataMerchError } =
    useDataMerchReport(submissionUuid);

  return (
    <PageContainer>
      {dataMerchError && <Banner>{dataMerchError.message}</Banner>}
      {dataMerchReport && 'error' in dataMerchReport && (
        <Banner>{dataMerchReport.error}</Banner>
      )}
      {dataMerchReport &&
        isArray(dataMerchReport) &&
        dataMerchReport.map((report) => (
          <DataMerchTable key={report.legalName} dataMerchData={report} />
        ))}
    </PageContainer>
  );
};
