import React from 'react';
import {
  Address,
  Box,
  Flex,
  formatDateString,
  formatPhoneNumber,
  Link,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';

export interface LexisNexisVerifiedTableProps {
  documentId: number;
  businessName?: string;
  address?: {
    street1: string;
    city: string;
    state: string;
    zip: string;
  };
  businessPhone?: string;
  established: string | undefined;
  submissionUuid: string;
}

export const LexisNexisVerifiedTable = (
  props: LexisNexisVerifiedTableProps
): JSX.Element => {
  const lexisNexisreportLink = (): JSX.Element => {
    if (featureFlags.use_new_bussines_ln_page) {
      return (
        <Link
          href={
            new URL(
              `/dashboard/submission/${props.submissionUuid}/business-lexis-nexis/report-id/${props.documentId}`,
              window.location.origin
            )
          }
          newTab
        >
          View Report
        </Link>
      );
    } else {
      return (
        <Link
          href={
            new URL(
              `/admin/documents/${props.documentId}/lexis_nexis`,
              UNDERWRITING_BASE_URL()
            )
          }
          newTab
        >
          View Report
        </Link>
      );
    }
  };
  return (
    <Box>
      <Flex justifyContent="space-between">
        <Subheading variant="subsection">Lexis Nexis Verified</Subheading>
        {lexisNexisreportLink()}
      </Flex>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>Business Name</Table.RowHeader>
            <Table.Cell>{props.businessName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Business Address</Table.RowHeader>
            <Table.Cell>
              {props.address && (
                <Address
                  streetAddress={props.address.street1}
                  city={props.address.city}
                  state={props.address.state}
                  zipCode={props.address.zip}
                  isSingleLine
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Business Phone</Table.RowHeader>
            <Table.Cell>
              {props.businessPhone && formatPhoneNumber(props.businessPhone)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Established</Table.RowHeader>
            <Table.Cell>
              {props.established && formatDateString(props.established)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Box>
  );
};
