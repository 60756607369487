import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  Text,
} from '@forward-financing/fast-forward';

type AssignOverwriteConfirmationModalProps = {
  onConfirm: () => void;
};

export const UnassignConfirmationModal = ({
  onConfirm,
}: AssignOverwriteConfirmationModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal
      body={
        <Box>
          <>
            <Text>
              This Submission was previously assigned to another user. Are you
              sure you want to proceed with unassigning? This may not be able to
              be undone depending on the stage of the Submission.
            </Text>

            <Flex
              justifyContent={'center'}
              flexDirection={'row'}
              gap={4}
              mt={5}
            >
              <Button
                startIcon="times"
                onClick={() => setIsOpen(false)}
                variant="secondary"
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  onConfirm();
                  setIsOpen(false);
                }}
                variant="danger"
              >
                Confirm Unassignment
              </Button>
            </Flex>
          </>
        </Box>
      }
      title="Unassign Confirmation"
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
      trigger={
        <IconButton hiddenLabel label="Unassign User" icon="user-xmark" />
      }
    />
  );
};
