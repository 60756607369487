import React from 'react';
import {
  Box,
  Flex,
  Text,
  Grid,
  formatDateString,
  Banner,
} from '@forward-financing/fast-forward';
import { InternalLink } from 'components/shared/InternalLink';
import { useCustomerSubmissions } from './CustomerSearchHooks';
import { Partner } from 'api/FundingClient/codecs';

interface CustomerSubmissionsProps {
  customerUuid?: string;
  isos: Partner[];
}

export const CustomerSubmissions = ({
  customerUuid,
  isos,
}: CustomerSubmissionsProps): JSX.Element => {
  const isoName = (isoUuid?: string): string | undefined =>
    isos?.find((p) => p.uuid === isoUuid)?.label;
  const { data: submissions, error: customerSubmissionError } =
    useCustomerSubmissions(customerUuid);

  const isSubmissionArrayIsEmpty = !submissions || submissions.length < 1;

  if (customerSubmissionError) {
    return (
      <Banner dismissable={false}>{customerSubmissionError.message}</Banner>
    );
  }
  return (
    <>
      <Box ml={3}>
        {submissions && (
          <Text bold size="l">
            Customer&apos;s submission(s)
          </Text>
        )}
      </Box>
      <Box m={3}>
        <Grid gutter>
          {isSubmissionArrayIsEmpty ? (
            <Grid.Item s={12} m={12} l={12}>
              <Box m={3} backgroundColor="gray-200" p={3}>
                No Customer submissions to show
              </Box>
            </Grid.Item>
          ) : (
            submissions?.map((sub) => {
              return (
                <Grid.Item key={sub.uuid} s={12} m={12} l={12}>
                  <Flex
                    p={2}
                    backgroundColor="gray-200"
                    flexDirection={'column'}
                  >
                    <InternalLink to={`/submissions/${sub.uuid}`}>
                      {sub.dateAppReceived &&
                        formatDateString(sub.dateAppReceived)}{' '}
                      - {isoName(sub.isoUuid)}
                    </InternalLink>
                    <Flex flexDirection={'row'} gap={2}>
                      <Text bold>Stage/Sub-stage</Text>
                      <Text>
                        {sub.stage}/{sub.subStage}
                      </Text>
                      <Text bold>Owners</Text>
                      <Text>
                        {sub.owner1FullName} {sub.owner2FullName}
                      </Text>
                    </Flex>
                  </Flex>
                </Grid.Item>
              );
            })
          )}
        </Grid>
      </Box>
    </>
  );
};
