import React from 'react';
import {
  Box,
  Flex,
  Subheading,
  Switch,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { generateLastTwelveMonthNames } from '../../../helpers/date/dateIterationUtils';
import {
  generateRows,
  generateAchTotalsRow,
  generateReturnsTotalsRow,
  getCellValue,
  getFormattedPercentValue,
} from '../AchReturnsMonthlyTableUtils';
import { AchReturnsByCode } from '../achReturns.types';

export interface AchReturnsMonthlyTableProps {
  returnsByCode?: AchReturnsByCode;
  responseReady: boolean;
}

export const AchReturnsMonthlyTable = (
  props: AchReturnsMonthlyTableProps
): JSX.Element => {
  const { returnsByCode, responseReady } = props;

  const columnMonthNames = generateLastTwelveMonthNames();

  // We show percentages in the table by default.
  const [showCounts, setShowCounts] = React.useState(false);

  if (!responseReady) {
    return <Text>Loading monthly returns...</Text>;
  }

  if (!returnsByCode) {
    return <Text>Monthly Summary N/A</Text>;
  }

  // Rows for the body of the table.
  const reportRows = generateRows(returnsByCode);

  // ACH Totals row.
  const achTotalsRow = generateAchTotalsRow(returnsByCode);

  // Returns Totals row.
  const returnsTotalsRow = generateReturnsTotalsRow(returnsByCode);

  return (
    <Box data-testid="ach-returns-monthly-table">
      <Subheading>
        <Flex justifyContent="space-between" alignItems="center">
          Monthly Summary
          <Switch
            id="show-count-switch"
            checked={showCounts}
            label="Total Count"
            onCheckedChange={(checked) => {
              setShowCounts(checked);
            }}
          />
        </Flex>
      </Subheading>

      <Table>
        <Table.Head>
          <Table.ColumnHeader key="Return Code">Return Code</Table.ColumnHeader>
          {columnMonthNames.map((monthName) => (
            <Table.ColumnHeader key={monthName}>{monthName}</Table.ColumnHeader>
          ))}
        </Table.Head>

        <Table.Body>
          {/* Object.keys will return a code like R01. */}
          {Object.keys(reportRows).map((code) => (
            <Table.Row key={code}>
              <Table.RowHeader>{code}</Table.RowHeader>
              {/* Within the return code we iterate through each month and
                  display the count for that month in a new cell. */}
              {Object.entries(reportRows[code]).map(
                ([dateYyyyMmKey, count]) => (
                  <Table.Cell key={dateYyyyMmKey}>
                    <Flex justifyContent="flex-end" alignItems="center" gap={1}>
                      {getCellValue(
                        showCounts,
                        count,
                        achTotalsRow[dateYyyyMmKey]
                      )}
                    </Flex>
                  </Table.Cell>
                )
              )}
            </Table.Row>
          ))}

          <Table.SummaryRow>
            <Table.Cell>Grand Total</Table.Cell>

            {Object.entries(returnsTotalsRow).map(([dateYyyyMmKey, count]) => (
              <Table.Cell key={dateYyyyMmKey}>
                <Flex justifyContent="flex-end" alignItems="center" gap={1}>
                  {count.toFixed(0)}
                </Flex>
              </Table.Cell>
            ))}
          </Table.SummaryRow>

          <Table.SummaryRow>
            <Table.Cell>Total ACH</Table.Cell>

            {Object.entries(achTotalsRow).map(([dateYyyyMmKey, count]) => (
              <Table.Cell key={dateYyyyMmKey}>
                <Flex justifyContent="flex-end" alignItems="center" gap={1}>
                  {count.toFixed(0)}
                </Flex>
              </Table.Cell>
            ))}
          </Table.SummaryRow>

          <Table.SummaryRow>
            <Table.Cell>Total %</Table.Cell>

            {Object.entries(returnsTotalsRow).map(([dateYyyyMmKey, count]) => (
              <Table.Cell key={dateYyyyMmKey}>
                <Flex justifyContent="flex-end" alignItems="center" gap={1}>
                  {getFormattedPercentValue(count, achTotalsRow[dateYyyyMmKey])}
                </Flex>
              </Table.Cell>
            ))}
          </Table.SummaryRow>
        </Table.Body>
      </Table>
    </Box>
  );
};
