import React, { useEffect } from 'react';
import { Banner, Card, Loading } from '@forward-financing/fast-forward';
import { PotentialFraudContent } from './PotentialFraudContent';
import { useLazyPotentialFraudMatches } from './PotentialFraudHooks';

export interface PotentialFraudContainerProps {
  submissionUuid: string;
}

export const PotentialFraudContainer = ({
  submissionUuid,
}: PotentialFraudContainerProps): JSX.Element => {
  const [
    fetchPotentialFraudMatches,
    { potentialFraudMatches, loading: matchesLoading, error: matchesError },
  ] = useLazyPotentialFraudMatches();

  useEffect(() => {
    void fetchPotentialFraudMatches(submissionUuid);
  }, [fetchPotentialFraudMatches, submissionUuid]);

  return (
    <Card title="Potential Fraud">
      {matchesError && <Banner>{matchesError.message}</Banner>}
      {matchesLoading && <Loading />}
      {potentialFraudMatches && !matchesLoading && (
        <PotentialFraudContent
          matches={potentialFraudMatches}
          submissionUuid={submissionUuid}
          refetchMatches={fetchPotentialFraudMatches}
        />
      )}
    </Card>
  );
};
