import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import {
  Box,
  Banner,
  Loading,
  Flex,
  formatDateString,
  PlainDate,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';
import { PaymentSchedule } from './PaymentSchedule';
import { PaymentScheduleProvider } from './PaymentScheduleContext';
import {
  useGetAdvanceProfile,
  useGetBankInfo,
  useGetPaymentSchedule,
} from './paymentScheduleFetchHooks';
import {
  createAdjustment,
  createResumePaymentsAdjustment,
  getScheduleAdjustmentProjection,
} from './paymentScheduleFetchUtils';
import {
  CalculatorFormState,
  NoSchedule,
  PaymentSchedule as PaymentScheduleType,
  SameDayAchFormState,
  ScheduleAdjustmentComparison,
} from './paymentSchedule.types';

export type Params = {
  advanceRecordId: string;
};

export interface PaymentScheduleContainerProps {
  children: React.ReactNode;
}

const initialFormState = (): CalculatorFormState => ({
  startDate: undefined,
  endDate: undefined,
  isOpenEnded: false,
  frequency: undefined,
  lastMonthRevenue: undefined,
  customAmount: undefined,
  // This input only has two options, and we're just defaulting it here
  paymentProcessor: 'auto_ach',
  adjustmentNote: undefined,
  selectedAdjustment: undefined,
});

const initialSameDayAchFormState = (): SameDayAchFormState => ({
  paymentDate: new PlainDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  ),
  customAmount: undefined,
  adjustmentNote: undefined,
});

const isNoSchedule = (
  schedule: PaymentScheduleType | undefined
): schedule is NoSchedule => {
  return (
    schedule !== undefined &&
    !Array.isArray(schedule) &&
    schedule.isNotAvailable
  );
};

export const PaymentScheduleWrapper = (
  props: PaymentScheduleContainerProps
): JSX.Element => {
  const { advanceRecordId: advanceRecordIdString } = useParams<
    keyof Params
  >() as Params;
  const advanceRecordId = Number(advanceRecordIdString);

  const [invalidInputErrorMessage, setInvalidInputErrorMessage] = useState<
    string | null
  >(null);
  const [resumePaymentsMessage, setResumePaymentsMessage] = useState<
    string | null
  >(null);
  const [calculateError, setCalculateError] = useState<string | undefined>(
    undefined
  );
  const [scheduleAdjustmentCreationError, setScheduleAdjustmentCreationError] =
    useState<string | undefined>();
  const [isLoadingProjections, setIsLoadingProjections] = useState(false);

  const {
    schedule,
    error: paymentScheduleErrorMessage,
    loading: paymentScheduleLoading,
    refetch: refetchPaymentSchedule,
  } = useGetPaymentSchedule(advanceRecordId);
  const {
    profile,
    error: profileErrorMessage,
    loading: profileLoading,
  } = useGetAdvanceProfile(advanceRecordId);
  const {
    bankInfo,
    achProcessors,
    error: bankInfoErrorMessage,
    loading: bankInfoLoading,
    refetch: refetchBankInfo,
  } = useGetBankInfo(advanceRecordId);

  const [formState, setFormState] = useState<CalculatorFormState>(
    initialFormState()
  );

  const [sameDayAchFormState, setSameDayAchFormState] =
    useState<SameDayAchFormState>(initialSameDayAchFormState());

  const [adjustmentProjections, setAdjustmentProjections] = useState<
    ScheduleAdjustmentComparison[] | undefined
  >(undefined);

  const updateFormState = (newState: Partial<CalculatorFormState>): void => {
    setInvalidInputErrorMessage(null);
    setCalculateError(undefined);
    setScheduleAdjustmentCreationError(undefined);
    setResumePaymentsMessage(null);
    setFormState((prevFormState) => ({
      ...prevFormState,
      ...newState,
    }));
  };

  const updateSameDayAchFormState = (
    newState: Partial<SameDayAchFormState>
  ): void => {
    setSameDayAchFormState((prevFormState) => ({
      ...prevFormState,
      ...newState,
    }));
  };

  const resetForm = (): void => {
    setFormState(initialFormState());
    setAdjustmentProjections(undefined);
  };

  const resetSameDayAchForm = (): void => {
    setSameDayAchFormState(initialSameDayAchFormState());
  };

  const getAdjustmentProjection = async (): Promise<void> => {
    try {
      setIsLoadingProjections(true);
      const response = await getScheduleAdjustmentProjection(advanceRecordId, {
        firstPaymentDate: formState.startDate?.toString() ?? '',
        lastPaymentDate: formState.endDate?.toString() ?? '',
        paymentAmount: formState.customAmount,
        lastMonthsRevenue: formState.lastMonthRevenue,
        frequency:
          formState?.frequency === 'biweekly'
            ? 'weekly'
            : formState.frequency ?? 'daily',
        recurrency: formState?.frequency === 'biweekly' ? 2 : 1,
      });
      setAdjustmentProjections(response);
    } catch (e: unknown) {
      setAdjustmentProjections(undefined);
      const error = toError(e);
      setCalculateError(error.message);
    } finally {
      setIsLoadingProjections(false);
    }
  };

  const createPaymentScheduleAdjustment = async (): Promise<void> => {
    try {
      if (
        formState.frequency &&
        formState.startDate &&
        formState.selectedAdjustment &&
        formState.adjustmentReason
      ) {
        await createAdjustment(advanceRecordId, {
          amount: formState.selectedAdjustment.amount,
          startDate: formState.startDate.toString(),
          endDate: formState.endDate?.toString(),
          frequency:
            formState?.frequency === 'biweekly'
              ? 'weekly'
              : formState.frequency,
          recurrency: formState?.frequency === 'biweekly' ? 2 : 1,
          notes: formState.adjustmentNote ?? '',
          settlement: false,
          adjustmentReason: formState.adjustmentReason,
          /**
           * The Select for paymentProcessor only has two options:
           * - manual_other
           * - auto_ach
           * In the old version of this feature, auto_ach was actually an empty string,
           * but that is unclear, and doesn't work with the Fast Forward Select)
           *
           * We only actually send data to the backend in the case of manual_other
           * because...that's the way the backend works :shrug:
           *
           * @tyrelosaur - Oct 6, 2022
           */
          achProvider:
            formState.paymentProcessor === 'manual_other'
              ? 'manual_other'
              : null,
        });

        // only do this in the happy path, rather than a `finally` so
        // that we don't reset the form and cause someone to lose their work
        // due to bad input
        resetForm();
        void refetchPaymentSchedule();
      } else {
        setInvalidInputErrorMessage(
          'Start Date, Frequency, Selected Adjustment, and an Adjustment Type are required to create an adjustment'
        );
      }
    } catch (e: unknown) {
      const error = toError(e);
      setScheduleAdjustmentCreationError(error.message);
      FFLogger.error(error, {
        advanceRecordId,
      });
    }
  };

  const handleResumePayments = async (dateString: string): Promise<void> => {
    try {
      await createResumePaymentsAdjustment(advanceRecordId, dateString);

      void refetchPaymentSchedule();
      setResumePaymentsMessage(
        `The calendar will resume payments starting on ${formatDateString(
          dateString
        )}.`
      );
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error, {
        advanceRecordId,
      });
    }
  };

  if (paymentScheduleLoading || profileLoading || bankInfoLoading) {
    return (
      <Box m={4}>
        <Loading size="large" />
      </Box>
    );
  }

  if (isNoSchedule(schedule)) {
    return (
      <Box>
        <Flex flexDirection="column" gap={2} mb={2}>
          <Banner dismissable={false} variant="info">
            The payment calendar is not yet available because the advance is not
            funded.
          </Banner>
        </Flex>
      </Box>
    );
  }

  return (
    <PaymentScheduleProvider
      value={{
        advanceRecordId,
        paymentSchedule: schedule,
        advanceProfile: profile,
        bankInfo,
        achProcessors,
        formState,
        sameDayAchFormState,
        calculateError: calculateError,
        updateFormState,
        updateSameDayAchFormState,
        getAdjustmentProjection,
        adjustmentProjections,
        isLoadingAdjustmentProjections: isLoadingProjections,
        resetForm,
        resetSameDayAchForm,
        createPaymentScheduleAdjustment,
        createAdjustmentError: scheduleAdjustmentCreationError,
        resumePaymentsOn: handleResumePayments,
        refetchBankInfo,
        refetchPaymentSchedule,
      }}
    >
      <Box>
        <Flex flexDirection="column" gap={2} mb={2}>
          {resumePaymentsMessage && (
            <Banner dismissable={false} variant="success">
              {resumePaymentsMessage}
            </Banner>
          )}
          {invalidInputErrorMessage && (
            <Banner dismissable={false}>{invalidInputErrorMessage}</Banner>
          )}
          {paymentScheduleErrorMessage && (
            <Banner dismissable={false}>{paymentScheduleErrorMessage}</Banner>
          )}
          {profileErrorMessage && (
            <Banner dismissable={false}>{profileErrorMessage}</Banner>
          )}
          {bankInfoErrorMessage && (
            <Banner dismissable={false}>{bankInfoErrorMessage}</Banner>
          )}
          {profile?.writeOff && (
            <Banner dismissable={false}>
              Written Off on {formatDateString(profile.writeOff)}
            </Banner>
          )}
        </Flex>
        {props.children}
      </Box>
    </PaymentScheduleProvider>
  );
};

export const PaymentScheduleContainer = (): JSX.Element => {
  return (
    <PaymentScheduleWrapper>
      <PaymentSchedule />
    </PaymentScheduleWrapper>
  );
};
