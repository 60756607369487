import { useCallback } from 'react';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { fetchPayments } from 'api/funding/paymentFetchUtils';
import { PaybackBatchRecordResponse } from 'types/api/funding/types';

export type UseFetchPaymentsResponse = UseGenericQueryResponse<
  PaybackBatchRecordResponse[]
>;

export const useFetchPayments = (
  batchType: 'same_day' | 'batch' | 'one_time',
  page?: number
): UseFetchPaymentsResponse => {
  const fetcher = useCallback(() => {
    return fetchPayments(batchType, page);
  }, [batchType, page]);

  return useGenericQuery<PaybackBatchRecordResponse[]>(fetcher);
};

export type UseFetchBatchPaymentsResponse = UseGenericQueryResponse<
  PaybackBatchRecordResponse[]
>;
