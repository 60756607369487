import {
  CustomerResponse,
  PaymentHistoryAdvanceResponse,
  PaymentHistoryResponse,
} from 'types/api/funding/types';
import { makeInternalAPIRequest } from '../../api/makeInternalAPIRequest';
import { FUNDING_URL } from '../../constants/globals';
import { PaymentHistoryAdvance } from './PaymentHistory.types';

export interface MerchantResponse {
  id: number;
  dba: string;
}

export const fetchCustomer = (
  customerUuid: string
): Promise<MerchantResponse> => {
  const url = new URL(`/api/v2/merchants/${customerUuid}`, FUNDING_URL());

  return makeInternalAPIRequest<CustomerResponse>(url, 'GET')
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 404) {
        throw new Error('Customer not found');
      } else if (response.status === 403) {
        throw new Error('You are not authorized');
      }

      throw new Error(
        `${response.status} Error fetching customer data. Please reload the page and try again.`
      );
    })
    .then((response) => ({
      id: response.merchant.id,
      dba: response.merchant.dba,
    }));
};

const toAdvance = (
  advance: PaymentHistoryAdvanceResponse
): PaymentHistoryAdvance => {
  return {
    dateOfAdvance: advance.date_of_advance,
    opportunityUuid: advance.opportunity_uuid,
    amount: advance.amount,
    dailyPayment: advance.daily_payment,
    targetTerm: advance.target_term,
    program: advance.program,
    advanceNumber: advance.advance_number,
    rejectionsCount: advance.rejections_count,
    lastPaymentDate: advance.last_payment_date,
    expectedPaymentCount: advance.expected_payment_count,
    fullPaymentCount: advance.full_payment_count,
    reducedPaymentCount: advance.reduced_payment_count,
    missedPaymentCount: advance.missed_payment_count,
    paymentScheduleUrl: advance.payment_schedule_url,
    collectionsShortNote: advance.collections_short_note,
    collectionNotes: advance.latest_collection_notes.map((collection_note) => ({
      id: collection_note.id,
      note: collection_note.note,
      creatorId: collection_note.creator_id,
      creatorName: collection_note.creator_name,
      createdAt: collection_note.created_at,
    })),
    thirtyDayDollarPace: advance.thirty_day_dollar_pace,
    sixtyDayDollarPace: advance.sixty_day_dollar_pace,
    overallDollarPace: advance.overall_dollar_pace,
    overallPaymentCountPace: advance.overall_payment_count_pace,
  };
};

export const fetchPaymentHistory = (
  customerUuid: string
): Promise<PaymentHistoryAdvance[]> => {
  const url = new URL(
    `api/v2/renewal_comparisons?uuid=${customerUuid}`,
    FUNDING_URL()
  );

  return makeInternalAPIRequest<PaymentHistoryResponse>(url, 'GET')
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 404) {
        throw new Error('Payment history not found');
      } else if (response.status === 403) {
        throw new Error('You are not authorized');
      }

      throw new Error(
        `${response.status} Error while fetching payment history data. Please reload the page and try again.`
      );
    })
    .then((data) => {
      return data.advances.map((advance) => toAdvance(advance));
    });
};
