import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { UsState } from '../api/UnderwritingClient/codecs';

const findByType = (
  address_components: { short_name: string; types: string[] }[] | undefined,
  type: string
) => {
  const matchedComponent = address_components?.find((addr_component) =>
    addr_component.types.includes(type)
  );
  return matchedComponent?.short_name ?? '';
};

const validateUsState = (
  address_components: { short_name: string; types: string[] }[] | undefined,
  usStates: UsState[]
) => {
  const state = findByType(address_components, 'administrative_area_level_1');
  return usStates.find((usState) => usState.abbreviation === state)
    ? state
    : '';
};

interface GoogleAddressSearch {
  ready: boolean;
  value: string;
  suggestions: import('use-places-autocomplete').Suggestions;
  setValue: import('use-places-autocomplete').SetValue;
  getPlaceDetails: (
    description: string,
    placeId: string
  ) => Promise<
    { street1: string; city: string; state: string; zip: string } | undefined
  >;
}

export const useGoogleAddressSearch = (
  usStates: UsState[]
): GoogleAddressSearch => {
  const { ready, value, setValue, suggestions, clearSuggestions } =
    usePlacesAutocomplete({
      requestOptions: { componentRestrictions: { country: 'us' } },
    });

  const getPlaceDetails = (description: string, placeId: string) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    return getDetails({
      placeId: placeId,
      fields: ['address_components'],
    }).then((details) => {
      if (typeof details !== 'string') {
        const streetNumber = findByType(
          details.address_components,
          'street_number'
        );

        const route = findByType(details.address_components, 'route');

        const street1 = `${streetNumber} ${route}`;
        const city =
          findByType(details.address_components, 'locality') ||
          findByType(details.address_components, 'sublocality_level_1');
        const state = validateUsState(details.address_components, usStates);
        const zip = findByType(details.address_components, 'postal_code');

        return { street1, city, state, zip };
      }
      return undefined;
    });
  };

  return {
    ready,
    value,
    suggestions,
    setValue,
    getPlaceDetails,
  };
};
