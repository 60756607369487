import React, { useContext, createContext } from 'react';
import { NewDealScoreContextData } from './NewDealScoring.types';

const NewDealScoringContext = createContext<NewDealScoreContextData>({
  score: {
    ownerRisk: 0,
    businessRisk: 0,
    qualityOfCashFlows: 0,
    onlinePresence: null,
    dealSpecificFactors: null,
    underwriterNote: '',
    pricingRating: 0,
    processRating: 0,
    managerNote: '',
    checklistResponses: [],
  },
  systemScore: {
    businessRisk: 0,
    ownerRisk: 0,
    averageScore: 0,
  },
  isUnscored: true,
  isPartialSave: false,
  handleScoreUpdate: () => {
    // Intentionally empty - want void type for return
  },
  handleUnderwriterTextAreaChange: () => {
    // Intentionally empty - want void type for return
  },
  handleManagerTextAreaChange: () => {
    // Intentionally empty - want void type for return
  },
  hasUnsavedUnderwriterChanges: false,
  hasUnsavedManagerChanges: false,
  resetForm: () => {
    // Intentionally empty - want void type for return
  },
  response: null,
  onSubmit: () => Promise.resolve(),
  isAutosaving: false,
});

export const useNewDealScoringContext = (): NewDealScoreContextData =>
  useContext(NewDealScoringContext);

interface NewDealScoringProviderProps {
  value: NewDealScoreContextData;
  children: React.ReactNode;
}

export const NewDealScoringProvider = ({
  value,
  children,
}: NewDealScoringProviderProps): JSX.Element => {
  return (
    <NewDealScoringContext.Provider value={value}>
      {children}
    </NewDealScoringContext.Provider>
  );
};
