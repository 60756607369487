import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  List,
  Modal,
  Combobox,
  ComboboxProps,
  Subheading,
} from '@forward-financing/fast-forward';
import { Attachment, DocumentTags } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import { useUpdateAttachment } from './attachmentManagerHooks';

export interface BulkEditAttachmentsModalProps {
  selectedAttachments: Attachment[];
  onSaveComplete: () => void;
}

const toFormState = (
  attachments: Attachment[],
  allDocumentTags: DocumentTags
): ComboboxProps['values'] => {
  const tagsOnEveryDocument = Object.keys(allDocumentTags).filter((key) => {
    return attachments.every((att) => att.documentTags.includes(key));
  });

  return tagsOnEveryDocument.map((tag) => ({
    value: tag,
    text: allDocumentTags[tag],
  }));
};

export const BulkEditAttachmentModal = ({
  selectedAttachments,
  onSaveComplete,
}: BulkEditAttachmentsModalProps): JSX.Element => {
  const { primaryId, documentTags, reloadAttachmentManager } =
    useAttachmentManagerContext();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [formState, setFormState] = useState<ComboboxProps['values']>(
    toFormState(selectedAttachments, documentTags)
  );

  const [updateAttachment, { error: updateAttachmentError }] =
    useUpdateAttachment();

  const handleOpenChange = (): void => {
    setFormState(toFormState(selectedAttachments, documentTags));
    setIsOpen((p) => !p);
    setErrorMessage(undefined);
  };

  useEffect(() => {
    if (updateAttachmentError) {
      setErrorMessage(updateAttachmentError.message);
    } else {
      setErrorMessage(undefined);
    }
  }, [updateAttachmentError]);

  const onSubmit = async (): Promise<void> => {
    const attachments = selectedAttachments.map((att) => {
      return {
        submissionUuid: primaryId,
        attachmentUuid: att.uuid,
        input: {
          documentTags: formState.map((tag) => tag.value),
        },
      };
    });
    const { success } = await updateAttachment(attachments);

    if (success) {
      handleOpenChange();
      onSaveComplete();
      reloadAttachmentManager();
    }
  };

  return (
    <Modal
      title="Bulk Edit Attachments"
      trigger={
        <Button
          startIcon="file-pen"
          disabled={selectedAttachments.length === 0}
        >
          Tag Multiple
        </Button>
      }
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      body={
        <Flex flexDirection="column" pb={6} gap={3}>
          <Box>
            {errorMessage && <Banner variant="error">{errorMessage}</Banner>}
            <Subheading variant="section">Selected Attachments</Subheading>
            <List>
              {selectedAttachments.map((att) => (
                <List.Item key={att.uuid}>{att.fileName}</List.Item>
              ))}
            </List>
          </Box>
          <Combobox
            closeMenuOnSelect={false}
            isMulti
            label="Document Tags"
            placeholder="Document Tags"
            values={formState}
            onValueChange={setFormState}
            options={Object.entries(documentTags).map(([key, pretty]) => ({
              value: key,
              text: pretty,
            }))}
          />
          <Flex justifyContent="center" gap={2}>
            <Button onClick={onSubmit}>Save</Button>
            <Button variant="secondary" onClick={handleOpenChange}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};
