import {
  Address,
  Account as AccountType,
  Submission,
  Customer,
  Application as ApplicationType,
  Contact,
  WebPresence as WebPresenceInterface,
  WebPresenceType,
  WebPresences,
  BankInfo,
  Decline,
  PrequalState,
  Opportunity,
} from '../codecs';
import { v4 as uuidv4 } from 'uuid';
import { SubmissionSource } from '../../../constants/globals';

export enum SubmissionObject {
  Application = 'application',
  Account = 'account',
  AccountAddress = 'accountAddress',
  Contacts = 'contacts',
  ContactAddress = 'contactAddress',
  Ownership = 'ownership',
  WebPresence = 'webPresence',
  BankInformation = 'bankInformation',
}

export const generateEmptyWebPresence = (
  type: WebPresenceType
): WebPresenceInterface => ({
  url: '',
  web_presence_type: type,
  urlChanged: false,
});

export const generateEmptyWebPresences = (): WebPresences => ({
  business_website: generateEmptyWebPresence(WebPresenceType.Website),
  facebook: generateEmptyWebPresence(WebPresenceType.Facebook),
  instagram: generateEmptyWebPresence(WebPresenceType.Instagram),
  yelp: generateEmptyWebPresence(WebPresenceType.Yelp),
  other: generateEmptyWebPresence(WebPresenceType.Other),
});

export const generateEmptyAddress = (): Address => ({
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
});

export const generateEmptyContact = (): Contact => ({
  uuid: '',
  first_name: '',
  last_name: '',
  born_on: '',
  email: '',
  home_phone: '',
  cell_phone: '',
  ssn: '',
  title: '',
  ownership_percentage: 0,
  address: generateEmptyAddress(),
  _destroy: false,
  ssnNotPresent: false,
  bornOnNotPresent: false,
  key: uuidv4(),
});

export const generateEmptyBankInfo = (): BankInfo => ({
  id: null,
  account_holder_full_name: '',
  bank_name: '',
  city: '',
  state: '',
  zip: '',
  account_number: '',
  routing_number: '',
  bank_id: '',
});

export const generateEmptyAccount = (): AccountType => ({
  uuid: '',
  entity_type: '',
  started_on: '',
  industry_id: 0,
  name: '',
  legal_name: '',
  fein: '',
  phone: '',
  created_at: new Date(),
  updated_at: new Date(),
  address: generateEmptyAddress(),
  dbaSameAsLegalName: false,
  feinNotPresent: false,
  web_presences: generateEmptyWebPresences(),
  bank_account: generateEmptyBankInfo(),
});

export const generateInitialPrequalState = (): PrequalState => ({
  prequal_started_at: new Date(),
  prequal_completed_at: null,
  account_completed: false,
  customer_search_results_reviewed: null,
  owners_completed: false,
  bank_accounts_completed: false,
  other_info_completed: false,
  sent_to_processing: false,
  selected_customer: null,
});

export const generateEmptyApplication = (): ApplicationType => ({
  uuid: '',
  notes: '',
  record_id: 0,
  loan_use: '',
  capital_needed: '',
  capitalNeededNotPresent: false,
  partner_id: 0,
  partner_uuid: '',
  customer_uuid: '',
  prequal_state_attributes: generateInitialPrequalState(),
  ownership_percentages: [],
  submission_source: SubmissionSource.OTHER,
  sales_rep_email: '',
  available_renewal_url: null,
});

export const generateEmptyDecline = (): Decline => ({
  decline_driver_notes: '',
  decline_drivers: [],
});

export const generateEmptySubmission = (): Submission => ({
  ...generateEmptyApplication(),
  account: generateEmptyAccount(),
  contacts: [generateEmptyContact()],
  ownership_percentages: [],
  decline: generateEmptyDecline(),
});

export const generateEmptyCustomer = (): Customer => ({
  uuid: '',
  name: '',
  legal_name: '',
  fein: '',
  phone: '',
  created_at: new Date(),
  updated_at: new Date(),
  address: generateEmptyAddress(),
});

export const generateEmptyOpportunity = (): Opportunity => ({
  application_uuid: '',
  decline_driver_notes: '',
  decline_drivers: '',
  name: '',
  owner_1_uuid: '',
  owner_2_uuid: '',
  prequal_analyst_name: '',
  processing_analyst_id: null,
  renewal_submitter_email: '',
  stage_name: '',
  sub_stage: '',
  submission_source: '',
  type: '',
  uuid: '',
  account: generateEmptyAccount(),
  underwriter_sign_off_timestamp: null,
  underwriter_sign_off_name: '',
  underwriter_sign_off_id: null,
  x2dc_underwriter__c: '',
  decision_analyst_id: null,
});
