import React from 'react';
import { Flex } from '@forward-financing/fast-forward';
import { ValidationResult } from '../../../helpers/validations/codecs';
import { ValidationMessage } from './ValidationMessage';

export interface TextAreaProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  validationResult?: ValidationResult;
  required?: boolean;
  moreInfoPopover?: React.ReactNode;
}

export const TextArea = (props: TextAreaProps): JSX.Element => {
  const isEmpty = props.validationResult && !props.validationResult.hasValue;
  const isInvalid = props.validationResult && !props.validationResult.isValid;
  const className = isInvalid || isEmpty ? 'input is-danger' : 'input';
  const rowNumber = props.rows ? props.rows : 4;
  const renderValidationIcon = () => {
    return isEmpty ? (
      <span className="validation-icon">
        <i className="fas fa-exclamation-circle " />
      </span>
    ) : null;
  };

  return (
    <div className="field">
      <label className="label has-text-weight-bold">
        <Flex>
          {renderValidationIcon()}
          {props.label}
          {props.moreInfoPopover}
        </Flex>
      </label>
      <div className="control">
        <textarea
          aria-label={props.label}
          className={`textarea ${className}`}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
          rows={rowNumber}
        />
        <ValidationMessage validationResult={props.validationResult} />
      </div>
    </div>
  );
};
