import {
  Flex,
  Link,
  Text,
  Subheading,
  List,
  Button,
} from '@forward-financing/fast-forward';
import React from 'react';

export interface HelpInstructionsProps {
  handleOpenChange: () => void;
}

export const HelpInstructions = ({
  handleOpenChange,
}: HelpInstructionsProps): JSX.Element => {
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <Flex flexDirection={'column'}>
        <Subheading variant={'section'}>DATA MERCH</Subheading>

        <Link newTab href={new URL('https://www.datamerch.com/dashboard')}>
          Data Merch Website
        </Link>
        <Text bold={true} size="l">
          Process the submission in the following cases:
        </Text>

        <List ordered={false}>
          <List.Item>
            Stacking, NSFs (Non-Sufficient Funds), COVID payment reduction or
            any reason that is not a total default
          </List.Item>
          <List.Item>
            Slow Pay (unless it is clearly a confirmed default)
          </List.Item>
          <List.Item>
            Data Merch filing where UW have an open approval last 30 days
          </List.Item>
        </List>

        <Text bold={true} size="l">
          Decline the submission in the following cases:
        </Text>

        <List ordered={false}>
          <List.Item>Fraud</List.Item>
          <List.Item>Altered bank statements</List.Item>
          <List.Item>Confirmed default (In Collections)</List.Item>
          <List.Item>
            Judgment history found and flagged in the data merch
          </List.Item>
          <List.Item>Closed Account after funding</List.Item>
          <List.Item>Charged off</List.Item>
        </List>

        <Subheading variant={'section'}>DOT</Subheading>

        <Text bold={true} size="l">
          Trucking & Transportation DOT
        </Text>

        <List ordered={false}>
          <List.Item>
            <Text bold={true}>Auto-decline:</Text>
            <List ordered={false}>
              <List.Item>
                If DOT shows the business to be Not Authorized / Out of Service
                / Inactive
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            <Text bold={true}>Process the submission:</Text>
            <List ordered={false}>
              <List.Item>If DOT record is not found</List.Item>
              <List.Item>
                If business operates under contract for another company under
                their DOT
              </List.Item>
            </List>
          </List.Item>
        </List>

        <Subheading variant={'section'}>PULLING CREDIT REPORT</Subheading>

        <Text>
          Before proceeding to pull the Credit Report please make sure that the
          Original Application has the owner’s signature and that it was signed
          in the last 30 days
        </Text>

        <Subheading variant={'section'}>FICO</Subheading>

        <Text bold={true} size={'l'}>
          FICO with two owners
        </Text>

        <Text>
          Send submission to Processing if the submission has 2 owners and one
          of them has problems related to the trade lines (no active trade
          lines, several months behind in mortgage or multiple months past due
          on several trade lines, also 9001, 9002, 9003) and the other owner is
          clear, regardless of the % ownership
        </Text>

        <Subheading variant={'section'}>TRADELINES</Subheading>

        <List ordered={false}>
          <List.Item>
            Trade lines with the Account Status of Inactive Account, Terminated,
            Paid Off / Zero Balance or Transferred to another account are not
            considered to be active tradelines
          </List.Item>
          <List.Item>
            Educational and Medical Tradelines with a “0” in the Current Status
            column in the last 2 complete months in the current year are not
            considered to be active trade lines
          </List.Item>
          <List.Item>
            Educational and Medical Tradelines going from 2 to 5 (60 to 150 days
            past due date) in the Current Status Column are not considered to be
            delinquent
          </List.Item>
          <List.Item>
            <Text bold={true}>Auto-decline:</Text>
            <List ordered={false}>
              <List.Item>
                Mortgage that is delinquent going from 2 to 5 (60 to 150 days
                past due date) in the Current Status Column in the last 2
                complete months in the current year
              </List.Item>
              <List.Item>
                Submission that doesn’t have any active trade lines
              </List.Item>
              <List.Item>
                Submission with more than 1 delinquent trade line
              </List.Item>
            </List>
          </List.Item>
        </List>

        <Subheading variant={'section'}>PUBLIC RECORDS – CR/LN</Subheading>

        <Text bold={true} size="l">
          Check public records for bankruptcy status (CR/LN)
        </Text>

        <List ordered={false}>
          <List.Item>
            <Text bold={true}>Auto-decline:</Text>
            <List ordered={false}>
              <List.Item>Petition Filed or Open</List.Item>
              <List.Item>
                Dismissed Bankruptcy – if it’s within the year (in last 12
                months on owner or business)
              </List.Item>
              <List.Item>Converted</List.Item>
            </List>
          </List.Item>
          <List.Item>
            <Text bold={true}>Process the submission:</Text>
            <List ordered={false}>
              <List.Item>Discharged Bankruptcy</List.Item>
              <List.Item>Vacated</List.Item>
            </List>
          </List.Item>
        </List>

        <Subheading variant={'section'}>PUBLIC RECORDS – LN</Subheading>

        <Text bold={true} size="l">
          Check public records for judgements (LN)
        </Text>

        <List ordered={false}>
          <List.Item>
            <Text bold={true}>Auto-decline:</Text>
            <List ordered={false}>
              <List.Item>Judgment related to MCA</List.Item>
              <List.Item>Settled judgments</List.Item>
              <List.Item>Judgment open with 0.00 in balance</List.Item>
              <List.Item>
                Multiple MCA judgments: Put in the decline driver notes the
                judgment that has the most recent and largest amount of money
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            When there is a previous decline for judgment over 30 days, pull the
            LN and check if the MCA Creditor still remains
          </List.Item>
        </List>

        <Subheading variant={'section'}>
          MANUALLY PULLING LEXIS NEXIS (LN)
        </Subheading>

        <Link
          newTab
          href={
            new URL(
              'https://docs.google.com/document/d/1wAw36kF5-Sku1LhovHV4DD9-kKhrdSB9/edit'
            )
          }
        >
          Step by Step Instructions
        </Link>

        <List ordered={false}>
          <List.Item>Search owner&apos;s LN report using SSN</List.Item>
          <List.Item>
            Search business LN report using any combination that includes
            company name, FEIN, or business address
          </List.Item>
        </List>

        <Text bold={true} size="l">
          Remember:
        </Text>

        <List ordered={false}>
          <List.Item>
            Search with the previous addresses of the business registered in the
            owner&apos;s LN
          </List.Item>
          <List.Item>
            Confirm if the company has not been registered in another state
          </List.Item>
        </List>

        <Subheading variant={'section'}>MANUALLY PULLING PAYNET</Subheading>

        <Text bold={true} size="l">
          Reviewing Criminal Filings
        </Text>

        <Text bold={true} size="l">
          Auto-decline:
        </Text>

        <List ordered={false}>
          <List.Item>
            If there is a heavy criminal charge even if it does not have a date
            or has a daily or weekly position into the last 3 months
          </List.Item>
          <List.Item>
            Previous submissions declined by the UW Team for Presence of
            Criminal Filings related to federal reports declined for 5 months
            after Underwriter made the first decline
          </List.Item>
          <List.Item>
            If a submission that was previously received and declined due to a
            particular case with an owner and now was received with a different
            owner, and the account has less than 30 days
          </List.Item>
          <List.Item>
            1 criminal filing from less than 10 years without positions
          </List.Item>
          <List.Item>
            Multiple criminal filing from less or more than 10 years without
            positions
          </List.Item>
        </List>
      </Flex>

      <Button onClick={handleOpenChange}>Close</Button>
    </Flex>
  );
};
