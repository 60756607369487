import { differenceInYears } from 'date-fns';

export const isDateInPast = (date: Date): boolean => {
  return date.getTime() < Date.now();
};

// This helper exists to determine if a date is before 1799,
// which we use when checking a business' start date
export const isStartDateTooOld = (date: Date): boolean => {
  return date.getFullYear() <= 1799;
};

export const isAtLeast18YearsOld = (date: Date): boolean => {
  return differenceInYears(new Date(), date) >= 18;
};

export const isParseableDate = (date: string): boolean => {
  return !Number.isNaN(Date.parse(date));
};
