import React from 'react';
import { Text, Table } from '@forward-financing/fast-forward';
import { Foreclosure } from './ownerLexisNexisPage.types';

export type ForeclosureTableProps = {
  foreclosureData: Foreclosure[];
};

export const ForeclosureTable = ({
  foreclosureData,
}: ForeclosureTableProps): JSX.Element => {
  return (
    <Table caption={'Foreclosure'}>
      <Table.Head>
        <Table.ColumnHeader>Site Address</Table.ColumnHeader>
        <Table.ColumnHeader>Recording Date</Table.ColumnHeader>
        <Table.ColumnHeader>Defendant</Table.ColumnHeader>
        <Table.ColumnHeader>Land Usage</Table.ColumnHeader>
        <Table.ColumnHeader>Deed Type</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {foreclosureData.map((row) => (
          <Table.Row key={`${row.deedType}${row.defendant}${row.landUsage}`}>
            <Table.Cell columnWidth="large">{row.siteAddress}</Table.Cell>
            <Table.Cell>{row.recordingDate}</Table.Cell>
            <Table.Cell>
              {row.defendant.length > 0 &&
                row.defendant.map((def) => <Text key={def}>{def}</Text>)}
            </Table.Cell>
            <Table.Cell>{row.landUsage}</Table.Cell>
            <Table.Cell>{row.deedType}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
