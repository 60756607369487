import React from 'react';
import { Flex, Box, Heading, Text } from '@forward-financing/fast-forward';

export interface PrequalCompleteProps {
  DBA: string;
}

export const PrequalComplete = (props: PrequalCompleteProps): JSX.Element => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box marginY={4}>
        <Heading>Prequal Complete</Heading>
      </Box>
      <Box marginBottom={4}>
        <Text>
          You have completed the prequalification process for{' '}
          <Text bold>{props.DBA}</Text>
        </Text>
      </Box>
    </Flex>
  );
};
