import { fetchUser, fetchUsersByRole } from 'api/auth/usersFetchUtils';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import {
  AuthenticatedUserResponse,
  UserByRoleResponse,
} from 'types/api/auth/types';

export const useGetApiUsersByRole = (
  role?: string
): UseGenericQueryResponse<UserByRoleResponse[]> => {
  const getUsers = useCallback(() => {
    if (!role) {
      return undefined;
    }
    return fetchUsersByRole(role);
  }, [role]);

  return useGenericQuery(getUsers);
};

export const useGetApiUser = (
  id?: string | number
): UseGenericQueryResponse<AuthenticatedUserResponse> => {
  const getUser = useCallback(() => {
    if (!id) {
      return undefined;
    }
    return fetchUser(id);
  }, [id]);

  return useGenericQuery(getUser);
};
