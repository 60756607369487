import React from 'react';
import { Box, Link, Subheading, Text } from '@forward-financing/fast-forward';

export const NotAuthorized = (): JSX.Element => {
  const portalUrl = new URL(
    'https://forwardfinancing.atlassian.net/servicedesk/customer/portal/1'
  );

  return (
    <Box textAlign="center" margin={4} paddingY={4}>
      <Box marginY={4}>
        <Subheading>You are not authorized to view this page.</Subheading>
        <Text>
          Please check the URL for any typos, and if you still think that you
          received this message in error, please report the issue through the{' '}
          <Link newTab href={portalUrl} title="Help Center">
            Help Center
          </Link>
          .
        </Text>
      </Box>
    </Box>
  );
};
