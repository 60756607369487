import React from 'react';
import { Table, Text } from '@forward-financing/fast-forward';

export interface PricingStrategyGrossTableProps {
  title: string;
  pricingStrategyProfiles: {
    id: number;
    revenue: string;
    high_risk: string;
    moderate_risk: string;
    low_risk: string;
  }[];
}

export const PricingStrategyGrossTable = (
  props: PricingStrategyGrossTableProps
): JSX.Element => {
  return (
    <>
      <Text bold={true}>{props.title}</Text>
      <Table caption={props.title}>
        <Table.Head>
          <Table.ColumnHeader>Revenue</Table.ColumnHeader>
          <Table.ColumnHeader>High Risk Industry</Table.ColumnHeader>
          <Table.ColumnHeader>Moderate Risk Industry</Table.ColumnHeader>
          <Table.ColumnHeader>Low Risk Industry</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {props.pricingStrategyProfiles.map((r) => (
            <Table.Row key={r.id}>
              <Table.Cell>{r.revenue}</Table.Cell>
              <Table.Cell>{r.high_risk}</Table.Cell>
              <Table.Cell>{r.moderate_risk}</Table.Cell>
              <Table.Cell>{r.low_risk}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
