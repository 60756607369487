import React from 'react';
import experianLogo from './images/experianLogo.png';
import { Box, Flex, Subheading, Text } from '@forward-financing/fast-forward';

export interface BusinessSummaryHeaderProps {
  name: string;
  id: string;
  address: string;
}

export const BusinessSummaryHeader = ({
  name,
  id,
  address,
}: BusinessSummaryHeaderProps): JSX.Element => {
  return (
    <>
      <Flex
        justifyContent="flex-start"
        padding={3}
        gap={4}
        backgroundColor="gray-200"
      >
        <Box>
          <img src={experianLogo as string} alt="Experian logo" />
        </Box>

        <Flex flexDirection="column">
          <Subheading>
            {name} [ID# {id}]
          </Subheading>
          <Text>{address}</Text>
        </Flex>
      </Flex>
    </>
  );
};
