import React, { useState } from 'react';
import { RequestDetails } from './RequestDetails';
import { ExceptionRequest } from '../exceptionsRequest.types';
import { ExceptionsRequestDeclineForm } from './ExceptionsRequestDeclineForm';
import {
  Banner,
  Box,
  Button,
  Flex,
  Modal,
  Text,
} from '@forward-financing/fast-forward';
import { useDeclineReasons } from '../exceptionsRequestHooks';

export interface ExceptionsRequestRowProps {
  exceptionRequest: ExceptionRequest;
  loadList: () => void;
}

export const ExceptionsRequestRow = (
  props: ExceptionsRequestRowProps
): JSX.Element => {
  const [displayDeclineModal, setDisplayDeclineModal] = useState(false);

  function handleDisplayDeclineModalChange(): void {
    setDisplayDeclineModal(!displayDeclineModal);
  }

  const { declineReasons, error: declineReasonsError } = useDeclineReasons();

  return (
    <Box p={3}>
      {declineReasonsError && (
        <Banner dismissable={false}>
          <Text>{declineReasonsError.message}</Text>
        </Banner>
      )}
      <RequestDetails exceptionRequest={props.exceptionRequest} />
      {props.exceptionRequest.inReview && declineReasons && (
        <Flex justifyContent="flex-end">
          <Modal
            title="Please confirm your decision to decline this exception request."
            isOpen={displayDeclineModal}
            onOpenChange={handleDisplayDeclineModalChange}
            trigger={
              <Button variant="danger" startIcon="thumbs-down">
                Decline Request
              </Button>
            }
            body={
              <ExceptionsRequestDeclineForm
                onExit={handleDisplayDeclineModalChange}
                onSave={props.loadList}
                requestId={props.exceptionRequest.id}
                declineReasons={declineReasons}
              />
            }
          />
        </Flex>
      )}
    </Box>
  );
};
