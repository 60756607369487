import React from 'react';
import { RenewalComparison } from './RenewalComparison';
import { useParams } from 'react-router-dom';
import { Banner, Box, Loading } from '@forward-financing/fast-forward';
import { useCoreIdFromSalesforceId } from 'hooks/useCoreIdFromSalesforceId.hook';

type Params = {
  submissionSalesforceId: string;
};

export const RenewalComparisonSalesforceContainer = (): JSX.Element => {
  const { submissionSalesforceId } = useParams<keyof Params>() as Params;

  if (!submissionSalesforceId) {
    throw new Error('No submissionSalesforceId param set in URL');
  }

  const {
    coreId: opportunityUuid,
    loading,
    error,
  } = useCoreIdFromSalesforceId(submissionSalesforceId, 'submission');

  if (loading) {
    return (
      <Box p={3}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Banner dismissable={false}>{error}</Banner>
      </Box>
    );
  }

  if (!opportunityUuid) {
    return (
      <Box p={3}>
        <Banner dismissable={false}>
          Unable to find Submission. Please reload the page and try again.
        </Banner>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <RenewalComparison opportunityUuid={opportunityUuid} />
    </Box>
  );
};
