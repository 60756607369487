import {
  Banner,
  Box,
  Card,
  Flex,
  IconButton,
  Link,
  List,
  Loading,
  Select,
  Subheading,
  Tag,
  Text,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { defaultTo } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ApplicationResponseOnboardingStatus } from 'types/api/waitingRoom/types';
import {
  useAssignUserNextApplication,
  useAttachments,
  useGetUsersByRole,
  useQueueItem,
  useUpdateApplication,
  useUpdateUnderwritingApplication,
} from './SubmissionOnboardingQueueHooks';
import {
  displayUserName,
  userToOptionShape,
} from './submissionOnboardingHelpers';
import { OnboardingStatusConfirmationModal } from './OnboardingStatusConfirmationModal';

export type StatusTagProps = {
  status: ApplicationResponseOnboardingStatus;
};

export const StatusTag = ({ status }: StatusTagProps): JSX.Element => {
  switch (status) {
    case 'in_progress':
      return <Tag bgColor="info">IN PROGRESS</Tag>;
    case 'available':
      return <Tag bgColor="primary">AVAILABLE</Tag>;
    case 'waiting_on_iso':
      return <Tag bgColor="warning">WAITING ON ISO</Tag>;
    case 'junk':
      return <Tag bgColor="danger">JUNK</Tag>;
    case 'completed':
      return <Tag bgColor="success">COMPLETED</Tag>;
    case 'not_ready':
    default:
      return <Tag bgColor="black">NOT READY</Tag>;
  }
};

export type SubmissionOnboardingApplicationDetailProps = {
  applicationUuid: string;
};

export const SubmissionOnboardingApplicationDetail = ({
  applicationUuid,
}: SubmissionOnboardingApplicationDetailProps): JSX.Element => {
  const [isEditingAssignee, setIsEditingAssignee] = useState(false);

  const {
    data: application,
    loading: applicationLoading,
    error: applicationError,
  } = useQueueItem(applicationUuid);

  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
  } = useAttachments(applicationUuid);

  const {
    data: prequalUsers,
    loading: prequalUsersLoading,
    error: prequalUsersError,
  } = useGetUsersByRole('prequal');

  const {
    data: teamLeadUsers,
    loading: teamLeadUsersLoading,
    error: teamLeadUsersError,
  } = useGetUsersByRole('processing_team_lead');

  const [
    updateApplication,
    { data: updatedApplication, error: errorUpdatingApplication },
  ] = useUpdateApplication(applicationUuid);

  const [
    assignUser,
    { data: assignedApplication, error: nextApplicationError },
  ] = useAssignUserNextApplication();

  const [
    updateUnderwritingApplication,
    { error: errorUpdatingUnderwritingApplication },
  ] = useUpdateUnderwritingApplication();

  const navigate = useNavigate();

  const users = [...(prequalUsers || []), ...(teamLeadUsers || [])];

  const handleUpdateStatus = async (
    status: ApplicationResponseOnboardingStatus
  ): Promise<void> => {
    const { success } = await updateApplication({ onboardingStatus: status });

    if (success) {
      navigate('/dashboard/submission-onboarding/queue');
    }
  };

  if (
    applicationLoading ||
    attachmentsLoading ||
    teamLeadUsersLoading ||
    prequalUsersLoading
  ) {
    return <Loading />;
  }

  const handleApplicationAssignment = async (
    newVal?: string
  ): Promise<void> => {
    let requestSuccess: boolean;

    if (newVal) {
      const { success } = await assignUser(newVal, applicationUuid);

      requestSuccess = success;
    } else {
      // GoLang doesn't have a global null type, so we need to send a null UUID value
      // to update this field to unassigned in place of other falsey values.
      const { success: assignSuccess } = await assignUser(
        '00000000-0000-0000-0000-000000000000',
        applicationUuid
      );

      if (assignSuccess) {
        await updateApplication({
          onboardingStatus: 'available',
        });

        requestSuccess = true;
      } else {
        requestSuccess = false;
      }
    }

    if (requestSuccess) {
      if (application?.underwritingApplicationUuid) {
        const newUserName = displayUserName(users || [], newVal);

        await updateUnderwritingApplication({
          applicationUuid: application.underwritingApplicationUuid,
          prequalAnalystName: ['Unassigned', 'Unknown'].includes(newUserName)
            ? ''
            : newUserName,
        });
      }

      setIsEditingAssignee(false);
    }
  };

  const assignee =
    assignedApplication?.onboardingAnalystUuid ||
    application?.onboardingAnalystUuid;

  const applicationStatus =
    updatedApplication?.onboardingStatus || application?.status;

  return (
    <Box marginTop={3} marginX={4} padding={2}>
      {applicationError && (
        <Banner variant="error">{applicationError.message}</Banner>
      )}
      {errorUpdatingApplication && (
        <Banner variant="error">{errorUpdatingApplication.message}</Banner>
      )}
      {nextApplicationError && (
        <Banner variant="error">{nextApplicationError.message}</Banner>
      )}
      {errorUpdatingUnderwritingApplication && (
        <Banner variant="error">
          {errorUpdatingUnderwritingApplication.message}
        </Banner>
      )}
      {attachmentsError && (
        <Banner variant="error">{attachmentsError.message}</Banner>
      )}
      {teamLeadUsersError && (
        <Banner variant="error">{teamLeadUsersError.message}</Banner>
      )}
      {prequalUsersError && (
        <Banner variant="error">{prequalUsersError.message}</Banner>
      )}

      {application && (
        <>
          <Flex marginX={3} justifyContent="space-between">
            <Flex gap={2} alignItems="center">
              <Subheading variant="section">Status</Subheading>
              {applicationStatus && <StatusTag status={applicationStatus} />}
              <Box mx={2}>
                {applicationStatus !== 'junk' && (
                  <OnboardingStatusConfirmationModal
                    status="junk"
                    trigger={<IconButton icon="trash" label="Mark as Junk" />}
                    onConfirmation={() => handleUpdateStatus('junk')}
                  />
                )}
              </Box>
            </Flex>

            <Flex gap={2} alignItems="center">
              <Subheading variant="section">Assignee</Subheading>
              {isEditingAssignee ? (
                <Select
                  hiddenLabel={true}
                  label="Assignee"
                  options={userToOptionShape(users)}
                  value={defaultTo(assignee, '')}
                  onValueChange={(newVal) =>
                    handleApplicationAssignment(newVal)
                  }
                />
              ) : (
                <>
                  <Text size="l">{displayUserName(users, assignee)}</Text>
                  {application.status !== 'completed' && (
                    <IconButton
                      hiddenLabel={true}
                      icon="pencil"
                      onClick={() => setIsEditingAssignee(true)}
                      label={'Edit Assignee'}
                    />
                  )}
                </>
              )}
            </Flex>
          </Flex>

          <Card
            title={application.emailSubject}
            actions={
              applicationStatus !== 'completed' && [
                <OnboardingStatusConfirmationModal
                  key="complete-button"
                  status="completed"
                  trigger={<IconButton icon="check" label="Mark as Complete" />}
                  onConfirmation={() => handleUpdateStatus('completed')}
                />,
              ]
            }
          >
            <Box paddingBottom={3}>
              <Flex gap={2} alignItems="baseline">
                <Text size="l" bold>
                  Subject
                </Text>
                <Text size="l">{application.emailSubject}</Text>
              </Flex>

              <Flex gap={2} alignItems="baseline">
                <Text size="l" bold>
                  From
                </Text>
                <Text size="l">{application.emailSender}</Text>
              </Flex>

              {application.prequalFormUrl && (
                <Flex gap={2} alignItems="baseline">
                  <Text size="l" bold>
                    Links
                  </Text>
                  <Link newTab href={new URL(application.prequalFormUrl)}>
                    Prequal form
                  </Link>
                </Flex>
              )}
            </Box>

            {attachments && attachments.length > 0 && (
              <Card title={'Attachments'} collapsible>
                <List>
                  {attachments.map((attachment) => (
                    <List.Item key={attachment.uuid}>
                      {attachment.presignedUrl && (
                        <Link newTab href={new URL(attachment.presignedUrl)}>
                          {attachment.originalFilename}
                        </Link>
                      )}
                    </List.Item>
                  ))}
                </List>
              </Card>
            )}

            <Box marginTop={3}>
              <Text size="l" bold>
                Email Body
              </Text>
              <Box backgroundColor="gray-100" padding={3}>
                <Text preserveNewLines size="l">
                  {application.emailBody}
                </Text>
              </Box>
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};
