import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import {
  CreateUnderwritingExceptionRequestBody,
  CreateUnderwritingExceptionRequestInput,
  DeclineExceptionRequestRequestBody,
  UnderwritingExceptionRequestsResponse,
  UpdateUnderwritingExceptionRequestBody,
  UpdateUnderwritingExceptionRequestInput,
} from 'types/api/banking/types';

export const fetchSubmissionExceptionRequests = async (
  submissionUuid: string
): Promise<UnderwritingExceptionRequestsResponse> => {
  const url = new URL(
    `/api/v1/opportunities/${submissionUuid}/underwriting_exception_requests`,
    BANK_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<UnderwritingExceptionRequestsResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Exception Requests'
    );
  }

  return response.json();
};

export const createExceptionRequest = async (
  submissionUuid: string,
  exceptionRequest: CreateUnderwritingExceptionRequestInput
): Promise<{ success: boolean }> => {
  const url = new URL(
    `/api/v1/opportunities/${submissionUuid}/underwriting_exception_requests`,
    BANK_BASE_URL()
  );

  /**
   * This endpoint returns a 201 response with
   * no response payload.
   */
  const response = await makeInternalAPIRequest<
    void,
    CreateUnderwritingExceptionRequestBody
  >(url, 'POST', {
    underwriting_exception_request: exceptionRequest,
  });

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to create exception request'
    );
  }

  return { success: true };
};

export const updateExceptionRequest = async (
  exceptionRequestId: number,
  exceptionRequest: UpdateUnderwritingExceptionRequestInput
): Promise<{ success: boolean }> => {
  const url = new URL(
    `api/v1/underwriting_exception_requests/${exceptionRequestId}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    void,
    UpdateUnderwritingExceptionRequestBody
  >(url, 'PATCH', {
    underwriting_exception_request: exceptionRequest,
  });

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to update exception request'
    );
  }

  return { success: true };
};

export const declineExceptionRequest = async (
  exceptionRequestId: number,
  requestBody: DeclineExceptionRequestRequestBody
): Promise<{ success: boolean }> => {
  const url = new URL(
    `/api/v1/underwriting_exception_decisions/${exceptionRequestId}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    void,
    DeclineExceptionRequestRequestBody
  >(url, 'PATCH', requestBody);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to decline exception request'
    );
  }

  return { success: true };
};
