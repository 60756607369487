import React from 'react';
import {
  Subheading,
  Table,
  formatDateString,
} from '@forward-financing/fast-forward';
import { ExperianConsumerPublicRecord } from '../types';

export type ExperianConsumerDetailProps = {
  publicRecords: ExperianConsumerPublicRecord[];
};

export const ExperianConsumerDetailPublicRecords = ({
  publicRecords,
}: ExperianConsumerDetailProps): JSX.Element => {
  return (
    <>
      <Subheading>Public Records</Subheading>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Court Name / Code</Table.ColumnHeader>
          <Table.ColumnHeader>Type / Status</Table.ColumnHeader>
          <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
          <Table.ColumnHeader>Status Date</Table.ColumnHeader>
          <Table.ColumnHeader>Responsibility</Table.ColumnHeader>
          <Table.ColumnHeader>Docket #</Table.ColumnHeader>
        </Table.Head>

        {publicRecords.length === 0 && (
          <Table.Body>
            <Table.Row>
              <Table.Cell>No public records found</Table.Cell>
            </Table.Row>
          </Table.Body>
        )}

        {publicRecords.length > 0 && (
          <Table.Body>
            {publicRecords.map((publicRecord) => {
              const filingDate = formatDateString(
                publicRecord.filingDate,
                'N/A'
              );
              const statusDate = formatDateString(
                publicRecord.statusDate,
                'N/A'
              );

              return (
                <Table.Row key={publicRecord.referenceNumber}>
                  <Table.Cell>{`${publicRecord.courtName} / ${publicRecord.courtCode}`}</Table.Cell>

                  <Table.Cell>{`${publicRecord.recordType} Chapter ${publicRecord.chapter} - ${publicRecord.status}`}</Table.Cell>

                  <Table.Cell>{filingDate}</Table.Cell>

                  <Table.Cell>{statusDate}</Table.Cell>

                  <Table.Cell columnWidth="medium">
                    {publicRecord.ecoa}
                  </Table.Cell>

                  <Table.Cell>{publicRecord.referenceNumber}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        )}
      </Table>
    </>
  );
};
