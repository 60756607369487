import React from 'react';
import { Icon } from '../generic/Icon/Icon';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonColor?: string;
  text?: string;
  leadingIcon?: string;
  trailingIcon?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  buttonClass?: string;
}

export const Button = ({
  isLoading = false,
  text = '',
  isDisabled = false,
  buttonClass = '',
  buttonColor = '',
  onClick,
  leadingIcon = '',
  trailingIcon = '',
}: Props): JSX.Element => {
  return (
    <button
      className={`button ${buttonColor} ${buttonClass} ${
        isLoading ? 'is-loading' : ''
      }`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {leadingIcon ? <Icon name={leadingIcon} /> : null}
      {text ? <span>{text}</span> : null}
      {trailingIcon ? <Icon name={trailingIcon} /> : null}
    </button>
  );
};
