import { useCallback } from 'react';

import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  LedgerUnderwritingPricingSuggestionsGetParams,
  LedgerUnderwritingPricingSuggestionsRaw,
} from 'types/api/underwriting/types';
import { fetchLedgerUnderwritingSuggestedPricing } from 'api/underwriting/suggestedPricingFetchUtils';

export const useFetchLedgerUnderwritingSuggestedPricing = (
  submissionUuid: string,
  params: LedgerUnderwritingPricingSuggestionsGetParams
): UseGenericQueryResponse<LedgerUnderwritingPricingSuggestionsRaw> => {
  const fetchFunction = useCallback(() => {
    return fetchLedgerUnderwritingSuggestedPricing(submissionUuid, params);
    // The params won't change over time, so we can ignore it from the dependency array.
    // Basically, we request suggested pricing once per ledger, so we don't need to look at the params.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};
