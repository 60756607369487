import React from 'react';
import { SectionHeader } from '../RenewalComparison.types';
import { Table } from '@forward-financing/fast-forward';
import { TableCellWithDefault } from './TableCellWithDefault';
import {
  RenewalComparisonData,
  RenewalComparisonRow,
} from 'types/api/underwriting/types';

export interface OwnerRiskSectionProps {
  data: RenewalComparisonData<'owner_risk_data' | 'summary_data'>;
  headers: SectionHeader;
}

export const OwnerRiskSection = ({
  data,
  headers,
}: OwnerRiskSectionProps): JSX.Element => {
  const [current, previous_1, previous_2] = Object.values(data)
    .filter((d) => !!d)
    .map((opp) => (opp as RenewalComparisonRow).owner_risk_data);

  return (
    <>
      {current && (
        <Table caption={'owner-risk'}>
          <Table.Head>
            <Table.ColumnHeader>Owner Risk</Table.ColumnHeader>
            <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
            <Table.ColumnHeader>{headers.previous_1}</Table.ColumnHeader>
            {headers.previous_2 && (
              <Table.ColumnHeader>{headers.previous_2}</Table.ColumnHeader>
            )}
          </Table.Head>

          <Table.Body>
            <Table.Row>
              <Table.RowHeader>FICO</Table.RowHeader>
              <TableCellWithDefault>{current.fico_score}</TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.fico_score}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.fico_score}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>BK Plus</Table.RowHeader>
              <TableCellWithDefault>
                {current.bankruptcy_plus}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.bankruptcy_plus}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.bankruptcy_plus}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Vantage</Table.RowHeader>
              <TableCellWithDefault>
                {current.vantage_score}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.vantage_score}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.vantage_score}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Total Delinquent Trade Lines</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.total_delinquent_trade_lines === 'number'
                  ? current.total_delinquent_trade_lines
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.total_delinquent_trade_lines === 'number'
                  ? previous_1?.total_delinquent_trade_lines
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.total_delinquent_trade_lines === 'number'
                    ? previous_2.total_delinquent_trade_lines
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Debt to High Credit</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.debt_to_high_credit === 'number'
                  ? `${current.debt_to_high_credit}%`
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.debt_to_high_credit === 'number'
                  ? `${previous_1?.debt_to_high_credit}%`
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.debt_to_high_credit === 'number'
                    ? `${previous_2.debt_to_high_credit}%`
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Public Records Count</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.public_records_count === 'number'
                  ? current.public_records_count
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.public_records_count === 'number'
                  ? previous_1?.public_records_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.public_records_count === 'number'
                    ? previous_2.public_records_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>UCCs</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.uccs_count === 'number'
                  ? current.uccs_count
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.uccs_count === 'number'
                  ? previous_1?.uccs_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.uccs_count === 'number'
                    ? previous_2.uccs_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Judgments</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.open_judgments_count === 'number'
                  ? current.open_judgments_count
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.open_judgments_count === 'number'
                  ? previous_1?.open_judgments_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.open_judgments_count === 'number'
                    ? previous_2.open_judgments_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Liens</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.open_liens_count === 'number'
                  ? current.open_liens_count
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.open_liens_count === 'number'
                  ? previous_1?.open_liens_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.open_liens_count === 'number'
                    ? previous_2.open_liens_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Criminal Filings</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.criminal_filings_count === 'number'
                  ? current.criminal_filings_count
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.criminal_filings_count === 'number'
                  ? previous_1?.criminal_filings_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.criminal_filings_count === 'number'
                    ? previous_2.criminal_filings_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
