import React from 'react';
import {
  Box,
  Text,
  Subheading,
  List,
  Link,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const IsoPopover = (): JSX.Element => {
  return (
    <Popover
      header="ISO"
      content={
        <Box>
          <Subheading variant="subsection">
            Rapid Finance vs Rapid-CSC
          </Subheading>
          <List>
            <List.Item>
              <Text>
                <Text bold>Rapid-CSC:</Text> If the Customer is missing 1 to 2
                days to complete the 12 months.
              </Text>
            </List.Item>
            <List.Item>
              <Text>
                <Text bold>Rapid Finance:</Text> This ISO is going to be used
                when the deal was submitted by bobasub@rapidfinance.com without
                the cscrf@rapidfinance.com email copied.
              </Text>
            </List.Item>
          </List>
          <Text>
            In order to see if the email cscrf@rapidfinance.com was copied go to{' '}
            <Link href={new URL('https://groups.google.com/my-groups')} newTab>
              Google Groups
            </Link>
            .
          </Text>
        </Box>
      }
      trigger={
        <IconButton label="ISO more info" icon="circle-question" hiddenLabel />
      }
    />
  );
};
