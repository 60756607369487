import React from 'react';
import { Button, Loading } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';

import { UpdateSubmissionBody } from '../../Navbar/SubmissionNavbar/navbarFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { ToolboxSubmission } from './Toolbox.types';

export interface SendToProcessingButtonProps {
  submission?: ToolboxSubmission;
  updateSubmission: (input: UpdateSubmissionBody) => Promise<MutationResponse>;
  requestLoading: boolean;
  refetchSubmission: () => void;
}
export const SendToProcessingButton = ({
  submission,
  updateSubmission,
  requestLoading,
  refetchSubmission,
}: SendToProcessingButtonProps): JSX.Element => {
  const { id: userId } = useUserContext();

  const sendToProcessing = async (): Promise<void> => {
    const { success } = await updateSubmission({
      decisionAnalystId: userId,
      stage: 'Processing',
      subStage: 'In Processing',
    });

    success && refetchSubmission();
  };

  const isInAvailableStages =
    submission?.subStage &&
    ['Pre-qualification', 'Waiting on ISO'].includes(submission?.subStage);
  const isInProcessingStage =
    submission && submission.stageName === 'Processing';
  const isInProcessingSubstage =
    submission && submission.subStage === 'In Processing';

  const shouldBeDisabled =
    !isInAvailableStages ||
    !isInProcessingStage ||
    isInProcessingSubstage ||
    !submission?.attachmentsReadyForProcessing ||
    requestLoading;

  return (
    <>
      <Button
        key="sendToProcessing"
        startIcon="gears"
        onClick={() => sendToProcessing()}
        disabled={shouldBeDisabled}
      >
        Send To Processing
        {requestLoading && <Loading size="small" />}
      </Button>
    </>
  );
};
