import React from 'react';
import differenceInYears from 'date-fns/differenceInYears';
import { DataLine } from '../shared/generic/DataLine';
import { HashMap } from '../../api/codecs';

import { BusinessSummaryInterface } from './codecs/BusinessSummary.codec';
import { safeUrl } from '../../helpers/urlUtil';
import {
  Gauge,
  List,
  Subheading,
  Text,
  Grid,
  Box,
  formatCurrency,
  formatPhoneNumber,
  formatDateString,
} from '@forward-financing/fast-forward';

export function addressToString(data: BusinessSummaryProps['data']): string {
  const adr = data.primary_address || {
    street: '',
    city: '',
    state: '',
    zip: '',
    zipExtension: '',
  };
  return `${adr.street} ${adr.city} ${adr.state} ${adr.zip} ${adr.zipExtension}`;
}

export interface BusinessSummaryProps {
  data: BusinessSummaryInterface;
}

const today = new Date();
const contactsText = (contacts: HashMap<string>[] = []): JSX.Element[] => {
  return contacts.map((contact, i) => {
    return (
      <span
        key={contact.name}
        title={`Name: ${contact.name}. Title: ${contact.title}`}
      >
        {contact.name}
        {contact.title ? ` - ${contact.title}` : ''}
        {i + 1 === contacts.length ? '' : <br />}
      </span>
    );
  });
};

const validScore = (score: number | null): boolean => {
  return score ? score >= 1 && score <= 100 : false;
};

const invalidScoreExplanation = (score: number | null): string => {
  switch (score) {
    case 999:
      return '(Insufficient data to generate a score.)';
    case 998:
      return '(Recent bankruptcy on file within the past 2 years.)';
    default:
      return '';
  }
};

const yearsOnFile = (yearsOnFile: string): string | number => {
  const yearsDiff = differenceInYears(today, Date.parse(yearsOnFile));
  return isNaN(yearsDiff) ? '' : yearsDiff;
};

const riskClassMappings = {
  'LOW RISK': 1,
  'LOW TO MEDIUM RISK': 2,
  'MEDIUM RISK': 3,
  'MEDIUM TO HIGH RISK': 4,
  'HIGH RISK': 5,
};

export const BusinessSummary = ({
  data,
}: BusinessSummaryProps): JSX.Element => {
  // we need to see if these are relative or absolute urls and ensure absolute if necessary
  const websiteUrl = (
    <a
      href={safeUrl(data.website_url)}
      target="_blank"
      rel="noreferrer noopener"
    >
      {data.website_url}
    </a>
  );

  return (
    <Grid>
      <Grid.Item>
        <Box paddingBottom={3}>
          <Subheading>Associated Business Names: </Subheading>
          <Text>{(data.associated_business_names || []).join(', ')}</Text>
        </Box>
      </Grid.Item>
      <Grid.Item xs={6}>
        <DataLine mainText="Website" text={websiteUrl} />
        <DataLine
          mainText="Phone"
          text={data.phone === null ? '' : formatPhoneNumber(data.phone)}
        />
        <DataLine mainText="Contacts" text={contactsText(data.contacts)} />
        <DataLine mainText="# of Employees" text={data.number_of_employees} />
        <DataLine mainText="Sales" text={formatCurrency(data.sales)} />
      </Grid.Item>
      <Grid.Item xs={6}>
        <DataLine
          mainText="Years on File"
          text={`${yearsOnFile(
            data.years_on_file
          )} (FILE ESTABLISHED ${formatDateString(data.years_on_file)})`}
        />
        <DataLine
          mainText="State of Incorporation"
          text={data.state_of_incorporation}
        />
        <DataLine
          mainText="Date of Incorporation"
          text={
            data.date_of_incorporation
              ? formatDateString(data.date_of_incorporation)
              : 'N/A'
          }
        />
        <DataLine mainText="Business Type" text={data.business_type} />
        <DataLine
          mainText="NAICS Code"
          text={(data.naics_codes || []).map((c, i) => (
            <span key={c.code}>
              {c.code} ({c.definition}){' '}
              {i + 1 === data.naics_codes.length ? '' : <br />}
            </span>
          ))}
        />
      </Grid.Item>
      {data.sbcs_score && (
        <Grid.Item>
          <Box marginBottom={2}>
            <Subheading>Risk Assessment</Subheading>
            <Subheading variant="section">
              Current SBCS Acquisition Score:{' '}
              {data.sbcs_score.sbcs_acquisition_score || 'N/A'}
            </Subheading>
            {data.sbcs_score.sbcs_risk_class && (
              <Subheading variant="section">
                Risk class:{' '}
                {validScore(data.sbcs_score.sbcs_acquisition_score)
                  ? data.sbcs_score.sbcs_risk_class.definition &&
                    `${
                      (riskClassMappings as Record<string, number>)[
                        data.sbcs_score.sbcs_risk_class.definition
                      ] || 'N/A'
                    } - ${data.sbcs_score.sbcs_risk_class.definition}`
                  : `N/A ${invalidScoreExplanation(
                      data.sbcs_score.sbcs_acquisition_score
                    )}`}
              </Subheading>
            )}
          </Box>
          {data.sbcs_score.sbcs_acquisition_score && (
            <Grid>
              <Grid.Item xs={5}>
                {validScore(data.sbcs_score.sbcs_acquisition_score) && (
                  <Gauge
                    min={0}
                    value={data.sbcs_score.sbcs_acquisition_score}
                    segments={[
                      { size: 20, label: '5' },
                      { size: 20, label: '4' },
                      { size: 25, label: '3' },
                      { size: 20, label: '2' },
                      { size: 15, label: '1' },
                    ]}
                    leftLabel="High risk"
                    rightLabel="Low risk"
                    colorPalette="quality"
                    reverseColors={false}
                  />
                )}
              </Grid.Item>
              <Grid.Item xs={7}>
                <Text>
                  This V2 score predicts the likelihood of serious credit
                  delinquencies for this business within the next 24 months.
                  Payment history and public record along with other variables
                  are used to predict future risk. Higher scores indicate lower
                  risk.
                </Text>
                <Text>Factors lowering the score:</Text>
                <List>
                  <List.Item>
                    NUMBER OF COMMERCIAL ACCOUNTS THAT ARE CURRENT
                  </List.Item>
                  <List.Item>
                    NUMBER OF OPEN COMMERCIAL RETAIL CREDIT CARD ACCOUNTS
                  </List.Item>
                  <List.Item>
                    NUMBER OF COMMERCIAL OPEN FINANCIAL ACCOUNTS
                  </List.Item>
                  <List.Item>NUMER OF OPEN COMMERCIAL ACCOUNTS</List.Item>
                </List>
                <Box paddingTop={3}>
                  {validScore(data.sbcs_score.sbcs_acquisition_score) ? (
                    <Text bold>
                      Risk class:{' '}
                      {(riskClassMappings as Record<string, number>)[
                        data.sbcs_score.sbcs_risk_class.definition
                      ] || 'N/A'}
                      {' - '}
                      {data.sbcs_score.sbcs_risk_class.definition}
                    </Text>
                  ) : (
                    <Text bold>Risk class: N/A</Text>
                  )}
                  <Text>
                    The risk class groups scores by risk into ranges of similar
                    performance. Range 5 is the highest risk, range 1 is the
                    lowest risk.
                  </Text>
                </Box>
                <Box paddingTop={3}>
                  <Text bold>Industry Risk Comparison</Text>
                  {validScore(data.sbcs_score.sbcs_acquisition_score) ? (
                    <Text>
                      {data.sbcs_score.sbcs_percentile_ranking}% of businesses
                      indicate a higher likelihood of severe delinquency.
                    </Text>
                  ) : (
                    <Text>N/A</Text>
                  )}
                </Box>
              </Grid.Item>
            </Grid>
          )}
        </Grid.Item>
      )}
    </Grid>
  );
};
