import React from 'react';
import {
  Text,
  Table,
  Box,
  formatCurrency,
} from '@forward-financing/fast-forward';
import { LienJudgment } from './ownerLexisNexisPage.types';

export interface LienJudgmentTableProps {
  lienJudgmentData: LienJudgment[];
}

export const LiensJudgmentsTable = ({
  lienJudgmentData,
}: LienJudgmentTableProps): JSX.Element => {
  return (
    <Table caption={'Liens Judgments'}>
      <Table.Head>
        <Table.ColumnHeader>Debtor</Table.ColumnHeader>
        <Table.ColumnHeader>Creditor</Table.ColumnHeader>
        <Table.ColumnHeader>Filings</Table.ColumnHeader>
        <Table.ColumnHeader>Amount</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {lienJudgmentData.map((row) => (
          <Table.Row key={JSON.stringify(row)}>
            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.debtor.length > 0 &&
                row.debtor.map((debtor) => (
                  <Box key={debtor.name || debtor.companyName}>
                    <Text>{debtor.name || debtor.companyName}</Text>
                    {debtor.address?.map((address) => (
                      <Text size="s" italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell>
              {row.creditor.length > 0 &&
                row.creditor.map((creditor) => (
                  <Box key={creditor.name}>
                    <Text>{creditor.name}</Text>
                    {creditor.address?.map((address) => (
                      <Text italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell columnWidth="large">
              {row.filings.length > 0 &&
                row.filings.map((filing) => (
                  <Box key={filing.number}>
                    <Text>
                      <Text bold>Type</Text> {filing.type}
                    </Text>
                    <Text>
                      <Text bold>Filing Date</Text> {filing.originFilingDate}
                    </Text>
                    <Text>
                      <Text bold>Agency</Text> {filing.agency}
                    </Text>
                    <Text>
                      <Text bold>Agency State</Text> {filing.agencyState}
                    </Text>
                    <Text>
                      <Text bold>Agency County</Text> {filing.agencyCounty}
                    </Text>
                    <Text>
                      <Text bold>Number</Text> {filing.number}
                    </Text>
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell>{row.amount && formatCurrency(+row.amount)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
