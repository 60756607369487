import React from 'react';
import { Text, Table, Box } from '@forward-financing/fast-forward';
import { UccFiling } from '../OwnerLexisNexisPage/ownerLexisNexisPage.types';

export interface UccFilingsTableProps {
  uccFilingData: UccFiling[];
}

export const UccFilingsTable = ({
  uccFilingData,
}: UccFilingsTableProps): JSX.Element => {
  return (
    <Table caption={'UCC Filings'}>
      <Table.Head>
        <Table.ColumnHeader>Debtor</Table.ColumnHeader>
        <Table.ColumnHeader>Secured</Table.ColumnHeader>
        <Table.ColumnHeader>Origin Filing Date</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
        <Table.ColumnHeader>Collateral</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {uccFilingData.map((row) => (
          <Table.Row
            key={`${row.status} ${row.collateral} ${row.originFilingDate}`}
          >
            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.debtor.length > 0 &&
                row.debtor.map((deb) => (
                  <Box key={deb.originName}>
                    <Text>{deb.originName}</Text>
                    {deb.addresses?.map((address) => (
                      <Text size="s" italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.secured.length > 0 &&
                row.secured.map((sec) => (
                  <Box key={sec.originName}>
                    <Text>{sec.originName}</Text>
                    {sec.addresses?.map((address) => (
                      <Text size="s" italic key={address}>
                        {address}
                      </Text>
                    ))}
                  </Box>
                ))}
            </Table.Cell>

            <Table.Cell>{row.originFilingDate}</Table.Cell>

            <Table.Cell>{row.status}</Table.Cell>
            <Table.Cell overflowStrategy="wrap" columnWidth="medium">
              {row.collateral.length > 0 && row.collateral.map((col) => col)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
