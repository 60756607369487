import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
} from '@forward-financing/fast-forward';

export interface ErrorPageProps {
  mainMessage: string;
  secondaryMessages?: JSX.Element;
  buttonText?: string;
  buttonIcon?: ButtonProps['startIcon'];
  buttonIconIsLeading?: boolean;
  onClick?: () => void;
}

export const ErrorPage = (props: ErrorPageProps): JSX.Element => {
  const secondaryMessages = props.secondaryMessages
    ? props.secondaryMessages
    : null;

  return (
    <Flex px={4} pb={4} textAlign="center" flexDirection="column">
      <Heading>{props.mainMessage}</Heading>
      {secondaryMessages}
      <Box mt={4}>
        {props.buttonIcon && props.buttonText && props.onClick && (
          <Button
            startIcon={props.buttonIconIsLeading ? props.buttonIcon : undefined}
            endIcon={props.buttonIconIsLeading ? undefined : props.buttonIcon}
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        )}
      </Box>
    </Flex>
  );
};
