import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { FeatureFlagResponse } from 'types/api/underwriting/types';

export const fetchFeatureFlagWithIdentifier = async (
  flag: string,
  identifier: string
): Promise<Record<string, boolean>> => {
  const url = new URL(
    `/api/v2/feature_flags/${flag}?identifier=${identifier}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<FeatureFlagResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Failed to fetch ${flag} Feature Flag`
    );
  }

  return response.json();
};
