import React from 'react';
import { Box, Flex, Link } from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';
import { RenewalComparison } from './RenewalComparison';

interface Params {
  opportunityUuid: string;
  applicationRecordId: string;
}

interface UnderwritingApplicationLinkProps {
  recordId: string;
}

// Once project phoenix is done we should refactor this link to use submission route in Internal FE instead of UA. Once this is done we can get rid of the application id param.
const UnderwritingApplicationLink = ({
  recordId,
}: UnderwritingApplicationLinkProps): JSX.Element => {
  return (
    <Link href={new URL(`/submissions/${recordId}`, window.location.origin)}>
      Back to Application
    </Link>
  );
};

export const RenewalComparisonCoreContainer = (): JSX.Element => {
  const { opportunityUuid, applicationRecordId } = useParams<
    keyof Params
  >() as Params;

  return (
    <Flex p={3} gap={3} flexDirection="column">
      <Box>
        <UnderwritingApplicationLink recordId={applicationRecordId} />
      </Box>
      <Box>
        <RenewalComparison opportunityUuid={opportunityUuid} />
      </Box>
      <Box>
        <UnderwritingApplicationLink recordId={applicationRecordId} />
      </Box>
    </Flex>
  );
};
