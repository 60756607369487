import { useCallback } from 'react';
import {
  useGenericMutation,
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { BankNameResponse } from 'types/api/underwriting/types';
import { createBank, fetchBanks } from 'api/underwriting/bankFetchUtils';

export const useGetApiBankNames = (): UseGenericQueryResponse<
  BankNameResponse[]
> => {
  const fetcher = useCallback(() => {
    return fetchBanks();
  }, []);

  return useGenericQuery(fetcher);
};

const addBankName = (bankName: string): Promise<BankNameResponse> => {
  return createBank(bankName);
};

export const useCreateApiBankName = (): UseGenericMutationResponse<
  BankNameResponse,
  string
> => {
  return useGenericMutation(addBankName);
};
