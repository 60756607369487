import React from 'react';
import {
  Address,
  Box,
  Flex,
  Grid,
  Subheading,
  Table,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import { ExperianConsumerDetailInfo } from '../types';

export type ExperianConsumerDetailProps = {
  experianConsumerDetailInfo: ExperianConsumerDetailInfo;
};

export const ExperianConsumerDetailApplicant = ({
  experianConsumerDetailInfo: {
    name,
    address: { city, state, streetAddress, zipCode },
    employer,
    otherAddresses,
    otherEmployers,
  },
}: ExperianConsumerDetailProps): JSX.Element => {
  const firstReportedDate = formatDateString(employer.firstReportedDate, 'N/A');
  const lastUpdatedDate = formatDateString(employer.lastUpdatedDate, 'N/A');

  return (
    <>
      <Subheading>Applicant</Subheading>
      <Grid gutter>
        <Grid.Item l={6} s={12}>
          <Table>
            <Table.Row>
              <Table.RowHeader>Best Name</Table.RowHeader>
              <Table.Cell>{name}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Best Address</Table.RowHeader>
              <Table.Cell>
                <Address
                  city={city}
                  state={state}
                  streetAddress={streetAddress}
                  zipCode={zipCode}
                  isSingleLine
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Best Employer</Table.RowHeader>
              <Table.Cell>
                <Flex flexDirection="column">
                  <Text>{employer.employerName}</Text>

                  <Text>{`Reported ${firstReportedDate} to ${lastUpdatedDate}`}</Text>
                </Flex>
              </Table.Cell>
            </Table.Row>
          </Table>
        </Grid.Item>

        <Grid.Item l={6} s={12}>
          <Table>
            <Table.Row>
              <Table.RowHeader>Other Addresses</Table.RowHeader>
              <Table.Cell>
                <Flex gap={2} flexDirection="column">
                  {otherAddresses.map((otherAddress) => (
                    <Text
                      key={`${otherAddress.city}-${otherAddress.state}-${otherAddress.streetAddress}-${otherAddress.zipCode}`}
                    >
                      <Address
                        key={`${otherAddress.city}-${otherAddress.state}-${otherAddress.streetAddress}-${otherAddress.zipCode}`}
                        city={otherAddress.city}
                        state={otherAddress.state}
                        streetAddress={otherAddress.streetAddress}
                        zipCode={otherAddress.zipCode}
                        isSingleLine
                      />
                    </Text>
                  ))}
                </Flex>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Other Employers</Table.RowHeader>
              <Table.Cell>
                <Flex gap={2} flexDirection="column">
                  {otherEmployers.map((otherEmployer) => {
                    const otherEmployerFirstReportedDate = formatDateString(
                      otherEmployer.firstReportedDate,
                      'N/A'
                    );
                    const otherEmployerLastUpdatedDate = formatDateString(
                      otherEmployer.lastUpdatedDate,
                      'N/A'
                    );

                    return (
                      <Box
                        key={`${otherEmployer.employerName}-${otherEmployerFirstReportedDate}-${otherEmployerLastUpdatedDate}`}
                      >
                        <Text>{otherEmployer.employerName}</Text>

                        <Text>{`Reported ${otherEmployerFirstReportedDate} to ${otherEmployerLastUpdatedDate}`}</Text>
                      </Box>
                    );
                  })}
                </Flex>
              </Table.Cell>
            </Table.Row>
          </Table>
        </Grid.Item>
      </Grid>
    </>
  );
};
