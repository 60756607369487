import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { DeclineDriversResponse } from 'types/api/underwriting/types';

export const fetchDeclineDrivers = async (
  submissionUuid?: string
): Promise<DeclineDriversResponse> => {
  const url = new URL(
    `/api/v2/decline_drivers${
      submissionUuid ? `?submission_uuid=${submissionUuid}` : ''
    }`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<DeclineDriversResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Decline Drivers');
  }

  return response.json();
};
