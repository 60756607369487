import React from 'react';
import { formatDateString, Table } from '@forward-financing/fast-forward';

import { InternalLink } from 'components/shared/InternalLink';
import { ExperianConsumerArchiveItem } from '../types';

export type ExperianConsumerArchiveTableProps = {
  reports: ExperianConsumerArchiveItem[];
};

export const ExperianConsumerArchiveTable = ({
  reports,
}: ExperianConsumerArchiveTableProps): JSX.Element => (
  <Table>
    <Table.Head>
      <Table.ColumnHeader>Created At</Table.ColumnHeader>
      <Table.ColumnHeader>Submission</Table.ColumnHeader>
      <Table.ColumnHeader>Experian Consumer Report</Table.ColumnHeader>
    </Table.Head>
    <Table.Body>
      {reports.map((report) => (
        <Table.Row key={report.reportUuid}>
          <Table.Cell columnWidth="small">
            {formatDateString(report.createdAt)}
          </Table.Cell>
          <Table.Cell columnWidth="medium">
            <InternalLink
              to={`/submissions/${report.submissionUuid}`}
              target="_blank"
            >
              {report.submissionUuid}
            </InternalLink>
          </Table.Cell>

          <Table.Cell columnWidth="medium">
            <InternalLink
              to={`/dashboard/consumer-credit-report/${report.reportUuid}`}
              target="_blank"
            >
              Detailed Report
            </InternalLink>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
