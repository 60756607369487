import React, { useMemo, useState } from 'react';
import { Box, Button, Sheet } from '@forward-financing/fast-forward';
import { defaultTo, isEqual } from 'lodash';

import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { Ledger } from '../ledger.types';
import { LEDGER_TABLES_MONTHS } from '../constants';
import {
  EMPTY_OFFERS_DATA,
  EMPTY_OFFERS_LEDGER_DATA,
  OFFERS_TABLE_FIELD_NAME_LABEL_MAP,
} from './offers.constants';
import {
  generateOffersTableColumnHeaders,
  getUpdatedOffer,
  isLedgerOffer,
} from './offers.utils';
import { LedgerOffer } from './offers.types';
import { OffersTableCell } from './OffersTableCell';

type OffersTableProps = {
  ledgerCreationDate: string;
  fetchedOffers: LedgerOffer[];
  handleChangeLedger: (updates: Partial<Ledger>) => void;
  handleOfferCalculation: (
    offer: Partial<LedgerOffer> | undefined,
    months: string
  ) => Promise<MutationResponse>;
  readOnly?: boolean;
  eligibleForWeekly?: boolean;
};

const OffersTableComponent = ({
  ledgerCreationDate,
  fetchedOffers,
  handleChangeLedger,
  handleOfferCalculation,
  readOnly,
  eligibleForWeekly,
}: OffersTableProps): JSX.Element => {
  const [offers, setOffers] = useState<Partial<LedgerOffer>[]>(fetchedOffers);

  const columnNames = useMemo(
    () => generateOffersTableColumnHeaders(ledgerCreationDate, true),
    [ledgerCreationDate]
  );

  const updateOffers = (
    fieldName: keyof LedgerOffer,
    months: number,
    value: string
  ): Partial<LedgerOffer>[] => {
    const isOfferExists = offers.some((offer) => offer.months === months);

    /**
     * Updates the `offers` array. If an offer with the same `months` exists, it updates the offer.
     * Otherwise, it adds a new offer with the specified `months` and `fieldName`.
     */
    const updatedOffers = isOfferExists
      ? offers.map((offer) =>
          offer.months === months
            ? getUpdatedOffer(offer, fieldName, value)
            : offer
        )
      : [...offers, { months, [fieldName]: value }];

    setOffers(updatedOffers);

    return updatedOffers;
  };

  const onBlurHandler = async (
    months: number,
    fieldName: keyof LedgerOffer,
    value: string
  ): Promise<void> => {
    const updatedOffers = updateOffers(fieldName, months, value);

    const changedOffer = updatedOffers.find((offer) => offer.months === months);
    const initialOffer = fetchedOffers?.find(
      (offer) => offer.months === months
    );

    handleChangeLedger({
      [`dollar${months}Months`]: changedOffer?.dollarOverride ?? '',
      [`gross${months}Months`]: changedOffer?.gross ?? '',
    });

    if (changedOffer && !isEqual(changedOffer, initialOffer)) {
      await calculateOffer(
        defaultTo(changedOffer.months?.toString(), months.toString()),
        changedOffer
      );
    }
  };

  const calculateOffer = async (
    months: string,
    changedOffer: Partial<LedgerOffer>
  ): Promise<void> => {
    const { success, response } = await handleOfferCalculation(
      changedOffer,
      months
    );

    if (success && isLedgerOffer(response)) {
      setOffers((prevOffers) => {
        return prevOffers.map((offer) => {
          if (offer.months === Number(response.months)) {
            return response;
          } else {
            return offer;
          }
        });
      });
    }
  };

  const resetOffers = (): void => {
    setOffers(EMPTY_OFFERS_DATA);

    handleChangeLedger(EMPTY_OFFERS_LEDGER_DATA);
  };

  const shouldShowResetButton = (): boolean =>
    !isEqual(EMPTY_OFFERS_DATA, offers) && !readOnly && !!offers.length;

  return (
    <>
      {shouldShowResetButton() && (
        <Box mb={2}>
          <Button onClick={() => resetOffers()} endIcon={'arrow-left-rotate'}>
            Reset Offers
          </Button>
        </Box>
      )}

      <Sheet>
        <Sheet.Head>
          <Sheet.ColumnHeader minColumnWidth="medium">
            Term length (Months)
          </Sheet.ColumnHeader>
          {columnNames.map((header) => (
            <Sheet.ColumnHeader minColumnWidth="small" key={header}>
              {header}
            </Sheet.ColumnHeader>
          ))}
        </Sheet.Head>
        <Sheet.Body>
          {Object.keys(OFFERS_TABLE_FIELD_NAME_LABEL_MAP()).map((fieldName) => (
            <Sheet.Row key={fieldName}>
              <Sheet.RowHeader>
                {
                  OFFERS_TABLE_FIELD_NAME_LABEL_MAP(eligibleForWeekly)[
                    fieldName
                  ]
                }
              </Sheet.RowHeader>
              {LEDGER_TABLES_MONTHS.map((months) => (
                <OffersTableCell
                  offersData={offers}
                  fieldName={fieldName}
                  months={months}
                  key={months}
                  isEditable={
                    ['dollarOverride', 'gross'].includes(fieldName) && !readOnly
                  }
                  handleBlur={onBlurHandler}
                />
              ))}
            </Sheet.Row>
          ))}
        </Sheet.Body>
      </Sheet>
    </>
  );
};

export const OffersTable = React.memo(OffersTableComponent);
