import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  ANALYTICS_GATEWAY_API_KEY,
  ANALYTICS_GATEWAY_BASE_URL,
} from 'constants/globals';
import { AccessLogsDataRequestBody } from 'types/api/analyticsGateway/types';

export const createAccessLog = async (
  body: AccessLogsDataRequestBody
): Promise<MutationResponse> => {
  const url = new URL('api/v1/access_logs', ANALYTICS_GATEWAY_BASE_URL());

  const customHeaders = { 'X-AUTH-TOKEN': ANALYTICS_GATEWAY_API_KEY() };

  const response = await makeInternalAPIRequest<
    void,
    AccessLogsDataRequestBody
  >(url, 'POST', body, customHeaders);

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(
    response.status,
    `Failed to create Access Logs for ${body.field_name}`
  );
};
