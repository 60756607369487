import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import { EstablishedDates } from './applicationSnapshot.types';
import { EstablishedDatesPopover } from './EstablishedDatesPopover';

export interface EstablishedDatesDisplayProps {
  establishedDates: EstablishedDates | undefined;
}

export const EstablishedDatesDisplay = ({
  establishedDates,
}: EstablishedDatesDisplayProps): JSX.Element => {
  const { lexisNexis } = establishedDates || {};

  const verifiedText =
    lexisNexis &&
    (lexisNexis.lexisNexisDayDifference > 90 ||
      lexisNexis.lexisNexisYearDifference > 1)
      ? 'Verified - Does Not Match'
      : 'Verified';

  return (
    <>
      {establishedDates?.customerReportedStartDate && (
        <Flex alignItems="center">
          <Box>
            {formatDateString(establishedDates.customerReportedStartDate)}
          </Box>
          <Divider orientation="vertical" />
          {establishedDates.lexisNexis ? (
            <Flex gap={2} alignItems={'center'}>
              <Text>{verifiedText}</Text>
              <EstablishedDatesPopover establishedDates={establishedDates} />
            </Flex>
          ) : (
            <Text italic>Not verified</Text>
          )}
        </Flex>
      )}
    </>
  );
};
