import { Banner, Grid } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BusinessOverviewSection } from './BusinessOverview/BusinessOverviewSection';
import { OwnerOverviewSection } from './OwnerOverview/OwnerOverviewSection';
import { useGetSubmission } from './SubmissionUnderwritingContainerHooks';

type Params = {
  submissionUuid: string;
};

export const OverviewSectionsRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const { data: submission, error: errorSubmission } =
    useGetSubmission(submissionUuid);

  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false} variant="error">
          {errorSubmission.message}
        </Banner>
      )}
      <Grid gutter>
        <Grid.Item>
          <OwnerOverviewSection
            submissionUuid={submissionUuid}
            submission={submission}
          />
        </Grid.Item>
        <Grid.Item>
          <BusinessOverviewSection
            submissionUuid={submissionUuid}
            submission={submission}
          />
        </Grid.Item>
      </Grid>
    </>
  );
};
