import {
  Banner,
  Box,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Flex,
  Form,
  Grid,
  Icon,
  Loading,
  MaskedTextInput,
  Select,
  Subheading,
  Text,
  TextInput,
} from '@forward-financing/fast-forward';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { isEqual } from 'lodash';
import { DATE_FORMAT, ENTITY_TYPES } from 'constants/globals';
import { addressHasNoSpecialChar } from 'helpers/validations/ValidationHelpers';
import {
  validateFein,
  validateZipCode,
} from 'helpers/validations/FieldValidator';
import { isDateInPast, isStartDateTooOld } from 'helpers/date/dateUtils';
import {
  Customer,
  CustomerAddress,
  FormUpdateState,
} from './SubmissionEditing.types';
import {
  useGetStates,
  useIndustryOptions,
  useUpdateCustomer,
} from './SubmissionEditingHooks';
import { convertDateStringToPlainDate } from './submissionEditingHelpers';

interface CustomerSectionFormProps {
  customer: Customer;
  submissionUuid: string;
  setFormStatus: (value: FormUpdateState) => void;
  formStatus: FormUpdateState;
}

const initializedAddress = (): CustomerAddress => {
  return {
    street1: '',
    street2: '',
    city: '',
    zip: '',
    state: '',
  };
};

export const CustomerSectionForm = ({
  customer,
  submissionUuid,
  setFormStatus,
  formStatus,
}: CustomerSectionFormProps): JSX.Element => {
  const [customerForm, setCustomerForm] = useState<Customer>(customer);
  const [customerAddress, setCustomerAddress] = useState<CustomerAddress>(
    customer.customerAddress || initializedAddress()
  );

  const [dbaSameAsLegalName, setDbaSameAsLegalName] = useState(false);
  const [phoneNotInApp, setPhoneNotInApp] = useState(
    customer.phoneNumber === undefined
  );
  const [taxIdNotInApp, setTaxIdNotInApp] = useState(
    customer.fein === undefined
  );
  const [customerErrors, setCustomerErrors] = useState<CustomerFormSchemaError>(
    {}
  );

  type CustomerFormSchemaError = Partial<
    z.inferFormattedError<typeof customerFormSchema>
  >;

  const customerFormSchema = z.object({
    legalName: z.string().min(1, { message: 'Legal Name is required.' }),
    dba: z.string().min(1, { message: 'DBA is required.' }),
    phoneNumber: z
      .string()
      .optional()
      .refine(
        (phoneNumber) => {
          // Check if phoneNotInApp is false and phoneNumber is not provided or empty
          if (!phoneNotInApp && (!phoneNumber || phoneNumber.trim() === '')) {
            return false;
          }
          return true;
        },
        {
          message: 'Phone is required.',
        }
      )
      .refine((phoneNumber) => phoneNotInApp || phoneNumber?.length === 10, {
        message: 'Phone must be 10 digits.',
      }),
    fein: z
      .string()
      .optional()
      .refine(
        (fein) => {
          // Check if taxIdNotInApp is false and fein is not provided or empty
          if (!taxIdNotInApp && (!fein || fein.trim() === '')) {
            return false;
          }
          return true;
        },
        {
          message: 'Federal Tax ID is required.',
        }
      )
      .refine(
        (fein) => {
          // Check length of fein
          return fein && fein.trim() !== '' ? validateFein(fein).isValid : true;
        },
        {
          message: 'Federal Tax ID must be 9 digits.',
        }
      ),
    businessStartDate: z
      .string()
      .optional() // The API dictates this may be undefined
      .refine((date) => !!date, {
        message: 'Business Start Date is required.',
      })
      .refine((date) => date && !isStartDateTooOld(new Date(date)), {
        message: 'Business Start Date must be after the year 1799.',
      })
      .refine((date) => date && isDateInPast(new Date(date)), {
        message: 'Business Start Date must be in the past.',
      }),
    industryId: z.number().positive({ message: 'Business Type is required.' }),
    entityType: z.string().min(1, { message: 'Entity Type is required.' }),
    customerAddress: z.object({
      street1: z
        .string()
        .min(1, { message: 'Street Address is required.' })
        .refine((street) => addressHasNoSpecialChar(street), {
          message:
            "Street Address can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), number signs (#), and spaces.",
        }),
      city: z
        .string()
        .min(1, { message: 'City is required.' })
        .refine((city) => addressHasNoSpecialChar(city), {
          message:
            "City can only contain letters, numbers, periods (.), commas (,), dashes (-), ampersands (&), apostrophes ('), number signs (#), and spaces.",
        }),
      state: z.string().min(1, { message: 'State is required.' }),
      zip: z
        .string()
        .min(1, { message: 'Zip Code is required.' })
        .refine((zip) => validateZipCode(zip).isValid, {
          message: 'Invalid Zip Code.',
        }),
    }),
  });

  const isValidCustomerForm = (): boolean => {
    const validationResult = customerFormSchema.safeParse({
      ...customerForm,
      customerAddress,
    });

    if (validationResult.success) {
      setCustomerErrors({});
    } else {
      setCustomerErrors(validationResult.error.format());
    }

    return validationResult.success;
  };

  const [
    updateCustomer,
    { error: updateCustomerError, loading: updateCustomerLoading },
  ] = useUpdateCustomer(customer.uuid);
  const { data: industries, error: industriesError } = useIndustryOptions();
  const { data: states, error: statesError } = useGetStates();

  useEffect(() => {
    dbaSameAsLegalName &&
      setCustomerForm((oldValue) => {
        return { ...oldValue, dba: oldValue.legalName };
      });
  }, [dbaSameAsLegalName, customerForm.legalName]);

  const handleUpdateCustomer = async (): Promise<void> => {
    setFormStatus(undefined);

    if (!isValidCustomerForm()) {
      setFormStatus('unsaved');
      return;
    }

    const customerFromWithMostRecentAddress = {
      ...customerForm,
      customerAddress: customerAddress,
    };

    const { success: successCustomer } = await updateCustomer(
      customerFromWithMostRecentAddress
    );

    if (successCustomer) {
      setFormStatus('updated');
    }
  };

  useEffect(() => {
    const customerFromWithMostRecentAddress = {
      ...customerForm,
      customerAddress: customerAddress,
    };

    if (isEqual(customerFromWithMostRecentAddress, customer)) {
      setFormStatus(undefined);
    }
  }, [customerForm, customerAddress, customer, setFormStatus]);

  const handleCustomerFormOnchange = (
    newVal: string | number | undefined,
    field: keyof Customer
  ): void => {
    formStatus !== 'unsaved' && setFormStatus('unsaved');

    if (field in customer) {
      setCustomerForm((oldValue) => {
        return { ...oldValue, [field]: newVal };
      });
    }
  };

  const handleAddressFormOnchange = (
    newVal: string,
    field: keyof CustomerAddress
  ): void => {
    setFormStatus('unsaved');

    setCustomerAddress((oldValue) => {
      return { ...oldValue, [field]: newVal };
    });
  };

  return (
    <Box>
      <Subheading>Customer Information</Subheading>
      <Divider margin={4} />
      {updateCustomerError && (
        <Banner dismissable>{updateCustomerError?.message}</Banner>
      )}

      {statesError && <Banner dismissable>{statesError?.message}</Banner>}

      {industriesError && (
        <Banner dismissable>Error fetching industries</Banner>
      )}

      {formStatus === 'unsaved' && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      <Form
        accessibleName="Edit Customer Information"
        onSubmit={handleUpdateCustomer}
        allowImplicitSubmission={false}
      >
        {({ fireSubmit }) => (
          <Grid gutter>
            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                <TextInput
                  label="Legal Name"
                  value={customerForm?.legalName}
                  onValueChange={(newVal) =>
                    handleCustomerFormOnchange(newVal, 'legalName')
                  }
                  errorMessage={customerErrors.legalName?._errors?.join(' ')}
                  required
                />
                <Box>
                  <TextInput
                    label="Dba"
                    disabled={dbaSameAsLegalName}
                    value={customerForm?.dba}
                    onValueChange={(newVal) =>
                      handleCustomerFormOnchange(newVal, 'dba')
                    }
                    errorMessage={customerErrors.dba?._errors?.join(' ')}
                    required
                  />
                  <Checkbox
                    checked={dbaSameAsLegalName}
                    onCheckboxChange={(checked) => {
                      setDbaSameAsLegalName(checked);
                    }}
                    label={'Same as Legal Name'}
                  />
                </Box>

                <Box>
                  <MaskedTextInput
                    label="Phone"
                    value={customerForm.phoneNumber}
                    type="number"
                    disabled={phoneNotInApp}
                    onValueChange={(newVal) =>
                      handleCustomerFormOnchange(newVal.value, 'phoneNumber')
                    }
                    format={'(###) ###-####'}
                    mask={'_'}
                    errorMessage={customerErrors.phoneNumber?._errors?.join(
                      ' '
                    )}
                    required={!phoneNotInApp}
                  />
                  {customer.phoneNumber === undefined && (
                    <Checkbox
                      checked={phoneNotInApp}
                      onCheckboxChange={(checked) => {
                        handleCustomerFormOnchange('', 'phoneNumber');
                        setPhoneNotInApp(checked);
                      }}
                      label={'Phone not provided'}
                    />
                  )}
                </Box>

                <Box>
                  <MaskedTextInput
                    label="Federal Tax ID"
                    value={customerForm.fein}
                    type="number"
                    onValueChange={(newVal) =>
                      handleCustomerFormOnchange(newVal.value, 'fein')
                    }
                    format={'##-#######'}
                    mask={'_'}
                    disabled={taxIdNotInApp}
                    errorMessage={customerErrors.fein?._errors?.join(' ')}
                    required={!taxIdNotInApp}
                  />
                  {customer.fein === undefined && (
                    <Checkbox
                      checked={taxIdNotInApp}
                      onCheckboxChange={(checked) => {
                        handleCustomerFormOnchange('', 'fein');
                        setTaxIdNotInApp(checked);
                      }}
                      label={'Tax ID not provided'}
                    />
                  )}
                </Box>

                <DatePicker
                  label="Business Start Date"
                  canSelectTime={false}
                  selected={convertDateStringToPlainDate(
                    customerForm.businessStartDate
                  )}
                  onChange={(newVal) =>
                    handleCustomerFormOnchange(
                      newVal?.toString(),
                      'businessStartDate'
                    )
                  }
                  placeholder={DATE_FORMAT}
                  errorMessage={customerErrors.businessStartDate?._errors?.join(
                    ' '
                  )}
                  required
                />

                <Select
                  label="Business Type"
                  options={industries || []}
                  value={customerForm?.industryId?.toString() || ''}
                  onValueChange={(newVal) => {
                    handleCustomerFormOnchange(+newVal, 'industryId');
                  }}
                  errorMessage={customerErrors.industryId?._errors?.join(' ')}
                  required
                />
              </Flex>
            </Grid.Item>

            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                <TextInput
                  label="Street Address"
                  value={customerAddress.street1}
                  onValueChange={(newVal) =>
                    handleAddressFormOnchange(newVal, 'street1')
                  }
                  errorMessage={customerErrors.customerAddress?.street1?._errors?.join(
                    ' '
                  )}
                  required
                />
                <TextInput
                  label="City"
                  value={customerAddress.city}
                  onValueChange={(newVal) =>
                    handleAddressFormOnchange(newVal, 'city')
                  }
                  errorMessage={customerErrors.customerAddress?.city?._errors?.join(
                    ' '
                  )}
                  required
                />
                <Select
                  label="State"
                  options={states || []}
                  value={customerAddress.state}
                  onValueChange={(newVal) =>
                    handleAddressFormOnchange(newVal, 'state')
                  }
                  errorMessage={customerErrors.customerAddress?.state?._errors?.join(
                    ' '
                  )}
                  required
                />

                <MaskedTextInput
                  label="Zip Code"
                  value={customerAddress.zip}
                  onValueChange={(newVal) =>
                    handleAddressFormOnchange(newVal.value, 'zip')
                  }
                  format={
                    customerAddress.zip.length > 5 ? '#####-####' : '#########'
                  }
                  type="number"
                  mask={''}
                  errorMessage={customerErrors.customerAddress?.zip?._errors?.join(
                    ' '
                  )}
                  required
                />

                <Select
                  label="Entity Type"
                  options={ENTITY_TYPES || []}
                  value={customerForm?.entityType || ''}
                  onValueChange={(newVal) =>
                    handleCustomerFormOnchange(newVal, 'entityType')
                  }
                  errorMessage={customerErrors.entityType?._errors?.join(' ')}
                  required
                />
              </Flex>
            </Grid.Item>

            <Grid.Item>
              <Flex gap={3} justifyContent={'right'}>
                {formStatus === 'updated' && (
                  <Icon size="2x" name="check-circle" />
                )}
                {updateCustomerLoading && <Loading />}

                <Button onClick={fireSubmit} disabled={updateCustomerLoading}>
                  Save Customer
                </Button>
              </Flex>
            </Grid.Item>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
