import React from 'react';
import {
  Flex,
  QualityIcon,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { Feature } from './v6ScoreDisplay.types';

type V6ScoreTableProps = {
  features?: Feature[];
};

const convertRatingToQualityIconColor = (
  rating: 'best' | 'worst'
): 'good' | 'bad' => {
  if (rating === 'best') {
    return 'good';
  } else {
    return 'bad';
  }
};

export const V6ScoreDisplayTable = ({
  features,
}: V6ScoreTableProps): JSX.Element => {
  if (!features) {
    return <></>;
  }

  return (
    <Table>
      <Table.Body>
        {features.map((feature) => (
          <Table.Row key={feature?.name}>
            <Table.Cell columnWidth="large">{feature.name}</Table.Cell>

            <Table.Cell columnWidth="small">
              <Flex justifyContent="flex-end">
                <Text bold>{feature.formattedInputValue}</Text>
              </Flex>
            </Table.Cell>

            <Table.Cell columnWidth="small">
              {feature.rating && (
                <Flex
                  justifyContent="flex-start"
                  data-testid={`quality-icon-${convertRatingToQualityIconColor(
                    feature.rating
                  )}`}
                >
                  <QualityIcon
                    quality={convertRatingToQualityIconColor(feature.rating)}
                  />
                </Flex>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
