import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Banner,
  Box,
  Loading,
  formatDate,
} from '@forward-financing/fast-forward';
import { toError } from '../../helpers/errorUtils';
import { PaymentHistoryAdvance } from './PaymentHistory.types';
import { fetchPaymentHistory } from './paymentHistoryFetchUtils';
import { PaymentHistoryAdvanceDetails } from './PaymentHistoryAdvanceDetails';

export interface PaymentHistoryProps {
  accountUuid: string;
}

export const PaymentHistory = ({
  accountUuid,
}: PaymentHistoryProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [paymentHistory, setPaymentHistory] = useState<
    PaymentHistoryAdvance[] | null
  >(null);

  useEffect(() => {
    const paymentHistoryFetcher = async (): Promise<void> => {
      try {
        const paymentHistoryResponse = await fetchPaymentHistory(accountUuid);
        if (paymentHistoryResponse.length === 0) {
          setErrorMessage(
            'This customer has no prior advances. Please verify that you are looking at the right customer and try again.'
          );
        } else {
          setPaymentHistory(paymentHistoryResponse);
        }
      } catch (e: unknown) {
        const error = toError(e);

        setErrorMessage(error.message);
      }
    };
    void paymentHistoryFetcher();
  }, [accountUuid]);

  if (errorMessage) {
    return <Banner dismissable={false}>{errorMessage}</Banner>;
  }

  if (paymentHistory === null) {
    return <Loading text="Loading Payment History" />;
  }

  return (
    <Box>
      <Accordion
        type="multiple"
        defaultValue={[paymentHistory[0].dateOfAdvance]}
      >
        {paymentHistory.map((advance) => (
          <Accordion.Item
            key={advance.dateOfAdvance}
            value={advance.dateOfAdvance}
          >
            <Accordion.Trigger>
              {formatDate(advance.dateOfAdvance, { excludeTime: true })}
            </Accordion.Trigger>
            <Accordion.Content>
              <PaymentHistoryAdvanceDetails advance={advance} />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    </Box>
  );
};
