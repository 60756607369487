import React from 'react';
import { Box, List, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from 'helpers/string/errorMessageUtils';
import { BusinessContact } from './BusinessLexisNexisPage.types';

type BusinessContactsTableProps = {
  businessContacts?: BusinessContact[];
};

export const BusinessContactTables = ({
  businessContacts,
}: BusinessContactsTableProps): JSX.Element => {
  const hasBusinessContacts = businessContacts && businessContacts.length > 0;
  return hasBusinessContacts ? (
    <>
      {businessContacts?.map((contact) => (
        <Box mb={3} key={`${contact.company}.${contact.contactType}`}>
          <Table caption="Business Contacts">
            <Table.Head>
              <Table.ColumnHeader>Company</Table.ColumnHeader>
              <Table.ColumnHeader>As Of</Table.ColumnHeader>
              <Table.ColumnHeader>Contact Type</Table.ColumnHeader>
            </Table.Head>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{contact.company}</Table.Cell>
                <Table.Cell>{contact.asOfDate}</Table.Cell>
                <Table.Cell>
                  <List>
                    {contact.contactType?.map(({ type, title }) => (
                      <List.Item key={`${type} ${title}`}>{type}</List.Item>
                    ))}
                  </List>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      ))}
    </>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Business Contacts')}</Text>
    </Box>
  );
};
