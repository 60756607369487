import { useEffect } from 'react';

export const usePreventNavigation = (shouldBlockNavigation: boolean): void => {
  useEffect(() => {
    if (shouldBlockNavigation) {
      const rejectNav = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        /**
         * event.returnValue is deprecated. However,
         * as of this writing, removing this line
         * causes the hook not to work in Chrome.
         * If you are attempting to remove it, please
         * verify that the hook still works without this
         * line.
         *
         * @tyrelosaur - Apr 29, 2022
         */
        event.returnValue = 'NO';
      };
      window.addEventListener('beforeunload', rejectNav);
      return () => window.removeEventListener('beforeunload', rejectNav);
    }
    return () => {
      // this function intentionally left blank
    };
  }, [shouldBlockNavigation]);
};
