import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { Bank } from 'api/UnderwritingClient/codecs';
import { UNDERWRITING_BASE_URL } from 'constants/globals';

export const fetchBanks = (): Promise<Bank[]> => {
  const url = new URL('api/v2/banks', UNDERWRITING_BASE_URL());

  return makeInternalAPIRequest<Bank[]>(url, 'GET').then((response) => {
    if (!response.ok) {
      throw new NetworkError(
        response.status,
        'Something went wrong fetching banks'
      );
    }
    return response.json();
  });
};

export const createBank = (bankName: string): Promise<Bank> => {
  type BankBody = {
    name: string;
  };
  return makeInternalAPIRequest<Bank, BankBody>(
    new URL('api/v2/banks', UNDERWRITING_BASE_URL()),
    'POST',
    { name: bankName }
  ).then((response) => {
    if (!response.ok) {
      throw new NetworkError(
        response.status,
        'Something went wrong creating Bank'
      );
    }
    return response.json();
  });
};
