import React from 'react';
import { Card, Currency, Table, Text } from '@forward-financing/fast-forward';
import { PublicRecordsOverview } from './types';

export interface PublicRecordsSummaryProps {
  publicRecord: PublicRecordsOverview | null;
}

interface PublicRecordSummaryRowProps {
  label: string;
  open: string;
  closed: string;
  total: string;
}

const CurrencyOrAmountCell = ({
  label,
  amount,
}: {
  label: string;
  amount: string;
}): JSX.Element => {
  if (label.includes('$')) {
    return <Currency amount={parseInt(amount, 10) / 100} />;
  }
  return <Text>{amount}</Text>;
};

const PublicRecordSummaryRow = ({
  label,
  open,
  closed,
  total,
}: PublicRecordSummaryRowProps): JSX.Element => (
  <Table.Row key={label}>
    <Table.Cell columnWidth="small">{label}</Table.Cell>

    <Table.Cell columnWidth="small">
      <CurrencyOrAmountCell label={label} amount={open} />
    </Table.Cell>

    <Table.Cell columnWidth="small">
      <CurrencyOrAmountCell label={label} amount={closed} />
    </Table.Cell>

    <Table.Cell columnWidth="small">
      <CurrencyOrAmountCell label={label} amount={total} />
    </Table.Cell>
  </Table.Row>
);

export const PublicRecordsSummary = (
  props: PublicRecordsSummaryProps
): JSX.Element => {
  const { publicRecord } = props;

  return (
    <Card title="Public Records">
      <Table>
        <Table.Head>
          <Table.ColumnHeader />
          <Table.ColumnHeader>Open</Table.ColumnHeader>
          <Table.ColumnHeader>Closed</Table.ColumnHeader>
          <Table.ColumnHeader>Total</Table.ColumnHeader>
        </Table.Head>
        {publicRecord && (
          <Table.Body>
            <PublicRecordSummaryRow
              label="BK #"
              open={publicRecord.openBkNumber}
              closed={publicRecord.closedBkNumber}
              total={publicRecord.totalBkNumber}
            />
            <PublicRecordSummaryRow
              label="Liens #"
              open={publicRecord.openLiensNumber}
              closed={publicRecord.closedLiensNumber}
              total={publicRecord.totalLiensNumber}
            />
            <PublicRecordSummaryRow
              label="Liens $"
              open={publicRecord.openLiensAmount}
              closed={publicRecord.closedLiensAmount}
              total={publicRecord.totalLiensAmount}
            />
            <PublicRecordSummaryRow
              label="Judgement #"
              open={publicRecord.openJudgmentNumber}
              closed={publicRecord.closedJudgmentNumber}
              total={publicRecord.totalJudgmentNumber}
            />
            <PublicRecordSummaryRow
              label="Judgement $"
              open={publicRecord.openJudgmentAmount}
              closed={publicRecord.closedJudgmentAmount}
              total={publicRecord.totalJudgmentAmount}
            />
          </Table.Body>
        )}
      </Table>
    </Card>
  );
};
