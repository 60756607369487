/**
 * Default validation state of offers,
 * until user change something in the table
 * we consider all offers as valid
 * Each key represent a months amount
 * and we have hardcoded 3-18 months length for offers.
 */
export const LEDGER_VALIDATION_DEFAULT_OFFERS_STATE = {
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
};
