import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CustomerEstablishedDatesResponse,
  CustomerResponse,
  SubmissionResponse,
  UpdateCustomerRequestBody,
  IneligibilitiesResponse,
  BusinessPaynetResponse,
} from 'types/api/underwriting/types';

// CAUTION: LOCAL TESTING ONLY. UNCOMMENT THESE LINES AS NEEDED FOR MOCK DATA.
// import { mockIneligibilities } from 'mocks/underwriting/generators/ApiV2CustomersUuidIneligibilities';
// import { mockBusinessPaynetReponse } from 'mocks/underwriting/generators/ApiV1PaynetReport';

export const fetchCustomer = async (
  customerUuid: string
): Promise<CustomerResponse> => {
  const url = new URL(
    `/api/v2/customers/${customerUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<CustomerResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Customer');
  }

  return response.json();
};

export const fetchCustomerSubmissions = async (
  customerUuid: string
): Promise<SubmissionResponse[]> => {
  const url = new URL(
    `/api/v2/customers/${customerUuid}/submissions`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SubmissionResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Submissions from Customer'
    );
  }

  return response.json();
};

export const fetchCustomerEstablishedDates = async (
  customerUuid: string
): Promise<CustomerEstablishedDatesResponse> => {
  const url = new URL(
    `/api/v2/customers/${customerUuid}/established_dates`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<CustomerEstablishedDatesResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Customer established dates'
    );
  }

  return response.json();
};

export const patchCustomer = async (
  customerUuid: string,
  body: UpdateCustomerRequestBody
): Promise<CustomerResponse> => {
  const url = new URL(
    `/api/v2/customers/${customerUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    CustomerResponse,
    UpdateCustomerRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update Customer');
  }

  return response.json();
};

export const fetchCustomerIneligibilities = async (
  customerUuid: string
): Promise<IneligibilitiesResponse> => {
  const url = new URL(
    `api/v2/customers/${customerUuid}/ineligibilities`,
    UNDERWRITING_BASE_URL()
  );

  // CAUTION: LOCAL TESTING ONLY. UNCOMMENT THESE LINES AS NEEDED FOR MOCK DATA.
  // return mockIneligibilities();

  const response = await makeInternalAPIRequest<IneligibilitiesResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Customer ineligibilities'
    );
  }

  return response.json();
};

export const fetchBusinessPaynetReport = async (
  submissionUuid: string
): Promise<BusinessPaynetResponse[]> => {
  // CAUTION: LOCAL TESTING ONLY. UNCOMMENT THESE LINES AS NEEDED FOR MOCK DATA.
  // return mockBusinessPaynetReponse();

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/paynet_reports`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<BusinessPaynetResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Customer Paynet Report'
    );
  }

  return response.json();
};

export const deleteDocument = async (documentId: string): Promise<boolean> => {
  const url = new URL(
    `/api/v2/documents/${documentId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'DELETE'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to delete document');
  }

  return true;
};
