import React from 'react';
import { AddressSummary } from './ownerLexisNexisPage.types';
import { Box, Table, formatPhoneNumber } from '@forward-financing/fast-forward';

export interface AddressSummaryTableProps {
  addressSummaryData: AddressSummary[];
}

export const AddressSummaryTable = ({
  addressSummaryData,
}: AddressSummaryTableProps): JSX.Element => {
  return (
    <Table caption={'Address Summary'}>
      <Table.Head>
        <Table.ColumnHeader>Address</Table.ColumnHeader>
        <Table.ColumnHeader>Phone</Table.ColumnHeader>
        <Table.ColumnHeader>Subject</Table.ColumnHeader>
        <Table.ColumnHeader>Date Last Seen</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {addressSummaryData.map((row) => (
          <Table.Row
            key={`${row.address}${row.phones}${row.subject}${row.dateLastSeen}`}
          >
            <Table.Cell columnWidth="large">{row.address}</Table.Cell>
            <Table.Cell>
              {row.phones.length > 0 &&
                row.phones.map((phoneNumber) => (
                  <Box key={phoneNumber}>{formatPhoneNumber(phoneNumber)}</Box>
                ))}
            </Table.Cell>
            <Table.Cell>{row.subject}</Table.Cell>
            <Table.Cell>{row.dateLastSeen}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
