import React from 'react';

import { SectionHeader } from '../RenewalComparison.types';
import { displayMonths } from '../../../helpers/utils';
import { formatCurrency, Table } from '@forward-financing/fast-forward';
import { TableCellWithDefault } from './TableCellWithDefault';
import { RenewalComparisonData } from 'types/api/underwriting/types';

export interface ApprovalInfoSectionProps {
  data: RenewalComparisonData<'approval_data' | 'summary_data'>;
  headers?: SectionHeader;
}

export const ApprovalInfoSection = ({
  data,
  headers,
}: ApprovalInfoSectionProps): JSX.Element => {
  const amountApprovedDisplayText = (
    fundedAmount: number | null | undefined
  ): string | undefined => {
    if (typeof fundedAmount === 'number') {
      return formatCurrency(fundedAmount);
    }

    return undefined;
  };

  const hasPrevious2Data = !!data.previous_2?.approval_data?.success;

  return (
    <Table caption={'Approval Comparison Table'}>
      <Table.Head>
        <Table.ColumnHeader>Approval Info</Table.ColumnHeader>
        <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
        {headers?.previous_1 && (
          <Table.ColumnHeader>{headers.previous_1}</Table.ColumnHeader>
        )}
        {headers?.previous_2 && (
          <Table.ColumnHeader>{headers.previous_2}</Table.ColumnHeader>
        )}
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>Amount Approved</Table.RowHeader>
          <TableCellWithDefault>
            {amountApprovedDisplayText(
              data.current.approval_data?.funded_amount
            )}
          </TableCellWithDefault>
          <TableCellWithDefault>
            {amountApprovedDisplayText(
              data.previous_1?.approval_data?.funded_amount
            )}
          </TableCellWithDefault>
          {hasPrevious2Data && (
            <TableCellWithDefault>
              {amountApprovedDisplayText(
                data.previous_2?.approval_data?.funded_amount
              )}
            </TableCellWithDefault>
          )}
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Term Approved</Table.RowHeader>
          <TableCellWithDefault>
            {typeof data.current.approval_data?.term_months === 'number'
              ? displayMonths(data.current.approval_data?.term_months)
              : undefined}
          </TableCellWithDefault>
          <TableCellWithDefault>
            {typeof data.previous_1?.approval_data?.term_months === 'number'
              ? displayMonths(data.previous_1?.approval_data?.term_months)
              : undefined}
          </TableCellWithDefault>
          {hasPrevious2Data && (
            <TableCellWithDefault>
              {typeof data.previous_2?.approval_data?.term_months === 'number'
                ? displayMonths(data.previous_2?.approval_data?.term_months)
                : undefined}
            </TableCellWithDefault>
          )}
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Program Approved</Table.RowHeader>
          <TableCellWithDefault>
            {data.current.approval_data?.program}
          </TableCellWithDefault>
          <TableCellWithDefault>
            {data.previous_1?.approval_data?.program}
          </TableCellWithDefault>
          {hasPrevious2Data && (
            <TableCellWithDefault>
              {data.previous_2?.approval_data?.program}
            </TableCellWithDefault>
          )}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
