import { Banner, Card } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { UnderwritingAttachmentManager } from './UnderwritingAttachmentManager';
import { useSubmission } from '../attachmentManagerHooks';

type Params = {
  submissionUuid: string;
};

export const UnderwritingAttachmentManagerRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }
  const {
    submission,
    error: errorSubmission,
    refetch: refetchSubmission,
  } = useSubmission(submissionUuid);
  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false}>{errorSubmission.message}</Banner>
      )}
      <Card title="ISO Attachments">
        <UnderwritingAttachmentManager
          submission={submission}
          refetchSubmission={refetchSubmission}
          submissionUuid={submissionUuid}
        />
      </Card>
    </>
  );
};
