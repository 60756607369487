import { PlainDate } from '@forward-financing/fast-forward';

export const convertDateStringToPlainDate = (
  date?: string
): PlainDate | undefined => {
  if (!date) {
    return undefined;
  }

  const [year, month, day] =
    date?.split('-').map((val) => parseInt(val, 10)) ?? [];

  return new PlainDate(year, month, day);
};
