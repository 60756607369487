import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CreditDataResponse,
  CustomerOwnerResponse,
  InquiryOverview,
  LexisNexisOwnerReportResponse,
  LexisNexisResponse,
  UpdateOwnerRequestBody,
  OwnerManualSearchResultsResponse,
  OwnerResponse,
  OwnerSimilarContactsResponse,
  OwnerUccReportResponse,
  SimilarOwnersResponse,
} from 'types/api/underwriting/types';
import { CREATE_OWNER_URL, DELETE_OWNER_URL } from 'api/constants';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  CreditData,
  CreditReport,
  OwnerCreditInquiry,
  TradelinesOverview,
} from '../../components/SubmissionUnderwriting/OwnerOverview/types';
import { BasicOwnerInfoTableFragmentFragment } from '../../__generated__/graphql';
import { toCreditReport } from './creditFetchUtils';

export const fetchOwner = async (ownerUuid: string): Promise<OwnerResponse> => {
  const url = new URL(`/api/v2/owners/${ownerUuid}`, UNDERWRITING_BASE_URL());

  const response = await makeInternalAPIRequest<OwnerResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch owner');
  }

  return response.json();
};

export const fetchOwnerSimilarContacts = async (
  ownerUuid: string,
  submissionUuid: string
): Promise<OwnerSimilarContactsResponse[]> => {
  const url = new URL(
    `/api/v2/owners/${ownerUuid}/similar_contacts?submission_uuid=${submissionUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerSimilarContactsResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch owner');
  }

  return response.json();
};

/**
 * Given an ownerUuid, this endpoint will return a list of similar owners.
 *
 * The force param is used to force the backend to recompute the similar owners
 * rather than use the cached value that is uses by default.
 */
export const fetchSimilarOwners = async (
  ownerUuid: string,
  force: boolean
): Promise<SimilarOwnersResponse> => {
  const url = new URL(
    `/api/v2/owners/${ownerUuid}/similar_owners?force=${force}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SimilarOwnersResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch similar owners');
  }

  return response.json();
};

export const fetchPublicDocuments = async (
  submissionUuid: string
): Promise<LexisNexisOwnerReportResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/lexis_nexis_owner_reports`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LexisNexisOwnerReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch public documents for submission owners'
    );
  }

  return response.json();
};

export const fetchLexisNexisForOwners = async (
  submissionUuid: string
): Promise<LexisNexisResponse[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/lexis_nexis_owner_overviews`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LexisNexisResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch LexisNexis report for submission owners'
    );
  }

  return response.json();
};

export const fetchSubmissionOwners = async (
  submissionUuid: string
): Promise<OwnerResponse[]> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/owners`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerResponse[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch submission owners'
    );
  }

  return response.json();
};

export const createOwner = async ({
  submissionUuid,
  body,
}: {
  submissionUuid: string;
  body: UpdateOwnerRequestBody;
}): Promise<OwnerResponse> => {
  const url = new URL(
    CREATE_OWNER_URL(submissionUuid),
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    OwnerResponse,
    UpdateOwnerRequestBody
  >(url, 'POST', body);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to create Owner');
  }

  return response.json();
};

export const deleteOwner = async ({
  submissionUuid,
  ownerUuid,
}: {
  submissionUuid: string;
  ownerUuid: string;
}): Promise<MutationResponse> => {
  const url = new URL(
    DELETE_OWNER_URL(submissionUuid, ownerUuid),
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<MutationResponse>(
    url,
    'DELETE'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to delete Owner');
  }

  return { success: true };
};

export const patchOwner = async (
  ownerUuid: string,
  body: UpdateOwnerRequestBody
): Promise<OwnerResponse> => {
  const url = new URL(`api/v2/owners/${ownerUuid}`, UNDERWRITING_BASE_URL());

  const response = await makeInternalAPIRequest<
    OwnerResponse,
    UpdateOwnerRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update Owner');
  }

  return response.json();
};

export const fetchOwnerManualSearchResult = async (
  submissionUuid: string,
  ownerUuid: string,
  queryParams: string
): Promise<OwnerManualSearchResultsResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/lexis_nexis_owner_search?${queryParams}`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<OwnerManualSearchResultsResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch LexisNexis Owner Manual Search'
    );
  }

  return response.json();
};

export const fetchOwnerUccReport = async (
  submissionUuid: string,
  ownerUuid: string,
  reportId: string
): Promise<OwnerUccReportResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/lexis_nexis_owner_ucc_documents/${reportId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerUccReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Owner UCC Report');
  }

  return response.json();
};

//TODO any formating below this point goes to Feature hooks
export const fetchOwnerCreditInquiries = async (
  submissionUuid: string
): Promise<OwnerCreditInquiry[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/inquiries`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<InquiryOverview[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Owner Inquiries');
  }

  const responseJson = await response.json();
  //TODO formating into Feature hook
  return responseJson.map((inquiry) => ({
    id: inquiry.id,
    ownerUuid: inquiry.owner_uuid,
    inquiredOn: inquiry.inquired_on,
    subscriber: inquiry.subscriber,
    inquiryType: inquiry.translated_inquiry_type_code,
    mcaLenderName: inquiry.mca_lender_name ?? undefined,
  }));
};

//TODO move this to Feature hook

const toTradelineOverview = (
  response: CreditDataResponse
): TradelinesOverview => {
  return {
    ownerUuid: response.owner_uuid,
    current30Days: response.current_delinquencies_over_thirty_days_count,
    current60Days: response.current_delinquencies_over_sixty_days_count,
    current90Days: response.current_delinquencies_over_ninety_days_count,
    currentDerog: response.current_derog_count,
    allTime30Days: response.delinquencies_over_thirty_days_count,
    allTime60Days: response.delinquencies_over_sixty_days_count,
    allTime90Days: response.delinquencies_over_ninety_days_count,
    allTimeDerog: response.now_delinquent_derog_count,
  };
};
// this returns data for tradelines count tables as well as credit overview
export const fetchCreditDataForOwners = async (
  submissionUuid: string
): Promise<CreditData> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/credit_overviews`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<CreditDataResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch CreditOverviews');
  }

  const responseJson = await response.json();

  const credit: CreditReport[] = responseJson.map(toCreditReport);

  const tradelines: TradelinesOverview[] =
    responseJson.map(toTradelineOverview);

  return {
    creditReports: credit,
    tradelinesOverviews: tradelines,
  };
};

//we already have fetchSubmissionOwners that's why we have V2,
//we will change in another PR.
export const fetchSubmissionOwnersV2 = async (
  submissionUuid: string
): Promise<BasicOwnerInfoTableFragmentFragment[]> => {
  const ownersUrl = new URL(
    `/api/v2/submissions/${submissionUuid}/owners`,
    UNDERWRITING_BASE_URL()
  );

  const ownersResponse = await makeInternalAPIRequest<CustomerOwnerResponse[]>(
    ownersUrl,
    'GET'
  );

  if (!ownersResponse.ok) {
    throw new NetworkError(ownersResponse.status, 'Failed to fetch Owners');
  }

  const owners = await ownersResponse.json();

  //TODO formating into Feature hook
  return owners.map((o) => ({
    id: o.uuid,
    fullName: `${o.first_name} ${o.last_name}`,
    ssn: o.ssn,
    ssnLastFour: o.ssn?.slice(-4),
    birthdate: o.born_on,
    homePhone: o.home_phone,
    workPhone: '', // Will be removed once we can properly refactor this type
    cellPhone: o.cell_phone,
    email: o.email,
    ownershipDate: o.ownership_date,
    ownershipPercentage: o.ownership_percentage
      ? Number(o.ownership_percentage)
      : undefined,
    address: o.address ?? {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
    },
  }));
};
