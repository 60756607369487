import React from 'react';
import {
  Banner,
  Flex,
  Text,
  formatDateString,
  Icon,
} from '@forward-financing/fast-forward';
import { isDateInPast } from './paymentScheduleUtils';
import { usePaymentScheduleContext } from './PaymentScheduleContext';

export const BorrowingBaseBanner = (): JSX.Element => {
  const { advanceProfile } = usePaymentScheduleContext();

  if (!advanceProfile || !advanceProfile?.dateAdvanceLeavesBorrowingBase) {
    return <></>;
  }

  const { dateAdvanceLeavesBorrowingBase } = advanceProfile;

  if (isDateInPast(dateAdvanceLeavesBorrowingBase)) {
    return (
      <Banner dismissable={false} variant="info">
        <Flex alignItems="center">
          <Text>
            BORROWING BASE: The account left the borrowing base on{' '}
            {formatDateString(dateAdvanceLeavesBorrowingBase)}.
          </Text>
        </Flex>
      </Banner>
    );
  }

  return (
    <Banner dismissable={false} variant="warning">
      <Flex gap={2} alignItems="center">
        <Icon name="exclamation-circle" />
        <Text>
          BORROWING BASE: The account will leave the borrowing base on{' '}
          {formatDateString(dateAdvanceLeavesBorrowingBase)}.
        </Text>
      </Flex>
    </Banner>
  );
};
