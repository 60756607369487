/* istanbul ignore file */
/**
 * We're skipping testing this file because most of the
 * functionality should be tested by Fast Forward and
 * the underlying datepicker library.
 *
 * The specific layouts for calendar days are tested in
 * their respective days.
 *
 * @tyrelosaur - September 26, 2022
 */
import React from 'react';
import { Calendar, PlainDate } from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { PaymentDay } from '../paymentSchedule.types';
import { CalendarDay } from './CalendarDay';

export const PaymentCalendar = (): JSX.Element => {
  const {
    formState: { startDate, endDate, isOpenEnded },
    updateFormState,
    paymentSchedule,
  } = usePaymentScheduleContext();

  const holidayDates = paymentSchedule
    ?.filter((day) => day.kind === 'HolidayDay')
    ?.map((day) => day.date);

  return (
    <Calendar<PaymentDay>
      hideWeekends
      minDate={paymentSchedule?.[0]?.date}
      maxDate={paymentSchedule?.[paymentSchedule.length - 1]?.date}
      startDate={startDate ?? null}
      endDate={endDate ?? null}
      disabledDates={holidayDates}
      onChange={([start, end]) => {
        if (isOpenEnded) {
          if (startDate && start && startDate.equals(start)) {
            // Calendar is trying to select a range, but we're creating
            // an open ended adjustment, so set the start date to what
            // the Calendar wants to set as the end date.
            updateFormState({
              startDate: end ?? undefined,
              endDate: undefined,
            });
          } else {
            // We either clicked a date before the current startDate
            // or the same date. In either case, just set the startDate,
            // and ensure that the endDate is empty.
            updateFormState({
              startDate: start ?? undefined,
              endDate: undefined,
            });
          }
        } else {
          if (startDate && !endDate) {
            // We're not open ended, but we've got no end date selected.
            // Most likely, the user clicked a date, then flipped to open ended,
            // then flipped back to NOT open ended.
            if (end) {
              // Calendar is trying to set a date that is after the current
              // startDate, so select the range.
              updateFormState({
                startDate: startDate ?? undefined,
                endDate: end ?? undefined,
              });
            } else {
              // Calendar is trying to select a single date (so the clicked date
              // is before the currently selected date in form state). Therefore,
              // select the startDate as both the start and endDate
              updateFormState({
                startDate: start ?? undefined,
                endDate: start ?? undefined,
              });
            }
          } else if (
            startDate &&
            start && // only here for an emptiness check. should pretty much always be defined
            endDate &&
            startDate.equals(endDate) &&
            PlainDate.compare(start, startDate) === 1
          ) {
            // We have a start and end date selected in form state, but they're
            // the same. We've clicked another date, and it's after the currently
            // selected start date. Therefore, we're trying to select a range.
            // Set the date we clicked to be the endDate, and leave the selected
            // startDate as is.
            updateFormState({
              startDate: startDate ?? undefined,
              endDate: start ?? undefined,
            });
          } else {
            // We either have nothing selected, or we've clicked a date before the
            // currently selected startDate
            updateFormState({
              startDate: start ?? undefined,
              endDate: start ?? undefined,
            });
          }
        }
      }}
      calendarEvents={paymentSchedule ?? undefined}
    >
      {(renderProps) => <CalendarDay {...renderProps} />}
    </Calendar>
  );
};
