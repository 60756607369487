import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
} from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  useDealScoringNotes,
  useUpdateSubmissionNote,
  useManagerDealScore,
  useUpdateManagerScore,
  useCreateManagerScore,
  UpdateManagerScoreBody,
} from '../../DealScoringFetchHooks';
import { DealScoringNotes } from '../../DealScoringNotes';
import { ManagerScoringNew } from './ManagerScoringNew';

export interface DealScoringManagerNotesProps {
  submissionUuid: string;
}

export const ManagerFeedbackNew = ({
  submissionUuid,
}: DealScoringManagerNotesProps): JSX.Element => {
  const [isClosed, setIsClosed] = useState(true);

  const isNonEmptyError = (error?: Error | undefined): boolean =>
    !!error && typeof error?.message === 'string' && error?.message.length > 0;

  // Notes

  const {
    data: initialNotes,
    loading: initialLoading,
    error: fetchError,
  } = useDealScoringNotes(submissionUuid);

  const [
    updateSubmissionNote,
    { data: updatedNote, loading: updating, error: updateError },
  ] = useUpdateSubmissionNote(submissionUuid, 'MANAGER');

  const loading = initialLoading || updating;

  const initialNote = initialNotes?.find(
    (noteElem) => noteElem.noteType === 'MANAGER'
  );
  const note = updatedNote || initialNote;

  const errors = [updateError, fetchError].filter(isNonEmptyError) as Error[];

  // Manager Scoring

  const {
    data: initialScore,
    loading: initialScoreLoading,
    error: initialScoreError,
  } = useManagerDealScore(submissionUuid);

  const [
    updateManagerScore,
    { data: updatedScore, loading: updatingScore, error: updateScoreError },
  ] = useUpdateManagerScore(submissionUuid);

  const [
    createManagerScore,
    { loading: creatingScore, error: createScoreError },
  ] = useCreateManagerScore(submissionUuid);

  const loadingScore = initialScoreLoading || creatingScore || updatingScore;

  const errorsScore = [
    createScoreError,
    updateScoreError,
    initialScoreError,
  ].filter(isNonEmptyError) as Error[];

  const score =
    updatedScore?.managerFeedbackScore?.toString() ||
    initialScore?.managerFeedbackScore?.toString() ||
    '';

  const isScored = updatedScore?.lastUpdatedAt || initialScore?.lastUpdatedAt;

  const updateScore = async (
    input: UpdateManagerScoreBody
  ): Promise<MutationResponse> => {
    // Updating the score doesn't work unless one already exists.
    if (!isScored) {
      await createManagerScore();
    }

    return updateManagerScore(input);
  };

  // General stuff

  const hasManagerFeedback = !!note?.content || isScored;

  // The default button label is "Add Manager Feedback" which could be
  // misleading while the data is still loading, so make sure we show that.
  // No need to add "loading..." when updating because the button is not visible.
  const buttonLabel = `${hasManagerFeedback ? 'View' : 'Add'} Manager Feedback${
    initialLoading ? ' (loading...)' : ''
  }`;

  return (
    <Box>
      <Divider />
      {isClosed ? (
        <IconButton
          icon={[hasManagerFeedback ? 'fas' : 'far', 'comment']}
          label={buttonLabel}
          onClick={() => setIsClosed(false)}
        />
      ) : (
        <IconButton
          icon="xmark"
          label="Close Manager Feedback"
          onClick={() => setIsClosed(true)}
        />
      )}

      {/* TODO: Change this to a <Box hidden={{isClosed ? 'true' : 'false'}}>
          when it's avaliable in fast-forward. */}
      <div
        style={{ display: isClosed ? 'none' : 'block' }}
        data-testid="manager-feedback-content"
      >
        <Grid gutter>
          <Grid.Item xs={12} s={6} l={6}>
            <ManagerScoringNew
              score={score}
              loading={loadingScore}
              updateScore={updateScore}
              errors={errorsScore}
            />
          </Grid.Item>

          <Grid.Item xs={12} s={6} l={6}>
            <DealScoringNotes
              note={note}
              emptyNoteType="MANAGER"
              loading={loading}
              updateNote={updateSubmissionNote}
              errors={errors}
            />
          </Grid.Item>
        </Grid>
      </div>
    </Box>
  );
};
