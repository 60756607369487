import React from 'react';
import { Box, Table } from '@forward-financing/fast-forward';
import { CriminalOffense, CriminalRecord } from './ownerLexisNexisPage.types';

export type CriminalRecordTableProps = {
  criminalRecordData: CriminalRecord[];
};

export type OffenseSubRowProps = {
  offense?: CriminalOffense;
};

export const OffenseSubRow = ({ offense }: OffenseSubRowProps): JSX.Element => {
  return (
    <>
      <Table.Cell columnWidth="large">{offense?.offense || 'N/A'}</Table.Cell>
      <Table.Cell columnWidth="large">
        {offense?.disposition || 'N/A'}
      </Table.Cell>
      <Table.Cell>{offense?.offenseDate || 'N/A'}</Table.Cell>
      <Table.Cell>{offense?.arrestDate || 'N/A'}</Table.Cell>
    </>
  );
};

const subsequentOffenses = (offenses: CriminalOffense[]): CriminalOffense[] => {
  return offenses.slice(1);
};

export const CriminalRecordTable = ({
  criminalRecordData,
}: CriminalRecordTableProps): JSX.Element => {
  if (criminalRecordData.length === 0) {
    return <></>;
  }

  return (
    <Box>
      <Table caption={'Criminal Records'}>
        <Table.Head>
          <Table.ColumnHeader>Data Source</Table.ColumnHeader>
          <Table.ColumnHeader>Name</Table.ColumnHeader>
          <Table.ColumnHeader colSpan={2}>Offenses</Table.ColumnHeader>
          <Table.ColumnHeader>Offense Date</Table.ColumnHeader>
          <Table.ColumnHeader>Arrest Date</Table.ColumnHeader>
          <Table.ColumnHeader>Case Filing Date</Table.ColumnHeader>
          <Table.ColumnHeader>State</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {criminalRecordData.map((data, idx) => (
            <>
              <Table.Row key={data.uniqueId} sectionDivider={idx > 0}>
                <Table.Cell
                  rowSpan={data.offenses.length}
                  verticalAlign="middle"
                >
                  {data.dataSource}
                </Table.Cell>
                <Table.Cell
                  rowSpan={data.offenses.length}
                  verticalAlign="middle"
                >
                  {data.name}
                </Table.Cell>
                <OffenseSubRow offense={data.offenses[0]} />
                <Table.Cell
                  rowSpan={data.offenses.length}
                  verticalAlign="middle"
                >
                  {data.caseFilingDate}
                </Table.Cell>
                <Table.Cell
                  rowSpan={data.offenses.length}
                  verticalAlign="middle"
                >
                  {data.state}
                </Table.Cell>
              </Table.Row>
              {/**
               * We use a helper here to remove the first item from the array of offenses because
               * the first offense is already being displayed above. We only want to map over the
               * subsequent items items to avoid duplicates.
               */}
              {subsequentOffenses(data.offenses).map((offense) => {
                return (
                  <Table.Row key={offense.offenseDate}>
                    <OffenseSubRow offense={offense} />
                  </Table.Row>
                );
              })}
            </>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
