import React from 'react';
import {
  Box,
  formatPhoneNumber,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { BusinessInformation } from './BusinessLexisNexisPage.types';

type BusinessInformationTableProps = {
  businessInformation?: BusinessInformation;
};

export const BusinessInformationTable = ({
  businessInformation,
}: BusinessInformationTableProps): JSX.Element => {
  return businessInformation ? (
    <Table caption="Business Information Table">
      <Table.Head>
        <Table.ColumnHeader>Company Name</Table.ColumnHeader>
        <Table.ColumnHeader>Address</Table.ColumnHeader>
        <Table.ColumnHeader>Phone Numbers</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        <Table.Row key={businessInformation.companyName}>
          <Table.Cell>{businessInformation.companyName}</Table.Cell>
          <Table.Cell>{businessInformation.address}</Table.Cell>
          <Table.Cell>
            {businessInformation.phoneNumbers.map((phoneNumber) => {
              return (
                <Text key={phoneNumber}>{formatPhoneNumber(phoneNumber)}</Text>
              );
            })}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Business Information')}</Text>
    </Box>
  );
};
