import React from 'react';
import { Navbar } from '@forward-financing/fast-forward';
import { AUTH_BASE_URL } from 'constants/globals';

export const NavbarSignOutLink = (): JSX.Element => (
  <Navbar.Link
    key="signout"
    icon="user"
    href={new URL('/sign_out', AUTH_BASE_URL())}
  >
    Sign Out
  </Navbar.Link>
);
