import React from 'react';
import { SALES_KEY, UNDERWRITERS_KEY } from './exceptionRequestConstants';
import {
  Grid,
  formatDateString,
  DescriptionList,
} from '@forward-financing/fast-forward';

export interface SummaryRowProps {
  displayStatus: string;
  submittedAt: string;
  viewer: typeof SALES_KEY | typeof UNDERWRITERS_KEY;
  collaboratorName: string | null;
  reviewCompletionTime: string | null;
  decisionType?: string | null;
}

const formatDecision = (decisionType?: string): string =>
  decisionType && decisionType.match(/approval|decline/gi)
    ? decisionType
    : 'pending';

export const SummaryRow = (props: SummaryRowProps): JSX.Element => {
  return (
    <DescriptionList>
      <Grid>
        <Grid.Item xs={12} s={6} xl={3}>
          <DescriptionList.Item>
            <DescriptionList.Term>Submitted</DescriptionList.Term>
            <DescriptionList.Details>
              {formatDateString(props.submittedAt)}
            </DescriptionList.Details>
          </DescriptionList.Item>
        </Grid.Item>
        <Grid.Item xs={12} s={6} xl={3}>
          <DescriptionList.Item>
            <DescriptionList.Term>Status</DescriptionList.Term>
            <DescriptionList.Details>
              {props.reviewCompletionTime
                ? formatDateString(props.reviewCompletionTime)
                : props.displayStatus}
            </DescriptionList.Details>
          </DescriptionList.Item>
        </Grid.Item>
        <Grid.Item xs={12} s={6} xl={3}>
          <DescriptionList.Item>
            <DescriptionList.Term>
              {props.viewer === SALES_KEY ? 'Underwriter' : 'Requested By'}
            </DescriptionList.Term>
            <DescriptionList.Details>
              {props.collaboratorName}
            </DescriptionList.Details>
          </DescriptionList.Item>
        </Grid.Item>
        <Grid.Item xs={12} s={6} xl={3}>
          <DescriptionList.Item>
            <DescriptionList.Term>Decision</DescriptionList.Term>
            <DescriptionList.Details>
              {formatDecision(props.decisionType ?? undefined)}
            </DescriptionList.Details>
          </DescriptionList.Item>
        </Grid.Item>
      </Grid>
    </DescriptionList>
  );
};
