import React from 'react';
import { Table, formatDateString } from '@forward-financing/fast-forward';

export interface InquiriesTableProps {
  /**
   * TODO: replace custom type with ExperianInquiry after
   * flag "experian_consumer_3pi_inquiries_detail"is enabled
   */
  inquiries: {
    id?: number;
    subscriber?: string;
    subscriberName?: string;
    mcaLenderName?: string;
    inquiredOn: string;
    inquiryType: string;
  }[];
}

export const InquiriesTable = (props: InquiriesTableProps): JSX.Element => (
  <Table>
    <Table.Head>
      <Table.ColumnHeader>Name</Table.ColumnHeader>
      <Table.ColumnHeader>Is MCA?</Table.ColumnHeader>
      <Table.ColumnHeader>Date</Table.ColumnHeader>
      <Table.ColumnHeader>Type</Table.ColumnHeader>
    </Table.Head>
    <Table.Body>
      {props.inquiries.map((inquiry) => (
        <Table.Row key={inquiry.id ?? inquiry.inquiredOn}>
          <Table.Cell>
            {inquiry.subscriber ?? inquiry.subscriberName}
          </Table.Cell>
          <Table.Cell
            backgroundColor={inquiry.mcaLenderName ? 'red-800' : undefined}
          >
            {inquiry.mcaLenderName ? 'Yes' : 'No'}
          </Table.Cell>
          <Table.Cell>{formatDateString(inquiry.inquiredOn)}</Table.Cell>
          <Table.Cell>{inquiry.inquiryType}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
