import React, { useState } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import {
  Box,
  Button,
  Currency,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { toDate } from '../../helpers/string/dateUtils';
import { FFLogger } from '../../api/LogClient';
import { FundingClient } from '../../api/FundingClient';
import { FUNDING_URL } from '../../constants/globals';
import { Prepayment } from '../../api/BankingClient/codecs';
import { toError } from '../../helpers/errorUtils';
import { useUserContext } from 'contexts/UserContext';
import { canApplyPrepaymentDiscount } from 'components/RouteAccess/authorizationHelpers';

export interface AdvancePrepaymentFormProps {
  opportunityUuid: string;
  prepaymentDate?: string;
  advanceDate: string;
  prepaymentSchedule: Prepayment[];
  commission: number;
  advanceRecordId: number;
}

interface PrepaymentDiscountCalculation {
  days_paid_in: number;
  new_term: number;
  new_factor_rate: number;
  new_purchased_amount: number;
}

function calculatePrepaymentDiscount(
  prepaymentDate: string,
  advanceDate: string,
  prepaymentSchedule: Prepayment[]
): PrepaymentDiscountCalculation {
  const daysPaidIn = differenceInDays(
    toDate(prepaymentDate),
    toDate(advanceDate)
  );
  const prepaymentScheduleSortedByTerm = [...prepaymentSchedule].sort(
    (left, right) => left.term - right.term
  );
  const prepayment =
    prepaymentScheduleSortedByTerm.find(
      (schedule) => schedule.term * 1.5 - daysPaidIn >= 0
    ) ||
    prepaymentScheduleSortedByTerm[prepaymentScheduleSortedByTerm.length - 1];

  return {
    days_paid_in: daysPaidIn,
    new_term: prepayment.term,
    new_factor_rate: prepayment.factor_rate,
    new_purchased_amount: prepayment.purchase_amount,
  };
}

async function submitPrepaymentDiscount(
  opportunityUuid: string,
  prepaymentDiscount: PrepaymentDiscountCalculation,
  commission: number,
  advanceRecordId: number,
  prepaymentDate?: string
): Promise<void> {
  try {
    await FundingClient.updateAdvance(opportunityUuid, {
      factor_rate: prepaymentDiscount.new_factor_rate,
      buy_rate: prepaymentDiscount.new_factor_rate - commission / 100.0,
      prepayment_date: prepaymentDate,
      purchased_amount: prepaymentDiscount.new_purchased_amount,
    });
    if (window?.top) {
      // .top is so it can redirect from within iframe
      window.top.location.href = `${FUNDING_URL()}/admin/advances/${advanceRecordId}`;
    }
  } catch (caughtValue: unknown) {
    const error = toError(caughtValue);
    FFLogger.error(error);
  }
}

export const AdvancePrepaymentForm = (
  props: AdvancePrepaymentFormProps
): JSX.Element => {
  const user = useUserContext();
  const [prepaymentDate, setPrepaymentDate] = useState(props.prepaymentDate);
  const prepaymentDiscount = prepaymentDate
    ? calculatePrepaymentDiscount(
        prepaymentDate,
        props.advanceDate,
        props.prepaymentSchedule
      )
    : null;
  return (
    <>
      <Box>
        <Subheading>Date Prepayment Received</Subheading>
        <input
          type="date"
          data-testid="date-prepayment-received"
          onChange={(event) => {
            event.persist();
            setPrepaymentDate(event.target.value);
          }}
        />
      </Box>
      {prepaymentDiscount && (
        <Box>
          <Subheading>Prepayment Discount</Subheading>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.RowHeader>Days Paid In</Table.RowHeader>
                <Table.Cell>{prepaymentDiscount.days_paid_in}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>New Term</Table.RowHeader>
                <Table.Cell>{prepaymentDiscount.new_term * 1.5}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>New Factor Rate</Table.RowHeader>
                <Table.Cell>{prepaymentDiscount.new_factor_rate}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.RowHeader>New Purchased Amount</Table.RowHeader>
                <Table.Cell>
                  <Currency amount={prepaymentDiscount.new_purchased_amount} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          {canApplyPrepaymentDiscount(user.role) && (
            <Button
              onClick={() =>
                submitPrepaymentDiscount(
                  props.opportunityUuid,
                  prepaymentDiscount,
                  props.commission,
                  props.advanceRecordId,
                  prepaymentDate
                )
              }
            >
              Apply Prepayment Discount
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
