import React from 'react';

import { SectionHeader } from '../RenewalComparison.types';
import { displayMonths, displayPercentage } from '../../../helpers/utils';
import { FUNDING_URL } from '../../../constants/globals';
import {
  Icon,
  formatDateString,
  isIso8601DateString,
  Table,
  Link,
  formatCurrency,
} from '@forward-financing/fast-forward';
import { TableCellWithDefault } from './TableCellWithDefault';
import {
  RenewalComparisonData,
  RenewalComparisonRow,
} from 'types/api/underwriting/types';

export interface FundingInfoSectionProps {
  data: RenewalComparisonData<'funding_data' | 'summary_data'>;
  headers: SectionHeader;
}

interface PaymentHistoryLinkProps {
  recordId: number;
}

const PaymentHistoryLink = ({
  recordId,
}: PaymentHistoryLinkProps): JSX.Element => {
  const url = new URL(
    `admin/advances/${recordId}?tab=payback-schedule`,
    FUNDING_URL()
  );
  return (
    <Link href={url} newTab>
      View Payment Calendar <Icon name="arrow-up-right-from-square" />
    </Link>
  );
};

const formatDateValue = (val: string): string =>
  isIso8601DateString(val) ? formatDateString(val) : val;

export const FundingInfoSection = ({
  data,
  headers,
}: FundingInfoSectionProps): JSX.Element => {
  const [current, previous_1, previous_2] = Object.values(data)
    .filter((d) => !!d)
    .map((opp) => (opp as RenewalComparisonRow).funding_data);

  return (
    <>
      {current && (
        <Table caption={'funding-info'}>
          <Table.Head>
            <Table.ColumnHeader>Funding Info</Table.ColumnHeader>
            <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
            <Table.ColumnHeader>{headers.previous_1}</Table.ColumnHeader>
            {headers.previous_2 && (
              <Table.ColumnHeader>{headers.previous_2}</Table.ColumnHeader>
            )}
          </Table.Head>

          <Table.Body>
            <Table.Row>
              <Table.RowHeader>UW</Table.RowHeader>
              <TableCellWithDefault>
                {data.current.summary_data?.underwriter_name ?? 'Unassigned'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {data.previous_1?.summary_data?.underwriter_name ??
                  'Unassigned'}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {data.previous_2?.summary_data?.underwriter_name ??
                    'Unassigned'}
                </TableCellWithDefault>
              )}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Funded Date</Table.RowHeader>
              <TableCellWithDefault>
                {current.date_of_advance
                  ? formatDateValue(current.date_of_advance.toString())
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.date_of_advance
                  ? formatDateValue(previous_1?.date_of_advance.toString())
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.date_of_advance
                    ? formatDateValue(previous_2.date_of_advance.toString())
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>% Repaid</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.percentage_repaid === 'number'
                  ? displayPercentage(current.percentage_repaid)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.percentage_repaid === 'number'
                  ? displayPercentage(previous_1?.percentage_repaid)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.percentage_repaid === 'number'
                    ? displayPercentage(previous_2.percentage_repaid)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Last Payment Date</Table.RowHeader>
              <TableCellWithDefault>
                {current.last_payment_date
                  ? formatDateValue(current.last_payment_date.toString())
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.last_payment_date &&
                  formatDateValue(previous_1?.last_payment_date.toString())}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.last_payment_date &&
                    formatDateValue(previous_2.last_payment_date.toString())}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>30 Day Pace</Table.RowHeader>
              <TableCellWithDefault>
                {current ? (
                  <>
                    {typeof current.thirty_day_dollar_pace === 'number'
                      ? `${displayPercentage(current.thirty_day_dollar_pace)}`
                      : 'N/A'}
                  </>
                ) : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.success ? (
                  <>
                    {typeof previous_1?.thirty_day_dollar_pace === 'number'
                      ? `${displayPercentage(
                          previous_1?.thirty_day_dollar_pace
                        )}`
                      : 'N/A'}
                  </>
                ) : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.success ? (
                    <>
                      {typeof previous_2.thirty_day_dollar_pace === 'number'
                        ? `${displayPercentage(
                            previous_2.thirty_day_dollar_pace
                          )}`
                        : 'N/A'}
                    </>
                  ) : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>60 Day Pace</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.sixty_day_dollar_pace === 'number'
                  ? `${displayPercentage(current.sixty_day_dollar_pace)}`
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.success ? (
                  <>
                    {typeof previous_1?.sixty_day_dollar_pace === 'number'
                      ? `${displayPercentage(
                          previous_1?.sixty_day_dollar_pace
                        )}`
                      : 'N/A'}
                  </>
                ) : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.success ? (
                    <>
                      {typeof previous_2?.sixty_day_dollar_pace === 'number'
                        ? `${displayPercentage(
                            previous_2?.sixty_day_dollar_pace
                          )}`
                        : 'N/A'}
                    </>
                  ) : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Overall Pace</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.overall_dollar_pace === 'number'
                  ? `${displayPercentage(current.overall_dollar_pace)}`
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.success ? (
                  <>
                    {typeof previous_1?.overall_dollar_pace === 'number'
                      ? `${displayPercentage(previous_1?.overall_dollar_pace)}`
                      : 'N/A'}
                  </>
                ) : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.success ? (
                    <>
                      {typeof previous_2?.overall_dollar_pace === 'number'
                        ? `${displayPercentage(
                            previous_2?.overall_dollar_pace
                          )}`
                        : 'N/A'}
                    </>
                  ) : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Overall Payment Count Pace</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.overall_payment_count_pace === 'number'
                  ? `${displayPercentage(current.overall_payment_count_pace)}`
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.success ? (
                  <>
                    {typeof previous_1?.overall_payment_count_pace === 'number'
                      ? `${displayPercentage(
                          previous_1?.overall_payment_count_pace
                        )}`
                      : 'N/A'}
                  </>
                ) : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.success ? (
                    <>
                      {typeof previous_2?.overall_payment_count_pace ===
                      'number'
                        ? `${displayPercentage(
                            previous_2?.overall_payment_count_pace
                          )}`
                        : 'N/A'}
                    </>
                  ) : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Most Recent Payment Status</Table.RowHeader>
              <TableCellWithDefault>
                {current.most_recent_payback_status || 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.most_recent_payback_status}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.most_recent_payback_status}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Funded Amount</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.amount === 'number'
                  ? formatCurrency(current.amount)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.amount === 'number'
                  ? formatCurrency(previous_1?.amount)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.amount === 'number'
                    ? formatCurrency(previous_2.amount)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Funded Term</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.target_term === 'number'
                  ? displayMonths(current.target_term)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.target_term === 'number'
                  ? displayMonths(previous_1?.target_term)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.target_term === 'number'
                    ? displayMonths(previous_2.target_term)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Commission</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.commission === 'number'
                  ? displayPercentage(current.commission)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.commission === 'number'
                  ? displayPercentage(previous_1?.commission)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.commission === 'number'
                    ? displayPercentage(previous_2.commission)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Current Balance</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.balance === 'number'
                  ? formatCurrency(current.balance)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.balance === 'number'
                  ? formatCurrency(previous_1?.balance)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.balance === 'number'
                    ? formatCurrency(previous_2.balance)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Minimum to Approve</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.minimum_to_approve === 'number'
                  ? formatCurrency(current.minimum_to_approve)
                  : undefined}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.minimum_to_approve === 'number'
                  ? formatCurrency(previous_1?.minimum_to_approve)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.minimum_to_approve === 'number'
                    ? formatCurrency(previous_2.minimum_to_approve)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Net Funded Amount</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.net_funded_amount === 'number'
                  ? formatCurrency(current.net_funded_amount)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.net_funded_amount === 'number'
                  ? formatCurrency(previous_1?.net_funded_amount)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.net_funded_amount === 'number'
                    ? formatCurrency(previous_2.net_funded_amount)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Processing Fee</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.processing_fee_amount === 'number'
                  ? formatCurrency(current.processing_fee_amount)
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.processing_fee_amount === 'number'
                  ? formatCurrency(previous_1?.processing_fee_amount)
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.processing_fee_amount === 'number'
                    ? formatCurrency(previous_2.processing_fee_amount)
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Payment Frequency</Table.RowHeader>
              <TableCellWithDefault>
                {current.contractual_payback_frequency || 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.contractual_payback_frequency}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.contractual_payback_frequency}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader># Missed Payments</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.missed_payment_count === 'number'
                  ? current.missed_payment_count
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.missed_payment_count === 'number'
                  ? previous_1?.missed_payment_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.missed_payment_count === 'number'
                    ? previous_2.missed_payment_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader># Reduced Payments</Table.RowHeader>
              <TableCellWithDefault>
                {typeof current.reduced_payment_count === 'number'
                  ? current.reduced_payment_count
                  : 'N/A'}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {typeof previous_1?.reduced_payment_count === 'number'
                  ? previous_1?.reduced_payment_count
                  : undefined}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {typeof previous_2.reduced_payment_count === 'number'
                    ? previous_2.reduced_payment_count
                    : undefined}
                </TableCellWithDefault>
              )}
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Payment History</Table.RowHeader>
              <TableCellWithDefault>
                {current.record_id ? (
                  <PaymentHistoryLink recordId={current.record_id} />
                ) : (
                  'N/A'
                )}
              </TableCellWithDefault>
              <TableCellWithDefault>
                {previous_1?.record_id && (
                  <PaymentHistoryLink recordId={previous_1?.record_id} />
                )}
              </TableCellWithDefault>
              {previous_2 && (
                <TableCellWithDefault>
                  {previous_2.record_id && (
                    <PaymentHistoryLink recordId={previous_2.record_id} />
                  )}
                </TableCellWithDefault>
              )}
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
