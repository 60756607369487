import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  Text,
} from '@forward-financing/fast-forward';
import { ApplicationResponseOnboardingStatus } from 'types/api/waitingRoom/types';

export interface OnboardingStatusConfirmationModalProps {
  status: ApplicationResponseOnboardingStatus;
  trigger: JSX.Element;
  onConfirmation: () => void;
}

export const OnboardingStatusConfirmationModal = ({
  status,
  trigger,
  onConfirmation,
}: OnboardingStatusConfirmationModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirm = (): void => {
    onConfirmation();
    setIsModalOpen(false);
  };

  return (
    <Modal
      trigger={trigger}
      isOpen={isModalOpen}
      title="Application Status Change Confirmation"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          <Text>
            You are about to mark this Application as{' '}
            {<Text bold>{status}</Text>}. This cannot be undone. Please confirm.
          </Text>
          <Flex justifyContent={'center'} flexDirection={'row'} gap={4} mt={5}>
            <Button
              startIcon="times"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              variant={status === 'junk' ? 'danger' : undefined}
              onClick={() => handleConfirm()}
            >
              Confirm Status Change
            </Button>
          </Flex>
        </Box>
      }
    />
  );
};
