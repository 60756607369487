import React from 'react';
import { ValidationMessage } from './ValidationMessage';
import { ValidationResult } from '../../../helpers/validations/codecs';

export interface WebPresenceInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  validationResult?: ValidationResult;
  leadingComponent?: JSX.Element;
  required?: boolean;
  isDisabled?: boolean;
}

export const WebPresenceInput = (props: WebPresenceInputProps): JSX.Element => {
  const isInvalid = props.validationResult && !props.validationResult.isValid;
  const className = isInvalid ? 'input is-danger' : 'input';
  const isDisabled = props.isDisabled || false;

  const renderLeadingComponent = () =>
    props.leadingComponent ? props.leadingComponent : null;

  return (
    <div className="field is-horizontal is-size-l">
      <div className="field-label web-presence-label">
        {renderLeadingComponent()}
      </div>

      <div className="web-presence-body">
        <input
          aria-label={props.name.replace('_', ' ')}
          className={className}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          required={props.required}
          disabled={isDisabled}
        />
        <ValidationMessage validationResult={props.validationResult} />
      </div>
    </div>
  );
};
