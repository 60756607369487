import React from 'react';
import { Box, Calendar, Text } from '@forward-financing/fast-forward';
import { HolidayDay } from '../paymentSchedule.types';

export interface HolidayCalendarDayProps {
  event: HolidayDay;
}

export const HolidayCalendarDay = ({
  event,
}: HolidayCalendarDayProps): JSX.Element => (
  <Calendar.Day bg="purple-300">
    <Box textAlign="center" p={1}>
      <Text>{event.holiday}</Text>
    </Box>
  </Calendar.Day>
);
