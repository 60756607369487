import React from 'react';
import {
  Box,
  Subheading,
  List,
  Link,
  Text,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const IsoSalesRepPopover = (): JSX.Element => {
  return (
    <Popover
      header="ISO Sales Rep Email"
      position="right"
      content={
        <Box>
          <Subheading variant="subsection">
            Register correct Sales Rep who will receive updates on the stage of
            the deal
          </Subheading>
          <List>
            <List.Item>
              <Text bold>
                Clara Capital, Morgan Cash, SBG Funding, United Capital Source
              </Text>
              <Box paddingLeft={2}>
                <Text>
                  Use the Business Checking Account as the Account Number.
                </Text>
              </Box>
            </List.Item>
            <List.Item>
              <Text bold>Empower Group</Text>
              <Box paddingLeft={2}>
                <Text>Locate the Empower Group Partner Sales Rep Email:</Text>
                <List>
                  <List.Item>
                    Go to{' '}
                    <Link
                      href={new URL('https://groups.google.com/my-groups')}
                      newTab
                    >
                      Google Groups
                    </Link>{' '}
                    and choose Prequal Submission group
                    (prequal-submissions@forwardfinancing.com).
                  </List.Item>
                  <List.Item>
                    Locate the name of the submission in JIRA, copy and paste it
                    in the Search Box.
                  </List.Item>
                  <List.Item>
                    In the search results, select the corresponding submission
                    and look for the Executive Sales Agent email.
                  </List.Item>
                  <List.Item>
                    Add the Executive Sales Agent email as the Partner Sales Rep
                    Email. If Executive Sales Agent email is not listed, use
                    Submitter Rep email instead.
                  </List.Item>
                </List>
                <Text italic>
                  Submitter Rep: Agent from Empower Group from whom we received
                  the submission.
                </Text>
                <Text italic>
                  Executive Sales Agent: Agent from Empower Group that the
                  submission was sent to or the agent that was CC-ed on the
                  submission.
                </Text>
              </Box>
            </List.Item>
            <List.Item>
              <Text bold>Lending Tree</Text>
              <Box paddingLeft={2}>
                <Text>
                  Sales Rep is usually listed in the email content. If it&apos;s
                  not there, you don&apos;t have to fill in this field.
                </Text>
              </Box>
            </List.Item>
            <List.Item>
              <Text bold>Business Capital</Text>
              <Box paddingLeft={2}>
                <Link
                  href={
                    new URL(
                      'https://drive.google.com/file/d/1IkqNDaUO8LAZgjSRTOoHcqM2Jb9dbjJa/view'
                    )
                  }
                  newTab
                >
                  NY, FL and CA locations
                </Link>
              </Box>
            </List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="ISO Sales Rep Email more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
