import { patchApplication } from 'api/underwriting/applicationFetchUtils';
import {
  UseGenericMutationResponse,
  useGenericMutation,
} from 'apiHooks/genericFetchHooks';
import { UpdateApplicationRequestBody } from 'types/api/underwriting/types';

export interface UseUpdateApplicationArgs {
  applicationUuid: string;
  updateBody: UpdateApplicationRequestBody;
}

export type UseUpdateApplicationResponse = UseGenericMutationResponse<
  boolean,
  UseUpdateApplicationArgs
>;

const updateApplication = (input: UseUpdateApplicationArgs): Promise<boolean> =>
  patchApplication(input.applicationUuid, input.updateBody);

export const useUpdateApiApplication = (): UseUpdateApplicationResponse => {
  return useGenericMutation(updateApplication);
};
