import React from 'react';
import { Card, Table, Text } from '@forward-financing/fast-forward';

import { BankruptcyRecord } from 'components/SubmissionUnderwriting/OwnerOverview/types';
import {
  formatDateString,
  mdyFormat,
  ymdFormat,
} from 'helpers/string/dateUtils';

export interface BankruptcyTableProps {
  bankruptcies: BankruptcyRecord[];
}

export const BankruptcyTable = ({
  bankruptcies,
}: BankruptcyTableProps): JSX.Element => (
  <Card title="Bankruptcy">
    {bankruptcies.length === 0 ? (
      <Text>No Records Found</Text>
    ) : (
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Type</Table.ColumnHeader>

          <Table.ColumnHeader>Chapter</Table.ColumnHeader>

          <Table.ColumnHeader>Filing Date</Table.ColumnHeader>

          <Table.ColumnHeader>Exit Date</Table.ColumnHeader>

          <Table.ColumnHeader>Filed As</Table.ColumnHeader>

          <Table.ColumnHeader>Status</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {bankruptcies.map((bankruptcy) => (
            <Table.Row key={`${bankruptcy.filingDate} ${bankruptcy.filedAs}`}>
              <Table.Cell columnWidth="small">
                <Text>Bankruptcy</Text>
              </Table.Cell>

              <Table.Cell columnWidth="small">{bankruptcy.chapter}</Table.Cell>

              <Table.Cell columnWidth="small">
                {bankruptcy.filingDate &&
                  formatDateString(bankruptcy.filingDate, ymdFormat, mdyFormat)}
              </Table.Cell>

              <Table.Cell columnWidth="small">
                {bankruptcy.exitDate &&
                  formatDateString(bankruptcy.exitDate, ymdFormat, mdyFormat)}
              </Table.Cell>

              <Table.Cell columnWidth="small">
                {bankruptcy.filedAs.join(', ')}
              </Table.Cell>

              <Table.Cell columnWidth="small">
                {bankruptcy.statuses
                  ?.map(
                    (item) =>
                      item.date &&
                      item.type &&
                      `${formatDateString(item.date, ymdFormat, mdyFormat)} ${
                        item.type
                      }`
                  )
                  .join(', ')}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )}
  </Card>
);
