import React from 'react';

import { Currency, Table, Text } from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { displayPercentage } from '../../../helpers/utils';
import { CashFlowData } from '../renewalComparison.types';
import { ChangeComparison } from './ChangeComparison';

const UNABLE_TO_FETCH = <Text italic>Unable to fetch</Text>;

const renderPercentage = (potentialNumber?: number): string | JSX.Element => {
  if (typeof potentialNumber === 'number') {
    return displayPercentage(potentialNumber);
  }

  return defaultTo(potentialNumber, UNABLE_TO_FETCH);
};

const renderCurrency = (potentialNumber?: number): string | JSX.Element => {
  if (typeof potentialNumber === 'number') {
    return <Currency amount={potentialNumber} />;
  }

  return defaultTo(potentialNumber, UNABLE_TO_FETCH);
};

type RevenueOverrideProps = {
  currentValue: number;
  previousValue?: number | null;
};

const RevenueOverride = ({
  currentValue,
  previousValue,
}: RevenueOverrideProps): JSX.Element => {
  if (typeof previousValue === 'number') {
    return (
      <ChangeComparison
        currentValue={currentValue}
        previousValue={previousValue}
      />
    );
  }
  return <Currency amount={currentValue} />;
};

export type CashFlowSectionProps = {
  current?: CashFlowData;
  prior?: CashFlowData;
};

export const CashFlowTable = ({
  current,
  prior,
}: CashFlowSectionProps): JSX.Element => {
  return (
    <>
      {current && (
        <Table caption="Cash Flow Table">
          <Table.Head>
            <Table.ColumnHeader>Cash Flow</Table.ColumnHeader>
            <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
            <Table.ColumnHeader>Prior Advance</Table.ColumnHeader>
          </Table.Head>

          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Revenue Override</Table.RowHeader>

              <Table.Cell>
                {typeof current.revenueOverride === 'number' ? (
                  <RevenueOverride
                    currentValue={current.revenueOverride}
                    previousValue={prior?.revenueOverride}
                  />
                ) : (
                  UNABLE_TO_FETCH
                )}
              </Table.Cell>

              <Table.Cell>{renderCurrency(prior?.revenueOverride)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Average Negative Days</Table.RowHeader>

              <Table.Cell>
                {defaultTo(current.averageNegativeDays, UNABLE_TO_FETCH)}
              </Table.Cell>

              <Table.Cell>
                {defaultTo(prior?.averageNegativeDays, UNABLE_TO_FETCH)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>ADB</Table.RowHeader>

              <Table.Cell>
                {renderCurrency(current.averageDailyBalance)}
              </Table.Cell>

              <Table.Cell>
                {renderCurrency(prior?.averageDailyBalance)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader># Current Positions</Table.RowHeader>

              <Table.Cell>
                {defaultTo(current.numberOfPositions, UNABLE_TO_FETCH)}
              </Table.Cell>

              <Table.Cell>
                {defaultTo(prior?.numberOfPositions, UNABLE_TO_FETCH)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Gross % of Current Positions</Table.RowHeader>

              <Table.Cell>
                {renderPercentage(current.grossOfPositions)}
              </Table.Cell>

              <Table.Cell>
                {renderPercentage(prior?.grossOfPositions)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>FF Gross %</Table.RowHeader>

              <Table.Cell>
                {renderPercentage(current.ffPercentOfGross)}
              </Table.Cell>

              <Table.Cell>
                {renderPercentage(prior?.ffPercentOfGross)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Total Gross %</Table.RowHeader>

              <Table.Cell>
                {renderPercentage(current.totalGrossPercent)}
              </Table.Cell>

              <Table.Cell>
                {renderPercentage(prior?.totalGrossPercent)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
