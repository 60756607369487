/**
 * I'm assuming that this function was written before we
 * started using TypeScript. I'm not confident that all
 * usages of this are correctly passing a boolean value
 * to this function (even though the type system says
 * they are), so I've opted to leave the "else" as is for
 * now.
 *
 * @tyrelosaur - July 24, 2022
 */
export function formatBoolean(value?: boolean | null): 'Yes' | 'No' | 'N/A' {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return 'N/A';
}
