import React, { useEffect, useMemo } from 'react';
import {
  Banner,
  Flex,
  formatCurrency,
  Loading,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';

import { useGetApiFeatureFlagWithIdentifier } from 'apiHooks/underwriting/featureFlagFetchHooks';
import {
  useLedgerBankingSuggestedPricing,
  useLedgerUnderwritingSuggestedPricing,
} from './ledgerHooks';
import { Ledger, LedgerBankingPricingSuggestions } from './ledger.types';
import { useLedgersValidationContext } from './LedgerValidationContext/LedgerValidationContext';

export type SuggestedPricingContainerProps = {
  submissionUuid: string;
  customerUuid?: string;
  ledger: Ledger;
};

const BankingSuggestedPricing = ({
  ledgerId,
}: {
  ledgerId: number;
}): JSX.Element | null => {
  const { data, loading, error } = useLedgerBankingSuggestedPricing(ledgerId);
  const { setSuggestedProgram } = useLedgersValidationContext();

  useEffect(() => {
    if (data?.programType) {
      setSuggestedProgram(data.programType);
    }
  }, [data?.programType, setSuggestedProgram]);

  const renderDeclineMessage = useMemo(() => {
    return !!data?.declineMessage;
  }, [data?.declineMessage]);

  const getGrossBasisText = (
    suggestion: LedgerBankingPricingSuggestions
  ): string =>
    `${suggestion.grossBasis.amount}${
      suggestion.grossBasis.unit
    } / ${formatCurrency(parseInt(suggestion.gross.displayedAmount, 10))}`;

  return (
    <>
      {loading && <Loading />}
      {error && <Banner>{error.message}</Banner>}
      {data && renderDeclineMessage && (
        <Banner variant="caution">{data.declineMessage}</Banner>
      )}
      {data && !renderDeclineMessage && (
        <Flex
          justifyContent="flex-start"
          gap={3}
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          mb={3}
        >
          <Text bold>System Suggested Pricing: </Text>
          <Flex gap={1}>
            <Text bold>UW Score: </Text>
            <Text>{data.underwritingAverageScore?.toFixed(1)}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Industry Risk: </Text>
            <Text>{data.industryRisk}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Program: </Text>
            <Text>{data.programType}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Estimated Term: </Text>
            <Text>
              {data.estimatedTerm === 1
                ? `${data.estimatedTerm} month`
                : `${data.estimatedTerm} months`}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Gross: </Text>
            <Text>{getGrossBasisText(data)}</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const UnderwritingSuggestedPricing = ({
  submissionUuid,
  ledger,
}: Omit<
  SuggestedPricingContainerProps,
  'customerUuid'
>): JSX.Element | null => {
  const { data, error, loading } = useLedgerUnderwritingSuggestedPricing(
    submissionUuid,
    {
      sourceKey: ledger.id.toString(),
      // Hardcode the sourceType to Underwriting::Ledger for now
      sourceType: 'Underwriting::Ledger',
      revenue: defaultTo(ledger.revenue, ''),
      financingsCount: defaultTo(ledger.financingsCount?.toString(), ''),
      financingsGross: defaultTo(ledger.financingsGross, ''),
    }
  );
  const { setSuggestedProgram } = useLedgersValidationContext();

  useEffect(() => {
    if (data?.programType) {
      setSuggestedProgram(data.programType);
    }
  }, [data?.programType, setSuggestedProgram]);

  return (
    <>
      {loading && <Loading />}
      {error && <Banner>{error.message}</Banner>}
      {data && (
        <Flex
          justifyContent="flex-start"
          gap={3}
          alignItems="center"
          flexWrap="wrap"
          mb={3}
        >
          <Text bold>System Suggested Pricing: </Text>
          <Flex gap={1}>
            <Text bold>Risk Score: </Text>
            <Text>{data.inputs.riskScore}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Industry Risk: </Text>
            <Text>{data.inputs.industryRisk}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Program: </Text>
            <Text>{data.programType}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Pre-Forward Gross: </Text>
            <Text>{data.inputs.financingsGross}%</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Recommended Term: </Text>
            <Text>
              {data.maxTerm === 1
                ? `${data.maxTerm} month`
                : `${data.maxTerm} months`}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Recommended Advance Amount: </Text>
            <Text>{formatCurrency(data.roundedAmount)}</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export const SuggestedPricingContainer = ({
  submissionUuid,
  customerUuid,
  ledger,
}: SuggestedPricingContainerProps): JSX.Element => {
  const pricingSuggestionsAbTestFlagEnabled =
    useGetApiFeatureFlagWithIdentifier(
      'ua_ba_pricing_suggestion_a_b_test',
      customerUuid
    );

  return (
    <>
      {!pricingSuggestionsAbTestFlagEnabled && (
        <BankingSuggestedPricing ledgerId={ledger.id} />
      )}
      {pricingSuggestionsAbTestFlagEnabled && (
        <UnderwritingSuggestedPricing
          submissionUuid={submissionUuid}
          ledger={ledger}
        />
      )}
    </>
  );
};
