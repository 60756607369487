import React from 'react';
import {
  Banner,
  Box,
  Flex,
  Subheading,
  RadioGrid,
  RadioGridProps,
  Icon,
} from '@forward-financing/fast-forward';
import { UpdateManagerScoreBody } from '../../DealScoringFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';

export interface ManagerScoringNewProps {
  score: string | undefined;
  loading: boolean;
  updateScore: (input: UpdateManagerScoreBody) => Promise<MutationResponse>;
  errors: Error[];
}

export const ManagerScoringNew = ({
  score,
  loading,
  updateScore,
  errors,
}: ManagerScoringNewProps): JSX.Element => {
  const questions: RadioGridProps['questions'] = [
    {
      name: 'managerFeedbackScore',
      value: score,
      label: 'Manager Score',
    },
  ];

  const handleRadioGridOnChange: RadioGridProps['onValueChange'] = (
    _name,
    newValue
  ) => {
    void updateScore({
      managerFeedbackScore: newValue === 'true',
    });
  };

  return (
    <Box>
      <Flex alignItems="center" gap={2}>
        <Subheading>Manager Score</Subheading>
        {loading && <Icon name="spinner" />}
        {!loading && score && <Icon name="check" />}
      </Flex>

      {errors.map((error) => (
        <Banner key={error.message} dismissable={false}>
          {error.message}
        </Banner>
      ))}

      <Box paddingTop={2}>
        <RadioGrid
          options={[
            { value: 'true', text: 'Agree' },
            { value: 'false', text: 'Disagree' },
          ]}
          questions={questions}
          onValueChange={handleRadioGridOnChange}
        />
      </Box>
    </Box>
  );
};
