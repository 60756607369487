import { defaultTo } from 'lodash';
import {
  useGetApiPreviousFundedSubmissions,
  useGetApiRenewalComparison,
  useGetSubmissionCompositeScore,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  CashFlowInfo,
  FundingInfo,
  OwnerRiskInfo,
  RenewalComparisonResponse,
  SubmissionCompositeScoreResponse,
  SubmissionResponse,
  SummaryInfo,
} from 'types/api/underwriting/types';
import {
  useApiMultipleRenewalComparisons,
  useApiRenewalComparisons,
} from 'apiHooks/funding/renewalComparisonsFetchHooks';
import { RenewalComparisonsResponse } from 'types/api/funding/types';
import {
  AcceptedOfferResponse,
  CreditDecisionResponse,
  DeclineCreditDecisionResponse,
} from 'types/api/banking/types';
import { useApiCreditDecisions } from 'apiHooks/banking/creditDecisionFetchHooks';
import { useApiAcceptedOffers } from 'apiHooks/banking/acceptedOfferFetchHooks';
import {
  AcceptedOffer,
  CashFlowData,
  CreditDecision,
  FundingData,
  OwnerRiskData,
  PreviousFundedSubmission,
  RenewalComparisons,
  RenewalComparisonSubmission,
  SubmissionCompositeScoreTier,
  SummaryData,
} from './renewalComparison.types';

const toFundingData = (data?: FundingInfo): FundingData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    amount: defaultTo(data.amount, undefined),
    targetTerm: defaultTo(data.target_term, undefined),
    balance: defaultTo(data.balance, undefined),
    minimumToApprove: defaultTo(data.minimum_to_approve, undefined),
    isAddOn: defaultTo(data.is_add_on, undefined),
    netFundedAmount: defaultTo(data.net_funded_amount, undefined),
    missedPaymentCount: defaultTo(data.missed_payment_count, undefined),
    reducedPaymentCount: defaultTo(data.reduced_payment_count, undefined),
    processingFeeAmount: defaultTo(data.processing_fee_amount, undefined),
    contractualPaybackFrequency: defaultTo(
      data.contractual_payback_frequency,
      undefined
    ),
    recordId: defaultTo(data.record_id, undefined),
    commission: defaultTo(data.commission, undefined),
    lastPaymentDate: defaultTo(data.last_payment_date, undefined),
    thirtyDayDollarPace: defaultTo(data.thirty_day_dollar_pace, undefined),
    sixtyDayDollarPace: defaultTo(data.sixty_day_dollar_pace, undefined),
    overallDollarPace: defaultTo(data.overall_dollar_pace, undefined),
    overallPaymentCountPace: defaultTo(
      data.overall_payment_count_pace,
      undefined
    ),
  };
};

const toSummaryData = (data?: SummaryInfo): SummaryData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    businessName: data.business_name,
    underwriterName: defaultTo(data.underwriter_name, undefined),
    stage: defaultTo(data?.stage, ''),
    subStage: defaultTo(data.sub_stage, undefined),
  };
};

const toCashFlowData = (data?: CashFlowInfo): CashFlowData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    revenueOverride: defaultTo(data.revenue_override, undefined),
    averageNegativeDays: defaultTo(data.average_negative_days, undefined),
    averageDailyBalance: defaultTo(data.average_daily_balance, undefined),
    numberOfPositions: defaultTo(data.number_of_positions, undefined),
    grossOfPositions: defaultTo(data.gross_of_positions, undefined),
    ffPercentOfGross: defaultTo(data.ff_percent_of_gross, undefined),
    totalGrossPercent: defaultTo(data.total_gross_percent, undefined),
  };
};

const toOwnerRiskData = (data?: OwnerRiskInfo): OwnerRiskData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    ficoScore: defaultTo(data.fico_score, undefined),
    bankruptcyPlus: defaultTo(data.bankruptcy_plus, undefined),
    vantageScore: defaultTo(data.vantage_score, undefined),
    totalDelinquentTradeLines: defaultTo(
      data.total_delinquent_trade_lines,
      undefined
    ),
    debtToHighCredit: defaultTo(data.debt_to_high_credit, undefined),
  };
};

const toRenewalComparisons = (
  data: RenewalComparisonResponse
): RenewalComparisons => {
  return {
    current: {
      fundingData: toFundingData(data.current.funding_data),
      summaryData: toSummaryData(data.current.summary_data),
      cashFlowData: toCashFlowData(data.current.cash_flow_data),
      ownerRiskData: toOwnerRiskData(data.current.owner_risk_data),
    },
    previousOne: data.previous_1
      ? {
          fundingData: toFundingData(data.previous_1.funding_data),
          summaryData: toSummaryData(data.previous_1.summary_data),
          cashFlowData: toCashFlowData(data.previous_1.cash_flow_data),
          ownerRiskData: toOwnerRiskData(data.previous_1.owner_risk_data),
        }
      : undefined,
    previousTwo: data.previous_2
      ? {
          fundingData: toFundingData(data.previous_2.funding_data),
          summaryData: toSummaryData(data.previous_2.summary_data),
        }
      : undefined,
  };
};

export const useRenewalComparisons = (
  submissionUuid?: string,
  sections?: string[]
): UseGenericQueryResponse<RenewalComparisons> => {
  return useGenericFeatureQuery(
    useGetApiRenewalComparison,
    (data) => data && toRenewalComparisons(data),
    submissionUuid,
    sections
  );
};

const toPreviousFundedSubmissions = (
  data?: SubmissionResponse[]
): PreviousFundedSubmission[] => {
  if (!data) {
    return [];
  }

  return data.map((submission) => ({
    submissionUuid: submission.uuid,
    underwriterName: defaultTo(submission.x2dc_underwriter__c, ''),
  }));
};

export const usePreviousFundedSubmissions = (
  submissionUuid?: string
): UseGenericQueryResponse<PreviousFundedSubmission[]> => {
  return useGenericFeatureQuery(
    useGetApiPreviousFundedSubmissions,
    (data) => toPreviousFundedSubmissions(data),
    submissionUuid
  );
};

const toRenewalComparisonSubmission = (
  data: RenewalComparisonsResponse
): RenewalComparisonSubmission => {
  const { advance } = data;

  return {
    advanceNumber: defaultTo(advance.advance_number, ''),
    amount: defaultTo(Number(advance.amount), 0),
    balance: defaultTo(advance.balance, ''),
    commission: defaultTo(advance.commission, ''),
    contractualPaybackFrequency: defaultTo(
      advance.contractual_payback_frequency,
      ''
    ),
    dailyPayment: defaultTo(advance.daily_payment, 0),
    dateOfAdvance: defaultTo(advance.date_of_advance, ''),
    isAddOn: defaultTo(advance.is_add_on, false),
    lastPaymentDate: defaultTo(advance.last_payment_date, ''),
    missedPaymentCount: defaultTo(advance.missed_payment_count, 0),
    mostRecentPaybackStatus: defaultTo(advance.most_recent_payback_status, ''),
    netFundedAmount: defaultTo(advance.net_funded_amount, ''),
    overallDollarPace: defaultTo(advance.overall_dollar_pace, undefined),
    overallPaymentCountPace: defaultTo(
      advance?.overall_payment_count_pace,
      undefined
    ),
    percentageRepaid: defaultTo(advance.percentage_repaid, ''),
    recordId: defaultTo(advance.record_id, 0),
    reducedPaymentCount: defaultTo(advance.reduced_payment_count, 0),
    sixtyDayDollarPace: defaultTo(advance.sixty_day_dollar_pace, undefined),
    targetTerm: defaultTo(advance.target_term, 0),
    thirtyDayDollarPace: defaultTo(advance.thirty_day_dollar_pace, undefined),
  };
};

export const useRenewalComparisonSubmission = (
  submissionUuid?: string
): UseGenericQueryResponse<RenewalComparisonSubmission> => {
  return useGenericFeatureQuery(
    useApiRenewalComparisons,
    (data) => data && toRenewalComparisonSubmission(data),
    submissionUuid
  );
};

export const useMultipleRenewalComparisonSubmission = (
  submissionUuid?: string[]
): UseGenericQueryResponse<RenewalComparisonSubmission[]> => {
  return useGenericFeatureQuery(
    useApiMultipleRenewalComparisons,
    (data) => data && data.map(toRenewalComparisonSubmission),
    submissionUuid
  );
};

const toCompositeScore = (
  data: SubmissionCompositeScoreResponse
): SubmissionCompositeScoreTier => {
  return {
    tier: data?.composite_score?.tier,
    compositeScore: defaultTo(data?.composite_score?.score, 0),
  };
};

export const useSubmissionCompositeScore = (
  submissionUuid?: string
): UseGenericQueryResponse<SubmissionCompositeScoreTier> => {
  return useGenericFeatureQuery(
    useGetSubmissionCompositeScore,
    (data) => data && toCompositeScore(data),
    submissionUuid
  );
};

const toAcceptedOffer = (response: AcceptedOfferResponse): AcceptedOffer => {
  return {
    id: response.data.id,
    buyRate: defaultTo(response.data.attributes.buy_rate, 0),
    factorRate: defaultTo(response.data.attributes.factor_rate, 0),
    program: defaultTo(response.data.attributes.program, ''),
  };
};

export const useAcceptedOffers = (
  submissionUuids?: string[]
): UseGenericQueryResponse<AcceptedOffer[]> => {
  return useGenericFeatureQuery(
    useApiAcceptedOffers,
    (data) => data && data.map(toAcceptedOffer),
    submissionUuids
  );
};

const isCreditDecisionResponse = (
  response: CreditDecisionResponse | DeclineCreditDecisionResponse
): response is CreditDecisionResponse => {
  // DeclineCreditDecisionResponse doesn't have a max_approval_amount
  // so we can use that to differentiate between the two types.
  // Needed to use the as operator to get the type guard to work
  // otherwise TS complained that max_approval_amount was possibly undefined
  // ...which is the point of the type guard.
  return (
    (response as CreditDecisionResponse)?.data?.attributes
      ?.max_approval_amount !== undefined
  );
};

const toCreditDecision = (
  response: CreditDecisionResponse | DeclineCreditDecisionResponse
): CreditDecision => {
  if (isCreditDecisionResponse(response)) {
    return {
      maxApprovalAmount: response.data.attributes.max_approval_amount,
    };
  }

  return {
    maxApprovalAmount: undefined,
  };
};

export const useSubmissionCreditDecision = (
  submissionUuid?: string
): UseGenericQueryResponse<CreditDecision> => {
  return useGenericFeatureQuery(
    useApiCreditDecisions,
    (data) => data && toCreditDecision(data),
    submissionUuid
  );
};
