/* istanbul ignore file */
/**
 * We've decided not to test this file.
 *
 * Please see https://github.com/ForwardFinancing/internal-frontend/pull/573#issuecomment-1148041693
 * and following comments for the rationale
 */
import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes as RouterRoutes,
  useLocation,
} from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import {
  UNDERWRITERS_KEY,
  SALES_KEY,
} from './ExceptionsRequest/exceptionRequestConstants';
import { ExceptionsRequestContainer as ExceptionsRequestRequestContainer } from './ExceptionsRequest/request/ExceptionsRequestContainer';
import { ExceptionsRequestContainer as ExceptionsRequestDecisionContainer } from './ExceptionsRequest/decision/ExceptionsRequestContainer';
import { AdvancePrepaymentContainer } from './AdvancePrepayment/AdvancePrepaymentContainer';
import { AdvancePrepaymentSalesforceContainer } from './AdvancePrepayment/AdvancePrepaymentSalesforceContainer';
import { SubmissionSearch } from './SubmissionSearch/SubmissionSearch';
import { PrequalFormContainer } from './PrequalForm/PrequalFormContainer';
import { SubmissionsContainer } from './Submissions/SubmissionsContainer';
import { BusinessCreditContainer } from './BusinessCredit/BusinessCreditContainer';
import { RenewalComparisonCoreContainer as RenewalComparisonCoreContainerOld } from './RenewalComparison/RenewalComparisonCoreContainer';
import { RenewalComparisonSalesforceContainer } from './RenewalComparison/RenewalComparisonSalesforceContainer';
import { LivePipelineContainer } from './LivePipeline/LivePipelineContainer';
import { LiveUnderwritingPipelineContainer } from './LivePipeline/LiveUnderwritingPipelineContainer';
import { LivePipelinePageMoved } from './LivePipeline/LivePipelinePageMoved';
import { PaymentHistoryContainer } from './PaymentHistory/PaymentHistoryContainer';
import { NotFound } from './RouteAccess/NotFound';
import { AdminPanelContainer } from './AdminPanel/AdminPanelContainer';
import { Navbar } from './Navbar/Navbar';
import { AuthorizedRoute } from './RouteAccess/AuthorizedRoute';
import { canAccessPaymentSchedule } from './RouteAccess/authorizationHelpers';
import { PaymentSchedulePage } from './PaymentSchedule/PaymentSchedulePage';
import { SubmissionUnderwritingContainer } from './SubmissionUnderwriting/SubmissionUnderwritingContainer';
import { ApplicationSnapshotRouteContainer } from './SubmissionUnderwriting/ApplicationSnapshotRouteContainer';
import { PhaseTwoRouteContainer } from './SubmissionUnderwriting/PhaseTwoRouteContainer';
import { PaymentScheduleIframeable } from './PaymentSchedule/PaymentScheduleIframeable';
import { OverviewSectionsRouteContainer } from './SubmissionUnderwriting/OverviewSectionsRouteContainer';
import { NewAttachmentRouteContainer } from './AttachmentManager/UnderwritingAttachmentManager/UnderwritingAttachmentRouteContainer';
import { PrequalAttachmentManagerRouteContainer } from './AttachmentManager/PrequalAttachmentManager/PrequalAttachmentManagerRouteContainer';
import { UnderwritingAttachmentManagerSalesforceContainer } from './AttachmentManager/UnderwritingAttachmentManager/UnderwritingAttachmentManagerSalesforceContainer';
import { UnderwritingAttachmentManagerRouteContainer } from './AttachmentManager/UnderwritingAttachmentManager/UnderwritingAttachmentManagerRouteContainer';
import { AdminPaymentContainer } from './AdminPayment/AdminPaymentContainer';
import { CreditInquiriesContainer } from './SubmissionUnderwriting/CreditInquiriesContainer';
import { MatchedRecordsRouteContainer } from './SubmissionUnderwriting/MatchedRecordsRouteContainer';
import { NewBulkAdjustments } from './BulkAdjustments/NewBulkAdjustments';
import { SubmissionNavbar } from './Navbar/SubmissionNavbar/SubmissionNavbar';
import { UnderwriterQueueLoading } from './UnderwriterQueue/UnderwriterQueueLoading';
import { PrequalQueueLoading } from './PrequalQueue/PrequalQueueLoading';
import { PublicRecordsPageContainer } from './SubmissionUnderwriting/OwnerOverview/PublicRecords/PublicRecordsPageContainer';
import { CriminalFilingsPageContainer } from './SubmissionUnderwriting/OwnerOverview/CriminalFilingsPageContainer';
import { AchReturnsContainer } from './AchReturns/AchReturnsContainer';
import { SubmissionEditingContainer } from './SubmissionEditing/SubmissionEditingContainer';
import { SubmissionOnboardingQueueContainer } from './SubmissionOnboardingQueue/SubmissionOnboardingQueueContainer';
import { SubmissionOnboardingApplicationDetailContainer } from './SubmissionOnboardingQueue/SubmissionOnboardingApplicationDetailContainer';
import { BankingStipulationManagerRouteContainer } from './BankingStipulationManager/BankingStipulationManagerRouteContainer';
import { BusinessLexisNexisPageContainer } from './SubmissionUnderwriting/BusinessLexisNexisPage/BusinessLexisNexisPage';
import { ProcessingQueueLoading } from './ProcessingQueue/ProcessingQueueLoading';
import { OwnerManualSearchPage } from './SubmissionUnderwriting/OwnerManualSearch/OwnerManualSearchPage';
import { BusinessManualSearchPage } from './SubmissionUnderwriting/BusinessManualSearch/BusinessManualSearchPage';
import { OwnerLNOverviewPageContainer } from './SubmissionUnderwriting/OwnerLNOverviewPage/OwnerLNOverviewPageContainer';
import { OwnerLNReportPageContainer } from './SubmissionUnderwriting/OwnerLNReportIdPage/OwnerLNReportPageContainer';
import { UccReportPage } from './SubmissionUnderwriting/OwnerOverview/UccReport/UccReportPage';
import { ExperianConsumerArchiveContainer } from './SubmissionUnderwriting/OwnerOverview/ExperianConsumerArchive/ExperianConsumerArchiveContainer';
import { BusinessUccReportPage } from './SubmissionUnderwriting/BusinessOverview/BusinessUccReportPage/BusinessUccReportPage';
import { BusinessPaynetPageContainer } from './SubmissionUnderwriting/BusinessPaynetPage/BusinessPaynetPageContainer';
import { ExperianConsumerDetailContainer } from './SubmissionUnderwriting/OwnerOverview/ExperianConsumerDetail/ExperianConsumerDetailContainer';
import { DataMerchReportPageContainer } from './SubmissionUnderwriting/DataMerchReport/DataMerchReportPageContainer';
import { TradelinesDetailsContainer } from './SubmissionUnderwriting/TradelinesDetailsPage/TradelinesDetailsContainer';
import { RenewalComparisonCoreContainer } from './NewRenewalComparison/RenewalComparisonCoreContainer';
import { SubmissionOnboardingQueueLoading } from './SubmissionOnboardingQueue/SubmissionOnboardingQueueLoading';
import { BusinessPublicRecordsPageContainer } from './SubmissionUnderwriting/BusinessOverview/BusinessPublicRecordsPage/BusinessPublicRecordsPageContainer';
import { BusinessLexisNexisReportContainer } from './SubmissionUnderwriting/BusinessLexisNexisPage/BusinessLexisNexisReportContainer';

const EXCEPTION_REQUEST_SALES_PATH = `opportunities/:sfid/underwriting_exception_requests/${SALES_KEY}`;

const EXCEPTION_REQUEST_UNDERWRITER_PATH = `opportunities/:uuid/underwriting_exception_requests/${UNDERWRITERS_KEY}`;

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const Routes = (): JSX.Element => {
  const query = useQuery();
  const { role } = useUserContext();

  return (
    // NOTE: the className "content" has styles that rely on it.
    <div className={'content route-container'}>
      <RouterRoutes>
        <Route
          path="/"
          element={<Navigate to="dashboard/applications" replace />}
        />
        <Route
          path="banking/submissions/:submissionUuid/stipulations"
          element={<BankingStipulationManagerRouteContainer />}
        />

        <Route
          path="dashboard"
          element={
            <>
              <Navbar />
              <Outlet />
            </>
          }
        >
          <Route path="ach-returns" element={<AchReturnsContainer />} />
          <Route path="submission-onboarding">
            <Route
              path="queue"
              element={<SubmissionOnboardingQueueContainer />}
            />
            <Route
              path="applications/:applicationUuid"
              element={<SubmissionOnboardingApplicationDetailContainer />}
            />
          </Route>
          <Route path="applications" element={<SubmissionSearch />} />
          <Route path="live-pipeline" element={<LivePipelineContainer />} />
          <Route
            path="submission/:submissionUuid/owner-lexis-nexis/:ownerUuid"
            element={<OwnerLNOverviewPageContainer />}
          />
          <Route
            path="submission/:submissionUuid/owner-lexis-nexis/report-id/:reportId"
            element={<OwnerLNReportPageContainer />}
          />
          <Route
            path="submission/:submissionUuid/business-lexis-nexis"
            element={<BusinessLexisNexisPageContainer />}
          />
          <Route
            path="submission/:submissionUuid/business-lexis-nexis/report-id/:reportId"
            element={<BusinessLexisNexisReportContainer />}
          />

          <Route
            path="submission/:submissionUuid/business-paynet"
            element={<BusinessPaynetPageContainer />}
          />

          <Route
            path="submission/:submissionUuid/customers/:customerUuid/ucc_report/:reportId"
            element={<BusinessUccReportPage />}
          />

          <Route
            path="submission/:submissionUuid/data-merch"
            element={<DataMerchReportPageContainer />}
          />

          <Route
            path="live-underwriting-pipeline"
            element={<LiveUnderwritingPipelineContainer />}
          />
          <Route
            path="submissions/:submissionUuid"
            element={<SubmissionUnderwritingContainer />}
          />
          <Route
            path="advances/:advanceRecordId/payment_schedule"
            element={
              <AuthorizedRoute isAuthorized={canAccessPaymentSchedule(role)}>
                <PaymentSchedulePage />
              </AuthorizedRoute>
            }
          />
          <Route
            path="applications/:applicationUuid/attachments"
            element={<PrequalAttachmentManagerRouteContainer />}
          />
          <Route path="underwriting" element={<AdminPanelContainer />} />
          {/* TODO: we are going to modify the existing route with the new attachments work.
            This new route is for dev work */}
          <Route
            path="newAttachments/:submissionUuid"
            element={<NewAttachmentRouteContainer />}
          />
          <Route
            path="submissions/:submissionUuid/owners/:ownerUuid/inquiries"
            element={<CreditInquiriesContainer />}
          />
          <Route
            path="submissions/:submissionUuid/owners/:ownerUuid/tradelines"
            element={<TradelinesDetailsContainer />}
          />
          <Route
            path="owners/:ownerUuid/experian_consumer_archive"
            element={<ExperianConsumerArchiveContainer />}
          />
          <Route
            path="owners/:ownerUuid/experian_consumer_detail/:reportUuid"
            element={<ExperianConsumerArchiveContainer />}
          />
          <Route
            path="submissions/:submissionUuid/owners/:ownerUuid/public_records"
            element={<PublicRecordsPageContainer />}
          />
          <Route
            path="submissions/:submissionUuid/owners/:ownerUuid/criminal_filings"
            element={<CriminalFilingsPageContainer />}
          />
          <Route
            path="submissions/:submissionUuid/business-overview/:documentUuid/public_records"
            element={<BusinessPublicRecordsPageContainer />}
          />
          <Route
            path="advances/bulk_adjustments/new"
            element={<NewBulkAdjustments />}
          />
          <Route
            path=":submissionUuid/owner_report_search/:ownerUuid"
            element={<OwnerManualSearchPage />}
          />
          <Route
            path=":submissionUuid/business_report_search"
            element={<BusinessManualSearchPage />}
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="submissions/:submissionUuid/owners/:ownerUuid/customers/:customerUuid/ucc_report/:reportId"
            element={<UccReportPage />}
          />
          <Route
            path="consumer-credit-report/:referenceUuid"
            element={<ExperianConsumerDetailContainer />}
          />
        </Route>
        <Route
          path="submissions"
          element={
            <>
              <SubmissionNavbar />
              <Outlet />
            </>
          }
        >
          <Route
            path=":submissionUuid/"
            element={<SubmissionUnderwritingContainer />}
          />
          <Route
            path=":submissionUuid/editing"
            element={<SubmissionEditingContainer />}
          />
        </Route>
        <Route path="admin">
          {/* TODO: this route is for dev work and should be removed when the work is complete */}
          <Route path="payments" element={<AdminPaymentContainer />} />

          <Route path="submissions/:submissionUuid">
            <Route
              path="application_snapshot"
              element={<ApplicationSnapshotRouteContainer />}
            />
            <Route
              path="attachments"
              element={<UnderwritingAttachmentManagerRouteContainer />}
            />
            {/* 
              The phase naming convention used for the routes below is intended to
              reflect different arrangements of the Application React view as we complete
              different phases of Project Phoenix. These routes will have some overlap
              such that we can use feature flags to toggle specific pieces of the UI 
              without reverting the entire thing back to the Rails view.
              
              Phase Two includes all of Application Snapshot, Fraud Scores, Email Content,
              Risk Factors, and Attachment Manager
            */}
            <Route path="phase_two" element={<PhaseTwoRouteContainer />} />
            <Route
              path="overview_sections"
              element={<OverviewSectionsRouteContainer />}
            />
            <Route
              path="matched_records"
              element={<MatchedRecordsRouteContainer />}
            />
          </Route>

          <Route
            path="applications/:applicationRecordId/renewal-comparison/:opportunityUuid"
            element={<RenewalComparisonCoreContainerOld />}
          />
          <Route
            path="submissions/:submissionUuid/renewal-comparison"
            element={<RenewalComparisonCoreContainer />}
          />
          <Route
            path="advances/:opportunityUuid/prepayment"
            element={<AdvancePrepaymentContainer />}
          />
          <Route
            path="advances/:advanceRecordId/payment_schedule"
            element={
              <AuthorizedRoute isAuthorized={canAccessPaymentSchedule(role)}>
                <PaymentScheduleIframeable />
              </AuthorizedRoute>
            }
          />
          <Route
            path="customer/:accountUuid/payment_history"
            element={<PaymentHistoryContainer />}
          />
          <Route
            path={EXCEPTION_REQUEST_SALES_PATH}
            element={<ExceptionsRequestRequestContainer />}
          />
          <Route
            path={EXCEPTION_REQUEST_UNDERWRITER_PATH}
            element={<ExceptionsRequestDecisionContainer />}
          />
        </Route>
        <Route path="live-pipeline" element={<LivePipelinePageMoved />} />
        <Route
          path="underwriter-queue-loading"
          element={<UnderwriterQueueLoading />}
        />
        <Route path="prequal-queue-loading" element={<PrequalQueueLoading />} />
        <Route
          path="processing-queue-loading"
          element={<ProcessingQueueLoading />}
        />
        <Route
          path="submission-onboarding-queue-loading"
          element={<SubmissionOnboardingQueueLoading />}
        />
        <Route path="prequalification" element={<PrequalFormContainer />} />
        <Route
          path="prequalification/:uuid"
          element={<PrequalFormContainer />}
        />
        <Route
          path="submissions/:uuid/edit"
          element={<SubmissionsContainer />}
        />
        <Route
          path="business-credit/:referenceId"
          element={<BusinessCreditContainer uuidType={query.get('uuidType')} />}
        />
        <Route
          path="renewal-comparison/:submissionSalesforceId"
          element={<RenewalComparisonSalesforceContainer />}
        />
        <Route path="sf">
          <Route
            path="applications/:submissionSfid/attachments"
            element={<UnderwritingAttachmentManagerSalesforceContainer />}
          />
          <Route
            path="submissions/:submissionSalesforceId/prepayment"
            element={<AdvancePrepaymentSalesforceContainer />}
          />
        </Route>
        <Route
          path="*"
          element={
            <>
              <Navbar />
              <NotFound />
            </>
          }
        />
      </RouterRoutes>
    </div>
  );
};
