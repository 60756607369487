import React from 'react';
import { Box, Card, Grid } from '@forward-financing/fast-forward';
import { PricingStrategyGrossTable } from './PricingStrategyGrossTable';

// These entries are currently hard coded data that needs to be displayed
// In the near future these will be transitioned to pull the data from an API
const pricingStrategyGrossStarterData = [
  {
    id: 1,
    revenue: '25K',
    high_risk: '$5,000',
    moderate_risk: '13%',
    low_risk: '14%',
  },
  {
    id: 2,
    revenue: '25K-50K',
    high_risk: '11%',
    moderate_risk: '12%',
    low_risk: '13%',
  },
  {
    id: 3,
    revenue: '50K-75K',
    high_risk: '10%',
    moderate_risk: '11%',
    low_risk: '12%',
  },
  {
    id: 4,
    revenue: '75K-100K',
    high_risk: '7%',
    moderate_risk: '8%',
    low_risk: '9%',
  },
  {
    id: 5,
    revenue: '100K-150K',
    high_risk: '4%',
    moderate_risk: '5%',
    low_risk: '6%',
  },
  {
    id: 6,
    revenue: '150K+',
    high_risk: '$35,000',
    moderate_risk: '$35,000',
    low_risk: '$35,000',
  },
];

const pricingStrategyGrossCoreData = [
  {
    id: 1,
    revenue: '25K',
    high_risk: '13%',
    moderate_risk: '14%',
    low_risk: '15%',
  },
  {
    id: 2,
    revenue: '25K-50K',
    high_risk: '12%',
    moderate_risk: '13%',
    low_risk: '14%',
  },
  {
    id: 3,
    revenue: '50K-75K',
    high_risk: '11%',
    moderate_risk: '12%',
    low_risk: '13%',
  },
  {
    id: 4,
    revenue: '75K-100K',
    high_risk: '10%',
    moderate_risk: '11%',
    low_risk: '12%',
  },
  {
    id: 5,
    revenue: '100K-150K',
    high_risk: '9%',
    moderate_risk: '10%',
    low_risk: '11%',
  },
  {
    id: 6,
    revenue: '150K-200K',
    high_risk: '8%',
    moderate_risk: '9%',
    low_risk: '10%',
  },
  {
    id: 7,
    revenue: '200K-250K',
    high_risk: '7%',
    moderate_risk: '8%',
    low_risk: '9%',
  },
  {
    id: 8,
    revenue: '250K-300K',
    high_risk: '6%',
    moderate_risk: '7%',
    low_risk: '8%',
  },
  {
    id: 9,
    revenue: '300K+',
    high_risk: '5%',
    moderate_risk: '6%',
    low_risk: '7%',
  },
];

const pricingStrategyGrossStandardData = [
  {
    id: 1,
    revenue: '25K',
    high_risk: '14%',
    moderate_risk: '15%',
    low_risk: '16%',
  },
  {
    id: 2,
    revenue: '25K-50K',
    high_risk: '13%',
    moderate_risk: '14%',
    low_risk: '15%',
  },
  {
    id: 3,
    revenue: '50K-75K',
    high_risk: '12%',
    moderate_risk: '13%',
    low_risk: '14%',
  },
  {
    id: 4,
    revenue: '75K-100K',
    high_risk: '11%',
    moderate_risk: '12%',
    low_risk: '13%',
  },
  {
    id: 5,
    revenue: '100K-150K',
    high_risk: '10%',
    moderate_risk: '11%',
    low_risk: '12%',
  },
  {
    id: 6,
    revenue: '150K-200K',
    high_risk: '9%',
    moderate_risk: '10%',
    low_risk: '11%',
  },
  {
    id: 7,
    revenue: '200K-250K',
    high_risk: '8%',
    moderate_risk: '9%',
    low_risk: '10%',
  },
  {
    id: 8,
    revenue: '250K-300K',
    high_risk: '7%',
    moderate_risk: '8%',
    low_risk: '9%',
  },
  {
    id: 9,
    revenue: '300K+',
    high_risk: '6%',
    moderate_risk: '7%',
    low_risk: '8%',
  },
];

const pricingStrategyGrossLowRiskData = [
  {
    id: 1,
    revenue: '25K',
    high_risk: '15%',
    moderate_risk: '17%',
    low_risk: '18%',
  },
  {
    id: 2,
    revenue: '25K-50K',
    high_risk: '14%',
    moderate_risk: '16%',
    low_risk: '17.5%',
  },
  {
    id: 3,
    revenue: '50K-75K',
    high_risk: '13%',
    moderate_risk: '15%',
    low_risk: '16.5%',
  },
  {
    id: 4,
    revenue: '75K-100K',
    high_risk: '12%',
    moderate_risk: '14%',
    low_risk: '16%',
  },
  {
    id: 5,
    revenue: '100K-150K',
    high_risk: '11%',
    moderate_risk: '12%',
    low_risk: '14.5%',
  },
  {
    id: 6,
    revenue: '150K-200K',
    high_risk: '10%',
    moderate_risk: '11%',
    low_risk: '13.5%',
  },
  {
    id: 7,
    revenue: '200K-250K',
    high_risk: '9%',
    moderate_risk: '10%',
    low_risk: '12.5%',
  },
  {
    id: 8,
    revenue: '250K-300K',
    high_risk: '8%',
    moderate_risk: '9%',
    low_risk: '10%',
  },
  {
    id: 9,
    revenue: '300K+',
    high_risk: '7%',
    moderate_risk: '8%',
    low_risk: '9%',
  },
];

const pricingStrategyGrossPremiumData = [
  {
    id: 1,
    revenue: '25K',
    high_risk: '16%',
    moderate_risk: '17%',
    low_risk: '18%',
  },
  {
    id: 2,
    revenue: '25K-50K',
    high_risk: '15%',
    moderate_risk: '16%',
    low_risk: '17.5%',
  },
  {
    id: 3,
    revenue: '50K-75K',
    high_risk: '14%',
    moderate_risk: '15%',
    low_risk: '17%',
  },
  {
    id: 4,
    revenue: '75K-100K',
    high_risk: '13%',
    moderate_risk: '14%',
    low_risk: '16.5%',
  },
  {
    id: 5,
    revenue: '100K-150K',
    high_risk: '12%',
    moderate_risk: '13%',
    low_risk: '16%',
  },
  {
    id: 6,
    revenue: '150K-200K',
    high_risk: '11%',
    moderate_risk: '12%',
    low_risk: '14.5%',
  },
  {
    id: 7,
    revenue: '200K-250K',
    high_risk: '10%',
    moderate_risk: '11%',
    low_risk: '14%',
  },
  {
    id: 8,
    revenue: '250K-300K',
    high_risk: '9%',
    moderate_risk: '10%',
    low_risk: '11%',
  },
  {
    id: 9,
    revenue: '300K+',
    high_risk: '8%',
    moderate_risk: '9%',
    low_risk: '10%',
  },
];

export const PricingStrategyGross = (): JSX.Element => {
  return (
    <Card title="Pricing Strategy: Gross">
      <Box>
        <Grid>
          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyGrossTable
                title="Starter Program Gross"
                pricingStrategyProfiles={pricingStrategyGrossStarterData}
              />
            </Box>
          </Grid.Item>

          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyGrossTable
                title="Core Program Gross"
                pricingStrategyProfiles={pricingStrategyGrossCoreData}
              />
            </Box>
          </Grid.Item>

          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyGrossTable
                title="Standard Program Gross"
                pricingStrategyProfiles={pricingStrategyGrossStandardData}
              />
            </Box>
          </Grid.Item>

          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyGrossTable
                title="Low Risk Program Gross"
                pricingStrategyProfiles={pricingStrategyGrossLowRiskData}
              />
            </Box>
          </Grid.Item>

          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyGrossTable
                title="Premium Program Gross"
                pricingStrategyProfiles={pricingStrategyGrossPremiumData}
              />
            </Box>
          </Grid.Item>
        </Grid>
      </Box>
    </Card>
  );
};
