import React from 'react';
import { useParams } from 'react-router-dom';
import { MatchedRecordsContainer } from './MatchedRecords/MatchedRecordsContainer';
import { useGetSubmission } from './SubmissionUnderwritingContainerHooks';
import { Banner } from '@forward-financing/fast-forward';

type Params = {
  submissionUuid: string;
};

export const MatchedRecordsRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();
  const { data: submission, error: errorSubmission } =
    useGetSubmission(submissionUuid);

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }

  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false} variant="error">
          {errorSubmission.message}
        </Banner>
      )}
      <MatchedRecordsContainer submission={submission} />
    </>
  );
};
