import React from 'react';
import {
  Box,
  Grid,
  Subheading,
  formatDateString,
} from '@forward-financing/fast-forward';
import { NewDealScore, RenewalScore } from '../RenewalScoring.types';
import { RenewalScoreTable } from './RenewalScoreTable';
import { OriginalAdvanceScoreTable } from './OriginalAdvanceScoreTable';
import { RenewalCompositeScoreTable } from '../RenewalCompositeScoreTable';
import {
  NewDealScoreComposite,
  RenewalScoreComposite,
} from 'components/DealScoring/DealScoring.types';
import { NewDealCompositeScoreTable } from 'components/DealScoring/NewDealScoring/NewDealCompositeScoreTable';

export interface PastAdvancesProps {
  originalDealScore: NewDealScore;
  previousRenewalScores: RenewalScore[];
  previousCompositeScore?: RenewalScoreComposite | NewDealScoreComposite;
  previousFundedDate?: string;
}

// sort the scores by funding date
// from most recent to least recent
const byDealFundingDate = (a: RenewalScore, b: RenewalScore): number =>
  a.dealFundedOn < b.dealFundedOn ? 1 : -1;

export const PastAdvances = ({
  originalDealScore,
  previousRenewalScores,
  previousCompositeScore,
  previousFundedDate,
}: PastAdvancesProps): JSX.Element => {
  const [mostRecentDealScore] = [...previousRenewalScores].sort(
    byDealFundingDate
  );

  return (
    <Box>
      <Grid gutter>
        <Grid.Item xs={12} s={6}>
          <OriginalAdvanceScoreTable score={originalDealScore} />
        </Grid.Item>

        {previousCompositeScore && previousFundedDate && (
          <Grid.Item xs={12} s={6}>
            <Subheading>
              Most Recent Renewal Advance:{' '}
              {formatDateString(previousFundedDate)}
            </Subheading>

            {previousCompositeScore.type === 'New Deal' ? (
              <NewDealCompositeScoreTable scoreData={previousCompositeScore} />
            ) : (
              <RenewalCompositeScoreTable scoreData={previousCompositeScore} />
            )}
          </Grid.Item>
        )}

        {/* Show the non-composite score if no composite score exists */}
        {!previousCompositeScore && mostRecentDealScore && (
          <Grid.Item xs={12} s={6} key={mostRecentDealScore.dealFundedOn}>
            <RenewalScoreTable score={mostRecentDealScore} />
          </Grid.Item>
        )}
      </Grid>
    </Box>
  );
};
