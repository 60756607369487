import { useCallback } from 'react';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { StateListResponse } from 'types/api/underwriting/types';
import { fetchStatesList } from 'api/underwriting/stateFetchUtils';

export const useGetApiStates = (): UseGenericQueryResponse<
  StateListResponse[]
> => {
  const fetcher = useCallback(() => {
    return fetchStatesList();
  }, []);

  return useGenericQuery(fetcher);
};
