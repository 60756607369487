/* eslint-disable promise/prefer-await-to-then */

import React, { useEffect, useState } from 'react';

import { OptionShape } from '@forward-financing/fast-forward/dist/Select/Select';
import { NumberFormatValues } from 'react-number-format';
import {
  Switch,
  TextInput,
  TextInputProps,
  MaskedTextInput,
  Select,
  SelectProps,
  Banner,
  Box,
  Grid,
  DatePicker,
  PlainDate,
  Flex,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';

import {
  Address,
  Contact,
  UsState,
} from '../../../api/UnderwritingClient/codecs';
// Components
import { DATE_FORMAT } from '../../../constants/globals';
import { LogData } from '../../../api/AnalyticsGatewayClient/codecs';
import { GoogleZipCodeLookup } from '../../shared/form/GoogleZipCodeLookup';

export interface OwnerInformationFormProps {
  owner: Contact;
  usStates: UsState[];
  accountAddress: Address;
  firstOwnerAddress?: Address;
  sendUnmaskedFieldLogs: (data: LogData) => Promise<void>;
  onChange: (owner: Contact) => void;
}

const doesOwnerExist = (owner: Contact): boolean => {
  return !!owner?.uuid;
};

export const OwnerInformationForm = (
  props: OwnerInformationFormProps
): JSX.Element => {
  const [owner, setOwner] = useState<Contact>(props.owner);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [useAccountAddress, setUseAccountAddress] = useState(false);
  const [useFirstOwnerAddress, setUseFirstOwnerAddress] = useState(false);
  const [shouldShowSsnValue, setShouldShowSsnValue] = useState(
    !doesOwnerExist(props.owner)
  );
  const [shouldShowDobValue, setShouldShowDobValue] = useState(
    !doesOwnerExist(props.owner)
  );

  useEffect(() => {
    setOwner(props.owner);
  }, [props.owner]);

  const handleSendUnmaskedFieldLogs = async (
    fieldName: string
  ): Promise<void> => {
    try {
      await props.sendUnmaskedFieldLogs({
        field_name: fieldName,
        object_uuid: owner.uuid || '',
        name: `${owner.first_name} ${owner.last_name}`,
        action: 'show',
        user_uuid: '',
        user_email: '',
        source: '',
        timestamp: '',
        object_type: 'Owner',
      });
    } catch (e: unknown) {
      const error = toError(e);
      setErrorMessage(error.message);
    }
  };

  const toggleSsnVisibility = (): void => {
    if (shouldShowSsnValue) {
      setShouldShowSsnValue((prevValue) => !prevValue);
    } else {
      void handleSendUnmaskedFieldLogs('ssn').then(() => {
        setShouldShowSsnValue((prevValue) => !prevValue);
      });
    }
  };

  const toggleDobVisibility = (): void => {
    if (shouldShowDobValue) {
      setShouldShowDobValue((prevValue) => !prevValue);
    } else {
      void handleSendUnmaskedFieldLogs('born_on').then(() => {
        setShouldShowDobValue((prevValue) => !prevValue);
      });
    }
  };

  const shouldDisableSsnField = (): boolean => {
    if ((!shouldShowSsnValue && owner.ssn) || owner.ssnNotPresent) {
      return true;
    } else {
      return false;
    }
  };

  const shouldDisableDobField = (): boolean => {
    if ((!shouldShowDobValue && owner.born_on) || owner.bornOnNotPresent) {
      return true;
    } else {
      return false;
    }
  };

  const handleSsnValue = (): string => {
    if (!shouldShowSsnValue || owner.ssnNotPresent) {
      return '';
    } else {
      return owner.ssn || '';
    }
  };

  const handleDobValue = (): PlainDate | undefined => {
    if (!shouldShowDobValue || owner.bornOnNotPresent) {
      return undefined;
    } else {
      return dateParser(owner.born_on || null);
    }
  };

  const handleUseAccountAddress = (checked: boolean): void => {
    if (useFirstOwnerAddress) {
      setUseFirstOwnerAddress(false);
    }
    setUseAccountAddress(checked);
    setOwner({
      ...owner,
      address: {
        ...owner.address,
        ...props.accountAddress,
      },
    });
    props.onChange(owner);
  };

  const handleUseFirstOwnerAddress = (checked: boolean): void => {
    if (useAccountAddress) {
      setUseAccountAddress(false);
    }
    setUseFirstOwnerAddress(checked);
    setOwner({
      ...owner,
      address: {
        ...owner.address,
        ...props.firstOwnerAddress,
      },
    });
    props.onChange(owner);
  };

  const dropDownStates = (usStates: UsState[]): OptionShape[] => {
    return [
      ...usStates.map((usState) => ({
        value: usState.abbreviation,
        text: usState.name,
      })),
    ];
  };

  const dateParser = (date: string | null): PlainDate | undefined => {
    if (date === null) {
      return undefined;
    }

    const [year, month, day] = date.split('-');

    return new PlainDate(+year, +month, +day);
  };

  const handleOwnerFieldsChange: TextInputProps['onValueChange'] = (
    value,
    { event }
  ) => {
    setErrorMessage(null);
    setOwner({
      ...owner,
      [event.target.name]: value,
    });
    props.onChange(owner);
  };

  const handleDobFieldsChange = (value: PlainDate | undefined): void => {
    setErrorMessage(null);
    setOwner({
      ...owner,
      born_on: value?.toString() || null,
    });
    props.onChange(owner);
  };

  const handleSsnNotPresent = (value: boolean): void => {
    setErrorMessage(null);

    setOwner({
      ...owner,
      ssnNotPresent: value,
      ssn: '',
    });
    props.onChange(owner);
  };

  const handleDobNotPresent = (value: boolean): void => {
    setErrorMessage(null);
    setOwner({
      ...owner,
      bornOnNotPresent: value,
      born_on: '',
    });
    props.onChange(owner);
  };

  const handleOwnerMaskedFieldsChange = (
    valueObject: NumberFormatValues,
    targetName: string
  ): void => {
    setErrorMessage(null);
    setOwner({
      ...owner,
      [targetName]: valueObject.value,
    });
    props.onChange(owner);
  };

  const handleAddressFieldsChange: TextInputProps['onValueChange'] = (
    value,
    { event }
  ): void => {
    setErrorMessage(null);
    setOwner({
      ...owner,
      address: {
        ...owner.address,
        [event.target.name]: value,
      },
    });
    props.onChange(owner);
  };

  const handleZipChange = (value: string): void => {
    setErrorMessage(null);

    setOwner({
      ...owner,
      address: {
        ...owner.address,
        zip: value,
      },
    });
    props.onChange(owner);
  };

  const handleStateSelectChange: SelectProps['onValueChange'] = (
    value
  ): void => {
    setErrorMessage(null);
    setOwner({
      ...owner,
      address: {
        ...owner.address,
        state: value,
      },
    });
    props.onChange(owner);
  };

  const autofillAddressFields = (
    fields: {
      street1?: string;
      city?: string;
      state?: string;
      zip?: string;
    } = {}
  ): void => {
    setErrorMessage(null);
    const { street1, city, state, zip } = fields;

    setOwner({
      ...owner,
      address: {
        ...owner.address,
        street1: street1 ?? '',
        city: city ?? '',
        state: state ?? '',
        zip: zip ?? '',
      },
    });
    props.onChange(owner);
  };

  const getSsnVisibilityButton = (): JSX.Element => {
    // We only want to show the button if the owner object has an uuid set
    if (doesOwnerExist(owner)) {
      return shouldShowSsnValue ? (
        <TextInput.IconButton
          description="Hide SSN"
          onClick={toggleSsnVisibility}
          icon={'eye-slash'}
        />
      ) : (
        <TextInput.IconButton
          description="Show SSN"
          onClick={toggleSsnVisibility}
          icon={'eye'}
        />
      );
    } else {
      return <></>;
    }
  };

  const getDobVisibilityButton = (): JSX.Element => {
    // We only want to show the button if the owner object has an uuid set
    if (doesOwnerExist(owner)) {
      return shouldShowDobValue ? (
        <TextInput.IconButton
          description="Hide Date of Birth"
          onClick={toggleDobVisibility}
          icon={'eye-slash'}
        />
      ) : (
        <TextInput.IconButton
          description="Show Date of Birth"
          onClick={toggleDobVisibility}
          icon={'eye'}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Grid gutter>
      {errorMessage && (
        <Grid.Item>
          <Box marginBottom={3}>
            <Banner>{errorMessage}</Banner>
          </Box>
        </Grid.Item>
      )}
      <Grid.Item>
        <Flex flexDirection="row-reverse">
          <Flex flexDirection="column">
            <Box marginTop={2}>
              <Switch
                checked={useAccountAddress}
                id="useAccountAddress"
                label="Home address same as business address"
                onCheckedChange={handleUseAccountAddress}
              />
            </Box>
            <Box marginTop={2}>
              <Switch
                checked={useFirstOwnerAddress}
                id="useFirstOwnerAddress"
                label="Home address same as Owner 1"
                onCheckedChange={handleUseFirstOwnerAddress}
              />
            </Box>
          </Flex>
        </Flex>
      </Grid.Item>
      <Grid.Item m={6}>
        <TextInput
          label="First Name"
          name="first_name"
          onValueChange={handleOwnerFieldsChange}
          value={owner.first_name}
          required
        />
        <TextInput
          label="Last Name"
          name="last_name"
          onValueChange={handleOwnerFieldsChange}
          value={owner.last_name}
          required
        />
        <MaskedTextInput
          afterInputContent={getSsnVisibilityButton()}
          label="SSN"
          name="ssn"
          mask="#"
          format="###-##-####"
          onValueChange={(e) => {
            if (!shouldDisableSsnField()) {
              handleOwnerMaskedFieldsChange(e, 'ssn');
            }
          }}
          placeholder={shouldShowSsnValue ? '000-00-0000' : 'XXX-XX-XXXX'}
          value={handleSsnValue()}
          disabled={shouldDisableSsnField()}
          type="text"
          required={!owner.ssnNotPresent && shouldShowSsnValue}
        />
        <Box mt={2}>
          <Switch
            label='"SSN" not on application'
            id="ssnNotPresent"
            checked={!!owner.ssnNotPresent}
            onCheckedChange={handleSsnNotPresent}
          />
        </Box>
        <DatePicker
          afterInputContent={getDobVisibilityButton()}
          label="Date of Birth"
          placeholder={shouldShowDobValue ? DATE_FORMAT : 'XX XX, XXXX'}
          onChange={handleDobFieldsChange}
          selected={handleDobValue()}
          disabled={shouldDisableDobField()}
          required={!owner.bornOnNotPresent && shouldShowDobValue}
        />
        <Box mt={2}>
          <Switch
            label="Date of Birth not on application"
            id="bornOnNotPresent"
            checked={!!owner.bornOnNotPresent}
            onCheckedChange={handleDobNotPresent}
          />
        </Box>
        <TextInput
          label="Title"
          name="title"
          onValueChange={handleOwnerFieldsChange}
          value={owner.title || ''}
        />
        <TextInput
          label="Ownership %"
          name="ownership_percentage"
          onValueChange={handleOwnerFieldsChange}
          placeholder="0"
          value={
            owner.ownership_percentage?.toString() === '0'
              ? ''
              : owner.ownership_percentage?.toString()
          }
          required
        />
      </Grid.Item>
      <Grid.Item m={6}>
        <TextInput
          label="Street Address"
          name="street1"
          onValueChange={handleAddressFieldsChange}
          value={owner.address?.street1}
          required
        />
        <TextInput
          label="City"
          name="city"
          onValueChange={handleAddressFieldsChange}
          value={owner.address?.city}
          required
        />
        <Select
          label="State"
          name="state"
          options={dropDownStates(props.usStates)}
          onValueChange={handleStateSelectChange}
          value={owner.address?.state || ''}
          required
        />
        <GoogleZipCodeLookup
          handleAutofill={autofillAddressFields}
          label="Zip Code"
          onChange={handleZipChange}
          placeholder="5 or 9-digit Zip Code"
          usStates={props.usStates}
          value={owner.address?.zip || ''}
        />
        <MaskedTextInput
          label="Home Phone"
          name="home_phone"
          beforeInputContent={<TextInput.DecorativeIcon icon="phone" />}
          mask="#"
          format="(###)###-####"
          onValueChange={(e) => handleOwnerMaskedFieldsChange(e, 'home_phone')}
          placeholder="(###)###-####"
          value={owner.home_phone || ''}
        />
        <MaskedTextInput
          label="Mobile Phone"
          name="cell_phone"
          beforeInputContent={<TextInput.DecorativeIcon icon="mobile-alt" />}
          mask="#"
          format="(###)###-####"
          onValueChange={(e) => handleOwnerMaskedFieldsChange(e, 'cell_phone')}
          placeholder="(###)###-####"
          value={owner.cell_phone || ''}
        />
        <TextInput
          label="Email Address"
          name="email"
          beforeInputContent={<TextInput.DecorativeIcon icon="envelope" />}
          onValueChange={handleOwnerFieldsChange}
          value={owner.email || ''}
        />
      </Grid.Item>
    </Grid>
  );
};
