import { useCallback, useEffect, useState } from 'react';
import { defaultTo } from 'lodash';
import {
  usePullExperianCommercial,
  UsePullExperianCommercialArgs,
} from 'apiHooks/3pi/experianCommercialFetchHooks';
import {
  useBulkUpdateApiWebPresence,
  useGetApiLexisNexisCustomerSearchResults,
  useGetApiPaynetSearchResults,
  useGetApiWebPresences,
  usePullCustomerLexisNexis,
  usePullPaynet,
} from 'apiHooks/underwriting/submissionFetchHooks';
import {
  useUpdateApiCustomer,
  useGetApiSOSFields,
} from 'apiHooks/underwriting/customerFetchHooks';
import { useGetApiIndustryRisks } from 'apiHooks/underwriting/industryFetchHooks';
import { toError } from 'helpers/errorUtils';
import {
  LexisNexisCustomerSearchResult,
  LexisNexisCustomerSearchResultsResponse,
  PaynetResponse,
  PaynetResultsResponse,
  SubmissionWebPresenceResponse,
  LexisNexisCustomerSearchResultsResponseDocument,
  CustomerResponse,
  IndustryRiskProfile,
  CreateRelativity6ArtifactsRequestBody,
  IndustryNaicsResponse,
  SOSFieldsResponse,
} from 'types/api/underwriting/types';

import {
  MutationResponse,
  UseGenericQueryResponse,
  UseGenericMutationResult,
  UseGenericMutationResponse,
} from 'apiHooks/genericFetchHooks';

import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  useApiPullRelativity6IndustryPrediction,
  UsePullRelativity6IndustryPredictionArgs,
} from 'apiHooks/3pi/relativity6FetchHooks';
import {
  useApiIndustryNaicsIndustryId,
  useApiCreateRelativity6ArtifactsSubmission,
} from 'apiHooks/underwriting/relativity6FetchHooks';
import { Relativity6IndustryPredictionResponseBody } from 'types/api/3pi/types';

import {
  fetchCustomer,
  fetchExperianMetaData,
  fetchBusinessLexisNexisCustomerReports,
  fetchBusinessLexisNexisCustomerOverviews,
} from './businessOverviewFetchUtils';
import {
  CustomerBusinessSummary,
  BusinessCustomerLexisNexisOverview,
  BusinessCustomerLexisNexisReport,
  ExperianMetaData,
  LexisNexisCustomerSearchResultDocument,
  LexisNexisSearchResult,
  PaynetSearchResult,
  UpdateCustomerBody,
  RiskProfile,
  WebPresence,
  PullRelativity6IndustryPredictionResponse,
  ArtifactData,
  Relativity6ArtifactsRequestBody,
  SOSFields,
} from './businessOverview.types';

export interface UseCustomerResponse {
  customer?: CustomerBusinessSummary;
  loading: boolean;
  error?: string;
}

export const useCustomer = (customerUuid?: string): UseCustomerResponse => {
  const [customer, setCustomer] = useState<
    CustomerBusinessSummary | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getCustomer = async (): Promise<void> => {
      try {
        if (customerUuid) {
          setLoading(true);
          const data = await fetchCustomer(customerUuid);
          setCustomer(data);
        }
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Failed to fetch Customer: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    void getCustomer();
  }, [customerUuid]);

  return {
    customer,
    loading,
    error: errorMessage,
  };
};

export interface UseWebPresenceResponse {
  webPresence?: WebPresence;
  loading: boolean;
  error?: string;
}

const toWebPresences = (
  response?: SubmissionWebPresenceResponse
): WebPresence | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    businessWebsite: response.business_website ?? undefined,
    facebook: response.facebook ?? undefined,
    instagram: response.instagram ?? undefined,
    yelp: response.yelp ?? undefined,
    other: response.other ?? undefined,
  };
};

export const useWebPresence = (
  submissionUuid?: string
): UseGenericQueryResponse<WebPresence> => {
  return useGenericFeatureQuery(
    useGetApiWebPresences,
    toWebPresences,
    submissionUuid
  );
};

export interface UseExperianMetaDataResponse {
  experianMetaData?: ExperianMetaData;
  loading: boolean;
  error?: string;
}

export const useExperianMetaData = (
  submissionUuid?: string
): UseExperianMetaDataResponse => {
  const [experianMetaData, setExperianMetaData] = useState<ExperianMetaData>();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getExperianMetaData = async (): Promise<void> => {
      try {
        if (submissionUuid) {
          setLoading(true);
          const data = await fetchExperianMetaData(
            submissionUuid,
            'submission'
          );
          setExperianMetaData(data);
        }
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Experian meta data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    void getExperianMetaData();
  }, [submissionUuid]);

  return {
    experianMetaData,
    loading,
    error: errorMessage,
  };
};

export interface BusinessCustomerLexisNexisReportResponse {
  customerLexisNexisReports?: BusinessCustomerLexisNexisReport[];
  loading: boolean;
  error?: string;
  refetch: () => Promise<void>;
}

export const useBusinessCustomerLexisNexisReports = (
  submissionUuid: string
): BusinessCustomerLexisNexisReportResponse => {
  const [reports, setReports] = useState<
    BusinessCustomerLexisNexisReport[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const getReports = async (): Promise<void> => {
    try {
      const data = await fetchBusinessLexisNexisCustomerReports(submissionUuid);
      setReports(data);
    } catch (e: unknown) {
      const error = toError(e);
      setErrorMessage(
        `Failed to fetch Lexis Nexis Customer Report: ${error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionUuid]);

  return {
    customerLexisNexisReports: reports,
    loading,
    error: errorMessage,
    refetch: getReports,
  };
};

export type UsePullCustomerCreditReportInput = Omit<
  UsePullExperianCommercialArgs,
  'submissionUuid'
>;

export type UsePullCustomerCreditReportResponse = [
  (input: UsePullCustomerCreditReportInput) => Promise<MutationResponse>,
  { success?: true; loading: boolean; error?: Error }
];

export const usePullCustomerCreditReport = (
  submissionUuid: string
): UsePullCustomerCreditReportResponse => {
  const [fetcher, { data, loading, error }] = usePullExperianCommercial();

  const wrappedFetcher = (
    input: UsePullCustomerCreditReportInput
  ): Promise<MutationResponse> => {
    return fetcher({
      ...input,
      submissionUuid,
    });
  };

  return [wrappedFetcher, { success: data?.success, loading, error }];
};

export interface UseCustomerLexisNexisOverviewResponse {
  overviews?: BusinessCustomerLexisNexisOverview[];
  loading: boolean;
  error?: string;
}

export const useBusinessCustomerLexisNexisOverview = (
  submissionUuid: string
): UseCustomerLexisNexisOverviewResponse => {
  const [overviews, setOverviews] = useState<
    BusinessCustomerLexisNexisOverview[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getLexisNexisOverview = async (): Promise<void> => {
      try {
        const data = await fetchBusinessLexisNexisCustomerOverviews(
          submissionUuid
        );
        setOverviews(data);
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    };

    void getLexisNexisOverview();
  }, [submissionUuid]);

  return {
    overviews,
    loading,
    error: errorMessage,
  };
};

export interface UseCustomerLexisNexisSearchResultsResponse {
  documents?: LexisNexisCustomerSearchResultDocument[] | null;
  loading: boolean;
  error?: string;
}

const toLexisNexisSearchResult = (
  result: LexisNexisCustomerSearchResult
): LexisNexisSearchResult => {
  const definedStreetAddressParts = [
    result.address?.street_number,
    result.address?.street_name,
    result.address?.street_suffix,
  ].filter(Boolean);

  return {
    reportId: result.report_identifier,
    name: result.name,
    address: {
      streetAddress:
        definedStreetAddressParts.length > 0
          ? definedStreetAddressParts.join(' ')
          : undefined,
      city: result.address?.city,
      state: result.address?.state,
      zip: result.address?.zip5,
    },
    hasUcc: result.has_ucc,
  };
};

const toLexisNexisCustomerSearchResultsResponseDocument = (
  document: LexisNexisCustomerSearchResultsResponseDocument
): LexisNexisCustomerSearchResultDocument => {
  return {
    submissionUuid: document.submission_uuid,
    results: document.results.map(toLexisNexisSearchResult),
    createdAt: document.created_at,
    query: document.query
      ? {
          companyName: document?.query?.company_name ?? undefined,
        }
      : undefined,
  };
};

const responseToDocuments = (
  data?: LexisNexisCustomerSearchResultsResponse
): LexisNexisCustomerSearchResultDocument[] | undefined => {
  /**
   * This endpoint response can contain a number of things based on a feature flag.
   *
   * If the send_multiple_ln_search_results flag is on, it will always return an
   * array of documents or an empty array. If the flag is off, it will return
   * an empty array or a single document object.
   *
   * Until we can fully implement this feature flag in a future ticket, this function
   * will convert the response to an array of documents if it is not already an array,
   * and we will ultimately find the first document with more than one result and return
   * that document's results from the hook.
   *
   * This is so that whether the flag is on or off on the backend, the frontend
   * feature will maintain the same behavior for now.
   *
   * @bradleyden - November 20, 2023
   */
  if (!data) {
    return undefined;
  }
  if (!Array.isArray(data?.documents)) {
    return [data?.documents].map(
      toLexisNexisCustomerSearchResultsResponseDocument
    );
  }

  return data?.documents.map(toLexisNexisCustomerSearchResultsResponseDocument);
};

export const useLexisNexisCustomerSearchResults = (
  submissionUuid: string
): UseCustomerLexisNexisSearchResultsResponse => {
  const { data, loading, error } =
    useGetApiLexisNexisCustomerSearchResults(submissionUuid);

  /**
   * I'm explicitly not using `useMemo` here because I don't
   * expect any crazy performance issues from this, but I've
   * written this in such a way as to make that easy to add if
   * I'm wrong.
   *
   * @tyrelosaur - March 21, 2023
   */
  const docs = responseToDocuments(data);

  return {
    documents: docs,
    loading,
    error: error?.message,
  };
};

type PullLexisNexisBody = {
  force?: boolean;
  reportId?: string;
};

type UsePullCustomerLexisNexisReportResult = [
  (input: PullLexisNexisBody) => Promise<MutationResponse>,
  { data?: { success: true }; loading: boolean; error?: Error }
];

export const usePullCustomerLexisNexisReport = (
  submissionUuid: string
): UsePullCustomerLexisNexisReportResult => {
  const [baseFetchFunction, { data, loading, error }] =
    usePullCustomerLexisNexis();

  const pullReport = async (
    input: PullLexisNexisBody
  ): Promise<MutationResponse> => {
    return await baseFetchFunction({
      submissionUuid,
      force: input.force,
      reportId: input.reportId,
    });
  };

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};

export interface UsePaynetResultsResponse {
  results?: PaynetSearchResult[] | null;
  loading: boolean;
  error?: string;
}

const toPaynetSearchResult = (result: PaynetResponse): PaynetSearchResult => {
  return {
    paynetId: result.paynet_id,
    name: result.name,
    city: result.city,
    stateCode: result.state_code,
    taxId: result.tax_id,
  };
};

const paynetResponseToResults = (
  data?: PaynetResultsResponse
): PaynetSearchResult[] | undefined => {
  if (!data) {
    return undefined;
  }
  if (Array.isArray(data?.documents)) {
    return undefined;
  }

  return data?.documents?.results?.map(toPaynetSearchResult);
};

export const usePaynetSearchResults = (
  submissionUuid: string
): UsePaynetResultsResponse => {
  const { data, loading, error } = useGetApiPaynetSearchResults(submissionUuid);

  const results = paynetResponseToResults(data);

  return {
    results,
    loading,
    error: error?.message,
  };
};

type PullPaynetBody = {
  reportId?: string;
};

type UsePullPaynetReportResult = [
  (input: PullPaynetBody) => Promise<MutationResponse>,
  { data?: MutationResponse; loading: boolean; error?: Error }
];

export const usePullPaynetReport = (
  submissionUuid: string
): UsePullPaynetReportResult => {
  const [baseFetchFunction, { data, loading, error }] = usePullPaynet();

  const pullReport = (input: PullPaynetBody): Promise<MutationResponse> => {
    return baseFetchFunction({
      submissionUuid,
      reportId: input.reportId,
    });
  };

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};

type UpdatedCustomer = Pick<CustomerBusinessSummary, 'legalName' | 'dba'> & {
  industryId: number | undefined;
  industry: string | undefined;
  businessStartDate?: string | undefined;
};

type UseUpdateCustomerResult = [
  (input: UpdateCustomerBody) => Promise<MutationResponse>,
  UseGenericMutationResult<UpdatedCustomer>
];

const toUpdatedCustomer = (
  customerResponse: CustomerResponse
): UpdatedCustomer => {
  return {
    legalName: customerResponse.legal_name,
    dba: customerResponse.name,
    industryId: customerResponse.industry_id ?? undefined,
    industry: customerResponse.industry_name ?? undefined,
    businessStartDate: customerResponse.started_on ?? undefined,
  };
};

export const useUpdateCustomer = (
  customerUuid: string
): UseUpdateCustomerResult => {
  const [updateCustomer, { loading, responseReady, data, error }] =
    useUpdateApiCustomer();

  const updateFunction = (
    args: UpdateCustomerBody
  ): Promise<MutationResponse> => {
    return updateCustomer({
      customerUuid,
      updateBody: {
        legal_name: args.legalName,
        name: args.dba,
        industry_id: args.industryId,
        started_on: args.businessStartDate,
      },
    });
  };

  return [
    updateFunction,
    {
      loading,
      // Might not be needed. Wait to next PR to see.
      responseReady,
      // Might not be needed. Wait to next PR to see.
      data: data ? toUpdatedCustomer(data) : undefined,
      error,
    },
  ];
};

const toIndustryRisks = (response: IndustryRiskProfile): RiskProfile => {
  return {
    id: response.id,
    name: response.name,
    riskProfile: response.risk_profile,
    pricingRiskProfile: response.pricing_risk_profile,
    archived: response.archived,
  };
};

export const useIndustryRisks = (): UseGenericQueryResponse<RiskProfile[]> => {
  return useGenericFeatureQuery(useGetApiIndustryRisks, (data) =>
    data ? data.map(toIndustryRisks) : undefined
  );
};

const toWebPresence = (
  webPresence?: SubmissionWebPresenceResponse
): WebPresence => {
  if (!webPresence) {
    return {};
  }

  return {
    businessWebsite: webPresence.business_website ?? undefined,
    facebook: webPresence.facebook ?? undefined,
    instagram: webPresence.instagram ?? undefined,
    yelp: webPresence.yelp ?? undefined,
    other: webPresence.other ?? undefined,
  };
};

export type UseBulkUpdateWebPresenceResult = UseGenericMutationResponse<
  WebPresence,
  WebPresence
>;

export const useBulkUpdateWebPresence = (
  submissionUuid: string
): UseBulkUpdateWebPresenceResult => {
  const [updateWebPresences, { data, error, ...rest }] =
    useBulkUpdateApiWebPresence();

  const updateFunction = (
    args: WebPresence
  ): Promise<Pick<MutationResponse, 'success'>> => {
    return updateWebPresences({
      submissionUuid,
      body: {
        business_website: args.businessWebsite ?? null,
        facebook: args.facebook ?? null,
        instagram: args.instagram ?? null,
        yelp: args.yelp ?? null,
        other: args.other ?? null,
      },
    });
  };

  return [
    updateFunction,
    {
      data: data ? toWebPresence(data) : undefined,
      error,
      ...rest,
    },
  ];
};

export type UsePullRelativity6IndustryPredictionResponse = [
  (args: PullRelativity6IndustryPredictionArgs) => Promise<MutationResponse>,
  {
    data?: PullRelativity6IndustryPredictionResponse;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

type PullRelativity6IndustryPredictionArgs = {
  body: UsePullRelativity6IndustryPredictionArgs;
};

const toRelativity6IndustryPredictionAttributes = (
  prediction: Relativity6IndustryPredictionResponseBody
): PullRelativity6IndustryPredictionResponse => {
  return {
    id: prediction.id,
    naicsVersion: prediction.naics_version,
    industryPredictions: prediction.industry_predictions.map((p) => ({
      industryName: p.industry_name,
      naicsCode: p.naics_code,
      confidenceScore: p.confidence_score,
      rank: p.rank,
    })),
    webPresences: prediction.web_presences,
  };
};

export const usePullRelativity6IndustryPrediction =
  (): UsePullRelativity6IndustryPredictionResponse => {
    const [pullFn, { data, error, responseReady, ...rest }] =
      useApiPullRelativity6IndustryPrediction();

    const pullFunction = useCallback(
      async (
        args: PullRelativity6IndustryPredictionArgs
      ): Promise<MutationResponse> => {
        return pullFn(args.body);
      },
      [pullFn]
    );

    return [
      pullFunction,
      {
        data: data
          ? toRelativity6IndustryPredictionAttributes(data)
          : undefined,
        error,
        responseReady,
        ...rest,
      },
    ];
  };

type IndustryNaicsResponseBody = {
  industryId: string;
};

const toIndustryNaicsIndustryId = (
  data?: IndustryNaicsResponse
): IndustryNaicsResponseBody | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    industryId: data.industry_id,
  };
};

export const useGetIndustryNaicsIndustryId = (
  naicsCode?: string,
  naicsVersion?: '2017' | '2022'
): UseGenericQueryResponse<IndustryNaicsResponseBody> => {
  return useGenericFeatureQuery(
    useApiIndustryNaicsIndustryId,
    toIndustryNaicsIndustryId,
    naicsCode,
    naicsVersion
  );
};

const toRelativity6ArtifactsSubmission = (
  artifact: ArtifactData
): CreateRelativity6ArtifactsRequestBody => {
  return {
    artifact_data: {
      predicted_industry: artifact.predictedIndustry,
      assigned_industry: artifact.assignedIndustry,
      confidence_score: artifact.confidenceScore,
      naics_code: artifact.naicsCode,
      naics_version: artifact.naicsVersion,
      artifact_case: artifact.artifactCase,
      artifact_type: artifact.artifactType,
      naics_code_title: artifact.naicsCodeTitle,
    },
  };
};

type UseCreateRelativity6AnalyticsArtifactsResponse = [
  (args: Relativity6ArtifactsRequestBody) => Promise<MutationResponse>,
  {
    error?: Error;
    loading: boolean;
  }
];

export type UseCreateRelativity6ArtifactsArgs = {
  submissionUuid: string;
  body: Relativity6ArtifactsRequestBody;
};

export const useCreateRelativity6Artifacts =
  (): UseCreateRelativity6AnalyticsArtifactsResponse => {
    const [createFn, { error, ...rest }] =
      useApiCreateRelativity6ArtifactsSubmission();

    const createFunction = async (
      args: Relativity6ArtifactsRequestBody
    ): Promise<MutationResponse> => {
      return createFn({
        submissionUuid: args.submissionUuid,
        body: toRelativity6ArtifactsSubmission(args.artifactData),
      });
    };

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };

export const toSOSFields = (
  response?: SOSFieldsResponse
): SOSFields | undefined => {
  if (!response) {
    return undefined;
  }

  const success = defaultTo(response.success, undefined);

  const error = defaultTo(response.error, undefined);

  if (!response.documents) {
    return {
      success,
      error,
    };
  }

  const streetNumber = defaultTo(
    response.documents.business_address?.street_number,
    undefined
  );

  const streetName = defaultTo(
    response.documents.business_address?.street_name,
    undefined
  );

  const streetSuffix = defaultTo(
    response.documents.business_address?.street_suffix,
    undefined
  );

  const streetAddress =
    streetNumber || streetName || streetSuffix
      ? `${streetNumber || ''} ${streetName || ''} ${streetSuffix || ''}`.trim()
      : undefined;

  const ownership = response.documents.ownership_info
    ? response.documents.ownership_info.map((owner) => {
        return {
          fullName: defaultTo(owner.full_name, undefined),
          contactType: owner.contact_type?.map((contact) => {
            return {
              type: defaultTo(contact.type, undefined),
              title: defaultTo(contact.title, undefined),
            };
          }),
        };
      })
    : undefined;

  const businessStartDate = response.documents.incorporate_date
    ? {
        year: defaultTo(response.documents.incorporate_date.year, undefined),
        month: defaultTo(response.documents.incorporate_date.month, undefined),
        day: defaultTo(response.documents.incorporate_date.day, undefined),
      }
    : undefined;

  const toZipCode = (zip5?: string, zip4?: string): string | undefined => {
    if (!zip5) {
      return undefined;
    }

    return zip4 ? `${zip5}-${zip4}` : zip5;
  };

  const businessAddress = response.documents.business_address
    ? {
        streetAddress: streetAddress,
        city: defaultTo(response.documents.business_address.city, undefined),
        state: defaultTo(response.documents.business_address.state, undefined),
        zip: toZipCode(
          defaultTo(response.documents.business_address.zip5, undefined),
          defaultTo(response.documents.business_address.zip4, undefined)
        ),
      }
    : undefined;

  const registeredAgent = response.documents.registered_agent
    ? {
        fullName: defaultTo(
          response.documents.registered_agent.full_name,
          undefined
        ),
        title: defaultTo(response.documents.registered_agent.title, undefined),
      }
    : undefined;

  const documents = {
    status: defaultTo(response.documents.status, undefined),
    organizationStructure: defaultTo(
      response.documents.organize_structure,
      undefined
    ),
    legalName: defaultTo(response.documents.company_name, undefined),
    businessStartDate: businessStartDate,
    businessAddress: businessAddress,
    ownership: ownership,
    registeredAgent: registeredAgent,
    message: defaultTo(response.documents.message, undefined),
  };

  return {
    success: success,
    error: error,
    documents: documents,
  };
};

export const useSOSFields = (
  submissionUuid?: string
): UseGenericQueryResponse<SOSFields> => {
  return useGenericFeatureQuery(
    useGetApiSOSFields,
    toSOSFields,
    submissionUuid
  );
};
