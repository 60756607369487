import { useCallback } from 'react';
import {
  deleteDocument,
  fetchBusinessPaynetReport,
  fetchCustomer,
  fetchCustomerEstablishedDates,
  fetchCustomerIneligibilities,
  fetchCustomerSubmissions,
  patchCustomer,
} from 'api/underwriting/customerFetchUtils';
import {
  useGenericMutation,
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
  useLazyGenericQuery,
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
} from 'apiHooks/genericFetchHooks';
import {
  CustomerResponse,
  SubmissionResponse,
  CustomerEstablishedDatesResponse,
  UpdateCustomerRequestBody,
  IneligibilitiesResponse,
  BusinessPaynetResponse,
  SOSFieldsResponse,
  UccBusinessReportResponse,
} from 'types/api/underwriting/types';
import {
  fetchBusinessUccReport,
  fetchSOSFields,
} from 'components/SubmissionUnderwriting/BusinessOverview/businessOverviewFetchUtils';

export type UseGetApiCustomerResponse =
  UseGenericQueryResponse<CustomerResponse>;

export const useGetApiCustomer = (
  customerUuid?: string
): UseGetApiCustomerResponse => {
  const getCustomer = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }

    return fetchCustomer(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(getCustomer);
};

interface UseLazyGetApiCustomerArgs {
  customerUuid: string;
}

export type UseLazyGetApiCustomerFetcher =
  UseLazyGenericQueryFetcher<UseLazyGetApiCustomerArgs>;

export type UseLazyGetApiSubmissionResponse =
  UseLazyGenericQueryResult<CustomerResponse>;

const fetchCustomerWithArgs = (
  args: UseLazyGetApiCustomerArgs
): Promise<CustomerResponse> => {
  return fetchCustomer(args.customerUuid);
};

export const useLazyGetApiCustomer = (): [
  UseLazyGetApiCustomerFetcher,
  UseLazyGetApiSubmissionResponse
] => {
  return useLazyGenericQuery(fetchCustomerWithArgs);
};

export const useGetCustomerSubmissions = (
  customerUuid?: string
): UseGenericQueryResponse<SubmissionResponse[]> => {
  const getCustomerSubmission = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }
    return fetchCustomerSubmissions(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(getCustomerSubmission);
};

export const useGetApiCustomerEstablishedDates = (
  customerUuid?: string
): UseGenericQueryResponse<CustomerEstablishedDatesResponse> => {
  const getCustomerEstablishedDates = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }
    return fetchCustomerEstablishedDates(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(getCustomerEstablishedDates);
};

export interface UseUpdateCustomerArgs {
  customerUuid: string;
  updateBody: UpdateCustomerRequestBody;
}

export type UseUpdateCustomerResponse = UseGenericMutationResponse<
  CustomerResponse,
  UseUpdateCustomerArgs
>;

const updateCustomer = (
  input: UseUpdateCustomerArgs
): Promise<CustomerResponse> => {
  return patchCustomer(input.customerUuid, input.updateBody);
};

export const useUpdateApiCustomer = (): UseUpdateCustomerResponse => {
  return useGenericMutation(updateCustomer);
};

export const useGetApiIneligibilities = (
  customerUuid?: string
): UseGenericQueryResponse<IneligibilitiesResponse> => {
  const fetchFunction = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }

    return fetchCustomerIneligibilities(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(fetchFunction);
};

export const useGetApiBusinessPaynetReport = (
  submissionUuid?: string
): UseGenericQueryResponse<BusinessPaynetResponse[]> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchBusinessPaynetReport(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};

export const useGetApiSOSFields = (
  submissionUuid?: string
): UseGenericQueryResponse<SOSFieldsResponse> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSOSFields(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SOSFieldsResponse>(fetchFunction);
};

const deleteReportFunction = (reportId: string): Promise<boolean> => {
  return deleteDocument(reportId);
};

export const useDeleteApiReport = (): UseGenericMutationResponse<
  boolean,
  string
> => {
  return useGenericMutation(deleteReportFunction);
};

export type UseGetApiBusinessUccReport =
  UseGenericQueryResponse<UccBusinessReportResponse>;

type UseUccReportArgs = {
  submissionUuid?: string;
  reportId?: string;
};

export const useGetApiBusinessUccReport = (
  input: UseUccReportArgs
): UseGetApiBusinessUccReport => {
  const fetcher = useCallback(() => {
    if (!input.submissionUuid || !input.reportId) {
      return undefined;
    }
    return fetchBusinessUccReport(input.submissionUuid, input.reportId);
  }, [input.submissionUuid, input.reportId]);

  return useGenericQuery(fetcher);
};
