import React from 'react';
import { Icon, Table, Tooltip } from '@forward-financing/fast-forward';

import { TradelineDetails } from '../types';

export const TradelineCreditLoanTypeCell = ({
  originalCreditorName,
  creditLoanType,
}: TradelineDetails): JSX.Element => {
  if (originalCreditorName) {
    return (
      <Table.Cell columnWidth="medium">
        <span>{creditLoanType}</span>{' '}
        <Tooltip
          content={`Original creditor: ${originalCreditorName}`}
          trigger={
            <span data-testid="tradelines-table-credit-loan-type-tooltip">
              <Icon name="info-circle" />
            </span>
          }
        />
      </Table.Cell>
    );
  }

  return <Table.Cell columnWidth="medium">{creditLoanType}</Table.Cell>;
};
