import React from 'react';

import { GoToNextDealButton } from 'components/shared/GoToNextPrequalDeal/GoToNextDealButton';
import { MutationResponse } from 'apiHooks/genericFetchHooks';

export interface ToolboxGoToNextSubmissionLinkProps {
  updateFunction: () => Promise<MutationResponse>;
  nextDealUrl: string | undefined;
  nextDealLoading: boolean;
}
export const ToolboxGoToNextSubmissionLink = ({
  updateFunction,
  nextDealUrl,
  nextDealLoading,
}: ToolboxGoToNextSubmissionLinkProps): JSX.Element => {
  return (
    <GoToNextDealButton
      updateFunction={updateFunction}
      nextDealUrl={nextDealUrl}
      nextDealLoading={nextDealLoading}
    />
  );
};
