import React from 'react';
import { Card } from '@forward-financing/fast-forward';
import { FraudScoringSubmission } from './fraudScoringContainer.types';
import { FraudScoringTable } from './FraudScoringTable';

export interface FraudScoringContainerProps {
  submission?: FraudScoringSubmission;
}

export const FraudScoringContainer = ({
  submission,
}: FraudScoringContainerProps): JSX.Element => {
  return (
    <Card title="Fraud Scores">
      <FraudScoringTable submission={submission} />
    </Card>
  );
};
