import React from 'react';
import { Banner, Box, Loading } from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';
import { UnderwritingAttachmentManager } from './UnderwritingAttachmentManager';
import { useSubmission } from '../attachmentManagerHooks';
import { useCoreSalesforceMap } from 'apiHooks/crmGateway/coreSalesforceMapsFetchHooks';

type Params = {
  submissionSfid: string;
};

export const UnderwritingAttachmentManagerSalesforceContainer =
  (): JSX.Element => {
    const { submissionSfid } = useParams<Params>();

    // istanbul ignore next
    if (!submissionSfid) {
      throw new Error('No Submission SFID param set');
    }

    const {
      map: coreMap,
      loading,
      error,
    } = useCoreSalesforceMap(submissionSfid);

    const {
      submission,
      error: errorSubmission,
      refetch: refetchSubmission,
    } = useSubmission(coreMap?.coreId);

    if (loading) {
      return (
        <Box p={2}>
          <Loading />
        </Box>
      );
    }

    if (error) {
      return (
        <Box p={2}>
          <Banner dismissable={false}>{error.message}</Banner>
        </Box>
      );
    }

    // istanbul ignore else
    if (coreMap?.coreId) {
      return (
        <Box p={2}>
          {errorSubmission && (
            <Banner dismissable={false}>{errorSubmission.message}</Banner>
          )}
          <UnderwritingAttachmentManager
            submission={submission}
            refetchSubmission={refetchSubmission}
            submissionUuid={coreMap.coreId}
            isSalesforce
          />
        </Box>
      );
    }

    // istanbul ignore next: we should never hit this case.
    return (
      <Box p={2}>
        <Banner dismissable={false}>
          Something has gone very wrong, and we are in a state that should be
          impossible. Please create an AST.
        </Banner>
      </Box>
    );
  };
