import { useCallback, useEffect, useState } from 'react';
import { defaultTo } from 'lodash';
import { toError } from 'helpers/errorUtils';
import {
  BankruptcyRecordResponse,
  CriminalRecord as ApiCriminalRecord,
  LexisNexisOwnerReport,
  LexisNexisResponse,
  PublicRecordsUccRecord,
  SubmissionResponse,
} from 'types/api/underwriting/types';
import { toLienJudgment } from 'components/LiensTable/liensTableUtils';

import {
  ConsumerAddress,
  ConsumerCreditEntities,
  ConsumerCreditV2,
  ExperianConsumerArchive,
  ExperianConsumerCreditReportResponse,
  ExperianConsumerPublicRecordResponse,
  ExperianConsumerResponse,
  ExperianConsumerResponseV2,
  Inquiry,
  ProfileSummary,
  RiskModel,
  Tradeline,
} from 'types/api/3pi/types';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiSubmission } from 'apiHooks/underwriting/submissionFetchHooks';
import {
  useGetApiLexisNexisForOwners,
  useGetApiPublicDocuments,
} from 'apiHooks/underwriting/ownerFetchHooks';
import {
  useFetchExperianConsumer,
  useFetchExperianConsumerArchives,
  useFetchExperianConsumerCreditReport,
} from 'apiHooks/3pi/experianConsumerFetchHooks';
import { fetchOwnerCreditInquiries } from 'api/underwriting/ownerFetchUtils';
import {
  fetchLexisNexisSearchResults,
  pullLexisNexis,
} from './ownerOverviewFetchUtils';
import {
  BankruptcyRecord,
  CriminalFilingsOverview,
  CriminalRecord,
  ExperianConsumer,
  ExperianConsumerAddress,
  ExperianConsumerArchiveItem,
  ExperianConsumerCreditV2,
  ExperianConsumerDetailInfo,
  ExperianConsumerEmployer,
  ExperianConsumerPublicRecord,
  ExperianInquiry,
  ExperianProfileSummary,
  ExperianPublicRecord,
  ExperianRiskModel,
  ExperianTradeline,
  LexisNexisOverviewData,
  LexisNexisPersonalPublicDocument,
  LexisNexisSearchResult,
  OwnerCreditInquiry,
  OwnerOverviewSubmission,
  PublicRecordsOverview,
  TradelinesOverview,
  UccRecord,
} from './types';

const formatDateObjectToString = (date: {
  day?: string;
  year: string;
  month?: string;
}): string => {
  if (!date.day || !date.month) {
    return date.year;
  } else {
    return `${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(
      2,
      '0'
    )}`;
  }
};

const toUccDocument = (ucc: PublicRecordsUccRecord): UccRecord => {
  return {
    securedParty: ucc.secured_party,
    dateFiled:
      typeof ucc.date_filed === 'string' || ucc.date_filed === null
        ? ucc.date_filed
        : formatDateObjectToString(ucc.date_filed),
    collateralDescription: ucc.collateral_description ?? undefined,
    isExpired: ucc.is_expired,
    isReleased: ucc.is_released,
  };
};

const toCriminalRecord = (
  criminalRecord: ApiCriminalRecord
): CriminalRecord => ({
  fullName: criminalRecord.full_name,
  offense: criminalRecord.offense,
  offenseDate: criminalRecord.offense_date,
  arrestDate: criminalRecord.arrest_date,
  caseFilingDate: criminalRecord.case_filing_date,
  state: criminalRecord.state,
  admittedDates: criminalRecord.admitted_dates,
  scheduledReleaseDates: criminalRecord.scheduled_release_dates,
  statuses: criminalRecord.statuses,
  caseTypeDescription: criminalRecord.case_type_description,
});

const toBankruptcyRecord = (
  bankruptcy: BankruptcyRecordResponse
): BankruptcyRecord => ({
  chapter: bankruptcy.chapter,
  filingDate: bankruptcy.filing_date,
  exitDate: bankruptcy.exit_date,
  filedAs: bankruptcy.filed_as,
  statuses: bankruptcy.statuses,
});

const toPersonalPublicDocument = (
  doc: LexisNexisOwnerReport
): LexisNexisPersonalPublicDocument => {
  return {
    documentId: doc.document_id,
    createdAt: doc.created_at,
    primary: doc.primary,
    individualBestName: defaultTo(doc.individual_best_name, undefined),
    ownerUuid: doc.owner_uuid,
    uccs: doc.uccs.map(toUccDocument),
    criminals: doc.criminals.map(toCriminalRecord),
    bankruptcies: doc.bankruptcies.map(toBankruptcyRecord),
    lienJudgments: doc.lien_judgments.map(toLienJudgment),
    reportIdentifier: defaultTo(doc.report_identifier, undefined),
  };
};

export const usePublicDocuments = (
  submissionUuid: string
): UseGenericQueryResponse<LexisNexisPersonalPublicDocument[]> => {
  return useGenericFeatureQuery(
    useGetApiPublicDocuments,
    (data) => data?.documents.map(toPersonalPublicDocument),
    submissionUuid
  );
};

interface UseLexisNexisSearchResultsResult {
  searchResults: LexisNexisSearchResult[];
  loading: boolean;
  error?: string;
  refetch: () => Promise<void>;
}

export const useLexisNexisSearchResults = (
  submissionUuid: string
): UseLexisNexisSearchResultsResult => {
  const [searchResults, setSearchResults] = useState<LexisNexisSearchResult[]>(
    []
  );
  const [searchResultsLoading, setSearchResultsLoading] =
    useState<boolean>(true);
  const [searchResultsError, setSearchResultsError] = useState<
    string | undefined
  >();

  const getSearchResults = useCallback(async (): Promise<void> => {
    try {
      setSearchResultsLoading(true);
      const data = await fetchLexisNexisSearchResults(submissionUuid);
      setSearchResults(data);
    } catch (e: unknown) {
      const error = toError(e);
      setSearchResultsError(
        `Failed to fetch lexis nexis search results: ${error.message}`
      );
    } finally {
      setSearchResultsLoading(false);
    }
  }, [submissionUuid]);

  useEffect(() => {
    void getSearchResults();
  }, [submissionUuid, getSearchResults]);

  return {
    loading: searchResultsLoading,
    searchResults: searchResults,
    error: searchResultsError,
    refetch: getSearchResults,
  };
};

type UsePullLexisNexisResult = [
  (
    submissionUuid: string,
    ownerUuid: string,
    force: boolean,
    reportId?: string
  ) => Promise<void>,
  { success: boolean; loading: boolean; error?: string }
];

export const usePullLexisNexis = (): UsePullLexisNexisResult => {
  const [success, setSuccess] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(true);
  const [attachError, setAttachError] = useState<string | undefined>();

  const fetchLexisNexis = useCallback(
    async (
      submissionUuid: string,
      ownerUuid: string,
      force: boolean,
      reportId?: string
    ): Promise<void> => {
      try {
        setRequestLoading(true);
        const data = await pullLexisNexis(
          submissionUuid,
          ownerUuid,
          force,
          reportId
        );
        setSuccess(data);
      } catch (e: unknown) {
        const error = toError(e);
        setAttachError(`Failed to pull LexisNexis report: ${error.message}`);
      } finally {
        setRequestLoading(false);
      }
    },
    []
  );

  return [
    fetchLexisNexis,
    { loading: requestLoading, success: success, error: attachError },
  ];
};

interface UseInquiriesResponse {
  inquiries?: OwnerCreditInquiry[];
  loading: boolean;
  error?: string;
  refetch: () => Promise<void>;
}

export const useGetOwnerInquiries = (
  submissionUuid: string
): UseInquiriesResponse => {
  const [inquiries, setInquiries] = useState<OwnerCreditInquiry[]>([]);
  const [inquiriesLoading, setInquiriesLoading] = useState<boolean>(true);
  const [inquiriesError, setInquiriesError] = useState<string | undefined>();

  const getInquiries = useCallback(async (): Promise<void> => {
    try {
      setInquiriesLoading(true);
      const data = await fetchOwnerCreditInquiries(submissionUuid);
      setInquiries(data);
    } catch (e: unknown) {
      const error = toError(e);
      setInquiriesError(`Failed to fetch inquiries: ${error.message}`);
    } finally {
      setInquiriesLoading(false);
    }
  }, [submissionUuid]);

  useEffect(() => {
    void getInquiries();
  }, [submissionUuid, getInquiries]);

  return {
    loading: inquiriesLoading,
    inquiries,
    error: inquiriesError,
    refetch: getInquiries,
  };
};

export const toSubmission = (
  response?: SubmissionResponse
): OwnerOverviewSubmission | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    ownerUuids: response.owner_uuids,
    customerUuid: response.customer_uuid,
    type: response.type,
  };
};

export const useGetSubmission = (
  submissionUuid: string
): UseGenericQueryResponse<OwnerOverviewSubmission> => {
  return useGenericFeatureQuery(
    useGetApiSubmission,
    toSubmission,
    submissionUuid
  );
};

const toCriminalFilingsOverview = (
  response: LexisNexisResponse
): CriminalFilingsOverview => {
  return {
    ownerUuid: response.owner_uuid,
    total: response.total_criminal_count,
    last12Months: response.last_twelve_months_criminal_filing_count,
    oneToThreeYears: response.one_to_three_year_criminal_filing_count,
    threeToFiveYears: response.three_to_five_year_criminal_filing_count,
    fiveToTenYears: response.five_to_ten_year_criminal_filing_count,
    moreThanTenYears: response.ten_year_plus_criminal_filing_count,
  };
};

const toPublicRecordsOverview = (
  response: LexisNexisResponse
): PublicRecordsOverview => {
  return {
    ownerUuid: response.owner_uuid,
    openBkNumber: response.open_bankruptcy_count,
    openLiensNumber: response.open_lien_count,
    openLiensAmount: response.open_lien_amount_cents,
    openJudgmentNumber: response.open_judgment_count,
    openJudgmentAmount: response.open_judgment_amount_cents,
    closedBkNumber: response.closed_bankruptcy_count,
    closedLiensNumber: response.closed_lien_count,
    closedLiensAmount: response.closed_lien_amount_cents,
    closedJudgmentNumber: response.closed_judgment_count,
    closedJudgmentAmount: response.closed_judgment_amount_cents,
    totalBkNumber: response.total_bankruptcy_count,
    totalLiensNumber: response.total_lien_count,
    totalLiensAmount: response.total_lien_amount_cents,
    totalJudgmentNumber: response.total_judgment_count,
    totalJudgmentAmount: response.total_judgment_amount_cents,
  };
};

const toLexisNexisOverviewData = (
  response: LexisNexisResponse[]
): LexisNexisOverviewData => {
  return {
    criminalFilings: response.map(toCriminalFilingsOverview),
    publicRecords: response.map(toPublicRecordsOverview),
  };
};

export const useLexisNexisForOwners = (
  submissionUuid: string
): UseGenericQueryResponse<LexisNexisOverviewData> => {
  return useGenericFeatureQuery(
    useGetApiLexisNexisForOwners,
    (data) => data && toLexisNexisOverviewData(data),
    submissionUuid
  );
};

export const toExperianConsumer = (
  data?: ExperianConsumerResponse | ExperianConsumerResponseV2
): ExperianConsumer | undefined => {
  if (!data) {
    return undefined;
  }

  // There will only be one element for `consume_credits` when fetching with
  // submissionUuid and ownerUuid, so we can safely use element 0. Also ensure
  // that none of the properties are undefined before accessing them.
  const consumerCredit =
    data.consumer_credits &&
    Array.isArray(data.consumer_credits) &&
    data.consumer_credits.length > 0
      ? data.consumer_credits[0]
      : undefined;

  // FICO and other scores.
  const riskModels = consumerCredit?.risk_models;

  const riskModelFico = riskModels
    ? riskModels.find(({ model_name }) => model_name === 'FICO')
    : undefined;

  const riskModelBkPlus = riskModels
    ? riskModels.find(({ model_name }) => model_name === 'BankruptcyPlus')
    : undefined;

  // Note: 'VantageScore' is also available but we should be using
  // 'VantageScore3' instead per Jeff Asselin and Mike Powers.
  const riskModelVantage = riskModels
    ? riskModels.find(({ model_name }) => model_name === 'VantageScore3')
    : undefined;

  // We only use the first profile summary.
  const profileSummary = consumerCredit?.profile_summaries?.[0];
  const totalDebtToHighCredit = profileSummary
    ? 100 - profileSummary.revolving_available_percent
    : undefined;

  const messages =
    consumerCredit?.informational_messages &&
    Array.isArray(consumerCredit?.informational_messages) &&
    consumerCredit?.informational_messages?.length > 0
      ? consumerCredit?.informational_messages
          .map((message) => message.message_text)
          .join(', ')
      : undefined;

  const statements =
    consumerCredit?.consumer_statements &&
    Array.isArray(consumerCredit?.consumer_statements) &&
    consumerCredit?.consumer_statements?.length > 0
      ? consumerCredit?.consumer_statements
          .map((statement) => statement.notes)
          .join(', ')
      : undefined;

  const notes =
    messages || statements
      ? [messages, statements].filter(Boolean).join(', ')
      : undefined;

  return {
    referenceUuid: consumerCredit?.reference_uuid,
    createdAt: consumerCredit?.created_at,
    ficoScore: riskModelFico?.model_score,
    bkPlusScore: riskModelBkPlus?.model_score,
    vantageScore: riskModelVantage?.model_score,
    totalDebtToHighCredit,
    totalDelinquentTradeLines: profileSummary?.now_delinquent_derog_count,
    oldestTradeLine: profileSummary?.oldest_trade_open_date_f,
    notes,
  };
};

export const toExperianConsumerV2 = (
  consumer: ConsumerCreditV2
): ExperianConsumerCreditV2 => {
  const result: ExperianConsumerCreditV2 = {};

  if (consumer.tradelines) {
    result.tradelines = consumer.tradelines.map(toExperianTradelines);
  }

  if (consumer.risk_models) {
    result.riskModels = consumer.risk_models.map(toExperianRiskModel);
  }

  if (consumer.addresses) {
    result.addresses = consumer.addresses.map(toExperianConsumerAddress);
  }

  if (consumer.inquiries) {
    result.inquiries = consumer.inquiries.map(toExperianInquiries);
  }

  if (consumer.public_records) {
    result.publicRecords = consumer.public_records.map(toExperianPublicRecords);
  }

  if (consumer.employment_informations) {
    result.employmentInformation = consumer.employment_informations.map(
      toExperianConsumerEmployer
    );
  }

  if (consumer.profile_summaries) {
    result.profileSummaries = consumer.profile_summaries.map(
      toExperianProfileSummary
    );
  }

  return result;
};

export const toExperianProfileSummary = (
  profileSummary: ProfileSummary
): ExperianProfileSummary => ({
  revolvingAvailablePercent: profileSummary.revolving_available_percent,
  totalTradeItemsCount: profileSummary.total_trade_items_count,
  satisfactoryAccountsCount: profileSummary.satisfactory_accounts_count,
  nowDelinquentDerogCount: profileSummary.now_delinquent_derog_count,
  wasDelinquentDerogCount: profileSummary.was_delinquent_derog_count,
  paidAccounts: profileSummary.paid_accounts,
  publicRecordsCount: profileSummary.public_records_count,
  oldestTradeOpenDateF: profileSummary.oldest_trade_open_date_f,
});

export const toExperianPublicRecords = (
  publicRecord: ExperianConsumerPublicRecordResponse
): ExperianPublicRecord => ({
  courtName: publicRecord.court_name,
  courtCode: publicRecord.court_code,
  chapter: publicRecord.chapter,
  statusCode: publicRecord.status,
  ecoaCode: publicRecord.ecoa,
  filingDateF: publicRecord.filing_date_f,
  amount: publicRecord.amount,
  referenceNumber: publicRecord.reference_number,
  statusDateF: publicRecord.status_date_f,
  plaintiffName: publicRecord.plaintiff_name,
});

export const toExperianRiskModel = (
  riskModel: RiskModel
): ExperianRiskModel => ({
  modelName: riskModel.model_name,
  modelScore: riskModel.model_score,
});

export const toExperianInquiries = (inquiry: Inquiry): ExperianInquiry => ({
  inquiredOn: inquiry.inquiry_date,
  inquiryType: inquiry.type,
  /**
   * mcaLenderName is used to make this data backward compatible
   * with data from outdated endpoint. This is why we have subscriber_name twice.
   */
  mcaLenderName: inquiry.is_mca_subscriber
    ? inquiry.subscriber_name
    : undefined,
  isMcaSubscriber: inquiry.is_mca_subscriber,
  subscriberName: inquiry.subscriber_name,
});

export const toExperianTradelines = (
  tradeline: Tradeline
): ExperianTradeline => ({
  derogCounter: tradeline.derog_counter,
  isOpen: tradeline.is_open,
  delinquenciesOver30Days: tradeline.delinquencies_over_30_days,
  delinquenciesOver60Days: tradeline.delinquencies_over_60_days,
  delinquenciesOver90Days: tradeline.delinquencies_over_90_days,
  paymentHistory: tradeline.payment_history,
});

type ErrorWithStatusCode = Error & {
  statusCode?: number;
};

type UseExperianConsumerResult = UseGenericQueryResponse<ExperianConsumer> & {
  error?: ErrorWithStatusCode;
};

export const useExperianConsumer = (
  submissionUuid?: string,
  ownerUuid?: string
): UseExperianConsumerResult =>
  useGenericFeatureQuery(
    useFetchExperianConsumer,
    toExperianConsumer,
    submissionUuid,
    ownerUuid
  );

/**
 * Hook to fetch Experian Consumer V2 data with a specific entity.
 *
 * @param {string} submissionUuid - The UUID of the submission.
 * @param {string} ownerUuid - The UUID of the owner.
 * @param {ConsumerCreditEntities[]} entities - The entities to fetch (optional).
 *
 * @returns {UseExperianConsumerResultV2} - The response from the generic query, including the error with status code if any.
 *
 * @example
 * const { data, error, isLoading } = useExperianConsumerV2(submissionUuid, ownerUuid, 'inquirires');
 */
export const useExperianConsumerV2 = (
  submissionUuid: string,
  ownerUuid: string,
  entities?: ConsumerCreditEntities[]
): UseGenericQueryResponse<ExperianConsumerCreditV2> =>
  useGenericFeatureQuery(
    useFetchExperianConsumer,
    (data) =>
      data && data.consumer_credits && data.consumer_credits[0]
        ? toExperianConsumerV2(data.consumer_credits[0])
        : undefined,
    submissionUuid,
    ownerUuid,
    entities
  );

export const toExperianConsumerArchive = (
  data: ExperianConsumerArchive
): ExperianConsumerArchiveItem => {
  return {
    createdAt: data.credit_pulled_date,
    submissionUuid: data.submission_uuid,
    reportUuid: data.reference_uuid,
  };
};

export const useGetExperianConsumerArchive = (
  ownerUuid?: string
): UseGenericQueryResponse<ExperianConsumerArchiveItem[]> =>
  useGenericFeatureQuery(
    useFetchExperianConsumerArchives,
    (data) =>
      data && data.consumer_credits
        ? data.consumer_credits.map(toExperianConsumerArchive)
        : [],
    ownerUuid
  );

export const toExperianConsumerAddress = (
  address?: ConsumerAddress
): ExperianConsumerAddress => {
  const streetAddress = [
    address?.street_prefix,
    address?.street_name,
    address?.street_suffix,
  ]
    .filter(Boolean)
    .join(' ');

  return {
    streetAddress,
    city: defaultTo(address?.city, ''),
    state: defaultTo(address?.state, ''),
    zipCode: defaultTo(address?.zip, ''),
  };
};

export const toExperianConsumerEmployer = (employer?: {
  employer_name: string;
  first_reported_date: string;
  last_updated_date: string;
}): ExperianConsumerEmployer => {
  return {
    employerName: defaultTo(employer?.employer_name, ''),
    firstReportedDate: employer?.first_reported_date,
    lastUpdatedDate: employer?.last_updated_date,
  };
};

export const toExperianConsumerPublicRecord = ({
  RecordType,
  court_name,
  court_code,
  chapter,
  status,
  ecoa,
  filing_date_f,
  amount,
  reference_number,
  status_date_f,
  plaintiff_name,
}: ExperianConsumerPublicRecordResponse): ExperianConsumerPublicRecord => {
  // Try to convert the amount to a whole number, but if not possible, leave
  // it as the original string. It can have values like "Not Available", which
  // should be left as-is. Or values like "00044210" which should be converted
  // to a whole number. I.e. 44210, which will be formatted by the component as
  // "$44,210".
  const amountNumber = Number(amount);
  const amountFormatted = isNaN(amountNumber)
    ? amount
    : amountNumber.toString();

  return {
    recordType: defaultTo(RecordType, ''),
    courtName: defaultTo(court_name, ''),
    courtCode: defaultTo(court_code, ''),
    chapter: defaultTo(chapter, ''),
    status: defaultTo(status, ''),
    ecoa: defaultTo(ecoa, ''),
    filingDate: filing_date_f,
    amount: defaultTo(amountFormatted, ''),
    referenceNumber: defaultTo(reference_number, ''),
    statusDate: status_date_f,
    plaintiffName: defaultTo(plaintiff_name, ''),
  };
};

export const toExperianConsumerDetail = (
  data?: ExperianConsumerCreditReportResponse
): ExperianConsumerDetailInfo | undefined => {
  if (!data || !data.consumer_credit) {
    return undefined;
  }

  const consumerIdentities = data?.consumer_credit?.consumer_identities;
  const consumerIdentity = consumerIdentities?.[0];

  // Name
  const fullName = [
    consumerIdentity?.first_name,
    consumerIdentity?.middle_name,
    consumerIdentity?.surname,
  ]
    .filter(Boolean)
    .join(' ');

  // Addresses
  const firstAddress = data?.consumer_credit?.addresses?.[0];
  const remainingAddresses = data?.consumer_credit?.addresses?.slice(1);

  const address = toExperianConsumerAddress(firstAddress);
  const otherAddresses =
    remainingAddresses?.map(toExperianConsumerAddress) || [];

  // Employers
  const latestEmployer = data?.consumer_credit?.employment_informations?.[0];

  const remainingEmployers =
    data?.consumer_credit?.employment_informations?.slice(1);

  const employer = toExperianConsumerEmployer(latestEmployer);
  const otherEmployers =
    remainingEmployers?.map(toExperianConsumerEmployer) || [];

  const publicRecords =
    data?.consumer_credit?.public_records?.map(
      toExperianConsumerPublicRecord
    ) || [];

  const informationalMessages =
    data?.consumer_credit?.informational_messages?.map((message) => ({
      messageText: message.message_text,
    })) || [];

  return {
    name: fullName,
    address,
    employer,
    otherAddresses,
    otherEmployers,
    publicRecords,
    informationalMessages,
  };
};

export const useGetExperianConsumerDetail = (
  referenceUuid?: string
): UseGenericQueryResponse<ExperianConsumerDetailInfo> =>
  useGenericFeatureQuery(
    useFetchExperianConsumerCreditReport,
    toExperianConsumerDetail,
    referenceUuid
  );

const ENTITIES_TO_REQUEST_FOR_SUMMARY: ConsumerCreditEntities[] = [
  'inquiries',
  'tradelines',
  'profile_summaries',
];

export const useExperianConsumerV2Summary = (
  submissionUuid: string,
  ownerUuid: string
): UseGenericQueryResponse<
  Omit<ExperianConsumerCreditV2, 'tradelines'> & {
    tradelines?: TradelinesOverview;
  }
> => {
  const { data, ...rest } = useExperianConsumerV2(
    submissionUuid,
    ownerUuid,
    ENTITIES_TO_REQUEST_FOR_SUMMARY
  );

  const tradelinesSummary = data?.tradelines?.length
    ? toTradelinesSummary(data.tradelines, ownerUuid, data.profileSummaries)
    : undefined;

  return { ...rest, data: { ...data, tradelines: tradelinesSummary } };
};

const toTradelinesSummary = (
  tradelines: ExperianTradeline[],
  ownerUuid: string,
  profileSummaries?: ExperianProfileSummary[]
): TradelinesOverview => {
  const openTradelines = tradelines.filter((tradeLine) => tradeLine.isOpen);

  const current30Days = openTradelines
    .filter((tradeline) => tradeline.paymentHistory.at(0) === '1')
    .length.toString();
  const current60Days = openTradelines
    .filter((tradeline) => tradeline.paymentHistory.at(0) === '2')
    .length.toString();
  const current90Days = openTradelines
    .filter((tradeline) => tradeline.paymentHistory.at(0) === '3')
    .length.toString();
  const currentDerog = openTradelines
    .filter(
      (tradeline) =>
        tradeline.paymentHistory.at(0) &&
        ['4', '5', '6', 'G'].includes(tradeline.paymentHistory.at(0) as string)
    )
    .length.toString();

  const allTime30Days = tradelines
    .reduce((res, tradeLine) => {
      return res + defaultTo(tradeLine.delinquenciesOver30Days, 0);
    }, 0)
    .toString();
  const allTime60Days = tradelines
    .reduce((res, tradeLine) => {
      return res + defaultTo(tradeLine.delinquenciesOver60Days, 0);
    }, 0)
    .toString();
  const allTime90Days = tradelines
    .reduce((res, tradeLine) => {
      return res + defaultTo(tradeLine.delinquenciesOver90Days, 0);
    }, 0)
    .toString();

  const allTimeDerog = (
    profileSummaries && profileSummaries[0]
      ? defaultTo(profileSummaries[0].nowDelinquentDerogCount, 0)
      : 0
  ).toString();

  return {
    current30Days,
    current60Days,
    current90Days,
    currentDerog,
    allTime30Days,
    allTime60Days,
    allTime90Days,
    allTimeDerog,
    ownerUuid,
  };
};
