import React from 'react';

import { PublicRecordsInterface } from './codecs/PublicRecords.codec';
import {
  Accordion,
  Box,
  Table,
  Currency,
  formatDateString,
} from '@forward-financing/fast-forward';

export const countPublicRecords = (
  data: PublicRecordsProps['data']
): number => {
  let publicRecordsCount = 0;
  if (data.judgments) {
    publicRecordsCount += data.judgments.length;
  }
  if (data.bankruptcies) {
    publicRecordsCount += data.bankruptcies.length;
  }
  if (data.liens) {
    publicRecordsCount += data.liens.length;
  }
  return publicRecordsCount;
};

export interface PublicRecordsProps {
  data: PublicRecordsInterface;
}

export const PublicRecords = ({ data }: PublicRecordsProps): JSX.Element => {
  return (
    <Box>
      <Accordion
        type={'multiple'}
        defaultValue={['bankruptcy', 'tax-liens', 'judgments']}
      >
        <Accordion.Item value="bankruptcy">
          <Accordion.Trigger>Bankruptcy</Accordion.Trigger>
          <Accordion.Content>
            <Table caption={'Bankruptcy Table'}>
              <Table.Head>
                <Table.ColumnHeader>File Date</Table.ColumnHeader>
                <Table.ColumnHeader>Status</Table.ColumnHeader>
                <Table.ColumnHeader>Liability Amount</Table.ColumnHeader>
                <Table.ColumnHeader>Asset Amount</Table.ColumnHeader>
                <Table.ColumnHeader>Exempt Amount</Table.ColumnHeader>
                <Table.ColumnHeader>Owner</Table.ColumnHeader>
                <Table.ColumnHeader>Filing Number</Table.ColumnHeader>
                <Table.ColumnHeader>Jurisdiction</Table.ColumnHeader>
              </Table.Head>
              {data.bankruptcies.map((bankruptcy) => (
                <Table.Row key={bankruptcy.file_date}>
                  <Table.Cell>
                    {formatDateString(bankruptcy.file_date)}
                  </Table.Cell>
                  <Table.Cell>{bankruptcy.status}</Table.Cell>
                  <Table.Cell>
                    <Currency amount={bankruptcy.liability_amount} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={bankruptcy.asset_amount} />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency amount={bankruptcy.exempt_amount} />
                  </Table.Cell>
                  <Table.Cell>{bankruptcy.owner}</Table.Cell>
                  <Table.Cell>{bankruptcy.filing_number}</Table.Cell>
                  <Table.Cell>{bankruptcy.jurisdiction}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value="tax-liens">
          <Accordion.Trigger>Tax Liens</Accordion.Trigger>
          <Accordion.Content>
            <Table caption={'Tax Liens Table'}>
              <Table.Head>
                <Table.ColumnHeader>File Date</Table.ColumnHeader>
                <Table.ColumnHeader>Filing Type</Table.ColumnHeader>
                <Table.ColumnHeader>Status</Table.ColumnHeader>
                <Table.ColumnHeader>Amount</Table.ColumnHeader>
                <Table.ColumnHeader>Filing Number</Table.ColumnHeader>
                <Table.ColumnHeader>Jurisdiction</Table.ColumnHeader>
              </Table.Head>
              {data.liens.map((lien) => (
                <Table.Row key={lien.file_date}>
                  <Table.Cell>{formatDateString(lien.file_date)}</Table.Cell>
                  <Table.Cell>{lien.filing_type}</Table.Cell>
                  <Table.Cell>{lien.status}</Table.Cell>
                  <Table.Cell>
                    <Currency amount={lien.amount} />
                  </Table.Cell>
                  <Table.Cell>{lien.filing_number}</Table.Cell>
                  <Table.Cell>{lien.jurisdiction}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value="judgments">
          <Accordion.Trigger>Judgments</Accordion.Trigger>
          <Accordion.Content>
            <Table caption={'Judgments Table'}>
              <Table.Head>
                <Table.ColumnHeader>File Date</Table.ColumnHeader>
                <Table.ColumnHeader>Status</Table.ColumnHeader>
                <Table.ColumnHeader>Amount</Table.ColumnHeader>
                <Table.ColumnHeader>Plaintiff</Table.ColumnHeader>
                <Table.ColumnHeader>Filing Number</Table.ColumnHeader>
                <Table.ColumnHeader>Jurisdiction</Table.ColumnHeader>
              </Table.Head>
              {data.judgments?.map((judgment) => (
                <Table.Row key={judgment.file_date}>
                  <Table.Cell>
                    {formatDateString(judgment.file_date)}
                  </Table.Cell>
                  <Table.Cell>{judgment.status}</Table.Cell>
                  <Table.Cell>
                    <Currency amount={judgment.amount} />
                  </Table.Cell>
                  <Table.Cell>{judgment.plaintiff}</Table.Cell>
                  <Table.Cell>{judgment.filing_number}</Table.Cell>
                  <Table.Cell>{judgment.jurisdiction}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};
