import { FFLogger } from '../../../api/LogClient';
import React, { useEffect, useState } from 'react';
import { Flex } from '@forward-financing/fast-forward';
import InputMask from 'react-input-mask';
import { ValidationResult } from '../../../helpers/validations/codecs';
import { Button } from './Button';
import { ValidationMessage } from './ValidationMessage';
import { toError } from 'helpers/errorUtils';

export interface InputProps {
  label: string;
  name: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  validationResult?: ValidationResult;
  inputMask?: string;
  leadingIcon?: string;
  checkBox?: {
    label: string;
    name: string;
    value: boolean;
    checkboxLabelSize: string;
    onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  };
  moreInfoPopover?: React.ReactNode;
  required?: boolean;
  isDisabled?: boolean;
  shouldBeMasked?: boolean;
  handleSendLogs?: (field_name: string) => Promise<void>;
}
type SensitiveValueState = 'hidden' | 'fetching' | 'revealed';

export const Input = (props: InputProps): JSX.Element => {
  const { handleSendLogs, name } = props;

  const [valueState, setValueState] = useState<SensitiveValueState>(
    props.value === '' ? 'revealed' : 'hidden'
  );
  const shouldBeMasked = valueState !== 'revealed' && props.shouldBeMasked;
  const [maskedInputComplete, setMaskedInputComplete] = useState(true);
  const isEmpty = props.validationResult && !props.validationResult.hasValue;
  const isInvalid = props.validationResult && !props.validationResult.isValid;
  const className = isInvalid || isEmpty ? 'input is-danger' : 'input';
  const hasIconClass = props.leadingIcon ? 'has-icons-left' : '';
  const checkboxLabelSize =
    props.checkBox && props.checkBox.checkboxLabelSize
      ? props.checkBox.checkboxLabelSize
      : 'is-2';
  const isDisabled = props.isDisabled || false;

  const renderValidationIcon = (): JSX.Element | null => {
    return isEmpty ? (
      <span className="validation-icon">
        <i className="fas fa-exclamation-circle " />
      </span>
    ) : null;
  };

  useEffect(() => {
    let mounted = true;

    if (valueState === 'fetching') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handleSendLogs?.(name)
        .then(() => {
          if (mounted) {
            setValueState('revealed');
          }
        })
        .catch((e: unknown) => {
          const error = toError(e);
          // show error
          FFLogger.error(error);
        });
    }

    const cleanup = (): void => {
      mounted = false;
    };

    return cleanup;
  }, [valueState, handleSendLogs, name]);

  const revealValue = (): void => {
    if (!props.name) {
      return;
    }
    setValueState('fetching');
    setMaskedInputComplete(false);
  };

  const shouldRenderShowButton = props.value !== '' && shouldBeMasked;

  const maskValue = shouldBeMasked
    ? props.value?.replace(/[a-z0-9]/gi, 'X')
    : '';

  const setMask = (): void => {
    if (
      !props.shouldBeMasked ||
      (props.validationResult && !props.validationResult.isValid)
    ) {
      return;
    }
    setValueState('hidden');
    props.value !== '' && setMaskedInputComplete(true);
  };

  const maskShouldBeDisabled =
    valueState === 'hidden' &&
    props.value !== '' &&
    props.shouldBeMasked &&
    maskedInputComplete;

  return (
    <div className="field">
      <div className="columns">
        <div className="column">
          <label className={`label has-text-weight-bold`}>
            <Flex>
              {renderValidationIcon()}
              {props.label}
              {props.moreInfoPopover}
            </Flex>
          </label>
        </div>
        {props.checkBox && (
          <div
            className={`column is-three-fifths has-text-right ${checkboxLabelSize}`}
          >
            <label className="checkbox">
              <span>{props.checkBox.label} </span>
              <input
                name={props.checkBox.name}
                type="checkbox"
                onClick={props.checkBox.onClick}
                defaultChecked={props.checkBox.value}
              />
            </label>
          </div>
        )}
      </div>

      <div className="control ">
        <p className={`control ${hasIconClass}`}>
          <InputMask
            aria-label={props.label}
            className={className}
            name={props.name}
            value={
              shouldBeMasked && maskedInputComplete ? maskValue : props.value
            }
            placeholder={props.placeholder}
            mask={shouldBeMasked ? '' : props.inputMask || ''}
            onChange={props.onChange}
            required={props.required}
            disabled={isDisabled || maskShouldBeDisabled}
            autoComplete="disabled"
            onBlur={setMask}
          />
          {shouldRenderShowButton && (
            <Button
              buttonColor={'transparent-button-black'}
              text={'Show'}
              onClick={revealValue}
              buttonClass={`show-masked-value-button ${props.name}`}
            />
          )}
          {props.leadingIcon && (
            <span className="icon is-small is-left">
              <i className={props.leadingIcon} />
            </span>
          )}
          <ValidationMessage validationResult={props.validationResult} />
        </p>
      </div>
    </div>
  );
};
