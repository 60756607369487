import { SubmissionNewDealCompositeScoreResponse } from 'types/api/underwriting/types';
import { useApiRefreshCompositeScore } from 'apiHooks/underwriting/submissionFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { NewDealScoreComposite } from '../DealScoring.types';

const toNewDealScoreComposite = (
  newDealCompositeScore?: SubmissionNewDealCompositeScoreResponse
): NewDealScoreComposite | undefined => {
  if (!newDealCompositeScore) {
    return undefined;
  }

  return {
    type: 'New Deal',
    industryRisk: {
      score: newDealCompositeScore.industry_risk?.score,
      value: newDealCompositeScore.industry_risk?.value,
      industry: newDealCompositeScore.industry_risk?.industry,
    },
    position: {
      score: newDealCompositeScore.position?.score,
      value: newDealCompositeScore.position?.value,
    },
    fico: {
      score: newDealCompositeScore.fico?.score,
      value: newDealCompositeScore.fico?.value,
    },
    timeInBusiness: {
      score: newDealCompositeScore.time_in_business?.score,
      value: newDealCompositeScore.time_in_business?.value,
    },
    ...(newDealCompositeScore?.risk_decile && {
      riskDecile: {
        score: newDealCompositeScore.risk_decile?.score,
        value: newDealCompositeScore.risk_decile?.value,
      },
    }),
    ...(newDealCompositeScore?.risk_score && {
      riskScore: {
        score: newDealCompositeScore.risk_score?.score,
        value: newDealCompositeScore.risk_score?.value,
      },
    }),
    trueRevenue: {
      score: newDealCompositeScore.true_revenue?.score,
      value: newDealCompositeScore.true_revenue?.value,
    },
    ...(newDealCompositeScore.composite_score && {
      compositeScore: {
        score: newDealCompositeScore.composite_score?.score,
        tier: newDealCompositeScore.composite_score?.tier,
      },
      updatedAt: newDealCompositeScore.updated_at,
    }),
  };
};

type UseRefreshCompositeScoreResult = [
  () => Promise<MutationResponse>,
  {
    newDealScoreCompositeRefreshed: NewDealScoreComposite | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

export const useRefreshNewDealCompositeScore = (
  submissionUuid: string
): UseRefreshCompositeScoreResult => {
  const [
    refreshCompositeScoreFunction,
    { data, error, loading, responseReady },
  ] = useApiRefreshCompositeScore();

  const refreshCompositeScore = async (): Promise<MutationResponse> => {
    return await refreshCompositeScoreFunction(submissionUuid);
  };

  const score = toNewDealScoreComposite(
    data as SubmissionNewDealCompositeScoreResponse
  );
  return [
    refreshCompositeScore,
    { newDealScoreCompositeRefreshed: score, error, loading, responseReady },
  ];
};
