import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  LexisNexisCustomerOverview,
  LexisNexisCustomerReportResponse,
} from 'types/api/underwriting/types';

export const fetchLexisNexisCustomerReports = async (
  submissionUuid: string
): Promise<LexisNexisCustomerReportResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/lexis_nexis_customer_reports`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<LexisNexisCustomerReportResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Lexis Nexis Customer Reports'
    );
  }

  return response.json();
};

export const fetchLexisNexisCustomerOverviews = async (
  submissionUuid: string
): Promise<LexisNexisCustomerOverview[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/lexis_nexis_customer_overviews`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LexisNexisCustomerOverview[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Lexis Nexis Customer Overviews'
    );
  }

  return response.json();
};
