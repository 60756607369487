import { underwriterAssignNextDeal } from 'api/banking/underwriterNextDealFetchUtils';
import {
  UseGenericMutationResponse,
  useGenericMutation,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { NextDealResponse } from 'types/api/banking/types';

export type UseApiUnderwriterNextDealResponse = UseGenericMutationResponse<
  NextDealResponse,
  void
>;

export const useApiUnderwriterNextDeal =
  (): UseApiUnderwriterNextDealResponse => {
    const loadData = useCallback(() => {
      return underwriterAssignNextDeal();
    }, []);

    return useGenericMutation(loadData);
  };
