import React from 'react';
import {
  Box,
  Flex,
  formatDateString,
  Link,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { UccRecord } from './types';

export type UccTableProps = {
  uccs: UccRecord[];
  reportId: number;
  submissionUuid: string;
  ownerUuid: string;
  customerUuid?: string;
};

const isActiveUcc = (ucc: UccRecord): boolean =>
  !ucc.isExpired && !ucc.isReleased;

export const UccTable = ({
  uccs,
  reportId,
  submissionUuid,
  ownerUuid,
  customerUuid,
}: UccTableProps): JSX.Element => {
  const activeUccs = uccs.filter(isActiveUcc);

  const reportUrl =
    featureFlags.use_new_owner_ucc && customerUuid
      ? new URL(
          `dashboard/submissions/${submissionUuid}/owners/${ownerUuid}/customers/${customerUuid}/ucc_report/${reportId}`,
          window.location.origin
        )
      : new URL(`/admin/ucc_documents/${reportId}`, UNDERWRITING_BASE_URL());

  return (
    <>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">UCC Documents</Subheading>
        {uccs.length > 0 && (
          <Link newTab href={reportUrl}>
            View Report
          </Link>
        )}
      </Flex>
      {uccs.length === 0 ? (
        <Box mb={3}>
          <Text>There are no UCC Documents</Text>
        </Box>
      ) : (
        <Box mb={3}>
          <Text>
            Active: {activeUccs.length}; Closed:{' '}
            {uccs.length - activeUccs.length}
          </Text>
          <Table>
            <Table.Head>
              <Table.ColumnHeader>Secured Party</Table.ColumnHeader>
              <Table.ColumnHeader>Date Filed</Table.ColumnHeader>
              <Table.ColumnHeader>Collateral Description</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              {activeUccs.map((u) => (
                <Table.Row
                  key={`${u.securedParty}_${u.dateFiled}_${u.collateralDescription}`}
                >
                  <Table.Cell columnWidth="small">{u.securedParty}</Table.Cell>
                  <Table.Cell columnWidth="small">
                    {u.dateFiled && formatDateString(u.dateFiled)}
                  </Table.Cell>
                  <Table.Cell columnWidth="large" overflowStrategy="wrap">
                    {u.collateralDescription}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
      )}
    </>
  );
};
