import { FetchResponse } from 'api/codecs';
import { AuthClient } from '../AuthClient';
import { BANK_BASE_URL } from '../../constants/globals';
import { AuthenticationHeaders } from '../AuthClient/codecs';
import { AcceptedOffer } from './codecs';

export class BankingClient extends AuthClient {
  private static BASE_URL = BANK_BASE_URL();

  static fetchAcceptedOffer = async (
    opportunityUuid: string
  ): Promise<AcceptedOffer> => {
    const getAcceptedOffer = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<AcceptedOffer>> => {
      const url = `${BankingClient.BASE_URL}api/v2/opportunities/${opportunityUuid}/accepted_offer/`;

      return BankingClient.get<AcceptedOffer>(url, {
        headers: authenticationHeaders,
      });
    };

    const response = await BankingClient.authenticateApiRequest<AcceptedOffer>(
      getAcceptedOffer
    );

    return response.json();
  };
}
