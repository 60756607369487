import {
  Flex,
  Heading,
  PageContainer,
  Text,
  Box,
  Loading,
  Banner,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import {
  useGetOneTimePayments,
  useGetBatchPayments,
} from './adminPaymentContainerHooks';

export const AdminPaymentContainer = (): JSX.Element => {
  // TODO address setting the payment pages in follow up PR
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [batchPaymentPage, setBatchPaymentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [oneTimePaymentPage, setOneTimePaymentPage] = useState(1);
  const {
    data: oneTimePayments,
    loading: oneTimePaymentsLoading,
    error: oneTimePaymentsError,
  } = useGetOneTimePayments(oneTimePaymentPage);
  const {
    data: batchPayments,
    loading: batchPaymentsLoading,
    error: batchPaymentsError,
  } = useGetBatchPayments(batchPaymentPage);

  const loading = oneTimePaymentsLoading || batchPaymentsLoading;

  return (
    <PageContainer>
      <Heading>Payments</Heading>

      <Flex flexDirection="column" gap={2}>
        {loading && <Loading />}
        {oneTimePaymentsError && (
          <Flex justifyContent="center">
            <Banner>{oneTimePaymentsError.message}</Banner>
          </Flex>
        )}
        {batchPaymentsError && (
          <Flex justifyContent="center">
            <Banner>{batchPaymentsError.message}</Banner>
          </Flex>
        )}
        {batchPayments && (
          <Box>
            <Text>{JSON.stringify(batchPayments)}</Text>
          </Box>
        )}
        {oneTimePayments && (
          <Box>
            <Text>{JSON.stringify(oneTimePayments)}</Text>
          </Box>
        )}
      </Flex>
    </PageContainer>
  );
};
