import { toError } from 'helpers/errorUtils';
import { useState, useCallback } from 'react';
import { HttpHook, UseHttpQuery } from './codecs/HttpHook';

// useHttpQuery takes any queryFn which basically is any function that returns a promise
// and turns it into a hook to use in React functional components.
export const useHttpQuery = <QueryInput, ResponseData>({
  queryFn,
}: UseHttpQuery<QueryInput, ResponseData>): HttpHook<
  QueryInput,
  ResponseData | undefined
> => {
  const [data, setData] = useState<ResponseData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [called, setCalled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleError = (err: unknown): void => {
    const error = toError(err);
    setLoading(false);
    setError(error.toString());
  };

  const query = useCallback(
    (variables: QueryInput): void => {
      setLoading(true);
      setCalled(true);
      queryFn(variables)
        .then((response) => {
          setLoading(false);
          setData(response);
        })
        .catch(handleError);
    },
    [queryFn]
  );

  return {
    data,
    called,
    loading,
    error,
    query,
  };
};
