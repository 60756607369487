import {
  Banner,
  Heading,
  Loading,
  PageContainer,
  Text,
} from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { featureFlags } from 'helpers/featureFlags';
import { ConsumerCreditEntities } from 'types/api/3pi/types';
import { InquiriesTable } from './OwnerOverview/InquiriesTable';
import {
  useExperianConsumerV2,
  useGetOwnerInquiries,
} from './OwnerOverview/ownerOverviewHooks';

type Params = {
  submissionUuid: string;
  ownerUuid: string;
};

const EXPERIAN_ENTITIES_TO_REQUEST: ConsumerCreditEntities[] = ['inquiries'];

export const CreditInquiriesContainer = (): JSX.Element => {
  const { submissionUuid, ownerUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }

  if (!ownerUuid) {
    throw new Error('Owner UUID Param not set');
  }

  const { inquiries, loading, error } = useGetOwnerInquiries(submissionUuid);

  const ownerInquiries = inquiries?.filter(
    (inquiry) => inquiry.ownerUuid === ownerUuid
  );

  const {
    data: dataV2,
    loading: loadingV2,
    error: errorV2,
  } = useExperianConsumerV2(
    submissionUuid,
    ownerUuid,
    EXPERIAN_ENTITIES_TO_REQUEST
  );

  const isUsingNewExperianEndpoint =
    featureFlags.experian_consumer_3pi_inquiries_detail;

  const isLoading = isUsingNewExperianEndpoint ? loadingV2 : loading;
  const errorData = isUsingNewExperianEndpoint ? errorV2?.message : error;
  const inquiriesData = isUsingNewExperianEndpoint
    ? dataV2?.inquiries
    : ownerInquiries;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      {/* TODO: Add Breadcrumbs */}
      <Heading>Credit Inquiries</Heading>

      {errorData && <Banner dismissable={false}>{errorData}</Banner>}

      {inquiriesData && inquiriesData.length > 0 ? (
        <InquiriesTable inquiries={inquiriesData} />
      ) : (
        <Text>This owner has no Credit Inquiries</Text>
      )}
    </PageContainer>
  );
};
