import React from 'react';
import {
  Address as AddressComponent,
  Divider,
  Flex,
  formatDateString,
  formatPhoneNumber,
  Link,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { differenceInYears } from 'date-fns';

import { gql } from '__generated__';
import {
  Address,
  BasicOwnerInfoTableFragmentFragment,
} from '__generated__/graphql';
import { InternalLink } from 'components/shared/InternalLink';
import { featureFlags } from 'helpers/featureFlags';
import { addressToString } from '../../../helpers/addressUtils';
import { SocialSecurityNumberDisplay } from './SocialSecurityNumberDisplay';

export const BasicOwnerInfoTableFragment = gql(`
  fragment BasicOwnerInfoTableFragment on Owner {
    id
    fullName
    ssn
    ssnLastFour
    birthdate
    address {
      street1
      street2
      city
      state
      zip
    }
    workPhone
    homePhone
    cellPhone
    email
    ownershipDate
    ownershipPercentage
  }
`);

export interface BasicOwnerInfoTableProps {
  currentUserCanSeePartialSsn: boolean;
  currentUserCanSeeDateOfBirth: boolean;
  owner?: BasicOwnerInfoTableFragmentFragment | null;
  lexisNexisReportUrl?: URL;
  lexisNexisOwnerPageUrl?: string;
}

const buildGoogleSearchAddressUrl = (address: Address): URL => {
  const url = new URL('https://google.com/search');
  url.searchParams.append(
    'q',
    addressToString({
      street1: address.street1 ?? '',
      street2: address.street2 ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      zip: address.zip ?? '',
    })
  );

  return url;
};

const formatOwnerDob = (dob: string): string => {
  if (!dob) {
    return ''; // Return empty if no birthdate provided;
  }
  const birthDate = new Date(dob);
  const today = new Date();
  return `${formatDateString(dob)} (Age: ${differenceInYears(
    today,
    birthDate
  )})`;
};

const buildGoogleSearchNameUrl = (
  fullName: string,
  city?: string | null,
  state?: string | null,
  arrest?: boolean
): URL => {
  const url = new URL('https://google.com/search');
  url.searchParams.append(
    'q',
    `${fullName} ${arrest ? '' : city} ${state}${arrest ? ' Arrest' : ''}`
  );

  return url;
};

export const BasicOwnerInfoTable = (
  props: BasicOwnerInfoTableProps
): JSX.Element => {
  if (!props.owner) {
    return <></>;
  }
  const { address } = props.owner;

  return (
    <>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Owner Basic Information</Subheading>
        <Flex>
          {featureFlags.use_owner_ln_page
            ? props.lexisNexisOwnerPageUrl && (
                <>
                  <InternalLink
                    target="_blank"
                    to={props.lexisNexisOwnerPageUrl}
                  >
                    Lexis Nexis
                  </InternalLink>
                  <Divider orientation="vertical" />
                </>
              )
            : props.lexisNexisReportUrl && (
                <>
                  <Link newTab href={props.lexisNexisReportUrl}>
                    LexisNexis
                  </Link>
                  <Divider orientation="vertical" />
                </>
              )}

          <Link
            newTab
            href={buildGoogleSearchNameUrl(
              props.owner.fullName,
              props.owner.address?.city,
              props.owner.address?.state
            )}
          >
            Google
          </Link>
          <Divider orientation="vertical" />
          <Link
            newTab
            href={buildGoogleSearchNameUrl(
              props.owner.fullName,
              props.owner.address?.city,
              props.owner.address?.state,
              true
            )}
          >
            Criminal
          </Link>
        </Flex>
      </Flex>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>Name</Table.RowHeader>
            <Table.Cell>{props.owner.fullName}</Table.Cell>
          </Table.Row>
          {props.currentUserCanSeeDateOfBirth && (
            <Table.Row>
              <Table.RowHeader>Date of Birth</Table.RowHeader>
              <Table.Cell>
                {props.owner.birthdate && formatOwnerDob(props.owner.birthdate)}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.RowHeader abbr="Social Security Number">SSN</Table.RowHeader>
            <Table.Cell>
              <SocialSecurityNumberDisplay
                ssn={props.owner.ssn}
                ssnLastFour={props.owner.ssnLastFour}
                canSeePartialSsn={props.currentUserCanSeePartialSsn}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Address</Table.RowHeader>
            <Table.Cell>
              {address && (
                <Link newTab href={buildGoogleSearchAddressUrl(address)}>
                  <AddressComponent
                    streetAddress={[address.street1, address.street2]}
                    city={address.city ?? ''}
                    state={address.state ?? ''}
                    zipCode={address.zip ?? ''}
                    isSingleLine
                  />
                </Link>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>State Resides</Table.RowHeader>
            <Table.Cell>{props.owner.address?.state}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Work Phone</Table.RowHeader>
            <Table.Cell>
              {props.owner.workPhone &&
                formatPhoneNumber(props.owner.workPhone)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Home Phone</Table.RowHeader>
            <Table.Cell>
              {props.owner.homePhone &&
                formatPhoneNumber(props.owner.homePhone)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Cell Phone</Table.RowHeader>
            <Table.Cell>
              {props.owner.cellPhone &&
                formatPhoneNumber(props.owner.cellPhone)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Email Address</Table.RowHeader>
            <Table.Cell>{props.owner.email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Acquired Business</Table.RowHeader>
            <Table.Cell>
              {props.owner.ownershipDate &&
                formatDateString(props.owner.ownershipDate)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
