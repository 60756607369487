import React from 'react';

import {
  Flex,
  formatCurrency,
  Icon,
  Text,
} from '@forward-financing/fast-forward';
import { percentageDifference } from '../../../helpers/utils';

export type ChangeComparisonProps = {
  currentValue: number;
  previousValue: number;
};

export const ChangeComparison = ({
  currentValue,
  previousValue,
}: ChangeComparisonProps): JSX.Element => {
  const diff = `${percentageDifference(currentValue, previousValue)}%`;

  // This render logic for direction and negative usage is specific for the renewal comparison
  // because of the displayed fields nature (debt and revenue)
  if (currentValue > previousValue) {
    return (
      <Flex gap={2} alignItems="center">
        <Text>
          {formatCurrency(currentValue)} ({diff})
        </Text>
        <Icon color="primary" name="arrow-trend-up" />
      </Flex>
    );
  }

  if (currentValue < previousValue) {
    return (
      <Flex gap={2} alignItems="center">
        <Text color="danger">
          {formatCurrency(currentValue)} ({diff})
        </Text>
        <Icon color="danger" name="arrow-trend-down" />
      </Flex>
    );
  }

  // if there's no change we only display the formatted current value
  return <Text>{formatCurrency(currentValue)}</Text>;
};
