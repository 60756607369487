import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { UccEntity, UccReport } from './uccReportPage.types';

export type UccReportTableProps = {
  uccReports?: UccReport[];
};

const uccEntityToCell = (entity: UccEntity): JSX.Element => {
  return (
    <Box mb={3}>
      <Text>{entity.name}</Text>
      <Text>{entity.address}</Text>
    </Box>
  );
};

export const UccReportTable = ({
  uccReports,
}: UccReportTableProps): JSX.Element => {
  const hasUccReports = uccReports && uccReports.length > 0;

  return hasUccReports ? (
    <Table>
      <Table.Head>
        <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
        <Table.ColumnHeader>Secured Parties</Table.ColumnHeader>
        <Table.ColumnHeader>Debtors</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
        <Table.ColumnHeader>File Type</Table.ColumnHeader>
        <Table.ColumnHeader>Origin File Number</Table.ColumnHeader>
        <Table.ColumnHeader>State</Table.ColumnHeader>
        <Table.ColumnHeader>Collateral Description</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {uccReports?.map((uccReport) => (
          <Table.Row key={`${uccReport.filingDate}-${uccReport.fileNumber}`}>
            <Table.Cell>{uccReport.filingDate}</Table.Cell>
            <Table.Cell>
              {uccReport.securedParties.map(uccEntityToCell)}
            </Table.Cell>
            <Table.Cell>{uccReport.debtors.map(uccEntityToCell)}</Table.Cell>
            <Table.Cell>{uccReport.status}</Table.Cell>
            <Table.Cell>{uccReport.fileType}</Table.Cell>
            <Table.Cell>{uccReport.fileNumber}</Table.Cell>
            <Table.Cell>{uccReport.state}</Table.Cell>
            <Table.Cell>{uccReport.collateralDescription}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Text>No UCC reports available</Text>
  );
};
