import React from 'react';
import { Box, Card, Grid } from '@forward-financing/fast-forward';
import { PricingStrategyProgramTermTable } from './PricingStrategyProgramTermTable';

// These entries are currently hard coded data that needs to be displayed
// In the near future these will be transitioned to pull the data from an API
const pricingStrategyProgramTermLowRiskData = [
  {
    id: 'lowr-1',
    score: '2.0 - 2.1',
    term: '3',
    program: 'Starter',
  },
  {
    id: 'lowr-2',
    score: '2.1 - 2.2',
    term: '4',
    program: 'Starter',
  },
  {
    id: 'lowr-3',
    score: '2.2 - 2.3',
    term: '5',
    program: 'Starter',
  },
  {
    id: 'lowr-4',
    score: '2.3 - 2.6',
    term: '6',
    program: 'Core',
  },
  {
    id: 'lowr-5',
    score: '2.6 - 3.0',
    term: '7',
    program: 'Standard',
  },
  {
    id: 'lowr-6',
    score: '3.0 - 3.3',
    term: '8',
    program: 'Low Risk',
  },
  {
    id: 'lowr-7',
    score: '3.3 - 3.7',
    term: '9',
    program: 'Low Risk',
  },
  {
    id: 'lowr-8',
    score: '3.7 - 4.2',
    term: '10',
    program: 'Premium',
  },
  {
    id: 'lowr-9',
    score: '4.2 - 4.6',
    term: '11',
    program: 'Premium',
  },
  {
    id: 'lowr-10',
    score: '4.6...',
    term: '12',
    program: 'Premium',
  },
];

const pricingStrategyProgramTermModerateRiskData = [
  {
    id: 'modr-1',
    score: '2.0 - 2.2',
    term: '3',
    program: 'Starter',
  },
  {
    id: 'modr-2',
    score: '2.3',
    term: '4',
    program: 'Starter',
  },
  {
    id: 'modr-3',
    score: '2.3 - 2.4',
    term: '4',
    program: 'Core',
  },
  {
    id: 'modr-4',
    score: '2.4 - 2.7',
    term: '5',
    program: 'Core',
  },
  {
    id: 'modr-5',
    score: '2.7 - 2.9',
    term: '6',
    program: 'Standard',
  },
  {
    id: 'modr-6',
    score: '2.9 - 3.2',
    term: '7',
    program: 'Standard',
  },
  {
    id: 'modr-7',
    score: '3.2 - 3.5',
    term: '8',
    program: 'Low Risk',
  },
  {
    id: 'modr-8',
    score: '3.5 - 3.9',
    term: '9',
    program: 'Low Risk',
  },
  {
    id: 'modr-9',
    score: '3.9 - 4.5',
    term: '10',
    program: 'Premium',
  },
  {
    id: 'modr-10',
    score: '4.5 - 4.8',
    term: '11',
    program: 'Premium',
  },
  {
    id: 'modr-11',
    score: '4.8+',
    term: '12',
    program: 'Premium',
  },
];

const pricingStrategyProgramTermHighRiskData = [
  {
    id: 'highr-1',
    score: '2.0 - 2.3',
    term: '3',
    program: 'Starter',
  },
  {
    id: 'highr-2',
    score: '2.3 - 2.4',
    term: '4',
    program: 'Starter',
  },
  {
    id: 'highr-3',
    score: '2.4 - 2.7',
    term: '4',
    program: 'Core',
  },
  {
    id: 'highr-4',
    score: '2.7 - 3.0',
    term: '5',
    program: 'Core',
  },
  {
    id: 'highr-5',
    score: '3.0 - 3.2',
    term: '6',
    program: 'Standard',
  },
  {
    id: 'highr-6',
    score: '3.2 - 3.6',
    term: '7',
    program: 'Standard',
  },
  {
    id: 'highr-7',
    score: '3.6 - 3.9',
    term: '8',
    program: 'Low Risk',
  },
  {
    id: 'highr-8',
    score: '3.9 - 4.2',
    term: '9',
    program: 'Low Risk',
  },
  {
    id: 'highr-9',
    score: '4.2 - 4.4',
    term: '10',
    program: 'Low Risk',
  },
  {
    id: 'highr-10',
    score: '4.4 - 4.8',
    term: '11',
    program: 'Premium',
  },
  {
    id: 'highr-11',
    score: '4.8+',
    term: '12',
    program: 'Premium',
  },
];

export const PricingStrategyProgramTerm = (): JSX.Element => {
  return (
    <Card title="Pricing Strategy: Program &amp; Term">
      <Box>
        <Grid>
          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyProgramTermTable
                title="Low Risk Industry"
                pricingStrategyProfiles={pricingStrategyProgramTermLowRiskData}
              />
            </Box>
          </Grid.Item>

          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyProgramTermTable
                title="Moderate Risk Industry"
                pricingStrategyProfiles={
                  pricingStrategyProgramTermModerateRiskData
                }
              />
            </Box>
          </Grid.Item>

          <Grid.Item xl={6}>
            <Box p={2}>
              <PricingStrategyProgramTermTable
                title="High Risk Industry"
                pricingStrategyProfiles={pricingStrategyProgramTermHighRiskData}
              />
            </Box>
          </Grid.Item>
        </Grid>
      </Box>
    </Card>
  );
};
