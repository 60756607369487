import React from 'react';
import { Box, Breadcrumbs, Heading } from '@forward-financing/fast-forward';
import { FUNDING_URL } from 'constants/globals';
import { useParams } from 'react-router-dom';
import { PaymentScheduleContainer } from './PaymentScheduleContainer';

type Params = {
  advanceRecordId: string;
};

export const PaymentSchedulePage = (): JSX.Element => {
  const { advanceRecordId } = useParams<keyof Params>() as Params;

  // eventually, we'll probably want to grab some details about the
  // customer and display them here as well. At the moment, only engineers
  // are likely to use this page, so we can make it a bit more slimmed down

  return (
    <Box>
      <Box px={4} pt={4}>
        <Breadcrumbs>
          <Breadcrumbs.Crumb href={new URL('/admin', FUNDING_URL())}>
            Funding App
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb
            href={new URL(`/admin/advances/${advanceRecordId}`, FUNDING_URL())}
          >
            Advance #{advanceRecordId}
          </Breadcrumbs.Crumb>
          <Breadcrumbs.Crumb href={new URL(window.location.href)}>
            Payment Schedule
          </Breadcrumbs.Crumb>
        </Breadcrumbs>
        <Heading>Advance #{advanceRecordId} Payment Schedule</Heading>
        <PaymentScheduleContainer />
      </Box>
    </Box>
  );
};
