import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { THIRD_PARTY_INTEGRATIONS_BASE_URL } from 'constants/globals';
import { PullExperianCommercialRequestBody } from 'types/api/3pi/types';

const threePi = (path: string): URL =>
  new URL(path, THIRD_PARTY_INTEGRATIONS_BASE_URL());

export const pullExperianCommercial = async (
  requestBody: PullExperianCommercialRequestBody
): Promise<{ success: true }> => {
  const url = threePi(`/api/v1/experian/commercial`);

  const response = await makeInternalAPIRequest<
    { message: string },
    PullExperianCommercialRequestBody
  >(url, 'POST', requestBody);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to pull Experian Commercial Report'
    );
  }

  return {
    success: true,
  };
};
