import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { UNKNOWN_USER_UUID, useUserContext } from './UserContext';
import {
  ViewHistoryStore,
  ViewHistoryData,
  ViewHistoryEntry,
  ViewHistoryModel,
  createEmptyViewHistory,
} from './stores/ViewHistoryStore';

// exported for testing
export const MAX_HISTORY_SIZE_PER_MODEL = 10;

interface ViewHistoryProviderProps {
  store: ViewHistoryStore;
  children: React.ReactNode;
}

interface ViewHistoryContextValue {
  historyData: ViewHistoryData;
  refreshModel: (model: ViewHistoryModel) => void;
  addEntry: (model: ViewHistoryModel, newEntry: ViewHistoryEntry) => void;
}

const ViewHistoryContext = createContext<ViewHistoryContextValue>({
  historyData: createEmptyViewHistory(),
  refreshModel: () => {
    // Intentionally empty - want void return type
  },
  addEntry: () => {
    // Intentionally empty - want void return type
  },
});

export const useViewHistoryContext = (): ViewHistoryContextValue =>
  useContext(ViewHistoryContext);

const getNewModelHistory = (
  modelEntries: ViewHistoryEntry[],
  newEntry: ViewHistoryEntry
) => {
  return (
    modelEntries
      // remove any existing items that have the same UUID as the new entry
      .filter((entry) => entry.objectUUID !== newEntry.objectUUID)

      // add the new entry
      .concat([newEntry])

      // sort from newest to oldest
      .sort((a, b) => b.timestamp - a.timestamp)

      // clamp array length
      .slice(0, MAX_HISTORY_SIZE_PER_MODEL)
  );
};

export function ViewHistoryProvider({
  store,
  children,
}: ViewHistoryProviderProps): JSX.Element {
  const [contextViewHistory, setContextViewHistory] = useState(
    createEmptyViewHistory()
  );

  const currentUser = useUserContext();

  useEffect(() => {
    if (currentUser.uuid === UNKNOWN_USER_UUID) {
      // we don't know who the current user is yet, so make provide an empty
      // view history in the context to avoid leaking the history to an unknown
      // user.
      //
      // note that we don't want to reset the store here, though, because we're
      // probably in the process of fetching the user information at this point,
      // and we don't want to remove any data in the store that might be
      // associated with fetched user.
      setContextViewHistory(createEmptyViewHistory());
    } else {
      if (currentUser.uuid === store.getUserUUID()) {
        // the current user is associated with the view history data in the
        // store, so it's safe to make the store data available through context.
        setContextViewHistory(store.getAllData());
      } else {
        // the current user is NOT associated with the view history data in the
        // store, so we need to reset the store data and begin building a new
        // view history for the current user.
        store.reset();
        store.setUserUUID(currentUser.uuid);
        setContextViewHistory(store.getAllData());
      }
    }
  }, [currentUser, store]);

  const refreshModel = useCallback(
    (model: ViewHistoryModel) => {
      if (currentUser.uuid === UNKNOWN_USER_UUID) {
        return;
      }

      setContextViewHistory((prevState) => ({
        ...prevState,
        [model]: store.getModelHistory(model),
      }));
    },
    [currentUser.uuid, store]
  );

  const addEntry = useCallback(
    (model: ViewHistoryModel, newEntry: ViewHistoryEntry) => {
      if (currentUser.uuid === UNKNOWN_USER_UUID) {
        return;
      }

      const newViewHistoryEntries = getNewModelHistory(
        store.getModelHistory(model),
        newEntry
      );
      store.setModelHistory(model, newViewHistoryEntries);
    },
    [currentUser.uuid, store]
  );

  const contextValue: ViewHistoryContextValue = useMemo(
    () => ({
      historyData: contextViewHistory,
      refreshModel,
      addEntry,
    }),
    [contextViewHistory, refreshModel, addEntry]
  );

  return (
    <ViewHistoryContext.Provider value={contextValue}>
      {children}
    </ViewHistoryContext.Provider>
  );
}
