import React from 'react';
import {
  Box,
  Currency,
  formatDateString,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from './PaymentScheduleContext';

export const AdvanceProfileTable = (): JSX.Element => {
  const { advanceProfile } = usePaymentScheduleContext();
  return (
    <Box>
      <Subheading>
        Funding Profile as of {formatDateString(new Date().toISOString())}
      </Subheading>
      <Table caption="Funding Profile">
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>Funding Start Date</Table.RowHeader>
            <Table.Cell>
              {advanceProfile?.dateOfAdvance
                ? formatDateString(advanceProfile.dateOfAdvance)
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Purchase Price</Table.RowHeader>
            <Table.Cell>
              {typeof advanceProfile?.amount === 'number' ? (
                <Currency amount={advanceProfile.amount} />
              ) : (
                'N/A'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Amount Sold</Table.RowHeader>
            <Table.Cell>
              {typeof advanceProfile?.purchasedAmount === 'number' ? (
                <Currency amount={advanceProfile.purchasedAmount} />
              ) : (
                'N/A'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Contractual Payment Amount</Table.RowHeader>
            <Table.Cell>
              {typeof advanceProfile?.dailyPayment === 'number' &&
              advanceProfile?.paymentFrequency ? (
                <>
                  <Currency amount={advanceProfile.dailyPayment} />/
                  {advanceProfile.paymentFrequency}
                </>
              ) : (
                'N/A'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Payments Total</Table.RowHeader>
            <Table.Cell>
              {typeof advanceProfile?.totalPayment === 'number' ? (
                <Currency amount={advanceProfile.totalPayment} />
              ) : (
                'N/A'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Percentage Paid</Table.RowHeader>
            <Table.Cell>
              {advanceProfile
                ? `${advanceProfile.percentageRepaid.toFixed(0)}%`
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Total Rejected Payments</Table.RowHeader>
            <Table.Cell>{advanceProfile?.rejectionsCount ?? 'N/A'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Outstanding Balance</Table.RowHeader>
            <Table.Cell>
              {typeof advanceProfile?.outstandingBalance === 'number' ? (
                <Currency amount={advanceProfile.outstandingBalance} />
              ) : (
                'N/A'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Last Payment Date</Table.RowHeader>
            <Table.Cell>
              {advanceProfile?.dateOfLastPayment &&
                formatDateString(advanceProfile?.dateOfLastPayment)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Box>
  );
};
