import { camelCase, toUpper } from 'lodash';
import { PlainDate } from '@forward-financing/fast-forward';

/**
 *
 * given a string in format snake_case, caterpillar-case or space separated
 * returns a string in PascalCase
 */
export const toPascalCase = (input: string): string => {
  return camelCase(input).replace(/^(.)/, toUpper);
};

export const isDateInPast = (
  dateString: string,
  nowNative = new Date()
): boolean => {
  const now = new PlainDate(
    nowNative.getFullYear(),
    nowNative.getMonth() + 1,
    nowNative.getDate()
  );

  const [year, month, day] = dateString
    .split('-')
    .map((numStr) => parseInt(numStr, 10));
  const date = new PlainDate(year, month, day);

  return PlainDate.compare(date, now) === -1;
};
