import { defaultTo } from 'lodash';
import {
  CreditDataResponse,
  PullCredit,
} from '../../types/api/underwriting/types';
import { CreditReport } from '../../components/SubmissionUnderwriting/OwnerOverview/types';
import { UNDERWRITING_BASE_URL } from '../../constants/globals';
import { makeInternalAPIRequest } from '../makeInternalAPIRequest';
import { NetworkError } from '../networkError';

//Move to Feature hook
export const toCreditReport = (response: CreditDataResponse): CreditReport => {
  return {
    id: response.id,
    ownerUuid: response.owner_uuid,
    fico: defaultTo(response.fico_score, undefined),
    bkPlus: response.bankruptcy_score,
    vantageScore: response.vantage_score,
    totalUnsecuredBalance: response.total_unsecured_balance,
    totalDebtToHighCredit: response.revolving_utilized_percent,
    totalTradeLines: response.trade_lines_count,
    totalSatisfactoryTradeLines: response.satisfactory_accounts_count,
    totalDelinquentTradeLines: response.now_delinquent_derog_count,
    newDelinquentTradeLines: response.derog_count,
    numberAccountsCurrentlyPayingOnTime: response.active_paid_accounts_count,
    publicRecordsCount: response.public_records_count,
    oldestTradeLine: response.oldest_open_trade_line,
    createdAt: response.created_at,
    creditReportExperianPath: response.credit_report_experian_path,
    experianArchivePath: response.experian_archive_path,
    tradeLinesPath: response.trade_lines_path,
    experianReportDate: response.experian_report_date,
    mismatch: response.mismatch
      ? response.mismatch.map((mismatch) => {
          return {
            field: mismatch.field,
            oldValue: mismatch.old_value,
            newValue: mismatch.new_value,
          };
        })
      : [],
    note: defaultTo(response.note, undefined),
  };
};

export const pullCreditForOwners = async (
  submissionUuid: string,
  ownerUuid: string,
  force: boolean
): Promise<void> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/experian_owner_reports`,
    UNDERWRITING_BASE_URL()
  );

  //Move body to Feature hook
  const body = {
    experian_owner_report: {
      force: force,
    },
  };

  const response = await makeInternalAPIRequest<void, PullCredit>(
    url,
    'POST',
    body
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull Experian');
  }
  return response.json();
};
