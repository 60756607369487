import React from 'react';
import { Box } from '@forward-financing/fast-forward';
import { useNewDealScoringContext } from './NewDealScoringContext';
import { LastUpdatedMessage } from '../LastUpdatedMessage';
import { useUserContext } from 'contexts/UserContext';
import { NewDealScoringReadOnly } from './NewDealScoringReadOnly';
import { NewDealScoringEditor } from './NewDealScoringEditor';

export const NewDealScoring = (): JSX.Element => {
  const { role: userRole } = useUserContext();
  const { response } = useNewDealScoringContext();

  return (
    <>
      {response && (
        <Box>
          {userRole === 'admin' ? (
            <NewDealScoringEditor />
          ) : (
            <NewDealScoringReadOnly />
          )}
          {response.lastUpdated && (
            <LastUpdatedMessage
              time={response.lastUpdated}
              name={response.lastUpdatedBy ?? 'Unknown User'}
            />
          )}
        </Box>
      )}
    </>
  );
};
