import React, { useEffect, useMemo } from 'react';
import {
  Banner,
  Box,
  Flex,
  Grid,
  Icon,
  Loading,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import {
  usePreviousFundedSubmissions,
  useRenewalComparisons,
} from './renewalComparisonHooks';
import { CashFlowTable } from './CashFlowTable/CashFlowTable';
import { OwnerRiskTable } from './OwnerRiskTable/OwnerRiskTable';
import { AdvanceComparisonFundingInfoTable } from './AdvanceComparisonFundingInfoTable/AdvanceComparisonFundingInfoTable';
import { CurrentSubmissionFundingInfoTable } from './CurrentSubmissionFundingInfoTable/CurrentSubmissionFundingInfoTable';
import { MostRecentAdvanceFundingInfoTable } from './MostRecentAdvanceFundingInfoTable/MostRecentAdvanceFundingInfoTable';

export type RenewalComparisonProps = {
  submissionUuid: string;
};

export const RenewalComparison = ({
  submissionUuid,
}: RenewalComparisonProps): JSX.Element => {
  const { role: userRole } = useUserContext();

  useEffect(() => {
    document.title = 'Renewal Comparison';
  }, []);

  // Need to memoize this array for the hook to work correctly
  const sections = useMemo(
    () =>
      userRole === 'processing'
        ? ['funding_data']
        : ['funding_data', 'cash_flow_data', 'owner_risk_data'],
    [userRole]
  );

  const {
    data: renewalComparisonsData,
    loading,
    error,
  } = useRenewalComparisons(submissionUuid, sections);

  // Need to get the previous funded submissions for comparisons
  const { data: previousFundedSubmissions } =
    usePreviousFundedSubmissions(submissionUuid);

  const previousFundedSubmissionUuids = previousFundedSubmissions?.map(
    (submission) => submission.submissionUuid
  );

  const mostRecentFundedSubmissionUuid = previousFundedSubmissionUuids?.[0];

  if (loading) {
    return (
      <Box my={3}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Banner dismissable={false}>
          <Flex alignItems="center" gap={3}>
            <Icon name="exclamation-triangle" color="white" />
            <Text>{error.message}</Text>
          </Flex>
        </Banner>
      </Box>
    );
  }

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'}>
        <Subheading>
          {renewalComparisonsData?.current.summaryData?.businessName}
        </Subheading>

        <Subheading variant="section">Renewal Comparison</Subheading>
      </Flex>

      <Grid gutter>
        <Grid.Item l={6}>
          <CurrentSubmissionFundingInfoTable
            submissionUuid={submissionUuid}
            mostRecentAdvanceUuid={mostRecentFundedSubmissionUuid}
          />
        </Grid.Item>

        <Grid.Item l={6}>
          <MostRecentAdvanceFundingInfoTable
            mostRecentAdvanceUuid={mostRecentFundedSubmissionUuid}
          />
        </Grid.Item>
      </Grid>

      <AdvanceComparisonFundingInfoTable
        previousFundedSubmissions={previousFundedSubmissions}
      />

      <CashFlowTable
        current={renewalComparisonsData?.current.cashFlowData}
        prior={renewalComparisonsData?.previousOne?.cashFlowData}
      />

      <OwnerRiskTable
        current={renewalComparisonsData?.current.ownerRiskData}
        prior={renewalComparisonsData?.previousOne?.ownerRiskData}
      />
    </Flex>
  );
};
