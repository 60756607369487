import React from 'react';
import { Flex, Text, Button, Box } from '@forward-financing/fast-forward';
import {
  ActiveSubmission,
  Customer,
} from '../../../api/UnderwritingClient/codecs';
import { CustomerCard } from './CustomerCard';
import { Partner } from 'api/FundingClient/codecs';
import { CustomerSubmissions } from './CustomerSubmissions';
import { featureFlags } from 'helpers/featureFlags';

interface CustomerConfirmationProps {
  activeSubmissions: ActiveSubmission[];
  handleContinue: () => void;
  handleBack: () => void;
  customer: Customer;
  partners: Partner[];
}

export function CustomerConfirmation(
  props: CustomerConfirmationProps
): JSX.Element {
  return (
    <Flex flexDirection="column">
      <Text bold>You selected to continue with the following customer:</Text>
      <Box marginTop={3} marginBottom={3} bg="gray-100" rounded>
        <CustomerCard
          customer={props.customer}
          activeSubmissions={props.activeSubmissions}
          partners={props.partners}
        />

        {featureFlags.show_customer_submissions && (
          <CustomerSubmissions
            customerUuid={props.customer.uuid}
            isos={props.partners}
          />
        )}
      </Box>
      <Flex gap={4} justifyContent="center">
        <Button onClick={props.handleBack} startIcon={['fas', 'chevron-left']}>
          Back to Search Results
        </Button>
        <Button
          onClick={props.handleContinue}
          startIcon={['fas', 'user-check']}
        >
          Confirm Selection
        </Button>
      </Flex>
    </Flex>
  );
}
