import React from 'react';
import { Box, List, Subheading } from '@forward-financing/fast-forward';

export const DealScorePopoverContent = (): JSX.Element => (
  <Box>
    <Subheading variant="subsection">No Changes</Subheading>
    <List>
      <List.Item>No changes in scoring; Up to 1 change if minor.</List.Item>
    </List>
    <Subheading variant="subsection">Minor Changes</Subheading>
    <List>
      <List.Item>
        1-2 changes in scoring (more obvious); 2 months change, moderate $
        change or 1 program change; minor override or stip change.
      </List.Item>
    </List>
    <Subheading variant="subsection">Major Changes</Subheading>
    <List>
      <List.Item>
        2+ changes in scoring - Either multiple point jump for one category or
        changes in multiple categories; major stip change.
      </List.Item>
    </List>
  </Box>
);
