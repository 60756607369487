import { ConsumerCreditEntities } from 'types/api/3pi/types';

export const TRADELINE_PRIMARY_ACCOUNT_TYPE_CODES = [
  '26',
  '25',
  '27',
  '08',
  '04',
  '05',
  '18',
  '00',
  '01',
  '02',
  '13',
  '47',
  '19',
  '15',
  '20',
  '07',
];

export const TRADELINE_PRIMARY_ECOA_CODES = ['1', '2', '4'];

export const TRADELINE_HISTORY_CODES = {
  '0': 'Current with zero balance - update received',
  '1': '30 days delinquent',
  '2': '60 days delinquent',
  '3': '90 days delinquent',
  '4': '120 days delinquent',
  '5': '150 days delinquent',
  '6': '180 days delinquent',
  '7':
    'Bankruptcy Chapter 13 Petitioned, or Discharged and ' +
    'Bankruptcy Chapter 13 Reaffirmation of Debt Rescinded',
  '8': 'Foreclosure Proceedings Started, Deed in Lieu',
  '9':
    'Settled, Insurance Claim, Term Default, Government Claim, Paid by Dealer, ' +
    'BK Chapter 7, 11 or 12 Petitioned, or Discharged and BK Chapter 7, 11 or 12 ' +
    'Reaffirmation of Debt Rescinded',
  B: 'Account Condition Changed - Payment Code Not Applicable',
  C: 'Current',
  G: 'Collection',
  H: 'Foreclosure',
  J: 'Voluntary Surrender',
  K: 'Repossession',
  L: 'Charge Off',
  N: 'Current with zero balance - no update received',
  '-': 'No update received',
};

export const TRADELINE_OTHER_RATING_TYPE = 'Other';

export const TRADELINE_ENTITIES_TO_REQUEST: ConsumerCreditEntities[] = [
  'tradelines',
];
