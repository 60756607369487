import React, { useEffect, useState } from 'react';
import { Sheet } from '@forward-financing/fast-forward';

import { useLedgersValidationContext } from '../LedgerValidationContext/LedgerValidationContext';
import { LedgerOffer } from './offers.types';
import {
  formatLedgerOfferField,
  isLedgerOfferKey,
  isValidOfferCellValue,
} from './offers.utils';

type OfferCellProps = {
  offersData: Partial<LedgerOffer>[];
  fieldName: string;
  months: number;
  isEditable?: boolean;
  key: number;
  handleBlur?: (
    months: number,
    fieldName: keyof LedgerOffer,
    value: string
  ) => void;
};

/**
 * Renders a cell in the offers table with optional editing capabilities.
 */
export const OffersTableCell = ({
  offersData,
  fieldName,
  months,
  isEditable,
  key,
  handleBlur,
}: OfferCellProps): JSX.Element | null => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const { updateOfferValidationState } = useLedgersValidationContext();

  useEffect(() => {
    const offer = offersData.find((offersItem) => offersItem.months === months);

    setValue(formatLedgerOfferField(offer, fieldName));
  }, [fieldName, months, offersData]);

  useEffect(() => {
    const isValidValue = isValidOfferCellValue(
      fieldName,
      value.replace(/%|\$|,/g, '')
    );

    setIsValid(isValidValue);
    updateOfferValidationState(months, isValidValue);
  }, [fieldName, months, updateOfferValidationState, value]);

  if (!isLedgerOfferKey(fieldName)) return null;

  const onFocusHandler = (): void => {
    // Remove any occurrences of %, $, or , from the previous value
    setValue((prev) => prev.replace(/%|\$|,/g, ''));
  };

  const onBlurHandler = (): void => {
    if (isValid && value.length > 0) {
      handleBlur?.(months, fieldName, value?.replace(/%|\$|,/g, ''));
    }
  };

  const onKeyDownHandler: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      onBlurHandler();
    }
  };

  return isEditable ? (
    <Sheet.TextCell
      backgroundColor={isValid ? undefined : 'red-100'}
      value={String(value)}
      key={`${key}-${months}`}
      onValueChange={(val) => setValue(val)}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onKeyDown={onKeyDownHandler}
    />
  ) : (
    <Sheet.TextCell key={`${key}-${months}`} value={value} />
  );
};
