import React from 'react';
import {
  Accordion,
  Box,
  Flex,
  formatPhoneNumber,
  IconButton,
  Subheading,
  Table,
  Address as AddressComponent,
  formatDateString,
  Banner,
  Icon,
  Text,
  Grid,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { BasicOwnerInfoTableFragmentFragment } from '__generated__/graphql';
import { usePullLexisNexis } from './ownerOverviewHooks';
import { SocialSecurityNumberDisplay } from './SocialSecurityNumberDisplay';
import {
  LexisNexisPersonalPublicDocument,
  LexisNexisSearchReport,
} from './types';

export interface LexisNexisSearchResultsProps {
  submissionUuid: string;
  ownerPublicDocuments?: LexisNexisPersonalPublicDocument[];
  results: LexisNexisSearchReport[];
  owner: BasicOwnerInfoTableFragmentFragment;
  currentUserCanSeePartialSsn: boolean;
  currentUserCanSeeDateOfBirth: boolean;
}

export const LexisNexisSearchResults = (
  props: LexisNexisSearchResultsProps
): JSX.Element => {
  const [
    attachReportToEntity,
    { error: lexisNexisPullError, success: lexisNexisSuccess },
  ] = usePullLexisNexis();

  const getPulledReportCreateDate = (
    identifier: string
  ): string | undefined => {
    const pulledReports = props.ownerPublicDocuments?.filter(
      (report) => report.reportIdentifier === identifier
    );
    return pulledReports && pulledReports.length > 0
      ? pulledReports.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0]
          .createdAt
      : undefined;
  };

  const showReportDate = (identifier: string): string | null => {
    const reportDate = getPulledReportCreateDate(identifier);
    if (reportDate) {
      return `Last pulled on ${formatDateTimeString(reportDate)}`;
    }
    return null;
  };

  return (
    <>
      <Accordion type="single" collapsible>
        <Accordion.Item value="item-1">
          <Accordion.Trigger>
            LexisNexis Search Results ({props.results.length})
          </Accordion.Trigger>
          <Accordion.Content>
            {lexisNexisPullError && (
              <Banner dismissable={false} variant="error">
                {lexisNexisPullError}
              </Banner>
            )}
            {lexisNexisSuccess && (
              <Banner dismissable={false} variant="success">
                <Flex flexDirection={'row'} gap={2}>
                  <Icon name="circle-check" size="2x" />
                  <Text>
                    LexisNexis was pulled successfully! It takes anywhere from 5
                    seconds to 4 minutes to process the data, so please allow a
                    little bit of time before refreshing the page. If you are
                    still not able to view the report after 4 minutes, please
                    contact the tech support.
                  </Text>
                </Flex>
              </Banner>
            )}
            <Grid>
              <Grid.Item s={12} m={9}>
                <Flex flexDirection="column" gap={3}>
                  <Box>
                    <Subheading variant="section">
                      Owner Information as Supplied with Submission
                    </Subheading>
                    <Table>
                      <Table.Head>
                        <Table.ColumnHeader>Name</Table.ColumnHeader>
                        <Table.ColumnHeader>Address</Table.ColumnHeader>
                        {props.currentUserCanSeePartialSsn && (
                          <Table.ColumnHeader>SSN</Table.ColumnHeader>
                        )}
                        {props.currentUserCanSeeDateOfBirth && (
                          <Table.ColumnHeader>DOB</Table.ColumnHeader>
                        )}
                        <Table.ColumnHeader>Phone Number</Table.ColumnHeader>
                      </Table.Head>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{props.owner.fullName}</Table.Cell>
                          <Table.Cell>
                            {props.owner.address && (
                              <AddressComponent
                                streetAddress={[
                                  props.owner.address.street1,
                                  props.owner.address.street2,
                                ]}
                                city={props.owner.address.city ?? ''}
                                state={props.owner.address.state ?? ''}
                                zipCode={props.owner.address.zip ?? ''}
                                isSingleLine
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <SocialSecurityNumberDisplay
                              ssn={props.owner.ssn}
                              ssnLastFour={props.owner.ssnLastFour}
                              canSeePartialSsn={
                                props.currentUserCanSeePartialSsn
                              }
                            />
                          </Table.Cell>
                          {props.currentUserCanSeeDateOfBirth && (
                            <Table.Cell>
                              {props.owner.birthdate &&
                                formatDateString(props.owner.birthdate)}
                            </Table.Cell>
                          )}
                          <Table.Cell>
                            {props.owner.workPhone && (
                              <Box>
                                {formatPhoneNumber(props.owner.workPhone)}
                              </Box>
                            )}
                            {props.owner.homePhone && (
                              <Box>
                                {formatPhoneNumber(props.owner.homePhone)}
                              </Box>
                            )}
                            {props.owner.cellPhone && (
                              <Box>
                                {formatPhoneNumber(props.owner.cellPhone)}
                              </Box>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Box>
                  <Box>
                    <Subheading variant="section">
                      LexisNexis Report Data
                    </Subheading>
                    <Table>
                      <Table.Head>
                        <Table.ColumnHeader>Names</Table.ColumnHeader>
                        <Table.ColumnHeader>Addresses</Table.ColumnHeader>
                        <Table.ColumnHeader>SSNs</Table.ColumnHeader>
                        <Table.ColumnHeader>DOBs</Table.ColumnHeader>
                        <Table.ColumnHeader>Actions</Table.ColumnHeader>
                      </Table.Head>
                      <Table.Body>
                        {props.results.map((result) => {
                          return (
                            <Table.Row key={result.reportIdentifier}>
                              <Table.Cell>
                                {result.fullName.map((name) => (
                                  <Box key={name}>{name}</Box>
                                ))}
                              </Table.Cell>
                              <Table.Cell>
                                {result.addresses.map((address) => {
                                  return (
                                    <Box key={address.streetAddress}>
                                      <AddressComponent
                                        streetAddress={
                                          address.streetAddress ?? ''
                                        }
                                        city={address.city ?? ''}
                                        state={address.state ?? ''}
                                        zipCode={address.state ?? ''}
                                      />
                                    </Box>
                                  );
                                })}
                              </Table.Cell>
                              <Table.Cell>
                                {result.ssn.map((ssn) => (
                                  <Box key={ssn}>{ssn}</Box>
                                ))}
                              </Table.Cell>
                              <Table.Cell>
                                {result.dateOfBirth?.map((dob) => (
                                  <Box key={`${dob.month}/${dob.year}`}>
                                    {dob.month}/{dob.year}
                                  </Box>
                                ))}
                              </Table.Cell>
                              <Table.Cell>
                                <IconButton
                                  icon="plus-circle"
                                  label="Add Report"
                                  onClick={() =>
                                    attachReportToEntity(
                                      props.submissionUuid,
                                      props.owner.id,
                                      false,
                                      result.reportIdentifier
                                    )
                                  }
                                />
                                <Text italic>
                                  {showReportDate(result.reportIdentifier)}
                                </Text>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Box>
                </Flex>
              </Grid.Item>
            </Grid>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
