import React from 'react';

import {
  BankInfo,
  Bank,
  UsState,
} from '../../../api/UnderwritingClient/codecs';
import { HashMap } from '../../../api/codecs';
import { ValidationResult } from '../../../helpers/validations/codecs';
import {
  dropDownStates,
  obtainValidZipCodeEvent,
  zipCodeMask,
} from '../../../helpers/utils';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Text,
} from '@forward-financing/fast-forward';

// Components
import { FormRow, DisplayStyle } from '../../shared/form/FormRow';
import { Input } from '../../shared/form/Input';
import { Dropdown } from '../../shared/form/Dropdown';
import { HTMLFormChangeOrMouseEvent } from '../../../types/form';
import { AccountNumberPopover } from './AccountNumberPopover';

export interface BankInformationProps {
  bank: BankInfo;
  bankError: boolean;
  usStates: UsState[];
  banks: Bank[];
  createBank: (bankName: string) => void;
  handleBankNameChange: (value: string) => void;
  handleBankInfoChange: (e: HTMLFormChangeOrMouseEvent) => void;
  validationErrors: HashMap<ValidationResult>;
  handleSendLogs?: (field_name: string) => Promise<void>;
}

export const BankInformation = (props: BankInformationProps): JSX.Element => {
  const handleZipCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const event = obtainValidZipCodeEvent(e);
    props.handleBankInfoChange(event);
  };

  const bankNames = props.banks?.map((val) => {
    return { value: val.name };
  });

  const filteredBankList = props.banks.filter((b) => {
    const bankNameFromInput = props.bank.bank_name?.toLocaleLowerCase();
    const bankNameFromList = b.name.toLocaleLowerCase();
    return bankNameFromInput && bankNameFromList.includes(bankNameFromInput);
  });

  const shouldShowAddButton = filteredBankList.length === 0;
  const handleCreateBankOnclick = () =>
    props.bank.bank_name && props.createBank(props.bank.bank_name.trim());

  return (
    <div className="column">
      <FormRow
        left={
          <Input
            label="Full Name on Account"
            name="account_holder_full_name"
            onChange={props.handleBankInfoChange}
            validationResult={props.validationErrors.account_holder}
            value={props.bank.account_holder_full_name || ''}
          />
        }
        // Add moreInfoPopover={<BankNameMoreInfoPopover />} when FF autocomplete component supports it.
        right={
          <Box marginBottom={shouldShowAddButton ? 2 : undefined}>
            <Autocomplete
              label="Bank Name"
              name="bank_name"
              items={bankNames}
              onValueChange={props.handleBankNameChange}
              value={props.bank.bank_name || ''}
            />
            {shouldShowAddButton && (
              <Grid>
                <Grid.Item xs={4}>
                  <Button startIcon={'plus'} onClick={handleCreateBankOnclick}>
                    Add Bank
                  </Button>
                </Grid.Item>
                <Grid.Item xs={8}>
                  {props.bankError && (
                    <Text bold>
                      {props.bank.bank_name} could not be added to the database.
                      However you may still proceed with the submission.
                    </Text>
                  )}
                </Grid.Item>
              </Grid>
            )}
          </Box>
        }
      />
      <FormRow
        left={
          <Input
            label="Account Number"
            name="account_number"
            moreInfoPopover={<AccountNumberPopover />}
            onChange={props.handleBankInfoChange}
            value={props.bank.account_number || ''}
            shouldBeMasked={true}
            handleSendLogs={props.handleSendLogs}
          />
        }
        right={
          <Input
            label="City"
            name="city"
            onChange={props.handleBankInfoChange}
            validationResult={props.validationErrors.city}
            value={props.bank.city || ''}
          />
        }
      />
      <FormRow
        displayStyle={DisplayStyle.SingleRight}
        right={
          <Dropdown
            label="State"
            name="state"
            options={dropDownStates(props.usStates)}
            onChange={props.handleBankInfoChange}
            value={props.bank.state || ''}
          />
        }
      />
      <FormRow
        displayStyle={DisplayStyle.SingleRight}
        right={
          <Input
            label="Zip Code"
            name="zip"
            inputMask={zipCodeMask(props.bank.zip)}
            onChange={handleZipCodeChange}
            placeholder="5 or 9-digit Zip Code"
            validationResult={props.validationErrors.zip}
            value={props.bank.zip || ''}
          />
        }
      />
    </div>
  );
};
