import { useCallback } from 'react';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { fetchReturnCodes } from 'api/funding/returnCodesFetchUtils';
import { AchReturnCodesResponse } from 'types/api/funding/types';

export type UseFetchReturnCodesResponse =
  UseGenericQueryResponse<AchReturnCodesResponse>;

export const useFetchReturnCodes = (
  provider: 'ach_works' | 'i_check_gateway'
): UseFetchReturnCodesResponse => {
  const fetcher = useCallback(() => {
    return fetchReturnCodes(provider);
  }, [provider]);

  return useGenericQuery<AchReturnCodesResponse>(fetcher);
};
