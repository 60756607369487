import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import {
  Banner,
  Button,
  Checkbox,
  CheckboxProps,
  Flex,
  Loading,
  Text,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';

import { DropdownOption } from '../../../types/form/index';
import { Decline } from '../../../api/UnderwritingClient/codecs';
import { declineDriversOptions } from '../../../helpers/utils';
import { isRequired } from '../../../helpers/validations/ValidationHelpers';
import { useDeclineDrivers } from '../WizardFetchHooks';

export interface DeclineFormProps {
  closeAction: () => void;
  submitAction: (decline: Decline) => void;
  initialDeclineNotes: string;
  initialDeclineDrivers: string[];
}

export const DeclineForm = (props: DeclineFormProps): JSX.Element => {
  const [declineNotes, setDeclineNotes] = useState(props.initialDeclineNotes);
  const [isLoading, setIsLoading] = useState(false);

  const [declineDrivers, setDeclineDrivers] = useState(
    props.initialDeclineDrivers
  );

  const [previousDecline, setPreviousDecline] = useState(false);

  const {
    data: underwritingDeclineDrivers,
    error: underwritingDeclineDriversError,
    loading: underwritingDeclineDriversLoading,
  } = useDeclineDrivers();

  const errorMessage =
    previousDecline || isRequired(declineDrivers[0])
      ? false
      : 'Please select a value or previous decline.';

  const handleReactSelectChange = (
    selectedOptions: MultiValue<DropdownOption>
  ): void => {
    const selectedValues = selectedOptions || [];
    const selectedDeclineDrivers = selectedValues.map((option) => {
      // the toString is only necessary because the DropdownOption allows numeric values,
      // even though we only ever have strings in this case
      return option.value.toString();
    });
    setDeclineDrivers(selectedDeclineDrivers);
  };

  const handleNotesChange: TextAreaProps['onChange'] = ({
    target: { value },
  }): void => {
    setDeclineNotes(value);
  };

  const handleDecline = (): void => {
    const decline = {
      decline_drivers: declineDrivers,
      decline_driver_notes: declineNotes,
      previous_decline: previousDecline,
    };
    setIsLoading(true);

    props.submitAction(decline);
  };

  const handlePreviousDeclineChange: CheckboxProps['onCheckboxChange'] = (
    checked
  ) => {
    if (checked) {
      setDeclineDrivers([]);
    }
    setPreviousDecline(!!checked);
  };

  if (underwritingDeclineDriversLoading) {
    return <Loading />;
  }

  return (
    <div data-testid="decline-form-container" className="decline-form">
      {underwritingDeclineDriversError && (
        <Banner dismissable={false}>
          {underwritingDeclineDriversError.message}
        </Banner>
      )}
      <div className="sub-header">
        Please provide the following information:
      </div>
      <Checkbox
        label="Previous Decline"
        checked={previousDecline}
        onCheckboxChange={handlePreviousDeclineChange}
      />
      <div className="field">
        <label className="label" htmlFor="decline-driver-select">
          Decline Drivers:
        </label>
        <div className="control">
          <Select
            id="decline-driver-select"
            aria-label="Decline Drivers"
            value={declineDriversOptions(declineDrivers)}
            onChange={handleReactSelectChange}
            isMulti={true}
            options={declineDriversOptions(
              underwritingDeclineDrivers?.sort() || []
            )}
            isOptionDisabled={() => declineDrivers.length >= 1}
            key={declineDrivers.length || ''} // gotta re-render
            isDisabled={previousDecline}
          />
          {!!errorMessage && <Text color="danger">{errorMessage}</Text>}
        </div>
      </div>
      <TextArea
        label="Decline Notes"
        value={declineNotes}
        onChange={handleNotesChange}
        disabled={previousDecline}
        rows={4}
      />
      <Flex gap={3} justifyContent="center">
        <Button
          onClick={props.closeAction}
          startIcon="times"
          disabled={isLoading}
          variant="secondary"
        >
          Cancel
        </Button>
        {isLoading ? (
          <Loading />
        ) : (
          <Button
            variant="danger"
            startIcon="thumbs-down"
            onClick={handleDecline}
            disabled={!!errorMessage}
          >
            Proceed with Decline
          </Button>
        )}
      </Flex>
    </div>
  );
};
