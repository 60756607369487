import React from 'react';
import {
  Banner,
  Currency,
  Divider,
  Flex,
  formatDateString,
  Link,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { useExperianConsumer } from './ownerOverviewHooks';
import { CreditReport } from './types';

export interface CreditReportProps {
  submissionUuid: string | undefined;
  ownerUuid: string | undefined;
  creditReport: CreditReport | undefined;
  canArchive: boolean;
}

export const CreditReportTable = ({
  submissionUuid,
  ownerUuid,
  creditReport,
  canArchive,
}: CreditReportProps): JSX.Element => {
  const {
    data: experianConsumerData,
    loading: experianConsumerLoading,
    error: experianConsumerError,
  } = useExperianConsumer(submissionUuid, ownerUuid);

  const bkPlusScore = featureFlags.experian_consumer_3pi_owner
    ? experianConsumerData?.bkPlusScore
    : creditReport?.bkPlus;
  const ficoScore = featureFlags.experian_consumer_3pi_owner
    ? experianConsumerData?.ficoScore
    : creditReport?.fico;
  const vantageScore = featureFlags.experian_consumer_3pi_owner
    ? experianConsumerData?.vantageScore
    : creditReport?.vantageScore;

  const isCreditReportLoading =
    featureFlags.experian_consumer_3pi_owner && experianConsumerLoading;
  const creditReportStatus = isCreditReportLoading ? ' Loading...' : '';

  const formattedExperianConsumerOldestTradeLine =
    experianConsumerData?.oldestTradeLine
      ? formatDateString(experianConsumerData?.oldestTradeLine)
      : '';

  const hasCreditReport = featureFlags.experian_consumer_3pi_owner
    ? !!experianConsumerData
    : !!creditReport;

  const hasArchive = featureFlags.experian_consumer_3pi_archive
    ? !!experianConsumerData
    : canArchive;

  // 404 Not Found is just a missing credit report, not an error.
  const hasExperianConsumerError =
    featureFlags.experian_consumer_3pi_owner &&
    experianConsumerError &&
    experianConsumerError?.statusCode !== 404;

  const archiveUrl = featureFlags.experian_consumer_3pi_owner
    ? new URL(
        `/dashboard/owners/${ownerUuid}/experian_consumer_archive`,
        window.location.origin
      )
    : new URL(creditReport?.experianArchivePath || '', UNDERWRITING_BASE_URL());

  const showLinks = featureFlags.experian_consumer_3pi_owner
    ? !!experianConsumerData
    : !!creditReport;

  const detailedCreditReportLink =
    featureFlags.experian_consumer_3pi_detailed_page
      ? new URL(
          `/dashboard/consumer-credit-report/${experianConsumerData?.referenceUuid}`,
          window.location.origin
        )
      : new URL(
          creditReport?.creditReportExperianPath || '',
          UNDERWRITING_BASE_URL()
        );

  return (
    <>
      {hasExperianConsumerError && (
        <Banner dismissable={false} variant="error">
          {experianConsumerError?.message}
        </Banner>
      )}

      <Flex gap={3}>
        <Subheading variant="section">{`Credit Report${creditReportStatus}`}</Subheading>
        {showLinks && (
          <Flex alignItems="center" justifyContent="center">
            <Link newTab href={detailedCreditReportLink}>
              Experian
            </Link>

            {hasArchive && (
              <>
                <Divider orientation="vertical" />
                <Link newTab href={archiveUrl}>
                  Archive
                </Link>
              </>
            )}
          </Flex>
        )}
      </Flex>

      {hasCreditReport ? (
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>FICO</Table.RowHeader>
              <Table.Cell>{ficoScore}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>BK Plus</Table.RowHeader>
              <Table.Cell>{bkPlusScore}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Vantage Score</Table.RowHeader>
              <Table.Cell>{vantageScore}</Table.Cell>
            </Table.Row>

            {!featureFlags.experian_consumer_3pi_owner && creditReport && (
              <Table.Row>
                <Table.RowHeader>Total Unsecured Balance</Table.RowHeader>
                <Table.Cell>
                  <Currency
                    amount={parseInt(creditReport.totalUnsecuredBalance)}
                  />
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.RowHeader>Total Debt to High Credit</Table.RowHeader>
              <Table.Cell>
                {featureFlags.experian_consumer_3pi_owner
                  ? !!experianConsumerData &&
                    experianConsumerData.totalDebtToHighCredit !== undefined &&
                    `${experianConsumerData.totalDebtToHighCredit}%`
                  : !!creditReport?.totalDebtToHighCredit &&
                    creditReport?.totalDebtToHighCredit + '%'}
              </Table.Cell>
            </Table.Row>

            {!featureFlags.experian_consumer_3pi_owner && (
              <Table.Row>
                <Table.RowHeader>Total Trade Lines</Table.RowHeader>
                <Table.Cell>{creditReport?.totalTradeLines}</Table.Cell>
              </Table.Row>
            )}

            {!featureFlags.experian_consumer_3pi_owner && (
              <Table.Row>
                <Table.RowHeader>
                  Total Satisfactory Trade Lines
                </Table.RowHeader>
                <Table.Cell>
                  {creditReport?.totalSatisfactoryTradeLines}
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.RowHeader>Total Delinquent Trade Lines</Table.RowHeader>
              <Table.Cell>
                {featureFlags.experian_consumer_3pi_owner
                  ? experianConsumerData?.totalDelinquentTradeLines
                  : creditReport?.totalDelinquentTradeLines}
              </Table.Cell>
            </Table.Row>

            {!featureFlags.experian_consumer_3pi_owner && (
              <Table.Row>
                <Table.RowHeader>
                  New Delinquent Trade Lines (~6 months)
                </Table.RowHeader>
                <Table.Cell>{creditReport?.newDelinquentTradeLines}</Table.Cell>
              </Table.Row>
            )}

            {!featureFlags.experian_consumer_3pi_owner && (
              <Table.Row>
                <Table.RowHeader>
                  # Accounts Currently Paying On Time
                </Table.RowHeader>
                <Table.Cell>
                  {creditReport?.numberAccountsCurrentlyPayingOnTime}
                </Table.Cell>
              </Table.Row>
            )}

            {!featureFlags.experian_consumer_3pi_owner && (
              <Table.Row>
                <Table.RowHeader>Public Records Count</Table.RowHeader>
                <Table.Cell>{creditReport?.publicRecordsCount}</Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.RowHeader>Oldest Trade Line</Table.RowHeader>
              <Table.Cell>
                {featureFlags.experian_consumer_3pi_owner
                  ? formattedExperianConsumerOldestTradeLine
                  : formatDateString(creditReport?.oldestTradeLine || '')}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <Text>No Credit Report Data</Text>
      )}
    </>
  );
};
