import React, { useMemo } from 'react';
import {
  formatCurrency,
  formatDateString,
  Icon,
  Link,
  Loading,
  Table,
} from '@forward-financing/fast-forward';
import { capitalize } from 'lodash';
import { FUNDING_URL } from 'constants/globals';
import { displayPercentage } from 'helpers/utils';
import {
  useAcceptedOffers,
  useMultipleRenewalComparisonSubmission,
} from '../renewalComparisonHooks';
import { PreviousFundedSubmission } from '../renewalComparison.types';

const calculatePaymentAmount = (
  amount: string | number,
  isDaily: boolean
): number => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) {
    return 0;
  }

  if (isDaily) {
    return numericAmount;
  }

  // Multiply by 5 as this is a weekly payment
  return numericAmount * 5;
};

type PaymentHistoryLinkProps = {
  recordId: number;
};

const PaymentHistoryLink = ({
  recordId,
}: PaymentHistoryLinkProps): JSX.Element => {
  const url = new URL(
    `admin/advances/${recordId}?tab=payback-schedule`,
    FUNDING_URL()
  );
  return (
    <Link href={url} newTab>
      View Payment Calendar <Icon name="arrow-up-right-from-square" />
    </Link>
  );
};

export type AdvanceComparisonFundingInfoTableProps = {
  previousFundedSubmissions: PreviousFundedSubmission[] | undefined;
};

export const AdvanceComparisonFundingInfoTable = ({
  previousFundedSubmissions,
}: AdvanceComparisonFundingInfoTableProps): JSX.Element => {
  const memoizedSubmissionUuids = useMemo(
    () =>
      previousFundedSubmissions?.map((submission) => submission.submissionUuid),
    [previousFundedSubmissions]
  );

  const { data: advanceComparisons, loading: loadingAdvanceComparisons } =
    useMultipleRenewalComparisonSubmission(memoizedSubmissionUuids);

  const { data: acceptedOffers, loading: loadingAcceptedOffers } =
    useAcceptedOffers(memoizedSubmissionUuids);

  if (loadingAdvanceComparisons || loadingAcceptedOffers) {
    return <Loading />;
  }

  return (
    <Table caption="Advance Comparison Table">
      <Table.Head>
        <Table.ColumnHeader>Advance Comparison</Table.ColumnHeader>
        {advanceComparisons?.map((submission) => (
          <Table.ColumnHeader key={submission.advanceNumber}>
            {submission.isAddOn ? 'Add-On' : 'Advance'}{' '}
            {submission.advanceNumber}
          </Table.ColumnHeader>
        ))}
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.RowHeader>UW</Table.RowHeader>
          {previousFundedSubmissions?.map((submission) => (
            <Table.Cell key={submission.submissionUuid}>
              {submission.underwriterName || 'Unassigned'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Funded Date</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatDateString(submission.dateOfAdvance)}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Funded Amount</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatCurrency(Number(submission.amount))}

              {/* 
                TODO: Not required for go live, but need additional
                data from Funding in order to populate this row
                with the percentage change between the $ amount funded
                and that of the previous advance.
                See FUN-1905 in Jira for more details.
              */}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Net Funded Amount</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatCurrency(Number(submission.netFundedAmount))}
            </Table.Cell>
          ))}
        </Table.Row>

        {/* 
          TODO: Not required for go live, but need additional
          data from Funding in order to populate this row
          with the repaid percentage at the time of funding.
          See FUN-1905 in Jira for more details.
        */}
        {/* <Table.Row>
          <Table.RowHeader>% Repaid at Time of Funding</Table.RowHeader>
        </Table.Row> */}

        <Table.Row>
          <Table.RowHeader>Funded Term</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.targetTerm} months
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Program</Table.RowHeader>
          {acceptedOffers?.map((submission) => (
            <Table.Cell key={submission.id}>{submission.program}</Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Buy Rate</Table.RowHeader>
          {acceptedOffers?.map((submission) => (
            <Table.Cell key={submission.id}>{submission.buyRate}</Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Factor Rate</Table.RowHeader>
          {acceptedOffers?.map((submission) => (
            <Table.Cell key={submission.id}>{submission.factorRate}</Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Commission</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {displayPercentage(Number(submission.commission))}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Payment Frequency</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {capitalize(submission.contractualPaybackFrequency)}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Payment Amount</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatCurrency(
                calculatePaymentAmount(
                  submission.dailyPayment,
                  submission.contractualPaybackFrequency.toLowerCase() ===
                    'daily'
                )
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>30 Day Pace</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.thirtyDayDollarPace
                ? displayPercentage(submission.thirtyDayDollarPace)
                : 'N/A'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>60 Day Pace</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.sixtyDayDollarPace
                ? displayPercentage(submission.sixtyDayDollarPace)
                : 'N/A'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Overall Pace</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.overallDollarPace
                ? displayPercentage(submission.overallDollarPace)
                : 'N/A'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Overall Payment Count Pace</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.overallPaymentCountPace
                ? displayPercentage(submission.overallPaymentCountPace)
                : 'N/A'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader># Missed Payments</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.missedPaymentCount}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader># Reduced Payments</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.reducedPaymentCount}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Payment History</Table.RowHeader>
          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              <PaymentHistoryLink recordId={submission.recordId} />
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
