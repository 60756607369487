import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Modal,
  Form,
  Text,
  TextInput,
  Select,
} from '@forward-financing/fast-forward';
import { industryRiskColors } from './IndustryRiskTable';
import { createIndustryRisk } from './IndustryRiskFetchUtils';
import { toError } from 'helpers/errorUtils';

export interface IndustryRiskCreateModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
  afterSave: () => void;
}

export const IndustryRiskCreateModal = (
  props: IndustryRiskCreateModalProps
): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [pricingRiskProfile, setPricingRiskProfile] = useState<string>('');
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();

  const handleOpenChange = (): void => {
    if (props.isOpen) {
      // clear any success or error messages we may have set
      setSuccess('');
      setError('');
    }
    props.onOpenChange();
  };

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      setSuccess('');
      setError('');
      await createIndustryRisk({
        name: name,
        pricing_risk_profile: pricingRiskProfile,
      });
      setName('');
      setPricingRiskProfile('');
      setSuccess('Industry Risk created!');
      props.afterSave();
    } catch (e) {
      const err = toError(e);
      setError(err.toString());
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      title="Add Industry Risk"
      isOpen={props.isOpen}
      onOpenChange={handleOpenChange}
      trigger={<></>}
      body={
        <Form
          accessibleName="Create Industry Risk"
          onSubmit={handleSubmit}
          allowImplicitSubmission={false}
        >
          {({ fireSubmit }) => (
            <Flex flexDirection="column">
              {error && <Banner marginY={2}>{error}</Banner>}

              {success && (
                <Flex justifyContent="center">
                  <Text color="status">{success}</Text>
                </Flex>
              )}

              <Box marginY={2}>
                <TextInput
                  label="Name"
                  value={name}
                  onValueChange={setName}
                  required={true}
                />
              </Box>

              <Box marginY={2}>
                <Select
                  label="Risk Profile"
                  value={pricingRiskProfile}
                  options={industryRiskColors.map((i) => ({
                    value: i.name,
                    text: i.displayName,
                  }))}
                  required={true}
                  onValueChange={setPricingRiskProfile}
                />
              </Box>

              <Box marginY={2}>
                <Button onClick={fireSubmit} disabled={isProcessing}>
                  Submit
                </Button>
              </Box>
            </Flex>
          )}
        </Form>
      }
    />
  );
};
