import React from 'react';
import { PaymentScheduleContainer } from './PaymentScheduleContainer';
import { Box } from '@forward-financing/fast-forward';

export const PaymentScheduleIframeable = (): JSX.Element => {
  return (
    <Box p={2}>
      <PaymentScheduleContainer />
    </Box>
  );
};
