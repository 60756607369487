import React from 'react';
import { Params, useParams } from 'react-router-dom';
import {
  Banner,
  Flex,
  PageContainer,
  Subheading,
} from '@forward-financing/fast-forward';
import {
  useOwner,
  useOwnerLexisNexisReport,
} from '../OwnerLexisNexisPage/ownerLexisNexisPageHooks';
import { OwnerLexisNexisPage } from '../OwnerLexisNexisPage/OwnerLexisNexisPage';

export const OwnerLNOverviewPageContainer = (): JSX.Element => {
  const { submissionUuid, ownerUuid } = useParams<keyof Params>() as Params;
  const { data: owner, error: errorOwner } = useOwner(ownerUuid);
  const {
    data: ownerLexisNexisReports,
    error: errorOwnerLexisNexisReports,
    responseReady: currentOwnerReportsResponseReady,
  } = useOwnerLexisNexisReport(submissionUuid);

  const handleBackToSubmission = (): void => {
    const submissionUrl = new URL(
      `/submissions/${submissionUuid}`,
      window.location.origin
    );
    window.location.replace(submissionUrl.toString());
  };

  const currentOwnerReport = ownerLexisNexisReports?.find(
    (report) => report.ownerUuid === ownerUuid
  );

  if (!currentOwnerReportsResponseReady && !currentOwnerReport) {
    return (
      <Flex alignContent={'center'}>
        <Subheading>There is no LexisNexis report available</Subheading>
      </Flex>
    );
  }

  const heading = owner && `${owner.fullName}'s Lexis Nexis Report`;
  return (
    <PageContainer>
      {errorOwnerLexisNexisReports && (
        <Banner dismissable={false}>
          {errorOwnerLexisNexisReports.message}
        </Banner>
      )}
      {errorOwner && <Banner dismissable={false}>{errorOwner.message}</Banner>}
      {currentOwnerReport && (
        <OwnerLexisNexisPage
          report={currentOwnerReport}
          handleBack={handleBackToSubmission}
          submissionUuid={submissionUuid}
          heading={heading}
        />
      )}
    </PageContainer>
  );
};
