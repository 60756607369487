import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { REACT_APP_WAITING_ROOM_BASE_URL } from 'constants/globals';
// CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO PRODUCTION.
// Uncomment these lines for mock data, and comment out the code below it.
// import { mockApiV1ApplicationQueueItemResponse } from 'mocks/waitingRoom/generators/ApiV1ApplicationsUuid';
import {
  ApplicationResponse,
  AssignApplicationRequestBody,
  ApplicationQueueItemResponse,
  SubmissionOnboardingQueueResponse,
  UpdateApplicationRequestBody,
} from 'types/api/waitingRoom/types';

const waitingRoom = (path: string): URL =>
  new URL(path, REACT_APP_WAITING_ROOM_BASE_URL());

export const fetchSubmissionOnboardingQueueApplications = async (
  applicationStatus: string,
  page: number,
  search?: string
): Promise<SubmissionOnboardingQueueResponse> => {
  const url = waitingRoom(
    `/api/v1/onboarding-queue?onboarding-status=${applicationStatus}&page=${page}${
      search ? `&search=${search}` : ''
    }`
  );

  const response =
    await makeInternalAPIRequest<SubmissionOnboardingQueueResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch queued Applications'
    );
  }

  return response.json();
};

export const fetchSubmissionOnboardingQueueApplication = async (
  applicationUuid: string
): Promise<ApplicationQueueItemResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(
  //       mockApiV1ApplicationQueueItemResponse(),
  //     );
  //   }, 5000);
  // });
  const url = waitingRoom(`/api/v1/onboarding-queue/${applicationUuid}`);

  const response = await makeInternalAPIRequest<ApplicationQueueItemResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Application for Waiting Room'
    );
  }

  return response.json();
};

export const assignNextApplication = async (
  body: AssignApplicationRequestBody
): Promise<ApplicationQueueItemResponse> => {
  const url = waitingRoom(`/api/v1/onboarding-queue/assign-to-next`);

  const response = await makeInternalAPIRequest<
    ApplicationQueueItemResponse,
    AssignApplicationRequestBody
  >(url, 'PUT', body);

  if (response.status === 204) {
    throw new NetworkError(
      response.status,
      'No Applications available to assign'
    );
  }

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to assign Application');
  }

  return response.json();
};

export const patchApplication = async (
  applicationUuid: string,
  body: UpdateApplicationRequestBody,
  source?: 'underwriting'
): Promise<ApplicationResponse> => {
  const url = waitingRoom(
    `api/v1/applications/${applicationUuid}${source ? `?source=${source}` : ''}`
  );

  const response = await makeInternalAPIRequest<
    ApplicationResponse,
    UpdateApplicationRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update Application');
  }

  return response.json();
};
