import { useCallback } from 'react';
import {
  fetchLexisNexisCustomerOverviews,
  fetchLexisNexisCustomerReports,
} from 'api/underwriting/lexisNexisCustomerFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  LexisNexisCustomerOverview,
  LexisNexisCustomerReportResponse,
} from 'types/api/underwriting/types';

export type UseGetApiLexisNexisCustomerReportsResponse =
  UseGenericQueryResponse<LexisNexisCustomerReportResponse>;

export const useGetApiLexisNexisCustomerReports = (
  submissionUuid: string
): UseGetApiLexisNexisCustomerReportsResponse => {
  const loadData = useCallback(() => {
    return fetchLexisNexisCustomerReports(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export type UseGetApiLexisNexisCustomerOverviews = UseGenericQueryResponse<
  LexisNexisCustomerOverview[]
>;

export const useGetApiLexisNexisCustomerOverviews = (
  submissionUuid: string
): UseGetApiLexisNexisCustomerOverviews => {
  const loadData = useCallback(() => {
    return fetchLexisNexisCustomerOverviews(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};
