import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { ML_DATA_PROVIDER_BASE_URL } from 'constants/globals';
import { InferenceResponse, PullInferenceResponse } from 'types/api/mldp/types';

export const fetchInference = async (
  submissionUuid: string
): Promise<InferenceResponse> => {
  const url = new URL(
    `/inference/${submissionUuid}`,
    ML_DATA_PROVIDER_BASE_URL()
  );

  const response = await makeInternalAPIRequest<InferenceResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch V6 Scores');
  }

  return response.json();
};

export const pullInference = async (
  submissionUuid: string
): Promise<PullInferenceResponse> => {
  const url = new URL(`/inference`, ML_DATA_PROVIDER_BASE_URL());

  const response = await makeInternalAPIRequest<PullInferenceResponse>(
    url,
    'POST',
    { submission_uuid: submissionUuid }
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull V6 Scores');
  }

  return response.json();
};
