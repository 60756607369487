import { toDate } from 'helpers/string/dateUtils';
import isAfter from 'date-fns/isAfter';
import sub from 'date-fns/sub';

export const hasOpenApproval = (
  subStage: string,
  dateAppReceived: string | undefined
): boolean => {
  if (!dateAppReceived || subStage !== 'Approved') {
    return false;
  }

  const splitDate = dateAppReceived.split('T')[0];

  const date = toDate(splitDate);

  return isAfter(date, sub(Date.now(), { days: 30 }));
};
