import React from 'react';
import {
  Accordion,
  Banner,
  Box,
  Grid,
  Heading,
  Icon,
  PageContainer,
} from '@forward-financing/fast-forward';
import { useReturnCodes } from './achReturnsContainerHooks';
import { AchReturnsByType } from './achReturns.types';
import { ReturnCodeTables } from './ReturnCodeTables/ReturnCodeTables';
import { AchReturnsTable } from './AchReturnsTable/AchReturnsTable';
import { AchReturnsMonthlyTable } from './AchReturnsMonthlyTable/AchReturnsMonthlyTable';

const WARNING_THRESHOLD = 0.135;
const DANGER_THRESHOLD = 0.15;

const calculateCurrentMonthPercentage = (
  sortedReturns: AchReturnsByType | undefined
): number => {
  if (!sortedReturns) {
    return -1;
  }

  const currentMonthReturns = sortedReturns[Object.keys(sortedReturns)[0]];

  if (
    currentMonthReturns?.returnedCount &&
    currentMonthReturns?.totalPaybacksCount
  ) {
    return (
      currentMonthReturns.returnedCount / currentMonthReturns.totalPaybacksCount
    );
  } else {
    return -1;
  }
};

// Rather than taking the keys and sorting those in a separate array, we
// can simply sort the entire returnsByType object by date here and then
// do whatever we need with this data afterwards.
// ES6 will preserve the order of the keys in the object, which is why
// we're able to do this.
const sortReturnsByType = (
  returns: AchReturnsByType | undefined
): AchReturnsByType => {
  if (!returns) {
    return {};
  }

  const sortedReturns: AchReturnsByType = {};

  Object.keys(returns)
    .sort((a, b) => {
      const aDate = new Date(a);
      const bDate = new Date(b);

      return bDate.getTime() - aDate.getTime();
    })
    .forEach((date) => {
      sortedReturns[date] = returns[date];
    });

  return sortedReturns;
};

export const AchReturnsContainer = (): JSX.Element => {
  const {
    data: achWorksData,
    responseReady: achWorksResponseReady,
    error: achWorksError,
  } = useReturnCodes('ach_works');
  const {
    data: iCheckGatewayData,
    responseReady: iCheckGatewayResponseReady,
    error: iCheckGatewayError,
  } = useReturnCodes('i_check_gateway');

  const sortedAchWorksReturnsByType: AchReturnsByType = sortReturnsByType(
    achWorksData?.returnsByType
  );

  const sortedIcheckGatewayReturnsByType: AchReturnsByType = sortReturnsByType(
    iCheckGatewayData?.returnsByType
  );

  return (
    <PageContainer data-testid="ach-returns-container">
      <Heading>ACH Returns</Heading>

      <Box pb={4}>
        <Accordion type={'multiple'} defaultValue={[]}>
          <Accordion.Item value="ach-works">
            <Accordion.Trigger>
              ACH Works{' '}
              {calculateCurrentMonthPercentage(sortedAchWorksReturnsByType) >=
                WARNING_THRESHOLD && <Icon name="warning" color="danger" />}
            </Accordion.Trigger>

            <Accordion.Content>
              <Grid gutter>
                {achWorksError && (
                  <Grid.Item l={12} xl={12}>
                    <Banner variant="danger" dismissable={false}>
                      {achWorksError.message}
                    </Banner>
                  </Grid.Item>
                )}

                <Grid.Item l={6} xl={6}>
                  <AchReturnsTable
                    dangerThreshold={DANGER_THRESHOLD}
                    warningThreshold={WARNING_THRESHOLD}
                    sortedReturns={sortedAchWorksReturnsByType}
                    responseReady={achWorksResponseReady}
                    currentMonthPercentage={calculateCurrentMonthPercentage(
                      sortedAchWorksReturnsByType
                    )}
                  />
                </Grid.Item>

                <Grid.Item l={6} xl={6}>
                  <ReturnCodeTables
                    codes={achWorksData?.codes}
                    responseReady={achWorksResponseReady}
                  />
                </Grid.Item>

                <Grid.Item l={12} xl={12}>
                  <Box pb={4}>
                    <AchReturnsMonthlyTable
                      returnsByCode={achWorksData?.returnsByCode}
                      responseReady={achWorksResponseReady}
                    />
                  </Box>
                </Grid.Item>
              </Grid>
            </Accordion.Content>
          </Accordion.Item>

          <Accordion.Item value="icheck-gateway">
            <Accordion.Trigger>
              iCheck Gateway{' '}
              {calculateCurrentMonthPercentage(
                sortedIcheckGatewayReturnsByType
              ) >= WARNING_THRESHOLD && <Icon name="warning" color="danger" />}
            </Accordion.Trigger>

            <Accordion.Content>
              <Grid gutter>
                {iCheckGatewayError && (
                  <Grid.Item l={12} xl={12}>
                    <Banner variant="danger" dismissable={false}>
                      {iCheckGatewayError.message}
                    </Banner>
                  </Grid.Item>
                )}

                <Grid.Item l={6} xl={6}>
                  <AchReturnsTable
                    dangerThreshold={DANGER_THRESHOLD}
                    warningThreshold={WARNING_THRESHOLD}
                    sortedReturns={sortedIcheckGatewayReturnsByType}
                    responseReady={iCheckGatewayResponseReady}
                    currentMonthPercentage={calculateCurrentMonthPercentage(
                      sortedIcheckGatewayReturnsByType
                    )}
                  />
                </Grid.Item>

                <Grid.Item l={6} xl={6}>
                  <ReturnCodeTables
                    codes={iCheckGatewayData?.codes}
                    responseReady={iCheckGatewayResponseReady}
                  />
                </Grid.Item>

                <Grid.Item l={12} xl={12}>
                  <Box pb={4}>
                    <AchReturnsMonthlyTable
                      returnsByCode={iCheckGatewayData?.returnsByCode}
                      responseReady={iCheckGatewayResponseReady}
                    />
                  </Box>
                </Grid.Item>
              </Grid>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      </Box>
    </PageContainer>
  );
};
