import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Icon,
  PageContainer,
  Tabs,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';

import { useBusinessCustomerReports } from './businessPublicRecordsHooks';
import { BusinessPublicRecords } from './BusinessPublicRecords';
/*
Page url: http://localhost:3001/dashboard/submissions/sf-opportunity-uuid/business-overview/sample-document-id/public_records
*/
type Params = {
  submissionUuid: string;
};

export const BusinessPublicRecordsPageContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }

  const { data: customerPublicDocuments } =
    useBusinessCustomerReports(submissionUuid);

  return (
    <PageContainer>
      <Heading>Business Public Records</Heading>
      {!customerPublicDocuments || customerPublicDocuments?.length === 0 ? (
        <Text>There are no public records</Text>
      ) : (
        <Tabs defaultValue={customerPublicDocuments[0]?.documentId?.toString()}>
          <Tabs.List>
            {customerPublicDocuments.map((doc) => (
              <Tabs.Trigger
                key={doc.documentId}
                value={doc.documentId.toString()}
              >
                <Flex gap={2}>
                  {doc.primary && <Icon name="flag" />}
                  {doc.companyName
                    ? doc.companyName
                    : `No Name available`} - {formatDateString(doc.createdAt)}
                </Flex>
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {customerPublicDocuments.map((doc) => (
            <Tabs.Content
              key={doc.documentId}
              value={doc.documentId.toString()}
            >
              <Box>
                <BusinessPublicRecords
                  liens={doc.lienJudgments}
                  bankruptcies={doc.bankruptcies}
                />
              </Box>
            </Tabs.Content>
          ))}
        </Tabs>
      )}
    </PageContainer>
  );
};
