import { useEffect, useState } from 'react';
import { useRafInterval, useEventListener } from 'ahooks';
import { featureFlags } from '../helpers/featureFlags';

interface ScrollPosition {
  x: number;
  y: number;
}
/**
 * `useScrollRestoration` is a custom hook for preserving the scroll position of
 * a page and restoring it after the page is refreshed. Useful in situations
 * affecting user experience due to page refreshes, e.g., when positioned to a
 * specific section of the application page. It uses session storage to save and
 * retrieve the scroll position. We use session storage because it is cleared
 * when the browser is closed, so it's a good place to store temporary data.
 * If we used local storage, the scroll position would be saved indefinitely,
 * and we would have to come up witha way to clean it up.
 *
 * Note: This hook is better tested manually in a browser, as it interacts with
 * browser properties and events not replicable in frameworks like Jest. Local
 * storage is also not supported in Jest.
 *
 * @param {string} key Unique identifier for the specific scroll position
 * to be saved. Used to differentiate scroll positions where there are
 * multiple scrollable sections in the application. Typically you set a key
 * in your component that is unique to the component, e.g., the component name.
 * @returns {void} No return value. It saves and restores the scroll
 * position based on page interactions.
 *
 * @example
 * const MyComponent = () => {
 *   useScrollRestoration('my-component');
 *   // ... component logic
 * };
 *
 * @see {@link https://ahooks.js.org/|ahooks} for hooks like
 * `useRafInterval` and `useEventListener` from the ahooks library.
 */
export const useScrollRestoration = (key: string): void => {
  const [scrollHeight, setScrollHeight] = useState<boolean | number>(false);
  const [timesChecked, setTimesChecked] = useState<number>(0);

  const sessionStorageKey = `scrollPosition-${key}`;

  // Save current scroll position.
  const saveScrollPosition = (): void => {
    if (!featureFlags.use_scroll_restoration) {
      return;
    }

    const scrollPosition: ScrollPosition = {
      x: window.scrollX,
      y: window.scrollY,
    };

    sessionStorage.setItem(sessionStorageKey, JSON.stringify(scrollPosition));
  };

  // Returns the saved scroll position.
  const getSavedScrollPosition = (): ScrollPosition | undefined => {
    if (!featureFlags.use_scroll_restoration) {
      return undefined;
    }

    const storedItem: string | undefined =
      sessionStorage.getItem(sessionStorageKey) ?? undefined;

    let scrollPositionSaved: ScrollPosition | undefined = undefined;

    if (storedItem) {
      scrollPositionSaved = JSON.parse(storedItem) as ScrollPosition;
    }

    return scrollPositionSaved;
  };

  // Save scroll position on pagehide (refresh, close, etc.)
  useEventListener('pagehide', saveScrollPosition);

  // Detect and save changes to document scroll height.
  const clearAnimationFrame = useRafInterval(() => {
    if (!featureFlags.use_scroll_restoration) {
      return;
    }

    const currentScrollHeight = document.body.scrollHeight;

    setTimesChecked(timesChecked + 1);

    if (currentScrollHeight !== scrollHeight) {
      setScrollHeight(currentScrollHeight);
    }
  }, 100);

  // Cancel checking for document scroll height changes after 100 checks, which
  // is 10 seconds. That's almost always enough time for all data to load. And
  // after that we don't want to keep checking for changes because the chance of
  // the user having scrolled already continues to increase. Repositioning
  // after the user has already scrolled would be unexpected and a bad UX.
  // You might be tempted to try to detect if the user has scrolled, but that's
  // not possible. The scroll event also fires when we programmatically scroll,
  // and there's no way to differentiate between this hook causing that event to
  // fire or the user causing it to fire.
  useEffect(() => {
    if (!featureFlags.use_scroll_restoration) {
      return;
    }

    if (timesChecked > 100) {
      clearAnimationFrame();
    }
  }, [timesChecked]);

  // Restore the saved scroll position, once it is within the bounds of the
  // document height.
  useEffect(() => {
    if (!featureFlags.use_scroll_restoration) {
      return;
    }

    const scrollPositionSaved = getSavedScrollPosition();

    if (scrollPositionSaved) {
      const scrollPositionX = Number(scrollPositionSaved.x);
      const scrollPositionY = Number(scrollPositionSaved.y);

      // Only restore scroll position if it's within the bounds of the document.
      // We also restore the "left" scroll position as a courtesy if the user
      // has a narrow window and has scrolled horizontally, however this is
      // far less important than vertical scroll, so we don't try to detect for
      // it.
      if (
        typeof scrollHeight === 'number' &&
        scrollPositionY < scrollHeight &&
        scrollPositionY > 0
      ) {
        window.scrollTo({
          top: scrollPositionY,
          left: scrollPositionX,
          behavior: 'auto',
        });
      }
    }
  }, [scrollHeight]);
};
