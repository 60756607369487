/**
 * Iterates over a series of months, executing a callback function for each
 * month.
 *
 * The callback function is passed a date object for each month and year, with
 * the day set to around the third of the month. For shorter months the day
 * is probably set to the second of the month. In practice, the day should be
 * ignored.
 *
 * You can omit either of the options (start or months) and a default will
 * still be set for it.
 *
 * The iteration goes from the most recent month to the month furthest in the
 * past.
 *
 * Functionality that was left out that could be implemented if you need it:
 * - Iterating forwards: add a `forwards` boolean parameter and use it to
 *   determine whether to add or subtract months.
 * - Similar functions for quarters, week numbers of the year, or days (e.g
 *   previous 30 days).
 *
 */
export const forEachMonth = (
  callback: (date: Date) => void,
  options?: {
    start?: Date;
    months?: number;
  }
): void => {
  // The default option object is set in the function signature, but if you
  // specify the options object, you can leave off any of the options. In that
  // case, the options object will be missing that property, so we set the
  // defaults for that scenario here.
  const { start = new Date(), months = 12 } = options || {};

  for (let i = 0; i < months; i++) {
    // New date must have the 3rd day of the month. If you use the current
    // date and it is the 31st, the month subtraction will skip months that have
    // less than 31 days. If you use the 1st of the month you risk it skipping a
    // previous month due to timezones. Yes, this is slightly hacky but JS
    // dates are notoriously difficult to work with. The alternative is to add a
    // bucketload of tests, so we will live with this minor hack.
    const monthDate = new Date(
      // slice(0, 7) returns the year and month in YYYY-MM format.
      // '-03' sets the day to the 3rd of the month.
      start.toISOString().slice(0, 7) + '-03'
    );

    monthDate.setMonth(monthDate.getMonth() - i);
    callback(monthDate);
  }
};

/**
 * Generate short month names along with year for the last twelve months.
 * In decsending order.
 *
 * You might be tempted to add various options to this to make it more generic
 * or flexible. That might be a mistake. Instead, think of this like the
 * fast-forward components. This standardizes the format we should
 * use to display rolling twelve month periods for reports.
 *
 * @example ['Mar 2023', 'Feb 2023', 'Jan 2023', Dec 2022, ...]
 */
export const generateLastTwelveMonthNames = (): string[] => {
  const monthNames: string[] = [];

  // Use the imported version so that tests using fake timers will work.
  forEachMonth((month) => {
    monthNames.push(
      month.toLocaleString('en-US', { month: 'short', year: 'numeric' })
    );
  });
  return monthNames;
};

/**
 * A string array that contains all the months of the year, starting from January.
 */
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
