export class NetworkError extends Error {
  readonly statusCode: Response['status'];

  constructor(statusCode: Response['status'], message = 'Network error') {
    super(`${statusCode}: ${message}`);

    this.statusCode = statusCode;

    // improve the stack trace
    // optional chaining because this is a V8-only feature
    // https://v8.dev/docs/stack-trace-api#stack-trace-collection-for-custom-exceptions
    Error.captureStackTrace?.(this, this.constructor);
  }
}
