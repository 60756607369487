import React from 'react';
import { Box, Divider, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { CorporateHistory } from './BusinessLexisNexisPage.types';

type CorporateHistoriesTableProps = {
  corporateHistories?: CorporateHistory[];
};

export const CorporateHistoriesTable = ({
  corporateHistories,
}: CorporateHistoriesTableProps): JSX.Element => {
  const hasHistories = corporateHistories && corporateHistories.length > 0;

  const lastHistoryIndex = corporateHistories?.length
    ? corporateHistories.length - 1
    : undefined;

  return hasHistories ? (
    <Table caption="Corporate Histories">
      <Table.Body>
        {corporateHistories?.map((history, index) => (
          <React.Fragment key={`history.${history.companyName}`}>
            <Table.Row>
              <Table.RowHeader>Company Name</Table.RowHeader>
              <Table.Cell>{history.companyName}</Table.Cell>
              <Table.RowHeader>Incorporate Date</Table.RowHeader>
              <Table.Cell>{history.incorporateDate}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Address</Table.RowHeader>
              <Table.Cell>{history.address}</Table.Cell>
              <Table.RowHeader>Types</Table.RowHeader>
              <Table.Cell>
                <>
                  {history.entityType && (
                    <Text>Entity: {history.entityType}</Text>
                  )}
                  {history.businessType && (
                    <Text>Business: {history.businessType}</Text>
                  )}
                  {history.additionalInfo && (
                    <Text>Additional Info: {history.additionalInfo}</Text>
                  )}
                </>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Status</Table.RowHeader>
              <Table.Cell>{history.status}</Table.Cell>
              <Table.RowHeader>Record Date</Table.RowHeader>
              <Table.Cell>{history.recordDate}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Name Type</Table.RowHeader>
              <Table.Cell>{history.nameType}</Table.Cell>
              <Table.RowHeader>Status Date</Table.RowHeader>
              <Table.Cell>{history.statusDate}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Registered Agent</Table.RowHeader>
              <Table.Cell>{history.registeredAgent}</Table.Cell>
              <Table.RowHeader>As Agent Date</Table.RowHeader>
              <Table.Cell>{history.asAgentDate}</Table.Cell>
            </Table.Row>

            {index !== lastHistoryIndex && (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <Divider orientation="horizontal" />
                </Table.Cell>
              </Table.Row>
            )}
          </React.Fragment>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Corporate Histories')}</Text>
    </Box>
  );
};
