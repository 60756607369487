import React from 'react';
import { Submission } from './navbar.types';
import { Navbar } from '@forward-financing/fast-forward';
import { FUNDING_URL } from 'constants/globals';

export interface NavbarFundingLinkProps {
  submission: Submission | undefined;
}
export const NavbarFundingLink = ({
  submission,
}: NavbarFundingLinkProps): JSX.Element => {
  const inFundedStage =
    ['Funded', 'Returned Funds'].includes(submission?.stage || '') ||
    ['Returned Funds', 'In Funding Queue', 'Payment Update'].includes(
      submission?.subStage || ''
    );

  if (!submission || !inFundedStage) {
    return <></>;
  }

  return (
    <Navbar.Link
      icon="sack-dollar"
      newTab
      href={new URL(`admin/advances/${submission.uuid}`, FUNDING_URL())}
    >
      Funding
    </Navbar.Link>
  );
};
