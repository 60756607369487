import { useState, useEffect, useCallback } from 'react';
import { toError } from 'helpers/errorUtils';
import {
  AchProcessors,
  AdvanceProfile,
  BankInfo,
  PaymentSchedule as PaymentScheduleType,
} from './paymentSchedule.types';
import {
  getAdvanceProfile,
  getBankInfo,
  getPaymentSchedule,
} from './paymentScheduleFetchUtils';

export const useGetAdvanceProfile = (
  advanceRecordId: number
): {
  loading: boolean;
  profile?: AdvanceProfile;
  error?: string;
  refetch: () => Promise<void>;
} => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<AdvanceProfile | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const advanceProfile = await getAdvanceProfile(advanceRecordId);
      setProfile(advanceProfile);
    } catch (e: unknown) {
      const errorObj = toError(e);

      setError(errorObj.message);
    } finally {
      setLoading(false);
    }
  }, [advanceRecordId]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  return {
    profile,
    error,
    loading,
    refetch: fetchData,
  };
};

export const useGetBankInfo = (
  advanceRecordId: number
): {
  loading: boolean;
  bankInfo?: BankInfo;
  achProcessors?: AchProcessors;
  error?: string;
  refetch: () => Promise<void>;
} => {
  const [loading, setLoading] = useState(true);
  const [achProcessors, setAchProcessors] = useState<string[] | undefined>(
    undefined
  );

  const [bankInfo, setBankInfo] = useState<BankInfo | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const bankInfoResponse = await getBankInfo(advanceRecordId);
      setBankInfo(bankInfoResponse.bankInfo);
      setAchProcessors(bankInfoResponse.achProcessors);
    } catch (e: unknown) {
      const errorObj = toError(e);
      setError(errorObj.message);
    } finally {
      setLoading(false);
    }
  }, [advanceRecordId]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  return {
    achProcessors,
    bankInfo,
    error,
    loading,
    refetch: fetchData,
  };
};

export const useGetPaymentSchedule = (
  advanceRecordId: number
): {
  loading: boolean;
  schedule?: PaymentScheduleType;
  error?: string;
  refetch: () => Promise<void>;
} => {
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState<PaymentScheduleType | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const paymentSchedule = await getPaymentSchedule(advanceRecordId);
      setSchedule(paymentSchedule);
    } catch (e: unknown) {
      const error = toError(e);

      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }, [advanceRecordId]);

  useEffect(() => {
    void fetchData();
  }, [advanceRecordId, fetchData]);

  return {
    schedule,
    error: errorMessage,
    loading,
    refetch: fetchData,
  };
};
