import React from 'react';
import { NewDealScore } from '../RenewalScoring.types';
import { ScoreTable } from './ScoreTable';
import { Text, formatDateString } from '@forward-financing/fast-forward';

export interface OriginalAdvanceScoreTableProps {
  score: NewDealScore;
}

export const OriginalAdvanceScoreTable = (
  props: OriginalAdvanceScoreTableProps
): JSX.Element => {
  const data = [
    {
      id: 2,
      label: 'Owner Risk',
      value: props.score.ownerRisk,
    },
    {
      id: 3,
      label: 'Business Risk',
      value: props.score.businessRisk,
    },
    {
      id: 4,
      label: 'Quality of Cash Flows',
      value: props.score.qualityOfCashFlows,
    },
  ];

  return (
    <>
      <ScoreTable
        title={`Original Advance: ${formatDateString(
          props.score.dealFundedOn
        )}`}
        data={data}
        maxScore={5}
        averageScore={props.score.averageScore}
      />
      {(props.score.dealSpecificFactors || props.score.onlinePresence) && (
        <Text>
          * This deal was scored based on a previous, now deprecated version of
          the guidelines.
        </Text>
      )}
    </>
  );
};
