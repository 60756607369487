import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import { Button, Icon, Loading, Flex } from '@forward-financing/fast-forward';

import { BorderedContainer } from '../../shared/BorderedContainer';
import { WebPresenceBorderedContainer } from '../../shared/WebPresenceBorderedContainer/WebPresenceBorderedContainer';
import { FormRow, DisplayStyle } from '../../shared/form/FormRow';

import { Dropdown } from '../../shared/form/Dropdown';
import {
  DropdownOption,
  HTMLFormChangeEvent,
  HTMLFormChangeOrMouseEvent,
} from '../../../types/form';
import { IsoPopover } from '../AccountInformationForm/IsoPopover';

import {
  Opportunity,
  Submission,
  WebPresenceType,
  IndustryType,
} from '../../../api/UnderwritingClient/codecs';
import { User } from '../../../api/AuthClient/codecs';
import { Partner } from '../../../api/FundingClient/codecs';
import { HashMap } from '../../../api/codecs';
import { isAdmin } from '../../../helpers/utils';

// Inputs
import { Input } from '../../shared/form/Input';
import { TextArea } from '../../shared/form/TextArea';
import { WebPresenceInput } from '../../shared/form/WebPresenceInput';

// Validations
import {
  isRequired,
  isBiggerThan,
} from '../../../helpers/validations/ValidationHelpers';
import {
  validate,
  validateCapitalNeeded,
  validateDate,
  validField,
  validateEmail,
  validateURL,
} from '../../../helpers/validations/FieldValidator';
import { ValidationResult } from '../../../helpers/validations/codecs';
import {
  ENTITY_TYPES,
  USE_OF_FUNDS,
  DATE_FORMAT,
} from '../../../constants/globals';
import { EntityTypePopover } from './EntityTypePopover';

import { IsoSalesRepPopover } from './IsoSalesRepPopover';
import { BusinessStartDatePopover } from './BusinessStartDatePopover';
import { EmailContentPopover } from './EmailContentPopover';

export interface AdditionalInformationFormProps {
  partner_id: number;
  submission: Submission;
  opportunity: Pick<Opportunity, 'type' | 'renewal_submitter_email'>;
  industryTypes: IndustryType[];
  partners: Partner[];
  handleApplicationChange: (e: HTMLFormChangeEvent) => void;
  handleAccountChange: (e: HTMLFormChangeOrMouseEvent) => void;
  handleOpportunityChange?: (e: HTMLFormChangeOrMouseEvent) => void;
  handleWebPresenceChange: (e: HTMLFormChangeOrMouseEvent) => void;
  onBack: () => void;
  onSubmit: () => void;
  declinedIcon: () => JSX.Element;
  forPrequal: boolean;
  loggedInUser: User;
}

export const AdditionalInformationForm = ({
  partner_id,
  submission,
  opportunity,
  industryTypes: industryTypesProp,
  partners: partnersProp,
  handleAccountChange,
  handleApplicationChange,
  handleWebPresenceChange,
  handleOpportunityChange,
  onBack,
  onSubmit,
  declinedIcon,
  forPrequal,
  loggedInUser,
}: AdditionalInformationFormProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const industryTypes: DropdownOption[] = [
    {
      value: 0,
      label: 'Select a Business Type',
    },
    ...industryTypesProp.map((industryType) => ({
      value: industryType.id,
      label: industryType.name,
    })),
  ];

  const partners: Partner[] = [
    {
      value: 0,
      label: 'Select a Partner',
      email: '',
      uuid: '',
    },
    ...partnersProp,
  ];

  const entityTypes: DropdownOption[] = [
    {
      value: '',
      label: 'Select an Entity Type',
    },
    ...ENTITY_TYPES,
  ];

  const useOfFunds: DropdownOption[] = [
    {
      value: '',
      label: 'Select Use of Funds',
    },
    ...USE_OF_FUNDS,
  ];

  const account = submission.account;

  const handleCapitalValue = (): string => {
    return submission.capitalNeededNotPresent ? '' : submission.capital_needed;
  };

  const handleCapitalNotPresentChange = (
    e: HTMLFormChangeOrMouseEvent
  ): void => {
    handleApplicationChange(e);

    const target = (
      e.currentTarget ? e.currentTarget : e.target
    ) as HTMLInputElement;

    if (target.checked) {
      const syntheticEvent = {
        persist: () => {
          // Intentionally empty
        },
        target: {
          name: 'capital_needed',
          value: '',
        },
      };

      handleApplicationChange(syntheticEvent as HTMLFormChangeEvent);
    }
  };

  const findPartner = (id: string | number): Partner | undefined => {
    return partnersProp.find((p) => p.value === Number(id));
  };

  const updateSalesRepEmail = (partner?: Partner): void => {
    const syntheticEventEmail = {
      persist: () => {
        // Intentionally empty
      },
      target: {
        name: 'sales_rep_email',
        value: partner ? partner.email : null,
      },
    };

    handleApplicationChange(syntheticEventEmail as HTMLFormChangeEvent);
  };

  const handleSelectPartner = (
    selectedOption: SingleValue<DropdownOption>
  ): void => {
    // We want to set both the partnerID and the partner UUID when this field is selected
    const syntheticEventId = {
      persist: () => {
        // Intentionally empty
      },
      currentTarget: {
        name: 'partner_id',
        value: (selectedOption as DropdownOption).value.toString(),
        type: 'change',
      },
    };

    handleApplicationChange(syntheticEventId as HTMLFormChangeEvent);

    const partner = findPartner((selectedOption as DropdownOption).value);

    const syntheticEventUUID = {
      persist: () => {
        // Intentionally empty
      },
      target: {
        name: 'partner_uuid',
        value: partner ? partner.uuid : null,
      },
    };

    handleApplicationChange(syntheticEventUUID as HTMLFormChangeEvent);
    updateSalesRepEmail(partner);
  };

  const validationErrors: HashMap<ValidationResult> = {
    capital_needed: submission.capitalNeededNotPresent
      ? validField
      : validateCapitalNeeded(submission.capital_needed),
    loan_use: validField,
    entity_type: validate([isRequired], account.entity_type),
    industry_type: validate(
      [isRequired, isBiggerThan(0)],
      account.industry_id ? account.industry_id.toString() : ''
    ),
    started_on: account.started_on
      ? validateDate(
          account.started_on || '',
          'business',
          'Valid Business Start Date is Required'
        )
      : validField,
    notes: forPrequal ? validate([isRequired], submission.notes) : validField,
    business_website: validateURL(account.web_presences?.business_website?.url),
    facebook: validateURL(account.web_presences?.facebook?.url),
    instagram: validateURL(account.web_presences?.instagram?.url),
    other: validateURL(account.web_presences?.other?.url),
    yelp: validateURL(account.web_presences?.yelp?.url),
    sales_rep_email: submission.sales_rep_email
      ? validateEmail(submission.sales_rep_email)
      : validField,
    renewal_submitter_email:
      opportunity.type === 'Renewal'
        ? validateEmail(opportunity.renewal_submitter_email)
        : validField,
    partnerId: validate(
      [isRequired, isBiggerThan(0)],
      partner_id ? partner_id.toString() : ''
    ),
  };

  const isValidAdditionalInfo = Object.values(validationErrors).every(
    (result) => result.hasValue && result.isValid
  );

  const handleSubmit = (e: React.MouseEvent): void => {
    e.preventDefault();

    if (isValidAdditionalInfo) {
      setIsLoading(true);
      onSubmit();
    }
  };

  const partnerSelectIsEmpty =
    validationErrors.partnerId && !validationErrors.partnerId.hasValue;

  useEffect(() => {
    if (partner_id && !submission.sales_rep_email) {
      const partner = findPartner(partner_id);
      if (partner?.email) {
        updateSalesRepEmail(partner);
      }
    }
  });

  return (
    <div className="additional-information-form">
      <BorderedContainer
        label="Additional Information"
        labelIcon={declinedIcon()}
      >
        <div className="columns">
          <div className="column" />
        </div>

        <FormRow
          left={
            <Input
              label="Business Start Date"
              name="started_on"
              value={account.started_on || ''}
              inputMask="99/99/9999"
              moreInfoPopover={<BusinessStartDatePopover />}
              placeholder={DATE_FORMAT}
              onChange={handleAccountChange}
              validationResult={validationErrors.started_on}
            />
          }
          right={
            <Input
              leadingIcon="fas fa-dollar-sign"
              label="Amount Needed"
              name="capital_needed"
              checkBox={{
                label: 'Amount needed not on application',
                name: 'capitalNeededNotPresent',
                value: submission.capitalNeededNotPresent || false,
                checkboxLabelSize: 'is-half',
                onClick: handleCapitalNotPresentChange,
              }}
              onChange={handleApplicationChange}
              value={handleCapitalValue()}
              validationResult={validationErrors.capital_needed}
              isDisabled={submission.capitalNeededNotPresent}
            />
          }
        />

        <FormRow
          left={
            <Flex flexDirection={'column'} gap={1}>
              <Dropdown
                label="Business Type"
                name="industry_id"
                options={industryTypes}
                onChange={(e) => {
                  handleAccountChange(e);
                }}
                validationResult={validationErrors.industry_type}
                value={account.industry_id || ''}
              />
            </Flex>
          }
          right={
            <Dropdown
              label="Use of Funds"
              name="loan_use"
              options={useOfFunds}
              onChange={handleApplicationChange}
              validationResult={validationErrors.loan_use}
              value={submission.loan_use}
            />
          }
        />

        <FormRow
          left={
            <Dropdown
              label="Entity Type"
              name="entity_type"
              options={entityTypes}
              moreInfoPopover={<EntityTypePopover />}
              onChange={handleAccountChange}
              validationResult={validationErrors.entity_type}
              value={account.entity_type || ''}
            />
          }
          right={
            <TextArea
              label="Email Content"
              name="notes"
              moreInfoPopover={<EmailContentPopover />}
              value={submission.notes || ''}
              onChange={handleApplicationChange}
              validationResult={validationErrors.notes}
            />
          }
        />
        <FormRow
          left={
            <div className="field">
              <label className={`has-text-weight-bold`}>
                <Flex>
                  {partnerSelectIsEmpty && <Icon name="exclamation-circle" />}
                  Partner
                  <IsoPopover />
                </Flex>
              </label>
              <Select
                aria-label="Partner"
                value={findPartner(partner_id)}
                name="partner_id"
                options={partners}
                onChange={handleSelectPartner}
                isDisabled={isAdmin(loggedInUser) ? false : !forPrequal}
                isSearchable={true}
                placeholder="Select a Partner"
                classNamePrefix="partner"
                className={partnerSelectIsEmpty ? 'is-danger' : ''}
              />
            </div>
          }
          right={
            <Input
              label="Partner Sales Rep Email"
              name="sales_rep_email"
              moreInfoPopover={<IsoSalesRepPopover />}
              onChange={handleApplicationChange}
              value={submission.sales_rep_email || ''}
              validationResult={validationErrors.sales_rep_email}
            />
          }
        />

        {opportunity.type === 'Renewal' ? (
          <FormRow
            displayStyle={DisplayStyle.SingleRight}
            right={
              <Input
                label="Renewal Submitter Email"
                name="renewal_submitter_email"
                onChange={handleOpportunityChange}
                value={opportunity.renewal_submitter_email || ''}
                validationResult={validationErrors.renewal_submitter_email}
              />
            }
          />
        ) : (
          <></>
        )}

        {forPrequal ? (
          <></>
        ) : (
          <WebPresenceBorderedContainer label="Social Presence">
            <FormRow
              left={
                <WebPresenceInput
                  name={WebPresenceType.Website}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Website]?.url || ''
                  }
                  leadingComponent={<Icon name="globe" />}
                  validationResult={validationErrors.business_website}
                />
              }
              right={
                <WebPresenceInput
                  name={WebPresenceType.Facebook}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Facebook]?.url || ''
                  }
                  leadingComponent={<Icon prefix="fab" name="facebook" />}
                  validationResult={validationErrors.facebook}
                />
              }
            />

            <FormRow
              left={
                <WebPresenceInput
                  name={WebPresenceType.Instagram}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Instagram]?.url ||
                    ''
                  }
                  leadingComponent={<Icon prefix="fab" name="instagram" />}
                  validationResult={validationErrors.instagram}
                />
              }
              right={
                <WebPresenceInput
                  name={WebPresenceType.Yelp}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Yelp]?.url || ''
                  }
                  leadingComponent={<Icon prefix="fab" name="yelp" />}
                  validationResult={validationErrors.yelp}
                />
              }
            />

            <FormRow
              left={
                <WebPresenceInput
                  name={WebPresenceType.Other}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Other]?.url || ''
                  }
                  leadingComponent={
                    <span className="other has-text-weight-bold">Other</span>
                  }
                  validationResult={validationErrors.other}
                />
              }
            />
          </WebPresenceBorderedContainer>
        )}
      </BorderedContainer>
      <div className="additional-navigation-actions columns">
        <div className="column">
          {submission.prequal_state_attributes.other_info_completed ? null : (
            <Button startIcon="arrow-left" onClick={onBack}>
              Back
            </Button>
          )}
        </div>
        <div className="column has-text-right">
          {isLoading ? (
            <Loading size="small" />
          ) : (
            <Button
              endIcon="arrow-right"
              disabled={!isValidAdditionalInfo}
              onClick={handleSubmit}
            >
              Proceed to Overview
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
