import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Grid,
  Heading,
  Loading,
  PageContainer,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';

import { useTradelinesDetails } from './tradelinesDetailsHooks';
import { TradelinesTable } from './TradelinesTable';

type Params = {
  submissionUuid: string;
  ownerUuid: string;
};

export const TradelinesDetailsContainer = (): JSX.Element => {
  const { ownerUuid, submissionUuid } = useParams<Params>() as Params;

  const { data, loading, error, responseReady } = useTradelinesDetails(
    submissionUuid,
    ownerUuid
  );

  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const toggleExpandableRows = (): void => {
    setIsAllExpanded((prev) => !prev);
  };

  return (
    <PageContainer>
      {error && <Banner>{error.message}</Banner>}

      <Heading>Experian - Trade Lines Detail</Heading>

      {loading && <Loading />}

      {responseReady && data && (
        <Grid gutter>
          <Grid.Item xs={12}>
            <Box mt={2}>
              <Button onClick={toggleExpandableRows}>{`${
                isAllExpanded ? 'Collapse' : 'Expand'
              } all tradelines`}</Button>
            </Box>
          </Grid.Item>
          <Grid.Item xs={12}>
            <Subheading>
              <Text bold> Open Primary Trade Lines</Text>
            </Subheading>

            <TradelinesTable
              tradelinesDetails={data.open}
              emptyMessage="There are no open primary trade lines"
              isAllRowsExpanded={isAllExpanded}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <Subheading>
              <Text bold> Closed Primary Trade Lines</Text>
            </Subheading>

            <TradelinesTable
              tradelinesDetails={data.closed}
              emptyMessage="There are no closed primary trade lines"
              isAllRowsExpanded={isAllExpanded}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <Subheading>
              <Text bold> Non-Primary Trade Lines</Text>
            </Subheading>

            <TradelinesTable
              tradelinesDetails={data.nonPrimary}
              emptyMessage="There are no non-primary trade lines"
              isAllRowsExpanded={isAllExpanded}
            />
          </Grid.Item>
        </Grid>
      )}
    </PageContainer>
  );
};
