import React from 'react';
import classNames from 'classnames';

export interface CheckboxProps {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  className?: string;
  checked?: boolean;
}

export const Checkbox = (props: CheckboxProps): JSX.Element => (
  <div
    className={classNames('column is-full-width field', props.className || '')}
  >
    <label className="checkbox">
      <span>{props.label}</span>{' '}
      <input
        type="checkbox"
        name={props.name || ''}
        onChange={props.onChange}
        disabled={props.disabled}
        checked={props.checked}
      />
    </label>
  </div>
);
