import React from 'react';
import { Button, Tooltip } from '@forward-financing/fast-forward';
import { AnalyticsGatewayClient } from 'api/AnalyticsGatewayClient';
import { downloadAllSubmissionAttachments } from 'api/underwriting/attachmentFetchUtils';
import { NetworkError } from 'api/networkError';
import { useUserContext } from 'contexts/UserContext';
import { toError } from 'helpers/errorUtils';
import { AttachmentSource } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';

export interface DownloadAllAttachmentsButtonProps {
  source: AttachmentSource;
  setErrorMessage: (errorMessage: string) => void;
  disabledForDocumentRetention: boolean;
}

export const DownloadAllAttachmentsButton = ({
  source,
  setErrorMessage,
  disabledForDocumentRetention,
}: DownloadAllAttachmentsButtonProps): JSX.Element => {
  const user = useUserContext();
  const { primaryId } = useAttachmentManagerContext();

  const sendLogsToAnalyticsGateway = async (): Promise<void> => {
    try {
      const logData = {
        field_name: 'Download All attachments',
        object_uuid: primaryId,
        name: 'Download All',
        action: 'Download All',
        object_type: 'SalesforceOpportunity',
      };

      return await AnalyticsGatewayClient.createLogSender(
        user,
        'Attachments Manager'
      )(logData);
    } catch {
      // return a promise to make TS happy.
      // don't bubble error any further because
      // we still want user to be able to do the download
      return Promise.resolve();
    }
  };

  const handleDownloadAll = async (): Promise<void> => {
    try {
      await sendLogsToAnalyticsGateway();
      const responseUrl = await downloadAllSubmissionAttachments(
        primaryId,
        source
      );

      if (responseUrl) {
        window.open(responseUrl);
      }
    } catch (e: unknown) {
      if (e instanceof NetworkError && e.statusCode === 422) {
        setErrorMessage(
          'Files are not ready for download yet. Please try again in a few seconds.'
        );
      } else {
        const error = toError(e);
        setErrorMessage(error.message);
      }
    }
  };

  if (disabledForDocumentRetention) {
    return (
      <Tooltip
        content="Download all not available due to document retention policy"
        trigger={<Button startIcon="download">Download all attachments</Button>}
      />
    );
  }

  return (
    <Button startIcon="download" onClick={handleDownloadAll}>
      Download all attachments
    </Button>
  );
};
