import React from 'react';
import { Icon, Link } from '@forward-financing/fast-forward';
import { FUNDING_URL } from 'constants/globals';
import { usePaymentScheduleContext } from './PaymentScheduleContext';

export const PaymentHistoryPdfDownloadLink = (): JSX.Element => {
  const { advanceRecordId } = usePaymentScheduleContext();

  return (
    <Link
      newTab
      href={
        new URL(
          `/admin/advances/${advanceRecordId}.pdf?source=payback`,
          FUNDING_URL()
        )
      }
    >
      Payment History PDF <Icon name="file-pdf" />
    </Link>
  );
};
