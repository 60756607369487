import React from 'react';
import { Navbar } from '@forward-financing/fast-forward';
import { Link, LinkProps } from 'react-router-dom';
import { NavbarItemProps } from '@forward-financing/fast-forward/dist/Navbar/NavItem';

export const InternalNavbarLink = ({
  icon,
  ...rest
}: NavbarItemProps & LinkProps): JSX.Element => (
  <Navbar.NavItemWrapper icon={icon}>
    <Link {...rest} />
  </Navbar.NavItemWrapper>
);
