import { HashMap } from '../codecs';
import {
  ANALYTICS_GATEWAY_API_KEY,
  ANALYTICS_GATEWAY_BASE_URL,
} from '../../constants/globals';
import { LogData } from './codecs';
import { HTTPClient } from '../HTTPClient';
import { User } from '../AuthClient/codecs';
import { formatDateToIso8601 } from '../../helpers/string/dateUtils';

export class AnalyticsGatewayClient extends HTTPClient {
  private static BASE_URL = ANALYTICS_GATEWAY_BASE_URL() || '';
  private static API_KEY = ANALYTICS_GATEWAY_API_KEY() || '';

  static async createLog(logData: LogData): Promise<void> {
    const url = `${AnalyticsGatewayClient.BASE_URL}api/v1/access_logs`;
    await AnalyticsGatewayClient.post<HashMap<string | undefined>, void>(
      url,
      logData,
      {
        headers: { 'X-AUTH-TOKEN': AnalyticsGatewayClient.API_KEY },
      }
    );
  }

  static createLogSender = (user: User, source: string) => {
    return (data: LogData): Promise<void> => {
      return AnalyticsGatewayClient.createLog({
        ...data,
        user_email: user.email,
        user_uuid: user.uuid,
        source,
        timestamp: formatDateToIso8601(new Date()),
      });
    };
  };
}
