import { Box } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BusinessCredit } from '.';

interface BusinessCreditContainerProps {
  uuidType?: string | null;
}

type Params = {
  referenceId?: string;
};

export const BusinessCreditContainer = (
  props: BusinessCreditContainerProps
): JSX.Element => {
  const { referenceId }: Params = useParams();

  let referenceType: string | undefined;
  if (props.uuidType) {
    referenceType = props.uuidType;
  }

  return (
    <Box marginX={4} marginY={2}>
      <BusinessCredit referenceId={referenceId} referenceType={referenceType} />
    </Box>
  );
};
