import { useCallback } from 'react';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { fetchIndustryRisks } from 'api/underwriting/industryFetchUtils';
import { IndustryRiskProfile } from 'types/api/underwriting/types';

// No explicit tests because it already has 100% coverage thanks to indirect
// testing via other components.
export const useGetApiIndustryRisks = (): UseGenericQueryResponse<
  IndustryRiskProfile[]
> => {
  const fetcher = useCallback(() => {
    return fetchIndustryRisks();
  }, []);

  return useGenericQuery(fetcher);
};
