import React from 'react';
import { Navbar } from '@forward-financing/fast-forward';
import { AUTH_BASE_URL } from 'constants/globals';
import { useUserContext } from 'contexts/UserContext';

export const NavbarAccountDropdown = (): JSX.Element => {
  const user = useUserContext();

  return (
    <Navbar.Dropdown label={`${user.first_name} ${user.last_name}`} icon="user">
      <Navbar.DropdownActionItem
        id="signout"
        text="Sign Out"
        onSelect={() => window.location.replace(`${AUTH_BASE_URL()}/sign_out`)}
      />
    </Navbar.Dropdown>
  );
};
