import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ACCESS_API_URL, AUTH_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';

const authLink = setContext(
  (_, { headers }: { headers: Record<string, unknown> }) => {
    // get the authentication token from local storage if it exists
    const tokenJson = localStorage.getItem('user_short_token');
    const token = tokenJson
      ? (JSON.parse(tokenJson) as { shortToken: string }).shortToken
      : '';
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Token ${token}` : '',
      },
    };
  }
);

const httpLink = createHttpLink({
  uri: ACCESS_API_URL(),
  credentials: 'include',
  fetchOptions: {
    mode: 'cors',
  },
  // Use explicit `window.fetch` so that outgoing requests
  // are captured and deferred until the Service Worker is ready.
  fetch: (...args) => fetch(...args),
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.extensions?.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED':
          // Redirect to Login screen here if invalid token
          window.location.href = new URL('sign_in', AUTH_BASE_URL()).toString();
          break;
      }
    }
  }
});

// TODO: Once we no longer need to feature flag using
// graphql, stop exporting this.
export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

interface ApolloContextProps {
  children: React.ReactNode;
}

export const ApolloContext = (props: ApolloContextProps): JSX.Element => {
  if (featureFlags.graphql_enabled) {
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  }

  return <>{props.children}</>;
};
