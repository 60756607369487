import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Card,
  Flex,
  IconButton,
  Text,
  TextArea,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { useUpdateSubmissionEmailContent } from './submissionEmailContentHooks';
import { EmailContentSubmission } from './submissionEmailContentContainer.types';

export interface SubmissionEmailContentContainerProps {
  submission: EmailContentSubmission;
}

const cardTitle = 'Submission Email Content';

export const SubmissionEmailContentContainer = ({
  submission,
}: SubmissionEmailContentContainerProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState('');
  const { role } = useUserContext();

  const [
    updateEmailContent,
    { emailContent: updatedEmailContent, error: updateError },
  ] = useUpdateSubmissionEmailContent(submission.uuid);

  const displayedValue = updatedEmailContent ?? submission?.emailContent;

  const onEditClick = (): void => {
    setIsEditing(true);
    setValue(displayedValue ?? '');
  };

  const onSave = async (): Promise<void> => {
    const { success } = await updateEmailContent({
      emailContent: value,
    });

    if (success) {
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <Card title={cardTitle}>
        {updateError && (
          <Banner variant="error" dismissable={false}>
            {updateError.message}
          </Banner>
        )}
        <Box>
          <TextArea
            label="Submission Email Content"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={5}
          />
          <Flex justifyContent="flex-end" gap={2}>
            <Button variant="secondary" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button onClick={onSave}>Save</Button>
          </Flex>
        </Box>
      </Card>
    );
  }

  return (
    <Card
      title={cardTitle}
      actions={
        ['admin', 'processing'].includes(role) ? (
          <IconButton
            label="Edit Email Content"
            icon="pencil"
            onClick={onEditClick}
          />
        ) : undefined
      }
    >
      <Box rows={10}>
        <Text preserveNewLines>{displayedValue ?? 'N/A'}</Text>
      </Box>
    </Card>
  );
};
