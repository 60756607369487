import React from 'react';

import {
  Box,
  Flex,
  Heading,
  Icon,
  PageContainer,
  Tabs,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import { LexisNexisPersonalPublicDocument } from './types';
import { CriminalFilingsTable } from './CriminalFilingsTable';

export interface CriminalFilingsPageProps {
  ownerPublicDocuments?: LexisNexisPersonalPublicDocument[];
}
export const CriminalFilingsPage = ({
  ownerPublicDocuments,
}: CriminalFilingsPageProps): JSX.Element => {
  return (
    <PageContainer>
      <Heading>Criminal Filings</Heading>
      {!ownerPublicDocuments || ownerPublicDocuments?.length === 0 ? (
        <Text>There are no criminal filings</Text>
      ) : (
        <Tabs defaultValue={ownerPublicDocuments[0]?.documentId?.toString()}>
          <Tabs.List>
            {ownerPublicDocuments.map((doc) => (
              <Tabs.Trigger
                key={doc.documentId}
                value={doc.documentId.toString()}
              >
                <Flex gap={2}>
                  {doc.primary && <Icon name="flag" />}
                  {doc.individualBestName
                    ? doc.individualBestName
                    : `No Name available`}{' '}
                  - {formatDateString(doc.createdAt)}
                </Flex>
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {ownerPublicDocuments.map((doc) => (
            <Tabs.Content
              key={doc.documentId}
              value={doc.documentId.toString()}
            >
              <Box backgroundColor="gray-200" padding={3}>
                <CriminalFilingsTable criminalFilings={doc.criminals} />
              </Box>
            </Tabs.Content>
          ))}
        </Tabs>
      )}
    </PageContainer>
  );
};
