export const canAccessPaymentSchedule = (role: string): boolean => {
  return [
    'manager',
    'admin',
    'jr_collections_rep',
    'admin_ach_read_write',
    'admin_ach_read',
    'super_admin',
    'customer_engagement_reps',
  ].includes(role);
};

// AdvancePrepaymentForm
export const canApplyPrepaymentDiscount = (role: string): boolean => {
  return [
    'admin',
    'admin_ach_read_write',
    'admin_ach_read',
    'super_admin',
  ].includes(role);
};
