import React from 'react';
import { Navbar } from '@forward-financing/fast-forward';
import { Submission } from './navbar.types';

export interface NavbarPaymentHistoryLinkProps {
  submission: Submission | undefined;
}
export const NavbarPaymentHistoryLink = ({
  submission,
}: NavbarPaymentHistoryLinkProps): JSX.Element => {
  if (submission?.type !== 'Renewal') {
    return <></>;
  }

  return (
    <Navbar.Link
      icon="file-invoice"
      newTab
      href={
        new URL(
          `${window.location.origin}/admin/customer/${submission.accountUuid}/payment_history`
        )
      }
    >
      Payment History
    </Navbar.Link>
  );
};
