import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Currency,
  Flex,
  formatDateString,
  Grid,
  Icon,
  Radio,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { ScheduleAdjustmentComparison } from '../paymentSchedule.types';

export const CompareAndSelect = (): JSX.Element => {
  const { adjustmentProjections, updateFormState } =
    usePaymentScheduleContext();

  const [selectedAdjustment, setSelectedAdjustment] =
    useState<ScheduleAdjustmentComparison | null>(null);

  const onConfirmClick = (): void => {
    updateFormState({
      selectedAdjustment:
        selectedAdjustment ??
        /* istanbul ignore next: not actually possible to hit this undefined */
        undefined,
    });
  };

  const handleChange = (newLabel: string): void => {
    const newValue = adjustmentProjections?.find(
      (option) => option.label === newLabel
    );
    setSelectedAdjustment(
      newValue ??
        /* istanbul ignore next: not actually possible to hit this null */
        null
    );
  };

  return (
    <Flex flexDirection="column" gap={2}>
      <Radio
        name="Compare & Select"
        value={selectedAdjustment?.label ?? ''}
        onChange={handleChange}
      >
        {adjustmentProjections?.map((option) => {
          return (
            <Radio.Item
              key={option.label}
              id={option.label}
              value={option.label}
              disabled={!!option.errors?.length}
            >
              <Grid>
                {option.errors?.length && option.errors.length > 0 && (
                  <Grid.Item xs={12}>
                    <Banner variant="warning" dismissable={false}>
                      <Flex gap={2} alignItems="center">
                        <Icon name="warning" color="warning" />
                        {option.errors.join(', ')}
                      </Flex>
                    </Banner>
                  </Grid.Item>
                )}
                <Grid.Item xs={3}>
                  <Box>
                    <Subheading variant="subsection">Adjustment</Subheading>
                    <Text>{option.label}</Text>
                  </Box>
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Box>
                    <Subheading variant="subsection">
                      Adjusted Amount
                    </Subheading>
                    <Text>
                      <Currency amount={option.amount} />
                    </Text>
                  </Box>
                </Grid.Item>
                <Grid.Item xs={5}>
                  <Box>
                    <Subheading variant="subsection">
                      Estimated Payoff Date
                    </Subheading>
                    <Text>
                      {option.estimatedPayoffDate
                        ? formatDateString(option.estimatedPayoffDate)
                        : 'N/A'}
                    </Text>
                  </Box>
                </Grid.Item>
              </Grid>
            </Radio.Item>
          );
        })}
      </Radio>
      <Flex justifyContent="center">
        <Button
          disabled={!selectedAdjustment}
          startIcon="check"
          onClick={onConfirmClick}
        >
          Apply Selected Schedule
        </Button>
      </Flex>
    </Flex>
  );
};
