import React from 'react';
import { Table } from '@forward-financing/fast-forward';

import { TradelineDetails } from '../types';

type DelinquencyCounterTableProps = {
  tradeline: TradelineDetails;
};

export const DelinquencyCounterTable = ({
  tradeline,
}: DelinquencyCounterTableProps): JSX.Element => {
  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader colSpan={4} textAlign="center">
          Delinquency Counter
        </Table.ColumnHeader>
      </Table.Head>

      <Table.Head>
        <Table.ColumnHeader>30</Table.ColumnHeader>
        <Table.ColumnHeader>60</Table.ColumnHeader>
        <Table.ColumnHeader>90</Table.ColumnHeader>
        <Table.ColumnHeader>Derog</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.Cell>{tradeline.delinquenciesOver30Days}</Table.Cell>
          <Table.Cell>{tradeline.delinquenciesOver60Days}</Table.Cell>
          <Table.Cell>{tradeline.delinquenciesOver90Days}</Table.Cell>
          <Table.Cell>{tradeline.derogCounter}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
