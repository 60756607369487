import React from 'react';
import { EstablishedDates } from './applicationSnapshot.types';
import {
  Popover,
  IconButton,
  Table,
  Box,
  formatDateString,
  IconButtonProps,
} from '@forward-financing/fast-forward';

export interface EstablishedDatesPopoverProps {
  establishedDates: EstablishedDates;
}

export const EstablishedDatesPopover = ({
  establishedDates,
}: EstablishedDatesPopoverProps): JSX.Element => {
  if (!establishedDates.lexisNexis) {
    return <></>;
  }

  const icon: IconButtonProps['icon'] =
    establishedDates.lexisNexis.lexisNexisDayDifference > 90
      ? 'exclamation-triangle'
      : 'info-circle';

  return (
    <Popover
      header="Verified Dates"
      trigger={<IconButton icon={icon} label="Show verified dates" />}
      content={
        <Box>
          <Table caption="Verified Establishment Dates">
            <Table.Head>
              <Table.ColumnHeader>Recorder</Table.ColumnHeader>
              <Table.ColumnHeader>Date</Table.ColumnHeader>
              <Table.ColumnHeader abbr="Difference">Diff</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Lexis Nexis</Table.Cell>
                <Table.Cell>
                  {formatDateString(
                    establishedDates.lexisNexis.lexisNexisStartDate
                  )}
                </Table.Cell>
                <Table.Cell>
                  {establishedDates.lexisNexis.lexisNexisYearDifference} Years
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      }
    />
  );
};
