import React, { useState } from 'react';
import {
  Banner,
  Button,
  Divider,
  Flex,
  Modal,
  Select,
  Subheading,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';

import { useUnderwritersProvider } from './UnderwritingAttachmentManagerContext';
import { useUserContext } from 'contexts/UserContext';

export interface UnderwritterSignOffModalProps {
  setUnderwriterSignOff: (underwriterSignOffId: number) => void;
}

export const UnderwritingSignOffModal = (
  props: UnderwritterSignOffModalProps
): JSX.Element => {
  const { sub_role } = useUserContext();
  const { underwriters } = useUnderwritersProvider();
  const [underwriterId, setUnderwriterId] = useState('');

  const [error, setError] = useState<string | undefined>();
  const [isUwSignOffModalOpen, setIsUwSignOffModalOpen] =
    useState<boolean>(false);

  const disableUwSignOffButton =
    !sub_role?.includes('vca') && !sub_role?.includes('underwriter');
  const handleOpenChange = (): void => {
    if (isUwSignOffModalOpen) {
      setError('');
    }

    setUnderwriterId('');
    setIsUwSignOffModalOpen((open) => !open);
  };

  const handleSubmit = (): void => {
    try {
      props.setUnderwriterSignOff(parseInt(underwriterId, 10));
      setError('');
      handleOpenChange();
    } catch (e) {
      const err = toError(e);
      setError(err.toString());
    }
  };

  return (
    <Modal
      title="Final Sign Off"
      isOpen={isUwSignOffModalOpen}
      onOpenChange={handleOpenChange}
      trigger={
        <Button startIcon="check" disabled={disableUwSignOffButton}>
          UW sign off
        </Button>
      }
      body={
        <Flex flexDirection={'column'} gap={3} p={4}>
          {error && (
            <Banner dismissable={false} variant="error">
              {error}
            </Banner>
          )}

          <Flex justifyContent={'center'} alignContent={'center'}>
            <Subheading>Select Underwriter for Final UW Sign Off</Subheading>
          </Flex>
          <Divider />
          <Select
            label="Underwriter"
            value={underwriterId}
            options={underwriters
              .map((user) => ({
                value: `${user.id}`,
                text: `${user.first_name} ${user.last_name}`,
              }))
              .sort((a, b) => a.text.localeCompare(b.text))}
            required={true}
            onValueChange={setUnderwriterId}
          />
          <Divider />
          <Flex justifyContent={'center'} gap={3}>
            <Button onClick={handleSubmit} startIcon="check" disabled={false}>
              Save
            </Button>
            <Button
              onClick={handleOpenChange}
              startIcon="times"
              disabled={false}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};
