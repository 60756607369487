import {
  Banner,
  Grid,
  Loading,
  Table,
  useScreenSize,
} from '@forward-financing/fast-forward';
import React from 'react';
import { useIndustryRisks } from './applicationSnapshotFetchHooks';
import { RiskProfile } from './applicationSnapshot.types';

const industryRiskMap = {
  low_risk: {
    color: 'green-200',
    displayName: 'Low Risk',
  },
  moderate_risk: {
    color: 'orange-200',
    displayName: 'Moderate Risk',
  },
  high_risk: {
    color: 'orange-600',
    displayName: 'High Risk',
  },
  prohibited: {
    color: 'red-300',
    displayName: 'Prohibited',
  },
  preferred: {
    color: undefined,
    displayName: 'Preferred',
  },
  archived: {
    color: 'gray-400',
    displayName: 'Archived',
  },
} as const;

const InnerTable = ({
  industries,
}: {
  industries: RiskProfile[];
}): JSX.Element => {
  return (
    <Table caption={'Industry Risk Table'}>
      <Table.Head>
        <Table.ColumnHeader>Industry Type</Table.ColumnHeader>
        <Table.ColumnHeader>Industry Risk</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {industries &&
          industries.map((industry) => {
            const object = industry.archived
              ? industryRiskMap.archived
              : industryRiskMap[
                  industry.pricingRiskProfile?.replace(
                    ' ',
                    '_'
                  ) as keyof typeof industryRiskMap
                ];

            return (
              <Table.Row key={industry.id}>
                <Table.Cell>{industry.name}</Table.Cell>
                <Table.Cell backgroundColor={object?.color}>
                  {object?.displayName}
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};

export const IndustryRiskTable = (): JSX.Element => {
  const { data: industries, loading, error } = useIndustryRisks();
  const { isLOrSmaller } = useScreenSize();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Banner dismissable={false} variant="error">
        {error.message}
      </Banner>
    );
  }

  // istanbul ignore next
  if (!industries) {
    return (
      <Banner dismissable={false} variant="error">
        Something has gone terribly wrong, and we are in a state that should be
        impossible. Please create an AST.
      </Banner>
    );
  }

  if (isLOrSmaller) {
    return <InnerTable industries={industries} />;
  }

  return (
    <Grid gutter>
      <Grid.Item xs={6}>
        <InnerTable industries={industries.slice(0, industries.length / 2)} />
      </Grid.Item>
      <Grid.Item xs={6}>
        <InnerTable industries={industries.slice(industries.length / 2)} />
      </Grid.Item>
    </Grid>
  );
};
