import {
  createAttachment,
  fetchAccount,
  removeAttachment,
  updateAttachment,
} from 'api/underwriting/attachmentFetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import {
  AccountResponse,
  CreateAttachmentRequestBody,
  UpdateAttachmentRequestBody,
} from 'types/api/underwriting/types';

export type UseGetApiAccountResponse = UseGenericQueryResponse<AccountResponse>;

export const useGetApiAccount = (
  applicationUuid: string
): UseGetApiAccountResponse => {
  const fetchFunction = useCallback(() => {
    return fetchAccount(applicationUuid);
  }, [applicationUuid]);

  return useGenericQuery<AccountResponse>(fetchFunction);
};

export interface UseCreateAttachmentArgs {
  submissionUuid: string;
  createBody: CreateAttachmentRequestBody;
}

export const useApiCreateAttachmentBatch = (): UseGenericMutationResponse<
  MutationResponse[],
  UseCreateAttachmentArgs[]
> => {
  const createAttachmentBatchFunction = (
    args: UseCreateAttachmentArgs[]
  ): Promise<MutationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, createBody }) =>
        createAttachment(submissionUuid, createBody)
      )
    );
  };

  return useGenericMutation(createAttachmentBatchFunction);
};

export interface UseDeleteAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
}

export const useApiDeleteAttachmentBatch = (): UseGenericMutationResponse<
  MutationResponse[],
  UseDeleteAttachmentArgs[]
> => {
  const deleteAttachmentBatchFunction = (
    args: UseDeleteAttachmentArgs[]
  ): Promise<MutationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, attachmentUuid }) =>
        removeAttachment(submissionUuid, attachmentUuid)
      )
    );
  };

  return useGenericMutation(deleteAttachmentBatchFunction);
};

export interface UseUpdateAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
  body: UpdateAttachmentRequestBody;
}

export const useApiUpdateAttachmentBatch = (): UseGenericMutationResponse<
  MutationResponse[],
  UseUpdateAttachmentArgs[]
> => {
  const updateAttachmentBatchFunction = (
    args: UseUpdateAttachmentArgs[]
  ): Promise<MutationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, attachmentUuid, body }) =>
        updateAttachment(submissionUuid, attachmentUuid, body)
      )
    );
  };

  return useGenericMutation(updateAttachmentBatchFunction);
};
