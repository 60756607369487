import React from 'react';
import { Box, Heading, Link, Text } from '@forward-financing/fast-forward';

export const LivePipelinePageMoved = (): JSX.Element => {
  return (
    <Box padding={4}>
      <Heading>The page you are looking for has been moved...</Heading>
      <Text>
        If you arrived here using a bookmark, we recommend updating it with the
        new URL!
      </Text>
      <Link
        href={
          new URL(
            window.location.href.replace(
              '/live-pipeline',
              '/dashboard/live-pipeline'
            )
          )
        }
      >
        Proceed to new Live Pipeline location
      </Link>
    </Box>
  );
};
