import {
  Banner,
  Button,
  Flex,
  Icon,
  Modal,
  OptionShape,
  Select,
  Subheading,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { useUpdateSubmission } from '../../Navbar/SubmissionNavbar/navbarFetchHooks';
import { isoCompetingSubMessageList } from '../../Navbar/SubmissionNavbar/constants';

export interface ToolboxIsoCompetingSubmissionModalProps {
  submissionUuid: string;
  submissionCompetingMessage: string | undefined;
  refetchSubmission: () => void;
}
export const ToolboxIsoCompetingSubmissionModal = ({
  submissionUuid,
  submissionCompetingMessage,
  refetchSubmission,
}: ToolboxIsoCompetingSubmissionModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isoCompetingSubMessage, setIsoCompetingSubMessage] = useState(
    submissionCompetingMessage
  );
  const [
    updateSubmission,
    { error: errorUpdateSubmission, loading: loadingUpdateSubmission },
  ] = useUpdateSubmission(submissionUuid);

  const handleOpenChange = (): void => {
    setIsOpen((prevState) => !prevState);
    setIsoCompetingSubMessage(submissionCompetingMessage);
  };

  const handleIsoCompetingSubMessageChange = async (): Promise<void> => {
    const { success } = await updateSubmission({
      isoCompetingSubMessage: isoCompetingSubMessage,
    });

    if (success) {
      setIsOpen(false);
      refetchSubmission();
    }
  };

  const isoCompetingSubMessageOptions: OptionShape[] =
    isoCompetingSubMessageList.map((opt) => ({ value: opt, label: opt }));

  return (
    <>
      <Modal
        title="ISO Competing Submission"
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        body={
          <>
            {errorUpdateSubmission && (
              <Banner dismissable={false} variant="error">
                <Flex gap={3}>
                  <Icon size="lg" name="triangle-exclamation" />
                  {errorUpdateSubmission.message}
                </Flex>
              </Banner>
            )}
            <Flex flexDirection={'column'} gap={3}>
              <Subheading variant="section">
                Please provide an ISO Competing Submission message
              </Subheading>
              <Select
                label="Select a message"
                options={isoCompetingSubMessageOptions}
                value={isoCompetingSubMessage || ''}
                onValueChange={setIsoCompetingSubMessage}
              />

              <Flex justifyContent="center" gap={2} m={3}>
                <Button
                  disabled={loadingUpdateSubmission}
                  startIcon={'compress-alt'}
                  onClick={handleIsoCompetingSubMessageChange}
                >
                  Save
                </Button>
                <Button
                  startIcon="x"
                  variant="secondary"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </>
        }
        trigger={
          <Button key="isoCompetingSub" startIcon="compress-alt">
            ISO Competing Submission
          </Button>
        }
      />
    </>
  );
};
