import { Banner, Card, Flex, Grid } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationSnapshotContainer } from './ApplicationSnapshot/ApplicationSnapshotContainer';
import { FraudScoringContainer } from './FraudScoring/FraudScoringContainer';
import { RiskFactorsContainer } from './RiskFactors/RiskFactorsContainer';
import { SubmissionEmailContentContainer } from './SubmissionEmailContent/SubmissionEmailContentContainer';
import { useGetSubmission } from './SubmissionUnderwritingContainerHooks';
import { UnderwritingAttachmentManager } from 'components/AttachmentManager/UnderwritingAttachmentManager/UnderwritingAttachmentManager';

type Params = {
  submissionUuid: string;
};

export const PhaseTwoRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const {
    data: submission,
    error: errorSubmission,
    refetch: refetchSubmission,
  } = useGetSubmission(submissionUuid);

  return (
    <Grid gutter>
      <Grid.Item m={6}>
        {errorSubmission && (
          <Banner dismissable={false} variant="error">
            {errorSubmission.message}
          </Banner>
        )}
        {submission && <ApplicationSnapshotContainer submission={submission} />}
      </Grid.Item>
      <Grid.Item m={6}>
        <Flex gap={3} flexDirection="column">
          {submission && (
            <SubmissionEmailContentContainer submission={submission} />
          )}
          <FraudScoringContainer submission={submission} />
          <RiskFactorsContainer submissionUuid={submissionUuid} />
        </Flex>
      </Grid.Item>
      <Grid.Item>
        <Card title="ISO Attachments" collapsible>
          <UnderwritingAttachmentManager
            submissionUuid={submissionUuid}
            submission={submission}
            refetchSubmission={refetchSubmission}
          />
        </Card>
      </Grid.Item>
    </Grid>
  );
};
