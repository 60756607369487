import { HashMap } from 'api/codecs';

const toNumericString = (val: number): string => Number(val).toFixed(0);
const toOneDecimalPoint = (val: number): string => Number(val).toFixed(1);
const toDollarAmount = (val: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // Removes decimals
    maximumFractionDigits: 0, // Ensures no decimals
  }).format(Math.round(val)); // Rounds to nearest dollar
};
const toPercentage = (val: number): string => {
  return (val * 100).toFixed(0) + '%';
};

type FeatureInformation = {
  description: string;
  displayFormatter: (val: number) => string;
};

export const v6ScoreFeatures: HashMap<FeatureInformation> = {
  BSMET_DEPOSIT_COUNT_MIN: {
    description: 'Minimum monthly deposit count over last 3 months',
    displayFormatter: toNumericString,
  },
  ENSCO_SCORE_VANTAGE_max: {
    description: 'Vantage score: consumer credit score',
    displayFormatter: toNumericString,
  },
  BSMET_DEPOSIT_DOLLARS_MAX: {
    description: 'Maximum monthly deposit dollars over last 3 months',
    displayFormatter: toDollarAmount,
  },
  BSMET_END_BALANCE_MIN: {
    description: 'Minimum monthly end balance over last 3 months',
    displayFormatter: toDollarAmount,
  },
  ENTRA_MOR_MAX_YEARS_SINCE_OPENED_max: {
    description: 'Age of oldest Mortgage tradeline',
    displayFormatter: toOneDecimalPoint,
  },
  ENSCO_SCORE_FICO_max: {
    description: 'FICO score: consumer credit score',
    displayFormatter: toNumericString,
  },
  ENINQ_INQUIRY_CNT_L90D_max: {
    description: 'Number of inquiries over last 90 days',
    displayFormatter: toNumericString,
  },
  PNSCO_PAYNET_SCORE: {
    description: 'Paynet score: business credit score',
    displayFormatter: toNumericString,
  },
  BSMET_NEGATIVE_DAYS_AVG: {
    description: 'Average number of days the bank account was < 0',
    displayFormatter: toNumericString,
  },
  ENTRA_PERC_CLOSED_LAST_PYMNT_MISSED_max: {
    description: 'Percent of closed tradelines with a delinquent status',
    displayFormatter: toPercentage,
  },
  EMSCO_INTELLISCORE: {
    description: 'Intelliscore: business credit score',
    displayFormatter: toNumericString,
  },
  ENADD_ADDRESS_YEARS_SINCE_FIRST_REPORTED_max: {
    description: 'Age of oldest reported personal address of the owner',
    displayFormatter: toOneDecimalPoint,
  },
  ENAGG_REVOLVING_BALANCE_max: {
    description: 'Dollar amount of revolving tradelines',
    displayFormatter: toDollarAmount,
  },
  EMAGG_SBCS1_IFTC01: {
    description:
      'Total number of Inquiries in the last 3 months for Financial credit purposes.',
    displayFormatter: toNumericString,
  },
  APBUS_YRS_IN_BUSINESS: {
    description: 'Time in business',
    displayFormatter: toOneDecimalPoint,
  },
  BSMUL_OTHER_POSITION_GROSS_DEPOSIT_DOLLARS: {
    description:
      'Total monthly payments to other funders as a percentage of monthly deposit dollars',
    displayFormatter: toPercentage,
  },
  ENTRA_AUT_MAX_YEARS_SINCE_OPENED_max: {
    description: 'Age of oldest Auto tradeline',
    displayFormatter: toOneDecimalPoint,
  },
  BSMET_NSFS_AVG: {
    description:
      'Average monthly non-sufficient fund charges over last 3 months',
    displayFormatter: toNumericString,
  },
  ENTRA_CRE_OTHER_USER_CNT_max: {
    description: 'Number of terminated credit cards and lines of credit',
    displayFormatter: toNumericString,
  },
  ENTRA_CREDIT_LIMIT_max: {
    description: 'Total credit limit of all open tradelines',
    displayFormatter: toDollarAmount,
  },
  BSMET_REVENUE_VELOCITY: {
    description:
      'Total revenue in last month as a percentage of total revenue over the last 3 months',
    displayFormatter: toPercentage,
  },
  EMSCO_YEARS_IN_FILE: {
    description: 'Years business has been in Experian file',
    displayFormatter: toOneDecimalPoint,
  },
  ENTRA_REV_OPEN_BALANCE_AMT_max: {
    description: 'Balance on open revolving tradelines',
    displayFormatter: toDollarAmount,
  },
  LPJUD_JUDGMENT_YEARS_SINCE_LAST_FILED_max: {
    description: 'Years since last judgment filed',
    displayFormatter: toOneDecimalPoint,
  },
  EMAGG_SBCS2_FTC245: {
    description:
      'Change of current balance over last 3 month average balance on FTC trades',
    displayFormatter: toDollarAmount,
  },
};
