import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { Executive } from './BusinessLexisNexisPage.types';

interface ExecutivesTableProps {
  executives?: Executive[];
}

export const ExecutivesTable = ({
  executives,
}: ExecutivesTableProps): JSX.Element => {
  const hasExecutives = executives && executives.length > 0;

  return hasExecutives ? (
    <Table caption="Executives">
      <Table.Head>
        <Table.ColumnHeader>Name</Table.ColumnHeader>
        <Table.ColumnHeader>Last Seen</Table.ColumnHeader>
        <Table.ColumnHeader>Title</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {executives?.map((executive) => (
          <Table.Row key={executive.name}>
            <Table.Cell>{executive.name}</Table.Cell>
            <Table.Cell>{executive.dateLastSeen}</Table.Cell>
            <Table.Cell>
              {executive.title.map((title) => (
                <Text key={title}>{title}</Text>
              ))}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Executives')}</Text>
    </Box>
  );
};
