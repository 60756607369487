import React from 'react';
import { Table } from '@forward-financing/fast-forward';

import { TradelineDetails } from '../types';

type CalendarTableProps = {
  tradeline: TradelineDetails;
};

const getLastUpdatedDate = (
  paidDate: Date | undefined,
  updatedDate: Date
): Date => {
  if (!paidDate) {
    return updatedDate;
  }

  return paidDate.getTime() > updatedDate.getTime() ? paidDate : updatedDate;
};

const getHistoryArray = (
  paymentHistory: string,
  lastUpdatedDate: Date
): string[] => {
  const historyArray = paymentHistory.split('');
  // We need to have actual month number instead of zero-based,
  // so we're adding +1 to convert 0 to 1 for January and etc..
  const latestUpdatedMonth = lastUpdatedDate.getMonth() + 1;

  // We have to add items from last updated month till Dec.
  for (let i = 0; i < 12 - latestUpdatedMonth; i++) {
    historyArray.unshift(' ');
  }

  while (historyArray.length % 36 !== 0) {
    historyArray.push(' ');
  }

  return historyArray;
};

const renderRows = (
  historyArray: string[],
  lastUpdatedOn: Date
): JSX.Element[] => {
  const rows = [];
  const numYears = historyArray.length / 12;

  for (let i = 0; i < numYears; i++) {
    const year = lastUpdatedOn.getFullYear() - i;
    const historyForYear = historyArray.slice(i * 12, i * 12 + 12).reverse();

    rows.push(
      <Table.Row key={i}>
        <Table.RowHeader>{year}</Table.RowHeader>
        {historyForYear.map((history) => (
          <Table.Cell key={`${i}${history}${year}`}>{history}</Table.Cell>
        ))}
      </Table.Row>
    );
  }

  return rows;
};

export const CalendarTable = ({
  tradeline,
}: CalendarTableProps): JSX.Element => {
  const { paymentHistory, lastPaidDate, statusUpdatedDate } = tradeline;

  const lastUpdated = getLastUpdatedDate(lastPaidDate, statusUpdatedDate);

  const historyArray = getHistoryArray(paymentHistory, lastUpdated);

  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader />
        <Table.ColumnHeader>Jan</Table.ColumnHeader>
        <Table.ColumnHeader>Feb</Table.ColumnHeader>
        <Table.ColumnHeader>Mar</Table.ColumnHeader>
        <Table.ColumnHeader>Apr</Table.ColumnHeader>
        <Table.ColumnHeader>May</Table.ColumnHeader>
        <Table.ColumnHeader>Jun</Table.ColumnHeader>
        <Table.ColumnHeader>Jul</Table.ColumnHeader>
        <Table.ColumnHeader>Aug</Table.ColumnHeader>
        <Table.ColumnHeader>Sep</Table.ColumnHeader>
        <Table.ColumnHeader>Oct</Table.ColumnHeader>
        <Table.ColumnHeader>Nov</Table.ColumnHeader>
        <Table.ColumnHeader>Dec</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>{renderRows(historyArray, lastUpdated)}</Table.Body>
    </Table>
  );
};
