import React from 'react';
import { Box, List, Subheading } from '@forward-financing/fast-forward';

export const OwnerRiskPopoverContent = (): JSX.Element => (
  <Box>
    <Subheading variant="subsection">Factors</Subheading>
    <List>
      <List.Item>Earliest Open Primary Tradeline</List.Item>
      <List.Item>Number of Open Primary Tradelines</List.Item>
      <List.Item>
        Primary tradelines with Recent Payment Issues or Closed in Bad Standing
      </List.Item>
      <List.Item>Mortgage Tradelines</List.Item>
      <List.Item>Caps: Vantage 1200</List.Item>
    </List>
  </Box>
);
