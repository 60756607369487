import { UNKNOWN_USER_UUID } from '../UserContext';

export type ViewHistoryModel = 'submissions';
export const MAX_HISTORY_SIZE_PER_MODEL = 20;
export const LOCAL_STORAGE_KEY = 'ff_viewHistoryStore';

export interface ViewHistoryEntry {
  objectUUID: string;
  title: string;
  timestamp: number;
}

export type ViewHistoryData = {
  userUUID: string;
} & {
  [M in ViewHistoryModel]: ViewHistoryEntry[];
};

export const createEmptyViewHistory = (): ViewHistoryData => ({
  userUUID: UNKNOWN_USER_UUID,
  submissions: [],
});

export abstract class ViewHistoryStore {
  /** gets all of the data in the store */
  abstract getAllData(): ViewHistoryData;

  /** sets all of the data in the store */
  abstract setAllData(data: ViewHistoryData): void;

  /** gets the UUID of the user associated with the data in the store */
  getUserUUID(): string {
    return this.getAllData().userUUID ?? UNKNOWN_USER_UUID;
  }

  /** sets UUID of the user associated with the data in the store */
  setUserUUID(userUUID: string): void {
    const currentData = this.getAllData();
    this.setAllData({ ...currentData, userUUID });
  }

  /** gets the view history for a specific model */
  getModelHistory(model: ViewHistoryModel): ViewHistoryEntry[] {
    const currentData = this.getAllData();
    return currentData[model];
  }

  /** sets the view history for a specific model */
  setModelHistory(model: ViewHistoryModel, entries: ViewHistoryEntry[]): void {
    const currentData = this.getAllData();
    this.setAllData({ ...currentData, [model]: entries });
  }

  /** reset all of the data in the store to the default values */
  reset(): void {
    this.setAllData(createEmptyViewHistory());
  }
}

export class InMemoryViewHistoryStore extends ViewHistoryStore {
  #data: ViewHistoryData;

  constructor(initialData: ViewHistoryData) {
    super();
    this.#data = initialData;
  }

  getAllData(): ViewHistoryData {
    return this.#data;
  }

  setAllData(data: ViewHistoryData): void {
    this.#data = data;
  }
}

export class LocalStorageViewHistoryStore extends ViewHistoryStore {
  getAllData(): ViewHistoryData {
    try {
      const existingData = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEY) || '{}'
      ) as ViewHistoryData;
      if (!existingData.userUUID) {
        // the store data is either missing or invalid, so reset the store and
        // return the reinitialized data.
        this.reset();
        return this.getAllData();
      }
      return existingData;
    } catch (error: unknown) {
      // the store's data is corrupted (most likely because it's not parseable
      // as JSON), so reset the store and return the reinitialized data.
      this.reset();
      return this.getAllData();
    }
  }

  setAllData(data: ViewHistoryData): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  }
}
