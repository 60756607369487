import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useUpdateApiSubmission } from 'apiHooks/underwriting/submissionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { Submission, User } from './SubmissionEditing.types';
import { UserByRoleResponse } from 'types/api/auth/types';
import { useGetApiUsersByRole } from 'apiHooks/auth/userFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';

export type UpdateSubmissionBody = {
  decisionAnalystId?: number;
  underwriter?: string;
  processingAnalystId?: number;
  allowDecisionAnalystOverwrite?: boolean;
};
type UseUpdateSubmissionStageResult = [
  (input: UpdateSubmissionBody) => Promise<MutationResponse>,
  { data?: Submission; error?: Error; loading: boolean }
];

export const useUpdateSubmission = (
  submissionUuid: string
): UseUpdateSubmissionStageResult => {
  const [updateSubmission, { error, loading }] = useUpdateApiSubmission();

  const updateFunction = (
    args: UpdateSubmissionBody
  ): Promise<MutationResponse> => {
    return updateSubmission({
      submissionUuid,
      updateBody: {
        decision_analyst_id: args.decisionAnalystId,
        x2dc_underwriter__c: args.underwriter,
        processing_analyst_id: args.processingAnalystId,
        allow_decision_analyst_overwrite: args.allowDecisionAnalystOverwrite,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

const toUsers = (user: UserByRoleResponse): User => {
  return {
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
    role: user.role,
    subRole: user.sub_role,
  };
};

const mappingUsers = (
  users: UserByRoleResponse[] | undefined
): User[] | undefined => users?.map(toUsers);

export const useGetUsersByRole = (
  role?: string
): UseGenericQueryResponse<User[]> =>
  useGenericFeatureQuery(useGetApiUsersByRole, mappingUsers, role);
