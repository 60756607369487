import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { FUNDING_BASE_URL } from 'constants/globals';
import { AchReturnCodesResponse } from 'types/api/funding/types';

export const fetchReturnCodes = async (
  provider: 'ach_works' | 'i_check_gateway'
): Promise<AchReturnCodesResponse> => {
  const url = new URL(`/api/v2/ach/return_codes`, FUNDING_BASE_URL());
  url.searchParams.append('provider', provider);

  const response = await makeInternalAPIRequest<AchReturnCodesResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Failed to fetch return codes for provider ${provider}`
    );
  }

  return response.json();
};
