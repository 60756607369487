// Tips on how to use string/dateUtils
//
// string/dateUtils is primary setup to help with dateStrings.
// dateStrings are just strings that represent dates.
// dateStrings used here are in any format not just those given by Date#toDateString()
//
// Here are a few examples of the types of functions that belong here:
// Ex. formatDateString => 'YYYY-MM-DD' to 'DD/MM/YYYY'
// Ex. getYear => would work with many types of date string formats
//  2021 <= 'Fri Apr 16 2021 08:15:54 GMT-0600 (Mountain Daylight Time)'
//  2021 <= '2021-02-01'
//  2021 <= '01/02/21'
//
// Learn more about date-fns: https://date-fns.org/docs/Getting-Started

import { BusinessTimer, DEFAULT_HOURS } from 'helpers/BusinessTimeDiff';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import getYearDateFns from 'date-fns/getYear';
import intervalToDuration from 'date-fns/intervalToDuration';
import differenceInDays from 'date-fns/differenceInDays';
import { FFLogger } from '../../api/LogClient';

// These are a list of common date string formats from our backend.
export const ymdFormat = 'yyyy-MM-dd'; // ISO8601

// Commonly used front-end formats.
export const mdyFormat = 'MM/dd/yyyy'; // ex. 03/21/2003
export const monthYearFormat = 'MMM yy'; // ex. Mar 20, Jun 00
export const longDateFormat = 'eeee, MMMM dd, yyyy'; // Thursday, June 3, 2021

let businessTimer: BusinessTimer;
const getBusinessTimer = (): BusinessTimer => {
  if (businessTimer) {
    return businessTimer;
  } else {
    const opts = {
      hours: DEFAULT_HOURS,
      // holidays: [], // TODO
      timeZone: 'America/New_York',
    };
    businessTimer = new BusinessTimer(opts);
    return businessTimer;
  }
};

export const getBusinessTimeDifference = (
  start: number,
  end: number
): number => {
  return getBusinessTimer().diff(start, end);
};

export const msToFormattedBusinessHours = (ms: number): string => {
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 9);
  const days = Math.floor(ms / (1000 * 60 * 60 * 9));

  const parts = [];
  if (days) {
    parts.push(`${days} d`);
  }
  if (hours) {
    parts.push(`${hours} h`);
  }
  if (minutes) {
    parts.push(`${minutes} m`);
  }

  if (!parts.length) {
    parts.push('0 m');
  }

  return parts.join(' ');
};

export const toDate = (
  dateString: string,
  dateStringFormat = ymdFormat
): Date => {
  return parse(dateString, dateStringFormat, new Date());
};

export const formatDateString = (
  dateString: string,
  currFormat: string,
  newFormat: string
): string => {
  try {
    const date = toDate(dateString, currFormat);
    return format(date, newFormat);
  } catch (err) {
    FFLogger.warn(
      `Error during formatDateString: dateString ${dateString}; currFormat: ${currFormat}; newFormat: ${newFormat}, err: ${err}`
    );
    return dateString;
  }
};

export const getYear = (
  dateString: string,
  currFormat: string = ymdFormat
): string => {
  try {
    const date = toDate(dateString, currFormat);
    if (date.toString() === 'Invalid Date') {
      throw new Error(`${dateString} is not a valid date string`);
    }
    return getYearDateFns(date).toString();
  } catch (err) {
    FFLogger.warn(
      `Error during getYear: dateString ${dateString}; format: ${currFormat}, err: ${err}`
    );
    return dateString;
  }
};
//this returns format mm/dd/yyyy, HH:MM:SS A
export const formatDatetoLocaleString = (
  date: Date | null | undefined
): string | null | undefined => {
  if (!date) {
    return date;
  }

  return new Date(date).toLocaleString('en-US');
};
export const formatDate = (date: Date, formatString: string): string => {
  try {
    return format(date, formatString);
  } catch (err) {
    FFLogger.warn(
      `Error during formatDatetoLocaleString: date ${date}; err: ${err}`
    );
    return date.toString();
  }
};

// Calculate the time between two given dates and return it as a Xd Yh Zm formatted string
export const timeBetweenDates = (date1: Date, date2: Date): string => {
  const duration = intervalToDuration({
    start: date1,
    end: date2,
  });

  const days = Math.abs(differenceInDays(date1, date2));
  const hours = duration.hours || 0;
  const minutes = duration.minutes;

  const daysDisplay = days > 0 ? `${days}d` : null;
  const hoursDisplay = daysDisplay || hours > 0 ? `${hours}h` : null;
  const minutesDisplay = `${minutes}m`;

  return [daysDisplay, hoursDisplay, minutesDisplay]
    .filter((v) => v !== null)
    .map((v) => `${v}`)
    .join(' ');
};

// Returns date string in ISO8601 (UTC) format, with no fractional seconds
export const formatDateToIso8601 = (date: Date): string => {
  try {
    return date.toISOString().split('.')[0] + 'Z';
  } catch (err) {
    FFLogger.warn(
      `Error during formatDateToIso8601: date ${date}; err: ${err}`
    );

    return '';
  }
};
