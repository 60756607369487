import React from 'react';
import { Table, TableCellProps, Text } from '@forward-financing/fast-forward';

export const TableCellWithDefault = ({
  children,
  ...rest
}: TableCellProps): JSX.Element => (
  <Table.Cell {...rest}>
    {children ?? <Text italic>Unable to fetch</Text>}
  </Table.Cell>
);
