import React from 'react';
import { Box, Grid } from '@forward-financing/fast-forward';
import { LiensTable } from 'components/LiensTable/LiensTable';
import { categorizeLiensJudgments } from 'components/LiensTable/liensTableUtils';
import { LienJudgmentRecord } from 'components/LiensTable/types';
import { BankruptcyRecord } from 'components/SubmissionUnderwriting/OwnerOverview/types';
import { BankruptcyTable } from './BankruptcyTable';

export interface BusinessPublicRecordsProps {
  liens: LienJudgmentRecord[];
  bankruptcies: BankruptcyRecord[];
}

export const BusinessPublicRecords = (
  props: BusinessPublicRecordsProps
): JSX.Element => {
  const {
    liensOpen,
    liensReleased,
    liensStateOpen,
    liensStateReleased,
    liensFederalOpen,
    liensFederalReleased,
    judgmentsOpen,
    judgmentsSettled,
  } = categorizeLiensJudgments(props.liens);

  return (
    <Box margin={4} paddingBottom={4}>
      <Grid gutter>
        <Grid.Item xs={6}>
          <BankruptcyTable bankruptcies={props.bankruptcies} />
        </Grid.Item>
      </Grid>

      <Grid gutter>
        <Grid.Item xs={6}>
          <LiensTable data={liensOpen} title="Total Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensReleased} title="Total Liens - Released" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensStateOpen} title="State Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable
            data={liensStateReleased}
            title="State Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={liensFederalOpen} title="Federal Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable
            data={liensFederalReleased}
            title="Federal Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={judgmentsOpen} title="Judgments - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensTable data={judgmentsSettled} title="Judgments - Settled" />
        </Grid.Item>
      </Grid>
    </Box>
  );
};
