import { LEDGER_TABLES_MONTHS } from '../constants';
import { Ledger } from '../ledger.types';

/**
 * A mapping of field names to their corresponding labels for display in the OffersTable component.
 */
export const OFFERS_TABLE_FIELD_NAME_LABEL_MAP = (
  isWeeklyPayment = false
): Record<string, string> => ({
  gross: 'Gross %',
  dollarOverride: 'Enter Approval $',
  approvalAmount: 'Approval Amount',
  multipleOfAdb: 'Multiple of ADB',
  paybackAmount: 'Payback Amount',
  dailyPayment: isWeeklyPayment ? 'Weekly Payment' : 'Daily Payment',
  paybackAmountGross: 'FF% of Gross',
  combinedGross: 'Total Gross',
});

/**
 * A list of field names that represent dollar amounts in the ledger offers.
 */
export const DOLLAR_FIELDS: string[] = [
  'approvalAmount',
  'paybackAmount',
  'dailyPayment',
  'dollarOverride',
];

/**
 * An array of strings representing the fields that are considered percentage fields
 * in the ledger offers.
 */
export const PERCENT_FIELDS: string[] = [
  'combinedGross',
  'paybackAmountGross',
  'gross',
];

/**
 * A constant representing an empty ledger offers object.
 * Each property corresponds to a specific month's dollar and gross values,
 * all initialized to empty string.
 */
export const EMPTY_OFFERS_LEDGER_DATA: Partial<Ledger> = {
  dollar3Months: '',
  gross3Months: '',
  dollar4Months: '',
  gross4Months: '',
  dollar5Months: '',
  gross5Months: '',
  dollar6Months: '',
  gross6Months: '',
  dollar7Months: '',
  gross7Months: '',
  dollar8Months: '',
  gross8Months: '',
  dollar9Months: '',
  gross9Months: '',
  dollar10Months: '',
  gross10Months: '',
  dollar11Months: '',
  gross11Months: '',
  dollar12Months: '',
  gross12Months: '',
  dollar13Months: '',
  gross13Months: '',
  dollar14Months: '',
  gross14Months: '',
  dollar15Months: '',
  gross15Months: '',
  dollar16Months: '',
  gross16Months: '',
  dollar17Months: '',
  gross17Months: '',
  dollar18Months: '',
  gross18Months: '',
};

export const EMPTY_OFFERS_DATA = LEDGER_TABLES_MONTHS.map((month) => ({
  months: month,
  approvalAmount: '',
  multipleOfAdb: 0,
  paybackAmount: '',
  dailyPayment: '',
  paybackAmountGross: '',
  combinedGross: '',
}));
