import React from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  formatDateString,
  Icon,
  Loading,
  PageContainer,
  Tabs,
} from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';

import { BusinessPaynetSummaryTable } from './BusinessPaynetSummaryTable';
import {
  useAllBusinessPaynetReports,
  useUpdateReport,
} from './businessPaynetHooks';
import { BusinessPaynetDetailsTable } from './BusinessPaynetDetailsTable';
import { BusinessPaynetDeleteReportModal } from './BusinessPaynetDeleteReportModal';
import { BusinessPaynet } from './businessPaynet.types';

type Params = { submissionUuid: string };

export const BusinessPaynetPageContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  const {
    data: businessPaynetData,
    loading: loadingBusinessPaynet,
    error: errorBusinessPaynet,
    refetch: refetchPaynetDocuments,
  } = useAllBusinessPaynetReports(submissionUuid);

  const [updateFn, { loading: updateReportLoading, error: updateReportError }] =
    useUpdateReport();

  const handleUpdateDocument = async (
    documentId: number,
    primary: boolean
  ): Promise<void> => {
    const { success } = await updateFn({ documentId, primary });
    success && refetchPaynetDocuments();
  };

  const primaryPaynetReport = (): BusinessPaynet | undefined => {
    if (!businessPaynetData) {
      return undefined;
    }
    return businessPaynetData?.find((p) => p.primary) || businessPaynetData[0];
  };

  return (
    <PageContainer>
      {errorBusinessPaynet && (
        <Box mt={3}>
          <Banner>{errorBusinessPaynet.message}</Banner>
        </Box>
      )}
      {updateReportError && (
        <Box mt={3}>
          <Banner>{updateReportError.message}</Banner>
        </Box>
      )}

      {(loadingBusinessPaynet || updateReportLoading) && <Loading />}

      {submissionUuid && businessPaynetData && (
        <Tabs defaultValue={primaryPaynetReport()?.documentId.toString()}>
          <Tabs.List wrap={'nowrap'}>
            {businessPaynetData?.map((paynet, index) => (
              <Tabs.Trigger
                value={paynet.documentId.toString()}
                key={`Name: ${paynet.reportId}`}
              >
                <Flex gap={2}>
                  {paynet.primary && <Icon name="flag" />}
                  {`Paynet Report #${index + 1} - ${formatDateString(
                    paynet.createdAt
                  )}`}
                </Flex>
              </Tabs.Trigger>
            ))}
          </Tabs.List>

          {businessPaynetData?.map((paynet) => (
            <Tabs.Content
              value={paynet.documentId.toString()}
              key={`Content: ${paynet.reportId}`}
            >
              <Flex flexDirection="column" gap={3}>
                <Flex justifyContent={'flex-end'} alignItems="center" gap={2}>
                  <Button
                    startIcon={'flag'}
                    onClick={() =>
                      handleUpdateDocument(paynet.documentId, true)
                    }
                    disabled={paynet.primary}
                  >
                    {paynet.primary
                      ? 'Flagged as Primary Report'
                      : 'Flag Report as Primary'}
                  </Button>

                  <BusinessPaynetDeleteReportModal
                    documentId={paynet.documentId.toString()}
                    submissionUuid={submissionUuid}
                  />
                </Flex>

                <BusinessPaynetSummaryTable data={paynet.businessSummary} />

                <BusinessPaynetDetailsTable
                  paymentDetails={paynet.paymentDetails}
                />
              </Flex>
            </Tabs.Content>
          ))}
        </Tabs>
      )}
    </PageContainer>
  );
};
