import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { CollectionsNote } from './CollectionsNote';
import { CollectionNote } from './PaymentHistory.types';

export interface CollectionNotesProps {
  collectionNotes: CollectionNote[];
}

const NotesTitle = () => (
  <Subheading variant="section">Most Recent ASD Notes</Subheading>
);

export const CollectionNotes = ({
  collectionNotes,
}: CollectionNotesProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalOpenChange = () => {
    setIsOpen((prev) => !prev);
  };

  if (collectionNotes.length === 0) {
    return (
      <>
        <NotesTitle />
        <Text>There are no ASD notes</Text>
      </>
    );
  }

  if (collectionNotes.length <= 5) {
    return (
      <>
        <NotesTitle />
        {collectionNotes.map((note) => (
          <CollectionsNote key={note.id} collectionNote={note} />
        ))}
      </>
    );
  }

  const firstFiveNotes = collectionNotes.slice(0, 5);

  return (
    <>
      <NotesTitle />
      {firstFiveNotes.map((note) => (
        <CollectionsNote key={note.id} collectionNote={note} />
      ))}
      <Modal
        title="ASD Notes"
        isOpen={isOpen}
        onOpenChange={handleModalOpenChange}
        trigger={<Button>View All ASD Notes</Button>}
        body={
          <Box>
            {collectionNotes.map((note) => (
              <CollectionsNote key={note.id} collectionNote={note} />
            ))}
            <Flex justifyContent="center">
              <Box>
                <Button onClick={handleModalOpenChange}>Close</Button>
              </Box>
            </Flex>
          </Box>
        }
      />
    </>
  );
};
