import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  formatDateTimeString,
  Modal,
  Text,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';
import { deleteDocument } from '../OwnerOverview/ownerOverviewFetchUtils';
import { BusinessCustomerLexisNexisReport } from './businessOverview.types';

export interface CustomerReportDeleteConfirmationModalProps {
  document: BusinessCustomerLexisNexisReport;
  afterDelete: (documentId: number) => void;
}

export const CustomerReportDeleteConfirmationModal = ({
  document,
  afterDelete,
}: CustomerReportDeleteConfirmationModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [documentsDeleteError, setDocumentsDeleteError] = useState<string>();

  const handleDeleteDocument = async (documentId: number): Promise<void> => {
    try {
      await deleteDocument(documentId);
      setIsModalOpen(false);
      afterDelete(documentId);
    } catch (e: unknown) {
      const error = toError(e);
      setDocumentsDeleteError(error.message);
    }
  };

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
    setDocumentsDeleteError('');
  };

  return (
    <Modal
      trigger={
        <Button variant="danger" startIcon={'trash'}>
          Delete Report
        </Button>
      }
      isOpen={isModalOpen}
      title="Deletion Confirmation"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {documentsDeleteError && (
            <Banner dismissable={false} variant="error">
              {documentsDeleteError}
            </Banner>
          )}
          <Text>
            You are about to delete Lexis Nexis report pulled{' '}
            {document.companyName && `for ${document.companyName}`} on{' '}
            {formatDateTimeString(document.createdAt)}. Please confirm.
          </Text>
          <Flex justifyContent={'center'} flexDirection={'row'} gap={4} mt={5}>
            <Button
              startIcon="trash"
              variant="danger"
              onClick={() => handleDeleteDocument(document.documentId)}
            >
              Delete
            </Button>
            <Button
              startIcon="times"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancel
            </Button>
          </Flex>
        </Box>
      }
    />
  );
};
