/** Feature flags that are available for use in the application */
export const flags = [
  // whether to use customer owner on submission
  'use_owner_not_contact',
  // whether to display updated prequal form
  'show_updated_prequal_form',
  // whether to display the system generated score in the UW score UI
  'system_score_in_uw_scoring',
  // whether graphql should be enabled in owner overview
  'graphql_enabled',
  // whether the same day ach submit from is enabled  in payment calendar
  'same_day_ach_form',
  // whether to use graphql in matched records
  'use_graphql_in_matched_records',
  // whether to show go to next deal button for prequal users
  'show_prequal_go_to_next_button',
  // whether the exceptions request UI allows frequency changes without exception
  'weekly_eligible',
  // whether to show create internal renewal button in application exception
  'show_create_internal_renewal_button',
  // whether to show the new deal composite score in the deal scoring section
  'show_new_deal_composite_score',
  // whether to show the 'Assign Me as PQ Analyst' button in submission's toolbar
  'show_assign_me_as_pq_analyst_button',
  // whether to show a confirmation prompt when working an external renewal with internal eligible
  'external_renewal_confirmation_flow',
  // whether to restore the user's scroll position after refreshing the page.
  'use_scroll_restoration',
  // whether the backend will send multiple LN search results or just one
  'send_multiple_ln_search_results',
  // whether to replace similar contacts table with similar owners in matched records section
  'show_similar_owners_table',
  // whether to use customer owner for prequal form for autofill
  'autofill_pq_form_owners_on_customer_match',
  // whether to show customer submissions in customer search
  'show_customer_submissions',
  // whether to render scoring section based on composite score rather than submission type
  'render_scoring_section_based_on_composite_score',
  // Business Summary click-to-edit enabled
  'business_summary_click_to_edit',
  // whether to show any functionality/data related to relativity 6 endpoints
  'show_relativity_6_industry_prediction',
  // show processing fee attributes UI in exceptions request form
  'show_processing_fee_attributes_section',
  // use new 3pi endpoints for experian consumer for FICO score.
  'experian_consumer_3pi',
  // use new 3pi endpoints for experian consumer for owner data.
  'experian_consumer_3pi_owner',
  // show Deal Category-related data
  'show_deal_category',
  // show submission onboarding queue link in navbar
  'submission_onboarding_queue_navigation',
  // replace potential fraud section with high risk review
  'show_high_risk_review_section',
  // show the Deal Category filter in Live UW Pipeline
  'show_uw_deal_category_filter',
  // show search bar in Submission Onboarding Queue
  'submission_onboarding_queue_search',
  // show the Go To Next button in the UI for processing users
  'processing_queue',
  // show the new owner lexis nexis page
  'use_owner_ln_page',
  // show new submission editing form
  'use_new_submission_editing_form',
  // move underwriting decline functionality from Banking App to UW App
  'decline_from_underwriting_app_only',
  // move all assignment functionality from live pipeline to the Submission page
  'move_assignment_out_of_live_pipeline',
  // show new business lexis nexis search
  'show_new_business_ln_search',
  // show new owner lexis nexis search
  'show_new_owner_ln_search',
  // show new owner ucc tables
  'use_new_owner_ucc',
  // use 3pi version of experian consumer reports archive
  'experian_consumer_3pi_archive',
  // use 3pi version of experian consumer detailed page
  'experian_consumer_3pi_detailed_page',
  // allows users to save data in the decline modal without actually declining the sub
  'allow_decline_data_save_without_decline',
  // show stipulation recommendation text that contains ownership confidence score modal
  'show_stipulation_recommendation_text',
  // show the new business ucc report
  'use_new_business_ucc',
  // replaces 'Credit Committee' with 'Decision Committee' in the UI
  'use_decision_committee_naming',
  // allow inline assignment of UWs via Application Snapshot
  'inline_editing_underwriter_assignment',
  // switch to new 3pi endpoint for experian consumer inquiries summary
  'experian_consumer_3pi_inquiries_summary',
  // switch to new 3pi endpoint for experian consumer inquiries details
  'experian_consumer_3pi_inquiries_detail',
  // remove describe stipulation type from stipulation editing
  'remove_describe_stipulation_type',
  // switch to new 3pi endpoint for experian consumer tradelines summary
  'experian_consumer_3pi_tradelines_summary',
  // show the new Renewal Comparison page
  'show_new_renewal_comparison_page',
  // show the new data merch page
  'use_new_data_merch_page',
  // show new business lexis nexis report page
  'use_new_bussines_ln_page',
  // use v2 endpoints for 3pi Experian Consumer
  'experian_consumer_3pi_v2_endpoints',
  // show react tradeline detail pages instead of UW page
  'experian_consumer_3pi_tradelines_detail',
  // show new paynet report page
  'use_new_business_paynet_page',
  // show the V6 Score Display tab
  'show_v6_score_display',
  // show SOS Fields in Business Overview
  'show_sos_fields',
  // show new ledgers in UA inside uw score section
  'show_new_ledgers_in_UA',
  // stop using customerUuid or accountUuid from BA links
  'ba_links_refactor_remove_customer',
  // show new natural disaster tag in business summary
  'show_new_natural_disaster_tag',
  // show informational messages on experian consumer detail page
  'experian_credit_report_show_info_messages',
  // use submission onboarding waiting room
  'use_submission_onboarding_waiting_room',
  // require decline notes when declining a submission
  'require_decline_notes',
  // Show the new business public records page
  'show_new_business_public_records_page',
  // Show the new Industry Prediction data
  'show_new_suggested_industry_predictions',
  // Separate usage of additional info and external notes
  'separate_additional_info_and_external_notes',
  // Fetches owners with submissionUuid
  'fetch_owners_by_submissionUuid',
] as const;
