import React, { useCallback } from 'react';
import {
  Banner,
  Flex,
  Loading,
  Subheading,
} from '@forward-financing/fast-forward';

import {
  useFetchBuyRates,
  useOverrideBuyRates,
  useResetBuyRates,
} from '../ledgerHooks';
import { Ledger } from '../ledger.types';
import { BuyRatesTable } from './BuyRatesTable';
import { MonthRate } from './buyRates.types';

type LedgerContainerProps = {
  submissionUuid: string;
  handleChangeLedger: (updates: Partial<Ledger>) => void;
  ledger: Ledger;
  readOnly?: boolean;
};

export const BuyRatesContainer = ({
  submissionUuid,
  ledger,
  handleChangeLedger,
  readOnly,
}: LedgerContainerProps): JSX.Element => {
  const { data, loading, error } = useFetchBuyRates(
    submissionUuid,
    ledger.id,
    ledger.program,
    ledger.overrideBuyRates
  );

  const [overrideBuyRates, { error: overrideBuyRatesError }] =
    useOverrideBuyRates();

  const [resetBuyRates, { error: resetBuyRatesError, loading: resetLoading }] =
    useResetBuyRates();

  const handleOverrideBuyRates = useCallback(
    async (monthRates: MonthRate[]) => {
      return overrideBuyRates({
        submissionUuid,
        ledgerId: ledger.id,
        requestBody: monthRates,
      });
    },
    [overrideBuyRates, submissionUuid, ledger.id]
  );

  const handleResetBuyRates = useCallback(async () => {
    return resetBuyRates({
      submissionUuid,
      ledgerId: ledger.id,
      programTypeId: ledger.program,
    });
  }, [ledger.id, ledger.program, submissionUuid, resetBuyRates]);

  return (
    <Flex flexDirection="column">
      <Subheading>Buy Rates</Subheading>

      {error && (
        <Banner autoDismissTime={5000} dismissable={'auto'}>
          {error.message}
        </Banner>
      )}

      {overrideBuyRatesError && (
        <Banner autoDismissTime={5000} dismissable={'auto'}>
          {overrideBuyRatesError.message}
        </Banner>
      )}

      {resetBuyRatesError && (
        <Banner autoDismissTime={5000} dismissable={'auto'}>
          {resetBuyRatesError.message}
        </Banner>
      )}

      {(loading || resetLoading) && <Loading />}

      {!loading && !error && !resetLoading && data && (
        <BuyRatesTable
          data={data}
          readOnly={readOnly}
          isEditable={Boolean(ledger.overrideBuyRates)}
          handleChangeLedger={handleChangeLedger}
          handleOverrideBuyRates={handleOverrideBuyRates}
          ledgerCreationDate={ledger.createdAt}
          handleResetBuyRates={handleResetBuyRates}
        />
      )}
    </Flex>
  );
};
