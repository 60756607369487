import { fetchCreditDecision } from 'api/banking/creditDecisionFetchUtils';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import {
  CreditDecisionResponse,
  DeclineCreditDecisionResponse,
} from 'types/api/banking/types';

export type UseApiCreditDecisionsResponse = UseGenericQueryResponse<
  CreditDecisionResponse | DeclineCreditDecisionResponse
>;

export const useApiCreditDecisions = (
  submissionUuid?: string
): UseApiCreditDecisionsResponse => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchCreditDecision(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};
