import { Box, Table, Text } from '@forward-financing/fast-forward';
import React from 'react';
import { Bankruptcy } from './ownerLexisNexisPage.types';

export interface BankruptciesTableProps {
  bankruptcies: Bankruptcy[];
}

export const BankruptciesTable = ({
  bankruptcies,
}: BankruptciesTableProps): JSX.Element => {
  return (
    <Box>
      <Table caption={'Bankruptcies'}>
        <Table.Head>
          <Table.ColumnHeader>Type</Table.ColumnHeader>
          <Table.ColumnHeader>Filing Date</Table.ColumnHeader>
          <Table.ColumnHeader>Status History</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {bankruptcies.map((data) => (
            <Table.Row key={`${data.type} ${data.filingDate}`}>
              <Table.Cell>{data.type}</Table.Cell>
              <Table.Cell>{data.filingDate}</Table.Cell>
              <Table.Cell>
                {data.statusHistory.map((status) => (
                  <Text key={status}>{status}</Text>
                ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
