import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { NextDealResponse } from 'types/api/banking/types';

export const underwriterAssignNextDeal =
  async (): Promise<NextDealResponse> => {
    const url = new URL(
      '/api/v2/next_application_for_underwriter',
      BANK_BASE_URL()
    );

    const response = await makeInternalAPIRequest<NextDealResponse>(
      url,
      'POST'
    );

    if (!response.ok) {
      throw new NetworkError(
        response.status,
        'The next application could not be assigned. Please try again.'
      );
    }

    return response.json();
  };
