import { v4 as uuidv4 } from 'uuid';
import {
  Contact,
  Account,
  Application,
  WebPresences,
  WebPresenceResponse,
  Owner,
  Ownership,
  WebPresenceType,
  Submission,
} from '../codecs';
import {
  formatDateString,
  mdyFormat,
  ymdFormat,
} from '../../../helpers/string/dateUtils';
import { featureFlags } from '../../../helpers/featureFlags';
import {
  generateEmptyContact,
  generateInitialPrequalState,
  generateEmptyAddress,
  generateEmptyDecline,
} from './index';

// TODO: rename this to SubmissionsDataBuilder

function obtainIndividualOwnershipPercentage(
  model: Contact | Owner,
  ownerships: Ownership[]
): number {
  if (featureFlags.use_owner_not_contact) {
    return model.ownership_percentage || 0;
  }

  if (ownerships && ownerships.length > 0) {
    return Math.trunc(
      ownerships.find((o) => o.contact_uuid === model.uuid)
        ?.ownership_percentage || 0
    );
  }

  return Math.trunc(model.ownership_percentage || 0);
}

/**
 * This is supposed to update the ownership percentage for each contact after
 * an update. However it is buggy and needs to be fixed.
 *
 * Using the index from updatedContacts on existingOwners seems like it
 * could create the buggy behavior we are seeing in Rollbar with:
 * TypeError: Cannot read properties of undefined (reading
 * 'ownership_percentage'). However I could not reproduce this locally except
 * by forcing it by manually removing an array element from existingOwners.
 * In order to get the error there needs to be more elements in updatedContacts
 * than in existingOwners. In other words, after upserting the contacts from the
 * form, the returned list of updated contacts would have to be larger. Not sure
 * how this could happen but maybe two people updating at the same time?
 * In any case, this needs more attention than just naively fixing the Rollbar
 * error with optional chaining as we had originally planned.
 *
 * @param updatedContacts List of contacts from the endpoint after update
 * @param existingOwners List of owners from the React form
 * @returns A list of Contacts with the percentages updated from the BE.
 */
export function setOwnershipPercentageForContacts(
  updatedContacts: Contact[],
  existingOwners: Contact[]
): Contact[] {
  return updatedContacts.map((contact, index): Contact => {
    const uiOwnerObtainedByUuid = existingOwners.find(
      (owner) => owner.uuid === contact.uuid
    );

    // This change below would make the Rollbar error go away, but then the
    // owner will be saved with a 0 percentage and no warning to the user.
    // At least with the error the user will know something went wrong. So
    // don't use this change below. The real fix needs more analysis.
    // : existingOwners[index]?.ownership_percentage;
    const ownershipPercentage = uiOwnerObtainedByUuid
      ? uiOwnerObtainedByUuid.ownership_percentage
      : existingOwners[index].ownership_percentage;
    return { ...contact, ownership_percentage: ownershipPercentage };
  });
}

// We don't need to change the date format in the new Owner form
// We can remove this after the users start using the new form
const formatBornOn = (date: string | null): string => {
  let bornOn: string;

  if (date) {
    bornOn = featureFlags.show_updated_prequal_form
      ? date.replace('/', '-')
      : formatDateString(date, ymdFormat, mdyFormat);
  } else {
    bornOn = '';
  }

  return bornOn;
};

export function buildOwnerModels(
  modelData: Contact[] | Owner[],
  stepCompleted = true,
  ownerships: Ownership[] = []
): Contact[] | Owner[] {
  const models: Contact[] | Owner[] =
    modelData.length < 1
      ? [generateEmptyContact()]
      : modelData.map((model: Contact | Owner) =>
          Object.assign({}, model, {
            key: model.uuid,
            born_on: formatBornOn(model.born_on),
            ssnNotPresent:
              (model.ssn === null || model.ssn === '') && stepCompleted,
            bornOnNotPresent: model.born_on === null && stepCompleted,
            ownership_percentage: obtainIndividualOwnershipPercentage(
              model,
              ownerships
            ),
            address:
              model.address === null ? generateEmptyAddress() : model.address,
          })
        );

  return models;
}

export function buildContactsFromOwners(owners: Owner[]): Contact[] {
  const contacts: Contact[] = owners.map(
    (owner: Owner): Contact => ({
      ...owner,
      id: undefined,
      uuid: '',
      born_on: formatBornOn(owner.born_on),
      address: owner.address || generateEmptyAddress(),
      ssnNotPresent: owner.ssn === null,
      bornOnNotPresent: owner.born_on === null,
      key: uuidv4(),
    })
  );

  return contacts;
}

export function buildWebPresences(
  webPresenceData: WebPresenceResponse
): WebPresences {
  return {
    business_website: {
      web_presence_type: WebPresenceType.Website,
      url: webPresenceData.business_website,
      urlChanged: false,
    },
    facebook: {
      web_presence_type: WebPresenceType.Facebook,
      url: webPresenceData.facebook,
      urlChanged: false,
    },
    instagram: {
      web_presence_type: WebPresenceType.Instagram,
      url: webPresenceData.instagram,
      urlChanged: false,
    },
    yelp: {
      web_presence_type: WebPresenceType.Yelp,
      url: webPresenceData.yelp,
      urlChanged: false,
    },
    other: {
      web_presence_type: WebPresenceType.Other,
      url: webPresenceData.other,
      urlChanged: false,
    },
  };
}

// stepCompleted is default true for Submission Edit path
export function buildAccount(
  accountData: Account,
  stepCompleted = true
): Account {
  const address =
    accountData.address === null ? generateEmptyAddress() : accountData.address;
  return {
    ...accountData,
    address: address,
    started_on: accountData.started_on
      ? formatDateString(accountData.started_on, ymdFormat, mdyFormat)
      : '',
    feinNotPresent: accountData.fein === null && stepCompleted,
    dbaSameAsLegalName: accountData.name === accountData.legal_name,
    phoneNotPresent: accountData.phone === null && stepCompleted,
  };
}

export function buildApplication(
  applicationData: Application,
  fromSubmissionEdit = false
): Application {
  const prequal_state_attributes = applicationData.prequal_state_attributes
    ? applicationData.prequal_state_attributes
    : generateInitialPrequalState();
  const prequal_started_at = prequal_state_attributes.prequal_started_at;
  const prequal_completed_at = prequal_state_attributes.prequal_completed_at;

  const application: Application = {
    ...applicationData,
    capital_needed: applicationData.capital_needed
      ? applicationData.capital_needed.replace(',', '')
      : '',
    capitalNeededNotPresent:
      (applicationData.capital_needed || '') === '' &&
      (fromSubmissionEdit || prequal_state_attributes.other_info_completed),
    prequal_state_attributes: {
      ...prequal_state_attributes,
      prequal_started_at: prequal_started_at
        ? new Date(prequal_started_at)
        : new Date(),
      prequal_completed_at: prequal_completed_at
        ? new Date(prequal_completed_at)
        : null,
    },
  };

  return application;
}

/* eslint-disable promise/prefer-await-to-then */
export function buildSubmission(
  applicationUuid: string,
  getApplication: (applicationUuid: string) => Promise<Application>,
  getAccountOrCustomer: (applicationUuid: string) => Promise<Account>,
  getContacts: (applicationUuid: string) => Promise<Contact[]>,
  fetchCustomerOwners: (customerUuid: string) => Promise<Owner[]>,
  fromSubmissionEdit = false
): Promise<Submission> {
  return Promise.all([
    getApplication(applicationUuid),
    featureFlags.use_owner_not_contact && fromSubmissionEdit
      ? []
      : getContacts(applicationUuid),
  ])
    .then(([application, contacts]) => {
      return Promise.all([
        application,
        fromSubmissionEdit && featureFlags.use_owner_not_contact
          ? fetchCustomerOwners(application.customer_uuid || '')
          : contacts,
        fromSubmissionEdit
          ? getAccountOrCustomer(application.customer_uuid || '')
          : getAccountOrCustomer(applicationUuid),
      ]);
    })
    .then(([application, contacts, account]) => {
      const submission: Submission = {
        ...buildApplication(application, fromSubmissionEdit),
        account: buildAccount(
          account,
          application.prequal_state_attributes === null
            ? fromSubmissionEdit
            : application.prequal_state_attributes.account_completed
        ),
        contacts: buildOwnerModels(
          contacts,
          application.prequal_state_attributes === null
            ? fromSubmissionEdit
            : application.prequal_state_attributes.owners_completed
        ),
        ownership_percentages: application.ownership_percentages,
        decline: generateEmptyDecline(),
        submission_source: application.submission_source,
      };
      return submission;
    });
}
/* eslint-enable promise/prefer-await-to-then */
