import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Banner,
  Card,
  Flex,
  IconButton,
  PageContainer,
  Subheading,
} from '@forward-financing/fast-forward';
import { useCustomer } from '../../OwnerOverview/UccReport/uccReportPageHooks';
import { BusinessUccReportTable } from './BusinessUccReportTable';
import { useUccBusinessReport } from './businessUccReportHook';

type Params = {
  submissionUuid: string;
  customerUuid: string;
  reportId: string;
};

export const BusinessUccReportPage = (): JSX.Element => {
  const { submissionUuid, customerUuid, reportId } = useParams<Params>();

  const { data: customerData, error: customerError } =
    useCustomer(customerUuid);

  const { data: uccReportData, error: uccReportError } = useUccBusinessReport(
    submissionUuid,
    reportId
  );

  const customerName = customerData?.legalName;

  useEffect(() => {
    if (customerName) {
      document.title = `${customerName}`;
    }

    return () => {
      document.title = 'Forward Financing';
    };
  }, [customerName]);

  const handleBackToSubmission = (): void => {
    const submissionUrl = new URL(
      `/submissions/${submissionUuid}`,
      window.location.origin
    );

    window.location.assign(submissionUrl.toString());
  };

  const activeUccReports = uccReportData?.filter(
    (uccReport) => !uccReport.closed
  );
  const closedUccReports = uccReportData?.filter(
    (uccReport) => uccReport.closed
  );

  return (
    <PageContainer>
      <Flex gap={3} mt={4} flexDirection="column">
        <IconButton
          label="Back to submission"
          onClick={handleBackToSubmission}
          icon={'circle-left'}
        />
        {customerError && (
          <Banner variant="danger" dismissable={false}>
            {customerError.message}
          </Banner>
        )}
        {uccReportError && (
          <Banner variant="danger" dismissable={false}>
            {uccReportError.message}
          </Banner>
        )}
        <Subheading>
          {customerName && `${customerName} - `}UCC Detail
        </Subheading>
        <Card title="UCC - Active">
          <BusinessUccReportTable uccReports={activeUccReports} />
        </Card>

        <Card title="UCC - Closed">
          <BusinessUccReportTable uccReports={closedUccReports} />
        </Card>
      </Flex>
    </PageContainer>
  );
};
