import React from 'react';
import { Box, Grid, Radio, Subheading } from '@forward-financing/fast-forward';

export const CompareAndSelectSkeleton = (): JSX.Element => {
  return (
    <Box>
      <Radio
        name="Compare & Select"
        value={''}
        onChange={
          /* istanbul ignore next */
          () => {
            // intentionally empty
          }
        }
      >
        {['1', '2', '3', '4'].map((item) => (
          <Radio.Item disabled id={item} value={item} key={item}>
            <Grid>
              <Grid.Item xs={12} s={3}>
                <Box pb={3}>
                  <Subheading variant="subsection">Adjustment</Subheading>
                </Box>
              </Grid.Item>
              <Grid.Item xs={12} s={4}>
                <Box pb={3}>
                  <Subheading variant="subsection">Adjusted Amount</Subheading>
                </Box>
              </Grid.Item>
              <Grid.Item xs={12} s={5}>
                <Box pb={3}>
                  <Subheading variant="subsection">
                    Estimated Payoff Date
                  </Subheading>
                </Box>
              </Grid.Item>
            </Grid>
          </Radio.Item>
        ))}
      </Radio>
    </Box>
  );
};
