import { Button, Loading } from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import React from 'react';

export interface CreateInternalRenewalButtonProps {
  createRenewalFunction: (submissionUuid: string) => Promise<MutationResponse>;
  fundedSubmissionUuid?: string;
  createRenewalLoading: boolean;
}
export const CreateInternalRenewalButton = ({
  createRenewalFunction,
  fundedSubmissionUuid,
  createRenewalLoading,
}: CreateInternalRenewalButtonProps): JSX.Element => {
  if (!fundedSubmissionUuid) {
    return <></>;
  }

  const createRenewal = async (): Promise<void> => {
    await createRenewalFunction(fundedSubmissionUuid);
  };

  return (
    <>
      {createRenewalLoading ? (
        <Loading size="small" />
      ) : (
        <Button variant={'cta'} onClick={() => createRenewal()}>
          Create Renewal
        </Button>
      )}
    </>
  );
};
