import React from 'react';
import { Text } from '@forward-financing/fast-forward';
import { IndustryRiskAuditEntryObjectChanges } from './IndustryRisk.types';

export interface AuditLogChangeTextProps {
  object_changes: IndustryRiskAuditEntryObjectChanges;
}

export const AuditLogChangeText = (
  props: AuditLogChangeTextProps
): JSX.Element => {
  const separator = '--';

  return (
    <>
      {props.object_changes.pricing_risk_profile !== undefined && (
        <Text>
          Pricing Risk Profile:{' '}
          <Text bold={true}>
            {props.object_changes.pricing_risk_profile.join(` ${separator} `)}
          </Text>
        </Text>
      )}

      {props.object_changes.archived !== undefined && (
        <Text>
          Archive Status:{' '}
          <Text bold={true}>
            {props.object_changes.archived
              .map((p: boolean): string => (p ? 'True' : 'False'))
              .join(` ${separator} `)}
          </Text>
        </Text>
      )}
    </>
  );
};
