import React from 'react';
import { Table } from '@forward-financing/fast-forward';
import { ProfessionalLicense } from './ownerLexisNexisPage.types';

export type ProfessionalLicensesTableProps = {
  professionalLicenses: ProfessionalLicense[];
};

export const ProfessionalLicensesTable = ({
  professionalLicenses,
}: ProfessionalLicensesTableProps): JSX.Element => {
  return (
    <Table caption="Professional Licenses">
      <Table.Head>
        <Table.ColumnHeader>Profession (or Board)</Table.ColumnHeader>
        <Table.ColumnHeader>License Type</Table.ColumnHeader>
        <Table.ColumnHeader>Issued Date</Table.ColumnHeader>
        <Table.ColumnHeader>Expiration Date</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {professionalLicenses.map(
          ({
            professionOrBoard,
            licenseType,
            issuedDate,
            expirationDate,
            status,
          }) => (
            <Table.Row
              key={`${licenseType}${issuedDate}${expirationDate}${status}`}
            >
              <Table.Cell>{professionOrBoard}</Table.Cell>
              <Table.Cell>{licenseType}</Table.Cell>
              <Table.Cell>{issuedDate}</Table.Cell>
              <Table.Cell>{expirationDate}</Table.Cell>
              <Table.Cell>{status}</Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
};
