import React from 'react';
import { Grid, Tabs, Text } from '@forward-financing/fast-forward';
import { Ineligibilities } from '../HighRiskReview.types';
import { MatchCriteriaList } from '../MatchCriteriaList';
import { IneligibilitiesTable } from './IneligibilitiesTable';

export type IneligibilitiesContentProps = {
  ineligibilities: Ineligibilities;
};

export const IneligibilitiesContent = ({
  ineligibilities,
}: IneligibilitiesContentProps): JSX.Element => {
  const noIneligibilities =
    ineligibilities.currentCustomerIneligibilities.length === 0 &&
    ineligibilities.matchedCustomers.length === 0;

  return noIneligibilities ? (
    <Text>No ineligibilities</Text>
  ) : (
    <Tabs
      defaultValue={
        ineligibilities.currentCustomerIneligibilities.length > 0
          ? 'current'
          : ineligibilities.matchedCustomers[0].customer.uuid
      }
    >
      <Tabs.List>
        {ineligibilities.currentCustomerIneligibilities.length > 0 && (
          <Tabs.Trigger value={'current'}>Current Customer</Tabs.Trigger>
        )}
        {ineligibilities.matchedCustomers.map((match) => (
          <Tabs.Trigger key={match.customer.uuid} value={match.customer.uuid}>
            {match.customer.legalName}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {ineligibilities.currentCustomerIneligibilities.length > 0 && (
        <Tabs.Content value={'current'}>
          <Grid gutter>
            <Grid.Item l={8}>
              <IneligibilitiesTable
                ineligibilities={ineligibilities.currentCustomerIneligibilities}
              />
            </Grid.Item>
          </Grid>
        </Tabs.Content>
      )}
      {ineligibilities.matchedCustomers.map((match) => (
        <Tabs.Content key={match.customer.uuid} value={match.customer.uuid}>
          <Grid gutter>
            <Grid.Item l={8}>
              <IneligibilitiesTable ineligibilities={match.ineligibilities} />
            </Grid.Item>
            <Grid.Item l={4}>
              <MatchCriteriaList criteria={match.criteria} />
            </Grid.Item>
          </Grid>
        </Tabs.Content>
      ))}
    </Tabs>
  );
};
