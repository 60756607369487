import React from 'react';
import {
  Currency,
  Table,
  formatDateString,
  useScreenSize,
} from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';

export const AdjustmentDetailsTable = (): JSX.Element => {
  const { formState } = usePaymentScheduleContext();
  const { isSOrSmaller } = useScreenSize();

  const adjustmentPeriod = (): string => {
    const start = formState.startDate
      ? formatDateString(formState.startDate?.toString())
      : 'N/A';

    let end = 'N/A';

    if (formState.endDate) {
      end = formatDateString(formState.endDate.toString());
    } else if (formState.selectedAdjustment?.estimatedPayoffDate) {
      end = formatDateString(formState.selectedAdjustment?.estimatedPayoffDate);
    }

    return `${start} - ${end}`;
  };

  const data = [
    {
      header: 'Adjustment',
      value: formState.selectedAdjustment?.label ?? 'N/A',
    },
    {
      header: 'Adjusted Payment',
      value:
        typeof formState.selectedAdjustment?.amount === 'number' ? (
          <Currency amount={formState.selectedAdjustment?.amount} />
        ) : (
          'N/A'
        ),
    },
    { header: 'Frequency', value: formState.frequency ?? 'N/A' },
    { header: 'Adjustment Period', value: adjustmentPeriod() },
    {
      header: 'Estimated Payoff Date',
      value: formState.selectedAdjustment?.estimatedPayoffDate
        ? formatDateString(formState.selectedAdjustment.estimatedPayoffDate)
        : 'N/A',
    },
  ];

  return (
    <Table caption="Schedule Adjustment Details">
      {isSOrSmaller ? (
        <Table.Body>
          {data.map((row) => (
            <Table.Row key={row.header}>
              <Table.RowHeader>{row.header}</Table.RowHeader>
              <Table.Cell>{row.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      ) : (
        <>
          <Table.Head>
            {data.map((column) => (
              <Table.ColumnHeader key={column.header}>
                {column.header}
              </Table.ColumnHeader>
            ))}
          </Table.Head>
          <Table.Body>
            <Table.Row>
              {data.map((column) => (
                <Table.Cell key={column.header}>{column.value}</Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </>
      )}
    </Table>
  );
};
