import { Box, Table } from '@forward-financing/fast-forward';
import React from 'react';
import { NameVariation } from './ownerLexisNexisPage.types';

export interface NameVariationsTableProps {
  nameVariationData: NameVariation[];
}

export const NameVariationsTable = ({
  nameVariationData,
}: NameVariationsTableProps): JSX.Element => {
  return (
    <Box>
      <Table caption={'Name Variations'}>
        <Table.Head>
          <Table.ColumnHeader>Name</Table.ColumnHeader>
          <Table.ColumnHeader>SSN</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {nameVariationData.map((data) => (
            <Table.Row key={data.name}>
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell>{data.ssnInformation}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
