import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { FUNDING_URL } from 'constants/globals';
import {
  PaymentSchedule,
  PaymentStatus,
  PaymentFrequency,
  PaymentDay,
  AdvanceProfile,
  UpdateBankInfoInput,
  UpdateBankInfoResponse,
  ScheduleAdjustmentComparison,
  BankInfoResponseShape,
  ExistingPayments,
  AdjustmentReason,
} from './paymentSchedule.types';
import { PlainDate } from '@forward-financing/fast-forward';
import { AdvanceProfileResponse } from 'types/api/funding/types';

export interface HolidayResponse {
  holiday: string;
}

export interface IndefinitePauseDayResponse {
  status: 'indefinite_pause';
  notes?: string;
  created_by?: string;
  created_on?: string;
}

export interface RejectionResponse {
  code: string;
  reason: string;
  description: string;
}

export interface PaymentResponse {
  amount: number;
  status: PaymentStatus;
  provider: string;
  rejection_data?: RejectionResponse;
  description?: string | null;
  same_day_ach: boolean;
  batch_path?: string | null;
}

export interface ScheduleDayResponse {
  // these top level "amount" and "status" should be the
  // aggregate of the payments in the paybacks field
  amount: number;
  status: PaymentStatus;
  paybacks: PaymentResponse[];
  reduction_percentage_bucket: string | null;
  reduction_percentage: string | null;
  manual: boolean;
  rollover: boolean;
  provider: string;
  balance_outstanding: number;
  percentage_repaid: number | null;
  stopped: boolean | null;
  notes?: string | null;
  created_by?: string | null;
  created_on?: string | null;
  frequency: PaymentFrequency | null;
  recurrency: number | null;
  adjustment_reason?: AdjustmentReason | null;
  scheduled_same_day_ach: {
    amount: number;
  } | null;
}

export type EmptyDayResponse = Record<string, never>;

type DayResponse =
  | HolidayResponse
  | IndefinitePauseDayResponse
  | ScheduleDayResponse;

export type PaymentScheduleResponse = Record<string, DayResponse>;
export type BusinessDaysResponse = Record<
  string,
  HolidayResponse | EmptyDayResponse
>;

const isHolidayResponse = (day: DayResponse): day is HolidayResponse =>
  'holiday' in day;

const isIndefinitePauseResponse = (
  day: DayResponse
): day is IndefinitePauseDayResponse =>
  'status' in day && day.status === 'indefinite_pause';

const isEmptyDayResponse = (
  day: DayResponse | EmptyDayResponse
): day is EmptyDayResponse => Object.keys(day).length === 0;

const dayResponseToDay = ([dateString, dayData]: [
  string,
  DayResponse | EmptyDayResponse
]): PaymentDay => {
  const [yearString, monthString, dayString] = dateString.split('-');
  const year = parseInt(yearString, 10);
  const month = parseInt(monthString, 10);
  const day = parseInt(dayString, 10);

  const date = new PlainDate(year, month, day);

  if (isEmptyDayResponse(dayData)) {
    return {
      kind: 'EmptyDay',
      date,
    };
  }

  if (isHolidayResponse(dayData)) {
    return {
      kind: 'HolidayDay',
      date,
      holiday: dayData.holiday,
    };
  }

  if (isIndefinitePauseResponse(dayData)) {
    if (dayData.notes && dayData.created_by && dayData.created_on) {
      return {
        kind: 'IndefinitePauseDay',
        date,
        status: 'indefinite_pause',
        notes: dayData.notes,
        createdBy: dayData.created_by,
        createdOn: dayData.created_on,
      };
    }

    return {
      kind: 'IndefinitePauseDay',
      date,
      status: 'indefinite_pause',
    };
  }

  let payments = dayData.paybacks;

  if (dayData.status === 'rejected') {
    payments = dayData.paybacks.map((payback) => {
      return {
        amount: payback.amount,
        status: payback.status,
        description: payback.rejection_data
          ? payback.rejection_data.description
          : payback.description,
        code: payback.rejection_data?.code,
        reason: payback.rejection_data?.reason,
        provider: payback.provider,
        same_day_ach: payback.same_day_ach,
        batch_path: payback.batch_path,
      };
    });
  }

  return {
    kind: 'ScheduleDay',
    date,
    amount: dayData.amount,
    status: dayData.status,
    payments: payments.map((payment) => {
      const { same_day_ach, batch_path, ...paymentObject } = payment;
      return {
        ...paymentObject,
        sameDayAch: same_day_ach,
        batchPath: batch_path,
      };
    }) as ExistingPayments[],
    reductionPercentageBucket: dayData.reduction_percentage_bucket,
    manual: dayData.manual,
    provider: dayData.provider,
    rollover: dayData.rollover,
    balanceOutstanding: dayData.balance_outstanding,
    percentageRepaid: dayData.percentage_repaid ?? undefined,
    stopped: dayData.stopped,
    notes: dayData.notes ?? undefined,
    createdBy: dayData.created_by ?? undefined,
    createdOn: dayData.created_on ?? undefined,
    frequency: dayData.frequency,
    recurrency: dayData.recurrency,
    adjustmentReason: dayData.adjustment_reason,
    scheduledSameDayAch: dayData.scheduled_same_day_ach
      ? { amount: dayData.scheduled_same_day_ach.amount }
      : undefined,
  };
};

/**
 * Given an advance record ID, this function will fetch the Payment Schedule for that
 * advance.
 *
 * @param advanceRecordId
 * @returns PaymentSchedule
 */
export const getPaymentSchedule = async (
  advanceRecordId: number
): Promise<PaymentSchedule | undefined> => {
  const url = new URL(
    `/api/v2/payback/${advanceRecordId}/schedule`,
    FUNDING_URL()
  );

  const response = await makeInternalAPIRequest<PaymentScheduleResponse>(
    url,
    'GET'
  );

  if (response.status === 404) {
    throw new Error(`Payment Schedule not found`);
  } else if (response.status === 403) {
    throw new Error('You are not authorized');
  } else if (response.status === 204) {
    return { isNotAvailable: true };
  }

  if (!response.ok) {
    throw new Error(
      `${response.status}: Error fetching Payment Schedule data. Please reload the page and try again.`
    );
  }

  const data = await response.json();

  return Object.entries(data).map(dayResponseToDay);
};

/**
 * Given a start date and, optionally, an end date, this function will get back an object
 * containing all weekdays between start date and end date. Unless the day is a holiday, the value
 * for each key will be an empty object.
 *
 * ## Response
 *
 * Example response for `start_date=2022-07-01&end_date=2022-07-06`
 * ```
 * {
 *   "2022-07-01":{}, // Friday, startDate
 *   "2022-07-04":{"holiday":"Independence Day"}, // Monday, happens to be a holiday
 *   "2022-07-05":{}, // Tuesday
 *   "2022-07-06":{}  // Wednesday, endDate
 * }
 * ```
 *
 * If endDate is not provided, the backend will default to the next business day
 *
 * Example response for `start_date=2022-07-01` (no end date provided)
 * ```
 * {
 *   "2022-07-01":{}, // Friday, startDate
 *   "2022-07-04":{"holiday":"Independence Day"}, // Monday, happens to be a holiday
 *   "2022-07-05":{} // Tuesday, first business day after startDate
 * }
 * ```
 *
 * We will then reformat the response into an array of PaymentDay
 *
 * @param startDate The first day to query, in YYYY-MM-DD format
 * @param endDate An end date for the query, in YYYY-MM-DD format. If not provided, the backend will default to the next business day after startDate
 */
export const getBusinessDays = (
  startDate: string,
  endDate?: string
): Promise<PaymentDay[]> => {
  const url = new URL(`/api/v2/payback/calendars`, FUNDING_URL());

  url.searchParams.append('start_date', startDate);
  if (endDate) {
    url.searchParams.append('end_date', endDate);
  }

  return makeInternalAPIRequest<BusinessDaysResponse>(url, 'GET')
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 404) {
        throw new Error(`Business Days not found`);
      } else if (response.status === 403) {
        throw new Error('You are not authorized');
      }

      throw new Error(
        `${response.status}: Error fetching Business Day data. Please reload the page and try again.`
      );
    })
    .then((data) => {
      return Object.entries(data).map(dayResponseToDay);
    });
};

/**
 * Given an advance record ID, fetches basic details about the
 * deal.
 *
 * @param advanceRecordId
 */
export const getAdvanceProfile = (
  advanceRecordId: number
): Promise<AdvanceProfile> => {
  const url = new URL(
    `/api/v2/payback/advance_profiles/${advanceRecordId}`,
    FUNDING_URL()
  );

  return makeInternalAPIRequest<AdvanceProfileResponse>(url, 'GET')
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 404) {
        throw new Error(`Advance Profile not found`);
      } else if (response.status === 403) {
        throw new Error('You are not authorized');
      }

      throw new Error(
        `${response.status}: Error fetching Advance Profile data. Please reload the page and try again.`
      );
    })
    .then((data) => {
      const { advance_profile: profile } = data;

      return {
        amount: profile.amount,
        dailyPayment: profile.daily_payment,
        dateOfAdvance: profile.date_of_advance,
        dateOfLastPayment: profile.date_of_last_payment ?? undefined,
        manualActive: profile.manual_active,
        outstandingBalance: profile.outstanding_balance,
        totalAmountIncludingFees: profile.payback_amount,
        paymentFrequency: profile.payment_frequency,
        percentageRepaid: profile.percentage_repaid,
        purchasedAmount: profile.purchased_amount,
        recordId: profile.record_id,
        rejectionsCount: profile.rejections_count,
        totalPayment: profile.total_payment,
        dateAdvanceLeavesBorrowingBase:
          profile.date_advance_will_leave_borrowing_base ?? undefined,
        writeOff: profile.write_off ?? undefined,
      };
    });
};

export interface BankInfoResponse {
  ach_routing_number: string;
  account_number: string;
  payback_ach_provider: string;
  providers: string[];
}

export const getBankInfo = (
  advanceRecordId: number
): Promise<BankInfoResponseShape> => {
  const url = new URL(
    `/api/v2/payback/bank_info/${advanceRecordId}`,
    FUNDING_URL()
  );

  return makeInternalAPIRequest<BankInfoResponse>(url, 'GET')
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 404) {
        throw new Error(`Bank Info not found`);
      } else if (response.status === 403) {
        throw new Error('You are not authorized');
      }

      throw new Error(
        `${response.status}: Error fetching Bank Info data. Please reload the page and try again.`
      );
    })
    .then((data) => {
      return {
        bankInfo: {
          achRoutingNumber: data.ach_routing_number,
          accountNumber: data.account_number,
          achProvider: data.payback_ach_provider,
        },
        achProcessors: data.providers,
      };
    });
};

export type UpdateBankInfoBody = {
  payback_ach_provider: string;
};

export const updateBankInfo = async (
  advanceRecordId: number,
  bankInfo: UpdateBankInfoInput
): Promise<UpdateBankInfoResponse> => {
  const url = new URL(
    `/api/v2/payback/bank_info/${advanceRecordId}`,
    FUNDING_URL()
  );

  const response = await makeInternalAPIRequest<
    UpdateBankInfoResponse,
    UpdateBankInfoBody
  >(url, 'PATCH', {
    payback_ach_provider: bankInfo.achProvider,
  });

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(`Advance not found`);
    } else if (response.status === 403) {
      throw new Error('You are not authorized');
    }
    throw new Error(
      `${response.status}: Error updating Bank Info. Please reload the page and try again.`
    );
  }

  return { success: true };
};

type CreateWriteOffBody = {
  date: string;
};

/**
 *
 * @param advanceRecordId The record ID of the advance
 * @param date The date on which to write off the advance, in YYYY-MM-DD format
 * @returns
 */
export const createWriteOff = (
  advanceRecordId: number,
  date: string
): Promise<void> => {
  const url = new URL(
    `/api/v2/payback/advances/${advanceRecordId}/write_off`,
    FUNDING_URL()
  );

  return makeInternalAPIRequest<void, CreateWriteOffBody>(url, 'POST', {
    date,
  }).then((response) => {
    if (response.ok) {
      return;
    }

    if (response.status === 404) {
      throw new Error(`Advance not found`);
    } else if (response.status === 403) {
      throw new Error('You are not authorized');
    }

    throw new Error(
      `${response.status}: Error creating write off. Please reload the page and try again.`
    );
  });
};

export interface ScheduleAdjustmentInput {
  /** A date string in YYYY-MM-DD format */
  firstPaymentDate: string;
  /** A date string in YYYY-MM-DD format */
  lastPaymentDate?: string;
  paymentAmount?: number;
  lastMonthsRevenue?: number;
  frequency: PaymentFrequency;
  recurrency: number;
}

export interface ScheduleAdjustmentResponseComparison {
  amount: number;
  /** A date string in YYYY-MM-DD format */
  estimated_payoff_date: string;
  label: string;
  error: string[] | null;
}

export interface ScheduleAdjustmentResponse {
  adjustment_comparisons: ScheduleAdjustmentResponseComparison[];
  errors: string[][] | null;
}

/**
 * Given an advance record ID and a potential payment plan, get the
 * projected schedule adjustment
 *
 * @param advanceRecordId
 * @param params see {@link ScheduleAdjustmentInput}
 */
export const getScheduleAdjustmentProjection = async (
  advanceRecordId: number,
  params: ScheduleAdjustmentInput
): Promise<ScheduleAdjustmentComparison[]> => {
  const url = new URL(
    `/api/v2/payback/schedule_adjustment_projections/${advanceRecordId}`,
    FUNDING_URL()
  );
  url.searchParams.append('start_date', params.firstPaymentDate);
  url.searchParams.append('end_date', params.lastPaymentDate ?? '');
  url.searchParams.append(
    'payment_amount',
    params.paymentAmount?.toString() ?? ''
  );
  url.searchParams.append(
    'last_months_revenue',
    params.lastMonthsRevenue?.toString() ?? ''
  );
  url.searchParams.append('frequency', params.frequency);
  url.searchParams.append('recurrency', params.recurrency.toString());

  const response = await makeInternalAPIRequest<ScheduleAdjustmentResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(`Advance not found`);
    } else if (response.status === 403) {
      throw new Error('You are not authorized');
    } else if (response.status === 422) {
      /**
       * Not all 422 errors are created equally.
       *
       * Sometimes we get a 422 where only some of the `adjustment_comparisons` have a non-null `error` key.
       * In this case, we want to return the data as if everything is fine, but
       * the UI will handle the display logic of the data based on the individual errors.
       *
       * In the event where all `adjustment_comparisons` have a non-null `error` key, we want to throw an error
       * derived from the contents of the global `errors` key so that the UI can display the error message
       * in the usual red error Banner.
       */
      const errorData = await response.json();

      const allErrors = errorData.adjustment_comparisons.every(
        (comparison) => comparison.error
      );

      if (allErrors) {
        throw new Error(errorData.errors?.flat().join(', '));
      }

      return errorData.adjustment_comparisons.map((comparison) => {
        return {
          amount: comparison.amount,
          estimatedPayoffDate: comparison.estimated_payoff_date,
          label: comparison.label,
          errors: comparison.error,
        };
      });
    }

    throw new Error(
      `${response.status}: Error getting schedule adjustment projection. Please reload the page and try again.`
    );
  }

  const data = await response.json();

  return data.adjustment_comparisons.map((comparison) => {
    return {
      amount: comparison.amount,
      estimatedPayoffDate: comparison.estimated_payoff_date,
      label: comparison.label,
      errors: comparison.error,
    };
  });
};

export interface CreateAdjustmentParams {
  amount: number;
  /** A date string in YYYY-MM-DD format */
  startDate: string;
  /** A date string in YYYY-MM-DD format */
  endDate?: string;
  frequency?: PaymentFrequency;
  recurrency: number;
  notes: string;
  settlement: boolean;
  achProvider: string | null;
  adjustmentReason?: AdjustmentReason;
  sameDayAch?: boolean;
}

type CreateAdjustmentRequestBody = {
  advance_record_id: string;
  amount: number;
  /** A date string in YYYY-MM-DD format */
  start_date: string;
  /** A date string in YYYY-MM-DD format */
  end_date?: string;
  frequency?: PaymentFrequency;
  recurrency: number;
  notes: string;
  settlement: boolean;
  ach_provider?: string;
  adjustment_reason?: AdjustmentReason;
  same_day_ach?: boolean;
};

export const createAdjustment = (
  advanceRecordId: number,
  params: CreateAdjustmentParams
): Promise<void> => {
  const url = new URL('/api/v2/payback/schedule_adjustments/', FUNDING_URL());

  return makeInternalAPIRequest<void, CreateAdjustmentRequestBody>(
    url,
    'POST',
    {
      advance_record_id: advanceRecordId.toString(),
      amount: params.amount,
      frequency: params.frequency,
      recurrency: params.recurrency,
      notes: params.notes,
      settlement: params.settlement,
      start_date: params.startDate,
      adjustment_reason: params.adjustmentReason,
      same_day_ach: params.sameDayAch,
      ...(params.endDate && {
        end_date: params.endDate,
      }),
      ...(params.achProvider && {
        ach_provider: params.achProvider,
      }),
    }
  ).then((response) => {
    if (response.ok) {
      return;
    }

    if (response.status === 404) {
      throw new Error(`Advance not found`);
    } else if (response.status === 403) {
      throw new Error('You are not authorized');
    }

    throw new Error(
      `${response.status}: Error creating schedule adjustment. Please reload the page and try again.`
    );
  });
};

export interface ResumePaymentAdjustmentParams {
  startDate: string;
  resume: boolean;
}

export const createResumePaymentsAdjustment = (
  advanceRecordId: number,
  resumePaymentsDate: string
): Promise<void> => {
  const url = new URL('/api/v2/payback/schedule_adjustments/', FUNDING_URL());

  return makeInternalAPIRequest<
    void,
    { advance_record_id: string; start_date: string; resume: true }
  >(url, 'POST', {
    advance_record_id: advanceRecordId.toString(),
    start_date: resumePaymentsDate,
    resume: true,
  }).then((response) => {
    if (response.ok) {
      return;
    }

    if (response.status === 404) {
      throw new Error(`Advance not found`);
    } else if (response.status === 403) {
      throw new Error('You are not authorized');
    }

    throw new Error(
      `${response.status}: Error creating schedule adjustment. Please reload the page and try again.`
    );
  });
};
