import React from 'react';
import { Box, Link, Text } from '@forward-financing/fast-forward';
import NotFoundBanner from '../../assets/images/NotFoundBanner.png';

export const NotFound = (): JSX.Element => {
  const portalUrl = new URL(
    'https://forwardfinancing.atlassian.net/servicedesk/customer/portal/1'
  );

  return (
    <Box textAlign="center" margin={4} paddingY={4}>
      <img src={NotFoundBanner as string} alt="404 Page Not Found" />
      <Box marginY={4}>
        <Text>
          We are sorry that we were not able to locate the page you are looking
          for.
        </Text>
        <Text>
          Please check the URL for any typos, and if you still think that you
          received this message in error, please report the issue through the{' '}
          <Link href={portalUrl} title="Help Center">
            Help Center
          </Link>
          .
        </Text>
      </Box>
    </Box>
  );
};
