import { useCallback } from 'react';
import { defaultTo } from 'lodash';
import {
  createCalculatedOffer,
  fetchCurrentOffer,
} from 'api/banking/offerFetchUtils';
import {
  useGenericMutation,
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { OfferResponse } from 'types/api/banking/types';
import { toLedgerOffer } from 'components/DealScoring/Ledger/ledgerHooks';
import { LEDGER_PROGRAM_OPTIONS_MAPPING } from 'components/DealScoring/Ledger/constants';
import { LedgerOffer } from 'components/DealScoring/Ledger/Offers/offers.types';
import {
  FetchCalculatedLedgerParams,
  Ledger,
} from '../../components/DealScoring/Ledger/ledger.types';

export type UseApiCurrentOfferResponse = UseGenericQueryResponse<
  OfferResponse | undefined
>;

export const useApiCurrentOffer = (
  submissionUuid?: string
): UseApiCurrentOfferResponse => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchCurrentOffer(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

const getProgramTypeName = (program: number): string => {
  if (!program) {
    return '';
  }

  return LEDGER_PROGRAM_OPTIONS_MAPPING[program.toString()];
};

const toCalculatedOffersPayload = (
  ledger: Ledger,
  offer: Partial<LedgerOffer> | undefined,
  months: string
): FetchCalculatedLedgerParams['payload'] => ({
  net_deposit_override: defaultTo(ledger.netDepositOverride, ''),
  average_daily_balance: defaultTo(ledger.adb?.toFixed(2), ''),
  // intentionally hardcoding this value
  calculator_name: '2015 Legacy',
  financings_gross: defaultTo(ledger.financingsGross, ''),
  program_type_name: getProgramTypeName(ledger.program),
  months,
  dollar_override: defaultTo(offer?.dollarOverride, ''),
  gross: defaultTo(offer?.gross, ''),
  ledger_id: ledger.id?.toString(),
});

export type UseApiCreateCalculatedOfferParams = Omit<
  FetchCalculatedLedgerParams,
  'payload'
> & {
  ledger: Ledger;
  offer: Partial<LedgerOffer> | undefined;
  months: string;
};

export const useApiCreateCalculatedOffer = (): UseGenericMutationResponse<
  LedgerOffer | undefined,
  UseApiCreateCalculatedOfferParams
> => {
  const fetchFn = async ({
    submissionUuid,
    ledgerId,
    ledger,
    offer,
    months,
  }: UseApiCreateCalculatedOfferParams): Promise<LedgerOffer | undefined> => {
    const response = await createCalculatedOffer({
      payload: toCalculatedOffersPayload(ledger, offer, months),
      submissionUuid,
      ledgerId,
    });

    return response ? toLedgerOffer(response) : undefined;
  };

  return useGenericMutation(fetchFn);
};
