import React, { useEffect, useState, createContext, useContext } from 'react';
import { AuthClient } from '../api/AuthClient/index';
import { User } from '../api/AuthClient/codecs';
import { FFLogger, setUserLogMeta } from '../api/LogClient';
import { toError } from 'helpers/errorUtils';

export const UNKNOWN_USER_UUID = '00000000-0000-0000-0000-000000000000';

const defaultUser: User = {
  id: 0,
  first_name: '',
  last_name: '',
  role: '',
  sub_role: null,
  email: '',
  uuid: UNKNOWN_USER_UUID,
  anonymized_id: '',
  api_key: '',
  cell_phone: null,
  created_by: '',
  current_ip_address: null,
  home_phone: null,
  img_src: null,
  inserted_at: null,
  is_activated: null,
  last_signed_in_at: null,
  record_id: null,
  role_id: 0,
  sign_in_count: 0,
};

const UserContext = createContext<User>(defaultUser);
export const useUserContext = (): User => useContext(UserContext);

interface UserProviderProps {
  value: User;
  children: React.ReactNode;
}

export const UserProvider = ({
  value,
  children,
}: UserProviderProps): JSX.Element => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

interface UserContextContainerProps {
  children: React.ReactNode;
}

export const UserContextContainer = ({
  children,
}: UserContextContainerProps): JSX.Element => {
  const [currentUser, setCurrentUser] = useState(defaultUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AuthClient.fetchAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user);
        FFLogger[setUserLogMeta]({
          id: user.id,
          role: user.role,
          sub_role: user.sub_role,
        });
      })
      .catch((e: unknown) => {
        const error = toError(e);
        FFLogger.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <></>;
  }

  return <UserProvider value={currentUser}>{children}</UserProvider>;
};
