import React from 'react';
import { Navbar } from '@forward-financing/fast-forward';
import { BANK_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { Submission } from './navbar.types';

export interface NavbarBankingLinkProps {
  submission: Submission | undefined;
}
export const NavbarBankingLink = ({
  submission,
}: NavbarBankingLinkProps): JSX.Element => {
  if (!submission?.customerUuid || !submission?.uuid) {
    return <></>;
  }

  const endpoint = featureFlags.ba_links_refactor_remove_customer
    ? `admin/prospective_merchants/sheets/${submission?.uuid}`
    : `admin/prospective_merchants/${submission?.customerUuid}/sheets/${submission?.uuid}`;

  return (
    <Navbar.Link
      icon="building-columns"
      newTab
      href={new URL(endpoint, BANK_BASE_URL())}
    >
      Banking
    </Navbar.Link>
  );
};
