import { THIRD_PARTY_INTEGRATIONS_BASE_URL } from '../../constants/globals';
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';

import { TradeLinesData } from './codecs/TradeLines.codec';
import { BusinessSummaryInterface } from './codecs/BusinessSummary.codec';
import { InquiriesInterface } from './codecs/Inquiries.codec';
import { PublicRecordsInterface } from './codecs/PublicRecords.codec';
import { UCCInterface } from './codecs/UniformCommercialCode.codec';

// ExperianBusinessCreditData's corresponding backend definition:
// https://github.com/ForwardFinancing/third_party_integrations/blob/production/domain/experian_commercial/experian_commercial.go#L56
export interface ExperianBusinessCreditData {
  business_summary: BusinessSummaryInterface;
  inquiries: InquiriesInterface;
  public_records: PublicRecordsInterface;
  uniform_commercial_code_filings: UCCInterface;
  trade_lines: TradeLinesData;
}

export const fetchExperianBusinessCredit = (
  uuid: string,
  uuidType: string
): Promise<ExperianBusinessCreditData> => {
  const url = new URL(
    `/api/v1/experian/commercial/${uuid}?uuidType=${uuidType}&entities=summary,public_records,inquiries,tradelines,ucc_filings`,
    new URL(THIRD_PARTY_INTEGRATIONS_BASE_URL())
  );

  return makeInternalAPIRequest<ExperianBusinessCreditData>(url, 'GET').then(
    (response) => {
      // Happy path. Checking for 200 status instead of "ok" status to handle 200 and 202 responses differently
      if (response.status === 200) {
        return response.json();
      }

      // Sad paths
      if (response.status === 202) {
        // Treating 202 like an error because it should be a rare ocurrance
        // and persistent or frequent 202s might be indicative of a problem.
        throw new NetworkError(
          response.status,
          'Still processing Business Credit. Please try again soon.'
        );
      } else if (response.status === 400) {
        throw new NetworkError(response.status, 'Bad request');
      } else if (response.status === 403) {
        throw new NetworkError(response.status, 'You are not authorized');
      } else if (response.status === 404) {
        throw new NetworkError(response.status, 'Business Credit not found');
      }

      throw new NetworkError(
        response.status,
        'Error fetching Experian business credit. Please reload the page and try again.'
      );
    }
  );
};
