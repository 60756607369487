import { defaultTo } from 'lodash';

import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  BuyRatesResponse,
  LedgerBankingPricingSuggestionsRaw,
  LedgerOfferRaw,
  LedgerResponse,
  MonthRatePayload,
  OverrideByRatesPayload,
  PatchLedgerRequestBody,
} from 'types/api/banking/types';
import {
  useApiCloneLedger,
  useApiCreateLedger,
  useApiDeleteLedger,
  useApiFetchBuyRates,
  useApiGetOverriddenValidations,
  useApiOverrideBuyRates,
  useApiPatchLedger,
  useApiResetBuyRates,
  useApiSendToCreditCommittee,
  useFetchLedgerBankingSuggestedPricing,
  useFetchLedgers,
  useFetchOffers,
  useValidationOverrideApi,
  UseValidationOverrideArgs,
} from 'apiHooks/banking/ledgerFetchHooks';
import { IsoResponse } from 'types/api/funding/types';
import { IndependentSalesOrganization } from 'components/SubmissionUnderwriting/ApplicationSnapshot/applicationSnapshot.types';
import { useGetApiIso } from 'apiHooks/funding/isoFetchHooks';
import {
  LedgerUnderwritingPricingSuggestionsGetParams,
  OverriddenValidationsResponse,
  PricingSuggestionResponse,
} from 'types/api/underwriting/types';
import { useFetchLedgerUnderwritingSuggestedPricing } from 'apiHooks/underwriting/suggestedPricingFetchHooks';
import { AuthenticatedUserResponse } from 'types/api/auth/types';
import { useGetApiUser } from 'apiHooks/auth/userFetchHooks';
import {
  useApiDeleteOwner,
  UseApiDeleteOwnerResponse,
} from 'apiHooks/underwriting/ownerFetchHooks';
import {
  useApiCreateCalculatedOffer,
  UseApiCreateCalculatedOfferParams,
} from '../../../apiHooks/banking/offerFetchHooks';
import {
  GenericLedgerApiParams,
  Ledger,
  LedgerBankingPricingSuggestions,
  LedgerUnderwritingPricingSuggestions,
  LedgerUnderwritingPricingSuggestionsParams,
  OverriddenValidations,
  User,
} from './ledger.types';
import { LedgerOffer } from './Offers/offers.types';
import { BuyRates, LedgerBuyRates, MonthRate } from './BuyRates/buyRates.types';
import { isLedgerBuyRatesKey } from './BuyRates/buyRates.utils';

export const toLedgersArray = (ledgers: LedgerResponse[]): Ledger[] => {
  const sortedLedgers = ledgers.sort((a, b) => {
    if (a.created_at && b.created_at) {
      return b.created_at.localeCompare(a.created_at);
    }
    return 0;
  });

  return (
    sortedLedgers
      // We will not operate with ledgers without ID, since we can't identify them.
      .filter((rawLedger) => rawLedger.id)
      .map((rawLedger) => ({
        id: rawLedger.id as number,
        createdAt: defaultTo(rawLedger.created_at, ''),
        override: defaultTo(
          rawLedger.net_deposit_override_cents &&
            rawLedger.net_deposit_override_cents / 100,
          0
        ),
        adb: defaultTo(
          rawLedger.average_daily_balance_cents &&
            rawLedger.average_daily_balance_cents / 100,
          0
        ),
        // 2 - Means standard program
        program: defaultTo(rawLedger.program_type_id, 2),
        forwardPosition: defaultTo(rawLedger.financings_count, 0),
        totalGross: defaultTo(rawLedger.financings_gross, ''),
        addOn: defaultTo(rawLedger.is_add_on, false),
        buyOut: defaultTo(rawLedger.has_buy_out_position, false),
        prime: defaultTo(rawLedger.prime_deal, false),
        previousReceived: defaultTo(rawLedger.previously_received, false),
        eligibleForWeekly: defaultTo(rawLedger.eligible_for_weekly, false),
        presentedAsWeekly: defaultTo(rawLedger.frequency, ''),
        uwNotes: defaultTo(rawLedger.underwriter_notes, ''),
        programExplanation: defaultTo(rawLedger.program_explanation, ''),
        revenue: (
          defaultTo(rawLedger.net_deposit_override_cents, 0) / 100
        ).toFixed(2),
        financingsCount: defaultTo(rawLedger.financings_count, 0),
        financingsGross: defaultTo(rawLedger.financings_gross, ''),
        netDepositOverride: (
          defaultTo(rawLedger.net_deposit_override_cents, 0) / 100
        ).toFixed(2),
        overrideBuyRates: defaultTo(rawLedger.override_buy_rates, false),
        overrideBuyRatesReason: defaultTo(
          rawLedger.override_buy_rates_reason,
          ''
        ),
        lock: defaultTo(rawLedger.is_locked, false),
        validationErrorMessage: rawLedger.data_validation_error_message
          ? rawLedger.data_validation_error_message
              .split(/\r?\n/)
              .filter((item) => item !== '')
          : [],
      }))
  );
};

export const useLedgers = (
  submissionUuid: string
): UseGenericQueryResponse<Ledger[]> =>
  useGenericFeatureQuery(
    useFetchLedgers,
    (data) => data && toLedgersArray(data),
    submissionUuid
  );

const toIso = (iso?: IsoResponse): IndependentSalesOrganization | undefined => {
  if (!iso) {
    return undefined;
  }

  return {
    uuid: iso.partner.uuid,
    name: iso.partner.name,
  };
};

export const useGetIso = (
  isoUuid?: string
): UseGenericQueryResponse<IndependentSalesOrganization> => {
  return useGenericFeatureQuery(useGetApiIso, toIso, isoUuid);
};

export const useSendToCreditCommittee = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  const [sendToCreditCommittee, { error, ...rest }] =
    useApiSendToCreditCommittee();

  return [
    sendToCreditCommittee,
    {
      error,
      ...rest,
    },
  ];
};

const toPatchLedgerReqBody = (ledger: Ledger): PatchLedgerRequestBody => {
  return {
    locking: ledger.lock ?? false,
    underwriting_tab: {
      is_add_on: ledger.addOn,
      has_buy_out_position: ledger.buyOut,
      prime_deal: ledger.prime,
      previously_received: ledger.previousReceived,
      eligible_for_weekly: ledger.eligibleForWeekly,
      frequency: ledger.presentedAsWeekly.toString(),
      program_type_id: ledger.program,
      program_explanation: ledger.programExplanation,
      underwriter_notes: ledger.uwNotes,
      gross_2_months: ledger.gross2Months,
      gross_3_months: ledger.gross3Months,
      gross_4_months: ledger.gross4Months,
      gross_5_months: ledger.gross5Months,
      gross_6_months: ledger.gross6Months,
      gross_7_months: ledger.gross7Months,
      gross_8_months: ledger.gross8Months,
      gross_9_months: ledger.gross9Months,
      gross_10_months: ledger.gross10Months,
      gross_11_months: ledger.gross11Months,
      gross_12_months: ledger.gross12Months,
      gross_13_months: ledger.gross13Months,
      gross_14_months: ledger.gross14Months,
      gross_15_months: ledger.gross15Months,
      gross_16_months: ledger.gross16Months,
      gross_17_months: ledger.gross17Months,
      gross_18_months: ledger.gross18Months,
      dollar_2_months: ledger.dollar2Months,
      dollar_3_months: ledger.dollar3Months,
      dollar_4_months: ledger.dollar4Months,
      dollar_5_months: ledger.dollar5Months,
      dollar_6_months: ledger.dollar6Months,
      dollar_7_months: ledger.dollar7Months,
      dollar_8_months: ledger.dollar8Months,
      dollar_9_months: ledger.dollar9Months,
      dollar_10_months: ledger.dollar10Months,
      dollar_11_months: ledger.dollar11Months,
      dollar_12_months: ledger.dollar12Months,
      dollar_13_months: ledger.dollar13Months,
      dollar_14_months: ledger.dollar14Months,
      dollar_15_months: ledger.dollar15Months,
      dollar_16_months: ledger.dollar16Months,
      dollar_17_months: ledger.dollar17Months,
      dollar_18_months: ledger.dollar18Months,
      buy_rate_2_months: ledger.buyRate2Months,
      buy_rate_3_months: ledger.buyRate3Months,
      buy_rate_4_months: ledger.buyRate4Months,
      buy_rate_5_months: ledger.buyRate5Months,
      buy_rate_6_months: ledger.buyRate6Months,
      buy_rate_7_months: ledger.buyRate7Months,
      buy_rate_8_months: ledger.buyRate8Months,
      buy_rate_9_months: ledger.buyRate9Months,
      buy_rate_10_months: ledger.buyRate10Months,
      buy_rate_11_months: ledger.buyRate11Months,
      buy_rate_12_months: ledger.buyRate12Months,
      buy_rate_13_months: ledger.buyRate13Months,
      buy_rate_14_months: ledger.buyRate14Months,
      buy_rate_15_months: ledger.buyRate15Months,
      buy_rate_16_months: ledger.buyRate16Months,
      buy_rate_17_months: ledger.buyRate17Months,
      buy_rate_18_months: ledger.buyRate18Months,
      override_buy_rates: ledger.overrideBuyRates,
      override_buy_rates_reason: ledger.overrideBuyRatesReason,
    },
  };
};

export const usePatchLedger = (): UseGenericMutationResponse<
  Pick<MutationResponse, 'success'>,
  GenericLedgerApiParams & {
    ledger: Ledger;
  }
> => {
  const [updateLedger, { error, ...rest }] = useApiPatchLedger();

  const patchFunction = ({
    ledger,
    ...ids
  }: GenericLedgerApiParams & {
    ledger: Ledger;
  }): Promise<Pick<MutationResponse, 'success'>> =>
    updateLedger({ ...ids, requestBody: toPatchLedgerReqBody(ledger) });

  return [patchFunction, { error, ...rest }];
};

export const useCloneLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  const [post, { error, ...rest }] = useApiCloneLedger();

  return [post, { error, ...rest }];
};

export const toBuyRates = (data?: BuyRatesResponse): BuyRates | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    buyRateTable: data.buy_rate_table.map((rate) => ({
      months: rate.months,
      rate: rate.rate,
    })),
    totalFactorRateTable: data.total_factor_rate_table.map((rate) => ({
      months: rate.months,
      rate: rate.rate,
    })),
    maxFactorRate: data.max_factor_rate,
  };
};

const toBuyRatesParams = (
  programTypeId: number,
  overrideBuyRates: boolean
): string => {
  return `program_type_id=${programTypeId}&override_buy_rates=${overrideBuyRates}`;
};

export const transformBuyRates = (
  payload: OverrideByRatesPayload
): LedgerBuyRates => {
  const initVal: LedgerBuyRates = {};

  const formattedBuyRates = Object.entries(payload.buy_rates).reduce(
    (acc, [_, value]) => {
      const monthKey = `buyRate${value.months}Months`;

      if (isLedgerBuyRatesKey(monthKey)) {
        acc[monthKey] = value.rate;
      }
      return acc;
    },
    initVal
  );

  return formattedBuyRates;
};

export const useFetchBuyRates = (
  submissionUuid: string,
  ledgerId: number,
  programTypeId: number,
  overrideBuyRates: boolean
): UseGenericQueryResponse<BuyRates> =>
  useGenericFeatureQuery(useApiFetchBuyRates, toBuyRates, {
    submissionUuid,
    ledgerId,
    params: toBuyRatesParams(programTypeId, overrideBuyRates),
  });

const toOverrideBuyRatesPayload = (
  rates: MonthRate[]
): OverrideByRatesPayload => {
  const initValue: { [key: string]: MonthRatePayload } = {};

  return {
    buy_rates: rates.reduce((acc, { months, rate }, index) => {
      acc[`${index}`] = {
        months,
        rate: rate.toString(),
      };
      return acc;
    }, initValue),
  };
};

type UseOverrideBuyRates = [
  (
    params: GenericLedgerApiParams & {
      requestBody: MonthRate[];
    }
  ) => Promise<MutationResponse>,
  { error: Error | undefined }
];

export const useOverrideBuyRates = (): UseOverrideBuyRates => {
  const [override, { error }] = useApiOverrideBuyRates();

  const fn = async (
    params: GenericLedgerApiParams & {
      requestBody: MonthRate[];
    }
  ): Promise<MutationResponse> => {
    const { response, success } = await override({
      ...params,
      requestBody: toOverrideBuyRatesPayload(params.requestBody),
    });
    return {
      success,
      response: toBuyRates(response as BuyRatesResponse),
    };
  };

  return [fn, { error }];
};

export const useResetBuyRates = (): [
  (
    params: GenericLedgerApiParams & {
      programTypeId: number;
    }
  ) => Promise<MutationResponse>,
  { error: Error | undefined; loading: boolean }
] => {
  const [reset, { error, loading }] = useApiResetBuyRates();

  const fn = async (
    params: GenericLedgerApiParams & {
      programTypeId: number;
    }
  ): Promise<MutationResponse> => {
    const { response, success } = await reset({
      ...params,
      params: toBuyRatesParams(params.programTypeId, false),
    });

    return {
      success,
      response: toBuyRates(response as BuyRatesResponse),
    };
  };

  return [fn, { error, loading }];
};

export const useDeleteLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  const [deleteLedger, { error, ...rest }] = useApiDeleteLedger();

  return [deleteLedger, { error, ...rest }];
};

export const useCreateLedger = (): UseGenericMutationResponse<
  MutationResponse,
  Omit<GenericLedgerApiParams, 'ledgerId'>
> => {
  const [createLedger, { error, ...rest }] = useApiCreateLedger();

  return [createLedger, { error, ...rest }];
};

export const toLedgerOffer = (offer: LedgerOfferRaw): LedgerOffer => ({
  approvalAmount: defaultTo(offer.approval_amount, ''),
  multipleOfAdb: defaultTo(offer.multiple_of_adb, 0),
  totalFactorRate: defaultTo(offer.total_factor_rate, 0),
  paybackAmount: defaultTo(offer.payback_amount, ''),
  paybackDays: defaultTo(offer.payback_days, 0),
  dailyPayment: defaultTo(offer.daily_payment, ''),
  paybackAmountGross: defaultTo(offer.payback_amount_gross, ''),
  combinedGross: defaultTo(offer.combined_gross, ''),
  months: Number(defaultTo(offer.months, 0)),
  dollarOverride: defaultTo(
    offer.dollar_override === '0.00' ? undefined : offer.dollar_override,
    undefined
  ),
  gross: defaultTo(offer.gross === '0.00' ? undefined : offer.gross, undefined),
});

export const useCreateCalculatedOffer = (): UseGenericMutationResponse<
  LedgerOffer | undefined,
  UseApiCreateCalculatedOfferParams
> => {
  const [calcOffer, { error, ...rest }] = useApiCreateCalculatedOffer();

  return [calcOffer, { error, ...rest }];
};

const toLedgerOffers = (
  offers: LedgerOfferRaw[] | undefined
): LedgerOffer[] => {
  if (!offers || !offers.length) {
    return [];
  }

  return offers.map((offer) => toLedgerOffer(offer));
};

export const useLedgerOffers = (
  params: Omit<GenericLedgerApiParams, 'customerUuid'>
): UseGenericQueryResponse<LedgerOffer[]> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useFetchOffers,
    (data) => toLedgerOffers(data),
    {
      ...params,
    }
  );

  return {
    error,
    ...rest,
  };
};

const toLedgerBankingSuggestedPricing = (
  data: LedgerBankingPricingSuggestionsRaw
): LedgerBankingPricingSuggestions => {
  return {
    declineMessage: defaultTo(data.decline_message, undefined),
    programType: defaultTo(data.program_type, ''),
    industryRisk: defaultTo(data.industry_risk, ''),
    underwritingAverageScore: defaultTo(data.underwriting_average_score, 0),
    estimatedTerm: defaultTo(data.estimated_term, 0),
    gross: {
      displayedAmount: defaultTo(data.gross?.displayed_amount, ''),
    },
    grossBasis: {
      amount: defaultTo(data.gross_basis?.amount, ''),
      unit: defaultTo(data.gross_basis?.unit, ''),
    },
  };
};

export const useLedgerBankingSuggestedPricing = (
  ledgerId: number
): UseGenericQueryResponse<LedgerBankingPricingSuggestions> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useFetchLedgerBankingSuggestedPricing,
    (data) => data && toLedgerBankingSuggestedPricing(data),
    ledgerId
  );

  return {
    error,
    ...rest,
  };
};

const toLedgerUnderwritingSuggestedPricing = (
  data: PricingSuggestionResponse
): LedgerUnderwritingPricingSuggestions => ({
  programType: data.program_type,
  inputs: {
    riskScore: data.inputs.risk_score,
    industryRisk: data.inputs.industry_risk,
    financingsGross: data.inputs.financings_gross,
  },
  maxTerm: data.max_term,
  roundedAmount: data.rounded_amount,
  submissionUuid: data.submission_uuid,
});

const toLedgerUnderwritingSuggestedPricingParams = (
  params: LedgerUnderwritingPricingSuggestionsParams
): LedgerUnderwritingPricingSuggestionsGetParams => ({
  source_key: params.sourceKey,
  source_type: params.sourceType,
  revenue: params.revenue,
  financings_count: params.financingsCount,
  financings_gross: params.financingsGross,
});

export const useLedgerUnderwritingSuggestedPricing = (
  submissionUuid: string,
  params: LedgerUnderwritingPricingSuggestionsParams
): UseGenericQueryResponse<LedgerUnderwritingPricingSuggestions> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useFetchLedgerUnderwritingSuggestedPricing,
    (data) =>
      data && toLedgerUnderwritingSuggestedPricing(data.primary_suggestion),
    submissionUuid,
    toLedgerUnderwritingSuggestedPricingParams(params)
  );

  return {
    error,
    ...rest,
  };
};

export type UseUpdateLedgerResponse = [
  (args: UseValidationOverrideArgs) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse>
];

export const useValidationOverride = (): UseUpdateLedgerResponse => {
  const [updateFn, { error, ...rest }] = useValidationOverrideApi();

  const updateFunction = (
    args: UseValidationOverrideArgs
  ): Promise<MutationResponse> => {
    return updateFn({
      submissionUuid: args.submissionUuid,
      updateBody: {
        note: args.updateBody.note,
        stage: args.updateBody.stage,
        missing_attributes: args.updateBody.missing_attributes,
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      ...rest,
    },
  ];
};

export const toOverriddenValidations = (
  data: OverriddenValidationsResponse[] | undefined
): OverriddenValidations[] | undefined => {
  if (!data || data.length === 0) {
    return undefined;
  }

  return data.map((response) => ({
    id: response.id,
    note: defaultTo(response.note, undefined),
    stage: response.stage,
    createdById: defaultTo(response.created_by_id, undefined),
    createdAt: defaultTo(response.created_at, undefined),
    missingAttributes: defaultTo(
      response.missing_attributes?.filter((item) => item !== ''),
      undefined
    ),
  }));
};

export const useGetOverriddenValidations = (
  submissionUuid: string
): UseGenericQueryResponse<OverriddenValidations[]> =>
  useGenericFeatureQuery(
    useApiGetOverriddenValidations,
    toOverriddenValidations,
    submissionUuid
  );

const toUser = (
  userResponse: AuthenticatedUserResponse | undefined
): User | undefined => {
  if (!userResponse) {
    return undefined;
  }

  const user = userResponse.user;

  return {
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
  };
};

export const useGetUser = (
  id?: string | number
): UseGenericQueryResponse<User> =>
  useGenericFeatureQuery(useGetApiUser, toUser, id);

export const useDeleteOwner = (): UseApiDeleteOwnerResponse =>
  useApiDeleteOwner();
