import React from 'react';
import {
  LexisNexisCustomerManualSearchData,
  PaynetManualSearchData,
  PaynetSearchResult,
  SearchType,
} from './businessManualSearch.types';
import { LexisNexisManualSearchTable } from './LexisNexisManualSearchTable';
import { PaynetSearchResultsTable } from './PaynetResultsTable';

type BusinessManualSearchTableProps = {
  data: PaynetManualSearchData[] | LexisNexisCustomerManualSearchData[];
  submissionUuid: string;
  searchType: SearchType;
};
export const BusinessManualSearchTable = ({
  data,
  submissionUuid,
  searchType,
}: BusinessManualSearchTableProps): JSX.Element => {
  return searchType === 'paynet' ? (
    <PaynetSearchResultsTable
      searchResults={data as PaynetSearchResult[]}
      submissionUuid={submissionUuid}
    />
  ) : (
    <LexisNexisManualSearchTable
      submissionUuid={submissionUuid}
      searchResults={data as LexisNexisCustomerManualSearchData[]}
    />
  );
};
