import React, { useEffect } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  formatDateTimeString,
  Grid,
  Loading,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { V6ScoreDisplayTable } from './V6ScoreDisplayTable';
import {
  useGetUser,
  useInference,
  usePullInference,
  useUpdateSubmission,
} from './v6ScoreDisplayHooks';

type V6ScoreDisplayProps = {
  submissionUuid: string;
};

export const V6ScoreDisplay = ({
  submissionUuid,
}: V6ScoreDisplayProps): JSX.Element => {
  const {
    data: inferenceData,
    loading: inferenceLoading,
    error: inferenceError,
    refetch: refetchInference,
  } = useInference(submissionUuid);

  const [
    pullInference,
    {
      data: pullInferenceData,
      error: pullInferenceError,
      loading: pullInferenceLoading,
    },
  ] = usePullInference();

  const { data: createdByUser } = useGetUser(inferenceData?.createdBy);
  const createdByUserFullName = createdByUser
    ? `${createdByUser.firstName} ${createdByUser.lastName}`
    : '';

  const [updateRiskScore, { error: updateRiskScoreError }] =
    useUpdateSubmission(submissionUuid);

  useEffect(() => {
    // Using typeof in the conditional to handle a rare edge case where the score is 0,
    // which would be falsy and not trigger the update
    if (typeof inferenceData?.score === 'number') {
      void updateRiskScore({
        riskScore: inferenceData?.score,
      });
    }
  }, [inferenceData?.score, updateRiskScore]);

  if (inferenceLoading) {
    return <Loading />;
  }

  const handleRefresh = async (): Promise<void> => {
    const result = await pullInference({
      submissionUuid,
    });

    if (result.success) {
      void refetchInference();
    }
  };

  const showAutoDeclineBanner =
    (pullInferenceData && pullInferenceData.autoDecline) ||
    (inferenceData && inferenceData.decision.declineRecommendation);

  const hasInferenceData = inferenceData !== undefined;

  return (
    <Box mb={4}>
      {inferenceError && <Banner>{inferenceError?.message}</Banner>}
      {pullInferenceError && <Banner>{pullInferenceError?.message}</Banner>}
      {updateRiskScoreError && <Banner>{updateRiskScoreError?.message}</Banner>}
      {showAutoDeclineBanner && (
        <Banner variant="error">
          Based on the risk score, the recommendation is to decline the
          submission.
        </Banner>
      )}

      <Flex gap={3} alignItems={'center'}>
        <Button
          startIcon="refresh"
          onClick={() => handleRefresh()}
          disabled={pullInferenceLoading}
        >
          Refresh V6 Scores
        </Button>

        {inferenceData?.createdAt && (
          <Text>
            Last updated on {formatDateTimeString(inferenceData?.createdAt)}{' '}
            {!!createdByUserFullName && `by ${createdByUserFullName}`}
          </Text>
        )}
      </Flex>

      {hasInferenceData && (
        <>
          <Subheading>V6 Score: {inferenceData?.score}/20</Subheading>

          <Grid gutter>
            <Grid.Item l={12} xl={6}>
              <Subheading variant="section">Top Supporters</Subheading>

              <V6ScoreDisplayTable features={inferenceData?.topFive} />
            </Grid.Item>

            <Grid.Item l={12} xl={6}>
              <Subheading variant="section">Top Detractors</Subheading>

              <V6ScoreDisplayTable features={inferenceData?.bottomFive} />
            </Grid.Item>
          </Grid>
        </>
      )}
    </Box>
  );
};
