import React from 'react';

export interface IconProps {
  name: string;
}

export const Icon = ({ name }: IconProps): JSX.Element => {
  return (
    // The key attribute here is important because it will force React to recognize the change to the
    // element and diff the child element. This is due to the way font-awesome is working under the hood,
    // where the DOM is being modified directly without React being aware.
    <span key={name} className="icon is-small">
      <i className={`fas ${name}`} role="img" />
    </span>
  );
};
