import React from 'react';
import {
  Box,
  Text,
  Subheading,
  List,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const FederalTaxIdPopover = (): JSX.Element => {
  return (
    <Popover
      header="Federal Tax ID (FEIN)"
      content={
        <Box>
          <Subheading variant="subsection">Missing FEIN</Subheading>
          <List>
            <List.Item>
              <Text>
                If the business{' '}
                <Text bold italic>
                  is
                </Text>{' '}
                a Sole Proprietor:
              </Text>
              <List>
                <List.Item>Use the SSN as the Tax ID</List.Item>
                <List.Item>Confirm the Tax ID with the ISO</List.Item>
                <List.Item>
                  Send to Processing if no Auto-Decline Criteria is found
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text>
                If the business{' '}
                <Text bold italic>
                  is not
                </Text>{' '}
                a Sole Proprietor and there is no Auto-Decline Criteria:
              </Text>
              <List>
                <List.Item>Confirm the Tax ID with the ISO</List.Item>
                <List.Item>Send to Processing</List.Item>
              </List>
            </List.Item>
          </List>
          <Subheading variant="subsection">Incomplete FEIN</Subheading>
          <List>
            <List.Item>
              If FEIN has less than 9 digits:{' '}
              <List>
                <List.Item>
                  Select &ldquo;FEIN not on application&rdquo;
                </List.Item>
                <List.Item>Complete the Prequal process</List.Item>
                <List.Item>Confirm the Tax ID with the ISO</List.Item>
                <List.Item>
                  Send to Processing if no Auto-Decline Criteria is found
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="Federal Tax ID more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
