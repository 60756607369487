export const FAQ_QUESTIONS = [
  {
    question: 'Do I have to fill in all Search Criteria information?',
    answer:
      'For the most accurate results, we recommend inputting as much information as you have. The only optional fields are Phone Number and FEIN as sometimes they may not be on the application. If they are missing, you will click the not on application button.',
  },
  {
    question: 'How does the search query work?',
    answer:
      "Our search query works as: Legal name, DBA name, AND address OR FEIN /Tax ID match exactly would be considered a verified match. Matching elements that don't meet criteria of exact match would be considered a potential match and show in the results. ",
  },
  {
    question: "What is a 'Recommended Match'?",
    answer:
      'A “Recommended Match” is what we used to call a “Verified Match”. This would be if Legal name, DBA name, AND address OR FEIN /Tax ID match exactly.',
  },
];
