import React from 'react';
import { Box, Loading } from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { CompareAndSelect } from './CompareAndSelect';
import { CompareAndSelectSkeleton } from './CompareAndSelectSkeleton';

export const CompareAndSelectLayout = (): JSX.Element => {
  const { adjustmentProjections, isLoadingAdjustmentProjections } =
    usePaymentScheduleContext();

  if (isLoadingAdjustmentProjections) {
    return (
      <Box>
        <Loading size="medium" text="Loading Projections..." />
      </Box>
    );
  }

  return (
    <Box>
      {Array.isArray(adjustmentProjections) ? (
        <CompareAndSelect />
      ) : (
        <CompareAndSelectSkeleton />
      )}
    </Box>
  );
};
