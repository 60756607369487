import { defaultTo } from 'lodash';
import {
  LienJudgmentRecord as ApiLienJudgmentRecord,
  LienJudgmentRecordAddress as ApiLienJudgmentRecordAddress,
} from 'types/api/underwriting/types';

import { LienJudgmentRecord, LienJudgmentRecordAddress } from './types';

// A lien must have a non-null type, because the type array is how we determine
// that it is a lien. This is helpful so we don't have to add extra condtions
// to check for null for the functions below that categorize only liens.
// E.g. `isLienState`.
interface Lien extends LienJudgmentRecord {
  type: string[];
}

type CategorizedLiens = {
  liensOpen: LienJudgmentRecord[];
  liensReleased: LienJudgmentRecord[];
  liensStateOpen: LienJudgmentRecord[];
  liensStateReleased: LienJudgmentRecord[];
  liensFederalOpen: LienJudgmentRecord[];
  liensFederalReleased: LienJudgmentRecord[];
  judgmentsOpen: LienJudgmentRecord[];
  judgmentsSettled: LienJudgmentRecord[];
};

export const categorizeLiensJudgments = (
  liensJudgments: LienJudgmentRecord[]
): CategorizedLiens => {
  const isOpen = (lien: LienJudgmentRecord): boolean =>
    lien.status !== 'RELEASED';
  const isReleased = (lien: LienJudgmentRecord): boolean => !isOpen(lien);

  const isTypeLien = (type: string): boolean => /lien/i.exec(type) !== null;

  const isTypeState = (type: string): boolean =>
    isTypeLien(type) && /state/i.exec(type) !== null;
  const isTypeFederal = (type: string): boolean =>
    isTypeLien(type) && /federal/i.exec(type) !== null;

  // lien.type is an array of strings, which is why we need to use .some()
  const isLien = (lien: LienJudgmentRecord): boolean =>
    lien.type?.some((type) => isTypeLien(type)) ?? false;
  const isJudgement = (lien: LienJudgmentRecord): boolean => !isLien(lien);

  const isLienState = (lien: Lien): boolean => lien.type.some(isTypeState);
  const isLienFederal = (lien: Lien): boolean => lien.type.some(isTypeFederal);

  const liens = liensJudgments.filter(isLien) as Lien[];
  const judgments = liensJudgments.filter(isJudgement);

  const liensOpen = liens.filter(isOpen);
  const liensReleased = liens.filter(isReleased);

  const liensStateOpen = liensOpen.filter(isLienState);
  const liensStateReleased = liensReleased.filter(isLienState);

  const liensFederalOpen = liensOpen.filter(isLienFederal);
  const liensFederalReleased = liensReleased.filter(isLienFederal);

  const judgmentsOpen = judgments.filter(isOpen);
  const judgmentsSettled = judgments.filter(isReleased);

  return {
    liensOpen,
    liensReleased,
    liensStateOpen,
    liensStateReleased,
    liensFederalOpen,
    liensFederalReleased,
    judgmentsOpen,
    judgmentsSettled,
  };
};

const toLienJudgmentAddress = (
  address: ApiLienJudgmentRecordAddress | null
): LienJudgmentRecordAddress => ({
  streetAddress: address
    ? `${address.street_number} ${address.street_name} ${address.street_suffix}`
    : '',
  city: defaultTo(address?.city, ''),
  state: defaultTo(address?.state, ''),
  zipCode: address
    ? `${address.zip5}${address.zip4 ? `-${address.zip4}` : ''}`
    : '',
});

const toLienJudgmentAddresses = (
  address: ApiLienJudgmentRecordAddress | ApiLienJudgmentRecordAddress[] | null
): LienJudgmentRecordAddress | LienJudgmentRecordAddress[] => {
  // Backend can return an array of addresses, or a single address!
  if (Array.isArray(address)) {
    return address.map(toLienJudgmentAddress);
  } else {
    return toLienJudgmentAddress(address);
  }
};

export const toLienJudgment = (
  lien: ApiLienJudgmentRecord
): LienJudgmentRecord => ({
  type: lien.type,
  creditor: lien.creditor,
  debtor: lien.debtor.map((debtor) => ({
    name: debtor.name,
    address: toLienJudgmentAddresses(debtor.address),
  })),
  originFilingDate: lien.origin_filing_date,
  amount: defaultTo(lien.amount, undefined),
  status: lien.status,
});
