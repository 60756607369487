import React from 'react';
import { CollectionNote } from './PaymentHistory.types';
import {
  Box,
  Divider,
  Flex,
  formatDate,
  Text,
} from '@forward-financing/fast-forward';

export interface CollectionsNoteProps {
  collectionNote: CollectionNote;
}

export const CollectionsNote = ({
  collectionNote,
}: CollectionsNoteProps): JSX.Element => {
  return (
    <Box>
      <Flex alignItems="center">
        <Text>{collectionNote.creatorName}</Text>
        <Divider orientation="vertical" />
        <Text>
          {formatDate(collectionNote.createdAt, { excludeTime: true })}
        </Text>
      </Flex>
      <Text>{collectionNote.note}</Text>
      <Divider />
    </Box>
  );
};
