import React, { useState } from 'react';
import {
  Button,
  Flex,
  formatDateString,
  IconButton,
  Text,
} from '@forward-financing/fast-forward';

import { usePaymentScheduleContext } from './PaymentScheduleContext';
import { getBusinessDays } from './paymentScheduleFetchUtils';
import { FFLogger } from 'api/LogClient';
import { toError } from 'helpers/errorUtils';

export const ResumePayments = (): JSX.Element => {
  const { paymentSchedule, resumePaymentsOn } = usePaymentScheduleContext();

  const [resumeDate, setResumeDate] = useState<string | null>(null);

  const arePaymentsPaused = paymentSchedule?.some(
    (day) => day.kind === 'IndefinitePauseDay'
  );

  const handleResumeClick = async (): Promise<void> => {
    try {
      // toISOString returns a string in the format: 2011-10-05T14:48:00.000Z
      // we just want the date part, so split on the `T` and take the first part
      const [dateString] = new Date().toISOString().split('T');

      const businessDaysResponse = await getBusinessDays(dateString);
      const startDate = businessDaysResponse.pop()?.date.toString();

      setResumeDate(
        startDate ??
          /* istanbul ignore next: we should always have a date if we get to this point */
          null
      );
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
    }
  };

  if (!arePaymentsPaused) {
    return <></>;
  }

  if (resumeDate) {
    const resumePayments = async (): Promise<void> => {
      // error handling is all done inside this function
      await resumePaymentsOn(resumeDate);
      setResumeDate(null);
    };

    return (
      <Flex gap={2} alignItems="center" flexWrap="wrap">
        <Text>
          The Advance will resume payments starting{' '}
          {formatDateString(resumeDate)}
        </Text>
        <Flex gap={2} alignItems="center" flexWrap="wrap">
          <IconButton icon="check" label="Resume" onClick={resumePayments} />
          <IconButton
            icon="x"
            label="Cancel"
            onClick={() => setResumeDate(null)}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Button onClick={handleResumeClick} startIcon={['far', 'circle-play']}>
      Resume Payments
    </Button>
  );
};
