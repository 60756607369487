import {
  UseApiDeclineReasons,
  useApiDeclineReasons,
} from 'apiHooks/banking/exceptionRequestDeclineReasonsFetchHooks';
import {
  useApiCreateExceptionRequests,
  useApiDeclineExceptionRequest,
  useApiExceptionRequests,
  UseApiExceptionRequestsResponse,
  useApiUpdateExceptionRequests,
} from 'apiHooks/banking/exceptionRequestFetchHooks';
import {
  useApiCurrentOffer,
  UseApiCurrentOfferResponse,
} from 'apiHooks/banking/offerFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  OfferResponse,
  ExceptionRequestRecord,
  UpdateLedgerBody,
  CreditDecisionResponse,
} from 'types/api/banking/types';
import { useApiUpdateLedger } from 'apiHooks/banking/ledgerFetchHooks';
import {
  UseApiCreditDecisionsResponse,
  useApiCreditDecisions,
} from 'apiHooks/banking/creditDecisionFetchHooks';
import {
  ExceptionRequest,
  ExceptionRequestProgram,
  Offer,
} from './exceptionsRequest.types';
import { CreditDecision } from './types';

const toOffer = (requestOffer: OfferResponse): Offer => {
  return {
    buyRate: parseFloat(requestOffer.buy_rate),
    fundingAmount: requestOffer.funding_amount,
    id: requestOffer.id,
    program: requestOffer.program,
    termLength: requestOffer.term_length,
    underwritingLedgerId: requestOffer.underwriting_ledger_id,
  };
};

const toExceptionRequest = (
  exceptionRequest: ExceptionRequestRecord
): ExceptionRequest => {
  return {
    id: exceptionRequest.id,
    termLength: exceptionRequest.months,
    buyRate: exceptionRequest.buy_rate,
    program: exceptionRequest.program,
    fundingAmount: exceptionRequest.gross,
    createdAt: exceptionRequest.created_at,
    requestingUser: exceptionRequest.requesting_user,
    offer: exceptionRequest.offer ? toOffer(exceptionRequest.offer) : null,
    decisionReversal: exceptionRequest.decision_reversal,
    decisionType: exceptionRequest.decision_type,
    reviewCompletedAt: exceptionRequest.review_completed_at,
    status: exceptionRequest.status,
    underwriterDecision: exceptionRequest.underwriter_decision
      ? toOffer(exceptionRequest.underwriter_decision)
      : null,
    declineNotes: exceptionRequest.decline_notes ?? undefined,
    offerExceptionNotes: exceptionRequest.offer_exception_notes ?? undefined,
    stipExceptionNotes: exceptionRequest.stip_exception_notes ?? undefined,

    reviewerUser: exceptionRequest.reviewer_user,
    underwritingLedgerId:
      exceptionRequest.offer?.underwriting_ledger_id ?? null,
    displayStatus: exceptionRequest.status.replace('_', ' '),
    inReview: ['pending_review', 'in_progress'].includes(
      exceptionRequest.status
    ),
    submittedAt: exceptionRequest.created_at,
    underwritingOfferId: exceptionRequest.offer?.id ?? null,
  };
};

const toCreditDecision = (
  creditDecision: CreditDecisionResponse
): CreditDecision => {
  return {
    frequency: creditDecision.data.attributes.frequency || 'Daily',
    eligibleForWeekly: creditDecision.data.attributes.eligible_for_weekly,
    processingFeeWaivedCents:
      creditDecision.data.attributes.processing_fee_waived_cents,
    processingFeeWaivedPercentage:
      creditDecision.data.attributes.processing_fee_waived_percentage,
    maxUpsell: creditDecision.data.attributes.max_upsell,
  };
};

interface UseExceptionRequestsResponse
  extends Omit<UseApiExceptionRequestsResponse, 'data'> {
  requests?: ExceptionRequest[];
}

export const useExceptionRequests = (
  submissionUuid?: string
): UseExceptionRequestsResponse => {
  const { data, error, ...rest } = useApiExceptionRequests(submissionUuid);

  return {
    requests: data?.underwriting_exception_requests.map(toExceptionRequest),
    error,
    ...rest,
  };
};

interface UseCurrentOfferResponse
  extends Omit<UseApiCurrentOfferResponse, 'data'> {
  offer?: Offer;
}

export const useCurrentOffer = (
  submissionUuid?: string
): UseCurrentOfferResponse => {
  const { data, error, ...rest } = useApiCurrentOffer(submissionUuid);

  return {
    offer: data ? toOffer(data) : undefined,
    error,
    ...rest,
  };
};

interface CreateExceptionRequestArgs {
  submissionUuid: string;
  requestingUser: string;
  fundingAmount: number;
  buyRate: number;
  program: ExceptionRequestProgram;
  termLength: number;
  stipExceptionNotes?: string;
  offerExceptionNotes?: string;
  decisionReversal?: boolean;
  underwritingOfferId?: number | null;
  frequency?: string;
}

export type UseCreateExceptionRequestResponse = [
  (args: CreateExceptionRequestArgs) => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

export const useCreateExceptionRequest =
  (): UseCreateExceptionRequestResponse => {
    const [createFn, { error, ...rest }] = useApiCreateExceptionRequests();

    const createFunction = async (
      args: CreateExceptionRequestArgs
    ): Promise<MutationResponse> => {
      return createFn({
        submissionUuid: args.submissionUuid,
        createBody: {
          requesting_user: args.requestingUser,
          gross: args.fundingAmount,
          buy_rate: args.buyRate,
          program: args.program,
          months: args.termLength,
          stip_exception_notes: args.stipExceptionNotes,
          offer_exception_notes: args.offerExceptionNotes,
          decision_reversal: args.decisionReversal,
          underwriting_offer_id: args.underwritingOfferId,
          frequency: args.frequency,
        },
      });
    };

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };

export interface UpdateExceptionRequestBody {
  requestingUser: string;
  fundingAmount: number;
  buyRate: number;
  program: ExceptionRequestProgram;
  months: number;
  stipExceptionNotes?: string;
  offerExceptionNotes?: string;
  decisionReversal?: boolean;
  offerId?: number | null;
  frequency?: string;
}

export interface UseUpdateExceptionRequestArgs {
  exceptionRequestId: number;
  body: UpdateExceptionRequestBody;
}

export type UseUpdateExceptionRequestResponse = [
  (args: UseUpdateExceptionRequestArgs) => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

export const useUpdateExceptionRequest =
  (): UseUpdateExceptionRequestResponse => {
    const [updateFn, { error, ...rest }] = useApiUpdateExceptionRequests();

    const updateFunction = (
      args: UseUpdateExceptionRequestArgs
    ): Promise<MutationResponse> => {
      return updateFn({
        exceptionRequestId: args.exceptionRequestId,
        updateBody: {
          requesting_user: args.body.requestingUser,
          gross: args.body.fundingAmount,
          buy_rate: args.body.buyRate,
          program: args.body.program,
          months: args.body.months,
          stip_exception_notes: args.body.stipExceptionNotes,
          offer_exception_notes: args.body.offerExceptionNotes,
          decision_reversal: args.body.decisionReversal,
          underwriting_offer_id: args.body.offerId,
          frequency: args.body.frequency,
        },
      });
    };

    return [
      updateFunction,
      {
        error,
        ...rest,
      },
    ];
  };

export interface UseDeclineReasonsResponse
  extends Omit<UseApiDeclineReasons, 'data'> {
  declineReasons?: string[];
}

export const useDeclineReasons = (): UseDeclineReasonsResponse => {
  const { data, error, ...rest } = useApiDeclineReasons();

  return {
    declineReasons: data,
    error,
    ...rest,
  };
};

export interface UseDeclineExceptionRequestArgs {
  declineNotes: string;
  reviewerId: number;
  declineReason: string;
}

export type UseDeclineExceptionRequestResponse = [
  (args: UseDeclineExceptionRequestArgs) => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

export const useDeclineExceptionRequest = (
  exceptionRequestId: number
): UseDeclineExceptionRequestResponse => {
  const [updateFn, { error, ...rest }] = useApiDeclineExceptionRequest();

  const updateFunction = (
    args: UseDeclineExceptionRequestArgs
  ): Promise<MutationResponse> => {
    return updateFn({
      exceptionRequestId: exceptionRequestId,
      requestBody: {
        underwriting_exception_request: {
          decline_notes: args.declineNotes,
          reviewer_id: args.reviewerId,
          decline_reason: args.declineReason,
        },
        decision: 'decline',
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      ...rest,
    },
  ];
};

interface UseCreditDecisionResponse
  extends Omit<UseApiCreditDecisionsResponse, 'data'> {
  creditDecision?: CreditDecision;
}

/**
 This feature currently only cares about credit decision data for approvals. The endpoint actually returns
 either an approval or a decline, which have different response shapes. This hook will only parse and
 return approval data, and will return undefined for declines.

 If the requirements of this feature change such that we need to do something with decline data as well,
 we can make the return type on this hook more dynamic, but for the time being accounting for declines
 only added complexity and no value.
 */
export const useCreditDecision = (
  submissionUuid?: string
): UseCreditDecisionResponse => {
  const { data, error, ...rest } = useApiCreditDecisions(submissionUuid);

  return {
    creditDecision:
      data?.data.attributes.decision_type === 'approval'
        ? toCreditDecision(data as CreditDecisionResponse)
        : undefined,
    error,
    ...rest,
  };
};

export interface UseUpdateLedgerArgs {
  submissionUuid: string;
  body: UpdateLedgerBody;
}

export type UseUpdateLedgerResponse = [
  (args: UseUpdateLedgerArgs) => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

export const useUpdateLedger = (): UseUpdateLedgerResponse => {
  const [updateFn, { error, ...rest }] = useApiUpdateLedger();

  const updateFunction = (
    args: UseUpdateLedgerArgs
  ): Promise<MutationResponse> => {
    return updateFn({
      submissionUuid: args.submissionUuid,
      updateBody: {
        frequency: args.body.frequency,
        processing_fee_waived_cents: args.body.processing_fee_waived_cents,
        processing_fee_waived_percentage:
          args.body.processing_fee_waived_percentage,
        max_upsell: args.body.max_upsell,
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      ...rest,
    },
  ];
};
