import React, { useEffect, useState } from 'react';
import { SubmissionSearchList } from './SubmissionSearchList';
import {
  Banner,
  Box,
  Heading,
  Loading,
  PageContainer,
  Pagination,
  Text,
} from '@forward-financing/fast-forward';
import { SearchForm } from './SearchForm';
import { UnderwritingClient } from 'api/UnderwritingClient';
import { FundingClient } from 'api/FundingClient';
import { Partner } from 'api/FundingClient/codecs';
import { Meta, SubmissionSearchResult } from 'api/UnderwritingClient/codecs';
import {
  ActiveSearch,
  HandleSearchSubmitOptions,
} from './submissionSearch.types';
import { INITIAL_START_DATE } from './constants';

export const SubmissionSearch = (): JSX.Element => {
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [partners, setPartners] = useState<Partner[]>([]);

  const [searchResults, setSearchResults] = useState<SubmissionSearchResult[]>(
    []
  );
  const [meta, setMeta] = useState<Meta | null>(null);

  const [activeSearch, setActiveSearch] = useState<ActiveSearch>({
    partnerId: undefined,
    startDate: INITIAL_START_DATE,
    endDate: new Date(),
    query: '',
    page: 1,
  });

  useEffect(() => {
    document.title = 'Submission Search';
  }, []);

  useEffect(() => {
    const fetchPartners = async (): Promise<void> => {
      try {
        const partnersResponse = await FundingClient.fetchPartners();

        setPartners(partnersResponse);
      } catch (e: unknown) {
        setErrorMessage('Could not load partners');
      }
    };

    void fetchPartners();
  }, []);

  const handleSearchSubmit = async (
    activeSearchData: ActiveSearch,
    options: HandleSearchSubmitOptions
  ): Promise<void> => {
    setIsLoadingResults(true);
    try {
      const { meta: resultsMeta, submissionSearchResults } =
        await UnderwritingClient.searchApplications({
          // if we do not send a partner ID, the backend will default
          // to Forward Financing as the partner. Sending 0 tells it not
          // to include partner in the search params
          partner_id: activeSearchData.partnerId ?? 0,
          start_date: activeSearchData.startDate,
          end_date: activeSearchData.endDate,
          q: activeSearchData.query,
          page: options.resetPagination ? 1 : activeSearchData.page,
        });

      setSearchResults(submissionSearchResults);
      setMeta(resultsMeta);
    } catch (e: unknown) {
      setErrorMessage('Could not load search results');
    } finally {
      setIsLoadingResults(false);
    }
  };

  const handlePaginationChange = (pageNum: number): void => {
    setActiveSearch((prev) => ({ ...prev, page: pageNum }));
    void handleSearchSubmit(
      { ...activeSearch, page: pageNum },
      { resetPagination: false }
    );
  };

  return (
    <PageContainer>
      <Box px={4}>
        {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
        <Box>
          <Heading>Submission Search</Heading>
          <SearchForm
            activeSearch={activeSearch}
            setActiveSearch={setActiveSearch}
            partners={partners}
            handleSubmit={handleSearchSubmit}
          />

          {isLoadingResults ? (
            <Loading />
          ) : (
            <Box>
              {meta && (
                <>
                  {searchResults.length === 0 ? (
                    <Text>No submission search results found.</Text>
                  ) : (
                    <SubmissionSearchList
                      submissionSearchResults={searchResults}
                      meta={meta}
                      partners={partners}
                    />
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
        {meta && meta?.total_pages > 1 && (
          <Pagination
            numPages={meta.total_pages}
            currentPage={meta.current_page}
            onPageClick={handlePaginationChange}
          />
        )}
      </Box>
    </PageContainer>
  );
};
