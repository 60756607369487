import React from 'react';
import { UsState } from 'api/UnderwritingClient/codecs';
import { useGoogleAddressSearch } from 'hooks/useGoogleAddressSearch.hook';
import { FFLogger } from 'api/LogClient';
import {
  Autocomplete,
  AutocompleteProps,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';

export interface GoogleZipLookupProps {
  handleAutofill: (fields?: { city: string; state: string }) => void;
  label: string;
  onChange: (val: string) => void;
  placeholder: string;
  usStates: UsState[];
  value: string;
}

export const GoogleZipCodeLookup = (
  props: GoogleZipLookupProps
): JSX.Element => {
  const {
    getPlaceDetails,
    suggestions: { status, data },
    setValue,
  } = useGoogleAddressSearch(props.usStates);

  const items = data.reduce(
    (places: { value: string }[], { types, description }) => {
      if (types.includes('postal_code')) {
        places.push({ value: description });
      }
      return places;
    },
    []
  );

  const handleInput: AutocompleteProps['onValueChange'] = (val: string) => {
    // Only allow zip 5 or 9 digit format (up to 9 numbers with optional dash)
    // If the value is outside this regex we assume that a result from lookup was selected
    if (/^[0-9]{0,5}-?[0-9]{0,4}$/.exec(val)) {
      props.onChange(val);
      setValue(val.substring(0, 5));
    } else {
      const place = data.find(({ description }) => description === val);
      if (place) {
        getPlaceDetails(place?.description, place?.place_id)
          .then((fields) => props.handleAutofill(fields))
          .catch((e: unknown) => {
            const error = toError(e);
            FFLogger.error(error);
          });
      }
    }
  };

  return (
    <Autocomplete
      name="zip"
      type="number"
      value={props.value}
      onValueChange={handleInput}
      label={props.label}
      items={status === 'OK' ? items : []}
      placeholder={props.placeholder}
    />
  );
};
