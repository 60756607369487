// Modal with text message and buttons to confirm or cancel an action
import React from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Loading,
  Modal,
  Text,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { useGroupedSubmissionLogs } from './applicationSnapshotFetchHooks';
import {
  ApplicationSnapshotSubmission,
  SubmissionLogs,
} from './applicationSnapshot.types';
import { UserFullNameText } from './AssignmentHistoryModal/UserFullNameText';

type AssignOverwriteConfirmationModalProps = {
  submission: ApplicationSnapshotSubmission;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const AssignOverwriteConfirmationModal = ({
  submission,
  isOpen,
  onConfirm,
  onClose,
}: AssignOverwriteConfirmationModalProps): JSX.Element => {
  const {
    data: submissionLogs,
    loading: submissionLogsLoading,
    error: submissionLogsError,
    responseReady: submissionLogsResponseReady,
  } = useGroupedSubmissionLogs(submission.uuid);

  const getLatestRelevantLog = (): SubmissionLogs | null => {
    if (!submissionLogs) {
      return null;
    }

    let latestLog: SubmissionLogs | null = null;

    switch (submission.subStage) {
      case 'In Underwriting':
        latestLog = submissionLogs.underwriterAssigned?.slice(-1)[0];
        break;
      case 'Credit Committee':
        latestLog = submissionLogs.creditCommitteeStart?.slice(-1)[0];
        break;
      case 'Underwriting Exception Request':
      case 'In Exception Review':
        latestLog =
          submissionLogs.underwritingExceptionRequestStart?.slice(-1)[0];
        break;
      case 'Final UW Signoff':
        latestLog = submissionLogs.underwritingFinalSignoffStart?.slice(-1)[0];
        break;
    }

    return latestLog;
  };

  const latestAssignmentLog = getLatestRelevantLog();

  const assignedToName = latestAssignmentLog ? (
    <Text bold>
      {submission.subStage === 'Credit Committee' ? (
        latestAssignmentLog.assignedTo
      ) : (
        <UserFullNameText userId={latestAssignmentLog.assignedTo} />
      )}
    </Text>
  ) : (
    <></>
  );

  const assignedByName = latestAssignmentLog ? (
    <UserFullNameText userId={latestAssignmentLog.assignedBy} />
  ) : (
    <></>
  );

  return (
    <Modal
      body={
        <Box>
          {submissionLogsLoading && <Loading />}

          {submissionLogsError && (
            <Banner variant="error">{submissionLogsError.message}</Banner>
          )}

          {submissionLogsResponseReady && (
            <>
              {latestAssignmentLog ? (
                <Text>
                  This Submission in the <Text bold>{submission.subStage}</Text>{' '}
                  sub-stage was previously assigned to{' '}
                  <Text bold>{assignedToName}</Text> by{' '}
                  <Text bold>{assignedByName}</Text> at{' '}
                  {formatDateTimeString(latestAssignmentLog.createdAt)}.
                </Text>
              ) : (
                <Text>
                  This Submission was previously assigned to another user.
                </Text>
              )}

              <Text>
                Are you sure you want to proceed with this assignment?
              </Text>

              <Flex
                justifyContent={'center'}
                flexDirection={'row'}
                gap={4}
                mt={5}
              >
                <Button
                  startIcon="times"
                  onClick={() => onClose()}
                  variant="secondary"
                >
                  Cancel
                </Button>

                <Button onClick={() => onConfirm()}>Confirm Assignment</Button>
              </Flex>
            </>
          )}
        </Box>
      }
      title="Confirm Reassignment"
      isOpen={isOpen}
      onOpenChange={onClose}
      trigger={<></>}
    />
  );
};
