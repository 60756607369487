import React from 'react';
import { Box, List, Subheading } from '@forward-financing/fast-forward';

export const ProcessScorePopoverContent = (): JSX.Element => (
  <Box>
    <Subheading variant="subsection">No Changes</Subheading>
    <List>
      <List.Item>
        No changes. Process/checklist followed; notes correctly completed.
      </List.Item>
    </List>
    <Subheading variant="subsection">Minor Changes</Subheading>
    <List>
      <List.Item>
        Minor feedback needed. Missed minor Information; changes to notes.
      </List.Item>
    </List>
    <Subheading variant="subsection">Major Changes</Subheading>
    <List>
      <List.Item>
        Major feedback needed. Process not followed correctly; missing key
        information; incorrect assumptions.
      </List.Item>
    </List>
  </Box>
);
