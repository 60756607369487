import React from 'react';
import { formatBoolean } from '../../../helpers/stringUtils';
import {
  Box,
  Currency,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { ExceptionRequest } from '../exceptionsRequest.types';
import { NoteList } from './NoteList';

export interface RequestDetailsProps {
  exceptionRequest: ExceptionRequest;
}

export const RequestDetails = (props: RequestDetailsProps): JSX.Element => {
  const { exceptionRequest } = props;
  const { offer } = exceptionRequest;

  const rowData = [
    {
      label: 'FF Offer',
      fundingAmount: offer?.fundingAmount,
      termLength: offer?.termLength,
      buyRate: offer?.buyRate,
      program: offer?.program,
      decisionReversal: 'N/A',
      notes: '-',
    },
    {
      label: 'Exception Request',
      fundingAmount: exceptionRequest.fundingAmount,
      termLength: exceptionRequest.termLength,
      buyRate: exceptionRequest.buyRate,
      program: exceptionRequest.program,
      decisionReversal: formatBoolean(exceptionRequest.decisionReversal),
      notes: exceptionRequest.reviewCompletedAt ? (
        <NoteList exceptionRequest={exceptionRequest} />
      ) : (
        exceptionRequest.offerExceptionNotes
      ),
    },
  ];

  return (
    <>
      <Box>
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Offer</Table.ColumnHeader>
            <Table.ColumnHeader>Funding Amount</Table.ColumnHeader>
            <Table.ColumnHeader>Term Length</Table.ColumnHeader>
            <Table.ColumnHeader>Buy Rate</Table.ColumnHeader>
            <Table.ColumnHeader>Program</Table.ColumnHeader>
            <Table.ColumnHeader>Decline Decision Reversal</Table.ColumnHeader>
            <Table.ColumnHeader>Notes</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {rowData.map((row) => (
              <Table.Row key={row.label}>
                <Table.Cell>{row.label}</Table.Cell>
                <Table.Cell>
                  {typeof row.fundingAmount !== 'undefined' && (
                    <Currency amount={row.fundingAmount} />
                  )}
                </Table.Cell>
                <Table.Cell>{row.termLength}</Table.Cell>
                <Table.Cell>{row.buyRate}</Table.Cell>
                <Table.Cell>{row.program}</Table.Cell>
                <Table.Cell>{row.decisionReversal}</Table.Cell>
                <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                  {row.notes}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Box pt={3}>
          <Subheading>
            Request to Waive Stipulations / Additional Documents
          </Subheading>
          {exceptionRequest.stipExceptionNotes ? (
            <Text>{exceptionRequest.stipExceptionNotes}</Text>
          ) : (
            <Text>
              <em>No exceptions requested.</em>
            </Text>
          )}
        </Box>
      </Box>
    </>
  );
};
