import React, { useCallback, useEffect, useState } from 'react';

import { Banner, Box, Grid, Loading } from '@forward-financing/fast-forward';
import { SelectableOption } from '@forward-financing/fast-forward/dist/Combobox/Combobox';
import { ItemProps } from '@forward-financing/fast-forward/dist/Grid/Item';
import { useLocation, useNavigate } from 'react-router-dom';
import { toError } from 'helpers/errorUtils';
import { User } from 'api/AuthClient/codecs';
import { UnderwritingClient } from 'api/UnderwritingClient';
import { useUserContext } from 'contexts/UserContext';
import { IndustryType } from '../../api/UnderwritingClient/codecs';
import { isNullOrUndefined, isUnderwriter } from '../../helpers/utils';
import { AuthClient } from '../../api/AuthClient';
import { LivePipelineTable } from './LivePipelineTable';
import {
  propertyAlgoliaMappings,
  validStageMappings,
  validUWSubStages,
} from './livePipelineConstants';
import { Filters } from './codecs';
import { LivePipelineQuickViewBox } from './LivePipelineQuickViewBox';
import { LivePipelineRecentDealsBox } from './LivePipelineRecentDealsBox';
import {
  useAssignUnderwriterNextDeal,
  useSearchSubmissions,
} from './LivePipelineHooks';
import { useFetchIsos } from './LivePipelineFetchHooks';

interface DefaultFilters {
  stage_name: string;
  sub_stage: string;
}

interface OptionalFilters {
  stage_name: string;
  sub_stage: string;
  ff_underwriter?: string;
  credit_committee?: string;
  deal_type?: string;
  partner_uuid?: string;
}

const defaultFilters = (): DefaultFilters => {
  const allSubStages = (
    Object.keys(validStageMappings) as Array<keyof typeof validStageMappings>
  )
    .map((stage) => {
      return validStageMappings[stage];
    })
    .flat();

  return {
    stage_name: Object.keys(validStageMappings).join(),
    sub_stage: [...new Set(allSubStages)].join(),
  };
};

const defaultUnderwriterFilters = (): DefaultFilters => {
  return {
    stage_name: 'Underwriting,Closing',
    sub_stage: validUWSubStages.join(),
  };
};

const queryFilters = (
  filters: Filters,
  underwriterView: boolean
): OptionalFilters => {
  const filtersToUse = underwriterView
    ? defaultUnderwriterFilters()
    : defaultFilters();

  return (Object.keys(filters) as Array<keyof typeof filters>)
    .filter((f) => filters[f].length > 0)
    .reduce(
      (acc, f) =>
        Object.assign(acc, {
          [(propertyAlgoliaMappings as Record<string, string>)[f] || f]:
            filters[f].join(),
        }),
      filtersToUse
    );
};

const urlSearch = new URLSearchParams(window.location.search);

const startingFilters: Filters = {
  stage_name: urlSearch?.get('stage_name')?.split(',') || [],
  sub_stage: urlSearch?.get('sub_stage')?.split(',') || [],
  ff_underwriter: urlSearch?.get('ff_underwriter')?.split(',') || [],
  decision_analyst_id: urlSearch?.get('decision_analyst_id')?.split(',') || [],
  credit_committee: urlSearch?.get('credit_committee')?.split(',') || [],
  deal_type: urlSearch?.get('deal_type')?.split(',') || [],
  // Used for filtering ISOs, but can't be changed as it needs to match Algolia's naming convention
  partner_uuid: urlSearch?.get('partner_uuid')?.split(',') || [],
  industry_name: urlSearch?.get('industry_name')?.split(',') || [],
  revenue_bucket: urlSearch?.get('revenue_bucket')?.split(',') || [],
  position_bucket: urlSearch?.get('position_bucket')?.split(',') || [],
  iso_competing_sub_message:
    urlSearch?.get('iso_competing_sub_message')?.split(',') || [],
  prime_deal_filter: urlSearch?.get('prime_deal_filter')?.split(',') || [],
  sla_status_filter: urlSearch?.get('sla_status_filter')?.split(',') || [],
  category_filter: urlSearch?.get('category_filter')?.split(',') || [],
};

export const LivePipeline = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useUserContext();
  const currentUserUnderwriter = isUnderwriter(user);

  const [
    opportunitiesQuery,
    {
      data: opportunityData,
      loading: opportunitiesLoading,
      error: opportunitySearchError,
    },
  ] = useSearchSubmissions();

  const underwriterView =
    currentUserUnderwriter &&
    location.pathname === '/dashboard/live-underwriting-pipeline';

  const [assignDealRequested, setAssignDealRequested] = useState(false);

  // network fetch results
  const [underwriters, setUnderwriters] = useState<SelectableOption[]>([]);
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [processingUsers, setProcessingUsers] = useState<User[]>([]);

  // user selections
  const [filters, setFilters] = useState(startingFilters);
  const [expandedTable, setExpandedTable] = useState(false);
  const [sortAttribute, setSortAttribute] = useState(
    urlSearch.get('sort_attr') || 'total_sub_stage_time'
  );
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(
    (order = urlSearch.get('sort_order')) => (order === 'asc' ? 'asc' : 'desc')
  );
  const [page, setPage] = useState(1);

  const toggleExpand = (): void => {
    setExpandedTable(!expandedTable);
  };

  // error messages
  const [underwritersError, setUnderwritersError] = useState('');
  const [processingUsersError, setProcessingUsersError] = useState('');
  const [industryFetchError, setIndustryFetchError] = useState('');

  // Note: the isos abbreviation is for ISOs and should be treated as a word when camelCasing
  const { data: isos, error: isosError } = useFetchIsos();

  // constants derived from state
  const tableSize: Pick<ItemProps, 'xs' | 's' | 'm' | 'l' | 'xl'> =
    expandedTable ? { xs: 12 } : { xs: 12, l: 10 };
  const sidebarSize:
    | Pick<ItemProps, 'xs' | 's' | 'm' | 'l' | 'xl'>
    | undefined = expandedTable ? undefined : { xs: 12, l: 2 };

  useEffect(() => {
    const fetchUnderwriters = async (): Promise<void> => {
      try {
        const underwritersResponse = await AuthClient.fetchUsersByRole(
          'underwriter'
        );
        const underwriterNames = underwritersResponse.map((underwriter) => {
          return {
            value: `${underwriter.first_name} ${underwriter.last_name}`,
            text: `${underwriter.first_name} ${underwriter.last_name}`,
          };
        });
        setUnderwriters([
          { value: 'Unassigned', text: 'Unassigned' },
          ...underwriterNames.sort((a, b) => a.text.localeCompare(b.text)),
        ]);
      } catch (e: unknown) {
        const error = toError(e);
        setUnderwritersError(error.message);
        setUnderwriters([{ value: 'Unassigned', text: 'Unassigned' }]);
      }
    };

    void fetchUnderwriters();
  }, []);

  useEffect(() => {
    const fetchUsers = async (): Promise<void> => {
      try {
        const users = await AuthClient.fetchUsersByRole('processing');
        setProcessingUsers(users);
      } catch (e: unknown) {
        const error = toError(e);
        setProcessingUsersError(error.message);
      }
    };
    void fetchUsers();
  }, []);

  useEffect(() => {
    const fetchIndustries = async (): Promise<void> => {
      try {
        const industriesResponse =
          await UnderwritingClient.fetchIndustryTypes();
        setIndustries(industriesResponse);
      } catch (e: unknown) {
        const error = toError(e);
        setIndustryFetchError(error.message);
      }
    };
    void fetchIndustries();
  }, []);

  const runOpportunityQuery = useCallback(() => {
    void opportunitiesQuery({
      sort: (propertyAlgoliaMappings as Record<string, string>)[sortAttribute],
      order: sortOrder,
      page: page,
      hits_per_page: 100,
      ...queryFilters(filters, underwriterView),
    });
  }, [
    filters,
    opportunitiesQuery,
    page,
    sortAttribute,
    sortOrder,
    underwriterView,
  ]);

  const updateUrlParams = useCallback(() => {
    urlSearch.set('sort_attr', sortAttribute);
    urlSearch.set('sort_order', sortOrder);

    (Object.keys(filters) as Array<keyof typeof filters>).forEach(
      (filterKey) => {
        urlSearch.delete(filterKey);
        const filter = filters[filterKey];
        if (filter.length > 0) {
          urlSearch.set(filterKey, filter.toString());
        }
      }
    );

    navigate({ search: `?${urlSearch}` });
  }, [sortAttribute, sortOrder, filters, navigate]);

  useEffect(() => {
    if (!isNullOrUndefined(underwriterView)) {
      runOpportunityQuery();
      updateUrlParams();
    }
  }, [runOpportunityQuery, updateUrlParams, underwriterView]);

  const updateFilters = useCallback(
    (filterObject: Filters) => {
      setFilters(filterObject);
      setPage(1);
    },
    [setFilters, setPage]
  );

  const handleOrderChange = useCallback(
    (property: string) => {
      const isAsc = sortOrder === 'asc' && property === sortAttribute;
      setSortOrder(isAsc ? 'desc' : 'asc');
      setSortAttribute(property);
    },
    [sortOrder, sortAttribute]
  );

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const [
    assignNextDeal,
    {
      data: nextDealData,
      loading: underwriterAssignLoading,
      error: underwriterAssignError,
      responseReady: underwriterAssignResponseReady,
    },
  ] = useAssignUnderwriterNextDeal();

  const assignDeal = async (): Promise<void> => {
    await assignNextDeal();
    setAssignDealRequested(true);
  };

  const isNextDealUnavailable =
    underwriterAssignResponseReady &&
    nextDealData &&
    nextDealData.dataUrls.length === 0;

  const isNextDealAvailable =
    nextDealData?.dataUrls &&
    nextDealData.dataUrls.length > 0 &&
    underwriterAssignResponseReady;

  useEffect(() => {
    if (assignDealRequested && nextDealData && isNextDealAvailable) {
      nextDealData.dataUrls.forEach((url) => {
        window.open(url, '_blank');
      });
      setAssignDealRequested(false);
    } else if (isNextDealUnavailable) {
      navigate('/underwriter-queue-loading');
    }
  }, [
    isNextDealAvailable,
    assignDealRequested,
    nextDealData,
    isNextDealUnavailable,
    navigate,
  ]);

  return (
    <Box>
      {isosError && <Banner dismissable={false}>{isosError?.message}</Banner>}
      {opportunitySearchError && (
        <Banner dismissable={false}>{opportunitySearchError.message}</Banner>
      )}
      {underwritersError && (
        <Banner dismissable={false}>{underwritersError}</Banner>
      )}
      {underwriterAssignError && (
        <Banner dismissable={false}>{underwriterAssignError.message}</Banner>
      )}
      {processingUsersError && (
        <Banner dismissable={false}>{processingUsersError}</Banner>
      )}
      {industryFetchError && (
        <Banner dismissable={false}>{industryFetchError}</Banner>
      )}
      <Grid>
        {!expandedTable && (
          <Grid.Item {...sidebarSize}>
            <LivePipelineQuickViewBox
              underwriterView={underwriterView}
              currentUserUnderwriter={currentUserUnderwriter}
            />
            <LivePipelineRecentDealsBox />
          </Grid.Item>
        )}
        {!opportunitySearchError && !isosError && (
          <Grid.Item {...tableSize}>
            {opportunityData && isos ? (
              <LivePipelineTable
                data={opportunityData.submissionSearchResults}
                isos={isos}
                meta={opportunityData.meta}
                filters={filters}
                underwriterView={underwriterView}
                currentUserUnderwriter={currentUserUnderwriter}
                fetchOpportunities={runOpportunityQuery}
                order={sortOrder}
                sortingProperty={sortAttribute}
                expandedTable={expandedTable}
                assignDeal={assignDeal}
                handleOrderChange={handleOrderChange}
                handlePageChange={handlePageChange}
                loading={opportunitiesLoading}
                toggleExpand={toggleExpand}
                underwriterAssignLoading={underwriterAssignLoading}
                underwriters={underwriters}
                processingUsers={processingUsers}
                industries={industries}
                updateFilters={updateFilters}
                urlParams={urlSearch}
              />
            ) : (
              <Box margin={2}>
                <Loading size="large" />
              </Box>
            )}
          </Grid.Item>
        )}
      </Grid>
    </Box>
  );
};
