import { RenewalScoreComposite } from '../DealScoring.types';

export const compositeScoreToText = (
  compositeScoreValue?: RenewalScoreComposite['compositeScore']
): string => {
  // !compositeScoreValue.score intentionally includes a value of 0 since
  // 0 is not a valid score.
  if (!compositeScoreValue || !compositeScoreValue.score) {
    return 'N/A';
  }

  return `${compositeScoreValue.score?.toFixed(1)} / Tier ${
    compositeScoreValue.tier || 'N/A'
  }`;
};
