import React from 'react';
import { LivePipeline } from './LivePipeline';
import { Banner, Text } from '@forward-financing/fast-forward';
import { isUnderwriter } from 'helpers/utils';
import { useUserContext } from 'contexts/UserContext';
import { InternalLink } from 'components/shared/InternalLink';

export const LivePipelineContainer = (): JSX.Element => {
  const user = useUserContext();
  const currentUserUnderwriter = isUnderwriter(user);

  return (
    <>
      {currentUserUnderwriter && (
        <Banner dismissable={false} variant="info">
          <Text>
            You are currently viewing the non-UW Live Pipeline. Please note that
            the UW Live Pipeline page has moved to{' '}
            <InternalLink
              reloadDocument
              to="/dashboard/live-underwriting-pipeline"
            >
              {window.location.origin}/dashboard/live-underwriting-pipeline
            </InternalLink>
            . Please update your bookmarks accordingly.
          </Text>
        </Banner>
      )}

      <LivePipeline />
    </>
  );
};
