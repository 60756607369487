import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Subheading,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';
import { usePreventNavigation } from 'hooks/usePreventNavigation.hook';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { NOTES_MAX_LENGTH } from './DealScoringConstants';
import { UpdateSubmissionNoteBody } from './DealScoringFetchHooks';
import { SubmissionNotes, DealScoringNoteType } from './DealScoring.types';

export interface DealScoringNotesProps {
  note: SubmissionNotes | undefined;
  emptyNoteType: DealScoringNoteType;
  loading: boolean;
  updateNote: (input: UpdateSubmissionNoteBody) => Promise<MutationResponse>;
  errors: Error[];
}

export const DealScoringNotes = ({
  note,
  emptyNoteType,
  loading,
  updateNote,
  errors,
}: DealScoringNotesProps): JSX.Element => {
  const [noteContent, setNoteContent] = useState<string>('');
  const [initialNoteContent, setInitialNoteContent] = useState<
    string | undefined
  >(undefined);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isAutosaving, setIsAutosaving] = useState(false);

  usePreventNavigation(hasUnsavedChanges);

  useEffect(() => {
    if (!loading && !hasUnsavedChanges) {
      setNoteContent(note?.content || '');
      if (!initialNoteContent) {
        setInitialNoteContent(note?.content);
      }
    }
  }, [note, loading, initialNoteContent, hasUnsavedChanges]);

  const handleTextAreaChange: TextAreaProps['onChange'] = (event) => {
    setNoteContent(event.target.value);
    setHasUnsavedChanges(true);
  };

  const saveNote = async (): Promise<void> => {
    setIsAutosaving(true);
    setInitialNoteContent(noteContent);
    const { success } = await updateNote({ noteContent });
    if (success) {
      setHasUnsavedChanges(false);
    }
    setIsAutosaving(false);
  };

  const noteType = note?.noteType || emptyNoteType;

  const getStatusMessage = (): string => {
    if (isAutosaving) {
      return ' (autosaving)';
    } else {
      return hasUnsavedChanges ? ' (unsaved changes)' : '';
    }
  };

  const heading = `${
    noteType === 'UNDERWRITER' ? 'Underwriter' : 'Manager'
  } Notes${getStatusMessage()}`;

  return (
    <Box>
      {errors.map((error) => (
        <Banner key={error.message} dismissable={false}>
          {error.message}
        </Banner>
      ))}

      <Subheading>{heading}</Subheading>
      <TextArea
        label="Record notes below"
        rows={7}
        onChange={handleTextAreaChange}
        maxLength={NOTES_MAX_LENGTH}
        value={noteContent}
        disabled={loading}
        onBlur={() => saveNote()}
      />
    </Box>
  );
};
