import React, { useState } from 'react';
import {
  Box,
  Modal,
  Divider,
  Subheading,
  Button,
} from '@forward-financing/fast-forward';
import { useAttachmentManagerContext } from '../AttachmentManager/AttachmentManagerContext';
import { CreateStipulationForm } from './CreateStipulationForm';

export const CreateStipulationModal = (): JSX.Element => {
  const { reloadAttachmentManager, validReasons, primaryId } =
    useAttachmentManagerContext();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  return (
    <Modal
      title="Add New Stipulation(s)"
      isOpen={isCreateModalOpen}
      onOpenChange={() => setIsCreateModalOpen(!isCreateModalOpen)}
      trigger={
        <Button startIcon="plus" disabled={false}>
          Add New Stipulation(s)
        </Button>
      }
      body={
        <Box>
          <Subheading>Provide stipulation information</Subheading>
          <Divider />
          <CreateStipulationForm
            onSubmit={() => {
              setIsCreateModalOpen(!isCreateModalOpen);
              reloadAttachmentManager();
            }}
            onCancel={() => {
              setIsCreateModalOpen(!isCreateModalOpen);
              reloadAttachmentManager();
            }}
            validReasons={validReasons}
            submissionUuid={primaryId}
          />
        </Box>
      }
    />
  );
};
