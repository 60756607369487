import { createBulkAdjustments } from 'api/funding/bulkAdjustmentsFetchUtils';
import {
  useGenericMutation,
  UseGenericMutationResponse,
} from 'apiHooks/genericFetchHooks';
import { BulkAdjustmentsCreateAttributes } from 'types/api/funding/types';

export interface UseCreateBulkAdjustmentArgs {
  advanceRecordIds: number[];
  createBody: BulkAdjustmentsCreateAttributes[];
}

export type UseCreateBulkAdjustmentResponse = UseGenericMutationResponse<
  { success: boolean },
  UseCreateBulkAdjustmentArgs
>;

const createBulkAdjustmentWithArgs = async (
  args: UseCreateBulkAdjustmentArgs
): Promise<{ success: boolean }> =>
  createBulkAdjustments(args.advanceRecordIds, args.createBody);

export const useApiCreateBulkAdjustment =
  (): UseCreateBulkAdjustmentResponse => {
    return useGenericMutation(createBulkAdjustmentWithArgs);
  };
