import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading } from '@forward-financing/fast-forward';
import { StipulationManager } from 'components/StipulationManager/StipulationManager';

type Params = {
  submissionUuid: string;
};
export const BankingStipulationManagerRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  return (
    <Box p={4} m={4} borderColor="blue-900" rounded>
      <Heading>Banking Stipulation Manager</Heading>

      {submissionUuid && <StipulationManager submissionUuid={submissionUuid} />}
    </Box>
  );
};
