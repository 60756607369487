import React from 'react';
import { Accordion, Text } from '@forward-financing/fast-forward';
import { useStipulationRecommendations } from './stipulationRecommendationsHooks';
import { OwnershipConfidenceScoreText } from './OwnershipConfidenceScoreText';

export type StipulationRecommendationsContainerProps = {
  submissionUuid: string;
};

export const StipulationRecommendationsContainer = ({
  submissionUuid,
}: StipulationRecommendationsContainerProps): JSX.Element => {
  const { data: stipulationRecommendations } =
    useStipulationRecommendations(submissionUuid);

  const proofOfOwnershipStipulation = stipulationRecommendations?.find(
    (stipulation) => stipulation.stipulationType === 'Proof Of Ownership'
  );

  const isRecommended =
    typeof proofOfOwnershipStipulation?.overallScore === 'number' &&
    proofOfOwnershipStipulation.overallScore <= 0;

  // If the proofOfOwnershipStipulation object is missing any of the required fields, return an empty fragment.
  // Wrapping this boolean check in a const caused TS to still complain about the object possibly being undefined,
  // so I had to opt for this more verbose check instead.
  if (
    !proofOfOwnershipStipulation?.bucket ||
    !proofOfOwnershipStipulation?.results ||
    typeof proofOfOwnershipStipulation?.overallScore !== 'number' // typeof check because overallScore can be 0
  ) {
    return <></>;
  }

  return (
    <Accordion type="single" collapsible>
      <Accordion.Item value="test">
        <Accordion.Trigger>
          <Text>
            Based on the Ownership Confidence Score, Proof of Ownership is{' '}
            <Text bold>
              {isRecommended ? 'recommended' : 'not recommended'}
            </Text>
            .
          </Text>
        </Accordion.Trigger>

        <Accordion.Content>
          <OwnershipConfidenceScoreText
            bucket={proofOfOwnershipStipulation.bucket}
            score={proofOfOwnershipStipulation.overallScore}
            results={proofOfOwnershipStipulation.results}
          />
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
};
