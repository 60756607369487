import { useCallback } from 'react';
import {
  fetchOwner,
  fetchOwnerSimilarContacts,
  fetchSimilarOwners,
  fetchPublicDocuments,
  fetchLexisNexisForOwners,
  fetchSubmissionOwners,
  fetchOwnerManualSearchResult,
  fetchOwnerUccReport,
  patchOwner,
  createOwner,
  deleteOwner,
} from 'api/underwriting/ownerFetchUtils';
import {
  MutationResponse,
  useGenericMutation,
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
  useLazyGenericQuery,
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
} from 'apiHooks/genericFetchHooks';
import { useDeepCompareCallback } from 'hooks/useDeepCompareCallback';
import {
  OwnerResponse,
  OwnerSimilarContactsResponse,
  SimilarOwnersResponse,
  LexisNexisOwnerReportResponse,
  LexisNexisResponse,
  UpdateOwnerRequestBody,
  OwnerManualSearchResultsResponse,
  OwnerUccReportResponse,
} from 'types/api/underwriting/types';

export type UseGetApiOwnerResponse = UseGenericQueryResponse<OwnerResponse>;
export type UseGetBatchApiOwnersResponse = UseGenericQueryResponse<
  OwnerResponse[]
>;

export const useGetApiOwner = (ownerUuid?: string): UseGetApiOwnerResponse => {
  const fetcher = useCallback(() => {
    if (!ownerUuid) {
      return undefined;
    }
    return fetchOwner(ownerUuid);
  }, [ownerUuid]);

  return useGenericQuery(fetcher);
};

export const useGetBatchApiOwners = (
  ownerUuids?: string[]
): UseGetBatchApiOwnersResponse => {
  const fetcher = useDeepCompareCallback(() => {
    if (!ownerUuids) {
      return undefined;
    }
    return Promise.all(ownerUuids.map((id) => fetchOwner(id)));
  }, [ownerUuids]);

  return useGenericQuery(fetcher);
};

export interface UseLazyGetOwnerArgs {
  ownerUuid: string;
}

export type UseLazyGetOwnerRefresher =
  UseLazyGenericQueryFetcher<UseLazyGetOwnerArgs>;

export type UseLazyGetOwnerResponse = UseLazyGenericQueryResult<OwnerResponse>;

const fetchOwnerWithArgs = (
  args: UseLazyGetOwnerArgs
): Promise<OwnerResponse> => {
  return fetchOwner(args.ownerUuid);
};

export const useLazyGetApiOwner = (): [
  UseLazyGetOwnerRefresher,
  UseLazyGetOwnerResponse
] => {
  return useLazyGenericQuery(fetchOwnerWithArgs);
};

export type UseGetOwnerSimilarContactsResponse = UseGenericQueryResponse<
  OwnerSimilarContactsResponse[]
>;

export const useGetApiOwnerSimilarContacts = (
  ownerUuid?: string,
  submissionUuid?: string
): UseGetOwnerSimilarContactsResponse => {
  const getOwnerSimilarContacts = useCallback(() => {
    if (!ownerUuid || !submissionUuid) {
      return undefined;
    }

    return fetchOwnerSimilarContacts(ownerUuid, submissionUuid);
  }, [ownerUuid, submissionUuid]);

  return useGenericQuery(getOwnerSimilarContacts);
};

export interface UseLazyGetApiSimilarOwnersArgs {
  ownerUuid: string;
  force?: boolean;
}

export type UseLazyGetApiSimilarOwnersFetcher =
  UseLazyGenericQueryFetcher<UseLazyGetApiSimilarOwnersArgs>;

export type UseLazyGetApiSimilarOwnersResponse =
  UseLazyGenericQueryResult<SimilarOwnersResponse>;

const fetchSimilarOwnersWithArgs = (
  args: UseLazyGetApiSimilarOwnersArgs
): Promise<SimilarOwnersResponse> => {
  return fetchSimilarOwners(args.ownerUuid, args.force || false);
};

export const useLazyGetApiSimilarOwners = (): [
  UseLazyGetApiSimilarOwnersFetcher,
  UseLazyGetApiSimilarOwnersResponse
] => {
  return useLazyGenericQuery(fetchSimilarOwnersWithArgs);
};

export type UseGetPublicDocumentsResponse =
  UseGenericQueryResponse<LexisNexisOwnerReportResponse>;

export const useGetApiPublicDocuments = (
  submissionUuid?: string
): UseGetPublicDocumentsResponse => {
  const getPublicDocuments = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchPublicDocuments(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getPublicDocuments);
};

export type UseGetApiLexisNexisForOwnersResponse = UseGenericQueryResponse<
  LexisNexisResponse[]
>;

export const useGetApiLexisNexisForOwners = (
  submissionUuid?: string
): UseGetApiLexisNexisForOwnersResponse => {
  const getLexisNexisForOwners = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchLexisNexisForOwners(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getLexisNexisForOwners);
};

/**
 * This hook is primarily used in submission editing with the purpose of refactoring the entities we update.
 * At this moment the BE is not ready to support updates to owners model. So in the meantime we are going to
 * send applicationUuid as submissionUuid and we will fetch contacts instead of owners.
 *
 * These two entities have the same structure. For this we will just use the contacts endpoint to fetch the data.
 */

export const useGetApiSubmissionOwners = (
  submissionUuid?: string
): UseGenericQueryResponse<OwnerResponse[]> => {
  const getOwners = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSubmissionOwners(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getOwners);
};

export type UseUpdateOwnerArgs = {
  ownerUuid: string;
  updateBody: UpdateOwnerRequestBody;
};

export type UseUpdateOwnerResponse = UseGenericMutationResponse<
  OwnerResponse[],
  UseUpdateOwnerArgs[]
>;

const updateOwners = (args: UseUpdateOwnerArgs[]): Promise<OwnerResponse[]> => {
  return Promise.all(
    args.map((arg) => patchOwner(arg.ownerUuid, arg.updateBody))
  );
};

export const useApiUpdateOwners = (): UseUpdateOwnerResponse => {
  return useGenericMutation(updateOwners);
};

type UseApiCreateOwnersArgs = {
  submissionUuid: string;
  body: UpdateOwnerRequestBody[];
};

export const useApiCreateOwners = (): UseGenericMutationResponse<
  OwnerResponse[],
  UseApiCreateOwnersArgs
> => {
  const createOwners = (
    args: UseApiCreateOwnersArgs
  ): Promise<OwnerResponse[]> =>
    Promise.all(
      args.body.map((body) =>
        createOwner({ submissionUuid: args.submissionUuid, body })
      )
    );

  return useGenericMutation(createOwners);
};

export type UseApiDeleteOwnerResponse = UseGenericMutationResponse<
  Pick<MutationResponse, 'success'>,
  { submissionUuid: string; ownerUuid: string }
>;

export const useApiDeleteOwner = (): UseApiDeleteOwnerResponse =>
  useGenericMutation(deleteOwner);

export type UseGetApiLexisNexisOwnersManualSearchArgs = {
  submissionUuid: string;
  ownerUuid: string;
  queryParams: string;
};

export type UseGetApiLexisNexisOwnersManualSearchResponse =
  UseGenericMutationResponse<
    OwnerManualSearchResultsResponse,
    UseGetApiLexisNexisOwnersManualSearchArgs
  >;

const getOwnerLexisNexisSearchResults = (
  input: UseGetApiLexisNexisOwnersManualSearchArgs
): Promise<OwnerManualSearchResultsResponse> => {
  return fetchOwnerManualSearchResult(
    input.submissionUuid,
    input.ownerUuid,
    input.queryParams
  );
};

export const useGetApiLexisNexisOwnerManualSearch =
  (): UseGetApiLexisNexisOwnersManualSearchResponse => {
    return useGenericMutation(getOwnerLexisNexisSearchResults);
  };

type UseUccReportArgs = {
  submissionUuid?: string;
  ownerUuid?: string;
  reportId?: string;
};

export type UseGetApiOwnerUccReport =
  UseGenericQueryResponse<OwnerUccReportResponse>;

export const useGetApiOwnerUccReport = (
  input: UseUccReportArgs
): UseGetApiOwnerUccReport => {
  const fetcher = useCallback(() => {
    if (!input.ownerUuid || !input.submissionUuid || !input.reportId) {
      return undefined;
    }
    return fetchOwnerUccReport(
      input.submissionUuid,
      input.ownerUuid,
      input.reportId
    );
  }, [input.ownerUuid, input.submissionUuid, input.reportId]);

  return useGenericQuery(fetcher);
};
