import React, { useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  Icon,
  IconButton,
  RadioGrid,
  RadioGridProps,
  Subheading,
  Text,
  TextArea,
} from '@forward-financing/fast-forward';
import { Question } from '@forward-financing/fast-forward/dist/RadioGrid/RadioGrid';
import { TextAreaProps } from 'components/shared/form/TextArea';
import { useNewDealScoringContext } from '../NewDealScoringContext';
import { ProcessScorePopoverContent } from './ProcessScorePopoverContent';
import { DealScorePopoverContent } from './DealScorePopoverContent';
import { ChecklistPopoverContent } from './ChecklistPopoverContent';

export const ManagerScoring = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    score,
    handleScoreUpdate,
    handleManagerTextAreaChange,
    hasUnsavedManagerChanges,
    onSubmit,
  } = useNewDealScoringContext();

  const handleRadioGridChange: RadioGridProps['onValueChange'] = (
    name,
    newValue
  ) => {
    handleScoreUpdate({
      ...score,
      [name]: Number(newValue),
    });
  };

  const handleChecklistRadioGridChange: RadioGridProps['onValueChange'] = (
    name,
    newValue
  ) => {
    const newChecklistValues = score.checklistResponses.map((response) => {
      const newChecklistValue =
        response.underwriterScoreReviewQuestionId === name
          ? {
              ...response,
              response: newValue === 'true',
            }
          : response;

      return newChecklistValue;
    });

    handleScoreUpdate({
      ...score,
      checklistResponses: newChecklistValues,
    });
  };

  const handleTextAreaChange: TextAreaProps['onChange'] = (e) => {
    handleManagerTextAreaChange({
      ...score,
      managerNote: e.target.value,
    });
  };

  const finalScoringQuestions: RadioGridProps['questions'] = [
    {
      name: 'processRating',
      value: score.processRating.toString(),
      label: 'Process Score',
      description: {
        header: 'Process score details',
        content: <ProcessScorePopoverContent />,
      },
    },
    {
      name: 'pricingRating',
      value: score.pricingRating.toString(),
      label: 'Deal Score',
      description: {
        header: 'Deal score details',
        content: <DealScorePopoverContent />,
      },
    },
  ];

  const generateChecklistQuestions = (
    questionType: 'deal' | 'process'
  ): Question[] => {
    return score.checklistResponses
      .filter((response) => response.questionType === questionType)
      .map((response) => {
        return {
          name: response.underwriterScoreReviewQuestionId,
          value:
            response.response === null ? 'true' : response.response.toString(),
          label: response.question,
          description: {
            header: response.question,
            content: (
              <ChecklistPopoverContent descriptors={response.descriptors} />
            ),
          },
        };
      });
  };

  const processScoreChecklistQuestions: RadioGridProps['questions'] =
    generateChecklistQuestions('process');
  const dealScoreChecklistQuestions: RadioGridProps['questions'] =
    generateChecklistQuestions('deal');

  const hasManagerFeedback = !!(
    score.processRating ||
    score.pricingRating ||
    score.managerNote
  );

  return (
    <Box>
      {isOpen ? (
        <Box>
          <IconButton
            icon="xmark"
            label="Close Manager Feedback"
            onClick={() => setIsOpen(false)}
          />
          <Grid gutter>
            <Grid.Item xs={12} s={6} l={4}>
              <Flex gap={2} alignItems="center">
                {hasUnsavedManagerChanges && (
                  <Icon
                    data-testid="manager-unsaved-changes-icon"
                    color="danger"
                    name="circle-exclamation"
                  />
                )}
                <Subheading>Manager score</Subheading>
              </Flex>

              <Subheading variant="subsection">
                Process Score Completed Correctly? (Y/N)
              </Subheading>
              {processScoreChecklistQuestions.length === 0 ? (
                <Text>No Questions To Display</Text>
              ) : (
                <RadioGrid
                  onValueChange={handleChecklistRadioGridChange}
                  options={[
                    { value: 'true', text: 'Yes' },
                    { value: 'false', text: 'No' },
                  ]}
                  questions={processScoreChecklistQuestions}
                />
              )}
              <Subheading variant="subsection">
                Deal Score Completed Correctly? (Y/N)
              </Subheading>
              {dealScoreChecklistQuestions.length === 0 ? (
                <Text>No Questions To Display</Text>
              ) : (
                <RadioGrid
                  onValueChange={handleChecklistRadioGridChange}
                  options={[
                    { value: 'true', text: 'Yes' },
                    { value: 'false', text: 'No' },
                  ]}
                  questions={dealScoreChecklistQuestions}
                />
              )}
            </Grid.Item>
            <Grid.Item xs={12} s={6} l={8}>
              <Flex gap={2} alignItems="center">
                <Subheading>Final Scoring</Subheading>
              </Flex>
              <Subheading variant="subsection">Select Final Scores</Subheading>
              <RadioGrid
                onValueChange={handleRadioGridChange}
                options={[
                  { value: '1', text: 'No Changes' },
                  { value: '2', text: 'Minor Changes' },
                  { value: '3', text: 'Major Changes' },
                ]}
                questions={finalScoringQuestions}
              />
              <Subheading>Notes</Subheading>
              <TextArea
                rows={7}
                label="Record notes below"
                value={score.managerNote}
                onChange={handleTextAreaChange}
                onBlur={onSubmit}
              />
            </Grid.Item>
          </Grid>
        </Box>
      ) : (
        <IconButton
          icon={[hasManagerFeedback ? 'fas' : 'far', 'comment']}
          label={
            hasManagerFeedback
              ? 'View Manager Feedback'
              : 'Add Manager Feedback'
          }
          onClick={() => setIsOpen(true)}
        />
      )}
    </Box>
  );
};
