export class UnknownCaughtValueError extends Error {
  constructor(caughtValue: unknown) {
    const messageLines = [
      'Non-error thrown.',
      'Please contact the engineering team immediately and include the following:',
      `${String(caughtValue)} thrown as error`,
    ];

    super(messageLines.join('\n\t'));

    // improve the stack trace
    // optional chaining because this is a V8-only feature
    // https://v8.dev/docs/stack-trace-api#stack-trace-collection-for-custom-exceptions
    // Disabling ESLint because our version of react-scripts is too old and doesn't
    // recognize optional chaining for function calls. We'll fix that soon
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Error.captureStackTrace?.(this, this.constructor);
  }
}

export const toError = (caughtValue: unknown): Error => {
  if (caughtValue instanceof Error) {
    return caughtValue;
  }

  return new UnknownCaughtValueError(caughtValue);
};
