import {
  FraudScoringDocument,
  OwnerResponse,
} from 'types/api/underwriting/types';
import { useGetApiFraudScoring } from 'apiHooks/underwriting/submissionFetchHooks';
import {
  useGetApiSubmissionOwners,
  useGetBatchApiOwners,
} from 'apiHooks/underwriting/ownerFetchHooks';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { featureFlags } from 'helpers/featureFlags';
import { useGenericFeatureQuery } from '../../featureHooks/genericFeatureHooks';
import { Owner, OwnerFraudScores } from './fraudScoringContainer.types';

const toFraudScoring = (fraudScore: FraudScoringDocument): OwnerFraudScores => {
  return {
    ownerUuid: fraudScore.owner_uuid,
    abuseScore:
      (fraudScore.abuse_score && {
        score: fraudScore.abuse_score.score,
        underwriterBucket: fraudScore.abuse_score.uw_bucket,
      }) ||
      undefined,
    firstPartySyntheticScore:
      (fraudScore.first_party_synthetic_score && {
        score: fraudScore.first_party_synthetic_score.score,
        underwriterBucket: fraudScore.first_party_synthetic_score.uw_bucket,
      }) ||
      undefined,
    thirdPartySyntheticScore:
      (fraudScore.third_party_synthetic_score && {
        score: fraudScore.third_party_synthetic_score.score,
        underwriterBucket: fraudScore.third_party_synthetic_score.uw_bucket,
      }) ||
      undefined,
  };
};

interface UseFraudScoringResult {
  fraudScores: OwnerFraudScores[] | undefined;
  error: Error | undefined;
  loading: boolean;
}

export const useGetFraudScoring = (
  submissionUuid?: string
): UseFraudScoringResult => {
  const { data, error, ...rest } = useGetApiFraudScoring(submissionUuid);

  const fraudScores = data && data.documents.map(toFraudScoring);

  return {
    fraudScores,
    error,
    ...rest,
  };
};

// add hooks for owner and submission

const toOwner = (owners?: OwnerResponse[]): Owner[] => {
  if (!owners) {
    return [];
  }
  return owners.map((owner) => {
    return {
      fullName: `${owner.first_name} ${owner.last_name}`,
      // Once the Ft is ON we can remove this like and make sure
      // FraudScoringTable uses owner.uuid instead of owner.ownerUuid for mapping thrue the owners array
      ownerUuid: featureFlags.fetch_owners_by_submissionUuid
        ? owner.uuid
        : owner.owner_uuid,
      uuid: owner.uuid,
    };
  });
};

interface OwnerResult {
  data?: Owner[];
  error?: Error;
  loading: boolean;
}

export const useOwnersByOwnerUuids = (
  ownerUuids?: string[]
): UseOwnerResult => {
  const { data, error, ...rest } = useGetBatchApiOwners(ownerUuids);

  return {
    data: toOwner(data),
    error,
    ...rest,
  };
};

type UseOwnerResult = UseGenericQueryResponse<Owner[]>;

const useOwnersBySubmissionUuid = (submissionUuid?: string): UseOwnerResult => {
  return useGenericFeatureQuery(
    useGetApiSubmissionOwners,
    toOwner,
    submissionUuid
  );
};

export const useOwners = (
  submissionUuid?: string,
  ownerUuids?: string[]
): OwnerResult => {
  const owners = useOwnersBySubmissionUuid(submissionUuid);
  const ownersByOwnerId = useOwnersByOwnerUuids(ownerUuids);
  return featureFlags.fetch_owners_by_submissionUuid ? owners : ownersByOwnerId;
};
