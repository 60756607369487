import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Currency,
  CurrencyInput,
  Flex,
  Icon,
  Loading,
  MaskedTextInput,
  Select,
  SelectProps,
  Table,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';
import { NumberFormatValues } from 'react-number-format';
import { PROGRAMS } from '../programConstants';
import {
  ExceptionRequest,
  ExceptionRequestProgram,
  Offer,
} from '../exceptionsRequest.types';
import { ExceptionRequestFormShape } from './ExceptionsRequestContainer';

const selectProgramOptions: SelectProps['options'] = PROGRAMS.map(
  (program) => ({ value: program, text: program })
);

const isFormValid = (formState: FormStateShape): boolean => {
  return (
    formState.program !== '' &&
    !Number.isNaN(parseFloat(formState.fundingAmount)) &&
    !Number.isNaN(parseFloat(formState.buyRate)) &&
    !Number.isNaN(parseInt(formState.termLength))
  );
};

export interface ExceptionsRequestFormProps {
  onCancel: () => void;
  onSubmit: (submitPayload: ExceptionRequestFormShape) => void;
  exceptionRequest?: ExceptionRequest;
  currentOffer?: Offer;
  isSubmitDisabled: boolean;
}

export interface FormStateShape {
  fundingAmount: string;
  termLength: string;
  program: ExceptionRequestProgram | '';
  buyRate: string;
  stipExceptionNotes: string;
  offerExceptionNotes: string;
}

export const ExceptionsRequestForm = (
  props: ExceptionsRequestFormProps
): JSX.Element => {
  const { currentOffer } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formState, setFormState] = useState<FormStateShape>({
    fundingAmount: props.exceptionRequest?.fundingAmount?.toString() ?? '',
    termLength: props.exceptionRequest?.termLength?.toString() ?? '',
    buyRate: props.exceptionRequest?.buyRate?.toString() ?? '',
    program: props.exceptionRequest?.program ?? '',
    stipExceptionNotes: props.exceptionRequest?.stipExceptionNotes ?? '',
    offerExceptionNotes: props.exceptionRequest?.offerExceptionNotes ?? '',
  });

  const handleChange: TextAreaProps['onChange'] = (event) => {
    setErrorMessage(null);
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange: SelectProps['onValueChange'] = (value) => {
    setErrorMessage(null);
    setFormState({
      ...formState,
      program: value as ExceptionRequestProgram | '',
    });
  };

  const handleMaskedTextInputChange = (
    valueObject: NumberFormatValues,
    targetName: string
  ): void => {
    setErrorMessage(null);
    setFormState({
      ...formState,
      [targetName]: valueObject.value ?? '',
    });
  };

  const onSubmitClick = (): void => {
    if (isFormValid(formState)) {
      setErrorMessage(null);
      const normalizedInput: ExceptionRequestFormShape = {
        ...currentOffer,
        ...props.exceptionRequest,
        ...formState,
        // isFormValid makes sure this is not ''
        program: formState.program as ExceptionRequestProgram,
        fundingAmount: parseFloat(formState.fundingAmount),
        buyRate: parseFloat(formState.buyRate),
        termLength: parseInt(formState.termLength, 10),
      };
      props.onSubmit(normalizedInput);
    } else {
      setErrorMessage(
        'Form is not valid. Please check your inputs and try again.'
      );
    }
  };

  const rows = [
    {
      label: 'Funding Amount($)',
      offer: currentOffer?.fundingAmount ? (
        <Currency amount={currentOffer.fundingAmount} />
      ) : null,
      exception: (
        <CurrencyInput
          label="Funding Amount"
          hiddenLabel
          thousandSeparator=","
          decimalScale={2}
          prefix="$"
          name="fundingAmount"
          placeholder="#####.##"
          value={formState.fundingAmount}
          onValueChange={(e) => handleMaskedTextInputChange(e, 'fundingAmount')}
        />
      ),
    },
    {
      label: 'Term Length',
      offer: currentOffer?.termLength,
      exception: (
        <MaskedTextInput
          format="##"
          label="Term Length"
          hiddenLabel
          type="number"
          name="termLength"
          placeholder="##"
          value={formState.termLength}
          onValueChange={(e) => handleMaskedTextInputChange(e, 'termLength')}
        />
      ),
    },
    {
      label: 'Buy Rate',
      offer: currentOffer?.buyRate,
      exception: (
        <CurrencyInput
          label="Buy Rate"
          hiddenLabel
          name="buyRate"
          placeholder="#.##"
          value={formState.buyRate}
          onValueChange={(e) => handleMaskedTextInputChange(e, 'buyRate')}
        />
      ),
    },
    {
      label: 'Program',
      offer: currentOffer?.program,
      exception: (
        <Select
          name="program"
          label="Program"
          value={formState.program}
          options={selectProgramOptions}
          onValueChange={handleSelectChange}
        />
      ),
    },
    {
      label: 'Decision Reversal',
      offer: props.exceptionRequest?.decisionReversal,
      exception: props.exceptionRequest?.decisionReversal ? (
        <Icon name="check" color="black" />
      ) : (
        'No'
      ),
    },
    {
      label: 'Stipulations / Additional Documents',
      offer: null,
      exception: (
        <TextArea
          label="Stipulation Exception Notes"
          hiddenLabel
          name="stipExceptionNotes"
          value={formState.stipExceptionNotes}
          onChange={handleChange}
          rows={1}
        />
      ),
    },
    {
      label: 'Notes',
      offer: null,
      exception: (
        <TextArea
          label="Offer Exception Notes"
          hiddenLabel
          name="offerExceptionNotes"
          value={formState.offerExceptionNotes}
          onChange={handleChange}
          rows={1}
        />
      ),
    },
  ];

  return (
    <>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Offer</Table.ColumnHeader>
          <Table.ColumnHeader>FF Offer</Table.ColumnHeader>
          <Table.ColumnHeader>Exception Request</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {rows.map((row) => (
            <Table.Row key={row.label}>
              <Table.Cell>{row.label}</Table.Cell>
              <Table.Cell>{row.offer}</Table.Cell>
              <Table.Cell>
                <Box py={1}>{row.exception}</Box>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Flex my={2} gap={2} justifyContent="flex-end">
        <Button variant="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button onClick={onSubmitClick} disabled={props.isSubmitDisabled}>
          {props.isSubmitDisabled ? <Loading /> : 'Submit Request'}
        </Button>
      </Flex>
      {errorMessage && <Banner>{errorMessage}</Banner>}
    </>
  );
};
