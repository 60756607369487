import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useApiAssignNextProcessingDeal } from 'apiHooks/underwriting/submissionFetchHooks';
import { ProcessingQueueResponse } from 'types/api/underwriting/types';

export type AssignedProcessingDeal = {
  submissionUuid?: string;
  customerUuid?: string;
};

type UseNextProcessingDealResult = [
  () => Promise<MutationResponse>,
  {
    data?: AssignedProcessingDeal;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

const toNextProcessingDeal = (
  response?: ProcessingQueueResponse
): AssignedProcessingDeal | undefined => {
  if (!response) {
    return undefined;
  }

  if ('success' in response) {
    return {
      submissionUuid: undefined,
      customerUuid: undefined,
    };
  }

  return {
    submissionUuid: response?.submission_uuid,
    customerUuid: response?.customer_uuid,
  };
};

export const useAssignNextProcessingDeal = (): UseNextProcessingDealResult => {
  const [assignNextProcessingDeal, { data, error, loading, responseReady }] =
    useApiAssignNextProcessingDeal();

  const updateFunction = async (): Promise<MutationResponse> => {
    return await assignNextProcessingDeal();
  };

  return [
    updateFunction,
    { data: toNextProcessingDeal(data), error, loading, responseReady },
  ];
};
