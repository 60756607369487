import React, { useState } from 'react';

import {
  Banner,
  Button,
  Flex,
  formatDateTimeString,
  Loading,
  Modal,
  Navbar,
  Table,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { useGetSubmissionStageHistory } from './navbarFetchHooks';

export interface NavbarSubmissionStageHistoryModalProps {
  submissionUuid: string;
}

export const NavbarSubmissionStageHistoryModal = ({
  submissionUuid,
}: NavbarSubmissionStageHistoryModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const renderSubStage = (subStage?: string): string | undefined => {
    if (subStage === 'Credit Committee') {
      return DECISION_OR_CREDIT_COMMITTEE;
    }

    return subStage;
  };

  const handleOpenChange = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  const {
    data: stageHistory,
    error,
    loading,
  } = useGetSubmissionStageHistory(submissionUuid);

  return (
    <Modal
      size={'auto'}
      title="Submission Stage History"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      body={
        <Flex flexDirection={'column'}>
          {error && <Banner>{error.message}</Banner>}
          {loading && <Loading />}
          {!loading && (
            <Table caption={'Submission Stage History'}>
              <Table.Head>
                <Table.ColumnHeader>Date</Table.ColumnHeader>
                <Table.ColumnHeader>Stage</Table.ColumnHeader>
                <Table.ColumnHeader>Substage</Table.ColumnHeader>
                <Table.ColumnHeader>UW assigned</Table.ColumnHeader>
                <Table.ColumnHeader>Notes</Table.ColumnHeader>
                <Table.ColumnHeader>Updated By</Table.ColumnHeader>
              </Table.Head>
              <Table.Body>
                {stageHistory?.map((data) => (
                  <Table.Row key={data.date + data.stage}>
                    <Table.Cell>{formatDateTimeString(data.date)}</Table.Cell>
                    <Table.Cell>{data.stage}</Table.Cell>
                    <Table.Cell>{renderSubStage(data.subStage)}</Table.Cell>
                    <Table.Cell>{data.assignedUnderwriter}</Table.Cell>
                    <Table.Cell columnWidth="large">{data.notes}</Table.Cell>
                    <Table.Cell>{data.updatedBy}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
          <Flex justifyContent={'center'} pt={3}>
            <Button onClick={handleOpenChange}>Close</Button>
          </Flex>
        </Flex>
      }
      trigger={
        <Navbar.Button key="stageHistory" icon="clock-rotate-left">
          Stage History
        </Navbar.Button>
      }
    />
  );
};
