export const safeUrl = (
  url: string | null | undefined,
  https = true
): string => {
  if (!url) {
    return '';
  }

  if (!/^https?:\/\//i.test(url)) {
    url = `http${https ? 's' : ''}://` + url;
  }

  return url;
};
