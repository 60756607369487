import {
  Box,
  Card,
  Grid,
  Heading,
  IconButton,
} from '@forward-financing/fast-forward';
import React from 'react';
import { noDataMessage } from 'helpers/string/errorMessageUtils';
import { UccFilingsTable } from '../LexisNexisSharedTables/UccFilingsTable';
import { NameVariationsTable } from './NameVariationsTable';
import { AddressSummaryTable } from './AddressSummaryTable';
import { PersonalInformationTable } from './PersonalInformationTable';
import { ProfessionalLicensesTable } from './ProfessionalLicensesTable';
import { NoticeOfDefaultsTable } from './NoticeOfDefaultsTable';
import { LiensJudgmentsTable } from './LiensJudgmentsTable';
import { CriminalRecordTable } from './CriminalRecordsTable';
import { ForeclosureTable } from './ForeclosureTable';
import { BankruptciesTable } from './BankruptciesTable';
import { CorporateAffiliationsTable } from './CorporateAffiliationsTable';
import { OwnerLexisNexisReport } from './ownerLexisNexisPage.types';

export type OwnerLexisNexisPageProps = {
  report: OwnerLexisNexisReport;
  heading?: string;
  handleBack?: () => void;
  submissionUuid?: string;
};

export const OwnerLexisNexisPage = ({
  report,
  handleBack,
  heading,
  submissionUuid,
}: OwnerLexisNexisPageProps): JSX.Element => {
  return (
    <>
      {handleBack && (
        <Box mt={2}>
          <IconButton
            label="Back to submission"
            onClick={handleBack}
            icon={'circle-left'}
          />
        </Box>
      )}
      {heading && <Heading>{heading}</Heading>}
      <Grid gutter>
        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Personal Information">
            {report.personalInformation ? (
              <PersonalInformationTable
                personalInformationData={report.personalInformation}
              />
            ) : (
              noDataMessage('Personal Information')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Name Variations">
            {report.nameVariations ? (
              <NameVariationsTable nameVariationData={report.nameVariations} />
            ) : (
              noDataMessage('Name Variations')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Address Summary">
            {report.addressSummary.length > 0 ? (
              <AddressSummaryTable addressSummaryData={report.addressSummary} />
            ) : (
              noDataMessage('Address Summary')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Professional Licenses">
            {report.professionalLicenses.length > 0 ? (
              <ProfessionalLicensesTable
                professionalLicenses={report.professionalLicenses}
              />
            ) : (
              noDataMessage('Professional Licenses')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Corporate Affiliations">
            {report.corporateAffiliations.length > 0 && submissionUuid ? (
              <CorporateAffiliationsTable
                submissionUuid={submissionUuid}
                corporateAffiliations={report.corporateAffiliations}
              />
            ) : (
              noDataMessage('Corporate Affiliations')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="Criminal Records">
            {report.criminalRecords.length > 0 ? (
              <CriminalRecordTable
                criminalRecordData={report.criminalRecords}
              />
            ) : (
              noDataMessage('Criminal Records')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={12} m={12} xs={12}>
          <Card title="UCC Filings">
            {report.uccFilings.length > 0 ? (
              <UccFilingsTable uccFilingData={report.uccFilings} />
            ) : (
              noDataMessage('UCC Filings')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Liens Judgments">
            {report.liensJudgments.length > 0 ? (
              <LiensJudgmentsTable lienJudgmentData={report.liensJudgments} />
            ) : (
              noDataMessage('Liens Judgments')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Bankruptcies">
            {report.bankruptcies.length > 0 ? (
              <BankruptciesTable bankruptcies={report.bankruptcies} />
            ) : (
              noDataMessage('Bankruptcies')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Notices of Default">
            {report.noticesOfDefaults ? (
              <NoticeOfDefaultsTable
                noticeOfDefaults={report.noticesOfDefaults}
              />
            ) : (
              noDataMessage('Notices of default')
            )}
          </Card>
        </Grid.Item>

        <Grid.Item l={6} m={6} s={12} xs={12}>
          <Card title="Foreclosures">
            {report.foreclosures.length > 0 ? (
              <ForeclosureTable foreclosureData={report.foreclosures} />
            ) : (
              noDataMessage('Foreclosures')
            )}
          </Card>
        </Grid.Item>
      </Grid>
    </>
  );
};
