import React, { useState } from 'react';
import {
  Banner,
  Box,
  Card,
  Checkbox,
  DatePicker,
  Flex,
  Grid,
  IconButton,
  MaskedTextInput,
  Select,
  TextInput,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { DATE_FORMAT } from 'constants/globals';
import { useUserContext } from 'contexts/UserContext';
import { CustomerAddress, Owner } from './SubmissionEditing.types';
import { useGetStates, useSendLogs } from './SubmissionEditingHooks';
import { convertDateStringToPlainDate } from './submissionEditingHelpers';
import { OwnerSchemaError } from './OwnerSectionForm';

type OwnerFormProps = {
  handleOwnersFormOnchange: (
    ownerToUpdateIndex: number,
    updatedOwner: Partial<Owner>
  ) => void;
  index: number;
  ownerForm: Owner;
  deleteOwner: (index: number) => void;
  customerAddress: CustomerAddress;
  owner1Address: CustomerAddress;
  persistedOwner: Owner;
  ownerErrors?: OwnerSchemaError;
};

export const OwnerForm = ({
  handleOwnersFormOnchange,
  index,
  ownerForm,
  deleteOwner,
  customerAddress,
  persistedOwner,
  owner1Address,
  ownerErrors,
}: OwnerFormProps): JSX.Element => {
  const [revealSsn, setRevealSsn] = useState(
    ownerForm.uuid === '' || ownerForm.ssn === '' || ownerForm.ssn === undefined
  );
  const [revealDob, setRevealDob] = useState(
    ownerForm.uuid === '' ||
      ownerForm.bornOn === '' ||
      ownerForm.bornOn === undefined
  );
  const {
    first_name: firstName,
    last_name: lastName,
    uuid: userUuid,
    email: userEmail,
  } = useUserContext();
  const { data: states, error: statesError } = useGetStates();

  const [sendLogs, { error: errorLogs }] = useSendLogs();

  const isOwnerPersisted = ownerForm.uuid !== '';

  const renderVisibilityButton = (
    field: string,
    revealButtonState: boolean,
    setStateFuction: React.Dispatch<React.SetStateAction<boolean>>
  ): JSX.Element => {
    if (!isOwnerPersisted) {
      return <></>;
    }
    return revealButtonState ? (
      <TextInput.IconButton
        description={`Hide ${field}`}
        onClick={() => setStateFuction(false)}
        icon={'eye-slash'}
      />
    ) : (
      <TextInput.IconButton
        description={`Show ${field}`}
        onClick={() => revealSensitiveValue(field, setStateFuction)}
        icon={'eye'}
      />
    );
  };

  const revealSensitiveValue = async (
    field: string,
    setStateFuction: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> => {
    const { success } = await sendLogs({
      fieldName: field,
      name: `${firstName} ${lastName}`,
      action: 'show',
      userUuid: userUuid,
      userEmail: userEmail,
      source: 'SubmissionEditing',
      timestamp: formatDateTimeString(new Date().toDateString()),
      objectType: 'Owner',
      objectUuid: ownerForm.uuid,
    });

    success && setStateFuction(true);
  };

  const isSsnFieldDisabled =
    (!revealSsn && ownerForm.ssn !== '') || ownerForm.ssnNotPresent;

  const isDobFieldDisabled =
    (!revealDob && ownerForm.bornOn !== '') || ownerForm.dobNotPresent;

  return (
    <>
      {statesError && <Banner dismissable>{statesError?.message}</Banner>}
      {errorLogs && <Banner dismissable>{errorLogs?.message}</Banner>}
      <Card
        title={`Owner ${index + 1}`}
        actions={
          <IconButton
            icon={'trash'}
            onClick={() => deleteOwner(index)}
            label="Remove Owner"
          />
        }
      >
        <Grid gutter>
          <Grid.Item s={12} m={12} l={12}>
            <Flex gap={2} justifyContent={'right'}>
              <Checkbox
                checked={ownerForm.hasSameAddressAsBusiness}
                onCheckboxChange={(checked) => {
                  handleOwnersFormOnchange(index, {
                    hasSameAddressAsBusiness: checked,
                    // Unchecking the other checkbox if it was checked.
                    // This is to not have both checkboxes checked at the same time
                    hasSameAddressAsOwner1:
                      ownerForm.hasSameAddressAsOwner1 && !checked,
                    address: customerAddress,
                  });
                }}
                label={'Home address same as business address'}
              />
              {index > 0 && (
                <Checkbox
                  checked={ownerForm.hasSameAddressAsOwner1}
                  onCheckboxChange={(checked) => {
                    handleOwnersFormOnchange(index, {
                      // Unchecking the other checkbox if it was checked.
                      // This is to not have both checkboxes checked at the same time
                      hasSameAddressAsBusiness:
                        ownerForm.hasSameAddressAsBusiness && !checked,
                      hasSameAddressAsOwner1: checked,
                      address: owner1Address,
                    });
                  }}
                  label={'Home address same as Owner 1'}
                />
              )}
            </Flex>
          </Grid.Item>
          <Grid.Item s={12} m={6} l={6}>
            <Flex flexDirection={'column'} gap={2}>
              <TextInput
                label="First Name"
                value={ownerForm.firstName}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    firstName: newVal,
                  })
                }
                errorMessage={ownerErrors?.firstName?._errors.join(' ')}
                required
              />

              <TextInput
                label="Last Name"
                value={ownerForm.lastName}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    lastName: newVal,
                  })
                }
                errorMessage={ownerErrors?.lastName?._errors.join(' ')}
                required
              />

              <Box>
                <MaskedTextInput
                  label="SSN"
                  value={
                    !revealSsn || ownerForm.ssnNotPresent ? '' : ownerForm.ssn
                  }
                  type="text"
                  disabled={isSsnFieldDisabled}
                  onValueChange={(newVal) =>
                    !isSsnFieldDisabled &&
                    handleOwnersFormOnchange(index, {
                      ssn: newVal.value,
                    })
                  }
                  placeholder={
                    revealSsn || ownerForm.ssnNotPresent ? '' : 'XXX-XX-XXXX'
                  }
                  format={'###-##-####'}
                  mask={'_'}
                  errorMessage={ownerErrors?.ssn?._errors.join(' ')}
                  afterInputContent={
                    !ownerForm.ssnNotPresent &&
                    ownerForm.ssn !== '' &&
                    renderVisibilityButton('SSN', revealSsn, setRevealSsn)
                  }
                  required={!ownerForm.ssnNotPresent && !ownerForm.ssn}
                />

                {persistedOwner?.ssnNotPresent || isOwnerPersisted === false ? (
                  <Checkbox
                    checked={ownerForm.ssnNotPresent}
                    onCheckboxChange={(checked) => {
                      handleOwnersFormOnchange(index, {
                        ssn: '',
                        ssnNotPresent: checked,
                      });
                    }}
                    label={'SSN not provided'}
                  />
                ) : null}
              </Box>

              <Box>
                <DatePicker
                  label="Date of Birth"
                  canSelectTime={false}
                  selected={
                    !revealDob || ownerForm.dobNotPresent
                      ? undefined
                      : convertDateStringToPlainDate(ownerForm.bornOn)
                  }
                  disabled={isDobFieldDisabled}
                  onChange={(newVal) =>
                    !isDobFieldDisabled &&
                    handleOwnersFormOnchange(index, {
                      bornOn: newVal?.toString(),
                    })
                  }
                  errorMessage={ownerErrors?.bornOn?._errors.join(' ')}
                  placeholder={
                    revealDob || ownerForm.dobNotPresent
                      ? DATE_FORMAT
                      : 'XX/XX/XXXX'
                  }
                  afterInputContent={
                    !ownerForm.dobNotPresent &&
                    ownerForm.bornOn !== '' &&
                    renderVisibilityButton(
                      'Date of Birth',
                      revealDob,
                      setRevealDob
                    )
                  }
                  required={!ownerForm.dobNotPresent && !ownerForm.bornOn}
                />
                {persistedOwner?.dobNotPresent || isOwnerPersisted === false ? (
                  <Checkbox
                    checked={ownerForm.dobNotPresent}
                    onCheckboxChange={(checked) => {
                      handleOwnersFormOnchange(index, {
                        bornOn: '',
                        dobNotPresent: checked,
                      });
                    }}
                    label={'Date of Birth not provided'}
                  />
                ) : null}
              </Box>

              <TextInput
                label="Title"
                value={ownerForm.title}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    title: newVal,
                  })
                }
              />

              <MaskedTextInput
                label="Ownership %"
                value={ownerForm.ownershipPercentage}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    ownershipPercentage: newVal.value,
                  })
                }
                format={'###'}
                type="number"
                mask={''}
                errorMessage={ownerErrors?.ownershipPercentage?._errors.join(
                  ' '
                )}
                required
              />
            </Flex>
          </Grid.Item>

          <Grid.Item s={12} m={6} l={6}>
            <Flex flexDirection={'column'} gap={2}>
              <TextInput
                label="Street Address"
                value={ownerForm.address.street1}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    address: {
                      ...ownerForm.address,
                      street1: newVal,
                    },
                  })
                }
                errorMessage={ownerErrors?.address?.street1?._errors.join(' ')}
                required
              />

              <TextInput
                label="City"
                value={ownerForm.address.city}
                onValueChange={(newVal) => {
                  handleOwnersFormOnchange(index, {
                    address: { ...ownerForm.address, city: newVal },
                  });
                }}
                errorMessage={ownerErrors?.address?.city?._errors.join(' ')}
                required
              />

              <Select
                label="State"
                options={states || []}
                value={ownerForm.address.state}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    address: {
                      ...ownerForm.address,
                      state: newVal,
                    },
                  })
                }
                errorMessage={ownerErrors?.address?.state?._errors.join(' ')}
                required
              />

              <MaskedTextInput
                label="Zip Code"
                value={ownerForm.address.zip}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    address: {
                      ...ownerForm.address,
                      zip: newVal.value,
                    },
                  })
                }
                format={
                  ownerForm.address.zip.length > 5 ? '#####-####' : '#########'
                }
                type="number"
                mask={''}
                errorMessage={ownerErrors?.address?.zip?._errors.join(' ')}
                required
              />

              <MaskedTextInput
                label="Home Phone"
                value={ownerForm.homePhone}
                type="number"
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    homePhone: newVal.value,
                  })
                }
                format={'(###) ###-####'}
                mask={'_'}
              />

              <MaskedTextInput
                label="Mobile Phone"
                value={ownerForm.cellPhone}
                type="number"
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    cellPhone: newVal.value,
                  })
                }
                format={'(###) ###-####'}
                mask={'_'}
              />

              <TextInput
                label="Email Address"
                type="email"
                value={ownerForm.email}
                onValueChange={(newVal) =>
                  handleOwnersFormOnchange(index, {
                    email: newVal,
                  })
                }
                errorMessage={ownerErrors?.email?._errors.join(' ')}
              />
            </Flex>
          </Grid.Item>
        </Grid>
      </Card>
    </>
  );
};
