import { FFLogger } from 'api/LogClient';
import { useEffect } from 'react';

const defaultSkip = (): boolean => false;

export const useLogError = (
  error: Error | undefined,
  skip: (e: Error) => boolean = defaultSkip,
  level: 'error' | 'warn' | 'info' = 'error'
): void => {
  useEffect(() => {
    if (error && !skip(error)) {
      FFLogger[level](error);
    }
  }, [error]);
};
