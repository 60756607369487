import React, { useEffect, useState } from 'react';
import { Sheet } from '@forward-financing/fast-forward';

import { isEmpty } from 'lodash';
import { isFloatOrNumberString } from 'helpers/validations/ValidationHelpers';
import { MonthRate } from './buyRates.types';
import { formatRate } from './buyRates.utils';

type EditableTextCellProps = Partial<MonthRate> & {
  isEditable: boolean;
  handleUpdate: (payload: MonthRate) => Promise<void>;
};

export const BuyRatesTableCell = ({
  rate,
  months,
  isEditable,
  handleUpdate,
}: EditableTextCellProps): JSX.Element => {
  const [value, setValue] = useState(formatRate(rate));

  useEffect(() => {
    if (rate) {
      setValue(rate.toFixed(3));
    }
  }, [rate]);

  const onBlurHandler = async (): Promise<void> => {
    if (!isNaN(Number(value)) && value !== rate?.toFixed(3) && months) {
      await handleUpdate({ months, rate: parseFloat(value) });
    }
  };

  const onChangeHandler = (newValue: string): void => {
    if (isFloatOrNumberString(newValue) || isEmpty(newValue)) {
      setValue(newValue);
    }
  };

  return (
    <Sheet.TextCell
      key={`${months}-${rate}`}
      value={value}
      onValueChange={isEditable ? onChangeHandler : undefined}
      onBlur={isEditable ? onBlurHandler : undefined}
    />
  );
};
