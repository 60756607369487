import React, { useState } from 'react';
import {
  Banner,
  Button,
  Modal,
  Table,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { IndustryRisk, IndustryRiskAuditEntry } from './IndustryRisk.types';
import { getAuditLog } from './IndustryRiskFetchUtils';
import { toError } from 'helpers/errorUtils';
import { AuditLogChangeText } from './AuditLogChangeText';

export interface IndustryRiskAuditLogModalProps {
  industryRisk: IndustryRisk;
}

export const IndustryRiskAuditLogModal = (
  props: IndustryRiskAuditLogModalProps
): JSX.Element => {
  const [auditEntries, setAuditEntries] = useState<
    IndustryRiskAuditEntry[] | undefined
  >();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleError = (e: unknown): void => {
    const err = toError(e);
    setError(err.toString());
  };

  const fetchIndustryRiskAuditLog = async (
    industryRisk: IndustryRisk
  ): Promise<void> => {
    try {
      const auditLogs = await getAuditLog(industryRisk);
      setAuditEntries(auditLogs);
    } catch (err) {
      handleError(err);
    }
  };

  const onOpenChangeHandler = (): void => {
    if (isOpen) {
      setAuditEntries(undefined);
    } else {
      void fetchIndustryRiskAuditLog(props.industryRisk);
    }
    setIsOpen((isOpen) => !isOpen);
  };

  const handleErrorDismiss = (): void => setError(error);

  return (
    <Modal
      title={`${props.industryRisk.name} Audit Log`}
      isOpen={isOpen}
      onOpenChange={onOpenChangeHandler}
      trigger={<Button>Audit Log</Button>}
      body={
        <>
          {error && <Banner onDismiss={handleErrorDismiss}>{error}</Banner>}
          <Table caption={`Industry Risk ${props.industryRisk.name}`}>
            <Table.Head>
              <Table.ColumnHeader>Username</Table.ColumnHeader>
              <Table.ColumnHeader>Event</Table.ColumnHeader>
              <Table.ColumnHeader>Change</Table.ColumnHeader>
              <Table.ColumnHeader>Modified At</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              {auditEntries &&
                auditEntries.map((entry: IndustryRiskAuditEntry) => (
                  <Table.Row key={entry.id}>
                    <Table.Cell>{entry.whodunnit_user_name}</Table.Cell>
                    <Table.Cell>{entry.event}</Table.Cell>
                    <Table.Cell>
                      <AuditLogChangeText
                        object_changes={entry.object_changes}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {formatDateTimeString(entry.created_at)}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </>
      }
    />
  );
};
