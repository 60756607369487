import React from 'react';
import { Radio } from '@forward-financing/fast-forward';
import { CustomerCard } from './CustomerCard';
import { CustomerOption } from './CustomerSearch.types';

interface CustomerListProps {
  customers: CustomerOption[];
  selectedCustomerUuid: string;
  handleOnChange: (customerUuid: string) => void;
}

export const CustomerList = (props: CustomerListProps): JSX.Element => {
  return (
    <Radio
      name="Select the account you wish to proceed with or create a new customer."
      value={props.selectedCustomerUuid}
      onChange={props.handleOnChange}
      orientation="vertical"
    >
      {props.customers.map((customer) => (
        <Radio.Item
          key={customer.uuid}
          id={customer.uuid}
          value={customer.uuid}
        >
          <CustomerCard
            customer={customer}
            isRecommended={customer.recommended}
          />
        </Radio.Item>
      ))}
    </Radio>
  );
};
