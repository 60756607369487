export interface Address {
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zip: string;
}

/**
 * @param address An Address object
 * @returns A string concatenation of all populated fields with space separators
 */
export const addressToString = (address: Address): string => {
  if (address.street2) {
    return `${address.street1} ${address.street2} ${address.city} ${address.state} ${address.zip}`;
  }

  return `${address.street1} ${address.city} ${address.state} ${address.zip}`;
};
