export interface FeatureFlagStoreOptions<T extends string> {
  flags: readonly T[];
}

/**
 * {@link FeatureFlagStore} instance that also has getters for each flag that
 * return whether that flag is currently active.
 *
 * Using getters makes mocking out specific flags really simple in tests.
 * For example, to have a test run with a flag called `my_flag` enabled, we
 * would just need to do:
 *
 * ```js
 * jest.spyOn(featureFlags, 'my_flag', 'get').mockImplementation(true);
 * ```
 *
 * If we used a lookup function -- e.g. `#isActive(flagName: T)` -- it would
 * be more difficult to mock, because jest does not support mocking different
 * behaviors for different arguments. That is, we would not be able to do
 * something like:
 *
 * ```js
 * jest.spyOn(featureFlags, 'isActive')
 *   .mockImplementationWhenCalledWith('my_flag');
 * ```
 *
 * ...because there's no such method on jest's mocks.
 */
export type FeatureFlagStoreWithFlagAccessors<T extends string> =
  FeatureFlagStore<T> & {
    readonly [K in T]: boolean;
  };

export class FeatureFlagStore<T extends string> {
  private flags: Record<string, boolean>;

  // Using a static factory method in order to add the flag getters to the
  // returned store's interface. We're using a private constructor to make sure
  // the stores are always created with this method.
  static create<U extends string>(
    options: FeatureFlagStoreOptions<U>
  ): FeatureFlagStoreWithFlagAccessors<U> {
    return new this(options) as FeatureFlagStoreWithFlagAccessors<U>;
  }

  private constructor(options: FeatureFlagStoreOptions<T>) {
    this.flags = Object.fromEntries(options.flags.map((fn) => [fn, false]));

    options.flags.forEach((flagName) => {
      Object.defineProperty(this, flagName, {
        get: () => this.flags[flagName],

        // allow the getter to be mocked
        configurable: true,
      });
    });
  }

  async loadBy(
    fetchFlags: (flagNames: readonly T[]) => Promise<Record<string, boolean>>
  ): Promise<void> {
    const flagNames = Object.keys(this.flags) as T[];

    if (flagNames.length === 0) {
      return;
    }

    const fetchedFlags = await fetchFlags(flagNames);

    flagNames.forEach((flagName) => {
      this.flags[flagName] = !!fetchedFlags[flagName];
    });

    this.overrideFlagsInDev();
  }

  /* istanbul ignore next */
  private overrideFlagsInDev() {
    if (process.env.NODE_ENV === 'development') {
      const searchParams = new URLSearchParams(window.location.search);

      (Object.keys(this.flags) as T[])
        .filter((flag) => searchParams.has(`feat_${flag}`))
        .forEach((flag) => {
          this.flags[flag] = searchParams.get(`feat_${flag}`) === 'ON';
        });
    }
  }
}
