import {
  createFraudProtectionDecision,
  fetchSubmissionFraudProtectionMatches,
} from 'api/underwriting/fraudProtectionFetchUtils';
import {
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
  useGenericMutation,
  useLazyGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  CreateFraudProtectionDecisionBody,
  CreateFraudProtectionDecisionResponse,
  FraudMatchResponse,
} from 'types/api/underwriting/types';

export interface UseLazyGetApiFraudMatchesArgs {
  submissionUuid: string;
}

export type UseLazyGetApiFraudMatchesRefresher =
  UseLazyGenericQueryFetcher<UseLazyGetApiFraudMatchesArgs>;

export type UseLazyGetApiFraudMatchesResponse = UseLazyGenericQueryResult<
  FraudMatchResponse[]
>;

const fetchMatchesWithArgs = (
  args: UseLazyGetApiFraudMatchesArgs
): Promise<FraudMatchResponse[]> =>
  fetchSubmissionFraudProtectionMatches(args.submissionUuid);

export const useApiLazyGetFraudProtectionMatches = (): [
  UseLazyGetApiFraudMatchesRefresher,
  UseLazyGetApiFraudMatchesResponse
] => {
  return useLazyGenericQuery(fetchMatchesWithArgs);
};

export type UseGetApiFraudProtectionMatchesResponse = UseGenericQueryResponse<
  FraudMatchResponse[]
>;

// Create Fraud Decision
export interface UseCreateFraudProtectionDecisionArgs {
  createBody: CreateFraudProtectionDecisionBody;
}

const createDecisionFunction = ({
  createBody,
}: UseCreateFraudProtectionDecisionArgs): Promise<CreateFraudProtectionDecisionResponse> => {
  return createFraudProtectionDecision(createBody);
};

export const useCreateApiFraudProtectionDecision =
  (): UseGenericMutationResponse<
    CreateFraudProtectionDecisionResponse,
    UseCreateFraudProtectionDecisionArgs
  > => {
    return useGenericMutation(createDecisionFunction);
  };
