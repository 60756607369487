import React, { useState } from 'react';
import {
  Banner,
  Box,
  FileUploader,
  FileUploaderProps,
  Grid,
  RadioGrid,
} from '@forward-financing/fast-forward';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import { AttachmentSource } from './attachmentManager.types';
import { useTimeout } from 'ahooks';
import { useCreateAttachment } from './attachmentManagerHooks';

export const AttachmentManagerUploader = (): JSX.Element => {
  const { primaryId, supportedFileExtensions, reloadAttachmentManager } =
    useAttachmentManagerContext();

  const [selectedFolder, setSelectedFolder] =
    useState<AttachmentSource>('closing_documents');

  const [uploaderState, setUploaderState] =
    useState<FileUploaderProps['state']>('default');
  const [uploadErrors, setUploadErrors] = useState<string[] | undefined>();

  const [statusResetDelay, setStatusResetDelay] = useState<
    number | undefined
  >();

  const [createAttachment, { error: createAttachmentError }] =
    useCreateAttachment();

  useTimeout(() => {
    setUploaderState('default');
    setStatusResetDelay(undefined);
  }, statusResetDelay);

  const handleDrop: FileUploaderProps['onDrop'] = async (
    acceptedFiles,
    fileRejections
  ) => {
    if (fileRejections.length > 0) {
      const errors = fileRejections.reduce((acc, rejection) => {
        const messages = rejection.errors.map((error) => {
          if (error.code === 'file-invalid-type') {
            return `${rejection.file.name}: Invalid file type`;
          } else {
            return `${rejection.file.name}: ${error.message}`;
          }
        });

        return [...acc, ...messages];
      }, [] as string[]);

      setUploaderState('error');
      setUploadErrors(errors);
      return;
    }

    // null out any existing error messages
    setUploadErrors(undefined);
    setUploaderState('loading');

    const attachments = acceptedFiles.map((file) => ({
      submissionUuid: primaryId,
      createBody: {
        source: selectedFolder,
        file,
      },
    }));

    const { success } = await createAttachment(attachments);

    if (success) {
      setUploaderState('success');
      reloadAttachmentManager();

      setStatusResetDelay(2000);
    } else {
      setUploaderState('error');
    }
  };

  const uploaderStateProps = (() => {
    switch (uploaderState) {
      case 'loading':
        return { state: 'loading' as const };
      case 'success':
        return { state: 'success' as const };
      case 'error':
        return {
          state: 'error' as const,
          errorMessage: uploadErrors ?? [],
          onErrorDismiss: () => {
            setUploaderState('default');
            setUploadErrors([]);
          },
        };
      case 'default':
      case undefined:
      default:
        return { state: 'default' as const };
    }
  })();

  return (
    <Box mb={3}>
      {uploadErrors &&
        uploadErrors.map((err) => (
          <Banner key={err} dismissable={false}>
            {err}
          </Banner>
        ))}
      {createAttachmentError && (
        <Banner dismissable={false}>{createAttachmentError.message}</Banner>
      )}
      <Grid gutter>
        <Grid.Item xs={12} m={5}>
          <RadioGrid
            options={[
              {
                value: 'closing_documents',
                text: 'Closing Documents',
              },
              {
                value: 'original_submission',
                text: 'Original Submission',
              },
              {
                value: 'other',
                text: 'Other',
              },
            ]}
            questions={[
              {
                name: 'selected_folder',
                label: 'Document Type',
                value: selectedFolder,
              },
            ]}
            onValueChange={(_questionName, newValue) => {
              // question name is `selected_folder`, but we only have one question
              // so no need to check the name
              setSelectedFolder(newValue as AttachmentSource);
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12} m={7}>
          {supportedFileExtensions && (
            <FileUploader
              onDrop={handleDrop}
              accept={supportedFileExtensions as FileUploaderProps['accept']}
              maxSize={FileUploader.ONE_MEGABYTE * 100}
              {...uploaderStateProps}
            />
          )}
        </Grid.Item>
      </Grid>
    </Box>
  );
};
