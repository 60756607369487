import React from 'react';
import {
  Banner,
  Box,
  Flex,
  Icon,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { AchReturnsByType } from '../achReturns.types';
import {
  generateLastTwelveMonthNames,
  months,
} from 'helpers/date/dateIterationUtils';

const getMonthName = (sortedReturns: AchReturnsByType | undefined): string => {
  if (!sortedReturns) {
    return '';
  }

  const currentMonth = Object.keys(sortedReturns)[0];

  // currentMonth is in yyyy-MM-dd format
  const month = Number(currentMonth.split('-')[1]);

  // Subtract 1 since months array is 0-indexed
  return months[month - 1];
};

const returnTypeAsPercentage = (
  divisor: number | undefined,
  dividend: number | undefined
): string => {
  if (!divisor || !dividend) {
    return '';
  }

  return `${((divisor * 100) / dividend).toFixed(1)}%`;
};

export interface AchReturnsTableProps {
  dangerThreshold: number;
  warningThreshold: number;
  sortedReturns?: AchReturnsByType;
  responseReady: boolean;
  currentMonthPercentage: number;
}

const columnMonthNames = generateLastTwelveMonthNames();

export const AchReturnsTable = ({
  dangerThreshold,
  warningThreshold,
  sortedReturns,
  responseReady,
  currentMonthPercentage,
}: AchReturnsTableProps): JSX.Element => {
  if (!responseReady) {
    return <Text>Loading returns by type...</Text>;
  }

  return (
    <>
      <Box py={3}>
        {currentMonthPercentage >= warningThreshold && (
          <Banner
            variant={
              currentMonthPercentage >= dangerThreshold ? 'danger' : 'warning'
            }
            dismissable={false}
          >
            <Flex gap={2} alignItems="center">
              <Icon name="warning" color="danger" />
              <Text bold>
                {currentMonthPercentage >= dangerThreshold
                  ? 'Exceeded'
                  : 'Approaching'}{' '}
                15% threshold limit for month of {getMonthName(sortedReturns)}
              </Text>
            </Flex>
          </Banner>
        )}

        <Text bold>ACH Returns</Text>
      </Box>

      <Table>
        <Table.Head>
          <Table.ColumnHeader>Month</Table.ColumnHeader>
          <Table.ColumnHeader>All Returns</Table.ColumnHeader>
          <Table.ColumnHeader>Administrative</Table.ColumnHeader>
          <Table.ColumnHeader>Unauthorized</Table.ColumnHeader>
        </Table.Head>

        <Table.Body>
          {sortedReturns && Object.keys(sortedReturns).length > 0 ? (
            Object.keys(sortedReturns).map((row, index) => (
              <Table.Row key={row}>
                <Table.RowHeader>{columnMonthNames[index]}</Table.RowHeader>

                <Table.Cell>
                  {returnTypeAsPercentage(
                    sortedReturns[row].returnedCount,
                    sortedReturns[row].totalPaybacksCount
                  )}
                </Table.Cell>

                <Table.Cell>
                  {returnTypeAsPercentage(
                    sortedReturns[row].administrativeReturnsCount,
                    sortedReturns[row].totalPaybacksCount
                  )}
                </Table.Cell>

                <Table.Cell>
                  {returnTypeAsPercentage(
                    sortedReturns[row].unauthorizedReturnsCount,
                    sortedReturns[row].totalPaybacksCount
                  )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Text>No returns found.</Text>
          )}
        </Table.Body>
      </Table>
    </>
  );
};
