import React from 'react';
import {
  Box,
  Subheading,
  List,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const BusinessStartDatePopover = (): JSX.Element => {
  return (
    <Popover
      header="Business Start Date"
      content={
        <Box>
          <Subheading variant="subsection">
            When not to decline a deal
          </Subheading>
          <List>
            <List.Item>
              If the Customer is missing 1 to 2 days to complete the 12 months.
            </List.Item>
            <List.Item>
              If the Customer has had approved applications since 2019, even if
              the Signed Application states that the business has less than 1
              year.
            </List.Item>
            <List.Item>
              If the business is a Sole Proprietor, or the SOS can&apos;t be
              found, proceed requesting confirmation of the Business Start Date
              with the exception of receiving several pay back months.
            </List.Item>
            <List.Item>
              If the TIB in the App is missing, and the SOS TIB is set to a
              particular date but there are Bank Statements showing that the
              Customer was doing business before the SOS date, confirm the
              Business Start Date with the ISO.
            </List.Item>
          </List>
          <Subheading variant="subsection">When to use the SOS TIB</Subheading>
          <List>
            <List.Item>TIB matches the Signature Date.</List.Item>
            <List.Item>TIB in the App is set to a future date.</List.Item>
            <List.Item>
              TIB in the App is set to a particular date and there are Bank
              Statements showing the Customer was doing business before the
              given date.
            </List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="Business Start Date more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
