import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { AcceptedOfferResponse } from 'types/api/banking/types';

export const fetchAcceptedOffer = async (
  submissionUuid: string
): Promise<AcceptedOfferResponse> => {
  const url = new URL(
    `api/v2/opportunities/${submissionUuid}/accepted_offer`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<AcceptedOfferResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch accepted offer');
  }

  return response.json();
};
