import React from 'react';
import {
  Banner,
  formatDateString,
  Text,
} from '@forward-financing/fast-forward';
import { usePaymentScheduleContext } from './PaymentScheduleContext';
import add from 'date-fns/add';
import isBefore from 'date-fns/isBefore';

export interface SixtyDayCovenantBannerProps {
  /**
   * We strongly recommend NOT passing this prop for production usage.
   * It is exposed as a means of dependency injection for unit tests.
   */
  now?: Date;
}

export const SixtyDayCovenantBanner = ({
  now = new Date(),
}: SixtyDayCovenantBannerProps): JSX.Element => {
  const { advanceProfile } = usePaymentScheduleContext();

  if (!advanceProfile?.dateOfLastPayment) {
    return <></>;
  }

  const [year, month, day] = advanceProfile.dateOfLastPayment
    .split('-')
    .map((datePart) => parseInt(datePart));
  const lastPaymentDate = new Date(year, month - 1, day);

  const sixtyDaysAfterLastPayment = add(lastPaymentDate, { days: 60 });
  const fortyFiveDaysAfterLastPayment = add(lastPaymentDate, { days: 45 });

  if (isBefore(sixtyDaysAfterLastPayment, now)) {
    return (
      <Banner dismissable={false}>
        <Text>
          60 DAY COVENANT: Last payment was{' '}
          {formatDateString(advanceProfile.dateOfLastPayment)}, account breached
          60 day covenant on{' '}
          {formatDateString(sixtyDaysAfterLastPayment.toString())}
        </Text>
      </Banner>
    );
  }

  if (isBefore(fortyFiveDaysAfterLastPayment, now)) {
    return (
      <Banner dismissable={false} variant="warning">
        <Text>
          60 DAY COVENANT: Last payment was{' '}
          {formatDateString(advanceProfile.dateOfLastPayment)}, account will
          reach 60 day delinquency on{' '}
          {formatDateString(sixtyDaysAfterLastPayment.toString())}
        </Text>
      </Banner>
    );
  }

  return <></>;
};
