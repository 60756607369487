import React from 'react';
import {
  Address,
  Banner,
  Loading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { howLongAgo } from '../ApplicationSnapshot/applicationSnapshotUtils';
import { useSOSFields } from './businessOverviewHooks';
import {
  SOSFieldsContactType,
  SOSFieldsOwnership,
} from './businessOverview.types';

export type SOSFieldsTableProps = {
  submissionUuid: string;
};

const parseRegisteredAgent = (registeredAgent?: {
  fullName?: string;
  title?: string;
}): string => {
  if (!registeredAgent) {
    return '';
  }

  const { title, fullName } = registeredAgent;

  return [fullName, title].filter(Boolean).join(', ');
};

const parseContactType = (contactType?: SOSFieldsContactType[]): string => {
  if (!contactType) {
    return '';
  }

  return contactType
    .map(({ type, title }) => [type, title].filter(Boolean).join(', '))
    .join(', ');
};

const parseOwnership = (
  ownership?: SOSFieldsOwnership[]
): JSX.Element[] | undefined => {
  if (!ownership) {
    return undefined;
  }

  return ownership.map((owner, index) => {
    const contactType = parseContactType(owner.contactType);

    const ownershipInfo = [owner.fullName, contactType]
      .filter(Boolean)
      .join(', ');

    return <Text key={ownershipInfo}>{ownershipInfo}</Text>;
  });
};

const getSOSMessageColor = (message?: string): ColorVariant | undefined => {
  if (!message) {
    return undefined;
  }

  if (/\bauto accept\b/i.test(message)) {
    return 'green-100';
  } else if (/\bentity type mismatch\b/i.test(message)) {
    return 'red-200';
  } else if (/\bmanually reviewed\b/i.test(message)) {
    return 'red-100';
  } else {
    return undefined;
  }
};

export const SOSFieldsTable = ({
  submissionUuid,
}: SOSFieldsTableProps): JSX.Element => {
  const {
    data: sosFields,
    loading: sosFieldsLoading,
    error: sosFieldsError,
  } = useSOSFields(submissionUuid);

  if (sosFieldsLoading) {
    return <Loading />;
  }

  if (sosFieldsError) {
    return <Banner dismissable={false}>Failed to fetch SOS Fields</Banner>;
  }

  const { documents, success, error } = sosFields || {};

  if (!documents && !success && error) {
    return <>{error}</>;
  }

  return documents ? (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>SOS Status</Table.RowHeader>
          <Table.Cell>{documents.status}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>SOS Organization Structure</Table.RowHeader>
          <Table.Cell>{documents.organizationStructure}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>SOS Legal Name</Table.RowHeader>
          <Table.Cell>{documents.legalName}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>SOS Time in Business</Table.RowHeader>
          <Table.Cell>
            {documents.businessStartDate &&
              documents.businessStartDate.year &&
              documents.businessStartDate.month &&
              documents.businessStartDate.day &&
              howLongAgo(
                `${documents.businessStartDate.year}-${documents.businessStartDate.month}-${documents.businessStartDate.day}`
              )}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>SOS Business Address</Table.RowHeader>
          <Table.Cell>
            {documents.businessAddress &&
              documents.businessAddress.streetAddress &&
              documents.businessAddress.city &&
              documents.businessAddress.state &&
              documents.businessAddress.zip && (
                <Address
                  streetAddress={[documents.businessAddress.streetAddress]}
                  city={documents.businessAddress.city || ''}
                  state={documents.businessAddress.state || ''}
                  zipCode={documents.businessAddress.zip || ''}
                  isSingleLine
                />
              )}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Ownership</Table.RowHeader>
          <Table.Cell>{parseOwnership(documents.ownership)}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Registered Agent</Table.RowHeader>
          <Table.Cell>
            {parseRegisteredAgent(documents.registeredAgent)}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>SOS Verified</Table.RowHeader>
          <Table.Cell backgroundColor={getSOSMessageColor(documents.message)}>
            {documents.message}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  ) : (
    <>No SOS Match Found</>
  );
};
