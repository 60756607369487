/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  fragment BasicOwnerInfoTableFragment on Owner {\n    id\n    fullName\n    ssn\n    ssnLastFour\n    birthdate\n    address {\n      street1\n      street2\n      city\n      state\n      zip\n    }\n    workPhone\n    homePhone\n    cellPhone\n    email\n    ownershipDate\n    ownershipPercentage\n  }\n": types.BasicOwnerInfoTableFragmentFragmentDoc,
    "\n  query getOwnerOverviewSubmission($id: String!) {\n    me {\n      canSeeBirthdate: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.birthdate\")\n      canSeeFullSsn: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.ssn\")\n      canSeePartialSsn: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.ssnLastFour\")\n    }\n    submission(id: $id) {\n      id\n      customer {\n        id\n        owners {\n          ...BasicOwnerInfoTableFragment\n        }\n      }\n    }\n  }\n": types.GetOwnerOverviewSubmissionDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BasicOwnerInfoTableFragment on Owner {\n    id\n    fullName\n    ssn\n    ssnLastFour\n    birthdate\n    address {\n      street1\n      street2\n      city\n      state\n      zip\n    }\n    workPhone\n    homePhone\n    cellPhone\n    email\n    ownershipDate\n    ownershipPercentage\n  }\n"): (typeof documents)["\n  fragment BasicOwnerInfoTableFragment on Owner {\n    id\n    fullName\n    ssn\n    ssnLastFour\n    birthdate\n    address {\n      street1\n      street2\n      city\n      state\n      zip\n    }\n    workPhone\n    homePhone\n    cellPhone\n    email\n    ownershipDate\n    ownershipPercentage\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getOwnerOverviewSubmission($id: String!) {\n    me {\n      canSeeBirthdate: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.birthdate\")\n      canSeeFullSsn: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.ssn\")\n      canSeePartialSsn: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.ssnLastFour\")\n    }\n    submission(id: $id) {\n      id\n      customer {\n        id\n        owners {\n          ...BasicOwnerInfoTableFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getOwnerOverviewSubmission($id: String!) {\n    me {\n      canSeeBirthdate: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.birthdate\")\n      canSeeFullSsn: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.ssn\")\n      canSeePartialSsn: isAllowed(action: \"READ\", resource: \"Submission.Customer.Owner.ssnLastFour\")\n    }\n    submission(id: $id) {\n      id\n      customer {\n        id\n        owners {\n          ...BasicOwnerInfoTableFragment\n        }\n      }\n    }\n  }\n"];

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function gql(source: string): unknown;

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;