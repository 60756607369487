import { createAccessLog } from 'api/analyticsGateway/accessLogsFetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  useGenericMutation,
} from 'apiHooks/genericFetchHooks';
import { AccessLogsDataRequestBody } from 'types/api/analyticsGateway/types';

export type UseSendAccessLogsResponse = UseGenericMutationResponse<
  MutationResponse,
  AccessLogsDataRequestBody
>;

const sendLogs = (
  body: AccessLogsDataRequestBody
): Promise<MutationResponse> => {
  return createAccessLog(body);
};

export const useApiSendAccessLogs = (): UseSendAccessLogsResponse => {
  return useGenericMutation(sendLogs);
};
