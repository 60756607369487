import React from 'react';
import { Icon, Subheading, Table, Flex } from '@forward-financing/fast-forward';

interface DataRow {
  id: number;
  label: string;
  value: number | null;
}

export interface ScoreTableProps {
  title: string;
  data: DataRow[];
  maxScore: number;
  averageScore: number | null;
  showTrendIcons?: boolean;
}

const TrendIcon = ({
  value,
  maxScore,
}: {
  value?: number | null;
  maxScore: number;
}) => {
  if (value === maxScore) {
    return <Icon color="secondary" name="arrow-up" />;
  }

  if (value === 1) {
    return <Icon color="danger" name="arrow-down" />;
  }

  return null;
};

export const ScoreTable = ({
  title,
  data,
  maxScore,
  averageScore,
  showTrendIcons,
}: ScoreTableProps): JSX.Element => {
  const summaryText = averageScore
    ? `${averageScore.toFixed(1)} / ${maxScore.toFixed(1)}`
    : 'N/A';

  return (
    <>
      <Subheading>{title}</Subheading>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Risk Category</Table.ColumnHeader>
          <Table.ColumnHeader>Score</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {data.map((row) => (
            <Table.Row key={row.id}>
              <Table.RowHeader>{row.label}</Table.RowHeader>
              <Table.Cell>
                <Flex justifyContent="flex-start" alignItems="center" gap={1}>
                  {row.value === null ? 'N/A' : row.value.toFixed(1)}
                  {showTrendIcons && (
                    <TrendIcon maxScore={maxScore} value={row.value} />
                  )}
                </Flex>
              </Table.Cell>
            </Table.Row>
          ))}
          <Table.SummaryRow>
            <Table.Cell>Average Score</Table.Cell>
            <Table.Cell>{summaryText}</Table.Cell>
          </Table.SummaryRow>
        </Table.Body>
      </Table>
    </>
  );
};
