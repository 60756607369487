import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from '../networkError';
import { FUNDING_BASE_URL } from 'constants/globals';
import {
  IsoDropdownResponse,
  IsoResponse,
} from '../../types/api/funding/types';
import { Partner } from 'api/FundingClient/codecs';

export const fetchIsoByUuid = async (uuid: string): Promise<IsoResponse> => {
  const url = new URL(`/api/v2/partners/${uuid}`, FUNDING_BASE_URL());

  const response = await makeInternalAPIRequest<IsoResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch ISO');
  }

  return response.json();
};

export const fetchIsos = async (): Promise<IsoDropdownResponse[]> => {
  const url = new URL(`/api/v2/dropdown_partners`, FUNDING_BASE_URL());

  const response = await makeInternalAPIRequest<Partner[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, `Failed to fetch partners`);
  }

  return response.json();
};
