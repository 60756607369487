import React from 'react';
import { Box, Table, Text } from '@forward-financing/fast-forward';

import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { formatDate } from 'helpers/string/dateUtils';
import { TradelineDetails } from './types';
import { TradelineOverview } from './TradelineOverview/TradelineOverview';
import { TradelineCurrentStatusCell } from './common/TradelineCurrentStatusCell';
import { TradelineCreditLoanTypeCell } from './common/TradelineCreditLoanTypeCell';

type TradelinesTableProps = {
  tradelinesDetails: TradelineDetails[];
  emptyMessage: string;
  isAllRowsExpanded: boolean;
};

const getRowColor = (tradeline: TradelineDetails): ColorVariant | undefined => {
  if (
    tradeline.currentStatus === '9' ||
    tradeline.accountStatusType
      .toLowerCase()
      .includes('unpaid balance reported as a loss')
  ) {
    return 'red-200';
  }

  return Number.isNaN(Number(tradeline.currentStatus)) ||
    tradeline.currentStatus === '0'
    ? undefined
    : 'yellow-200';
};

const formatDateToExpected = (
  openDate: string | undefined
): string | undefined => {
  if (!openDate) {
    return undefined;
  }

  const dateObj = new Date(openDate);

  return formatDate(dateObj, 'MM/dd/yyyy');
};

export const TradelinesTable = ({
  tradelinesDetails,
  emptyMessage,
  isAllRowsExpanded,
}: TradelinesTableProps): JSX.Element => {
  if (!tradelinesDetails.length) {
    return <Text>{emptyMessage}</Text>;
  }

  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader />
        <Table.ColumnHeader>Name</Table.ColumnHeader>
        <Table.ColumnHeader>Credit Loan Type</Table.ColumnHeader>
        <Table.ColumnHeader>Responsibility</Table.ColumnHeader>
        <Table.ColumnHeader>Rating Type</Table.ColumnHeader>
        <Table.ColumnHeader>Open Date</Table.ColumnHeader>
        <Table.ColumnHeader>Account Balance</Table.ColumnHeader>
        <Table.ColumnHeader>Account Status Type</Table.ColumnHeader>
        <Table.ColumnHeader>Current Status</Table.ColumnHeader>
        <Table.ColumnHeader>Delinquency Count</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {tradelinesDetails.map((tradeline) => (
          <>
            <Table.ExpandableRow
              key={`${tradeline.name}-${tradeline.openDate}`}
              content={
                <Box py={3}>
                  <TradelineOverview tradeline={tradeline} />
                </Box>
              }
              backgroundColor={getRowColor(tradeline)}
              isExpandedByParent={isAllRowsExpanded}
              isWholeRowClickable={true}
            >
              <Table.Cell>{tradeline.name}</Table.Cell>
              <TradelineCreditLoanTypeCell {...tradeline} />
              <Table.Cell>{tradeline.responsibility}</Table.Cell>
              <Table.Cell>{tradeline.ratingType}</Table.Cell>
              <Table.Cell>
                {formatDateToExpected(tradeline.openDate)}
              </Table.Cell>
              <Table.Cell>{tradeline.accountBalance}</Table.Cell>
              <Table.Cell>{tradeline.accountStatusType}</Table.Cell>
              <TradelineCurrentStatusCell {...tradeline} />
              <Table.Cell>{tradeline.delinquencyCount}</Table.Cell>
            </Table.ExpandableRow>

            {tradeline.originalCreditorName && (
              <Table.Row
                key={`${tradeline.name}-${tradeline.openDate}${tradeline.originalCreditorName}`}
              >
                <Table.Cell colSpan={10}>
                  {'Original Creditor: '}
                  <b>{tradeline.originalCreditorName}</b>
                </Table.Cell>
              </Table.Row>
            )}
          </>
        ))}
      </Table.Body>
    </Table>
  );
};
