import React from 'react';
import { usePublicDocuments } from './ownerOverviewHooks';
import { Banner } from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';
import { CriminalFilingsPage } from './CriminalFilingsPage';

type Params = {
  submissionUuid: string;
  ownerUuid: string;
};

export const CriminalFilingsPageContainer = (): JSX.Element => {
  const { submissionUuid, ownerUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }

  if (!ownerUuid) {
    throw new Error('Owner UUID Param not set');
  }
  const { data: publicDocuments, error: publicDocumentsError } =
    usePublicDocuments(submissionUuid);

  const ownerPublicDocuments = publicDocuments?.filter(
    (doc) => doc.ownerUuid === ownerUuid
  );

  return (
    <>
      {publicDocumentsError && (
        <Banner dismissable={false} variant="error">
          {publicDocumentsError.message}
        </Banner>
      )}
      <CriminalFilingsPage ownerPublicDocuments={ownerPublicDocuments} />
    </>
  );
};
