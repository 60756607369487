// make sure these lists are on sync with the enums in the stipulation model in UW app

export const STIPULATION_STATUS_LIST = [
  { value: 'not_received', text: 'Not Received' },
  { value: 'pending', text: 'Pending' },
  { value: 'accepted', text: 'Accepted' },
  { value: 'rejected', text: 'Rejected' },
  { value: 'uw_sign_off', text: 'UW Sign Off' },
  { value: 'waived', text: 'Waived' },
];

export const SIGNED_CONTRACTS_STIPULATION_STATUS_LIST = [
  ...STIPULATION_STATUS_LIST,
  { value: 'rejected_and_reprice', text: 'Rejected & Reprice' },
];

export const BANK_VERIFICATION_TYPE_LIST = [
  { value: 'Streaming via Plaid', text: 'Streaming via Plaid' },
  {
    value: 'Streaming via Decision Logic',
    text: 'Streaming via Decision Logic',
  },
  { value: 'Manual', text: 'Manual' },
];
