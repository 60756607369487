import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Flex,
  Banner,
  Subheading,
  Icon,
  Loading,
  Text,
} from '@forward-financing/fast-forward';
import { PotentialFraudAlert } from './PotentialFraud.types';
import { useCreatePotentialFraudDecision } from './PotentialFraudHooks';
import { useUserContext } from 'contexts/UserContext';

export interface RemoveFraudAlertModalProps {
  alert: PotentialFraudAlert;
  submissionUuid: string;
  onSuccess: (submissionUuid: string) => Promise<void>;
}

export const RemoveFraudAlertModal = ({
  alert,
  submissionUuid,
  onSuccess,
}: RemoveFraudAlertModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const user = useUserContext();

  const [
    createFraudDecision,
    {
      responseReady: createFraudDecisionResponseReady,
      loading: createFraudDecisionLoading,
      error: createFraudDecisionError,
    },
  ] = useCreatePotentialFraudDecision();

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (createFraudDecisionResponseReady && !createFraudDecisionError) {
      void onSuccess(submissionUuid);
      setIsModalOpen(false);
    }
  }, [
    createFraudDecisionResponseReady,
    createFraudDecisionError,
    onSuccess,
    submissionUuid,
  ]);

  return (
    <Modal
      trigger={<Button variant={'danger'}>Remove from List</Button>}
      isOpen={isModalOpen}
      title="Remove Fraud Alert from List"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {createFraudDecisionError && (
            <Banner dismissable={false}>
              {createFraudDecisionError.message}
            </Banner>
          )}

          <Subheading variant="page">
            <Icon name="circle-exclamation" /> You are about to remove this{' '}
            {alert.type === 'payment_issue_alert' ? 'Payment Issues ' : ''}
            fraud alert from the list. Are you sure?
          </Subheading>

          <Text size="l">This cannot be undone by user action.</Text>

          <Flex justifyContent={'center'} flexDirection={'row'} gap={4} mt={5}>
            <Button
              startIcon="times"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancel
            </Button>
            <Flex gap={1}>
              <Button
                onClick={() =>
                  createFraudDecision({
                    alertId: alert.id,
                    userId: user.id,
                    status: 'disconfirmed',
                  })
                }
                variant="danger"
                disabled={createFraudDecisionLoading}
              >
                Remove
              </Button>
              {createFraudDecisionLoading && <Loading size="small" />}
            </Flex>
          </Flex>
        </Box>
      }
    />
  );
};
