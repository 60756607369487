import React from 'react';
import {
  Box,
  Subheading,
  List,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const AccountNumberPopover = (): JSX.Element => {
  return (
    <Popover
      header="Account Number"
      content={
        <Box>
          <Subheading variant="subsection">
            Locating the Account Number
          </Subheading>
          <List>
            <List.Item>
              Use the Business Checking Account as the Account Number.
            </List.Item>
            <List.Item>
              If the Account Number is incomplete, look for a MTD or the Voided
              Check to complete it.
            </List.Item>
            <List.Item>
              For Credit Union accounts always use the Account Number, and if
              not available, use the Member Number.
            </List.Item>
            <List.Item>
              The Main Account is the one that has payments to a MCA. If there
              are no payments to a MCA: choose the account that has more
              revenue. If both have payments to a MCA: choose the one that has
              more revenue.
            </List.Item>
            <List.Item>
              IOLTA or Savings Account is processed only if there&apos;s a
              Business Checking Account with insufficient revenue. IOLTA or
              Savings Account cannot be priced for revenue and they should
              always be treated as non-primary accounts.
            </List.Item>
            <List.Item>
              If the Business is located in the USA but the Bank Statements are
              from a foreign country, request the 3 most recent Bank Statements
              of a USA Business Checking Account from the ISO. If the ISO
              replies that the Customer doesn&apos;t have a USA Bank Account we
              are going to Auto-Decline the Submission for foreign business.
            </List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="Account Number more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
