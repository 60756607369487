import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  IsoDropdownResponse,
  IsoResponse,
} from '../../types/api/funding/types';
import { fetchIsoByUuid, fetchIsos } from 'api/funding/isoFetchUtils';
import { useCallback } from 'react';

export type UseApiIsosResponse = UseGenericQueryResponse<IsoResponse[]>;

export const useApiIsos = (isoUuids?: string[]): UseApiIsosResponse => {
  const fetchIsosByUuid = useCallback(() => {
    if (!isoUuids) {
      return undefined;
    }

    return Promise.all(isoUuids.map((id) => fetchIsoByUuid(id)));
  }, [isoUuids]);

  return useGenericQuery<IsoResponse[]>(fetchIsosByUuid);
};

export type UseApiIsoResponse = UseGenericQueryResponse<IsoResponse>;
export const useGetApiIso = (isoUuid?: string): UseApiIsoResponse => {
  const fetcher = useCallback(() => {
    if (!isoUuid) {
      return undefined;
    }

    return fetchIsoByUuid(isoUuid);
  }, [isoUuid]);

  return useGenericQuery<IsoResponse>(fetcher);
};

export type UseGetFetchPartnersResponse = UseGenericQueryResponse<
  IsoDropdownResponse[]
>;

export const useApiGetIsos = (): UseGetFetchPartnersResponse => {
  const fetcher = useCallback(() => {
    return fetchIsos();
  }, []);

  return useGenericQuery<IsoDropdownResponse[]>(fetcher);
};
