import React from 'react';
import { Box, List, Subheading } from '@forward-financing/fast-forward';

export const BusinessRiskPopoverContent = (): JSX.Element => (
  <Box>
    <Subheading variant="subsection">Factors</Subheading>
    <List>
      <List.Item>Time in Business</List.Item>
      <List.Item>Business Tradeline Payment history</List.Item>
    </List>
  </Box>
);
