import React from 'react';
import { Table } from '@forward-financing/fast-forward';

import { TradelineDetails } from '../types';
import { TradelineCurrentStatusCell } from '../common/TradelineCurrentStatusCell';
import { TradelineCreditLoanTypeCell } from '../common/TradelineCreditLoanTypeCell';

type TradelineOverviewTableProps = {
  tradeline: TradelineDetails;
};

export const TradelineOverviewTable = ({
  tradeline,
}: TradelineOverviewTableProps): JSX.Element => {
  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>Account Name</Table.RowHeader>
          <Table.Cell columnWidth="small">{tradeline.name}</Table.Cell>
          <Table.RowHeader>Current Status</Table.RowHeader>
          <TradelineCurrentStatusCell {...tradeline} />
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Open Date</Table.RowHeader>
          <Table.Cell columnWidth="small">{tradeline.openDate}</Table.Cell>
          <Table.RowHeader>Rating Type</Table.RowHeader>
          <Table.Cell columnWidth="small">{tradeline.ratingType}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Credit Loan Type</Table.RowHeader>
          <TradelineCreditLoanTypeCell {...tradeline} />
          <Table.RowHeader>Hight Credit Amount</Table.RowHeader>
          <Table.Cell columnWidth="small">{tradeline.creditLimit}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Account Status Type</Table.RowHeader>
          <Table.Cell columnWidth="small">
            {tradeline.accountStatusType}
          </Table.Cell>
          <Table.RowHeader>Account Balance</Table.RowHeader>
          <Table.Cell columnWidth="small">
            {tradeline.accountBalance}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Status</Table.RowHeader>
          <Table.Cell columnWidth="small" overflowStrategy="wrap">
            {tradeline.paymentStatus}
          </Table.Cell>
          <Table.RowHeader>Monthly Payment</Table.RowHeader>
          <Table.Cell columnWidth="small">
            {tradeline.monthlyPayment}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Responsibility</Table.RowHeader>
          <Table.Cell columnWidth="small">
            {tradeline.responsibility}
          </Table.Cell>
          <Table.RowHeader />
          <Table.Cell columnWidth="small" />
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
