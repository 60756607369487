import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Card,
  Grid,
  List,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';
import { fetchRiskFactors } from './riskFactorsContainerFetchUtils';
import { RiskFactors } from './riskFactorsContainer.types';

export interface RiskFactorsContainerProps {
  submissionUuid: string;
}

export const RiskFactorsContainer = ({
  submissionUuid,
}: RiskFactorsContainerProps): JSX.Element => {
  const [riskFactorError, setRiskFactorError] = useState<string | undefined>();

  const [riskFactors, setRiskFactors] = useState<RiskFactors | undefined>(
    undefined
  );

  useEffect(() => {
    const loadRiskFactors = async (): Promise<void> => {
      try {
        const response = await fetchRiskFactors(submissionUuid);
        setRiskFactors(response);
        setRiskFactorError(undefined);
      } catch (e: unknown) {
        const error = toError(e);
        setRiskFactorError(error.message);
      }
    };

    void loadRiskFactors();
  }, [submissionUuid]);

  const previousAutoDeclinesArray: string[] = riskFactors
    ? (Object.values(riskFactors.previousAutoDeclines).filter(
        Boolean
      ) as string[])
    : [];

  const previousAdvanceCollectionStatusArray: string[] = riskFactors
    ? (Object.values(riskFactors.previousAdvanceCollectionStatus).filter(
        Boolean
      ) as string[])
    : [];

  const highRiskQualitiesArray: string[] = riskFactors
    ? (Object.values(riskFactors.highRiskQualities).filter(Boolean) as string[])
    : [];

  return (
    <Card title="Risk Factors">
      {riskFactorError && (
        <Banner dismissable={false} variant="error">
          {riskFactorError}
        </Banner>
      )}
      <Box>
        {riskFactors && (
          <Grid gutter>
            <Grid.Item xs={12} l={6} xl={3}>
              <Box pt={2} bg="red-500" />
              <Subheading variant="subsection">
                Previous Auto-Decline
              </Subheading>
              {previousAutoDeclinesArray.length > 0 ? (
                <List>
                  {previousAutoDeclinesArray.map((riskFactor) => (
                    <List.Item key={riskFactor}>{riskFactor}</List.Item>
                  ))}
                </List>
              ) : (
                <Text italic>None</Text>
              )}
            </Grid.Item>
            <Grid.Item xs={12} l={6} xl={3}>
              <Box pt={2} bg="orange-600" />
              <Subheading variant="subsection">High Risk Qualities</Subheading>
              {highRiskQualitiesArray.length > 0 ? (
                <List>
                  {highRiskQualitiesArray.map((riskFactor) => (
                    <List.Item key={riskFactor}>{riskFactor}</List.Item>
                  ))}
                </List>
              ) : (
                <Text italic>None</Text>
              )}
            </Grid.Item>
            <Grid.Item xs={12} l={6} xl={3}>
              <Box pt={2} bg="orange-300" />
              <Subheading variant="subsection">
                Previous Advance with Collection Status
              </Subheading>
              {previousAdvanceCollectionStatusArray.length > 0 ? (
                <List>
                  {previousAdvanceCollectionStatusArray.map((riskFactor) => (
                    <List.Item key={riskFactor}>{riskFactor}</List.Item>
                  ))}
                </List>
              ) : (
                <Text italic>None</Text>
              )}
            </Grid.Item>
            <Grid.Item xs={12} l={6} xl={3}>
              <Box pt={2} bg="orange-100" />
              <Subheading variant="subsection">Fraud Alert</Subheading>
              {riskFactors.potentialFraud ? (
                <List>
                  <List.Item key={'potential-fraud'}>
                    {riskFactors.potentialFraud}
                  </List.Item>
                </List>
              ) : (
                <Text italic>None</Text>
              )}
            </Grid.Item>
          </Grid>
        )}
      </Box>
    </Card>
  );
};
