import { fetchDeclineDrivers } from 'api/underwriting/declineDriversFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { DeclineDriversResponse } from 'types/api/underwriting/types';

export type UseGetApiDeclineDriversResponse =
  UseGenericQueryResponse<DeclineDriversResponse>;

export const useGetApiDeclineDrivers = (
  submissionUuid?: string
): UseGetApiDeclineDriversResponse => {
  const loadData = useCallback(() => {
    return fetchDeclineDrivers(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};
