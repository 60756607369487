import React from 'react';
import { Banner, Card } from '@forward-financing/fast-forward';
import { UnderwritingAttachmentManager } from './UnderwritingAttachmentManager';
import { useSubmission } from '../attachmentManagerHooks';

export interface AttachmentManagerCardProps {
  submissionUuid: string;
}

export const AttachmentManagerCard = ({
  submissionUuid,
}: AttachmentManagerCardProps): JSX.Element => {
  const {
    submission,
    error: errorSubmission,
    refetch: refetchSubmission,
  } = useSubmission(submissionUuid);
  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false}>{errorSubmission.message}</Banner>
      )}
      <Card title="ISO Attachments" collapsible={true}>
        <UnderwritingAttachmentManager
          submission={submission}
          refetchSubmission={refetchSubmission}
          submissionUuid={submissionUuid}
        />
      </Card>
    </>
  );
};
