import { fetchAttachmentsForApplication } from 'api/waitingRoom/attachmentFetchUtils';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { AttachmentsResponse } from 'types/api/waitingRoom/types';

export type UseApiAttachmentsResponse =
  UseGenericQueryResponse<AttachmentsResponse>;

export const useApiGetAttachmentsForApplication = (
  applicationUuid?: string
): UseApiAttachmentsResponse => {
  const fetchData = useCallback(() => {
    if (!applicationUuid) {
      return undefined;
    }

    return fetchAttachmentsForApplication(applicationUuid);
  }, [applicationUuid]);

  return useGenericQuery(fetchData);
};
