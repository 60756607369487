import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiCustomer } from 'apiHooks/underwriting/customerFetchHooks';
import { UccOwnerResponse } from 'types/api/underwriting/types';
import {
  useGetApiOwner,
  useGetApiOwnerUccReport,
} from 'apiHooks/underwriting/ownerFetchHooks';
import { Customer, Owner, UccReport } from './uccReportPage.types';

export const useCustomer = (
  customerUuid?: string
): UseGenericQueryResponse<Customer> => {
  return useGenericFeatureQuery(
    useGetApiCustomer,
    (customer) => ({ legalName: customer?.legal_name }),
    customerUuid
  );
};

export const useOwner = (
  ownerUuid?: string
): UseGenericQueryResponse<Owner> => {
  return useGenericFeatureQuery(
    useGetApiOwner,
    (owner) => ({ firstName: owner?.first_name, lastName: owner?.last_name }),
    ownerUuid
  );
};

const toUccReport = (uccResponse: UccOwnerResponse): UccReport => {
  return {
    filingDate: uccResponse.filing_date ?? undefined,
    securedParties: uccResponse.secured_parties ?? [],
    debtors: uccResponse.debtors ?? [],
    status: uccResponse.status ?? undefined,
    fileType: uccResponse.file_type ?? undefined,
    fileNumber: uccResponse.file_number,
    state: uccResponse.state,
    collateralDescription: uccResponse.collateral_description ?? undefined,
    closed: uccResponse.closed,
  };
};

export const useOwnerUccReport = (
  submissionUuid?: string,
  ownerUuid?: string,
  reportId?: string
): UseGenericQueryResponse<UccReport[]> => {
  return useGenericFeatureQuery(
    useGetApiOwnerUccReport,
    (data) => data?.uccs.map(toUccReport),
    { ownerUuid, submissionUuid, reportId }
  );
};
