import {
  Banner,
  Box,
  Card,
  Heading,
  PageContainer,
} from '@forward-financing/fast-forward';
import React from 'react';
import { PrequalAttachmentManager } from './PrequalAttachmentManager';
import { useAccount } from '../attachmentManagerHooks';

export interface PrequalAttachmentManagerContainerProps {
  applicationUuid: string;
}

export const PrequalAttachmentManagerContainer = ({
  applicationUuid,
}: PrequalAttachmentManagerContainerProps): JSX.Element => {
  const { data: account, error: accountError } = useAccount(applicationUuid);

  return (
    <PageContainer>
      <Box mt={3}>
        {accountError && (
          <Banner dismissable={false}>{accountError.message}</Banner>
        )}

        <Heading>{account?.name}</Heading>
        <Card title="ISO Attachments">
          <PrequalAttachmentManager applicationUuid={applicationUuid} />
        </Card>
      </Box>
    </PageContainer>
  );
};
