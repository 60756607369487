import { Box, PageContainer } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AttachmentManagerCard } from './AttachmentManagerCard';

type Params = {
  submissionUuid: string;
};

export const NewAttachmentRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;
  return (
    <PageContainer>
      <Box py={4}>
        <AttachmentManagerCard submissionUuid={submissionUuid} />
      </Box>
    </PageContainer>
  );
};
