/**
 * A mapping of ledger program options to their corresponding names.
 * The keys represent the program option identifiers, and the values
 * represent the human-readable names for those options.
 *
 * @example
 * ```typescript
 * const optionName = LEDGER_PROGRAM_OPTIONS_MAPPING['34']; // 'Starter'
 * ```
 */
export const LEDGER_PROGRAM_OPTIONS_MAPPING: Record<string, string> = {
  '34': 'Starter',
  '1': 'High Risk',
  '2': 'Standard',
  '3': 'Low Risk',
  '4': 'Premium',
};

/**
 * Amount of month starting from ledger.created_at date.
 * For each item ColumnHeader will be generated and rendered in the table.
 */
export const LEDGER_TABLES_MONTHS = [
  3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
];

/**
 * Name for each month idx. 0-based
 */
export const LEDGER_TABLES_IDX_MONTH_NAME_MAP: Record<string, string> = {
  '0': 'Jan',
  '1': 'Feb',
  '2': 'Mar',
  '3': 'Apr',
  '4': 'May',
  '5': 'Jun',
  '6': 'Jul',
  '7': 'Aug',
  '8': 'Sep',
  '9': 'Oct',
  '10': 'Nov',
  '11': 'Dec',
};
