import React from 'react';
import { useGetUser } from '../applicationSnapshotFetchHooks';
import { userFullName } from 'helpers/utils';

export type UserFirstLastTextProps = {
  userId: string | number;
};

export const UserFullNameText = ({
  userId,
}: UserFirstLastTextProps): JSX.Element => {
  const { data: user, error: userError } = useGetUser(userId);

  if (userError) {
    return <>{userError.message}</>;
  }

  return <>{user ? userFullName(user.firstName, user.lastName) : 'N/A'}</>;
};
