import React, { useState } from 'react';
import { z } from 'zod';

import {
  Banner,
  Button,
  Flex,
  Form,
  Grid,
  MaskedTextInput,
  Select,
  Text,
  TextInput,
} from '@forward-financing/fast-forward';

import { defaultTo } from 'lodash';
import {
  validateFein,
  validateZipCode,
} from '../../../helpers/validations/FieldValidator';
import { useGetStates } from '../../SubmissionEditing/SubmissionEditingHooks';
import {
  BusinessManualSearchFetcher,
  BusinessManualSearchQueryParams,
  SearchType,
} from './businessManualSearch.types';

const paynetSchema = z.object({
  companyName: z.string().optional(),
  fein: z
    .string()
    .optional()
    .refine(
      (fein) =>
        fein && fein.trim() !== '' ? validateFein(fein).isValid : true,
      {
        message: 'Invalid FEIN.',
      }
    ),
  city: z.string().optional(),
  state: z.string().optional(),
});

const lexisNexisSchema = paynetSchema.extend({
  phone: z.string().regex(/^\d*$/, 'Phone must be numeric').optional(),
  street1: z.string().optional(),
  zip: z
    .string()
    .optional()
    .refine((zip) => (zip ? validateZipCode(zip).isValid : true), {
      message: 'Invalid Zip Code.',
    }),
});

// Using lexisNexisSchema here since it encompasses all fields from both schemas.
type FormSchemaError = Partial<z.inferFormattedError<typeof lexisNexisSchema>>;

export type BusinessManualSearchFormProps = {
  fetcher: BusinessManualSearchFetcher;
  loading: boolean;
  searchType: SearchType;
  submissionUuid?: string;
};

export const BusinessManualSearchForm = ({
  fetcher,
  loading,
  searchType,
  submissionUuid,
}: BusinessManualSearchFormProps): JSX.Element => {
  const [formValues, setFormValues] = useState<BusinessManualSearchQueryParams>(
    {
      companyName: '',
      fein: '',
      phone: '',
      street1: '',
      city: '',
      state: '',
      zip: '',
    }
  );

  // The validation schema is determined by the search type, since
  // the fields accepted by each search type are different.
  const formValidationSchema = (
    searchType === 'lexisNexis' ? lexisNexisSchema : paynetSchema
  ).refine((data) => Object.values(data).some((value) => !!value), {
    message: 'At least one field must be filled.',
  });

  const [errors, setErrors] = useState<FormSchemaError>({});
  const { data: states, error: statesError } = useGetStates();

  const handleChange = (
    name: keyof NonNullable<BusinessManualSearchQueryParams>,
    value: string
  ): void => {
    setFormValues({ ...formValues, [name]: value });
  };

  const isValidForm = (): boolean => {
    const validationResult = formValidationSchema.safeParse(formValues);
    if (validationResult.success) {
      setErrors({});
    } else {
      setErrors(validationResult.error.format());
    }
    return validationResult.success;
  };

  const handleSubmit = async (): Promise<void> => {
    if (isValidForm() && !!submissionUuid) {
      await fetcher(submissionUuid, formValues);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      accessibleName="Business report search page"
      allowImplicitSubmission={false}
    >
      {({ fireSubmit }) => (
        <>
          {statesError && <Banner dismissable>{statesError?.message}</Banner>}
          <Grid gutter>
            <Grid.Item s={12} m={3} l={3}>
              <TextInput
                name="companyName"
                label="Company Name"
                value={formValues.companyName}
                onValueChange={(newVal) => handleChange('companyName', newVal)}
                errorMessage={errors?.companyName?._errors.join(' ')}
              />
            </Grid.Item>

            <Grid.Item s={12} m={3} l={3}>
              <MaskedTextInput
                name="fein"
                label="FEIN"
                type="text"
                value={formValues.fein}
                format={'##-#######'}
                mask={''}
                onValueChange={(newVal) => handleChange('fein', newVal.value)}
                errorMessage={errors?.fein?._errors.join(' ')}
              />
            </Grid.Item>

            {searchType === 'lexisNexis' && (
              <Grid.Item s={12} m={3} l={3}>
                <MaskedTextInput
                  name="phone"
                  label="Phone"
                  value={formValues.phone}
                  onValueChange={(newVal) =>
                    handleChange('phone', newVal.value)
                  }
                  errorMessage={errors?.phone?._errors.join(' ')}
                  format={'(###) ###-####'}
                />
              </Grid.Item>
            )}

            {searchType === 'lexisNexis' && (
              <Grid.Item s={12} m={3} l={3}>
                <TextInput
                  name="street1"
                  label="Street 1"
                  value={formValues.street1}
                  onValueChange={(newVal) => handleChange('street1', newVal)}
                  errorMessage={errors?.street1?._errors.join(' ')}
                />
              </Grid.Item>
            )}

            <Grid.Item s={12} m={3} l={3}>
              <TextInput
                name="city"
                label="City"
                value={formValues.city}
                onValueChange={(newVal) => handleChange('city', newVal)}
                errorMessage={errors?.city?._errors.join(' ')}
              />
            </Grid.Item>

            <Grid.Item s={12} m={3} l={3}>
              <Select
                name="state"
                label="State"
                options={defaultTo(states, [])}
                value={defaultTo(formValues.state, '')}
                onValueChange={(newVal) => handleChange('state', newVal)}
                errorMessage={errors?.state?._errors.join(' ')}
              />
            </Grid.Item>

            {searchType === 'lexisNexis' && (
              <Grid.Item s={12} m={3} l={3}>
                <MaskedTextInput
                  name="zip"
                  type="number"
                  label="Zip"
                  value={formValues.zip}
                  onValueChange={(newVal) => handleChange('zip', newVal.value)}
                  format={'##### ####'}
                  mask={''}
                  errorMessage={errors?.zip?._errors.join(' ')}
                />
              </Grid.Item>
            )}
          </Grid>

          <Flex mt={3} gap={3} justifyContent={'right'} alignItems={'center'}>
            {errors._errors && (
              <Text color="danger">{errors._errors.join(' ')}</Text>
            )}

            <Button onClick={fireSubmit} disabled={loading}>
              {loading ? 'Searching... ' : 'Search'}
            </Button>
          </Flex>
        </>
      )}
    </Form>
  );
};
