import React from 'react';
import { Currency, Table } from '@forward-financing/fast-forward';

import { BusinessPaynet } from './businessPaynet.types';

type BusinessPaynetSummaryTableProps = {
  data: BusinessPaynet['businessSummary'];
};

export const BusinessPaynetSummaryTable = ({
  data,
}: BusinessPaynetSummaryTableProps): JSX.Element => {
  return (
    <Table data-test-id={'paynet-business-summary-table'}>
      <Table.Head>
        <Table.ColumnHeader>Company Name</Table.ColumnHeader>
        <Table.ColumnHeader>Address / Phone</Table.ColumnHeader>
        <Table.ColumnHeader>Paynet MasterScore v2</Table.ColumnHeader>
        <Table.ColumnHeader>Percentile</Table.ColumnHeader>
        <Table.ColumnHeader>Tax ID</Table.ColumnHeader>
        <Table.ColumnHeader>Total Outstanding Receivable</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{data.companyName}</Table.Cell>
          <Table.Cell>{data.address}</Table.Cell>
          <Table.Cell>{data.paynetMasterScorev2}</Table.Cell>
          <Table.Cell>{data.percentile}</Table.Cell>
          <Table.Cell>{data.taxId}</Table.Cell>
          <Table.Cell>
            <Currency amount={Number(data.totalOutstandingReceivable)} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
