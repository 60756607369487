import React, { useCallback, useEffect, useState } from 'react';
import { Table, Card, Dropdown, Banner } from '@forward-financing/fast-forward';
import { IndustryRisk } from './IndustryRisk.types';
import { fetchAllIndustryRisks } from './IndustryRiskFetchUtils';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { toError } from 'helpers/errorUtils';
import { IndustryRiskEditModal } from './IndustryRiskEditModal';
import { IndustryRiskCreateModal } from './IndustryRiskCreateModal';
import { IndustryRiskAuditLogModal } from './IndustryRiskAuditLogModal';

export interface IndustryRiskColorInterface {
  name: string;
  color: ColorVariant;
  displayName: string;
}

export const industryRiskColors: IndustryRiskColorInterface[] = [
  {
    name: 'low_risk',
    color: 'green-200',
    displayName: 'Low Risk',
  },
  {
    name: 'moderate_risk',
    color: 'orange-200',
    displayName: 'Moderate Risk',
  },
  {
    name: 'high_risk',
    color: 'orange-600',
    displayName: 'High Risk',
  },
  {
    name: 'prohibited',
    color: 'red-300',
    displayName: 'Prohibited',
  },
  {
    name: 'archived',
    color: 'gray-400',
    displayName: 'Archived',
  },
];

export const getIndustryRiskColorObject = (
  name: string,
  archived: boolean | null
): IndustryRiskColorInterface | undefined => {
  if (archived) {
    return industryRiskColors.find((entry) => entry.name === 'archived');
  }
  return industryRiskColors.find((entry) => entry.name === name);
};

export const IndustryRiskTable = (): JSX.Element => {
  const [industries, setIndustries] = useState<IndustryRisk[]>([]);
  const [modalIndustries, setModalIndustries] = useState<IndustryRisk[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleError = (e: unknown): void => {
    const err = toError(e);
    setError(err.toString());
  };

  const fetchIndustryRisks = useCallback(async () => {
    try {
      const industryRisks = await fetchAllIndustryRisks();
      setIndustries(
        JSON.parse(JSON.stringify(industryRisks)) as IndustryRisk[]
      );
      setModalIndustries(
        JSON.parse(JSON.stringify(industryRisks)) as IndustryRisk[]
      );
    } catch (err) {
      handleError(err);
    }
  }, []);

  const handleModalOnOpenChange = (): void => {
    // if the modal was open and now its closing make sure we reset the modal to the current
    // state of the main industry risk table
    if (isModalOpen) {
      setModalIndustries(
        JSON.parse(JSON.stringify(industries)) as IndustryRisk[]
      );
    }

    // close the modal
    setIsModalOpen((currentIsModalOpen) => !currentIsModalOpen);
  };

  useEffect(() => {
    void fetchIndustryRisks();
  }, [fetchIndustryRisks]);

  const refreshIndustryRisks = (): void => {
    void fetchIndustryRisks();
  };

  return (
    <Card
      title="Industry Risk"
      actions={
        <Dropdown label="Actions">
          <Dropdown.ActionItem
            id="1"
            text="Edit Industry Risks"
            onSelect={() =>
              setIsModalOpen((currIsModalShowing) => !currIsModalShowing)
            }
          />
          <Dropdown.ActionItem
            id="2"
            text="Add Industry Risk"
            onSelect={() =>
              setIsCreateModalOpen((isCreateModalOpen) => !isCreateModalOpen)
            }
          />
        </Dropdown>
      }
    >
      <IndustryRiskEditModal
        industries={modalIndustries}
        isOpen={isModalOpen}
        onOpenChange={handleModalOnOpenChange}
        afterSave={refreshIndustryRisks}
      />

      <IndustryRiskCreateModal
        isOpen={isCreateModalOpen}
        onOpenChange={() =>
          setIsCreateModalOpen((isCreateModalOpen) => !isCreateModalOpen)
        }
        afterSave={refreshIndustryRisks}
      />

      {error.length > 0 && <Banner>{error}</Banner>}

      <Table caption={'Industry Risk Table'}>
        <Table.Head>
          <Table.ColumnHeader>Industry Type</Table.ColumnHeader>
          <Table.ColumnHeader>Industry Risk</Table.ColumnHeader>
          <Table.ColumnHeader>Actions</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {industries &&
            industries.map((industry) => (
              <Table.Row key={industry.id}>
                <Table.Cell>{industry.name}</Table.Cell>
                <Table.Cell
                  backgroundColor={
                    getIndustryRiskColorObject(
                      industry.pricing_risk_profile?.replace(' ', '_'),
                      Boolean(industry.archived)
                    )?.color
                  }
                >
                  {
                    getIndustryRiskColorObject(
                      industry.pricing_risk_profile?.replace(' ', '_'),
                      Boolean(industry.archived)
                    )?.displayName
                  }
                </Table.Cell>
                <Table.Cell columnWidth="small">
                  <IndustryRiskAuditLogModal industryRisk={industry} />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </Card>
  );
};
