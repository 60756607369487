import { pullExperianCommercial } from 'api/3pi/experianCommercialFetchUtils';
import {
  useGenericMutation,
  UseGenericMutationResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';

export type UsePullExperianCommercialArgs = {
  customerUuid: string;
  submissionUuid: string;
  street: string;
  city: string;
  /**
   * 2 character code for a state: "FL" for Florida
   */
  state: string;
  zip: string;
  name?: string;
  fein?: string;
};

export type UsePullExperianCommercialResponse = UseGenericMutationResponse<
  { success: true },
  UsePullExperianCommercialArgs
>;

export const usePullExperianCommercial =
  (): UsePullExperianCommercialResponse => {
    const fetchFunction = useCallback((args: UsePullExperianCommercialArgs) => {
      return pullExperianCommercial({
        customer_uuid: args.customerUuid,
        submission_uuid: args.submissionUuid,
        name: args.name,
        street: args.street,
        city: args.city,
        state: args.state,
        zip: args.zip,
        tax_id: args.fein,
      });
    }, []);

    return useGenericMutation(fetchFunction);
  };
