import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { CRM_GATEWAY_BASE_URL } from 'constants/globals';
import { CoreSalesforceMapResponse } from 'types/api/crmGateway/types';

export const fetchCoreSalesforceMap = async (
  salesforceId: string
): Promise<CoreSalesforceMapResponse> => {
  // istanbul ignore next - convenience for local development where CRMGW doesn't work well
  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve({
      core_id: salesforceId,
      object_type: 'submission',
      salesforce_id: salesforceId,
    });
  }

  const url = new URL(
    `/api/v2/core_salesforce_maps/${salesforceId}`,
    CRM_GATEWAY_BASE_URL()
  );
  url.searchParams.append('object_type', 'submission');

  const response = await makeInternalAPIRequest<CoreSalesforceMapResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Non 2XX response from crm gateway`
    );
  }

  return response.json();
};
