import { fetchExceptionRequestDeclineReasons } from 'api/banking/exceptionRequestDeclineReasonsFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { DeclineReasonsResponse } from 'types/api/banking/types';

export type UseApiDeclineReasons =
  UseGenericQueryResponse<DeclineReasonsResponse>;

export const useApiDeclineReasons = (): UseApiDeclineReasons => {
  return useGenericQuery(fetchExceptionRequestDeclineReasons);
};
