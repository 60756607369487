import React from 'react';
import { Currency, Subheading, Table } from '@forward-financing/fast-forward';
import { displayPercentage } from '../../helpers/utils';
import { Prepayment } from '../../api/BankingClient/codecs';

export interface AdvancePrepaymentScheduleProps {
  opportunityUuid: string;
  prepaymentSchedule: Prepayment[];
}

export const AdvancePrepaymentSchedule = (
  props: AdvancePrepaymentScheduleProps
): JSX.Element => {
  return (
    <>
      <Subheading>Prepayment Schedule</Subheading>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Term</Table.ColumnHeader>
          <Table.ColumnHeader>Factor Rate</Table.ColumnHeader>
          <Table.ColumnHeader>Purchased Amount</Table.ColumnHeader>
          <Table.ColumnHeader>Savings Amount</Table.ColumnHeader>
          <Table.ColumnHeader>Savings Percent</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {props.prepaymentSchedule.map((prepayment) => (
            <Table.Row key={prepayment.term}>
              <Table.Cell>{prepayment.term * 1.5}</Table.Cell>
              <Table.Cell>{prepayment.factor_rate}</Table.Cell>
              <Table.Cell>
                <Currency amount={prepayment.purchase_amount} />
              </Table.Cell>
              <Table.Cell>
                <Currency amount={prepayment.savings} />
              </Table.Cell>
              <Table.Cell>
                {displayPercentage(prepayment.savings_percent * 100.0)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
