import React, { useCallback, useMemo, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  DescriptionList,
  Divider,
  Flex,
  Grid,
  Icon,
  Loading,
  Modal,
  Subheading,
  Text,
  TextArea,
  TextInput,
} from '@forward-financing/fast-forward';
import { defaultTo, isEqual } from 'lodash';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { isAfterUnderwriting } from 'helpers/utils';
import { useNewDealScoringContext } from '../NewDealScoring/NewDealScoringContext';
import { Submission } from '../DealScoringContainer.types';
import { useScoringComposite } from '../DealScoringFetchHooks';
import { StipulationManager } from '../../StipulationManager/StipulationManager';
import { RevenueSummaryTable } from './RevenueSummaryTable';
import { Ledger } from './ledger.types';
import { ScoresContainerBase } from './ScoresContainerBase';
import { LedgerToolbox } from './LedgerToolbox';
import { OffersContainer } from './Offers/OffersContainer';
import { BuyRatesContainer } from './BuyRates/BuyRatesContainer';
import {
  useGetOverriddenValidations,
  useGetUser,
  useValidationOverride,
} from './ledgerHooks';
import { useLedgersValidationContext } from './LedgerValidationContext/LedgerValidationContext';
import { OverrideValidationCard } from './OverrideValidation/OverrideValidationCard';

export type LedgerContainerProps = {
  ledger: Ledger;
  currentSubmissionUuid: string;
  submission: Submission;
  refetchLedgers: () => void;
  isRenewal?: boolean;
};

export const areAllElementsInArray = (
  sourceArray: string[] = [],
  targetArray: string[] = []
): boolean => {
  const lowerCaseBSet = new Set(targetArray.map((item) => item.toLowerCase()));

  return sourceArray.every((item) => lowerCaseBSet.has(item.toLowerCase()));
};

export const LedgerContainer = ({
  ledger,
  currentSubmissionUuid,
  submission,
  refetchLedgers,
  isRenewal,
}: LedgerContainerProps): JSX.Element => {
  const {
    ledgerToUpdate,
    setLedgerToUpdate,
    isProgramExplanationInvalid,
    isProgramExplanationRequired,
  } = useLedgersValidationContext();

  const [successText, setSuccessText] = useState<string | undefined>('');
  const [isOverrideValidationModalOpen, setIsOverrideValidationModalOpen] =
    useState(false);
  const [isFormNotSaved, setIsFormNotSaved] = useState<boolean>(false);
  const [overrideNotes, setOverrideNotes] = useState('');

  const {
    data: compositeScore,
    loading: compositeScoreLoading,
    error: compositeScoreError,
  } = useScoringComposite(submission.uuid);

  const {
    data: overriddenValidations,
    loading: overriddenValidationsLoading,
    error: overriddenValidationsError,
    refetch: overriddenValidationsRefetch,
  } = useGetOverriddenValidations(submission.uuid);

  const [
    overrideValidations,
    { loading: overrideValidationsLoading, error: overrideValidationsError },
  ] = useValidationOverride();

  const { response } = useNewDealScoringContext();

  const handleChangeLedger = useCallback(
    (toUpdate: Partial<Ledger>): void => {
      setLedgerToUpdate((prevLedger) => {
        const updatedLedger = { ...prevLedger, ...toUpdate };
        setIsFormNotSaved(false);
        if (!isEqual(updatedLedger, ledger)) {
          setIsFormNotSaved(true);
        }
        return updatedLedger;
      });
    },
    [ledger, setLedgerToUpdate]
  );

  const handleAction = async (
    action: () => Promise<MutationResponse>,
    successMessage: string,
    afterFetchAction?: () => void
  ): Promise<void> => {
    setSuccessText(undefined);

    const { success } = await action();

    if (success) {
      setSuccessText(successMessage);
      refetchLedgers?.();
      afterFetchAction?.();
      setIsFormNotSaved(false);
    }
  };

  const handleSendLedger = async (
    patchLedger: () => Promise<MutationResponse>,
    successMessage: string,
    closeConfirmationModal: () => void
  ): Promise<void> => {
    setSuccessText(undefined);

    const { success: actionSuccess } = await patchLedger();

    overriddenValidationsRefetch();
    refetchLedgers();
    closeConfirmationModal();

    if (actionSuccess) {
      setSuccessText(successMessage);
    }
  };

  const handleOverrideReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void =>
    handleChangeLedger({
      overrideBuyRatesReason: event.target.value,
    });

  const editIsEnabled = useMemo(
    () => !!ledgerToUpdate.overrideBuyRates && ledgerToUpdate.program !== 0,
    [ledgerToUpdate]
  );

  const handleOverrideValidations = async (): Promise<void> => {
    if (!submission) {
      return;
    }

    const { success } = await overrideValidations({
      submissionUuid: submission.uuid,
      updateBody: {
        note: overrideNotes,
        stage: submission.stage,
        missing_attributes: ledger.validationErrorMessage || [],
      },
    });

    if (success) {
      setIsOverrideValidationModalOpen(false);
      overriddenValidationsRefetch();
    }
  };

  const latestOverriddenValidation =
    overriddenValidations?.[overriddenValidations.length - 1];

  const { data: underwriter } = useGetUser(
    latestOverriddenValidation?.createdById
  );

  const isRenewalSubmission = submission.type === 'Renewal';
  const isInProcessingStage = submission?.stage === 'Processing';
  const isCurrentSubmission = submission?.uuid === currentSubmissionUuid;
  const shouldDisableToolbox =
    (submission && isAfterUnderwriting(submission.stage)) ||
    isInProcessingStage ||
    !isCurrentSubmission;
  const overrideValidationText = (): string =>
    ledger.validationErrorMessage && ledger.validationErrorMessage.length > 1
      ? 'Override validations'
      : 'Override validation';

  return (
    <Flex flexDirection={'column'} gap={3}>
      {successText && (
        <Box m={3}>
          <Banner variant="success" autoDismissTime={5000} dismissable={'auto'}>
            {successText}
          </Banner>
        </Box>
      )}

      {isFormNotSaved && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      {compositeScoreError && <Banner>{compositeScoreError.message}</Banner>}
      {overriddenValidationsError && (
        <Banner>{overriddenValidationsError.message}</Banner>
      )}

      <Modal
        trigger={<></>}
        isOpen={isOverrideValidationModalOpen}
        onOpenChange={() => {
          setIsOverrideValidationModalOpen((prev) => !prev);
        }}
        title={`${overrideValidationText()}?`}
        body={
          overriddenValidationsLoading ? (
            <Loading />
          ) : (
            <Flex gap={2} flexDirection="column" alignItems="flex-start">
              {overrideValidationsError && (
                <Banner>{overrideValidationsError.message}</Banner>
              )}

              <Icon name={'circle-exclamation'} color="danger" size="lg" />

              <Flex gap={1} flexDirection="column" alignItems="flex-start">
                <Text>
                  This submission cannot be sent until it has the following
                  data:
                </Text>

                {ledger.validationErrorMessage &&
                  ledger.validationErrorMessage.map((item) => (
                    <Text key={item}>- {item}</Text>
                  ))}
              </Flex>

              <TextArea
                label="Note"
                onChange={(e) => {
                  setOverrideNotes(e.target.value);
                }}
              />

              <Flex gap={2}>
                {overrideValidationsLoading ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={!overrideNotes}
                    onClick={handleOverrideValidations}
                  >
                    {overrideValidationText()}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setIsOverrideValidationModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          )
        }
      />

      <LedgerToolbox
        ledger={ledger}
        submissionUuid={submission.uuid}
        shouldDisableToolbox={shouldDisableToolbox}
        handleAction={handleAction}
        editIsEnabled={editIsEnabled}
        handleSendLedger={handleSendLedger}
        setIsOverrideValidationModalOpen={setIsOverrideValidationModalOpen}
      />

      <Grid gutter>
        <Grid.Item xl={6}>
          <OverrideValidationCard
            latestOverriddenValidation={latestOverriddenValidation}
            underwriter={underwriter}
          />

          {compositeScoreLoading && <Loading />}

          {!compositeScoreLoading &&
            compositeScore &&
            compositeScore.type === 'New Deal' && (
              <Flex gap={2}>
                <ScoresContainerBase
                  title="Manual Score"
                  currentScore={defaultTo(response?.averageScore, 0).toFixed(1)}
                  maxScore="5.0"
                  scores={[
                    {
                      scoreName: 'Owner Risk',
                      scoreValue: defaultTo(response?.ownerRisk, 0),
                    },
                    {
                      scoreName: 'Business Risk',
                      scoreValue: defaultTo(response?.businessRisk, 0),
                    },
                    {
                      scoreName: 'Quality of Cash Flows',
                      scoreValue: defaultTo(response?.qualityOfCashFlows, 0),
                    },
                  ]}
                />

                {!compositeScoreLoading && (
                  <ScoresContainerBase
                    title="Composite Score"
                    currentScore={defaultTo(
                      compositeScore.compositeScore?.score,
                      0
                    ).toFixed(1)}
                    maxScore={`Tier ${defaultTo(
                      compositeScore.compositeScore?.tier,
                      'N/A'
                    )}`}
                    scores={[
                      {
                        scoreName: 'Positions',
                        scoreValue: defaultTo(compositeScore.position.score, 0),
                      },
                      {
                        scoreName: 'Industry Risk',
                        scoreValue: defaultTo(
                          compositeScore.industryRisk.score,
                          0
                        ),
                      },
                      {
                        scoreName: 'FICO Score',
                        scoreValue: defaultTo(compositeScore.fico.score, 0),
                      },
                      {
                        scoreName: 'Time in Business',
                        scoreValue: defaultTo(
                          compositeScore.timeInBusiness.score,
                          0
                        ),
                      },
                      {
                        scoreName: 'Revenue Tier',
                        scoreValue: defaultTo(
                          compositeScore.trueRevenue.score,
                          0
                        ),
                      },
                      // Risk Score will be replacing Risk Decile end of October 2024.
                      // The fetched data will be returning either a `riskScore` for more recent submissions
                      // or a `riskDecile` for older submissions.
                      {
                        scoreName: compositeScore?.riskDecile
                          ? 'Risk Decile'
                          : 'Risk Score',
                        scoreValue: defaultTo(
                          compositeScore?.riskDecile
                            ? compositeScore?.riskDecile?.score
                            : compositeScore?.riskScore?.score,
                          0
                        ),
                      },
                    ]}
                  />
                )}
              </Flex>
            )}

          {!compositeScoreLoading &&
            compositeScore &&
            compositeScore.type === 'Renewal' && (
              <ScoresContainerBase
                title="Composite Score"
                currentScore={defaultTo(
                  compositeScore.compositeScore?.score,
                  0
                ).toFixed(1)}
                maxScore={`Tier ${defaultTo(
                  compositeScore.compositeScore?.tier,
                  'N/A'
                )}`}
                scores={[
                  {
                    scoreName: "Number of X's renewed",
                    scoreValue: compositeScore.timesFunded.score,
                  },
                  {
                    scoreName: 'Industry Risk',
                    scoreValue: compositeScore.industryRisk.score,
                  },
                  {
                    scoreName: 'Payment History',
                    scoreValue: compositeScore.paymentHistory.score,
                  },
                  {
                    scoreName: 'Revenue Change',
                    scoreValue: compositeScore.revenueChange.score,
                  },
                  {
                    scoreName: 'FICO Change',
                    scoreValue: compositeScore.ficoChange.score,
                  },
                  {
                    scoreName: 'Stacking',
                    scoreValue: compositeScore.stacking.score,
                  },
                  ...(compositeScore.version === 'renewal_composite_score_v2'
                    ? [
                        {
                          scoreName: 'BK Plus',
                          scoreValue: compositeScore.bkPlus.score,
                        },
                      ]
                    : []),
                ]}
              />
            )}
        </Grid.Item>

        <Grid.Item xl={6}>
          <DescriptionList>
            <Divider orientation="horizontal" />

            <DescriptionList.Item>
              <DescriptionList.Term>External ISO Notes</DescriptionList.Term>
              <DescriptionList.Details>
                <TextInput
                  label="External ISO Notes"
                  hiddenLabel
                  name="externalISONotes"
                  type="text"
                  value={ledgerToUpdate.uwNotes}
                  readOnly={ledger.lock}
                  onValueChange={(val) => {
                    handleChangeLedger({ uwNotes: val });
                  }}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <Divider orientation="horizontal" />

            <DescriptionList.Item>
              <DescriptionList.Term>Program Explanation</DescriptionList.Term>
              <DescriptionList.Details>
                <TextInput
                  label="Program Explanation"
                  hiddenLabel
                  name="programExplanation"
                  type="text"
                  readOnly={ledger.lock}
                  value={ledgerToUpdate.programExplanation}
                  onValueChange={(val) =>
                    handleChangeLedger({ programExplanation: val })
                  }
                  required={
                    isProgramExplanationInvalid &&
                    isProgramExplanationRequired &&
                    !isRenewalSubmission
                  }
                />
                {isProgramExplanationInvalid && isProgramExplanationRequired && (
                  <Text bold color="danger" size="s">
                    *Required because the program is Starter/Core or does not
                    match with suggested pricing.
                  </Text>
                )}
              </DescriptionList.Details>
            </DescriptionList.Item>

            {ledgerToUpdate.overrideBuyRates && (
              <>
                <Divider orientation="horizontal" />

                <DescriptionList.Item>
                  <DescriptionList.Term>
                    Override Explanation
                  </DescriptionList.Term>
                  <DescriptionList.Details>
                    <TextArea
                      label="Override Explanation"
                      hiddenLabel
                      readOnly={ledger.lock}
                      name="overrideExplanation"
                      value={ledgerToUpdate.overrideBuyRatesReason}
                      onChange={handleOverrideReason}
                    />
                  </DescriptionList.Details>
                </DescriptionList.Item>
              </>
            )}
          </DescriptionList>
        </Grid.Item>
      </Grid>

      <Flex flexDirection={'column'}>
        <Subheading>Revenue Summary</Subheading>

        <Box>
          <RevenueSummaryTable
            ledger={ledgerToUpdate}
            amountRequested={submission?.amountRequested}
            isoUuid={submission?.isoUuid}
            onChange={handleChangeLedger}
            readOnly={ledger.lock}
          />

          <BuyRatesContainer
            ledger={ledgerToUpdate}
            readOnly={ledger.lock}
            submissionUuid={submission.uuid}
            handleChangeLedger={handleChangeLedger}
          />
        </Box>
      </Flex>

      <OffersContainer
        submissionUuid={submission.uuid}
        ledger={ledger}
        readOnly={ledger.lock}
        customerUuid={submission?.customerUuid}
        handleChangeLedger={handleChangeLedger}
        eligibleForWeekly={ledgerToUpdate.eligibleForWeekly}
        isRenewal={isRenewal}
      />

      <StipulationManager submissionUuid={submission.uuid} />
    </Flex>
  );
};
