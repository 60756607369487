import { AchReturnsByCode } from './achReturns.types';
import { forEachMonth } from '../../helpers/date/dateIterationUtils';

/*
 * Generates an object with the last 12 months in yyyy-mm format as keys and
 * 0 as the value.
 */
export const generateEmptyMonthValues = (): Totals => {
  const monthValues: { [yyyyMmKey: string]: number } = {};
  forEachMonth((month) => {
    monthValues[month.toISOString().slice(0, 7)] = 0;
  });
  return monthValues;
};

/**
 * Generate a sorted array of unique return codes. See the tests for example
 * input and output.
 *
 */
export const generateUniqueSortedReturnCodes = (
  returnsByCode: AchReturnsByCode
): string[] => {
  const emptyMonths = generateEmptyMonthValues();

  const uniqueCodes: Set<string> = new Set();
  for (const [dateKey, codes] of Object.entries(returnsByCode)) {
    // yyyy-mm-dd to yyyy-mm
    const yyyyMmKey = dateKey.slice(0, 7);

    for (const [code] of Object.entries(codes)) {
      // Make sure it's one of the 12 past months.
      if (
        code !== 'totalPaybacksCount' &&
        emptyMonths[yyyyMmKey] !== undefined
      ) {
        uniqueCodes.add(code);
      }
    }
  }
  const sortedUniqueCodes = Array.from(uniqueCodes).sort();

  return sortedUniqueCodes;
};

interface Totals {
  [dateYyyyMmKey: string]: number;
}

interface Row {
  [codeKey: string]: Totals;
}

/**
 * Generate a row for each code, with zero values for the months. See the
 * tests for example input and output.
 *
 */
export const generateEmptyRows = (codes: string[]): Row => {
  const emptyRows: Row = codes.reduce((result: Row, code): Row => {
    result[code] = generateEmptyMonthValues();
    return result;
  }, {} as Row);
  return emptyRows;
};

/**
 * Generate a row for each code, and add the total payback count for each month.
 * See the tests for example input and output.
 *
 * Starts with the empty rows (see the test for what that looks like) and then
 * adds the total payback count for each month.
 *
 */
export const generateRows = (returns: AchReturnsByCode): Row => {
  const sortedUniqueCodes = generateUniqueSortedReturnCodes(returns);

  // Process each month.
  return Object.keys(returns).reduce((result, dateKey) => {
    const codes = returns[dateKey];
    // yyyy-mm-dd to yyyy-mm
    const yyyyMmKey = dateKey.slice(0, 7);

    // Within each month, process each code.
    for (const [code, count] of Object.entries(codes)) {
      if (code !== 'totalPaybacksCount') {
        // Make sure it's one of the 12 past months already in the list.
        if (result[code]?.[yyyyMmKey] !== undefined) {
          result[code][yyyyMmKey] = count;
        }
      }
    }
    return result;
  }, generateEmptyRows(sortedUniqueCodes));
};

// Generate the ACH totals rows.
export const generateAchTotalsRow = (
  returnsByCode: AchReturnsByCode
): Totals => {
  return Object.keys(returnsByCode).reduce(
    (result: Totals, dateKey): Totals => {
      // yyyy-mm-dd to yyyy-mm
      const yyyyMmKey = dateKey.slice(0, 7);
      // Make sure it's one of the 12 past months already in the list.
      if (result[yyyyMmKey] !== undefined) {
        result[yyyyMmKey] =
          result[yyyyMmKey] + returnsByCode[dateKey].totalPaybacksCount;
      }
      return result;
    },
    generateEmptyMonthValues()
  );
};

// Total returns by month.
export const generateReturnsTotalsRow = (
  returnsByCode: AchReturnsByCode
): Totals => {
  return Object.keys(returnsByCode).reduce(
    (result: Totals, dateKey): Totals => {
      const codes = returnsByCode[dateKey];
      // yyyy-mm-dd to yyyy-mm
      const yyyyMmKey = dateKey.slice(0, 7);
      Object.keys(codes).forEach((code) => {
        if (code !== 'totalPaybacksCount') {
          // Make sure it's one of the 12 past months already in the list.
          if (result[yyyyMmKey] !== undefined) {
            result[yyyyMmKey] =
              result[yyyyMmKey] + returnsByCode[dateKey][code];
          }
        }
      });
      return result;
    },
    generateEmptyMonthValues()
  );
};

export const calculatePercent = (count: number, achTotal: number): number => {
  if (achTotal > 0) {
    return (count / achTotal) * 100;
  }

  return 0;
};

export const getFormattedPercentValue = (
  count: number,
  achTotal: number
): string => {
  return `${calculatePercent(count, achTotal).toFixed(1)}%`;
};

export const getCellValue = (
  showCounts: boolean,
  count: number,
  achTotal: number
): string => {
  if (showCounts) {
    return `${count.toFixed(0)}`;
  }

  return getFormattedPercentValue(count, achTotal);
};
