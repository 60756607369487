import { useEffect, useState } from 'react';
import { CrmGatewayClient } from 'api/CrmGatewayClient';
import { toError } from 'helpers/errorUtils';

export interface UseCoreIdResult {
  coreId?: string;
  loading: boolean;
  error?: string;
}

/**
 * Hits CRM Gateway to fetch the Core ID via the Salesforce ID.
 *
 * Note: Currently we only handle submissions. If there is another entity
 * type that we will need to handle at some point, you should expand the potential
 * types for the entityType via a union, rather than changing it to string.
 *
 * @param salesforceId
 * @param entityType
 *
 * @deprecated prefer to use `useCoreSalesforceMap` from
 * `src/apiHooks/crmGateway/coreSalesforceMapsFetchHooks.ts`
 * instead
 */
export const useCoreIdFromSalesforceId = (
  salesforceId: string,
  entityType: 'submission'
): UseCoreIdResult => {
  const [coreId, setCoreId] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    CrmGatewayClient.fetchMapping(salesforceId, entityType, true)
      .then((response) => {
        setCoreId(response.core_id);
      })
      .catch((e: unknown) => {
        const error = toError(e);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [salesforceId]);

  return {
    coreId,
    loading,
    error: errorMessage,
  };
};
