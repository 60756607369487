import {
  useApiCreateInternalRenewal,
  useApiGetSubmissionStageHistory,
  useApiGoToNextDeal,
  useApiLazyGetSubmissionEligibility,
  useCreateApiSubmissionLog,
  useGetApiSubmissionLogs,
  useGetApiWebPresences,
  useGetOwnerCreditOverviews,
  UseGetSubmissionStageHistory,
  useLazyGetApiSubmission,
  useUpdateApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';
import {
  useGetApiCustomer,
  useGetApiCustomerEstablishedDates,
} from 'apiHooks/underwriting/customerFetchHooks';
import {
  useGetApiSubmissionOwners,
  useGetBatchApiOwners,
} from 'apiHooks/underwriting/ownerFetchHooks';
import { useGetApiIso } from 'apiHooks/funding/isoFetchHooks';
import { useGetApiIndustryRisks } from 'apiHooks/underwriting/industryFetchHooks';

import {
  CreateApiSubmissionLogBody,
  CreditCommitteeAssignedLogResponse,
  CreditDataResponse,
  CustomerEstablishedDatesResponse,
  CustomerResponse,
  EnqueuedDealResponse,
  ExceptionAssignedLogResponse,
  FinalUnderwriterSignOffAssignedLogResponse,
  IndustryRiskProfile,
  OwnerResponse,
  RenewalEligibleResponse,
  SubmissionLogResponse,
  SubmissionLogsResponse,
  SubmissionResponse,
  SubmissionStageHistoryResponse,
  SubmissionWebPresenceResponse,
  UnderwriterAssignedLogResponse,
} from 'types/api/underwriting/types';
import { IsoResponse } from 'types/api/funding/types';

import {
  MutationResponse,
  UseGenericQueryResponse,
  UseLazyGenericQueryResult,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  useApiPullRelativity6IndustryPrediction,
  UsePullRelativity6IndustryPredictionArgs,
} from 'apiHooks/3pi/relativity6FetchHooks';
import { useFetchExperianConsumer } from 'apiHooks/3pi/experianConsumerFetchHooks';
import {
  ExperianConsumerResponse,
  Relativity6IndustryPredictionResponseBody,
} from 'types/api/3pi/types';
import {
  AuthenticatedUserResponse,
  UserByRoleResponse,
} from 'types/api/auth/types';
import {
  useGetApiUser,
  useGetApiUsersByRole,
} from 'apiHooks/auth/userFetchHooks';
import { featureFlags } from 'helpers/featureFlags';
import { ApplicationSnapshotProps } from './ApplicationSnapshot';
import {
  ApplicationSnapshotSubmission,
  Customer,
  EnqueuedDeal,
  EstablishedDates,
  ExperianConsumer,
  GroupedSubmissionLogs,
  IndependentSalesOrganization,
  Owner,
  OwnerFicoScores,
  Relativity6IndustryPrediction,
  RiskProfile,
  SubmissionStageHistory,
  User,
  WebPresence,
} from './applicationSnapshot.types';

const toCustomer = (response?: CustomerResponse): Customer | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    uuid: response.uuid,
    legalName: response.legal_name,
    doingBusinessAsName: response.name,
    address: response.address ?? undefined,
    industryName: response.industry_name ?? undefined,
    startedOn: response.started_on ?? undefined,
    fein: response.fein ?? undefined,
    isoProductId: response.iso_product_id ?? undefined,
  };
};

export const useGetCustomer = (
  customerUuid?: string
): UseGenericQueryResponse<Customer> => {
  return useGenericFeatureQuery(useGetApiCustomer, toCustomer, customerUuid);
};

// useGetBatchOwners and toOwner should be removed once we finish moving contacts to owners
const toOwner = (response: OwnerResponse): Owner => {
  return {
    uuid: response.uuid,
    fullName: `${response.first_name} ${response.last_name}`,
  };
};

export const useGetBatchOwners = (
  ownerUuids?: string[]
): UseGenericQueryResponse<Owner[]> => {
  return useGenericFeatureQuery(
    useGetBatchApiOwners,
    (data) => (data ? data?.map(toOwner) : []),
    ownerUuids
  );
};

const toSubmissionOwner = (response: OwnerResponse): Owner => {
  return {
    uuid: response.uuid,
    fullName: `${response.first_name} ${response.last_name}`,
  };
};

export const useSubmissionOwners = (
  submissionUuid?: string
): UseGenericQueryResponse<Owner[]> => {
  return useGenericFeatureQuery(
    useGetApiSubmissionOwners,
    (data) => (data ? data?.map(toSubmissionOwner) : []),
    submissionUuid
  );
};

export const useGetOwners = (
  ownerUuids?: string[],
  submissionUuid?: string
): UseGenericQueryResponse<Owner[]> => {
  const submissionOwners = useSubmissionOwners(submissionUuid);
  const batchOwners = useGetBatchOwners(ownerUuids);
  return featureFlags.fetch_owners_by_submissionUuid
    ? submissionOwners
    : batchOwners;
};

const toWebPresences = (
  response?: SubmissionWebPresenceResponse
): WebPresence | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    businessWebsite: response.business_website ?? undefined,
    facebook: response.facebook ?? undefined,
    instagram: response.instagram ?? undefined,
    yelp: response.yelp ?? undefined,
    other: response.other ?? undefined,
  };
};

export const useGetWebPresences = (
  submissionUuid?: string
): UseGenericQueryResponse<WebPresence> => {
  return useGenericFeatureQuery(
    useGetApiWebPresences,
    toWebPresences,
    submissionUuid
  );
};

const toCustomerEstablishedDates = (
  response?: CustomerEstablishedDatesResponse
): EstablishedDates | undefined => {
  if (!response) {
    return undefined;
  }

  const {
    ln: { ln_date, ln_day_diff, ln_year_diff },
  } = response;

  return {
    customerReportedStartDate: response.customer_started_on,
    ...(ln_date !== null &&
      ln_day_diff !== null &&
      ln_year_diff !== null && {
        lexisNexis: {
          lexisNexisStartDate: ln_date,
          lexisNexisYearDifference: ln_year_diff,
          lexisNexisDayDifference: ln_day_diff,
        },
      }),
  };
};

export const useGetEstablishedDates = (
  customerUuid?: string
): UseGenericQueryResponse<EstablishedDates> => {
  return useGenericFeatureQuery(
    useGetApiCustomerEstablishedDates,
    toCustomerEstablishedDates,
    customerUuid
  );
};

const toIso = (iso?: IsoResponse): IndependentSalesOrganization | undefined => {
  if (!iso) {
    return undefined;
  }

  return {
    uuid: iso.partner.uuid,
    name: iso.partner.name,
  };
};

export const useGetIso = (
  isoUuid?: string
): UseGenericQueryResponse<IndependentSalesOrganization> => {
  return useGenericFeatureQuery(useGetApiIso, toIso, isoUuid);
};

const toOwnerFicoScores = (
  creditReport?: CreditDataResponse[]
): OwnerFicoScores[] => {
  if (!creditReport) {
    return [];
  }
  return creditReport.map((report) => ({
    ownerUuid: report.owner_uuid,
    fico: report.fico_score ?? undefined,
  }));
};

type UseOwnerCreditDataResult = UseLazyGenericQueryResult<OwnerFicoScores[]>;

export const useOwnerCreditData = (
  submissionUuid?: string
): UseOwnerCreditDataResult => {
  return useGenericFeatureQuery(
    useGetOwnerCreditOverviews,
    toOwnerFicoScores,
    submissionUuid
  );
};

type UseRelativity6IndustryPredictionResult = [
  (args: UsePullRelativity6IndustryPredictionArgs) => Promise<MutationResponse>,
  {
    data?: Relativity6IndustryPrediction;
    error?: Error;
    loading: boolean;
  }
];

const toIndustryPrediction = (
  data?: Relativity6IndustryPredictionResponseBody
): Relativity6IndustryPrediction | undefined => {
  if (!data) {
    return undefined;
  }

  // Filter by rank 0 in industry_prediction,
  // which will give us the most confident prediction
  const industry = data.industry_predictions.find((item) => item.rank === 0);

  return {
    industry: industry?.industry_name ?? '',
    confidenceScore: industry?.confidence_score ?? 0,
  };
};

export const useRelativity6IndustryPrediction =
  (): UseRelativity6IndustryPredictionResult => {
    const [createFn, { data, error, ...rest }] =
      useApiPullRelativity6IndustryPrediction();

    const createFunction = async (
      args: UsePullRelativity6IndustryPredictionArgs
    ): Promise<MutationResponse> => {
      return createFn(args);
    };

    return [
      createFunction,
      {
        data: toIndustryPrediction(data),
        error,
        ...rest,
      },
    ];
  };

const isNonEmptyMessage = (message?: string): message is string =>
  typeof message === 'string' && message.length > 0;

/* istanbul ignore next: not testing because it's just smushing together all the other hooks */
export const useApplicationSnapshot = (
  submission: ApplicationSnapshotSubmission
): ApplicationSnapshotProps => {
  const { customerUuid, isoUuid, uuid: submissionUuid } = submission || {};

  const {
    data: customer,
    error: customerError,
    loading: customerLoading,
  } = useGetCustomer(customerUuid);

  const {
    data: owners,
    error: ownersError,
    loading: ownersLoading,
  } = useGetOwners(submission?.ownerUuids, submissionUuid);

  const {
    data: establishedDates,
    error: establishedDatesError,
    loading: establishedDatesLoading,
  } = useGetEstablishedDates(customerUuid);

  const { data: webPresence, error: webPresenceError } =
    useGetWebPresences(submissionUuid);

  const { data: iso, error: isoError } = useGetIso(isoUuid);

  const errorMessages = [
    customerError?.message,
    ownersError?.message,
    webPresenceError?.message,
    establishedDatesError?.message,
    isoError?.message,
  ].filter(isNonEmptyMessage);

  const loading = customerLoading || ownersLoading || establishedDatesLoading;

  return {
    initialSubmission: submission,
    customer,
    webPresence,
    establishedDates,
    owners: owners?.filter((owner) =>
      submission?.ownerUuids.includes(owner.uuid)
    ),
    iso,
    errorMessages,
    loading,
  };
};

const toIndustryRisks = (response: IndustryRiskProfile): RiskProfile => {
  return {
    id: response.id,
    name: response.name,
    riskProfile: response.risk_profile,
    pricingRiskProfile: response.pricing_risk_profile,
    archived: response.archived,
  };
};

export const useIndustryRisks = (): UseGenericQueryResponse<RiskProfile[]> => {
  return useGenericFeatureQuery(useGetApiIndustryRisks, (data) =>
    data ? data.map(toIndustryRisks) : undefined
  );
};

// useApiGoToNextDeal

type UseNextDealResult = [
  () => Promise<MutationResponse>,
  {
    nextDealData: EnqueuedDeal | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

const toNextDeal = (
  response?: EnqueuedDealResponse
): EnqueuedDeal | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    submissionUuid: response?.submission_uuid,
    nextDealUrl: response?.underwriting_app_url,
  };
};

export const useGoToNextDeal = (): UseNextDealResult => {
  const [goToNextDeal, { data, error, loading, responseReady }] =
    useApiGoToNextDeal();

  const gotToNextDealFunction = async (): Promise<MutationResponse> => {
    return await goToNextDeal();
  };

  return [
    gotToNextDealFunction,
    { nextDealData: toNextDeal(data), error, loading, responseReady },
  ];
};

//Iso Elegibility hooks
type UseCreateRenewalResult = [
  (submissionUuid: string) => Promise<MutationResponse>,
  {
    renewalSubmissionUuid: string | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];
export const useCreateRenewal = (): UseCreateRenewalResult => {
  const [createRenewal, { data, error, loading, responseReady }] =
    useApiCreateInternalRenewal();

  const createRenewalFunction = async (
    submissionUuid: string
  ): Promise<MutationResponse> => {
    return await createRenewal(submissionUuid);
  };

  const renewalSubmissionUuid = data?.data;

  return [
    createRenewalFunction,
    { renewalSubmissionUuid, error, loading, responseReady },
  ];
};

type UseLazySubmissionEligibilityResult = [
  (submissionUuid: string) => Promise<void>,
  {
    data: RenewalEligibleResponse | undefined;
  }
];

export const useLazyGetSubmissionEligibility =
  (): UseLazySubmissionEligibilityResult => {
    const [fetcher, { data, error, ...rest }] =
      useApiLazyGetSubmissionEligibility();

    return [
      fetcher,
      {
        data,
        ...rest,
      },
    ];
  };

export const toSubmissionStageHistory = (
  data?: SubmissionStageHistoryResponse[]
): SubmissionStageHistory[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    return {
      date: item.date,
      subStage: item.sub_stage,
      notes: item.notes,
    };
  });
};

export interface UseSubmissionStageHistoryResult
  extends Omit<UseGetSubmissionStageHistory, 'data'> {
  data?: SubmissionStageHistory[];
}

export const useSubmissionStageHistory = (
  submissionUuid?: string
): UseSubmissionStageHistoryResult =>
  useGenericFeatureQuery(
    useApiGetSubmissionStageHistory,
    toSubmissionStageHistory,
    submissionUuid
  );

const toExperianConsumer = (
  data?: ExperianConsumerResponse
): ExperianConsumer | undefined => {
  if (!data) {
    return undefined;
  }

  // There will only be one element for `consume_credits` when fetching with
  // submissionUuid and ownerUuid, so we can safely use element 0. Also ensure
  // that none of the properties are undefined before accessing them and that
  // any arrays are non-empty before using them for `find`.
  const riskModel =
    data.consumer_credits &&
    Array.isArray(data.consumer_credits) &&
    data.consumer_credits.length > 0 &&
    data.consumer_credits[0].risk_models
      ? data.consumer_credits[0].risk_models.find(
          ({ model_name }) => model_name === 'FICO'
        )
      : undefined;

  return {
    ficoScore: riskModel?.model_score,
  };
};

type ErrorWithStatusCode = Error & {
  statusCode?: number;
};

type UseExperianConsumerResult = UseGenericQueryResponse<ExperianConsumer> & {
  error?: ErrorWithStatusCode;
};

export const useExperianConsumer = (
  submissionUuid?: string,
  ownerUuid?: string
): UseExperianConsumerResult =>
  useGenericFeatureQuery(
    useFetchExperianConsumer,
    toExperianConsumer,
    submissionUuid,
    ownerUuid
  );

const toUser = (
  userResponse: AuthenticatedUserResponse | undefined
): User | undefined => {
  if (!userResponse) {
    return undefined;
  }

  const user = userResponse.user;

  return {
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
  };
};

export const useGetUser = (
  id?: string | number
): UseGenericQueryResponse<User> =>
  useGenericFeatureQuery(useGetApiUser, toUser, id);

const toUsers = (users?: UserByRoleResponse[]): User[] => {
  if (!users) {
    return [];
  }
  return users?.map((user) => {
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      id: user.id,
    };
  });
};

export const useGetUsersByRole = (
  role?: string
): UseGenericQueryResponse<User[]> =>
  useGenericFeatureQuery(useGetApiUsersByRole, toUsers, role);

const isUnderwriterAssignedLog = (
  log: SubmissionLogResponse
): log is UnderwriterAssignedLogResponse => {
  return log.event === 'underwriter_assigned';
};

const isCreditCommitteeLog = (
  log: SubmissionLogResponse
): log is CreditCommitteeAssignedLogResponse => {
  return log.event === 'credit_committee_start';
};

const isExceptionAssignedLog = (
  log: SubmissionLogResponse
): log is ExceptionAssignedLogResponse => {
  return log.event === 'underwriting_exception_request_start';
};

const isFinalUnderwriterSignOffLog = (
  log: SubmissionLogResponse
): log is FinalUnderwriterSignOffAssignedLogResponse => {
  return log.event === 'underwriting_final_signoff_start';
};

const toGroupedSubmissionLogs = (
  response?: SubmissionLogsResponse
): GroupedSubmissionLogs | undefined => {
  if (!response) {
    return undefined;
  }

  const data = response.data;

  // Filter by each event
  const underwriterAssignedLogs = data.filter(isUnderwriterAssignedLog);
  const creditCommitteeLogs = data.filter(isCreditCommitteeLog);
  const exceptionAssignedLogs = data.filter(isExceptionAssignedLog);
  const finalUnderwriterSignOffLogs = data.filter(isFinalUnderwriterSignOffLog);

  return {
    underwriterAssigned: underwriterAssignedLogs.map((log) => ({
      assignedBy: log.user_id,
      assignedTo: log.data.underwriter_id,
      createdAt: log.created_at,
      updatedAt: log.updated_at,
      event: log.event,
    })),

    creditCommitteeStart: creditCommitteeLogs.map((log) => ({
      assignedBy: log.user_id,
      assignedTo: log.data.credit_committee__c,
      createdAt: log.created_at,
      updatedAt: log.updated_at,
      event: log.event,
    })),

    underwritingExceptionRequestStart: exceptionAssignedLogs.map((log) => ({
      assignedBy: log.user_id,
      assignedTo: log.data.exception_request_underwriter_user_id,
      createdAt: log.created_at,
      updatedAt: log.updated_at,
      event: log.event,
    })),

    underwritingFinalSignoffStart: finalUnderwriterSignOffLogs.map((log) => ({
      assignedBy: log.user_id,
      assignedTo: log.data.underwriter_sign_off_id,
      createdAt: log.created_at,
      updatedAt: log.updated_at,
      event: log.event,
    })),
  };
};

export const useGroupedSubmissionLogs = (
  submissionUuid?: string
): UseGenericQueryResponse<GroupedSubmissionLogs> =>
  useGenericFeatureQuery(
    useGetApiSubmissionLogs,
    toGroupedSubmissionLogs,
    submissionUuid
  );
const toApplicationSnapshotSubmission = (
  submission: SubmissionResponse
): ApplicationSnapshotSubmission => {
  return {
    amountRequested: submission.capital_needed,
    applicationCreatedDate: submission.date_app_received__c ?? undefined,
    category: submission.category ?? undefined,
    completedAutomationSteps:
      submission.completed_automation_steps ?? undefined,
    creditCommittee: submission.credit_committee__c ?? undefined,
    customerUuid: submission.customer_uuid,
    decisionAnalystId: submission.decision_analyst_id ?? undefined,
    decisionAnalystName: submission.decision_analyst_name ?? undefined,
    hasPreviouslyFundedCustomer: submission.has_previously_funded_customer,
    internalRenewal: submission.internal_renewal,
    isoCompetingSubMessage: submission.iso_competing_sub_message ?? undefined,
    isoUuid: submission.partner_uuid ?? undefined,
    ownerUuids: submission.owner_uuids,
    prequalAnalystName: submission.prequal_analyst_name ?? undefined,
    previousFundedSubmissionUuid:
      submission.previous_funded_opportunity_uuid ?? undefined,
    primeDeal: submission.prime_deal ?? undefined,
    processor: submission.processor ?? undefined,
    salesOwnerId: submission.sales_owner_id ?? undefined,
    salesOwnerName: submission.sales_owner_name ?? undefined,
    stageName: submission.stage_name,
    submissionSource: submission.submission_source,
    subStage: submission.sub_stage ?? undefined,
    type: submission.type,
    underwriter: submission.x2dc_underwriter__c ?? undefined,
    underwriterSignOffId: submission.underwriter_sign_off_id ?? undefined,
    underwriterId: submission.underwriter_id ?? undefined,
    underwriterExceptionRequestId:
      submission.exception_request_underwriter_user_id ?? undefined,
    uuid: submission.uuid,
  };
};

type UseCreateSubmissionLogResult = [
  (
    submissionUuid: string,
    body: CreateApiSubmissionLogBody
  ) => Promise<MutationResponse>,
  {
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];
export const useCreateSubmissionLog = (): UseCreateSubmissionLogResult => {
  const [createSubmissionLog, { error, loading, responseReady }] =
    useCreateApiSubmissionLog();

  const createSubmissionLogFunction = async (
    submissionUuid: string,
    body: CreateApiSubmissionLogBody
  ): Promise<MutationResponse> => {
    return await createSubmissionLog({ submissionUuid, createBody: body });
  };

  return [createSubmissionLogFunction, { error, loading, responseReady }];
};

type UseLazySubmissionResult = [
  (input: { submissionUuid: string }) => Promise<void>,
  {
    data: ApplicationSnapshotSubmission | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

export const useLazyGetSubmission = (): UseLazySubmissionResult => {
  const [fetcher, { data, error, ...rest }] = useLazyGetApiSubmission();

  return [
    fetcher,
    {
      data: data && toApplicationSnapshotSubmission(data),
      error,
      ...rest,
    },
  ];
};

export type UpdateSubmissionBody = {
  underwriterId?: number | null;
  creditCommittee?: string;
  underwriterExceptionRequestId?: number | null;
  underwriterSignOffId?: number | null;
  subStage?: string;
};

type UpdateSubmissionArgs = {
  submissionUuid: string;
  body: UpdateSubmissionBody;
};

type UseUpdateSubmissionResult = [
  (input: UpdateSubmissionArgs) => Promise<MutationResponse>,
  { error?: Error; loading?: boolean }
];

export const useUpdateSubmission = (): UseUpdateSubmissionResult => {
  const [updateSubmission, { error, loading }] = useUpdateApiSubmission();

  const updateFunction = (
    args: UpdateSubmissionArgs
  ): Promise<MutationResponse> => {
    return updateSubmission({
      submissionUuid: args.submissionUuid,
      updateBody: {
        underwriter_id: args.body.underwriterId,
        credit_committee__c: args.body.creditCommittee,
        exception_request_underwriter_user_id:
          args.body.underwriterExceptionRequestId,
        underwriter_sign_off_id: args.body.underwriterSignOffId,
        sub_stage: args.body.subStage,
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};
