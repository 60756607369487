import React from 'react';
import { Banner } from '@forward-financing/fast-forward';
import { usePublicDocuments } from './ownerOverviewHooks';
import { CriminalFilingsLinkPreviewPage } from './CriminalFilingsLinkPreviewPage';

export type CriminalFilingsLinkPreviewContainerProps = {
  submissionUuid: string;
  ownerUuid: string;
};

export const CriminalFilingsLinkPreviewContainer = ({
  submissionUuid,
  ownerUuid,
}: CriminalFilingsLinkPreviewContainerProps): JSX.Element => {
  const { data: publicDocuments, error: publicDocumentsError } =
    usePublicDocuments(submissionUuid);

  const ownerPublicDocument = publicDocuments?.find(
    (doc) => doc.ownerUuid === ownerUuid
  );

  return (
    <>
      {publicDocumentsError && (
        <Banner dismissable={false} variant="error">
          {publicDocumentsError.message}
        </Banner>
      )}
      <CriminalFilingsLinkPreviewPage
        ownerPublicDocument={ownerPublicDocument}
      />
    </>
  );
};
